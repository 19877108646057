import React, {useState} from 'react'
import {useNavigate, Link} from 'react-router-dom'

import * as Yup from 'yup'
import {useFormik} from 'formik'
import {AlertModal} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const departmentSchema = Yup.object().shape({
  department: Yup.string().required('Deparment is required'),
})

export function EditDepartment() {
  const initialValues = {
    department: localStorage.getItem('editDepartmentName'),
    departmentId: localStorage.getItem('editDepartmentId'),
    organizationId: localStorage.getItem('org_Id'),
    isActive: true,
  }

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: departmentSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      axios
        .post(`${API_URL}/Department/UpdateDepartment`, values)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            setLoading(false)
            navigate('/department')
          })
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setSubmitting(false)
          setStatus('Something went wrong')
        })
    },
  })

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer' role='header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Department Details</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                  <span>Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Department Name'
                    {...formik.getFieldProps('department')}
                  />
                  {formik.touched.department && formik.errors.department && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.department}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <Link to={'/department'}>
                <button type='submit' className='btn btn-secondary'>
                  Cancel
                </button>
              </Link>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && <span className='indicator-label'>Edit Department</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
