import React, {useEffect, useState} from 'react'
import PartnerCommision from '../Component/PartnerCommision'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios, {CancelTokenSource} from 'axios'
import {AlertModal, Loader} from '../../CommonFunctions/CommonFunction'
import {KTSVG} from '../../../_metronic/helpers'
import {GetAllActivePartner} from '../../services/partner.service'
import EditPartnerCommission from './EditPartnerCommission'

const PartnerCommissionData: React.FC = () => {
  const API = process.env.REACT_APP_API_URL
  let cancelTokenSource: CancelTokenSource | null = null
  const role = localStorage.getItem('role')
  const [posts, setPosts] = useState([])
  const [screenloader, setScreenLoader] = useState<boolean>(false)
  const [memberOption, setMemberOption] = useState<any[]>([])
  const [optionSelectedPartner, setOptionSelectedPartner] = useState<any>('')
  const [partner, setPartner] = useState<any[]>([])
  const [newRecords, setNewRecords] = useState<any>()
  const [updateState, setUpdateState] = useState<any>('')
  const [noRecord, setNoRecord] = useState<boolean>(false)
  const [addButtonDisable, setAddButtonDisable] = useState<boolean>(true)

  //   useEffect(() => {
  //     const isGroupAdmin = localStorage.getItem('isGroupAdmin')
  //     if (!isGroupAdmin) {
  //       setOptionSelectedMember({
  //         label: localStorage.getItem('name'),
  //         value: localStorage.getItem('userId'),
  //       })
  //     }
  //     if (
  //       role === process.env.REACT_APP_SYSTEM_SECOND_ROLE ||
  //       role === process.env.REACT_APP_SYSTEM_THIRD_ROLE
  //     ) {
  //       // callMemberUnderManager()
  //       GetAssignee()
  //         .then((res: any) => {
  //           const newList = res.data.map((item: any) => {
  //             return {
  //               label: item.assigneeName,
  //               value: item.assigneeId,
  //             }
  //           })
  //           setMemberOption(newList)
  //         })
  //         .catch((err) => {
  //           console.log(err)
  //         })
  //     } else if (
  //       role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
  //       role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
  //     ) {
  //       memberInfoByOrg()
  //         .then((res) => {
  //           const newList = res.data.map((item: any) => {
  //             return {
  //               label: item.fullName,
  //               value: item.userId,
  //             }
  //           })
  //           setMemberOption(newList)
  //         })
  //         .catch((err) => {
  //           console.log(err)
  //         })
  //     }
  //   }, [])

  useEffect(() => {
    GetAllActivePartner()
      .then((res: any) => {
        //console.log(res.data)
        const newList = res.data.map((item: any) => {
          return {
            label: item.partnerName,
            value: item.partnerId,
          }
        })
        setPartner(newList)
      })
      .catch((err) => console.error(err))
  }, [])

  const earlyStageCall = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    setNoRecord(false)
    setNewRecords([])
    setPosts([])
    setScreenLoader(true)
  }

  const SendDataOfUser = (userId: any) => {
    earlyStageCall()
    fetchPosts(userId)
  }

  //   const SendDataOfDate = (date: any) => {
  //     earlyStageCall()
  //     fetchPosts(dayjs(date).format('YYYY-MM-DDT00:00:00[Z]'), setOptionSelectedPartner.value)
  //   }

  const fetchPosts = (partnerId: any) => {
    setScreenLoader(true)
    setAddButtonDisable(false)
    cancelTokenSource = axios.CancelToken.source()
    axios
      .get(`${API}/PartnerOrgAssociation/GetPartnerAllOrgAssociationByPartnerId`, {
        params: {partnerId},
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setPosts(res.data)
        if (res.data.length === 0) setNoRecord(true)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setScreenLoader(false)
        setNewRecords([])
        setAddButtonDisable(false)
      })
  }

  //   const handleDateChange = (dates: any) => {
  //     setStartDate(dates)
  //     SendDataOfDate(dates)
  //   }

  const deleteHandler = (id: any) => {
    console.log('delete')
    if (id) {
      axios
        .post(`${API}/PartnerOrgAssociation/DeletePartnerOrgAssociation?id=${id}`)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          )
          fetchPosts(optionSelectedPartner.value)
        })
        .catch((err) => console.error(err))
    }
  }

  const cancelHandle = () => {
    // console.log('cancel')
    setAddButtonDisable(false)
    setNewRecords([])
    setUpdateState(null)
  }
  const addNewRecordHandle = () => {
    if (optionSelectedPartner) {
      setNoRecord(false)
      setAddButtonDisable(true)
      setNewRecords(() => (
        <PartnerCommision
          display={'Add'}
          deleteHandler={deleteHandler}
          partnerId={optionSelectedPartner.value}
          refreshList={fetchPosts}
          cancelHandler={cancelHandle}
        />
      ))
    }
  }

  return (
    <>
      <div className={`card card-xxl-stretch mb-xl-7`}>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Associate Partner</span>
          </h3>
          <div className='d-flex align-items-center justify-content-end'>
            <div className='d-flex align-items-center'>
              <div
                style={{width: '200px'}}
                className='mx-3'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Search Partner'
              >
                {role === process.env.REACT_APP_SYSTEM_THIRD_ROLE ? (
                  <>
                    {memberOption.length > 1 && (
                      <Select
                        className='selectDropdown'
                        components={makeAnimated()}
                        value={optionSelectedPartner}
                        options={partner}
                        placeholder='Select Partner'
                        onChange={(item: any) => {
                          setOptionSelectedPartner(item)
                          SendDataOfUser(item.value)
                          setAddButtonDisable(false)
                        }}
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                      />
                    )}
                  </>
                ) : (
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    value={optionSelectedPartner}
                    options={partner}
                    placeholder='Select Partner'
                    onChange={(item: any) => {
                      setOptionSelectedPartner(item)
                      SendDataOfUser(item.value)
                      setAddButtonDisable(false)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                )}
              </div>

              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to Add new Organization'
              >
                <button
                  className='btn btn-sm btn-primary me-3'
                  disabled={addButtonDisable}
                  onClick={addNewRecordHandle}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  Add New Organization
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card py-9 px-10'>
        {!screenloader && newRecords}
        {!screenloader &&
          posts.map((item: any) =>
            updateState === item.id ? (
              <EditPartnerCommission
                data={item}
                cancelHandler={cancelHandle}
                refreshList={fetchPosts}
                partnerId={optionSelectedPartner.value}
                updateStatus={setUpdateState}
              />
            ) : (
              <PartnerCommision
                display={'Default'}
                value={item}
                key={item.id}
                deleteHandler={deleteHandler}
                cancelHandler={cancelHandle}
                updateStatus={setUpdateState}
              />
            )
          )}
        {screenloader && Loader('0')}
        {!screenloader && posts.length === 0 && noRecord && (
          <h2 className='noRecordFound'>No Records Found</h2>
        )}
      </div>
    </>
  )
}

export default PartnerCommissionData
