import dayjs from 'dayjs'
import React, {useEffect, useState, useRef} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import swal from 'sweetalert2'
import axios from 'axios'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import {
  Loader,
  Pagination,
  SingleDatePickerComponent,
  AlertModal,
  CommonActionButtons,
} from '../../CommonFunctions/CommonFunction'
import '../../../css/Holiday.css'

const API_URL = process.env.REACT_APP_API_URL

const Holiday = () => {
  const [holidays, setHolidays] = useState<any>([])
  const [screenloader, setScreenLoader] = useState<any>(false)
  const [updateState, setUpdateState] = useState('')

  const getHolidays = () => {
    setScreenLoader(true)
    axios
      .get(`${API_URL}/HolidayCalendar/GetYearlyHolidaysByOrgId`, {
        params: {
          organizationId: localStorage.getItem('org_Id'),
          currentDate: dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        },
      })
      .then((res) => {
        setHolidays(res.data)
        setScreenLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setScreenLoader(false)
      })
  }

  useEffect(() => {
    getHolidays()
  }, [])

  const deleteHandler = (Props: any) => {
    let body = {
      holidayId: Props,
      organizationId: localStorage.getItem('org_Id'),
      isActive: false,
      modifiedDate: dayjs().format(),
      modifiedBy: localStorage.getItem('name'),
    }

    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Yes, delete it!'
    ).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .patch(`${API_URL}/HolidayCalendar/DeleteHoliday`, body)
          .then((res) => {
            getHolidays()
            AlertModal(
              res.data.message,
              '',
              res.data.success ? 'success' : 'warning',
              false,
              '#7066E0',
              'Ok'
            )
          })
          .catch((err) => {
            console.log(err)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Holiday not Deleted', '', 'error', false, '#7066E0', 'Ok')
        swal.fire({
          title: 'Holiday not deleted.',
          text: '',
          icon: 'error',
          heightAuto: false,
        })
      }
    })
  }

  let itemsPerPage: number = 10
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage

    setCurrentItems(holidays.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(holidays.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, holidays])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % holidays.length

    setItemOffset(newOffset)
  }

  function handleEdit(id: any) {
    setUpdateState(id)
  }

  return (
    <div>
      {localStorage.getItem('role') !== process.env.REACT_APP_SYSTEM_FIRST_ROLE ? (
        <div>
          <div className='card mb-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Holiday</span>
                {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new members</span> */}
              </h3>
            </div>
            <div className='card-body py-3' style={{padding: '30px'}}>
              <div className='table-responsive'>
                <table
                  className='table align-middle gs-0 gy-4'
                  style={{borderRadius: '5px', border: '1px solid'}}
                >
                  <thead>
                    <tr className='fw-bolder' style={{background: '#728FCE', fontSize: '15px'}}>
                      <th className='min-w-100px'></th>
                      <th className='min-w-150px text-start '>Name</th>
                      <th className='min-w-150px '>Day</th>
                      <th className='min-w-150px text-start'>Date</th>
                      <th className='min-w-10px'></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {currentItems.map((name: any) => (
                      <tr>
                        <td></td>
                        <td>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark  d-block fs-6 '>{name.holidayName}</span>
                          </div>
                        </td>
                        <td>
                          <span className='text-dark  d-block fs-6 ' style={{textAlign: 'left'}}>
                            {dayjs(name.holidayDate).format('dddd')}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {dayjs(name.holidayDate).format('DD/MM/YYYY')}
                          </span>
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}

                {screenloader && Loader('100px')}

                {!screenloader && currentItems.length === 0 && (
                  <h2 className='noRecordFound'>No Holidays Found</h2>
                )}
              </div>
              {/* end::Table container */}
            </div>
            {holidays.length > 10 && (
              <div className='d-flex align-items-center justify-content-center mb-3'>
                {Pagination(handlePageClick, pageCount, 0)}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className='card mb-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Holiday</span>
                {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new members</span> */}
              </h3>

              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <div>
                  <Tippy placement='top' content='Click to add a Holiday'>
                    <Link to='/addholiday' className='btn btn-sm btn-primary me-3'>
                      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                      Add Holiday
                    </Link>
                  </Tippy>
                </div>
              </div>
            </div>
            <div className='card-body py-3' style={{padding: '30px'}}>
              <div className='table-responsive'>
                <table
                  className='table align-middle gs-0 gy-4'
                  style={{borderRadius: '5px', border: '1px solid'}}
                >
                  <thead>
                    <tr
                      className='fw-bolder'
                      style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
                    >
                      <th className='min-w-100px' style={{width: '30%', paddingLeft: '9rem'}}>
                        Name
                      </th>
                      <th className='min-w-150px' style={{width: '20%', paddingLeft: '3rem'}}>
                        Day
                      </th>
                      <th className='min-w-150px ' style={{width: '25%', paddingLeft: '9rem'}}>
                        Date
                      </th>
                      <th className='min-w-150px text-center' style={{width: '25%'}}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {currentItems.map((name: any) =>
                      updateState === name.holidayId ? (
                        <Edit
                          name={name}
                          setUpdateState={setUpdateState}
                          getHolidays={getHolidays}
                        />
                      ) : (
                        <tr key={name.holidayId}>
                          <td style={{width: '30%', paddingLeft: '9rem', height: '4rem'}}>
                            <div className='d-flex justify-content-start flex-column'>
                              <span
                                className='text-dark  d-block fs-6 '
                                style={{lineHeight: '3rem', height: '31px'}}
                              >
                                {name.holidayName}
                              </span>
                            </div>
                          </td>
                          <td style={{width: '20%', paddingLeft: '3rem'}}>
                            <span className='text-dark  d-block fs-6 ' style={{textAlign: 'left'}}>
                              {dayjs.utc(name.holidayDate).format('dddd')}
                            </span>
                          </td>
                          <td style={{width: '25%', paddingLeft: '9rem'}}>
                            <span className='text-dark  d-block fs-6 '>
                              {dayjs.utc(name.holidayDate).format('DD/MM/YYYY')}
                            </span>
                          </td>
                          <td style={{width: '25%'}}>
                            <div className='d-flex justify-content-center flex-shrink-0'>
                              <Tippy placement='top' content='Edit holiday'>
                                <button
                                  // to='/editholiday'

                                  //state={users}
                                  onClick={() => {
                                    localStorage.setItem('editUser', name.holidayId)
                                    handleEdit(name.holidayId)
                                  }}
                                  data-bs-toggle='tooltip'
                                  data-bs-placement='top'
                                  data-bs-trigger='hover'
                                  className={`btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm me-1 ${
                                    new Date(name.holidayDate) <= new Date() ? 'disabled' : ''
                                  }`}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='svg-icon-3'
                                  />
                                </button>
                              </Tippy>

                              <Tippy placement='top' content='Delete holiday'>
                                <a
                                  onClick={() => deleteHandler(name.holidayId)}
                                  data-bs-toggle='tooltip'
                                  data-bs-placement='top'
                                  data-bs-trigger='hover'
                                  className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </a>
                              </Tippy>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}

                {screenloader && Loader('100px')}

                {!screenloader && currentItems.length === 0 && (
                  <h2 className='noRecordFound'>No Holidays Found</h2>
                )}
              </div>
              {/* end::Table container */}
            </div>

            {holidays.length > 10 && (
              <div className='d-flex align-items-center justify-content-center mb-3'>
                {Pagination(handlePageClick, pageCount, 0)}
              </div>
            )}
            {/* begin::Body */}
          </div>
        </div>
      )}
    </div>
  )
}

interface EditProps {
  name: any
  setUpdateState: any
  getHolidays: any
}

function Edit({name, setUpdateState, getHolidays}: EditProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const [editedName, setEditedName] = useState(name.holidayName)
  const [editedDate, setEditedDate] = useState(dayjs.utc(name.holidayDate).format('YYYY-MM-DD'))
  const [error, setError] = useState(false)
  const [nextYear, setNextYear] = useState(0)

  useEffect(() => {
    getMaxDate()
  }, [])

  const getMaxDate = () => {
    const currentYear = new Date().getFullYear()
    const nextYearValue = currentYear + 1
    setNextYear(nextYearValue)
  }

  const handleUpdate = () => {
    if (!editedName) {
      return
    }

    const values = {
      holidayId: name.holidayId,
      holidayName: editedName,
      holidayDate: dayjs.utc(editedDate).format('YYYY-MM-DDT00:00:00[Z]'),
      organizationId: localStorage.getItem('org_Id'),
      modifiedBy: localStorage.getItem('name'),
      modifiedDate: dayjs().format(),
    }

    axios
      .post(`${API_URL}/HolidayCalendar/UpdateHoliday`, values)
      .then((res) => {
        swal.fire({
          title: `${res.data.message}`,
          text: '',
          icon: res.data.success ? 'success' : 'warning',
          width: '23rem',
          heightAuto: false,
          backdrop: `rgba(0,0,0,0.8)`,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          customClass: {
            title: 'swal-popup',
          },
        })
        getHolidays()
      })
      .catch((err) => {
        console.log(err)
      })
    setUpdateState(null)
  }

  const handleCancel = () => {
    setUpdateState(null)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    editedName ? setError(false) : setError(true)
  }, [editedName])

  const handleDateChange = (dates: any) => {
    setEditedDate(dayjs.utc(dates).format(''))
  }

  return (
    <>
      <tr key={'a'}>
        <td style={{width: '30%', paddingLeft: '9rem'}} key={'aa'}>
          <input
            className='edit-input fs-6'
            type='text'
            name='name'
            ref={inputRef}
            spellCheck={false}
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
          {error && (
            <div className='fv-plugins-message-container'>
              <div className='text-danger mt-2'>*Holiday Name is required</div>
            </div>
          )}
        </td>
        <td style={{width: '20%', paddingLeft: '3rem'}} key={'ab'}>
          <span className='text-dark  d-block fs-6 ' style={{textAlign: 'left'}}>
            {dayjs(name.holidayDate).format('dddd')}
          </span>
        </td>
        <td style={{width: '25%', paddingLeft: '9rem'}} key={'ac'}>
          {SingleDatePickerComponent(
            new Date(editedDate),
            handleDateChange,
            'edit-input fs-6 edit-date',
            new Date(nextYear, 11, 31),
            new Date()
          )}

          {/* <span className='text-dark  d-block fs-6 '>
                    <input type='text' name='price' value={editedDate} onChange={(e) => setEditedDate(e.target.value)} />

                </span> */}
        </td>

        <td style={{width: '25%'}} key={'ad'}>
          {CommonActionButtons(handleUpdate, handleCancel)}
        </td>
      </tr>
      <style>
        {`
                        .form-control{
                            
                            font-size:unset;
                        }

                        
                    `}
      </style>
    </>
  )
}

export {Holiday}
