/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'

import axios from 'axios'
import dayjs from 'dayjs'
import { Pagination, Loader, MultiDateTimePicker } from '../../CommonFunctions/CommonFunction'

const API_URL = process.env.REACT_APP_API_URL

const UserTimeClaim = () => {
  const [spinner, setSpinner] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [dateError, setDateError] = useState(false)
  const [timeData, setTimeData] = useState<any[]>([])

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const currentItemss = timeData.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(timeData.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % timeData.length
    setItemOffset(newOffset)
  }

  useEffect(() => {
    setSpinner(true)
    getAllTimeClaims(startDate, endDate)
  }, [])

  const getAllTimeClaims = async (fromDate: any, toDate: any) => {
    if (!fromDate) {
      return
    }
    setSpinner(true)
    const body = {
      userId: localStorage.getItem('userId'),
      organizationId: localStorage.getItem('org_Id'),
      fromDate: dayjs(fromDate).format('YYYY-MM-DDT00:00:00[Z]'),
      toDate: toDate ? dayjs(toDate).format('YYYY-MM-DDT00:00:00[Z]') : dayjs(fromDate).format('YYYY-MM-DDT00:00:00[Z]'),
    }

    const allTimeClaims = await axios.post(`${API_URL}/TimeClaim/GetClaimTimeForUser`, body)
    setTimeData(allTimeClaims.data)
    setSpinner(false)
  }

  useEffect(() => {
    startDate ? setDateError(false) : setDateError(true)
  }, [startDate])

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)

    getAllTimeClaims(start, end)
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Time Claim Status</span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>

        <div style={{ display: 'flex' }}>
          <div className='date-picker-admin-individual muted' id='select-date'>
            {MultiDateTimePicker(startDate, endDate, handleDateChange, 'form-control ss_date')}

            {dateError && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2 mx-8'>Select a date</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {spinner ? (
        Loader('100px')
      ) : (
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder'>
                  <th></th>
                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-100px'>Date</th>
                  <th className='min-w-100px'>Time Zone</th>
                  <th className='min-w-100px text-center'>Start Time</th>
                  <th className='min-w-100px text-center'>End Time</th>
                  <th className='min-w-150px'>Reason</th>
                  <th className='min-w-100px'>Approved / Rejected By</th>
                  <th className='min-w-100px text-center'>Status </th>
                </tr>
              </thead>
              <tbody>
                {currentItemss.map((time) => (
                  <tr key={time.timeClaimId}>
                    <td></td>
                    <td >
                      <span className='text-dark  d-block fs-6 '>{time.userName}</span>
                    </td>
                    <td >
                      <span className='text-dark  d-block fs-6 '>
                        {dayjs(time.recordDate).format('DD/MM/YYYY')}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark d-flex mb-1 fs-6'>{time.timeZone}</span>
                    </td>
                    <td >
                      <span className='text-dark  d-block fs-6 text-center'>
                        {dayjs(time.fromTime).format('HH:mm')}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark d-block fs-6 text-center'>
                        {dayjs(time.toTime).format('HH:mm')}
                      </span>
                    </td>
                    <td >
                      <span className='text-dark d-block fs-6'>{time.reason}</span>
                    </td>
                    <td >
                      <span className='text-dark d-block fs-6'>{time.responseBy}</span>
                    </td>
                    <td>
                      <span className='d-flex justify-content-center'>
                        <div className='card-toolbar'>
                          <span
                            className={` badge fw-bold me-auto px-4 py-3 ${time.claimStatus === 'Rejected'
                              ? 'badge-light-danger'
                              : time.claimStatus === 'Pending'
                                ? 'badge-light-warning'
                                : 'badge-light-success'
                              } `}
                          >
                            {time.claimStatus}
                          </span>
                        </div>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className='d-flex flex-column'>
              {timeData.length > 10 && (
                <div className='d-flex align-items-center justify-content-center'>
                  {Pagination(handlePageClick, pageCount, 0)}
                </div>
              )}
            </div>

            {!spinner && timeData.length === 0 && (
              <h2 className='noRecordFound user-select-none'>No Request Found</h2>
            )}

            <style>
              {`
                    .ss_date:{
                      width:201px !important;
                    }
                        .paginationBttns{
                            margin-bottom:0;
                        }

                        .custom-select-class{
                          text-align: center;
                          width: 8rem;
                        }

                        .select-custom-header{
                          margin-right: 1rem;
                          line-height: 3rem;
                        }
                    `}
            </style>
          </div>
        </div>
      )}
    </div>
  )
}

export { UserTimeClaim }
