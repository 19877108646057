import React from 'react'
import { KTSVG } from '../../../helpers'
import dayjs from 'dayjs'

type Props={
    data:any
}

const UpgradePlanReqModal:React.FC<Props> = ({data}) => {

    return (
        <div className='modal fade' id='kt_modal_CurrentPlan' aria-hidden='true'>
            <div className='modal-dialog mw-650px d-flex align-items-center h-100' style={{ margin: 'auto' }}>
                <div className='modal-content rounded'>
                    <div className='modal-header justify-content-end border-0 pb-0'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>

                    <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
                        <div className='mb-7 text-center'>
                            <h1 className='mb-3'>Plan Details</h1>
                        </div>
                        <div className='d-flex '>
                            <div className='d-flex w-50 align-items-center justify-content-center flex-column'>
                                <h3>Active Plan</h3>
                                <div className='d-flex flex-column'>
                                <span>Type : {data.activePlanName}</span>
                                    <span>Amount : {data.activePlanAmount}</span>
                                    <span>Start Date : {dayjs(data.activePlanEffectiveFrom).format('YYYY/MM/DD')}</span>
                                    <span>End Date : {dayjs(data.activePlanTerminationDate).format('YYYY/MM/DD')}</span>
                                </div>
                            </div>
                            <div className='d-flex w-50 align-items-center flex-column'>
                                <h3>New Plan</h3>
                                <div className='d-flex flex-column'>
                                    <span>Type : {data.newPlanName}</span>
                                    <span>Amount : {data.newPlanAmount}</span>
                                    <span>Request Date : {dayjs(data.requestedDate).format('YYYY/MM/DD')}</span>
                                    {/* <span>Start Date : {dayjs(data.newEffectiveFrom).format('YYYY/MM/DD')}</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UpgradePlanReqModal