import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import Label, {AlertModal} from '../../CommonFunctions/CommonFunction'
import {getAppInformationById, EditInformation} from '../../services/Information.service'
import {useNavigate} from 'react-router-dom'

const EditInformationDetails: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [labelDetials, setLabelDetials] = useState<any>([])
  const letters = /^[a-zA-Z][a-zA-Z ]*$/

  const memberSchema = Yup.object().shape({
    infoValue: Yup.string().min(3, 'Minimum 10 character').required('Description is required'),
  })

  const initialValues = {
    infoConfigName: '',
    infoValue: '',
  }

  useEffect(() => {
    const id = localStorage.getItem('editConfig')
    if (id) {
      getAppInformationById(id).then((res: any) => {
        formik.setFieldValue('infoConfigName', res.data.infoConfigName)
        formik.setFieldValue('infoValue', res.data.infoValue)
        setLabelDetials(res.data)
      })
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: memberSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)

      let body = {
        id: labelDetials.id,
        infoValue: values.infoValue,
      }

      const editResponse = await EditInformation(body)
      if (!editResponse?.status) {
        AlertModal(
          'Something went wrong!',
          'Please try again later.',
          'error',
          false,
          '#7066E0',
          'Ok'
        ).then(() => {
          localStorage.removeItem('editConfig')
          navigate('/allInformation')
          resetForm()
          setLoading(false)
        })
      } else {
        AlertModal(
          editResponse.data.message,
          '',
          editResponse.data.success ? 'success' : 'warning',
          false,
          '#7066E0',
          'Ok'
        ).then(() => {
          localStorage.removeItem('editConfig')
          navigate('/allInformation')
          resetForm()
          setLoading(false)
        })
      }

      resetForm()
      setLoading(false)
    },
  })

  return (
    <Label
      formik={formik}
      loading={loading}
      navigate={navigate}
      isDisabled={true}
      buttonName='Save Changes'
    />
  )
}

export default EditInformationDetails
