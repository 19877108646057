import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

export function GetAllRequestByOrgForAdmin(FromDate: any, ToDate: any) {
  const OrganizationId = localStorage.getItem('org_Id')
  const UserId = localStorage.getItem('userId')

  return axios.get(`${API_URL}/TimeClaim/GetClaimTimeForAdmin`, {
    params: {
      OrganizationId,
      UserId,
      FromDate,
      ToDate,
    },
  })
}
