import clsx from 'clsx'
import React, {FC} from 'react'

import {HeaderUserMenu} from '../../../partials'

import image2 from '../../../../images/60111.jpg'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}></div>

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}></div>

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={image2} alt='metronic' />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}
export {Topbar}
