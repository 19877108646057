import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {userInfoActions} from '../redux/UserInfoRedux'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [showOrg, setShowOrg] = useState(false)
  const navigate = useNavigate()
  const [inputType, setInputType] = useState('password')
  const [imgPath, setImgPath] = useState('eye-view')
  const [groupAdminId, setGroupAdminId] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then((res) => {
            let accessToken = res.data.accessToken
            if (res.data.success === false) {
              setSubmitting(false)
              setStatus(res.data.message)
              setLoading(false)
            } else {
              if (res.data.isSuspended) {
                if (
                  res.data.roleName === 'Admin' ||
                  res.data.roleName === 'Partner' ||
                  res.data.roleName === 'Super Admin'
                ) {
                  localStorage.setItem('Email', res.data.email)
                  localStorage.setItem('role', res.data.roleName)
                  localStorage.setItem('userId', res.data.userId)
                  localStorage.setItem('org_Id', res.data.organizationId)
                  localStorage.setItem('org_name', res.data.organizationName)
                  localStorage.setItem('name', res.data.fullName)
                  localStorage.setItem('ProjTaskConfig', res.data.projectTaskConfig)
                  localStorage.setItem('cutOffTime', res.data.userCutOffTime)
                  localStorage.setItem('timezone', res.data.orgTimeZoneId)
                  setStatus('')
                  setSubmitting(false)
                  setLoading(false)
                  dispatch(auth.actions.login(accessToken))
                  dispatch(userInfoActions.updateUserInfo(res.data))
                } else {
                  setStatus('Your account has been Suspended')
                  setLoading(false)
                  setSubmitting(false)
                }
              } else {
                localStorage.setItem('Email', res.data.email)
                localStorage.setItem('role', res.data.roleName)
                localStorage.setItem('userId', res.data.userId)
                localStorage.setItem('org_Id', res.data.organizationId)
                localStorage.setItem('org_name', res.data.organizationName)
                localStorage.setItem('name', res.data.fullName)
                localStorage.setItem('ProjTaskConfig', res.data.projectTaskConfig)
                localStorage.setItem('cutOffTime', res.data.userCutOffTime)
                localStorage.setItem('orgCutOffTime', res.data.orgCutOffTime)
                localStorage.setItem('timezone', res.data.orgTimeZoneId)
                setStatus('')
                setSubmitting(false)
                setLoading(false)

                dispatch(auth.actions.login(accessToken))
                dispatch(userInfoActions.updateUserInfo(res.data))
              }
            }
            if (res.data.isGroupAdmin === true) {
              setAccessToken(res.data.accessToken)
              setGroupAdminId(res.data.userId)
              localStorage.setItem('isGroupAdmin', res.data.isGroupAdmin)
              navigate('/orgSelect')
            }
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 100)
    },
  })

  useEffect(() => {
    const verify = searchParams.get('verify')
    if (verify) {
      // Function to decode base64
      const decodeBase64 = (base64String: any) => {
        try {
          return atob(base64String)
        } catch (e) {
          console.error('Failed to decode base64 string:', e)
          return ''
        }
      }

      // Decode the token
      const decodedToken = decodeBase64(verify)

      // Find the position of the end of the email
      const emailEndPosition =
        decodedToken.indexOf('.com') + 4 ||
        decodedToken.indexOf('.net') + 4 ||
        decodedToken.indexOf('.org') + 4

      let email = ''
      let password = ''

      if (emailEndPosition) {
        email = decodedToken.slice(0, emailEndPosition)
        password = decodedToken.slice(emailEndPosition)
      }

      if (email && password) {
        formik.setFieldValue('email', email, false)
        formik.setFieldValue('password', password, false)
        formik.setTouched({email: true, password: true}, false)

        searchParams.delete('email')
        searchParams.delete('password')

        const newUrl = `${window.location.pathname}${searchParams.toString()}${
          window.location.hash
        }`
        window.history.replaceState({}, document.title, newUrl)

        setTimeout(() => {
          formik.validateForm().then((errors) => {
            if (!errors.email && !errors.password) {
              localStorage.setItem('isRedirected', 'true')
              formik.submitForm()
            }
          })
        }, 0)
      }
    }
  }, [])

  const handleClick = () => {
    setInputType(inputType === 'password' ? 'text' : 'password')
    setImgPath(inputType === 'password' ? 'eye-hide' : 'eye-view')
  }

  const handleClose = () => {
    setLoading(false)
    setShowOrg(false)
  }

  return (
    <>
      <div
        className='d-flex justify-content-center align-items-center '
        style={{width: '100%', borderRadius: '20px 20px 0px 0px', paddingLeft: '3rem'}}
      >
        <img
          src={toAbsoluteUrl('/media/logos/mera_monitor_logo_bgWhite.svg')}
          style={{width: '35rem', padding: '3rem 1rem 1rem 1rem'}}
        ></img>
      </div>
      <form
        className='form w-100'
        style={{padding: '0px 45px 35px 45px'}}
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          {/* <h1 className='text-dark mb-3'>Sign In to AAPNA Time Tracker</h1> */}
        </div>
        {/* begin::Heading */}
        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          <div></div>
        )}
        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder=' Enter Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />

          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert' className='text-danger mt-2'>
                {formik.errors.email}
              </span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to='/auth/forgot-password'
                className='fs-6 fw-bolder'
                style={{marginLeft: '200px', color: '#603BE5'}}
              >
                Forgot Password ?
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <div style={{position: 'relative'}}>
            <input
              type={inputType}
              placeholder='Enter Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            <img
              className=' custom-eye cursor-pointer'
              src={toAbsoluteUrl(`/media/icons/duotune/art/${imgPath}.svg`)}
              style={{top: '10px', right: '0'}}
              onClick={handleClick}
            ></img>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger mt-2'>
                  {formik.errors.password}
                </span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg w-100 mb-5'
            style={{background: '#603BE5', color: '#FFFFFF'}}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>

      <style>
        {`
                    input::-ms-reveal,
                    input::-ms-clear {
                      display: none;
                    }
                        
                    `}
      </style>
    </>
  )
}
