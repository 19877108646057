import React, {useState, useEffect} from 'react'
import dayjs from 'dayjs'
import '../../../css/website.css'

import {getAllPayment} from '../../services/Payment.services'

import {Loader, Pagination} from '../../CommonFunctions/CommonFunction'

const Invoice = () => {
  const [paymentHistory, setPaymentHistory] = useState<any>([])

  const [spinner, setSpinner] = useState(false)
  const [rowPerPage] = useState(10)
  const [forcePage] = useState<any>(null)

  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const currentItemss = paymentHistory.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(paymentHistory.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % paymentHistory.length
    setItemOffset(newOffset)
  }

  const getPaymentHistory = async () => {
    try {
      setSpinner(true)
      await getAllPayment().then((res) => {
        setPaymentHistory(res.data)
        setSpinner(false)
      })
    } catch (error) {
      console.log(error)
      setSpinner(false)
    }
  }

  useEffect(() => {
    getPaymentHistory()
  }, [])

  const handleDownload = (url: string) => {
    const link = document.createElement('a')
    link.href = url
    const fileName = url.substring(url.lastIndexOf('/') + 1)
    link.download = fileName
    link.dispatchEvent(new MouseEvent('click'))
  }

  return (
    <>
      <div>
        {spinner ? (
          Loader('100px')
        ) : (
          <>
            {' '}
            <div className='card mt-5'>
              <div className='card-header card-header-stretch border-bottom border-gray-200'>
                <div className='card-title'>
                  <h3 className=' m-0' style={{fontSize: '1.5rem', fontWeight: '700'}}>
                    Payment History
                  </h3>
                </div>
              </div>

              {currentItemss.length > 0 && (
                <div>
                  <div className='tab-content'>
                    <div
                      id='kt_billing_months'
                      className='card-body p-0 tab-pane fade active show'
                      role='tabpanel'
                      aria-labelledby='kt_billing_months'
                    >
                      <div className='table-responsive'>
                        <table className='table table-row-bordered align-middle gy-4 gs-9'>
                          <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                            <tr>
                              <td className='min-w-150px'>Payment ID</td>
                              <td className='min-w-125px'>Start Date</td>
                              <td className='min-w-125px'>End Date</td>
                              <td className='min-w-100px'>Plan </td>
                              <td className='min-w-100px'>Amount</td>
                              <td className='min-w-100px'>Type</td>
                              <td className='min-w-100px'>Date</td>
                              {/* <td className='min-w-100px'>Invoice</td>
                              <td className='min-w-100px'>Receipt</td> */}
                              <td></td>
                            </tr>
                          </thead>
                          <tbody className='fw-semibold text-gray-600'>
                            {currentItemss.map((data: any) => (
                              <tr key={data.paymentId}>
                                <td className='fs-4'>{data.paymentId}</td>
                                <td className='fs-4'>
                                  {dayjs(data.effectiveFrom).format('DD/MM/YYYY')}
                                </td>
                                <td className='fs-4'>
                                  {dayjs(data.terminationDate).format('DD/MM/YYYY')}
                                </td>
                                <td className='fs-4'>{data.billingPlanName}</td>
                                <td className='fs-4'>{data.amount}</td>
                                <td className='fs-4'>{data.transactionType}</td>
                                <td className='fs-4'>
                                  {dayjs(data.transactionDate).format('DD/MM/YYYY')}
                                </td>

                                {/* <td>
                                  <button
                                    className='btn btn-sm btn-light btn-active-primary'
                                    onClick={() => handleDownload(data.invoiceUrl)}
                                  >
                                    Download
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className='btn btn-sm btn-light btn-active-primary'
                                    onClick={() => handleDownload(data.receiptUrl)}
                                  >
                                    Download
                                  </button>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {paymentHistory.length > 10 && (
                    <div className='d-flex align-items-center justify-content-center position-relative mb-3'>
                      {paymentHistory.length > rowPerPage &&
                        Pagination(handlePageClick, pageCount, forcePage)}
                    </div>
                  )}
                </div>
              )}

              {currentItemss.length === 0 && (
                <h2 className='noRecordFound'>No Previous Payments Found</h2>
              )}
            </div>
          </>
        )}

        <style>{`
          .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
            animation: fadeIn 0.5s ease;
            
          }

          .modal-innerContainer {
            max-width: 700px;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 6px;
            padding: 20px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            
          }

        .custom-modal-header{
            width: 100%;
            padding-right: 34rem;
            font-size: 1.8rem;
            font-weight: 700;
        }


        `}</style>
      </div>
    </>
  )
}

export {Invoice}
