import React, {useState, useEffect} from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import '../../../css/website.css'
import {Loader} from '../../CommonFunctions/CommonFunction'

const API = process.env.REACT_APP_API_URL

const TransactionDetails = () => {
  const role = localStorage.getItem('role')
  const [paymentHistory, setPaymentHistory] = useState<any>([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState<any>([])
  const [spinner, setSpinner] = useState(false)

  const getPaymentHistory = async () => {
    setSpinner(true)
    const history = await axios.get(`${API}/Payment/GetAllPaymentByOrganisation`, {
      params: {
        org_Id: localStorage.getItem('org_Id'),
      },
    })

    console.log('The history is ', history.data)
    setPaymentHistory(history.data)
    setSpinner(false)
  }

  const handleModalOpen = async (paymentId: any) => {
    console.log('the payment id is ', paymentId)

    const details = await axios.get(`${API}/Payment/GetPaymentByPaymentId`, {
      params: {
        payment_Id: paymentId,
      },
    })
    setPaymentDetails(details.data)

    setModalOpen(true)
  }

  const handleModalClose = () => {
    console.log('This is inside the handle modal close')
    setModalOpen(false)
  }

  useEffect(() => {
    console.log('this is to get the payment history')
    getPaymentHistory()
  }, [])

  return (
    <>
      <div>
        <div className='card mt-5'>
          <div className='mt-5 mb-5' style={{marginLeft: '30px'}}>
            {role === process.env.REACT_APP_SYSTEM_FIRST_ROLE && (
              <h3
                className='card-title align-items-start flex-column'
                style={{marginBottom: 'unset'}}
              >
                <span className='card-label fw-bolder fs-2'>Payment Details</span>
              </h3>
            )}
          </div>
        </div>

        <div className='card mt-5' style={{padding: '2rem 2.5rem'}}>
          {spinner && Loader('0px')}

          {paymentHistory.length === 0 && <h2 className='noRecordFound'>No Payments Found</h2>}
          {paymentHistory.length > 0 && (
            <div>
              <h3 className='mb-5'>Latest Payment</h3>
              <div className='row'>
                <div className='d-flex' style={{border: '1px solid', padding: '10px'}}>
                  <div className='w-50'>
                    <span className='text-dark d-flex mb-2 fs-2'>
                      Payment Date : {dayjs(paymentHistory[0].transactionDate).format('DD/MM/YYYY')}
                    </span>
                    <span className='text-dark d-flex mb-2 fs-2'>
                      Payment Amount : {paymentHistory[0].amount}
                    </span>
                    <span className='text-dark d-flex mb-2 fs-2'>
                      Payment ID : {paymentHistory[0].paymentId}
                    </span>
                  </div>
                  <div className='w-50' style={{paddingLeft: '2.5rem'}}>
                    <span className='text-dark d-flex mb-2 fs-2'>
                      Transaction ID : {paymentHistory[0].transactionId}
                    </span>
                    <span className='text-dark d-flex mb-2 fs-2'>
                      Payment Status : {paymentHistory[0].transactionStatus}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {paymentHistory.length > 0 ? (
          <div className='card mt-5'>
            <div className='card-header card-header-stretch border-bottom border-gray-200'>
              <div className='card-title'>
                <h3 className=' m-0' style={{fontSize: '1.5rem', fontWeight: '700'}}>
                  Payment History
                </h3>
              </div>
            </div>

            <div className='tab-content'>
              <div
                id='kt_billing_months'
                className='card-body p-0 tab-pane fade active show'
                role='tabpanel'
                aria-labelledby='kt_billing_months'
              >
                <div className='table-responsive'>
                  <table className='table table-row-bordered align-middle gy-4 gs-9'>
                    <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75'>
                      <tr>
                        <td className='min-w-150px'>Payment ID</td>
                        <td className='min-w-150px'>Date</td>
                        <td className='min-w-250px'>Status</td>
                        <td className='min-w-150px'>Amount</td>
                        <td className='min-w-150px'>Invoice</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold text-gray-600'>
                      {paymentHistory.map((data: any) => (
                        <tr key={data.paymentId}>
                          <td className='fs-4'>{data.paymentId}</td>
                          <td className='fs-4'>
                            {dayjs(data.transactionDate).format('DD/MM/YYYY')}
                          </td>
                          <td className='fs-4'>{data.transactionStatus}</td>
                          <td className='fs-4'>{data.amount}</td>

                          <td className='text-right'>
                            <button
                              className='btn btn-sm btn-light btn-active-light-primary'
                              onClick={() => handleModalOpen(data.paymentId)}
                            >
                              View
                            </button>
                            {/* <a
                                                                href='#'
                                                                className='btn btn-sm btn-primary'
                                                                data-bs-toggle='modal'
                                                                data-bs-target='#kt_modal_search_modal'
                                                                id='kt_toolbar_primary_button1'
                                                            >

                                                                View

                                                            </a> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        {isModalOpen && (
          <div className='modal-container'>
            <div className='card mt-5 modal-innerContainer' style={{padding: '2rem 2.5rem'}}>
              <i
                className='fa fa-times'
                aria-hidden='true'
                style={{fontSize: '2rem', paddingLeft: '46rem', cursor: 'pointer'}}
                onClick={() => handleModalClose()}
              ></i>
              <div className='mb-3'>
                <h3 className='custom-modal-header'>Payment Details</h3>
              </div>
              <div className='row'>
                <div className='d-flex' style={{border: '1px solid', padding: '10px'}}>
                  <div className='w-50'>
                    <span className='text-dark d-flex mb-2 fs-4'>
                      Payment Date : {dayjs(paymentDetails.transactionDate).format('DD/MM/YYYY')}
                    </span>
                    <span className='text-dark d-flex mb-2 fs-4'>
                      Payment Amount : {paymentDetails.amount}
                    </span>
                    <span className='text-dark d-flex mb-2 fs-4'>
                      Payment ID : {paymentDetails.paymentId}
                    </span>
                  </div>
                  <div className='w-50' style={{paddingLeft: '2.5rem'}}>
                    <span className='text-dark d-flex mb-2 fs-4'>
                      Transaction ID : {paymentDetails.transactionId}
                    </span>
                    <span className='text-dark d-flex mb-2 fs-4'>
                      Payment Status : {paymentDetails.transactionStatus}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <style>{`
          .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
            animation: fadeIn 0.5s ease;
            
          }

          .modal-innerContainer {
            max-width: 700px;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 6px;
            padding: 20px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            
          }

        .custom-modal-header{
            width: 100%;
            padding-right: 34rem;
            font-size: 1.8rem;
            font-weight: 700;
        }


        `}</style>
      </div>
    </>
  )
}

export {TransactionDetails}
