import React from 'react'
import {convertSecIntoHoursMinSec} from '../Common_Function/Function'
import {Loader} from '../../CommonFunctions/CommonFunction'

type Props = {
  data: []
  title: string
  loader: any
}

export const ProdAndUnCompo: React.FC<Props> = ({data, title, loader}) => {
  return (
    <div className='card h-550px' style={{width: '49%'}}>
      <div className='card-header border-0'>
        <h3 className=' align-self-center'>
          <span className='card-label fw-bolder fs-4 py-5'>{title}</span>
        </h3>
      </div>
      <div className='mx-5 mb-6 overflow-auto'>
        <table className='table align-middle gs-0 gy-4 mb-0'>
          {/* begin::Table head */}
          <thead>
            <tr
              className='fw-bolder'
              style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
            >
              <th></th>
              <th className=' min-w-200px'>Site / App</th>
              <th className=' min-w-100px text-center'>Time</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {data?.map((list: any, index: any) => (
              <tr key={index}>
                <td></td>

                <td>
                  <span className='text-dark  d-flex mb-1 fs-6'>
                    {list.appName ? list.appName : list.url}
                  </span>
                </td>
                <td>
                  <span className='text-dark justify-content-center d-flex mb-1 fs-6'>
                    {convertSecIntoHoursMinSec(list.totalTime)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {loader
          ? Loader('100px')
          : data.length === 0 && <h2 className='noRecordFound'>No Records Found</h2>}
      </div>
    </div>
  )
}
