import axios, {CancelTokenSource} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

//to get selected and unselected website by org
export function GetAllCatalouge() {
  return axios.get(`${API_URL}/AppUrlCatalougeMaster/GetAllCatalouge`)
}

export function GetWebAppByOrgAndCatalogueId(catalogMasterId: string, url: string) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }
  const orgId = localStorage.getItem('org_Id')

  cancelTokenSource = axios.CancelToken.source()
  return axios.get(`${API_URL}/AppUrlCatalogue/${url}`, {
    params: {
      orgId,
      catalogMasterId,
    },
    cancelToken: cancelTokenSource?.token,
  })
}

export function AddCategoryData(body: any, url: string) {
  return axios.post(`${API_URL}/AppUrlCatalogue/${url}`, body)
}
export function RemoveCategoryData(body: any, url: string) {
  return axios.patch(`${API_URL}/AppUrlCatalogue/${url}`, body)
}

export function GetWebByOrgDeptAndCatalogueId(
  catalogMasterId: string,
  departmentId: string,
  url: string
) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }
  const orgId = localStorage.getItem('org_Id')
  cancelTokenSource = axios.CancelToken.source()
  return axios.get(`${API_URL}/AppUrlCatalogue/${url}`, {
    params: {
      orgId,
      catalogMasterId,
      departmentId,
    },
    cancelToken: cancelTokenSource?.token,
  })
}
export function GetWebAppByOrgUserAndCatalogueId(
  catalogMasterId: string,
  userId: string,
  url: string
) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }
  const orgId = localStorage.getItem('org_Id')
  cancelTokenSource = axios.CancelToken.source()
  return axios.get(`${API_URL}/AppUrlCatalogue/${url}`, {
    params: {
      orgId,
      catalogMasterId,
      userId,
    },
    cancelToken: cancelTokenSource?.token,
  })
}
