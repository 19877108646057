import dayjs from 'dayjs'
import moment from 'moment'

export const convertSecIntoHoursMinSec = (totalSeconds: any) => {
  const duration = moment.duration(totalSeconds, 'seconds')
  const hours = Math.floor(duration.asHours()).toString().padStart(2, '0')
  const formattedTime = moment.utc(totalSeconds * 1000).format(hours + ':mm:ss')

  return formattedTime === '00:00:00' ? '-' : formattedTime
}

export const convertSecIntoAMPM = (sec: any) => {
  let checkTime = dayjs(sec).format('HH:mm:ss')
  let displayTime = dayjs(sec).format('hh:mm:ss A')
  return checkTime == '00:00:00' ? `-` : displayTime
}
