import React, {useEffect, useState} from 'react'
import {memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import axios, {CancelTokenSource} from 'axios'
import 'react-image-lightbox/style.css'
import './style.css'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'
import {Loader, SingleDatePickerComponent} from '../../CommonFunctions/CommonFunction'
import dayjs from 'dayjs'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {GetAssignee} from '../../services/GetAllAssinee.services'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {userInfoActions} from '../../modules/auth'

const API = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

type Props = {
  className: string
}

const ListsWidget6: React.FC<Props> = ({className}) => {
  const SelectedUser: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const [members, setMembers] = useState<any[]>([])
  const role = localStorage.getItem('role')
  const [path, setPath] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [loadloader, setLoadLoader] = useState(false)
  const [memberOption, setMemberOption] = useState<any>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [showNorRecordFound, setShowNoRecordFound] = useState<any>(false)
  const showRenderThumb = true
  const [userEmail, setUserEmail] = useState<any>('')
  const [userTimeZone, setUserTimeZone] = useState<string>('')
  const [colorLegends, setColorLegends] = useState<any[]>([])

  const setEmailOfUser = (userId: any, members: any) => {
    let Id: any = members.find((item: any) => item.userId == userId)
    setUserEmail(Id.email)
    return Id.email
  }

  const earlyStageCall = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    setPath([])
    setSpinner(true)
    setLoadLoader(false)
    setShowNoRecordFound(false)
  }

  const SendDataOfUser = (item: any, members: any) => {
    earlyStageCall()
    let email = ''
    if (SelectedUser.role !== process.env.REACT_APP_SYSTEM_THIRD_ROLE) {
      email = setEmailOfUser(item, members)
    } else {
      email = localStorage.getItem('Email') || ''
    }

    callOfScreenshots(dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'), item, email)
  }

  const SendDataOfDate = (date: any) => {
    earlyStageCall()
    callOfScreenshots(dayjs(date).format('YYYY-MM-DDT00:00:00[Z]'), optionSelectedMember.value)
  }

  const callOfScreenshots = (date?: any, userId?: any, email?: any) => {
    cancelTokenSource = axios.CancelToken.source()
    axios
      .get(`${API}/CloudStorageScreenshots/GetScreenshots`, {
        params: {
          Email: email ? email : userEmail,
          ReportDate: date ? date : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
          OrganizationId: localStorage.getItem('org_Id'),
          UserId: userId ? userId : localStorage.getItem('userId'),
        },
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setUserTimeZone(res.data.timeZone)
        const newList = res.data.blobResponse.map((item: any) => {
          return {
            original: item.original,
            thumbnail: item.thumbnail,
            thumbnailClass: item.colorCode,
            description: `Time - ${item.description.replace('.', ':')}:00`,
          }
        })
        setPath(newList)
        if (res.data.blobResponse.length > 0) {
          setLoadLoader(true)
        } else if (res.data.blobResponse.length == 0) {
          setSpinner(false)
          setShowNoRecordFound(true)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const callColorLegends = () => {
    axios
      .get(`${API}/ColorCodeRange/GetColorCodeRangeConfigurationByByOrg`, {
        params: {
          organizationId: localStorage.getItem('org_Id'),
        },
      })
      .then((res: any) => {
        setColorLegends(res.data)
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    callColorLegends()
    setSpinner(true)
    if (
      !SelectedUser.selectedUserId &&
      SelectedUser.role !== process.env.REACT_APP_SYSTEM_THIRD_ROLE
    ) {
      setUserEmail(localStorage.getItem('Email'))
      callOfScreenshots('', '', localStorage.getItem('Email'))
    }

    if (SelectedUser.role === process.env.REACT_APP_SYSTEM_THIRD_ROLE) {
      setUserEmail(localStorage.getItem('Email'))
      callOfScreenshots('', '', localStorage.getItem('Email'))
    }
  }, [])

  useEffect(() => {
    const isGroupAdmin = localStorage.getItem('isGroupAdmin')

    if (!isGroupAdmin && !SelectedUser.selectedUserId) {
      setOptionSelectedMember({
        label: localStorage.getItem('name'),
        value: localStorage.getItem('userId'),
      })
    }
    if (
      role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    ) {
      getAdminAndReportAdminData()
    } else if (
      role === process.env.REACT_APP_SYSTEM_THIRD_ROLE ||
      role === process.env.REACT_APP_SYSTEM_SECOND_ROLE
    ) {
      getManagerAndUserData()
    }
  }, [])

  const getManagerAndUserData = async () => {
    GetAssignee()
      .then((res) => {
        // setMembers(res.data)
        const newList = res.data.map((item: any) => {
          return {
            label: item.assigneeName,
            value: item.assigneeId,
          }
        })

        setMemberOption(newList)
      })
      .catch((err) => {
        console.log(err)
      })
    if (SelectedUser.role !== process.env.REACT_APP_SYSTEM_THIRD_ROLE) {
      memberInfoByOrg()
        .then((res) => {
          setMembers(res.data)
          if (
            SelectedUser.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE &&
            SelectedUser.selectedUserId
          ) {
            let user = {
              label: SelectedUser.selectedUserName,
              value: SelectedUser.selectedUserId,
            }
            setOptionSelectedMember(user)
            SendDataOfUser(user.value, res.data)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const getAdminAndReportAdminData = async () => {
    memberInfoByOrg()
      .then((res) => {
        setMembers(res.data)
        const newList = res.data.map((item: any) => {
          return {
            label: item.fullName,
            value: item.userId,
          }
        })
        setMemberOption(newList)
        if (
          (SelectedUser.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
            SelectedUser.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE) &&
          SelectedUser.selectedUserId
        ) {
          let user = {
            label: SelectedUser.selectedUserName,
            value: SelectedUser.selectedUserId,
          }
          setOptionSelectedMember(user)
          SendDataOfUser(user.value, res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDateChange = (dates: any) => {
    setStartDate(dates)
    SendDataOfDate(dates)
  }

  if (role !== process.env.REACT_APP_SYSTEM_THIRD_ROLE) {
    return (
      <>
        <div className={`card ${className}`}>
          <div className='card-body'>
            {/* begin::Header */}
            <div className='card-title align-items-start flex-column'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Screenshot</span>
                </h3>

                <div className='d-flex align-items-center'>
                  <label className='mx-5 fs-5 fw-bold'>
                    <span className='fw-bolder'>TimeZone - </span>
                    {userTimeZone}
                  </label>

                  <div
                    style={{width: '200px'}}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Member'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      value={optionSelectedMember}
                      options={memberOption}
                      placeholder='Select Member'
                      onChange={(item: any) => {
                        if (
                          SelectedUser.selectedUserId !== item.value &&
                          (SelectedUser.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                            SelectedUser.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
                            SelectedUser.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE)
                        ) {
                          dispatch(
                            userInfoActions.updateSelectedUser({
                              selectedUserName: item.label,
                              selectedUserId: item.value,
                            })
                          )
                        }
                        setOptionSelectedMember(item)
                        SendDataOfUser(item.value, members)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>

                  <div className='d-flex'>
                    {SingleDatePickerComponent(startDate, handleDateChange, 'form-control ss_date')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loadloader && colorLegends.length > 0 && (
            <div className='d-flex justify-content-center mt-3 w-100 px-5'>
              <div className='d-flex py-1 border-primary border rounded'>
                <label className='mx-5 fs-5 fw-bold'>
                  <span className='fw-bolder'>Activity Level -</span>
                </label>
                {loadloader &&
                  colorLegends?.map((item) => (
                    <div
                      key={item.id}
                      className='d-flex justify-content-center align-items-center mx-5'
                    >
                      <span
                        className='d-flex justify-content-center align-items-center'
                        style={{
                          width: '22px',
                          height: '20px',
                          borderRadius: '68px',
                          background: `${item.colorCode}`,
                          textShadow: '2px 3px 2px black',
                        }}
                      ></span>
                      <span className='d-flex align-items-center' style={{marginLeft: '7px'}}>
                        {`${item.minValue} - ${item.maxValue === 0 ? 'Max' : item.maxValue}`}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {loadloader && (
            <div style={{zIndex: '0', padding: '10px 30px'}}>
              <ImageGallery
                items={path}
                thumbnailPosition='left'
                showPlayButton={true}
                showFullscreenButton={true}
                showIndex={true}
                lazyLoad={true}
                disableThumbnailScroll={true}
                slideOnThumbnailOver={false}
                flickThreshold={0.5}
                stopPropagation={true}
                onImageLoad={() => {
                  setSpinner(false)
                }}
                //onImageLoad={handleImageLoad}
                renderThumbInner={(item) => (
                  <div>
                    <div style={{position: 'relative'}}>
                      <img
                        src={item.thumbnail}
                        alt={item.originalAlt}
                        style={{maxWidth: '100%', maxHeight: '100%'}}
                        onLoad={() => setSpinner(false)}
                      />
                      {showRenderThumb && (
                        <span
                          className='d-flex justify-content-around align-items-center py-1 '
                          style={{
                            background: 'rgba(0, 0, 0, 0.5)',
                            color: '#fff',
                          }}
                        >
                          <div
                            className='d-flex'
                            style={{
                              width: '10px',
                              height: '10px',
                              borderRadius: '50%',
                              border: '1px solid white',
                              backgroundColor: `${item.thumbnailClass}`,
                            }}
                          ></div>
                          <div className=''>{item.description?.split('-')[1].trim()}</div>
                        </span>
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          )}

          {showNorRecordFound && (
            <h2
              style={{
                fontSize: '1.6rem',
                color: '#cccccc',
                fontWeight: '600',
                textAlign: 'center',
                display: 'block',
              }}
            >
              No Screenshots Found
            </h2>
          )}
        </div>
        {spinner && Loader('100px')}

        <style>
          {`
                        .image-gallery-thumbnails{

                           overflow-y:auto
                        }

                        .image-gallery-thumbnails::-webkit-scrollbar-thumb {
                            background-color: #009EF7 !important;
                        }

                        .image-gallery-thumbnails::-webkit-scrollbar-thumb:hover {
                            background-color: #009EF7 !important;
                        }
                    `}
        </style>
      </>
    )
  } else {
    return (
      <>
        <div className={`card ${className}`}>
          <div className='card-body'>
            {/* begin::Header */}
            <div className='card-title align-items-start flex-column'>
              <div className='card-header border-0 pt-5'>
                <div className='d-flex justify-content-center'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Screenshot</span>
                  </h3>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                  <label className='mx-5 fs-5 fw-bold'>
                    <span className='fw-bolder'>TimeZone - </span>
                    {userTimeZone}
                  </label>
                  {memberOption.length > 1 && (
                    <div
                      style={{width: '200px'}}
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title='Search Member'
                    >
                      <Select
                        className='selectDropdown'
                        components={makeAnimated()}
                        value={optionSelectedMember}
                        options={memberOption}
                        placeholder='Select Member'
                        onChange={(item: any) => {
                          setOptionSelectedMember(item)
                          SendDataOfUser(item.value, members)
                        }}
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                      />
                    </div>
                  )}

                  <div>
                    {SingleDatePickerComponent(startDate, handleDateChange, 'form-control ss_date')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loadloader && colorLegends.length > 0 && (
            <div className='d-flex justify-content-center mt-3 w-100 px-5'>
              <div className='d-flex py-1 border-primary border rounded'>
                <label className='mx-5 fs-5 fw-bold'>
                  <span className='fw-bolder'>Activity Level -</span>
                </label>
                {loadloader &&
                  colorLegends?.map((item) => (
                    <div
                      key={item.id}
                      className='d-flex justify-content-center align-items-center mx-5'
                    >
                      <span
                        className='d-flex justify-content-center align-items-center'
                        style={{
                          width: '22px',
                          height: '20px',
                          borderRadius: '68px',
                          background: `${item.colorCode}`,
                          textShadow: '2px 3px 2px black',
                        }}
                      ></span>
                      <span className='d-flex align-items-center' style={{marginLeft: '7px'}}>
                        {`${item.minValue} - ${item.maxValue === 0 ? 'Max' : item.maxValue}`}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {loadloader && (
            <div style={{zIndex: '0', padding: '10px 30px'}}>
              <ImageGallery
                items={path}
                thumbnailPosition='left'
                showPlayButton={true}
                showFullscreenButton={true}
                showIndex={true}
                lazyLoad={true}
                disableThumbnailScroll={true}
                slideOnThumbnailOver={false}
                showNav={true}
                onImageLoad={() => {
                  setSpinner(false)
                }}
                renderThumbInner={(item) => (
                  <div>
                    <div style={{position: 'relative'}}>
                      <img
                        src={item.thumbnail}
                        alt={item.originalAlt}
                        style={{maxWidth: '100%', maxHeight: '100%'}}
                        onLoad={() => setSpinner(false)}
                      />
                      {showRenderThumb && (
                        <span
                          className='d-flex justify-content-around align-items-center py-1 '
                          style={{
                            background: 'rgba(0, 0, 0, 0.5)',
                            color: '#fff',
                          }}
                        >
                          <div
                            className='d-flex'
                            style={{
                              width: '10px',
                              height: '10px',
                              borderRadius: '50%',
                              border: '1px solid white',
                              backgroundColor: `${item.thumbnailClass}`,
                            }}
                          ></div>
                          <div className=''>{item.description?.split('-')[1].trim()}</div>
                        </span>
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          )}

          {showNorRecordFound && (
            <h2
              style={{
                fontSize: '1.6rem',
                color: '#cccccc',
                fontWeight: '600',
                textAlign: 'center',
                display: 'block',
              }}
            >
              No Screenshots Found
            </h2>
          )}
        </div>
        {spinner && Loader('100px')}
        <style>
          {`
                        .image-gallery-thumbnails{

                           overflow-y:auto
                        }

                        .image-gallery-thumbnails::-webkit-scrollbar-thumb {
                            background-color: #009EF7 !important;
                        }

                        .image-gallery-thumbnails::-webkit-scrollbar-thumb:hover {
                            background-color: #009EF7 !important;
                        }
                    `}
        </style>
      </>
    )
  }
}

export default ListsWidget6
