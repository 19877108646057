import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import axios from 'axios'
import dayjs from 'dayjs'
import {useNavigate} from 'react-router-dom'

import {SingleDatePickerComponent, AlertModal} from '../../CommonFunctions/CommonFunction'
import {getMaxDate} from '../Common_Function/Function'

const API = process.env.REACT_APP_API_URL

const AddHoliday = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState<any>(new Date())
  const [nextYear, setNextYear] = useState(0)

  const initialValues = {
    holidayName: '',
    holidayDate: dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
    organizationId: localStorage.getItem('org_Id'),
    createdBy: localStorage.getItem('name'),
    createdDate: dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
  }

  const addholidayschema = Yup.object().shape({
    holidayName: Yup.string().required('Enter name'),
    holidayDate: Yup.date().required('Date Field is required.'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: addholidayschema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      values.holidayName = values.holidayName.trim()
      axios
        .post(`${API}/HolidayCalendar/AddHoliday`, values)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            if (res.data.success) {
              resetForm()
              setStartDate(null)
              setLoading(false)
              navigate('/holiday')
            }
          })
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setSubmitting(false)
        })
      setLoading(false)
    },
    validate: (values) => {
      let errors: any = {}
      if (values.holidayDate == '') {
        errors.holidayDate = 'select date'
      }
      return errors
    },
  })

  const handleDateChange = (dates: any) => {
    setStartDate(dates)
    formik.setFieldValue('holidayDate', dayjs(dates).format('YYYY-MM-DDT00:00:00[Z]'))
  }

  useEffect(() => {
    getMaxDate(setNextYear)
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        style={{pointerEvents: 'none'}}
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Holiday Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Name'
                  {...formik.getFieldProps('holidayName')}
                />
                {formik.touched.holidayName && formik.errors.holidayName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.holidayName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Date</span>
              </label>

              <div className='col-lg-8 fv-row'>
                {SingleDatePickerComponent(
                  startDate,
                  handleDateChange,
                  'form-control single_date',
                  new Date(nextYear, 11, 31),
                  new Date()
                )}

                {formik.touched.holidayDate && formik.errors.holidayDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.holidayDate}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => navigate('/holiday')}
            >
              Cancel
            </button>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && <span className='indicator-label'>Create</span>}
              {loading && (
                <span className='d-block indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {AddHoliday}
