/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import dayjs, {Dayjs} from 'dayjs'
import {
  Loader,
  RowsPerPage,
  ErrorModal,
  generateDatePresets,
} from '../../CommonFunctions/CommonFunction'
import {
  GetAllActivePartner,
  GetPendingCommissionDetailByPartnerId,
} from '../../services/partner.service'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {DatePicker} from 'antd'
import {AddPendingCommissionDetails} from './PendingCommissionDetail'
const {RangePicker} = DatePicker

const PendingCommission = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [loading, _] = useState(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const [list, __] = useState<any>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [___, setForcePage] = useState<any>(null)
  const [totalAmount, setTotalAmount] = useState(0)
  const [pendingCommission, setPendingCommission] = useState<any[]>([])
  const [selectedPartner, setSelectedPartner] = useState<any>(null)
  const [partnerList, setPartnerList] = useState<any[]>([])
  const presets = generateDatePresets()

  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    GetAllPartnerData()
  }, [])

  useEffect(() => {
    getAllPendingRequest()
  }, [selectedPartner, startDate, endDate])

  const GetAllPartnerData = async () => {
    try {
      setSpinner(true)
      const allPartner = await GetAllActivePartner()
      const newList = allPartner.data.map((item: any) => ({
        label: item.partnerName,
        value: item.partnerId,
      }))
      setPartnerList(newList)
      setSelectedPartner(newList[0])
      setSpinner(false)
    } catch (error) {
      ErrorModal()
    }
  }

  const getAllPendingRequest = async () => {
    try {
      setSpinner(true)
      if (selectedPartner !== null && startDate !== null) {
        let body = {
          partnerId: selectedPartner.value,
          fromDate: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          toDate:
            endDate !== null
              ? dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss[Z]')
              : dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        }

        const allPendingCommission = await GetPendingCommissionDetailByPartnerId(body)
        setPendingCommission(allPendingCommission.data)
        setSpinner(false)
      }
    } catch (error) {
      ErrorModal()
      setSpinner(false)
    }
  }

  const handlePartnerChange = (item: any) => {
    setSelectedPartner(item)
  }

  useEffect(() => {
    const thisMonth = [dayjs().startOf('month'), dayjs()] as [dayjs.Dayjs, dayjs.Dayjs]
    handleDateChange(thisMonth)
  }, [])

  const handleDateChange = (dates: any) => {
    if (dates) {
      setStartDate(dates[0])
      setEndDate(dates[1])
    }
  }

  const handleClick = (e: any, amount: any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id))
      const newTotalAmount = parseFloat((totalAmount - amount).toFixed(2))
      setTotalAmount(newTotalAmount)
    } else {
      setTotalAmount(parseFloat((totalAmount + amount).toFixed(2)))
    }
  }

  const modalClose = () => {
    setIsCheck([])
    setTotalAmount(0)
    setIsModalOpen(false)
    getAllPendingRequest()
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 190, // Set your desired width here
    }),
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Partner Commission Payment</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
          <div style={{display: 'flex'}}>
            {spinner ? (
              ''
            ) : (
              <div
                className='d-flex align-items-center card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Search Organization'
              >
                <div style={{marginRight: '1rem'}}>
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    value={selectedPartner}
                    options={partnerList}
                    placeholder='Select partner...'
                    onChange={handlePartnerChange}
                    isClearable={false}
                    isSearchable
                    closeMenuOnScroll
                    noOptionsMessage={() => 'No options available'}
                    styles={customStyles}
                  />
                </div>
                <RangePicker
                  value={[startDate, endDate]}
                  onChange={handleDateChange}
                  style={{height: '2.9rem', marginTop: '0.4rem'}}
                  presets={presets}
                />
              </div>
            )}
          </div>
        </div>
        {spinner && Loader('0px')}
        {!spinner && pendingCommission.length == 0 && (
          <h2 className='noRecordFound'>No Records Found</h2>
        )}

        {!spinner && pendingCommission.length > 0 && (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder'>
                    <th></th>
                    <th className='min-w-25px'>Select</th>

                    <th className='min-w-125px'>Organization</th>
                    <th className='min-w-125px'>Organization Payment Date</th>
                    <th className='min-w-100px'>Currency</th>
                    <th className='min-w-100px'>Commission Amount</th>
                    <th></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {pendingCommission.length > 0 &&
                    pendingCommission.map((data, index) => (
                      <tr key={index}>
                        <td></td>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-13-check'
                              key={data.id}
                              type='checkbox'
                              id={data.id}
                              onChange={(e) => handleClick(e, data.commissionAmount)}
                              checked={isCheck.includes(data.id)}
                            />
                          </div>
                        </td>
                        <td>
                          <span className='text-dark  d-block fs-6 '>{data.organisationName}</span>
                        </td>
                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {dayjs(data.organizationPaymentDate).format('DD/MM/YYYY')}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark   d-block fs-6'>{data.currency}</span>
                        </td>
                        <td>
                          <span className='text-dark   d-block fs-6'>{data.commissionAmount}</span>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>

            {list.length > 10 && (
              <div className='d-flex align-items-center justify-content-center position-relative mb-3'>
                <div style={{left: '2rem', position: 'absolute', top: '0'}}>
                  {RowsPerPage(
                    setForcePage,
                    setRowPerPage,
                    setItemOffset,
                    rowPerPage,
                    list.length,
                    '0px'
                  )}
                </div>
              </div>
            )}

            {/* end::Table */}

            <style>
              {`

                        .custom-select-class{
                          text-align: center;
                          width: 8rem;
                        }

                        .select-custom-header{
                          margin-right: 1rem;
                          line-height: 3rem;
                        }

                        .pagination-custom-class{

                        }

                        
                    `}
            </style>

            {/* end::Table container */}
          </div>
        )}

        <div className='d-flex justify-content-end py-3 px-9'>
          {!spinner && (
            <div
              className='alert alert-warning d-flex align-items-center justify-content-center'
              role='alert'
              style={{fontSize: 'small', fontWeight: '700', marginRight: '1rem'}}
            >
              Amount - {totalAmount}
            </div>
          )}
          {spinner ? (
            ''
          ) : (
            <button
              type='button'
              className='btn btn-primary mb-3'
              disabled={isCheck.length == 0}
              onClick={() => setIsModalOpen(true)}
            >
              {!loading && <span className='indicator-label'>Add</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>

        {/* begin::Body */}
      </div>
      {isModalOpen && (
        <AddPendingCommissionDetails
          modalClose={modalClose}
          partnerId={selectedPartner.value}
          commissionId={isCheck}
          commissionAmount={totalAmount}
        />
      )}
    </>
  )
}

export {PendingCommission}
