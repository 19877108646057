import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {AlertModal} from '../../CommonFunctions/CommonFunction'
import * as Yup from 'yup'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'

type PopupProps = {
  onClose: any
  updateStatus?: any
  getList: any
  data: any
}

const SuperAdminTrialExtensionPopUp: React.FC<PopupProps> = ({data, onClose, getList}) => {
  const API_URL = process.env.REACT_APP_API_URL
  const [startDate, setStartDate] = useState(new Date())
  const [minDate, setMinDate] = useState(new Date())

  useEffect(() => {
    updateStartDate()
    formik.setFieldValue(`organizationId`, data.organizationId)
  }, [])

  const updateStartDate = () => {
    const currentDate = new Date()
    const trialEndDateDate = new Date(data.trialEndDate)

    // Check if current date is greater than trialEndDate
    if (currentDate.getTime() > trialEndDateDate.getTime()) {
      // If current date is greater, update startDate to current date
      formik.setFieldValue('trialEndDate', dayjs(currentDate).format())
      setStartDate(currentDate)
      setMinDate(currentDate)
    } else {
      // Otherwise, update startDate to trialEndDate
      formik.setFieldValue('trialEndDate', dayjs(trialEndDateDate).format())
      setStartDate(trialEndDateDate)
      setMinDate(trialEndDateDate)
    }
  }

  const handleIconClick = () => {
    onClose(false)
  }

  const handleDateChange = (dates: any) => {
    setStartDate(new Date(dates))
    formik.setFieldValue('trialEndDate', dayjs(dates).format())
  }

  const trialExtend = Yup.object().shape({
    // trialEndDate: Yup.date().required('date is required'),
    responseReason: Yup.string().max(200, 'Maximum 200 character').required('Comment is required'),
  })

  const initialValues = {
    organizationId: '',
    trialEndDate: '',
    responseReason: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: trialExtend,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      axios
        .post(`${API_URL}/OrganizationTrialExtenstion/TrialExtensionRequestBySuperAdmin`, values)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'error',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            getList()
            handleIconClick()
          })
        })
        .catch((err) => console.error(err))
        .finally(() => resetForm())
    },
  })

  return (
    <div className='modal-container d-flex'>
      <div style={{margin: 'auto', paddingLeft: '200px'}}>
        <div
          className='card mt-3 modal-innerContainer'
          style={{padding: '0rem 2.5rem 2rem 2.5rem'}}
        >
          <div className='card mt-5'>
            <form onSubmit={formik.handleSubmit} className='extension-extension-form'>
              <div className='d-flex justify-content-evenly'>
                <p className='extension-form-title'>Enter Details</p>
                <i
                  className='fa fa-times'
                  onClick={() => handleIconClick()}
                  aria-hidden='true'
                  style={{
                    fontSize: '1.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '-103px',
                    cursor: 'pointer',
                  }}
                ></i>
              </div>

              <div>
                <span className='extension-span'>End Date</span>
                <DatePicker
                  className='form-control custom-Height'
                  dateFormat='dd/MM/yyyy'
                  onChange={(date: any) => {
                    handleDateChange(date)
                  }}
                  selected={startDate}
                  isClearable={false}
                  placeholderText='Select a Date'
                  minDate={minDate}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                />
              </div>

              <div className='mt-3'>
                <span className='extension-span'>Comment</span>
                <div className='extension-input-container'>
                  <textarea
                    className='form-control form-control-border'
                    placeholder='Comment'
                    {...formik.getFieldProps('responseReason')}
                  />
                  {formik.touched.responseReason && formik.errors.responseReason && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.responseReason}</div>
                    </div>
                  )}
                </div>
              </div>

              <button className='extension-submit mt-3' type='submit'>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <style>{`
            .modal-container {
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 1000;
              animation: fadeIn 0.5s ease;
              
            }
  
            #div-scroller::-webkit-scrollbar-thumb {
              background-color: #C1C1C1 !important;
            }
            #div-scroller::-webkit-scrollbar {
              width:0.5rem
            }
  
  
  
            .modal-innerContainer {
              
              margin: 0 auto;
              background-color: #fff;
              border-radius: 6px;
              padding: 20px;
              position: relative;
              width:100%;
            }
  
          .custom-modal-header{
              padding-right: 29rem;
              font-size: 1.8rem;
              font-weight: 700;
          }
  
          .paginationBttns{
              width:23rem;
              margin: 2rem auto 0 auto;
          }
  
          .temp-class{
              padding: 0.5rem;
              width: 8rem;
              margin: 0;
              justify-content:center;
              margin:unset
          }
  
  
          `}</style>
    </div>
  )
}

export default SuperAdminTrialExtensionPopUp
