import axios from 'axios'
import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import {ChartReact} from './Chart'
import duration from 'dayjs/plugin/duration'

const API = process.env.REACT_APP_API_URL

const DisplayProjectAndTask: React.FC = () => {
  dayjs.extend(duration)

  const [projects, setProjects] = useState<any[]>([])

  useEffect(() => {
    axios
      .get(`${API}/Projects/GetAllProjectByOrganisation`, {
        params: {
          id: localStorage.getItem('org_Id'),
        },
      })
      .then((res) => {
        setProjects(res.data)
      })
  }, [])

  return (
    <>
      <div className={`card card-xxl-stretch mb-5 mb-xl-10 mt-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Project</span>
          </h3>
          <div className='d-flex align-items-center justify-content-end'></div>
        </div>
      </div>
      <div className='row g-6 g-xl-9 mb-5'>
        {projects.map((item: any) => (
          <div key={item.projectName} className='col-xl-4'>
            <div className='card-body p-0 bg-white'>
              <div
                className='px-9 pt-7 card-rounded h-275px w-100'
                style={{border: '2px solid black'}}
              >
                <div className='d-flex flex-stack'>
                  <h3 className='m-0 fw-bolder fs-2' style={{color: 'rgb(17,17,100)'}}>
                    {item.projectName}
                  </h3>
                </div>
                <div>
                  <ChartReact
                    series={[
                      dayjs.duration(item.expectedHours, 'hours').asSeconds(),
                      item.loggedHours,
                    ]}
                    option={['Expected Time', 'Time Spend']}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export {DisplayProjectAndTask}
