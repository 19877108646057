import * as auth from '../../app/modules/auth/redux/AuthRedux'
import {AlertModal} from '../../app/CommonFunctions/CommonFunction'
export const errorAxios = (axios: any, store: any) => {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.response.use(
    (config: any) => {
      //console.log("config in error", config)
      return config
    },
    (err: any) => {
      if (err.response.status === 401) {
        store.dispatch(auth.actions.logout())
        localStorage.clear()
        AlertModal(`Token has expired`, '', 'warning', false, '#7066E0', 'Ok')
      }
      return Promise.reject(err)
    }
  )
}
