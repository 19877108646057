import React from 'react'

import {Link, Outlet, useLocation} from 'react-router-dom'
import '../../../css/ProductivityManager.css'

const OrganizationWrapper: React.FC = () => {
  const location = useLocation()
  return (
    <>
      <div className='card mb-5'>
        <div className='card-body pt-3' style={{padding: '0px 25px'}}>
          <div className='d-flex'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={`nav-link me-6 ${
                    location.pathname === '/organization/active' ||
                    location.pathname === '/organization'
                      ? 'text-dark active-wrapper'
                      : 'text-active-primary fw-bolder'
                  }`}
                  to='/organization/active'
                >
                  Active
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`nav-link me-6 ${
                    location.pathname === '/organization/inactive'
                      ? 'text-dark inactive-wrapper'
                      : 'text-active-primary fw-bolder'
                  } `}
                  to='/organization/inactive'
                >
                  Inactive
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export {OrganizationWrapper}
