import React, {useState} from 'react'
import {StatisticsWidget5work} from '../../../_metronic/partials/widgets'
import {ErrorModal} from '../../CommonFunctions/CommonFunction'
import {UserList} from '../layout-builder/BuilderPageAdmin.poup'

interface Props {
  time: any
  color: any
  title: any
  description: any
  bgcolor: string
  img?: string
  action?: any
  date?: any
  depart?: any
  rawData?: any
}

const AdminStats: React.FC<Props> = (props: Props) => {
  const clickableFields = ['Total Members', 'Active Members', 'Offline Members']
  const [members, setMembers] = useState(null)
  const [popupTitle, setPopupTitle] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const currentUrl = window.location.pathname

  const isClickable =
    clickableFields.includes(props.title) &&
    props.time !== 0 &&
    (currentUrl === '/adminDashboard' || currentUrl === '/overviewDashboard')
  const cursorType = isClickable ? 'cursor-pointer' : 'cursor-default'

  const handleTileClick = (title: string) => {
    try {
      setPopupTitle(title)
      let membersData = null
      switch (title) {
        case 'Total Members':
          membersData = props.rawData.allMembers
          break
        case 'Active Members':
          membersData = props.rawData.activeMembers
          break
        case 'Offline Members':
          membersData = props.rawData.offlineMembers
          break
        default:
          membersData = null
      }

      if (membersData && membersData.length > 0) {
        setMembers(membersData)
        setIsModalVisible(true)
      } else {
        setMembers(null)
        setIsModalVisible(false)
      }
    } catch (error) {
      ErrorModal()
    }
  }

  const closeHandler = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <div className='g-5 g-xl-8 redirect' style={{display: 'contents'}}>
        <div
          className='col-xl-2'
          onClick={() => isClickable && handleTileClick(props.title)}
          style={{cursor: isClickable ? 'pointer' : 'default'}}
        >
          <StatisticsWidget5work
            date={props.date}
            depart={props.depart}
            action={props.action}
            className='card-xs-stretch mb-xs-8'
            bgcolor={props.bgcolor}
            color={props.color}
            iconColor='white'
            img={props.img}
            descriptions={props.title}
            title={props.time}
            description={props.description}
            cursorType={cursorType}
          />
        </div>
      </div>
      {isModalVisible && (
        <UserList
          title={popupTitle}
          members={members}
          onClose={closeHandler}
          selectedDate={props.date}
        />
      )}
    </>
  )
}

export {AdminStats}
