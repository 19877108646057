import React, {useState, useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  ErrorModal,
  addEditGroupAdminFrom,
  baseGroupAdminSchema,
  fillCompanyDropDown,
  submitAddEditGroupAdmin,
} from '../../CommonFunctions/CommonFunction'

import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'

const letters = /^[a-zA-Z][a-zA-Z ]*$/

const EditGroupAdminDetails: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [companiesOptions, setCompaniesOptions] = useState<any[]>([])
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [searchParams, _] = useSearchParams()

  const GroupAdmin: any = useSelector<RootState>(({data}) => data.groupAdmin, shallowEqual)

  const groupAdminSchema = baseGroupAdminSchema.shape({
    groupAdminName: Yup.string()
      .matches(letters, 'numerals and symbols are not allowed')
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Full Name is required'),
  })

  const initialValues = {
    groupAdminId: '',
    email: '',
    groupAdminName: '',
    organizationIds: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: groupAdminSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      const {email, ...updatedValues} = values

      submitAddEditGroupAdmin(
        setLoading,
        updatedValues,
        navigate,
        resetForm,
        setSubmitting,
        'Registration/UpdateGroupAdmin'
      )
    },
  })

  useEffect(() => {
    fillCompanyDropDown(setCompaniesOptions)
    getUserDetails()
  }, [])

  const getUserDetails = () => {
    try {
      const userId = searchParams.get('user')
      const selectedUser = GroupAdmin.find((user: any) => user.groupAdminId === userId)
      const organizationIds =
        selectedUser?.organizations?.map((org: any) => org.organizationId) || []

      const selectedOptionsList = selectedUser.organizations.map((org: any) => ({
        label: org.organizationName,
        value: org.organizationId,
      }))

      setSelectedOptions(selectedOptionsList)

      formik.setFieldValue('organizationIds', organizationIds)

      formik.setFieldValue('email', selectedUser?.groupAdminEmail || '')
      formik.setFieldValue('groupAdminName', selectedUser?.groupAdminName || '')
      formik.setFieldValue('groupAdminId', selectedUser?.groupAdminId || '')
    } catch (error) {
      ErrorModal().then(() => {
        navigate('/allGroupAdmin')
      })
    }
  }

  const params = {
    type: 'Edit',
    formik,
    selectedOptions,
    companiesOptions,
    setSelectedOptions,
    loading,
    navigate,
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0' role='banner'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Group Admin Details</h3>
        </div>
      </div>

      <div className='collapse show'>{addEditGroupAdminFrom(params)}</div>
    </div>
  )
}

export default EditGroupAdminDetails
