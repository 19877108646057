import React, {useState, useEffect} from 'react'

import * as Yup from 'yup'
import {AlertModal, baseSchema} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'
import {useFormik} from 'formik'
import {useNavigate} from 'react-router-dom'
import dayjs from 'dayjs'
import CompanyDetailsForm from './CompanyDetailsForm'

const API = process.env.REACT_APP_API_URL

const initialValues = {
  organizationName: '',
  adminName: '',
  org_Address: '',
  org_Email: '',
  org_ContactNo: '',
  TaxNumber: '',
  emp_Strength: '',
  createdDate: dayjs().format('DD-MM-YYYY'),
  createdBy: localStorage.getItem('name'),
  ContractDocFile: '',
}

export function ComapanyDetails() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const companySchema = baseSchema.shape({
    adminName: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Admin Name is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: companySchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setLoading(false)

      setTimeout(() => {
        axios
          .post(`${API}/Organization/RegisterOrganization`, values, {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          })
          .then((res) => {
            AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok').then(() => {
              resetForm()

              setLoading(false)
              navigate('/organization')
            })
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            setSubmitting(false)
            setStatus('Registration process has broken')
          })
      }, 0)
    },
  })
  useEffect(() => {
    setSpinner(true)
  }, [])

  return <CompanyDetailsForm formType='Add' formik={formik} loading={loading} />
}
