import React from 'react'
import RangeSlider from 'react-rangeslider'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js';

type Props = {
    title: string,
    fieldName: string,
    value: any,
    updateValue: any,
    max?: any,
    min?: any,
    tippy?: any
}

const RowWithSlider: React.FC<Props> = ({ title, value, updateValue, fieldName, max = 100, min = 1, tippy=false }) => {

    return (
        <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                {title}
                {
                    tippy &&
                    (
                        <Tippy
                            content={tippy}
                            followCursor={true}
                            plugins={[followCursor]}>
                            <span>
                                <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                            </span>
                        </Tippy>
                    )
                }

            </label>

            <div className='col-lg-8 fv-row'>
                <RangeSlider
                    value={Number(value)}
                    onChange={(e: any) => updateValue(fieldName, e)}
                    min={min}
                    max={max}
                    step={1}
                    labels={{
                        [min]: value <= min ? value : min,
                        [value]: value,
                        [max]: value >= max ? value : max,
                    }}
                ></RangeSlider>
                {/* 
      {dataformik.touched.screenshot_Interval && dataformik.errors.screenshot_Interval && (
        <div className='fv-plugins-message-container'>
          <div className='text-danger mt-2'>{dataformik.errors.screenshot_Interval}</div>
        </div>
      )} */}
            </div>
        </div>
    )
}

export default RowWithSlider