import axios, {CancelTokenSource} from 'axios'
const API_URL = process.env.REACT_APP_API_URL

let cancelTokenSource: CancelTokenSource | null = null

export function GetKeyboardMouseStrokes(UserId: string, FromDate: string, ToDate: string) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }

  const OrganizationId = localStorage.getItem('org_Id')

  cancelTokenSource = axios.CancelToken.source()

  return axios.get(`${API_URL}/KeyboardAndMouseStrokes/GetStrokes`, {
    params: {
      OrganizationId,
      UserId,
      FromDate: FromDate,
      ToDate: ToDate,
    },
    cancelToken: cancelTokenSource?.token,
  })
}

export function AddProductive(UserId: string, ProductiveAppAndUrl: string) {
  console.log('This is inside the add producitve')
  const OrganizationId = localStorage.getItem('org_Id')
  const body = {
    OrganizationId,
    UserId,
    ProductiveAppAndUrl,
  }

  console.log('The url or the app name is ', ProductiveAppAndUrl)

  return axios.get(`${API_URL}/AppUrlCatalogue/ConvertUnProductiveAppUrlToProductiveAppUrl`, {
    params: body,
  })
}

export function AddUnProductive(UserId: string, ProductiveAppAndUrl: string) {
  console.log('This is inside the add producitve')
  const OrganizationId = localStorage.getItem('org_Id')
  const body = {
    OrganizationId,
    UserId,
    ProductiveAppAndUrl,
  }

  console.log('The url or the app name is ', ProductiveAppAndUrl)

  return axios.get(`${API_URL}/AppUrlCatalogue/ConvertProductiveAppUrlToUnProductiveAppUrl`, {
    params: body,
  })
}
