import axios, {CancelTokenSource} from 'axios'
import dayjs from 'dayjs'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useState, useEffect} from 'react'
import {
  Loader,
  RowsPerPage,
  Pagination,
  MultiDateTimePicker,
  loadAssigeeAndMember,
  earlyStageCall,
} from '../../CommonFunctions/CommonFunction'
import moment from 'moment'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../setup'
import {userInfoActions} from '../../modules/auth'

const TimeEntriesReport: React.FC = () => {
  const API = process.env.REACT_APP_API_URL
  const SelectedUser: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  let cancelTokenSource: CancelTokenSource | null = null
  const role = localStorage.getItem('role')
  const [posts, setPosts] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [memberOption, setMemberOption] = useState<any>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [screenloader, setScreenLoader] = useState<any>(false)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [dateError, setDateError] = useState<any>(false)

  const SendDataOfUser = (userId: any) => {
    earlyStageCall(cancelTokenSource, setPosts, setScreenLoader, setItemOffset)

    fetchPosts(
      dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      endDate
        ? dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]')
        : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
      userId
    )
  }

  const SendDataOfDate = (start: any, end: any) => {
    setDateError(false)
    earlyStageCall(cancelTokenSource, setPosts, setScreenLoader, setItemOffset)

    if (start == null && end == null) {
      setDateError(true)
      setScreenLoader(false)
      return
    } else if (start && end == null) {
      end = start
    }

    fetchPosts(
      dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
      dayjs(end).format('YYYY-MM-DDT00:00:00[Z]'),
      optionSelectedMember.value
    )
  }

  const fetchPosts = (startDate: any, endDate: any, userId?: any) => {
    setScreenLoader(true)
    const body = {
      organizationId: localStorage.getItem('org_Id'),
      userId: userId ? userId : localStorage.getItem('userId'),
      fromDate: startDate ? startDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
      toDate: endDate ? endDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
    }
    cancelTokenSource = axios.CancelToken.source()
    axios
      .post(`${API}/ProjectTaskReport/GetTimeEntryReportForUser`, body, {
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setPosts(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setScreenLoader(false))
  }

  useEffect(() => {
    if (
      (SelectedUser.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
        SelectedUser.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
        SelectedUser.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) &&
      SelectedUser.selectedUserId
    ) {
      setOptionSelectedMember({
        label: SelectedUser.selectedUserName,
        value: SelectedUser.selectedUserId,
      })
    } else {
      setOptionSelectedMember({
        label: localStorage.getItem('name'),
        value: localStorage.getItem('userId'),
      })
    }

    loadAssigeeAndMember(role, setMemberOption)
  }, [])

  useEffect(() => {
    if (SelectedUser.selectedUserId) {
      fetchPosts(
        dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        SelectedUser.selectedUserId
      )
    } else {
      fetchPosts(dayjs().format('YYYY-MM-DDT00:00:00[Z]'), dayjs().format('YYYY-MM-DDT00:00:00[Z]'))
    }
  }, [])

  //Pagination
  let itemsPerPage: number = rowPerPage
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState<any>()

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(posts.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(posts.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, posts])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % posts.length
    setItemOffset(newOffset)
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(dates)
    setStartDate(start)
    setEndDate(end)
    SendDataOfDate(start, end)
  }

  const convertSecIntoHoursMinSec = (totalSeconds: any) => {
    const duration = moment.duration(totalSeconds, 'seconds')
    const hours = Math.floor(duration.asHours()).toString().padStart(2, '0')
    const formattedTime = moment.utc(totalSeconds * 1000).format(hours + ':mm')

    return formattedTime
  }

  return (
    <>
      <div className={`card card-xxl-stretch mb-5 mt-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1 justify-content-center'>
              Daily Time Entries
            </span>
          </h3>

          <div className='d-flex align-items-center justify-content-end'>
            {memberOption.length > 0 && (
              <div className='d-flex align-items-center'>
                <div>
                  <div
                    className='mx-5'
                    style={{width: '200px'}}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Member'
                  >
                    {memberOption.length > 1 && (
                      <Select
                        className='selectDropdown'
                        components={makeAnimated()}
                        value={optionSelectedMember}
                        options={memberOption}
                        placeholder='select member'
                        onChange={(item: any) => {
                          console.log('The role is ', SelectedUser.role)
                          if (
                            SelectedUser.selectedUserId !== item.value &&
                            (SelectedUser.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                              SelectedUser.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
                              SelectedUser.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE)
                          ) {
                            dispatch(
                              userInfoActions.updateSelectedUser({
                                selectedUserName: item.label,
                                selectedUserId: item.value,
                              })
                            )
                          }
                          setOptionSelectedMember(item)

                          SendDataOfUser(item.value)
                        }}
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                      />
                    )}
                  </div>
                </div>

                <div className='d-flex flex-column'>
                  {MultiDateTimePicker(
                    startDate,
                    endDate,
                    handleDateChange,
                    'form-control custom-Height'
                  )}

                  {dateError && (
                    <span className='text-danger' style={{marginLeft: '1rem'}}>
                      Please Select Date
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className='card-body py-3' style={{padding: '30px'}}>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4' style={{border: '1px solid '}}>
                <thead>
                  <tr className='fw-bolder' style={{background: '#728FCE', fontSize: '15px'}}>
                    <th className='min-w-100px text-center'>Date</th>
                    <th className='min-w-150px '>Project Name</th>
                    <th className='min-w-125px '>Task Name</th>
                    <th className='min-w-100px text-center'>Estimated Hours</th>
                    <th className='min-w-100px text-center'>Logged Hours</th>
                    <th className='min-w-125px text-center'>Start Time</th>
                    <th className='min-w-125px text-center'>End Time</th>
                    <th className='min-w-125px text-center'>Final Hours</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item: any, index: any) => (
                    <tr
                      key={item.id}
                      className={
                        index % 2 == 0
                          ? 'border-bottom bg-light-primary border border-dark'
                          : 'border border-dark'
                      }
                      style={{borderBottom: '1px solid !important'}}
                    >
                      <td className=' text-center '>
                        <span className='text-dark fs-6'>
                          {dayjs(item.recordDate).format('DD/MM/YYYY')}
                        </span>
                      </td>

                      <td>
                        <div>
                          <span className='text-dark fs-6'>{item.projectName}</span>
                        </div>
                      </td>

                      <td>
                        <div>
                          <span className='text-dark fs-6'>{item.taskName}</span>
                        </div>
                      </td>

                      <td className=' text-center '>
                        <span className='text-dark  fs-6'>
                          {item.isDefault ? '-' : item.taskEstimatedHours}
                        </span>
                      </td>

                      <td className=' text-center '>
                        <span className='text-dark  fs-6'>
                          {convertSecIntoHoursMinSec(item.taskLoggedHours).slice(0, 5)}
                        </span>
                      </td>

                      <td>
                        <tbody className='d-flex flex-column'>
                          {item.taskEntries?.map((item: any, index: any) => (
                            <tr
                              key={index}
                              className=' d-flex justify-content-center align-items-center w-100'
                              style={{height: '30px'}}
                            >
                              <td className=' text-center align-items-center p-0 d-flex'>
                                <span className='text-dark  fs-6'>
                                  {dayjs(item.startTime).format('HH:mm')}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </td>

                      <td>
                        <tbody className='d-flex flex-column'>
                          {item.taskEntries?.map((item: any, index: any) => (
                            <tr
                              key={index}
                              className=' d-flex align-items-center justify-content-center w-100'
                              style={{height: '30px'}}
                            >
                              <td className=' text-center p-0 d-flex'>
                                <span className='text-dark  fs-6 d-flex'>
                                  {dayjs(item.endTime).format('HH:mm')}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </td>

                      <td className=' text-center '>
                        <span className='text-dark  fs-6'>
                          {convertSecIntoHoursMinSec(item.newLoggedHours).slice(0, 5)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {screenloader && Loader('0')}
              {!screenloader && currentItems.length === 0 && (
                <h2 className='noRecordFound'>No Records Found</h2>
              )}
            </div>
            {posts.length > 10 && (
              <div className='d-flex align-items-center justify-content-center position-relative mb-7'>
                <div style={{left: '0', position: 'absolute', top: '0'}}>
                  {RowsPerPage(
                    setForcePage,
                    setRowPerPage,
                    setItemOffset,
                    rowPerPage,
                    posts.length,
                    '1rem'
                  )}
                </div>

                {posts.length > rowPerPage && Pagination(handlePageClick, pageCount, forcePage)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TimeEntriesReport
