/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import swal from 'sweetalert2'
import {AlertModal} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'



type Props = {
  className: string
}

const Project1: React.FC<Props> = ({className}) => {
  const API_URL = process.env.REACT_APP_API_URL
  const [projects, setProjects] = useState<any[]>([])

  const getproject = () => {
    axios
      .get(`${API_URL}/Projects/GetProjectByOrganisation`, {
        params: {
          id: localStorage.getItem('org_Id'),
        },
      })
      .then((res: any) => {
        setProjects(res.data)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getproject()
  }, [])

  const deleteHandler = (Props: any) => {
    
    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Delete'
    ).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .patch(`${API_URL}/Projects/DeleteProject?projectId=${Props}`)
          .then((res) => {
            getproject()
            AlertModal(
              res.data.message,
              '',
              res.data.success === false ? 'error' : 'success',
              false,
              '#7066E0',
              'Ok'
            )
          })
          .catch((err) => {
            console.error(err)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Project not deleted', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Projects</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a project'
        >
          <Link to='/project/addproject' className='btn btn-sm btn-primary me-3'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Add Project
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder '>
                <th className='w-45px'></th>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-140px'>Owner</th>
                <th className='min-w-100px text-center'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {projects.map((project) => (
                <tr key={project.projectId}>
                  <td></td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column '>
                        {project.projectName}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column '>
                        {project.projectOwnerName}
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className='d-flex justify-content-evenly flex-shrink-0'>
                      <Link
                        to='/editProject'
                        onClick={() => localStorage.setItem('editProject', project.projectId)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </Link>
                      <a
                        onClick={() => deleteHandler(project.projectId)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {Project1}
