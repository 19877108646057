/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import dayjs from 'dayjs'
import {KTSVG} from '../../../_metronic/helpers'
import '../../../css/website.css'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import {
  CheckPaymentSuccessOrFailure,
  DeletePendingUpgradeRequest,
  GetPendingChangePlanRequestByOrgId,
  getActivePlanByOrg,
} from '../../services/Payment.services'
import {
  AlertModal,
  checkPlanStatus,
  Loader,
  planDetails,
} from '../../CommonFunctions/CommonFunction'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../setup'
import {PlansPopUp} from './PlansPopUp'

import * as auth from '../../../app/modules/auth/redux/AuthRedux'
const MyPlan = () => {
  const role = localStorage.getItem('role')
  const SelectedUser: any = useSelector<RootState>(({data}) => data, shallowEqual)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pendingRequest, setPendingRequest] = useState<any>([])
  const [futurePlans, setFuturePlans] = useState<any>([])
  const [spinner, setSpinner] = useState(false)
  const [visibility, setVisibility] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [view, setView] = useState('')
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    checkPaymentStatus()
    getPendingUpgradeRequest()
    getFuturePaidPlans()
  }, [])

  const getFuturePaidPlans = () => {
    getActivePlanByOrg().then((res) => {
      if (res.data.length == 0) {
        setVisibility(true)
      } else if (Number(res.data[0].noOfUsers) !== 0) {
        setVisibility(Number(res.data[0].noOfUsers) <= Number(res.data[0].noOfActiveUsers))
      }
      //setPaymentDetails(res.data[0])

      setFuturePlans(res.data)
    })
  }

  const getPendingUpgradeRequest = async () => {
    try {
      const getAllPendingRequest = await GetPendingChangePlanRequestByOrgId()
      setPendingRequest(getAllPendingRequest.data)
    } catch (error) {
      AlertModal('Sorry', 'Something went wrong.', 'error', false, '#7066E0', 'Ok')
    }
  }

  const checkPaymentStatus = async () => {
    try {
      const sessionIdFromParams = searchParams.get('sessionId')
      const sessionIdFromStoage = localStorage.getItem('sessionId')

      if (sessionIdFromParams !== null && sessionIdFromParams === sessionIdFromStoage) {
        const status = await CheckPaymentSuccessOrFailure(sessionIdFromStoage)
        if (status.data.message === 'paid') {
          AlertModal('Hurray', 'Payment Successful.', 'success', false, '#7066E0', 'Ok').then(
            () => {
              localStorage.removeItem('sessionId')
              if (ConfigDetails.isSuspended) {
                dispatch(auth.actions.logout())
              } else {
                getPendingUpgradeRequest()
                getFuturePaidPlans()
                checkPlanStatus(ConfigDetails, dispatch, auth)
                planDetails(SelectedUser, dispatch)
              }
            }
          )
        } else {
          AlertModal('Sorry', 'Payment Unsuccessful.', 'error', false, '#7066E0', 'Ok').then(() => {
            localStorage.removeItem('sessionId')
          })
        }
      }
    } catch (error) {
      AlertModal('Sorry', 'Something went wrong.', 'error', false, '#7066E0', 'Ok')
    }
  }

  const getPercent = (value: any, range: any) => {
    return `${(value / range) * 100}%`
  }

  const getActiveCount = (activeUser: any, totalUser: any) => {
    const result = activeUser - totalUser

    if (result >= 0) {
      return `${result} Users remaining until your plan requires update`
    } else {
      return 'Your plan requires an update .'
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const deleteHandler = async (pendingId: string) => {
    try {
      const response = await DeletePendingUpgradeRequest(pendingId)

      AlertModal(
        response.data.message,
        '',
        response.data.success ? 'success' : 'warning',
        false,
        '#7066E0',
        'Ok'
      ).then(() => {
        getPendingUpgradeRequest()
      })
    } catch (error) {
      AlertModal('Sorry', 'Something went wrong.', 'error', false, '#7066E0', 'Ok')
    }
  }

  const handleUpdate = async () => {
    checkPaymentStatus()
    //getActivePlan()
    getPendingUpgradeRequest()
  }

  return (
    <>
      <div className='mx-5'>
        <div className='card mt-5'>
          <div className='mt-5 mb-5' style={{marginLeft: '30px'}}>
            {role === 'Admin' && (
              <h3
                className='card-title align-items-start flex-column'
                style={{marginBottom: 'unset'}}
              >
                <span className='card-label fw-bolder fs-2'>My Plan</span>
              </h3>
            )}
          </div>
        </div>

        {futurePlans.length == 0 && (
          <div className='card  mb-5 mb-xl-10 mt-5 p-10'>
            <div className='card-body'>
              {visibility && (
                <div>
                  <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-12 p-6'>
                    <i className='ki-duotone ki-information fs-2tx text-warning me-4'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>

                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-semibold'>
                        <h4 className='text-gray-900 fw-bolder fs-2'>We need your attention!</h4>
                        <div className='fs-4 text-gray-700'> You do not have any active plan. </div>
                      </div>
                    </div>
                  </div>
                  {!ConfigDetails.isSuspended && (
                    <div className='d-flex justify-content-end pb-0 px-0'>
                      <button
                        style={{marginLeft: '1rem'}}
                        className='btn btn-primary'
                        type='button'
                        data-bs-toggle='modal'
                        onClick={() => setView('Next')}
                        data-bs-target='#kt_modal_update_plan'
                      >
                        Pay for Next Cycle
                      </button>
                    </div>
                  )}
                </div>
              )}

              {ConfigDetails.isSuspended && (
                <div className='d-flex justify-content-end pb-0 px-0'>
                  <button
                    className='btn btn-primary'
                    style={{marginRight: '10px'}}
                    onClick={() => navigate('/auth')}
                  >
                    Back
                  </button>
                  <button
                    className='btn btn-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_update_plan'
                    onClick={() => setView('change')}
                  >
                    Choose Plan
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        {futurePlans?.length > 0 &&
          futurePlans?.map((data: any, index: any) => (
            <div className='card  mb-5 mb-xl-10 mt-5 p-10' key={data.paymentId}>
              <div className='card-body'>
                {visibility && index == 0 && (
                  <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-12 p-6'>
                    <i className='ki-duotone ki-information fs-2tx text-warning me-4'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>

                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-semibold'>
                        <h4 className='text-gray-900 fw-bold'>We need your attention!</h4>
                        <div className='fs-6 text-gray-700'>
                          {data?.paymentId === null
                            ? 'You do not have any active plan.'
                            : 'Your plans require an update. You have already registered the maximum number of users .'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className='row flex-row'>
                  <div className='w-50'>
                    <div className='row mb-6' style={{flexWrap: 'nowrap'}}>
                      <label className='col-lg-3  fw-bold fs-3'>
                        <span>Plan</span>
                      </label>
                      <label className='col-lg-3  fw-bold fs-3' style={{width: 'fit-content'}}>
                        <span>:</span>
                      </label>

                      <div className='col-lg-8  fv-row fs-3'>{data.billingPlanName}</div>
                    </div>
                    <div className='row mb-6' style={{flexWrap: 'nowrap'}}>
                      <label className='col-lg-3  fw-bold fs-3'>
                        <span>Amount</span>
                      </label>
                      <label className='col-lg-3  fw-bold fs-3 ' style={{width: 'fit-content'}}>
                        <span>:</span>
                      </label>

                      <div className='col-lg-8  fv-row fs-3'>{data.amount}</div>
                    </div>
                    <div className='row mb-6' style={{flexWrap: 'nowrap'}}>
                      <label className='col-lg-3  fw-bold fs-3'>
                        <span>Start Date</span>
                      </label>
                      <label className='col-lg-3  fw-bold fs-3 ' style={{width: 'fit-content'}}>
                        <span>:</span>
                      </label>

                      <div className='col-lg-8  fv-row fs-3'>
                        {dayjs(data.effectiveFrom).format('DD/MM/YYYY')}
                      </div>
                    </div>
                    <div className='row mb-6' style={{flexWrap: 'nowrap'}}>
                      <label className='col-lg-3  fw-bold fs-3'>
                        <span>End Date</span>
                      </label>
                      <label className='col-lg-3  fw-bold fs-3' style={{width: 'fit-content'}}>
                        <span>:</span>
                      </label>

                      <div className='col-lg-8  fv-row fs-3'>
                        {dayjs(data.terminationDate).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                  <div className='w-50'>
                    <div>
                      <div className='d-flex text-muted fw-bold fs-5 mb-3'>
                        <span className='flex-grow-1 text-gray-800'>Users</span>
                        <span className='text-gray-800'>
                          {data.noOfActiveUsers} of {data.noOfUsers} Used
                        </span>
                      </div>

                      <div className='progress h-8px bg-light-primary mb-2'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{
                            width: getPercent(data.noOfActiveUsers, data.noOfUsers),
                          }}
                          aria-valuenow={86}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        ></div>
                      </div>

                      <div className='fs-6 text-gray-600 fw-semibold mb-10'>
                        {getActiveCount(data.noOfUsers, data.noOfActiveUsers)}
                      </div>
                      {index == 0 && (
                        <div className='d-flex justify-content-end pb-0 px-0'>
                          <button
                            style={{marginLeft: '1rem'}}
                            className='btn btn-primary'
                            type='button'
                            data-bs-toggle='modal'
                            onClick={() => setView('Next')}
                            data-bs-target='#kt_modal_update_plan'
                          >
                            Pay for Next Cycle
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {Object.keys(pendingRequest).length > 0 && pendingRequest.newPlanId != null && (
          <div className='card mb-5 mb-xl-8 mt-5'>
            {/* begin::Header */}

            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Update Plan Request</span>
                <span className='text-muted mt-1 fw-bold fs-7'></span>
              </h3>
            </div>
            {spinner ? (
              Loader('0px')
            ) : (
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bolder'>
                        <th></th>
                        <th className='min-w-100px'>Current Plan</th>
                        <th className='min-w-100px'>New Plan</th>
                        <th className='min-w-100px'>Requested Date</th>
                        <th className='min-w-100px text-center'>Actions </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {pendingRequest.activePlanName}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {pendingRequest.newPlanName}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark d-flex mb-1 fs-6'>
                            {dayjs(pendingRequest.requestedDate).format('DD/MM/YYYY')}
                          </span>
                        </td>

                        <td className='d-flex flex-nowrap justify-content-center'>
                          <Tippy placement='top' content='Delete request'>
                            <a
                              onClick={() => deleteHandler(pendingRequest.id)}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className=' btn btn-icon btn-bg-light btn-active-color-primary btn-sm text-center'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </Tippy>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}

        <PlansPopUp
          onClose={handleCloseModal}
          view={view}
          planId={futurePlans[0]?.billingPlanId}
          handleUpdate={handleUpdate}
        />
      </div>
    </>
  )
}

export {MyPlan}
