import React, {useState, useRef} from 'react'
import axios from 'axios'
import {useFormik} from 'formik'
import {AlertModal, Loader} from '../../CommonFunctions/CommonFunction'
import * as Yup from 'yup'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'

const API_URL = process.env.REACT_APP_API_URL

const SupportForm: React.FC = () => {
  const UserDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [loader, setLoader] = useState<boolean>(false)
  const receiptRef: any = useRef()

  const initialValues = {
    OrganizationId: UserDetails.orgId,
    UserId: UserDetails.userId,
    TicketDescription: '',
    Attachements: [],
  }

  const planDetailsSchema = Yup.object().shape({
    TicketDescription: Yup.string()
      .min(3, 'Minimum 3 character')
      .max(300, 'Maximum 300 character')
      .required('Issue is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: planDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoader(true)
      const formData = new FormData()
      formData.append('OrganizationId', values.OrganizationId)
      formData.append('UserId', values.UserId)
      formData.append('TicketDescription', values.TicketDescription)

      for (let i = 0; i < values.Attachements.length; i++) {
        formData.append('Attachements', values.Attachements[i])
      }

      axios
        .post(`${API_URL}/Support/RaisedSupportTicket`, formData, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          resetForm()
          setLoader(false)
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          )
        })
        .catch((err) => {
          console.log(err)
          setLoader(false)
        })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Support Form</span>
          </h3>
        </div>
        {loader ? (
          Loader('0')
        ) : (
          <>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Issue</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-border '
                    placeholder='Please enter your issue'
                    {...formik.getFieldProps('TicketDescription')}
                  />
                  {formik.touched.TicketDescription && formik.errors.TicketDescription && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.TicketDescription}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Upload File</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    id='ReceiptFile'
                    accept='.png,.jpeg,.jpg'
                    className='form-control form-control-border '
                    onChange={(event: any) => {
                      const files = Array.from(event.currentTarget.files)
                      formik.setFieldValue('Attachements', files)
                    }}
                    onBlur={formik.handleBlur}
                    ref={receiptRef}
                    multiple
                  />
                  {formik.touched.Attachements && formik.errors.Attachements && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.Attachements}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loader}>
                {!loader && <span className='indicator-label'>Raise Issue</span>}
                {loader && (
                  <span className='indicator-progress d-block'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  )
}

export default SupportForm
