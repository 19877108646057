declare global {
  interface Window {
    dataLayer: any[]
    gtag: (...args: any[]) => void
  }
}

export const initializeGA = () => {
  window.dataLayer = window.dataLayer || []
  function gtag(...args: any[]) {
    window.dataLayer.push(args)
  }
  window.gtag = gtag
  gtag('js', new Date())
  gtag('config', 'G-KJ6KLY6Q36')
}

export const logPageView = (url: string) => {
  window.gtag('config', 'G-KJ6KLY6Q36', {
    page_path: url,
  })
}
