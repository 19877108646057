import React, {useState, useEffect} from 'react'

import '../../../css/website.css'

import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {Loader, ErrorModal} from '../../CommonFunctions/CommonFunction'

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import '../../../css/document.css'
import {GetAllPartner, GetPartnerContractDocByPartnerId} from '../../services/partner.service'
import {ViewDocument} from '../documents/DocumentViewPopUp'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'

const PartnerDocs = () => {
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [orgList, setOrgList] = useState<any[]>([])
  const [partnerList, setPartnerList] = useState<any[]>([])
  const [docFiles, setDocFiles] = useState<any>({})
  const [selectedOrg, setSelectedOrg] = useState<any>(null)
  const [selectedPartner, setSelectedPartner] = useState<any>(null)
  const [fileUrl, setFileUrl] = useState('')
  const [fileName, setFileName] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const partnerId = localStorage.getItem('userId')

  //to call the function to get the documents
  useEffect(() => {
    getAllDocument()
  }, [selectedPartner])

  //to call the function to get all the partners in case of admin
  useEffect(() => {
    try {
      if (ConfigDetails.role === 'Super Admin') {
        GetAllPartnerData()
      } else if (ConfigDetails.role === 'Partner') {
        let body = {
          label: ConfigDetails.userName,
          value: ConfigDetails.userId,
        }
        setSelectedPartner(body)
      }
    } catch (error) {
      ErrorModal()
    }
  }, [])

  //to get all the partners
  const GetAllPartnerData = async () => {
    try {
      const allPartner = await GetAllPartner()
      const newList = allPartner.data
        .filter((item: any) => item.isActive === true) // Filter only active partners
        .map((item: any) => ({
          label: item.partnerName,
          value: item.partnerId,
        }))
      setPartnerList(newList)
      setSelectedPartner(newList[0])
    } catch (error) {
      ErrorModal()
    }
  }

  //to get all the documents
  const getAllDocument = async () => {
    try {
      setSpinner(true)

      const {role, orgId} = ConfigDetails
      if (selectedPartner !== null) {
        let getAllDocs = await GetPartnerContractDocByPartnerId(selectedPartner.value)

        if (getAllDocs.data.documentFile !== undefined) {
          setDocFiles(getAllDocs.data.documentFile)
        }
      }
    } catch (error) {
      console.error('Failed to get all documents:', error)
      ErrorModal()
    } finally {
      setSpinner(false)
    }
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const convertByteToMb = (byte: any) => {
    try {
      const megabytes = byte / 1e6
      return megabytes.toFixed(2)
    } catch (error) {
      ErrorModal()
    }
  }

  const determineImageSrc = (fileName: string): string => {
    const extension = fileName.split('.').pop()?.toLowerCase()
    if (extension === 'png') {
      return '/media/icons/duotune/technology/image.png'
    } else if (extension === 'pdf') {
      return '/media/icons/duotune/technology/pdf.png'
    } else {
      return '/media/icons/duotune/technology/image.png'
    }
  }

  const handleDownload = (fileUrl: any, fileName: any) => {
    const downloadLink = document.createElement('a')
    downloadLink.href = fileUrl
    downloadLink.setAttribute('download', fileName)
    document.body.appendChild(downloadLink)

    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const handlePartnerChange = (item: any) => {
    setSelectedPartner(item)
    setDocFiles([])
    setSelectedOrg(null)
    setOrgList([])
  }

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px 16px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-2 mb-1'>Document</span>
            </h3>

            {(ConfigDetails.role === 'Super Admin' || ConfigDetails.role === 'Partner') && (
              <div className='d-flex'>
                {ConfigDetails.role === 'Super Admin' && (
                  <div style={{marginRight: '1rem'}}>
                    <div
                      className='d-flex align-items-center card-toolbar'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title='Search Organization'
                    >
                      <Select
                        components={makeAnimated()}
                        value={selectedPartner}
                        options={partnerList}
                        placeholder='Select partner...'
                        onChange={handlePartnerChange}
                        isClearable={false}
                        isSearchable
                        closeMenuOnScroll
                        noOptionsMessage={() => 'No options available'}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='card-body py-3 d-flex' style={{padding: '40px', flexWrap: 'wrap'}}>
          {spinner && Loader(0)}
          {!spinner &&
            docFiles !== undefined &&
            Object.keys(docFiles).length > 0 &&
            docFiles.fileName !== null && (
              <div className='partner-document'>
                <div>
                  <div className='partner-document__image-main'>
                    <div className='partner-document__image-container'>
                      <img
                        src={determineImageSrc(docFiles.fileName)} // Use the helper function here
                        height={28}
                        width={28}
                        className='svg-icon-1 partner-document__doc-type'
                        // alt='File type icon'
                      />
                    </div>
                    <div className='partner-document__file-container'>
                      <div className='partner-document__file-details'>
                        <span className='partner-document__file-name'>{docFiles.fileName}</span>
                        <span className='partner-document__file-size'>
                          {convertByteToMb(docFiles.fileSize)}mb
                        </span>
                      </div>
                      <div className='d-flex'>
                        <img
                          style={{cursor: 'pointer', marginRight: '0.5rem'}}
                          src={toAbsoluteUrl(`/media/icons/duotune/art/eye-view.svg`)}
                          height={20}
                          onClick={() => {
                            setFileUrl(docFiles.fileUrl)
                            setFileName(docFiles.fileName)
                            setModalOpen(true)
                          }}
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_VewDocument'
                        ></img>
                        <img
                          style={{cursor: 'pointer'}}
                          src={toAbsoluteUrl(`/media/icons/duotune/art/document-download.svg`)}
                          height={20}
                          onClick={() => handleDownload(docFiles.fileUrl, docFiles.fileName)}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        {!spinner && docFiles !== null && docFiles.fileName == null && (
          <div>
            <h2 className='noRecordFound'>No Records Found</h2>
          </div>
        )}
        {/* end::Body */}
        <style>
          {`
                        
                        .custom-select-class{
                          text-align: center;
                          width: 14rem;
                        }

                        

                        .select-custom-header{
                          margin-right: 1rem;
                          line-height: 3rem;
                        }

                        .card-toolbar > .css-b62m3t-container > div {
                          width:14rem !important;
                        }
                        

                        

                        
                    `}
        </style>
      </div>
      {modalOpen && (
        <ViewDocument
          fileUrl={fileUrl}
          partnerId={partnerId}
          orgId=''
          modalClose={handleModalClose}
          fileName={fileName}
        />
      )}
    </div>
  )
}

export {PartnerDocs}
