import React, {useEffect, useState} from 'react'
import {Loader} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const PartnerDashboard: React.FC = () => {
  const [organization, setOrganization] = useState<any[]>([])
  const [screenloader, setScreenLoader] = useState<any>(false)
  const [referralCode, setReferalCode] = useState<any>('')

  useEffect(() => {
    axios
      .get(`${API_URL}/Registration/GetPartnerById?partnerId=${localStorage.getItem('userId')}`)
      .then((res) => setReferalCode(res.data.referralCode))
      .catch((err) => console.error(err))
  }, [])

  useEffect(() => {
    setScreenLoader(true)
    axios
      .get(`${API_URL}/MeraMonitor/ReferedOrganizationByPartnerId`, {
        params: {
          partnerId: localStorage.getItem('userId'),
        },
      })
      .then((res: any) => {
        setOrganization(res.data)
        if (res.data) setScreenLoader(false)
      })
      .catch((err) => console.error(err))
  }, [])
  return (
    <div className='card-body'>
      <div className='tab-content'>
        <div className='card-header border-0'>
          <div className='partner-daterange-container justify-content-end align-items-center'>
            <h3 className='card-title align-items-start flex-column '>
              <span className='card-label fw-bolder fs-2 mb-1 '>Partner Code - {referralCode}</span>
            </h3>
          </div>
        </div>
        {/* <div className='d-flex justify-content-around'>
                    <AdminStats
                        time={''}
                        title='Total Organizations'
                        description={dayjs().format('DD MMMM YYYY')}
                        color='warning'
                        img='/media/icons/duotune/art/group.svg'
                        bgcolor='#34A853'
                    />

                    <AdminStats
                        time={''}
                        title='Total Users'
                        description={dayjs().format('DD MMMM YYYY')}
                        color='warning'
                        img='/media/icons/duotune/art/group.svg'
                        bgcolor='#FBBC04'
                    />

                    <AdminStats
                        time={''}
                        title='Total Active Users'
                        description={dayjs().format('DD MMMM YYYY')}
                        color='warning'
                        img='/media/icons/duotune/art/group.svg'
                        bgcolor='#6237DD'
                    />
                </div> */}
        <div className='mt-5 w-100 d-flex justify-content-between'>
          <div
            className='border-0 w-100'
            //style={{ width: '49%' }}
          >
            <div className=''>
              <div className='card-header border-0  pt-5'>
                <h3 className='card-title align-items-start flex-column px-5 pt-3'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Referred</span>
                </h3>
                <div>
                  <div className='card-body'>
                    {screenloader && Loader('100px')}

                    {!screenloader && (
                      <div className='d-block'>
                        <div className='table-responsive'>
                          <table className='table align-middle gs-0 gy-4'>
                            <thead>
                              <tr
                                className='fw-bolder'
                                style={{
                                  background: '#728FCE',
                                  fontSize: '15px',
                                  borderBottom: '1px solid',
                                }}
                              >
                                <td></td>
                                <td className='min-w-100px '>Name</td>
                                <td className='min-w-100px text-center'>Total Users</td>
                                <td className='min-w-100px text-center'>Active Users</td>
                                {/* <td className='min-w-100px text-center'>Working Users</td> */}
                              </tr>
                            </thead>
                            <tbody>
                              {organization?.length > 0 &&
                                organization.map((org: any) => (
                                  <tr key={org.organizationId}>
                                    <td></td>
                                    <td className='fs-6'>
                                      <span> {org.organizationName} </span>
                                    </td>

                                    <td className='fs-6 text-center'>
                                      <span>{org.totalUsers}</span>
                                    </td>

                                    <td className='fs-6 text-center'>
                                      <span>{org.totalActiveUsers}</span>
                                    </td>

                                    {/* <td className='fs-6 text-center'>
                                                                        <span>{org.totalWorkingUsers}</span>
                                                                    </td> */}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {organization?.length === 0 && (
                          <h2 className='noRecordFound'>No Records Found</h2>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartnerDashboard
