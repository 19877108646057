import React, {useEffect, useState} from 'react'
import {AlertModal, GetOrgList} from '../../CommonFunctions/CommonFunction'
import axios from 'axios'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const TaxAndDiscount: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [loader, setLoader] = useState<boolean>(false)
  const [companiesOptions, setCompaniesOptions] = useState<any[]>([])
  const [orgOptions, setOrgOptions] = useState<any[]>([])

  useEffect(() => {
    FillCompanyDropdown()
  }, [])

  const callOrgTaxAndDiscount = (value: any) => {
    axios
      .get(`${API_URL}/OrganizationTaxAndDiscount/GetDetailByOrgId`, {
        params: {
          orgId: value,
        },
      })
      .then((res) => {
        formik.setFieldValue('discountPercentage', res.data.discountPercentage)
        formik.setFieldValue('taxPercentage', res.data.taxPercentage)
      })
      .catch((err) => console.log(err))
  }

  const FillCompanyDropdown = async () => {
    try {
      const newList = await GetOrgList()
      setCompaniesOptions(newList)
    } catch (error) {
      console.log('Something went wrong')
    }
  }

  const initialValues = {
    organizationId: '',
    discountPercentage: 0,
    taxPercentage: 0,
  }

  const planDetailsSchema = Yup.object().shape({
    organizationId: Yup.string().required('Organization is required'),
    taxPercentage: Yup.number()
      .required('No of Users is required')
      .min(0, 'Please Enter Number more than 0')
      .max(100, 'Number cannot be greater than 100'),
    discountPercentage: Yup.number()
      .required('No of Users is required')
      .min(0, 'Please Enter Number more than 0')
      .max(100, 'Number cannot be greater than 100'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: planDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoader(true)
      axios
        .post(`${API_URL}/OrganizationTaxAndDiscount/UpdateTaxAndDiscount`, values)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            if (res.data.success) {
              resetForm()
            }
            setOrgOptions([])
            setLoader(false)
          })
        })
        .catch((err) => {
          console.log(err)
          setLoader(false)
        })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Tax And Discount</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
        </div>

        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Organization Name</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <div
                className='min-w-200px'
                //style={{ width: '200px' }}
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Search Organization'
              >
                <Select
                  className='selectDropdown'
                  components={makeAnimated()}
                  value={orgOptions}
                  options={companiesOptions}
                  onBlur={formik.handleBlur}
                  placeholder='Select Organization'
                  onChange={(item: any) => {
                    callOrgTaxAndDiscount(item.value)
                    setOrgOptions(item)
                    formik.setFieldValue('organizationId', item.value)
                  }}
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnScroll={true}
                />
              </div>
              {formik.touched.organizationId && formik.errors.organizationId && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{formik.errors.organizationId}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Discount Percentage</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                type='number'
                step='any'
                min={0}
                className='form-control form-control-border '
                placeholder='Employee Count'
                {...formik.getFieldProps('discountPercentage')}
              />
              {formik.touched.discountPercentage && formik.errors.discountPercentage && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{formik.errors.discountPercentage}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Tax Percentage</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                type='number'
                step='any'
                min={0}
                className='form-control form-control-border '
                placeholder='Employee Count'
                {...formik.getFieldProps('taxPercentage')}
              />
              {formik.touched.taxPercentage && formik.errors.taxPercentage && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{formik.errors.taxPercentage}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loader}>
            {!loader && <span className='indicator-label'>Submit</span>}
            {loader && (
              <span className='indicator-progress d-block'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default TaxAndDiscount
