/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'

import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import axios from 'axios'

import dayjs from 'dayjs'
import {
  Pagination,
  Loader,
  AlertModal,
  CommonActionButtons,
} from '../../../CommonFunctions/CommonFunction'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import swal from 'sweetalert2'
const API_URL = process.env.REACT_APP_API_URL

const Department = () => {
  const [department, setDepartment] = useState<any[]>([])

  const [searchQuery, setSearchQuery] = useState('')
  const [filteredDepartment, setFilteredDepartment] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [updateState, setUpdateState] = useState('')

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const filteredMembers = department.filter((departmentData) =>
    departmentData.department.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % department.length
    setItemOffset(newOffset)
  }

  const currentItemss = filteredMembers.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(department.length / itemsPerPage)

  useEffect(() => {
    if (localStorage.getItem('role') === process.env.REACT_APP_SYSTEM_FIRST_ROLE) {
      setSpinner(true)
      GetDepartment()
    }
  }, [])

  const GetDepartment = async () => {
    const organizationId = localStorage.getItem('org_Id')
    try {
      const departments = await axios.get(`${API_URL}/Department/GetDepartment`, {
        params: {
          organizationId: organizationId,
        },
      })
      setDepartment(departments.data)
      setFilteredDepartment(departments.data)
      setSpinner(false)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteHandler = (Props: any) => {
    let body = {
      organizationId: localStorage.getItem('org_Id'),
      departmentId: Props,
      isActive: false,
      modifiedDate: dayjs().format(),
      modifiedBy: localStorage.getItem('name'),
    }

    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#7066E0',
      'Yes, delete it!'
    ).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .post(`${API_URL}/Department/DeleteDepartment`, body)
          .then((res) => {
            GetDepartment()
            AlertModal(
              res.data.message,
              '',
              res.data.success ? 'success' : 'warning',
              false,
              '#7066E0',
              'Ok'
            )
          })
          .catch((err) => {
            console.log(err.data.message)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal(`Department not Deleted`, '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  const handleSearchChange = (name: string) => {
    setSearchQuery(name)
    setFilteredDepartment(filteredDepartment)
    setItemOffset(0)
  }

  function handleEdit(id: any) {
    setUpdateState(id)
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder mb-1' style={{fontSize: '19px'}}>
            Departments
          </span>
        </h3>

        <div style={{display: 'flex'}}>
          {spinner ? (
            ''
          ) : (
            <Tippy placement='top' content='Search department by department name'>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                // title='Search members by name or email'
              >
                <div style={{position: 'relative', marginRight: '1rem'}}>
                  <input
                    style={{padding: '6px 30px 6px 3rem', minHeight: 'unset'}}
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Search by Department Name'
                    onChange={(e) => handleSearchChange(e.target.value)}
                  />
                  <img
                    style={{top: '8px'}}
                    className='custom-placeholder'
                    src={toAbsoluteUrl('/media/icons/duotune/art/custom-search.png')}
                  ></img>
                </div>
              </div>
            </Tippy>
          )}

          <Tippy placement='top' content='Click to add a department'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              // title='Click to add a user'
            >
              <Link to='/department/addDepartment' className='btn btn-sm btn-primary me-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Department
              </Link>
            </div>
          </Tippy>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      {spinner ? (
        Loader('100px')
      ) : (
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                  </th>
                  <th className='min-w-120px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Name
                  </th>
                  <th className='min-w-100px text-center'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItemss.map((names, index) =>
                  updateState === names.departmentId ? (
                    <Edit
                      name={names}
                      setUpdateState={setUpdateState}
                      getDepartment={GetDepartment}
                    />
                  ) : (
                    <tr key={index}>
                      <td></td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div
                            className='d-flex justify-content-start flex-column '
                            style={{width: '13rem', fontSize: '13px'}}
                          >
                            {/* <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'> */}
                            {names.department}

                            {/* </a> */}
                          </div>
                        </div>
                      </td>

                      <td style={{width: '10rem'}}>
                        <div className='d-flex justify-content-evenly flex-shrink-0'>
                          <Tippy placement='top' content='Edit department'>
                            <button
                              onClick={() => {
                                handleEdit(names.departmentId)
                              }}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </button>
                          </Tippy>

                          <Tippy placement='top' content='Delete department'>
                            <a
                              onClick={() => deleteHandler(names.departmentId)}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </Tippy>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  )
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            <div className='d-flex flex-column'>
              {filteredMembers.length > 10 && (
                <div className='d-flex align-items-center justify-content-center'>
                  {Pagination(handlePageClick, pageCount, 0)}
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  )
}

interface EditProps {
  name: any
  setUpdateState: any
  getDepartment: any
}

const Edit: React.FC<EditProps> = ({name, setUpdateState, getDepartment}, index: any) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [editedName, setEditedName] = useState(name.department)
  const [error, setError] = useState(false)

  const handleUpdate = () => {
    if (!editedName) {
      return
    }

    const values = {
      department: editedName,
      departmentId: name.departmentId,
      organizationId: localStorage.getItem('org_Id'),
    }

    axios
      .post(`${API_URL}/Department/UpdateDepartment`, values)
      .then((res) => {
        swal.fire({
          title: `${res.data.message}`,
          text: '',
          icon: res.data.success ? 'success' : 'warning',
          width: '23rem',
          heightAuto: false,
          backdrop: `rgba(0,0,0,0.8)`,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          customClass: {
            title: 'swal-popup',
          },
        })
        getDepartment()
      })
      .catch((err) => {
        console.log(err)
      })
    setUpdateState(null)
  }

  const handleCancel = () => {
    setUpdateState(null)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    editedName ? setError(false) : setError(true)
  }, [editedName])

  return (
    <>
      <tr key={name.departmentId}>
        <td></td>
        <td>
          <input
            className='edit-input fs-6'
            type='text'
            name='name'
            ref={inputRef}
            spellCheck={false}
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
          {error && (
            <div className='fv-plugins-message-container'>
              <div className='text-danger mt-2'>*Department Name is required</div>
            </div>
          )}
        </td>

        <td style={{width: '10rem'}}>{CommonActionButtons(handleUpdate, handleCancel)}</td>
        <td></td>
      </tr>
    </>
  )
}

export {Department}
