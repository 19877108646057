/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {ResetPassword} from './components/resetpassword'

import LoginBg from '../../../images/loginBg.svg'
import {CreatePassword} from './components/CreatePassword'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${LoginBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {/* begin::Content */}
      <div
        className='flex-center flex-column flex-column-fluid'
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          width: '100%',
          alignContent: 'flex-start',
          paddingLeft: '12%',
        }}
      >
        {/* begin::Logo */}
        {/* <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
        </a> */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div
          className='w-lg-500px bg-white shadow-sm mx-auto'
          style={{borderRadius: '20px', marginTop: '5%'}}
        >
          <Outlet />
        </div>
        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-4'>
            <span className='fw-bold me-2' style={{color: '#000000'}}>
              {' '}
              &copy; {new Date().getFullYear()}
            </span>
            <a href='' className='text-gray-800' style={{color: '#000000'}}>
              AapnaInfotech. All Right Reserved
            </a>
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}

      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-Password' element={<ResetPassword />} />
      <Route path='create-Password' element={<CreatePassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
