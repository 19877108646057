/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import dayjs from 'dayjs'
import axios, {CancelTokenSource} from 'axios'
import {Loader} from '../../CommonFunctions/CommonFunction'
import {convertSecIntoHoursMinSec} from '../../utils/ConvertSeconds.utils'

let cancelTokenSource: CancelTokenSource | null
interface MyObject {
  fromDate: string
  awayTime: string
  unproductiveTime: string
  organizationId: string
  toDate: string
  totalIdleTime: string
  totalProductiveTime: string
  userId: string
  userName: string
}

type Props = {
  className: string
  selectedUser: string
  startDate: Date
  endDate: Date
  role: string
  department: string
}

const API_URL = process.env.REACT_APP_API_URL

const AdminChart: React.FC<Props> = ({
  className,
  selectedUser,
  startDate,
  endDate,
  role,
  department,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const [_, setSelectedDate] = useState('')
  const [__, setSeries] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [prevChart, setPrevChart] = useState<any>(null)

  useEffect(() => {
    const fetchData = async () => {
      const userRole = localStorage.getItem('role')
      if (userRole === 'Manager') {
        await getManagerData()
      } else {
        setSpinner(true)
        let timesUrl = ''
        let body = {}
        if (prevChart) {
          prevChart.destroy()
        }
        if (department) {
          timesUrl = 'AdminDashboard/GetUsersTimeProductivityByDepartment'
          body = {
            organizationId: localStorage.getItem('org_Id'),
            departmentId: department,
            fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
            toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
          }
        }

        if (selectedUser) {
          if (selectedUser === 'All') {
            timesUrl = 'AdminDashboard/GetAllUsersTimeProductivity'
            body = {
              organizationId: localStorage.getItem('org_Id'),
              fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
              toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
            }
          } else {
            timesUrl = 'AdminDashboard/GetTimeProductivityByManager'
            body = {
              managerId: selectedUser,
              organizationId: localStorage.getItem('org_Id'),
              fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
              toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
            }
          }
        }
        let getData = await getTimesData(timesUrl, body)

        if (!isMounted) {
          return
        }

        setSpinner(false)
        if (!chartRef.current) {
          console.log('asj')
          return
        } else {
          console.log('asj run')
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        const chart = new ApexCharts(
          chartRef.current,
          getChartOptions(height, setSelectedDate, setSeries, getData)
        )
        setPrevChart(chart)

        if (chart) {
          chart.render()
        }
        return () => {
          if (chart) {
            chart.destroy()
          }
        }
      }
    }

    let isMounted = true
    fetchData()

    return () => {
      isMounted = false
    }
  }, [selectedUser, startDate, endDate, role, department])

  const getTimesData = async (TimesUrl: any, body: any) => {
    try {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('New request initiated')
      }
      cancelTokenSource = axios.CancelToken.source()
      const getData = await axios.post(`${API_URL}/${TimesUrl}`, body, {
        cancelToken: cancelTokenSource?.token,
      })

      return getData.data
    } catch (error) {
      console.log(error)
    }
  }

  const getManagerData = async () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    if (prevChart) {
      prevChart.destroy()
    }

    if (!department) {
      setSpinner(true)
      let body = {
        ManagerId: selectedUser,
        OrganizationId: localStorage.getItem('org_Id'),
        FromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        ToDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
      }
      cancelTokenSource = axios.CancelToken.source()

      axios
        .get(`${API_URL}/AdminDashboard/GetTimeProductivityByReportManager`, {
          params: body,
          cancelToken: cancelTokenSource?.token,
        })
        .then((res) => {
          setSpinner(false)
          if (!chartRef.current) {
            console.log('asj')
            setSpinner(false)
            return
          } else {
            console.log('asj run')
          }

          const height = parseInt(getCSS(chartRef.current, 'height'))
          const chart = new ApexCharts(
            chartRef.current,
            getChartOptions(height, setSelectedDate, setSeries, res?.data)
          )

          setPrevChart(chart)

          if (chart) {
            chart.render()
          }
          return () => {
            if (chart) {
              chart.destroy()
            }
          }
        })
    } else {
      setSpinner(true)
      let body = {
        ManagerId: selectedUser,
        OrganizationId: localStorage.getItem('org_Id'),
        DepartmentId: department,
        FromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        ToDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
      }
      cancelTokenSource = axios.CancelToken.source()
      axios
        .get(`${API_URL}/AdminDashboard/GetTimeProductivityByReportManagerandDeptId`, {
          params: body,
          cancelToken: cancelTokenSource?.token,
        })
        .then((res) => {
          setSpinner(false)
          if (!chartRef.current) {
            console.log('asj')
            setSpinner(false)
            return
          } else {
            console.log('asj run')
          }

          const height = parseInt(getCSS(chartRef.current, 'height'))
          const chart = new ApexCharts(
            chartRef.current,
            getChartOptions(height, setSelectedDate, setSeries, res?.data)
          )

          setPrevChart(chart)

          if (chart) {
            chart.render()
          }
          return () => {
            if (chart) {
              chart.destroy()
            }
          }
        })
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}

        {spinner ? (
          Loader('100px')
        ) : (
          <div
            ref={chartRef}
            id='kt_charts_widget_1_chart'
            style={{height: '300px', textAlign: 'left'}}
          />
        )}
      </div>
    </div>
  )
}

export {AdminChart}

function getChartOptions(
  height: number,
  setSelectedDate: (value: string) => void,
  setSeries: (value: string) => void,
  getData: any
): ApexOptions {
  const borderColor = getCSSVariableValue('--bs-danger')

  const productive = Number(getData.totalProductiveTime) / 3600
  const idle = Number(getData.totalIdleTime) / 3600
  const away = Number(getData.totalAwayTime) / 3600
  const total = Number(getData.totalTime) / 3600

  return {
    series: [
      {
        name: 'Time',
        data: [productive, idle, away, total],
      },
    ],
    chart: {
      id: 'mychart',
      fontFamily: 'inherit',
      stacked: false,
      type: 'bar',
      height: `${height}px`,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60px',
        borderRadius: 5,
        distributed: true,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        const changeVal = Math.round(val * 3600)
        const formattedTime = convertSecIntoHoursMinSec(changeVal)
        if (formattedTime === '00:00:00') {
          return ''
        }
        return formattedTime
      },
      style: {
        colors: ['black', 'black', 'black', 'black'],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Productive', 'Idle', 'Away', 'Total'],
      axisBorder: {
        show: true,
        color: 'black',
      },
      axisTicks: {
        show: false,
        color: 'black',
      },
      labels: {
        style: {
          colors: 'black',
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: 'black',
          fontSize: '12px',
        },
        formatter: function (val: number) {
          if (val !== Infinity) {
            const roundedVal = Math.round(val)
            return roundedVal.toString()
          } else {
            return '1'
          }
        },
      },
      axisBorder: {
        show: true,
        color: 'black',
      },
      forceNiceScale: true,
    },
    fill: {
      // opacity: 10,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val: number) {
          const changeVal = Math.round(val * 3600)

          return convertSecIntoHoursMinSec(changeVal)
        },
      },
    },
    colors: ['#34A853', '#FDDA0D', '#9D6B50', '#FF6178'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  }
}
