/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import dayjs from 'dayjs'
import {checkPlanStatus, guidedTour} from '../../../../app/CommonFunctions/CommonFunction'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

export function HeaderWrapper() {
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const {config, classes, attributes} = useLayout()
  const navigate = useNavigate()
  const {header, aside} = config
  const [alertBox, setAlertBox] = useState(false)
  const [message, setMessage] = useState('')
  const isGroupAdmin = localStorage.getItem('isGroupAdmin')

  useEffect(() => {
    if (ConfigDetails.orgId === '6656c5bd282c1dfc1ab6d44f' && ConfigDetails?.role === 'Admin') {
      setAlertBox(true)
      setMessage(`The data you are viewing is sample data.`)
    }

    const planStatusRespone = checkPlanStatus(ConfigDetails, dispatch, auth)
    setAlertBox(planStatusRespone?.alert || false)
    setMessage(planStatusRespone?.message || '')

    // if (ConfigDetails?.role === 'Admin' && ConfigDetails.orgId !== '6656c5bd282c1dfc1ab6d44f') {
    //   const currentDate = dayjs()
    //   const planDate = dayjs(ConfigDetails.planExpiry)
    //   const daysDifference = planDate.diff(currentDate, 'day')

    //   if (daysDifference == 0) {
    //     setAlertBox(true)
    //     setMessage(`Your plan is expiring today!`)
    //   } else if (daysDifference < 0) {
    //     dispatch(auth.actions.logout())
    //     setAlertBox(true)
    //     setMessage(`Your plan has expired!`)
    //   } else if (daysDifference <= 7) {
    //     setAlertBox(true)
    //     setMessage(`Your plan is going to expire in ${daysDifference} day(s)!`)
    //   }
    // }
  }, [ConfigDetails])

  const handelOrganizationChnage = async () => {
    navigate('/orgSelect')
  }

  // const startTour = () => {
  //   localStorage.setItem('tour', 'true')
  //   if (window.location.pathname === '/member') {
  //     guidedTour()
  //   } else {
  //     window.location.href = '/member'
  //   }
  // }

  // const handleClick = () => {
  //   navigate('/help')
  // }
  return (
    <>
      <div
        id='kt_header'
        style={{
          backgroundColor: '#8080ff',
        }}
        className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
        {...attributes.headerMenu}
      >
        <div
          className={clsx(
            classes.headerContainer.join(' '),
            'd-flex align-items-stretch justify-content-between'
          )}
        >
          {isGroupAdmin && (
            <div style={{paddingTop: '10px'}}>
              <button
                type='button'
                onClick={() => handelOrganizationChnage()}
                className='btn btn-info'
                style={{width: '14rem'}}
              >
                Change Organization
              </button>
              {/* <button className='org-button' onClick={() => handelOrganizationChnage()}>
                Change Organization
              </button> */}
            </div>
          )}

          {alertBox && (
            <div className='w-100 d-flex align-items-center justify-content-center'>
              <div
                className='alert alert-warning d-flex align-items-center justify-content-center'
                role='alert'
                // style={{width: '30%', fontSize: 'small', fontWeight: '700', marginTop: '0.8rem'}}
                style={{fontSize: 'small', fontWeight: '700', marginTop: '0.8rem'}}
              >
                {message}
              </div>
            </div>
          )}

          {/* {ConfigDetails?.role === 'Admin' && (
            <div className='d-flex'>
              <div style={{paddingTop: '10px', width: '8rem'}}>
                <button type='button' onClick={startTour} className='btn btn-info'>
                  Start Tour
                </button>
              </div>
              <div style={{paddingTop: '10px'}}>
                <button type='button' onClick={handleClick} className='btn btn-info'>
                  Help
                </button>
              </div>
            </div>
          )} */}

          {/* begin::Aside mobile toggle */}
          {aside.display && (
            <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
              <div
                className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                id='kt_aside_mobile_toggle'
              >
                <KTSVG
                  path='/media/icons/duotune/abstract/abs015.svg'
                  className='svg-icon-2x mt-1'
                />
              </div>
            </div>
          )}
          {/* end::Aside mobile toggle */}
          {/* begin::Logo */}

          {/* end::Logo */}

          {aside.display && (
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
              <Link to='/' className='d-lg-none'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/only_Logo_noBG.svg')}
                  className='h-40px'
                />
              </Link>
            </div>
          )}

          {/* begin::Wrapper */}
          <div className=''>
            {/* begin::Navbar */}
            {header.left === 'menu' && (
              <div className='d-flex align-items-stretch' id='kt_header_nav'>
                <Header />
              </div>
            )}

            {header.left === 'page-title' && (
              <div className='d-flex align-items-center' id='kt_header_nav'>
                <DefaultTitle />
              </div>
            )}

            <div className='d-flex align-items-stretch flex-shrink-0'>
              <Topbar />
            </div>
          </div>

          {/* end::Wrapper */}
        </div>
      </div>

      {ConfigDetails?.showAlert && (
        <div
          className='alert alert-primary w-100 mb-0 text-center fs-5 fw-bolder pb-1 pt-5'
          role='alert'
        >
          A simple primary alert—check it out!
        </div>
      )}
    </>
  )
}
