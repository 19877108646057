import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'

import {AlertModal} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'

import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL

const initialValues = {
  email: '',
  // passwordResetLink: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 character')
    .max(50, 'Maximum 50 character')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [captcha, setCaptcha] = useState('')
  const [inputValue, setInputValue] = useState('')
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setTimeout(() => {
        if (captcha !== inputValue) {
          AlertModal('Invalid Captcha', '', 'error', false, '#7066E0', 'Ok').then(() => {
            setLoading(false)
            setInputValue('')
            generateCaptcha()
            setSubmitting(false)
            return
          })
        } else {
          axios
            .post(`${API_URL}/Login/ForgotPassword`, values)
            .then((res) => {
              AlertModal(
                res.data.message,
                '',
                res.data.success ? 'success' : 'warning',
                false,
                '#7066E0',
                'Ok'
              )
              setInputValue('')
              generateCaptcha()
              resetForm()
              setLoading(false)
            })
            .catch((err) => {
              console.log(err)
              setLoading(false)
              setSubmitting(false)

              setStatus('Registration process has broken')
            })
        }
      }, 0)
    },
  })

  const handleChange = (event: any) => {
    setInputValue(event.target.value)
    formik.setSubmitting(false)
  }

  useEffect(() => {
    generateCaptcha()
  }, [])

  const generateCaptcha = () => {
    const charsArray = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const crypto = window.crypto || (window as any).msCrypto

    if (!crypto) {
      console.error('Crypto API not supported by this browser.')
      return
    }

    let captchaValue = ''
    for (let i = 0; i < 6; i++) {
      const randomValues = new Uint32Array(1)
      crypto.getRandomValues(randomValues)
      const index = randomValues[0] % charsArray.length
      captchaValue += charsArray[index]
    }

    setCaptcha(captchaValue)
  }

  return (
    <>
      <div>
        <div
          className='d-flex justify-content-center align-items-center '
          style={{width: '100%', borderRadius: '20px 20px 0px 0px', paddingLeft: '3rem'}}
        >
          <img
            src={toAbsoluteUrl('/media/logos/mera_monitor_logo_bgWhite.svg')}
            style={{width: '35rem', padding: '3rem 1rem 1rem 1rem'}}
          ></img>
        </div>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
          style={{padding: '40px 50px'}}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Forgot Password ?</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>
              Enter your email to reset your password.
            </div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>Sent password reset link. Please check your email</div>
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
            <input
              type='email'
              placeholder='Enter Your Email Address'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block text-danger mt-2'>
                  <span role='alert '>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          <div
            className='d-flex mb-5'
            style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}
          >
            <div style={{height: '3.5  rem', width: '44%'}}>
              <input
                type='text'
                placeholder='Enter Captcha'
                autoComplete='off'
                value={inputValue}
                onChange={(e) => handleChange(e)}
                className={clsx('form-control form-control-lg ')}
              />
            </div>
            <div style={{height: '3.5rem', width: '44%'}}>
              <span
                style={{
                  fontSize: '16px',
                  display: 'flex',
                  justifyContent: 'center',
                  textDecoration: 'line-through',
                }}
                className={clsx('form-control form-control-lg ')}
              >
                {captcha}
              </span>
            </div>
            <div style={{border: '1px solid black,', height: '3.5rem', width: '10%'}}>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: '25%',
                  cursor: 'pointer',
                }}
                onClick={generateCaptcha}
              >
                <img
                  src='/media/icons/duotune/general/captcha-refresh.svg'
                  alt='Captcha Refresh Icon'
                  className='svg-icon-3'
                  style={{height: 25, width: 25}}
                />
              </span>
            </div>
          </div>
          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg fw-bolder me-4'
              style={{background: '#603BE5', color: '#FFFFFF'}}
              disabled={formik.isSubmitting || inputValue.length == 0}
            >
              {!loading && <span className='indicator-label'>Send</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg fw-bolder'
                style={{background: '#603BE5', color: '#FFFFFF'}}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </>
  )
}
