import React, {useEffect, useState} from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

export function AsideMenuMain() {
  const role = localStorage.getItem('role')
  const [checkPrjTaskStatus, setCheckPrjTaskStatus] = useState(false)
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)

  useEffect(() => {
    setCheckPrjTaskStatus(localStorage.getItem('ProjTaskConfig') == 'true' ? true : false)
  }, [])

  if (
    role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
    role === process.env.REACT_APP_SYSTEM_SECOND_ROLE ||
    role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE ||
    role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
    role === process.env.REACT_APP_SYSTEM_SIXTH_ROLE
  ) {
    return (
      <>
        {role !== process.env.REACT_APP_SYSTEM_FOURTH_ROLE && (
          <AsideMenuItem
            to='/builder'
            title='Dashboard'
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/dashboard.svg'
          />
        )}

        {
          //////////////////////////////////Admin/////////////////////////////////////
          role === process.env.REACT_APP_SYSTEM_FIRST_ROLE && (
            <>
              <div
                className='admin-main-container scroller-issue'
                style={{overflow: 'auto', marginBottom: '6rem'}}
              >
                <div className='menu-item'>
                  <div className='menu-content  pb-2' style={{paddingTop: '1.2rem'}}>
                    <span className='menu-section text text-uppercase fs-8 ls-1'>Admin</span>
                  </div>
                </div>

                {/* <AsideMenuItem
          to='/timesheet/daily'
          icon='/media/icons/duotune/art/timesheet.svg'
          title='Timesheets'
          fontIcon='bi-app-indicator'>
          <AsideMenuItem to='/timesheet/daily' title='Daily' hasBullet={true} />

          <AsideMenuItem to='/crafted/pages/profile/overview' title='Calender' hasBullet={true} />

        </AsideMenuItem> */}

                <AsideMenuItem
                  to='adminDashboard'
                  icon='/media/icons/duotune/art/admin.svg'
                  title='Team Dashboard'
                />

                <AsideMenuItemWithSub
                  //to='/report/'
                  title='Report'
                  icon='/media/icons/duotune/art/report.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='/report/timeTracker'
                    icon='/media/icons/duotune/art/attendance.svg'
                    title='Time Tracker'
                  />
                  <AsideMenuItem
                    to='/report/urls'
                    title='System Activity'
                    icon='/media/icons/duotune/art/urls.svg'
                  />
                  <AsideMenuItem
                    to='/report/systemActivity'
                    title='Activity Summary'
                    icon='/media/icons/duotune/art/activitySummary.svg'
                  />
                  <AsideMenuItem
                    to='/report/weeklySummary'
                    title='Weekly Summary'
                    icon='/media/icons/duotune/general/notebook.svg'
                  />
                  <AsideMenuItem
                    to='/report/productivitys'
                    icon='/media/icons/duotune/art/bar-chart-fill.svg'
                    title='Productivity vs Idle'
                  />
                  <AsideMenuItem
                    to='/admin_records'
                    title='Web & Apps'
                    icon='/media/icons/duotune/art/web&app.svg'
                  />

                  {/* <AsideMenuItem to='/schedule' title='Schedule' icon='/media/icons/duotune/art/schedule.svg' /> */}
                  {/* <AsideMenuItem to='/web&app' title='Web and App' icon='/media/icons/duotune/art/schedule.svg' />
              <AsideMenuItem to='/empAttendance' title='Attendance' icon='/media/icons/duotune/art/schedule.svg' /> */}
                  <AsideMenuItem
                    to='/report/attendance'
                    title='Attendance'
                    icon='/media/icons/duotune/general/attendance.svg'
                  />
                  <AsideMenuItem
                    to='/report/timeline'
                    title='Timeline'
                    icon='/media/icons/duotune/art/shift.svg'
                  />

                  {ConfigDetails.projectTaskConfig && (
                    <>
                      <AsideMenuItem
                        to='/report/dailyTimeEntry'
                        title='Daily Time Entry'
                        icon='/media/icons/duotune/general/TimeEntries.svg'
                      />
                      <AsideMenuItem
                        to='projectTaskReport'
                        icon='/media/icons/duotune/art/projectTaskReport.svg'
                        title='Project Report'
                      />
                      <AsideMenuItem
                        to='/userTaskReport'
                        title='User Task Report'
                        icon='/media/icons/duotune/art/userTask.svg'
                      />
                    </>
                  )}

                  <AsideMenuItem
                    to='/report/ProdVsUnpro'
                    title='Productive Vs Unproductive'
                    icon='/media/icons/duotune/general/overview.svg'
                  />
                  {/* <AsideMenuItem to='/apps' title='Apps' icon='/media/icons/duotune/art/apps.svg' /> */}
                </AsideMenuItemWithSub>

                <AsideMenuItem
                  to='screenshots'
                  icon='/media/icons/duotune/art/fullscreen.svg'
                  title='Screenshot'
                />
                {/* <AsideMenuItem
                  to='projectTaskReport'
                  icon='/media/icons/duotune/art/fullscreen.svg'
                  title='Project Task Report'
                /> */}

                {ConfigDetails.isEnableLiveStreaming && (
                  <AsideMenuItem
                    to='liveStreaming'
                    icon='/media/icons/duotune/general/liveStreaming.svg'
                    title='Live Stream'
                  />
                )}

                {ConfigDetails.isEnableLiveRecording && (
                  <AsideMenuItem
                    to='liveRecording'
                    icon='/media/icons/duotune/general/liveRecording.svg'
                    title='Live Recording'
                  />
                )}

                {/* <AsideMenuItemWithSub
                to='/activities'
                icon='/media/icons/duotune/art/fullscreen.svg'
                title='Screenshots'
                fontIcon='bi-app-indicator'
              >
                <AsideMenuItem to={`activities/screenshots`} icon='/media/icons/duotune/art/activity.svg ' title='Screenshots' />
                <AsideMenuItem to='activities/dwonload' icon='/media/icons/duotune/art/download.svg ' title='Download' />

              </AsideMenuItemWithSub> */}
                {/* <AsideMenuItem
              to='/crafted/account/settings'
              title='Settings'
              icon='/media/icons/duotune/art/setting.svg'
              fontIcon='bi-app-indicator'
            /> */}
                {ConfigDetails.projectTaskConfig && (
                  <AsideMenuItemWithSub
                    title='Project & Task'
                    icon='/media/icons/duotune/art/pass.svg'
                    fontIcon='bi-person'
                  >
                    <AsideMenuItem
                      to='/project'
                      title='Project'
                      icon='/media/icons/duotune/art/project.svg'
                    />

                    {/* <AsideMenuItem
                      to='/type'
                      title='Project Type & Task Type'
                      icon='/media/icons/duotune/general/folder-plus.svg'
                    /> */}
                    <AsideMenuItem
                      to='/task'
                      title='Task'
                      icon='/media/icons/duotune/art/task-list.svg'
                    />
                    <AsideMenuItem
                      to='/markTaskCompletion'
                      title='Task Completion'
                      fontIcon='bi-archive'
                      icon='/media/icons/duotune/art/journal-check.svg'
                    />
                  </AsideMenuItemWithSub>
                )}

                <AsideMenuItemWithSub
                  // to='/member'
                  title='Member'
                  icon='/media/icons/duotune/art/people.svg'
                  fontIcon='bi-person'
                  tourClass='member'
                >
                  <AsideMenuItem
                    to='/member'
                    title='Member Info'
                    icon='/media/icons/duotune/art/member.svg'
                    tourClass='memberinfo'
                  />

                  <AsideMenuItem
                    to='/changeMan'
                    title='Change Manager'
                    icon='/media/icons/duotune/art/manager-1.svg'
                  />

                  {/* <AsideMenuItem to='/shift' title='Shift' icon="/media/icons/duotune/art/shift.svg" /> */}
                </AsideMenuItemWithSub>
                <AsideMenuItemWithSub
                  // to='/member'
                  title='Pricing and Billing'
                  icon='/media/icons/duotune/art/billing.svg'
                  fontIcon='bi-person'
                >
                  {ConfigDetails.organizationStatus !== 'Paid' && (
                    <AsideMenuItem
                      to='/plans'
                      title='Plans'
                      icon='/media/icons/duotune/art/myPlan.svg'
                    />
                  )}

                  <AsideMenuItem
                    to='/myPlan'
                    title='My Plan'
                    icon='/media/icons/duotune/art/plan.svg'
                  />
                  <AsideMenuItem
                    to='/invoice'
                    title='History'
                    icon='/media/icons/duotune/art/invoice.svg'
                  />
                </AsideMenuItemWithSub>

                <AsideMenuItem
                  to='support'
                  icon='/media/icons/duotune/art/support.svg'
                  title='Support'
                />

                <AsideMenuItemWithSub
                  //to='TimeClaim'
                  title='Time Claim'
                  icon='/media/icons/duotune/general/clock-history.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='TimeClaim/claim'
                    icon='/media/icons/duotune/general/claim.svg'
                    title='Claim'
                  />
                  <AsideMenuItem
                    to='TimeClaim/request'
                    icon='/media/icons/duotune/general/request.svg'
                    title='Request'
                  />
                  <AsideMenuItem
                    to='TimeClaim/status'
                    icon='/media/icons/duotune/general/updateTimeClaim.svg'
                    title='Status'
                  />
                </AsideMenuItemWithSub>

                {ConfigDetails.projectTaskConfig && (
                  <AsideMenuItem
                    to='/timeEntry'
                    title='Time Entry'
                    icon='/media/icons/duotune/general/userTimeEntry.svg'
                  />
                )}

                <AsideMenuItem
                  to='/department'
                  title='Department'
                  fontIcon='bi-app-indicator'
                  icon='/media/icons/duotune/art/corporate.svg'
                />

                <AsideMenuItem
                  to='/holiday'
                  title='Holiday'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/art/holidayCalendar.svg'
                />

                {ConfigDetails.organizationStatus === 'UnderTrial' && (
                  <AsideMenuItem
                    to='/trial'
                    title='Trial'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/gift.svg'
                  />
                )}

                {/* <AsideMenuItem
                to='/transactionDetails'
                title='Payment Details'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/art/holidayCalendar.svg'
              /> */}

                <AsideMenuItemWithSub
                  title='Configuration'
                  icon='/media/icons/duotune/art/configuration.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='/configuration'
                    title='Settings'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/art/setting.svg'
                  />
                  <AsideMenuItem
                    to='/documents'
                    title='Documents'
                    icon='/media/icons/duotune/general/documents.svg'
                  />
                  <AsideMenuItem
                    to='ReportAccess'
                    icon='/media/icons/duotune/art/access.svg'
                    title='Report Access'
                  />
                  <AsideMenuItem
                    to='/WebAppCatalogue'
                    title='Productive App / URL'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/art/catalogue.svg'
                  />
                  <AsideMenuItem
                    to='/history'
                    title='History'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/art/history.svg'
                  />
                </AsideMenuItemWithSub>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: '5px',
                }}
              >
                {/* <div style={{ marginLeft: '25.5px' }}>
                <a href='https://www.aapnainfotech.com/about-us/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>About</span>
                </a>

              </div>
              <div style={{ marginLeft: '25.5px', paddingTop: '2.5px' }}>
                <a href='https://www.aapnainfotech.com/contact/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>Contact</span>
                </a>
              </div>
              <div style={{ marginLeft: '25.5px', paddingTop: '2.5px' }}>
                <a href='https://www.aapnainfotech.com/work/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>Our Work</span>
                </a>
              </div> */}
                <div
                  className='position-fixed text-white bg-black bottom-0'
                  style={{width: '17rem'}}
                >
                  <div className='d-flex justify-content-center py-1 align-items-center'>
                    <span>{new Date().getFullYear()} &copy;</span>
                    <span>AapnaInfotech</span>
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          //////////////////////////////////Super Admin/////////////////////////////////////
          role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE && (
            <>
              <div
                className='admin-main-container scroller-issue'
                style={{overflow: 'auto', marginBottom: '6rem'}}
              >
                <div className='menu-item'>
                  <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text text-uppercase fs-8 ls-1'>Super Admin</span>
                  </div>
                </div>
                {/* <AsideMenuItem
              to='/project'
              title='Projects'
              fontIcon='bi-app-indicator'
              icon='/media/icons/duotune/art/project.svg'
            /> */}
                {/* <AsideMenuItem
                to='/member'
                title='Members'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/art/member.svg'
              /> */}
                <AsideMenuItem
                  to='/organization'
                  title='Organization'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/art/company.svg'
                />
                <AsideMenuItem
                  to='/overview'
                  title='Companies'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/art/enterprise.svg'
                />
                <AsideMenuItem
                  to='/onBoardRequest'
                  title='onBoard Request'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/general/onBoardReq.svg'
                />

                <AsideMenuItemWithSub
                  //to='TimeClaim'

                  title='Integration'
                  icon='/media/icons/duotune/general/integration.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='integration/portals'
                    title='Portals'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/portal.svg'
                  />

                  <AsideMenuItem
                    to='/integration/request'
                    title='Request'
                    fontIcon='bi-archive'
                    icon='/media/icons/duotune/general/integration.svg'
                  />
                </AsideMenuItemWithSub>

                <AsideMenuItem
                  to='/populateData'
                  title='Populate Sales Data'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/general/onBoardReq.svg'
                />

                <AsideMenuItemWithSub
                  //to='TimeClaim'
                  title='Payment'
                  icon='/media/icons/duotune/general/payment.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='payment'
                    icon='/media/icons/duotune/general/addPayment.svg'
                    title='Payment'
                  />
                  {/* <AsideMenuItem
                    to='AdHOCPayment'
                    icon='/media/icons/duotune/general/addPayment.svg'
                    title='Ad HOC Payment'
                  /> */}
                  <AsideMenuItem
                    to='ChangePlanReq'
                    icon='/media/icons/duotune/general/planreq.svg'
                    title='Plan Request'
                  />
                  <AsideMenuItem
                    to='ExistingPlan'
                    icon='/media/icons/duotune/general/existingPlan.svg'
                    title='Payment History'
                  />

                  <AsideMenuItem
                    to='TaxAndDiscount'
                    icon='/media/icons/duotune/general/TaxAndDiscount.svg'
                    title='Tax and Discount'
                  />
                </AsideMenuItemWithSub>

                <AsideMenuItem
                  to='allPlans'
                  icon='/media/icons/duotune/general/subscription.svg'
                  title='Plans'
                />
                <AsideMenuItem
                  to='deleteScreenshot'
                  icon='/media/icons/duotune/general/subscription.svg'
                  title='Delete Screenshot'
                />

                <AsideMenuItem
                  to='config'
                  icon='/media/icons/duotune/general/addPayment.svg'
                  title='Configuration'
                />
                <AsideMenuItemWithSub
                  //to='TimeClaim'
                  title='Partner'
                  icon='/media/icons/duotune/general/partner.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='partner'
                    icon='/media/icons/duotune/general/partner.svg'
                    title='Info'
                  />

                  <AsideMenuItem
                    to='associatePartner'
                    icon='/media/icons/duotune/general/partnerAssociate.svg'
                    title='Associate Partner'
                  />

                  <AsideMenuItem
                    to='pendingCommission'
                    icon='/media/icons/duotune/general/partner.svg'
                    title='Commission Payment'
                  />

                  <AsideMenuItem
                    to='commission'
                    icon='/media/icons/duotune/general/commission.svg'
                    title='Commission'
                  />
                </AsideMenuItemWithSub>

                {/* <AsideMenuItem
                  to='/documents'
                  title='Documents'
                  icon='/media/icons/duotune/general/documents.svg'
                /> */}
                <AsideMenuItem
                  to='/documents'
                  title='Documents'
                  icon='/media/icons/duotune/general/documents.svg'
                />
                <AsideMenuItem
                  to='allGroupAdmin'
                  icon='/media/icons/duotune/general/partner.svg'
                  title='Group Admin'
                />
                <AsideMenuItem
                  to='allInformation'
                  icon='/media/icons/duotune/general/labelInfo.svg'
                  title='Labels'
                />
                <AsideMenuItemWithSub
                  //to='TimeClaim'
                  title='Trial Extension'
                  icon='/media/icons/duotune/general/free-trial.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='trialExtension'
                    icon='/media/icons/duotune/art/history.svg'
                    title='Extend Trial'
                  />
                  <AsideMenuItem
                    to='trialExtensionRequest'
                    icon='/media/icons/duotune/art/history.svg'
                    title='Request'
                  />
                  <AsideMenuItem
                    to='trialExtensionHistory'
                    icon='/media/icons/duotune/art/history.svg'
                    title='History'
                  />
                </AsideMenuItemWithSub>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: '5px',
                }}
              >
                {/* <div style={{ marginLeft: '25.5px' }}>
                <a href='https://www.aapnainfotech.com/about-us/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>About</span>
                </a>

              </div>
              <div style={{ marginLeft: '25.5px', paddingTop: '2.5px' }}>
                <a href='https://www.aapnainfotech.com/contact/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>Contact</span>
                </a>
              </div>
              <div style={{ marginLeft: '25.5px', paddingTop: '2.5px' }}>
                <a href='https://www.aapnainfotech.com/work/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>Our Work</span>
                </a>
              </div> */}
                <div
                  className='position-fixed text-white bg-black bottom-0'
                  style={{width: '17rem'}}
                >
                  <div className='d-flex justify-content-center py-1'>
                    <span>{new Date().getFullYear()} &copy;</span>
                    <span>AapnaInfotech</span>
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          //////////////////////////////////Manager/////////////////////////////////////
          role === process.env.REACT_APP_SYSTEM_SECOND_ROLE && (
            <>
              <div
                className='admin-main-container scroller-issue'
                style={{overflow: 'auto', marginBottom: '6rem'}}
              >
                <div className='menu-item'>
                  <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text text-uppercase fs-8 ls-1'>Manager</span>
                  </div>
                </div>
                <AsideMenuItem
                  to='overviewDashboard'
                  icon='/media/icons/duotune/art/admin.svg'
                  title='Team Dashboard'
                />

                <AsideMenuItemWithSub
                  title='Report'
                  icon='/media/icons/duotune/art/report.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='/report/timeTracker'
                    icon='/media/icons/duotune/art/attendance.svg'
                    title='Time Tracker'
                  />
                  <AsideMenuItem
                    to='/report/urls'
                    title='System Activity'
                    icon='/media/icons/duotune/art/urls.svg'
                  />

                  <AsideMenuItem
                    to='/report/systemActivity'
                    title='Activity Summary'
                    icon='/media/icons/duotune/art/activitySummary.svg'
                  />
                  <AsideMenuItem
                    to='/report/weeklySummary'
                    title='Weekly Summary'
                    icon='/media/icons/duotune/general/notebook.svg'
                  />

                  {/* <AsideMenuItem to='/reports/productivity' icon='/media/icons/duotune/art/attendance.svg' title='Productivity' /> */}
                  <AsideMenuItem
                    to='/report/productivitys'
                    icon='/media/icons/duotune/art/bar-chart-fill.svg'
                    title='Productivity vs Idle'
                  />
                  <AsideMenuItem
                    to='/manager_records'
                    title='Web&Apps'
                    icon='/media/icons/duotune/art/web&app.svg'
                  />
                  {/* <AsideMenuItem to='/schedule' title='Schedule' icon='/media/icons/duotune/art/schedule.svg' /> */}
                  <AsideMenuItem
                    to='/report/attendance'
                    title='Attendance'
                    icon='/media/icons/duotune/general/attendance.svg'
                  />
                  <AsideMenuItem
                    to='/report/timeline'
                    title='Timeline'
                    icon='/media/icons/duotune/art/shift.svg'
                  />
                  {ConfigDetails.projectTaskConfig && (
                    <>
                      <AsideMenuItem
                        to='/report/dailyTimeEntry'
                        title='Daily Time Entry'
                        icon='/media/icons/duotune/general/TimeEntries.svg'
                      />
                      <AsideMenuItem
                        to='userTaskReport'
                        title='User Task Report'
                        icon='/media/icons/duotune/art/userTask.svg'
                      />
                    </>
                  )}

                  <AsideMenuItem
                    to='/report/ProdVsUnpro'
                    title='Productive Vs Unproductive'
                    icon='/media/icons/duotune/general/overview.svg'
                  />

                  {/* <AsideMenuItem to='/web&app/individual' title='Web and App' icon='/media/icons/duotune/art/schedule.svg' /> */}
                </AsideMenuItemWithSub>

                <AsideMenuItem
                  to='screenshots'
                  icon='/media/icons/duotune/art/fullscreen.svg'
                  title='Screenshot'
                />

                {ConfigDetails.isEnableLiveStreaming && (
                  <AsideMenuItem
                    to='liveStreaming'
                    icon='/media/icons/duotune/general/liveStreaming.svg'
                    title='Live Stream'
                  />
                )}

                {ConfigDetails.isEnableLiveRecording && (
                  <AsideMenuItem
                    to='liveRecording'
                    icon='/media/icons/duotune/general/liveRecording.svg'
                    title='Live Recording'
                  />
                )}

                {ConfigDetails.projectTaskConfig && (
                  <AsideMenuItemWithSub
                    title='Project & Task'
                    icon='/media/icons/duotune/art/pass.svg'
                    fontIcon='bi-person'
                  >
                    <AsideMenuItem
                      to='markTaskCompletion'
                      title='Task Completion'
                      fontIcon='bi-archive'
                      icon='/media/icons/duotune/art/journal-check.svg'
                    />
                  </AsideMenuItemWithSub>
                )}

                <AsideMenuItemWithSub
                  title='Time Claim'
                  icon='/media/icons/duotune/general/clock-history.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='TimeClaim/claim'
                    icon='/media/icons/duotune/general/claim.svg'
                    title='Claim'
                  />
                  <AsideMenuItem
                    to='TimeClaim/request'
                    icon='/media/icons/duotune/general/request.svg'
                    title='Request'
                  />
                  <AsideMenuItem
                    to='TimeClaim/status'
                    icon='/media/icons/duotune/general/updateTimeClaim.svg'
                    title='Status'
                  />
                </AsideMenuItemWithSub>

                {ConfigDetails.projectTaskConfig && (
                  <AsideMenuItem
                    to='/timeEntry'
                    title='Time Entry'
                    icon='/media/icons/duotune/general/userTimeEntry.svg'
                  />
                )}
                {/* <AsideMenuItemWithSub
                to='/activities'
                icon='/media/icons/duotune/art/fullscreen.svg'
                title='Screenshots'
                fontIcon='bi-app-indicator'
              >
                <AsideMenuItem to='activities/screenshots' icon='/media/icons/duotune/art/activity.svg ' title='Screenshots' />

              </AsideMenuItemWithSub> */}
                <AsideMenuItem
                  to='/holiday'
                  title='Holiday'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/art/holidayCalendar.svg'
                />
                <AsideMenuItem
                  to='support'
                  icon='/media/icons/duotune/art/support.svg'
                  title='Support'
                />
                {/* <AsideMenuItemWithSub
              title='Web&Apps'
              icon='/media/icons/duotune/art/people.svg'
              fontIcon='bi-person'
            >
              <AsideMenuItem to='/manager_records' title='Web&Apps' icon='/media/icons/duotune/art/member.svg' />
              <AsideMenuItem to='/shift' title='Shift' icon="/media/icons/duotune/art/shift.svg" />
            </AsideMenuItemWithSub> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: '5px',
                }}
              >
                {/* <div style={{ marginLeft: '25.5px' }}>
                <a href='https://www.aapnainfotech.com/about-us/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>About</span>
                </a>

              </div>
              <div style={{ marginLeft: '25.5px', paddingTop: '2.5px' }}>
                <a href='https://www.aapnainfotech.com/contact/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>Contact</span>
                </a>
              </div>
              <div style={{ marginLeft: '25.5px', paddingTop: '2.5px' }}>
                <a href='https://www.aapnainfotech.com/work/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>Our Work</span>
                </a>
              </div> */}
                <div
                  className='position-fixed text-white bg-black bottom-0'
                  style={{width: '17rem'}}
                >
                  <div className='d-flex justify-content-center py-1'>
                    <span>{new Date().getFullYear()} &copy;</span>
                    <span>AapnaInfotech</span>
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          //////////////////////////////////Report Admin/////////////////////////////////////
          role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE && (
            <>
              <div
                className='admin-main-container scroller-issue'
                style={{overflow: 'auto', marginBottom: '6rem'}}
              >
                <div className='menu-item'>
                  <div className='menu-content  pb-2' style={{paddingTop: '1.2rem'}}>
                    <span className='menu-section text text-uppercase fs-8 ls-1'>Report Admin</span>
                  </div>
                </div>

                {/* <AsideMenuItem
          to='/timesheet/daily'
          icon='/media/icons/duotune/art/timesheet.svg'
          title='Timesheets'
          fontIcon='bi-app-indicator'>
          <AsideMenuItem to='/timesheet/daily' title='Daily' hasBullet={true} />

          <AsideMenuItem to='/crafted/pages/profile/overview' title='Calender' hasBullet={true} />

        </AsideMenuItem> */}

                <AsideMenuItemWithSub
                  title='Report'
                  icon='/media/icons/duotune/art/report.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='/report/timeTracker'
                    icon='/media/icons/duotune/art/attendance.svg'
                    title='Time Tracker'
                  />
                  <AsideMenuItem
                    to='/report/urls'
                    title='System Activity'
                    icon='/media/icons/duotune/art/urls.svg'
                  />
                  <AsideMenuItem
                    to='/report/systemActivity'
                    title='Activity Summary'
                    icon='/media/icons/duotune/art/activitySummary.svg'
                  />
                  <AsideMenuItem
                    to='/report/weeklySummary'
                    title='Weekly Summary'
                    icon='/media/icons/duotune/general/notebook.svg'
                  />

                  <AsideMenuItem
                    to='/report/productivitys'
                    icon='/media/icons/duotune/art/bar-chart-fill.svg'
                    title='Productivity vs Idle'
                  />
                  <AsideMenuItem
                    to='/admin_records'
                    title='Web & Apps'
                    icon='/media/icons/duotune/art/web&app.svg'
                  />

                  {/* <AsideMenuItem to='/schedule' title='Schedule' icon='/media/icons/duotune/art/schedule.svg' /> */}
                  {/* <AsideMenuItem to='/web&app' title='Web and App' icon='/media/icons/duotune/art/schedule.svg' />
              <AsideMenuItem to='/empAttendance' title='Attendance' icon='/media/icons/duotune/art/schedule.svg' /> */}
                  <AsideMenuItem
                    to='/report/attendance'
                    title='Attendance'
                    icon='/media/icons/duotune/general/attendance.svg'
                  />
                  <AsideMenuItem
                    to='/report/timeline'
                    title='Timeline'
                    icon='/media/icons/duotune/art/shift.svg'
                  />
                  {/* <AsideMenuItem
                    to='/report/dispPrjDetails'
                    title='Project'
                    icon='/media/icons/duotune/general/card-list.svg'
                  /> */}
                  {ConfigDetails.projectTaskConfig && (
                    <>
                      <AsideMenuItem
                        to='/report/dailyTimeEntry'
                        title='Daily Time Entry'
                        icon='/media/icons/duotune/general/TimeEntries.svg'
                      />
                      <AsideMenuItem
                        to='userTaskReport'
                        title='User Task Report'
                        icon='/media/icons/duotune/art/userTask.svg'
                      />
                    </>
                  )}

                  <AsideMenuItem
                    to='/report/ProdVsUnpro'
                    title='Productive Vs Unproductive'
                    icon='/media/icons/duotune/general/overview.svg'
                  />

                  {/* <AsideMenuItem to='/apps' title='Apps' icon='/media/icons/duotune/art/apps.svg' /> */}
                </AsideMenuItemWithSub>

                <AsideMenuItem
                  to='screenshots'
                  icon='/media/icons/duotune/art/fullscreen.svg'
                  title='Screenshot'
                />

                <AsideMenuItem
                  to='liveStreaming'
                  icon='/media/icons/duotune/general/liveStreaming.svg'
                  title='Live Stream'
                />

                {/* <AsideMenuItemWithSub
                to='/activities'
                icon='/media/icons/duotune/art/fullscreen.svg'
                title='Screenshots'
                fontIcon='bi-app-indicator'
              >
                <AsideMenuItem to={`activities/screenshots`} icon='/media/icons/duotune/art/activity.svg ' title='Screenshots' />
                <AsideMenuItem to='activities/dwonload' icon='/media/icons/duotune/art/download.svg ' title='Download' />

              </AsideMenuItemWithSub> */}
                {/* <AsideMenuItem
              to='/crafted/account/settings'
              title='Settings'
              icon='/media/icons/duotune/art/setting.svg'
              fontIcon='bi-app-indicator'
            /> */}
                {checkPrjTaskStatus && (
                  <AsideMenuItemWithSub
                    title='Project & Task'
                    icon='/media/icons/duotune/art/pass.svg'
                    fontIcon='bi-person'
                  >
                    <AsideMenuItem
                      to='/project'
                      title='Project'
                      icon='/media/icons/duotune/art/project.svg'
                    />

                    {/* <AsideMenuItem
                      to='/type'
                      title='Project Type & Task Type'
                      icon='/media/icons/duotune/general/folder-plus.svg'
                    /> */}
                    <AsideMenuItem
                      to='/task'
                      title='Task'
                      icon='/media/icons/duotune/art/journal-check.svg'
                    />
                  </AsideMenuItemWithSub>
                )}

                <AsideMenuItemWithSub
                  title='Time Claim'
                  icon='/media/icons/duotune/general/clock-history.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='TimeClaim/claim'
                    icon='/media/icons/duotune/general/claim.svg'
                    title='Claim'
                  />

                  <AsideMenuItem
                    to='TimeClaim/status'
                    icon='/media/icons/duotune/general/updateTimeClaim.svg'
                    title='Status'
                  />
                </AsideMenuItemWithSub>

                {/* <AsideMenuItemWithSub
                  title='Member'
                  icon='/media/icons/duotune/art/people.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='/member'
                    title='Member'
                    icon='/media/icons/duotune/art/member.svg'
                  />

                  <AsideMenuItem
                    to='/changeMan'
                    title='Change Manager'
                    icon='/media/icons/duotune/art/manager-1.svg'
                  />

                </AsideMenuItemWithSub> */}

                {/* <AsideMenuItemWithSub
                  title='Time Claim'
                  icon='/media/icons/duotune/general/clock-history.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem
                    to='TimeClaim/claim'
                    icon='/media/icons/duotune/general/claim.svg'
                    title='Claim'
                  />
                  <AsideMenuItem
                    to='TimeClaim/request'
                    icon='/media/icons/duotune/general/request.svg'
                    title='Request'
                  />
                  <AsideMenuItem
                    to='TimeClaim/status'
                    icon='/media/icons/duotune/general/updateTimeClaim.svg'
                    title='Status'
                  />
                </AsideMenuItemWithSub> */}

                {/* <AsideMenuItem
                  to='/department'
                  title='Department'
                  fontIcon='bi-app-indicator'
                  icon='/media/icons/duotune/art/corporate.svg'
                /> */}

                <AsideMenuItem
                  to='/holiday'
                  title='Holiday'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/art/holidayCalendar.svg'
                />
                <AsideMenuItem
                  to='support'
                  icon='/media/icons/duotune/art/support.svg'
                  title='Support'
                />
                {/* <AsideMenuItem
                to='/transactionDetails'
                title='Payment Details'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/art/holidayCalendar.svg'
              /> */}
                {/* <AsideMenuItem
                  to='/WebAppCatalogue'
                  title='Category'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/art/catalogue.svg'
                /> */}

                {/* <AsideMenuItem
                  to='/config'
                  title='Configuration'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/art/setting.svg'
                /> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: '5px',
                }}
              >
                {/* <div style={{ marginLeft: '25.5px' }}>
                <a href='https://www.aapnainfotech.com/about-us/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>About</span>
                </a>

              </div>
              <div style={{ marginLeft: '25.5px', paddingTop: '2.5px' }}>
                <a href='https://www.aapnainfotech.com/contact/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>Contact</span>
                </a>
              </div>
              <div style={{ marginLeft: '25.5px', paddingTop: '2.5px' }}>
                <a href='https://www.aapnainfotech.com/work/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>Our Work</span>
                </a>
              </div> */}
                <div
                  className='position-fixed text-white bg-black bottom-0'
                  style={{width: '17rem'}}
                >
                  <div className='d-flex justify-content-center py-1'>
                    <span>{new Date().getFullYear()} &copy;</span>
                    <span>AapnaInfotech</span>
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          //////////////////////////////////Partner/////////////////////////////////////
          role === process.env.REACT_APP_SYSTEM_SIXTH_ROLE && (
            <>
              <div
                className='admin-main-container scroller-issue'
                style={{overflow: 'auto', marginBottom: '6rem'}}
              >
                <div className='menu-item'>
                  <div className='menu-content  pb-2' style={{paddingTop: '1.2rem'}}>
                    <span className='menu-section text text-uppercase fs-8 ls-1'>Partner</span>
                  </div>
                </div>

                <AsideMenuItem
                  to='/organizations'
                  title='Organization'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/art/company.svg'
                />

                <AsideMenuItem
                  to='ExistingPlan'
                  icon='/media/icons/duotune/general/existingPlan.svg'
                  title='Payment'
                />
                <AsideMenuItem
                  to='commission'
                  icon='/media/icons/duotune/general/commission.svg'
                  title='Commission'
                />
                {/* <AsideMenuItem
                  to='documents'
                  icon='/media/icons/duotune/general/documents.svg'
                  title='Documents'
                /> */}
                <AsideMenuItem
                  to='/documents'
                  title='Documents'
                  icon='/media/icons/duotune/general/documents.svg'
                />

                {/* <AsideMenuItem
                  to='builder'
                  icon='/media/icons/duotune/art/admin.svg'
                  title='Teams Dashboard'
                /> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: '5px',
                }}
              >
                {/* <div style={{ marginLeft: '25.5px' }}>
                <a href='https://www.aapnainfotech.com/about-us/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>About</span>
                </a>

              </div>
              <div style={{ marginLeft: '25.5px', paddingTop: '2.5px' }}>
                <a href='https://www.aapnainfotech.com/contact/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>Contact</span>
                </a>
              </div>
              <div style={{ marginLeft: '25.5px', paddingTop: '2.5px' }}>
                <a href='https://www.aapnainfotech.com/work/' style={{ color: 'white' }}>
                  <span className='cursor-pointer'>Our Work</span>
                </a>
              </div> */}
                <div
                  className='position-fixed text-white bg-black bottom-0'
                  style={{width: '17rem'}}
                >
                  <div className='d-flex justify-content-center py-1'>
                    <span>{new Date().getFullYear()} &copy;</span>
                    <span>AapnaInfotech</span>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </>
    )
  } else {
    return (
      //////////////////////////////////USER/////////////////////////////////////
      <>
        <div
          className='admin-main-container scroller-issue'
          style={{overflow: 'auto', marginBottom: '6rem'}}
        >
          <AsideMenuItem
            to='/builder'
            title='Dashboard'
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/dashboard.svg'
          />

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text text-uppercase fs-8 ls-1'>User</span>
            </div>
          </div>
          {/* <AsideMenuItem
          to='/timesheet/daily'
          icon='/media/icons/duotune/art/timesheet.svg'
          title='Timesheets'
          fontIcon='bi-app-indicator'
        >
          <AsideMenuItem to='/timesheet/daily' title='Daily' hasBullet={true} />

          <AsideMenuItem to='/crafted/pages/profile/overview' title='Calender' hasBullet={true} />

        </AsideMenuItem> */}

          <AsideMenuItemWithSub
            title='Report'
            icon='/media/icons/duotune/art/report.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='/report/timeTracker'
              icon='/media/icons/duotune/art/attendance.svg'
              title='Time Tracker'
            />
            <AsideMenuItem
              to='/report/urls'
              title='System Activity'
              icon='/media/icons/duotune/art/urls.svg'
            />
            <AsideMenuItem
              to='/report/systemActivity'
              title='Activity Summary'
              icon='/media/icons/duotune/art/activitySummary.svg'
            />

            <AsideMenuItem
              to='/report/weeklySummary'
              title='Weekly Summary'
              icon='/media/icons/duotune/general/notebook.svg'
            />
            <AsideMenuItem
              to='/report/productivity'
              title='Productivity vs Idle'
              icon='/media/icons/duotune/art/bar-chart-fill.svg'
            />

            <AsideMenuItem
              to='/records'
              title='Web&Apps'
              icon='/media/icons/duotune/art/web&app.svg'
            />
            <AsideMenuItem
              to='/report/attendance'
              title='Attendance'
              icon='/media/icons/duotune/general/attendance.svg'
            />
            <AsideMenuItem
              to='/report/timeline'
              title='Timeline'
              icon='/media/icons/duotune/art/shift.svg'
            />
            {ConfigDetails.projectTaskConfig && (
              <>
                <AsideMenuItem
                  to='/report/dailyTimeEntry'
                  title='Daily Time Entry'
                  icon='/media/icons/duotune/general/TimeEntries.svg'
                />
                <AsideMenuItem
                  to='userTaskReport'
                  title='User Task Report'
                  icon='/media/icons/duotune/art/userTask.svg'
                />
              </>
            )}
            {/* <AsideMenuItem to='/web&app/individual' title='Web and App' icon='/media/icons/duotune/art/schedule.svg' /> */}
          </AsideMenuItemWithSub>

          <AsideMenuItem
            to='screenshots'
            icon='/media/icons/duotune/art/fullscreen.svg'
            title='Screenshot'
          />
          {ConfigDetails.projectTaskConfig && (
            <AsideMenuItemWithSub
              title='Project & Task'
              icon='/media/icons/duotune/art/pass.svg'
              fontIcon='bi-person'
            >
              <AsideMenuItem
                to='markTaskCompletion'
                title='Task Completion'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/art/journal-check.svg'
              />
            </AsideMenuItemWithSub>
          )}

          <AsideMenuItemWithSub
            title='Time Claim'
            icon='/media/icons/duotune/general/clock-history.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='TimeClaim/claim'
              icon='/media/icons/duotune/general/claim.svg'
              title='Claim'
            />

            <AsideMenuItem
              to='TimeClaim/status'
              icon='/media/icons/duotune/general/updateTimeClaim.svg'
              title='Status'
            />
          </AsideMenuItemWithSub>

          {ConfigDetails.projectTaskConfig && (
            <AsideMenuItem
              to='/timeEntry'
              title='Time Entry'
              icon='/media/icons/duotune/general/userTimeEntry.svg'
            />
          )}
          {/* <AsideMenuItemWithSub
            to='/activities'
            icon='/media/icons/duotune/art/fullscreen.svg'
            title='Screenshots'
            fontIcon='bi-app-indicator'
          >
            <AsideMenuItem to='activities/screenshots' icon='/media/icons/duotune/art/activity.svg ' title='Screenshots' />

          </AsideMenuItemWithSub> */}
          {/* <AsideMenuItem
          to='/crafted/account/settings'
          title='Settings'
          icon='/media/icons/duotune/art/setting.svg'
          fontIcon='bi-app-indicator'
        /> */}

          <AsideMenuItem
            to='/holiday'
            title='Holiday'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/art/holidayCalendar.svg'
          />
          <AsideMenuItem to='support' icon='/media/icons/duotune/art/support.svg' title='Support' />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            position: 'fixed',
            bottom: '5px',
          }}
        >
          <div className='position-fixed text-white bg-black bottom-0' style={{width: '17rem'}}>
            <div className='d-flex justify-content-center py-1'>
              <span>{new Date().getFullYear()} &copy;</span>
              <span>AapnaInfotech</span>
            </div>
          </div>
        </div>
      </>
    )
  }
}
