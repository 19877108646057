import React, {useState} from 'react'
import '../../../css/website.css'
import {Loader} from '../../CommonFunctions/CommonFunction'
import '../../../css/help.css'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import ReactPlayer from 'react-player'

const Help = () => {
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [docFiles, setDocFiles] = useState<any>({})
  const [selectedOrg, setSelectedOrg] = useState<any>(null)
  const [fileUrl, setFileUrl] = useState('')
  const [fileName, setFileName] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [spinner, setSpinner] = useState(false)

  const videoArray = [
    {
      label: 'Introduction',
      value: '/media/video/help/Mera Monitor Intro Video.mp4',
    },
    {
      label: 'Add Member',
      value: '/media/video/help/Add Members.mp4',
    },
    {
      label: 'Time Tracker',
      value: '/media/video/help/Time Tracker.mp4',
    },
    {
      label: 'System Activity',
      value: '/media/video/help/System Activity.mp4',
    },
    {
      label: 'Activity Summary',
      value: '/media/video/help/Activity Summary.mp4',
    },
    {
      label: 'Productivity vs Idle',
      value: '/media/video/help/Productivity vs Idle.mp4',
    },
    {
      label: 'Web & Apps',
      value: '/media/video/help/Web & Apps.mp4',
    },
    {
      label: 'Attendance',
      value: '/media/video/help/Attendance.mp4',
    },
    {
      label: 'Timeline',
      value: '/media/video/help/Timeline.mp4',
    },
    {
      label: 'Screenshots',
      value: '/media/video/help/Mera Monitor Video Screenshots.mp4',
    },
    {
      label: 'Live Streaming and Live Recording',
      value: '/media/video/help/Mera Monitor Video Live Streaming and Recording.mp4',
    },
    {
      label: 'Configuration',
      value: '/media/video/help/Mera Monitor Video Configuration.mp4',
    },
    {
      label: 'User Level Control',
      value: '/media/video/help/Mera Monitor Video User Level Control.mp4',
    },
  ]

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 pt-5'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px 16px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-2 mb-1'>Videos</span>
            </h3>
          </div>
        </div>

        <div className='card-body py-3 d-flex' style={{padding: '40px', flexWrap: 'wrap'}}>
          {spinner && Loader(0)}
          {!spinner &&
            docFiles !== undefined &&
            videoArray.length > 0 &&
            videoArray.map((list, index) => (
              <div key={index} className='help-video'>
                <div>
                  <div className='help-video__image-main'>
                    <div className='help-video__image-container'>
                      <img
                        src='/media/icons/duotune/technology/video.png'
                        height={28}
                        width={28}
                        className='svg-icon-1 help-video__doc-type'
                      />
                    </div>
                    <div
                      className='help-video__file-container'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div className='help-video__file-details'>
                        <span
                          className='help-video__file-name'
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            setFileUrl(list.value)
                            setFileName(list.label)
                            setModalOpen(true)
                          }}
                        >
                          {list.label}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {!spinner && videoArray.length === 0 && (
          <div>
            <h2 className='noRecordFound'>No Records Found</h2>
          </div>
        )}
        <style>
          {`
            .custom-select-class {
              text-align: center;
              width: 14rem;
            }
            .select-custom-header {
              margin-right: 1rem;
              line-height: 3rem;
            }
            .card-toolbar > .css-b62m3t-container > div {
              width: 14rem !important;
            }
          `}
        </style>
      </div>
      {modalOpen && (
        <Modal open={modalOpen} onClose={() => setModalOpen(false)} center>
          <h2>{fileName}</h2>
          <ReactPlayer url={fileUrl} controls playing width='100%' height='100%' />
        </Modal>
      )}
    </div>
  )
}

export {Help}
