import React, {useState, useEffect} from 'react'

import {useFormik} from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  AlertModal,
  GetOrgList,
  handleOrgAdd,
  planSchema,
} from '../../CommonFunctions/CommonFunction'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'

import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import moment from 'moment'
import dayjs from 'dayjs'

const API = process.env.REACT_APP_API_URL

interface SelectedValues {
  organization: any // Adjust the type according to your needs
  date: any // Adjust the type according to your needs
}

const AddNewPlan: React.FC = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [benefits, setBenefits] = useState<any[]>([])
  const [planCurrency, setPlanCurrency] = useState<any[]>([])
  const [selectedPlanCurrency, setSelectedPlanCurrency] = useState<any[]>([])
  const [planDuration, setPlanDuration] = useState<any[]>([])
  const [selectedValues, setSelectedValues] = useState<SelectedValues[]>([])
  const [orgCount, setOrgCount] = useState(1)
  const [isCustom, setIsCustom] = useState<{label: string; value: boolean}[]>([
    {label: 'No', value: false},
  ])

  const [organizationDropdown, setOrganizationDropdown] = useState<any[]>([])
  const [filteredDropdown, setFilteredDropdown] = useState<any[]>([])
  const [orgErrors, setOrgErrors] = useState(Array.from({length: orgCount}, () => false))
  const [dateErrors, setDateErrors] = useState(Array.from({length: orgCount}, () => false))

  useEffect(() => {
    axios
      .get(`${API}/PlanTypes/GetAllActivePlanTypesList`)
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.planTypeName,
            value: item.planTypeId,
          }
        })
        setPlanDuration(newList)
      })
      .catch((err) => console.error)

    axios
      .get(`${API}/PlanFeatures/GetAllActivePlanFeatures`)
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.planFeature,
            value: item.planFeatureId,
          }
        })
        setBenefits(newList)
      })
      .catch((err) => console.error)

    axios
      .get(`${API}/CurrencyCodes/GetAllActiveCurrencyCodeList`)
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.currencyCode,
            value: item.currencyCodeId,
          }
        })

        const defaultCurrencry = newList.find((data: any) => data.label === 'INR')
        if (defaultCurrencry !== undefined && Object.keys(defaultCurrencry).length > 0) {
          formik.setFieldValue('currencyCodeId', defaultCurrencry.value)
          setSelectedPlanCurrency(defaultCurrencry)
        }
        setPlanCurrency(newList)
      })
      .catch((err) => console.error)
  }, [])

  const initialValues = {
    planName: '',
    planTypeId: '',
    planDescription: '',
    currencyCodeId: '',
    amount: '',
    benefitIds: [],
    isCustomPlan: false,
    customBillingPlans: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: planSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setLoading(true)

      if (values.isCustomPlan === true && selectedValues.length === 0) {
        // Set setOrgErrors to true at index 0
        const updatedErrors = [...orgErrors]
        updatedErrors[0] = true
        setOrgErrors(updatedErrors)

        setLoading(false)
        return
      } else if (values.isCustomPlan === true) {
        const lastIdx = orgCount - 1
        const lastOrg = selectedValues[lastIdx]?.organization
        const lastDate = selectedValues[lastIdx]?.date

        if (!lastOrg) {
          const updatedErrors = [...orgErrors]
          updatedErrors[lastIdx] = true
          setOrgErrors(updatedErrors)
          setLoading(false)
          return
        } else if (!lastDate) {
          console.error('Last entry does not contain the "date" key.')
          setLoading(false)
          const updatedErrors = [...dateErrors]
          updatedErrors[lastIdx] = true
          setDateErrors(updatedErrors)
          return
        }
      }

      let arr: any = []

      for (let i = 0; i < selectedValues.length; i++) {
        arr.push({
          organizationId: selectedValues[i].organization.value,
          effectiveFromDate: dayjs(selectedValues[i].date).format('YYYY-MM-DDThh:MM:ssZ'),
        })
      }

      values.customBillingPlans = arr
      const body = {...values, amount: String(values.amount)}
      axios
        .post(`${API}/BillingPlan/AddBillingPlan`, body)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            navigate('/allPlans')
            resetForm()
            setLoading(false)
          })
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setSubmitting(false)
        })

      // resetForm()
      setLoading(false)
    },
  })

  useEffect(() => {
    getOrg()
  }, [])

  const getOrg = async () => {
    try {
      const newList = await GetOrgList()
      setOrganizationDropdown(newList)
      setFilteredDropdown(newList)
    } catch (error) {
      console.log(error)
      setOrganizationDropdown([])
      setFilteredDropdown([])
    }
  }

  //to handle the change in the organization

  // to handle the change in the date
  const handleDateChange = (date: any, index: number) => {
    const updatedValues = [...selectedValues]

    updatedValues[index] = {...updatedValues[index], date: date}
    // Reset the error for the specific index
    const updatedErrors = [...dateErrors]
    updatedErrors[index] = false
    setDateErrors(updatedErrors)

    setSelectedValues(updatedValues)
  }

  // Function to handle organization change for a specific index
  const handleOrganizationDropChange = (item: any, index: number) => {
    const updatedValues = [...selectedValues]

    updatedValues[index] = {...updatedValues[index], organization: item}

    setSelectedValues(updatedValues)

    // Reset the error for the specific index
    const updatedErrors = [...orgErrors]
    updatedErrors[index] = false
    setOrgErrors(updatedErrors)

    const updatedDropdown = organizationDropdown.filter(
      (data) => !updatedValues.some((value) => value.organization === data)
    )
    setFilteredDropdown(updatedDropdown)
  }

  const handleOrgRemove = (index: number) => {
    const updatedValues = [...selectedValues]
    updatedValues.splice(index, 1)
    setSelectedValues(updatedValues)
    const updatedDropdown = organizationDropdown.filter(
      (data) => !updatedValues.some((value) => value.organization === data)
    )
    setFilteredDropdown(updatedDropdown)
    if (orgCount > 1) {
      setOrgCount(orgCount - 1)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0' role='banner'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Plan Details</h3>
        </div>
      </div>

      <div className='collapse show'>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Plan Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-border '
                  placeholder='Plan Name'
                  {...formik.getFieldProps('planName')}
                />
                {formik.touched.planName && formik.errors.planName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.planName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Description</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-border '
                  placeholder='Description'
                  {...formik.getFieldProps('planDescription')}
                />
                {formik.touched.planDescription && formik.errors.planDescription && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.planDescription}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                <span className=''>Duration</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <div
                  className='min-w-200px'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Currency'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    options={planDuration}
                    onBlur={formik.handleBlur}
                    placeholder='Select Duration'
                    onChange={(item: any) => {
                      formik.setFieldValue('planTypeId', item.value)
                    }}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
                <div>
                  {formik.touched.planTypeId && formik.errors.planTypeId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.planTypeId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Currency</label>
              <div className='col-lg-8 fv-row'>
                <div
                  className='min-w-200px'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Currency'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    options={planCurrency}
                    value={selectedPlanCurrency}
                    onBlur={formik.handleBlur}
                    placeholder='Select Currency'
                    onChange={(item: any) => {
                      setSelectedPlanCurrency(item)
                      formik.setFieldValue('currencyCodeId', item.value)
                    }}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
                <div>
                  {formik.touched.currencyCodeId && formik.errors.currencyCodeId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.currencyCodeId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Amount</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='number'
                  className='form-control form-control-border '
                  placeholder='Amount'
                  {...formik.getFieldProps('amount')}
                />
                {formik.touched.amount && formik.errors.amount && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.amount}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 '>Benefits</label>
              <div className='col-lg-8 fv-row'>
                <div
                  className='min-w-200px'
                  //style={{ width: '200px' }}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Department'
                >
                  <Select
                    className='selectDropdown'
                    isMulti
                    components={makeAnimated()}
                    options={benefits}
                    onBlur={formik.handleBlur}
                    placeholder='Benefits'
                    onChange={(item: any) => {
                      const array = item.map((item: any) => item.value)
                      formik.setFieldValue('benefitIds', array)
                    }}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                    closeMenuOnSelect={false}
                  />
                </div>
                <div>
                  {formik.touched.currencyCodeId && formik.errors.currencyCodeId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.currencyCodeId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Custom Plan</label>
              <div className='col-lg-8 fv-row'>
                <div
                  className='min-w-200px'
                  //style={{ width: '200px' }}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Department'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    options={[
                      {label: 'Yes', value: true},
                      {label: 'No', value: false},
                    ]}
                    value={isCustom}
                    onBlur={formik.handleBlur}
                    menuPlacement='auto'
                    menuPosition='fixed'
                    placeholder='Custom Plan'
                    onChange={(item: any) => {
                      if (item.value === false) {
                        setSelectedValues([])
                        setOrgCount(1)
                        setFilteredDropdown(organizationDropdown)
                      }
                      setIsCustom(item)
                      formik.setFieldValue('isCustomPlan', item !== null ? item.value : '')
                      //SendDataOfUser(item.value)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
                <div>
                  {formik.touched.isCustomPlan && formik.errors.isCustomPlan && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.isCustomPlan}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {formik.getFieldProps('isCustomPlan').value === true ? (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                  Organization
                </label>

                <div className='col-lg-8 fv-row'>
                  {Array.from({length: orgCount}, (_, index) => (
                    <div className='mt-2'>
                      <div className='d-flex ' style={{justifyContent: 'space-between'}}>
                        <div
                          className='w-350px'
                          //style={{ width: '200px' }}
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          data-bs-trigger='hover'
                          title='Search Organization'
                        >
                          <Select
                            className='selectDropdown'
                            components={makeAnimated()}
                            options={filteredDropdown}
                            value={selectedValues[index]?.organization}
                            onBlur={formik.handleBlur}
                            placeholder='Select Organization'
                            menuPlacement='auto'
                            menuPosition='fixed'
                            onChange={(item: any) => {
                              // setSelectedOrg(item)
                              handleOrganizationDropChange(item, index)
                              //SendDataOfUser(item.value)
                            }}
                            isClearable={true}
                            isSearchable={true}
                            closeMenuOnScroll={true}
                          />
                          {orgErrors[index] && (
                            <span className='text-danger' style={{marginLeft: '1rem'}}>
                              Please select an organization
                            </span>
                          )}
                        </div>

                        <div
                          className='w-350px'
                          //style={{ width: '200px' }}
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          data-bs-trigger='hover'
                          title='Search Organization'
                        >
                          <DatePicker
                            // className='form-control custom-Height'

                            className='form-control w-100'
                            dateFormat='dd/MM/yyyy'
                            selected={selectedValues[index]?.date}
                            onChange={(date: any) => {
                              handleDateChange(date, index)
                            }}
                            isClearable={false}
                            placeholderText='Select an effective date'
                            maxDate={moment().add(1, 'year').toDate()}
                            minDate={new Date()}
                            onKeyDown={(e) => {
                              e.preventDefault()
                            }}
                          />
                          {dateErrors[index] && (
                            <span className='text-danger' style={{marginLeft: '1rem'}}>
                              Please Select an effective date
                            </span>
                          )}
                        </div>
                        <div style={{paddingTop: '0.5rem'}}>
                          {index !== 0 && (
                            <i
                              className='fa fa-times'
                              onClick={() => handleOrgRemove(index)}
                              aria-hidden='true'
                              style={{
                                fontSize: '1.5rem',
                                display: 'flex',
                                flexDirection: 'column',

                                cursor: 'pointer',
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                      {/* <div>
                        {formik.touched.organization && formik.errors.organization && (
                          <div className='fv-plugins-message-container'>
                            <div className='text-danger mt-2'>{formik.errors.organization}</div>
                          </div>
                        )}
                      </div> */}
                    </div>
                  ))}

                  <button
                    className='btn btn-primary mt-2'
                    type='button'
                    onClick={() =>
                      handleOrgAdd(
                        orgCount,
                        selectedValues,
                        setOrgCount,
                        orgErrors,
                        setOrgErrors,
                        dateErrors,
                        setDateErrors
                      )
                    }
                  >
                    Add More Organization
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className='card-body py-3'>{/* begin::Table container */}</div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => navigate('/allPlans')}
            >
              Cancel
            </button>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && <span className='indicator-label'>Add Plan</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddNewPlan
