/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

import image2 from '../../../../images/60111.jpg'
import {
  Pagination,
  Loader,
  RowsPerPage,
  AlertModal,
  deleteHandler,
} from '../../../CommonFunctions/CommonFunction'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const InactiveMember = () => {
  const API_URL = process.env.REACT_APP_API_URL

  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const [list, setList] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [forcePage, setForcePage] = useState<any>(null)
  const [filteredMembers, setFilteredMembers] = useState<any[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [currentData, setCurrentData] = useState<any[]>([])
  const [sortingOrders, setSortingOrders] = useState<{[key: string]: 'asc' | 'desc'}>({})

  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)
  useEffect(() => {
    let updatedFilteredMembers = list.filter(
      (member: any) =>
        member.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        member.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
    const newEndOffset = itemOffset + itemsPerPage
    const item = updatedFilteredMembers.slice(itemOffset, newEndOffset)
    const count = Math.ceil(updatedFilteredMembers.length / itemsPerPage)
    setFilteredMembers(updatedFilteredMembers)
    setCurrentData(item)
    setPageCount(count)
  }, [list, searchQuery, itemOffset, itemsPerPage])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % list.length
    setItemOffset(newOffset)
  }

  const listOfInactiveUsers = () => {
    axios
      .get(`${API_URL}/Registration/InactiveUsersByOrganization`, {
        params: {
          organizationId: localStorage.getItem('org_Id'),
        },
      })
      .then((res) => {
        setList(res.data)
        setSpinner(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    setSpinner(true)
    listOfInactiveUsers()
  }, [])

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(currentData.map((li: any) => li.userId))
    if (isCheckAll) {
      setIsCheck([])
    }
  }

  const handleClick = (e: any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id))
    }
    if (isCheck) {
      setIsCheckAll(false)
    }
  }

  const activateUser = () => {
    setLoading(true)
    if (isCheck.length == 0) {
      setLoading(false)
    } else {
      const body = {
        userId: isCheck,
      }
      axios
        .post(`${API_URL}/Registration/ActivateUser`, body)
        .then((res) => {
          listOfInactiveUsers()
          AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok')

          setLoading(false)
          setIsCheck([])
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value)
    setItemOffset(0)
  }

  const handleSorting = (sortingType: string) => {
    const currentSortingOrder = sortingOrders[sortingType] || 'asc'

    const newSortingOrder = currentSortingOrder === 'asc' ? 'desc' : 'asc'

    let sortedItems = [...currentData]

    switch (sortingType) {
      case 'Name':
        sortedItems.sort((a, b) => compareStrings(a.fullName, b.fullName, newSortingOrder))
        break
      case 'Manager':
        sortedItems.sort((a, b) => compareStrings(a.managerName, b.managerName, newSortingOrder))
        break
      case 'EmpId':
        sortedItems.sort((a, b) => compareStrings(a.empId, b.empId, newSortingOrder))
        break
      case 'Email':
        sortedItems.sort((a, b) => compareStrings(a.email, b.email, newSortingOrder))
        break

      default:
        sortedItems.sort((a, b) => compareStrings(a.fullName, b.fullName, newSortingOrder))
        break
    }

    setCurrentData(sortedItems)

    setSortingOrders({
      ...sortingOrders,
      [sortingType]: newSortingOrder,
    })
  }

  const compareStrings = (a: string, b: string, order: string) => {
    const aValue = a || ''
    const bValue = b || ''

    return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Member</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
          <div className='d-flex'>
            {spinner ? (
              ''
            ) : (
              <Tippy placement='top' content='Search Members by name or email'>
                <div
                  className='card-toolbar'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                >
                  <div style={{position: 'relative'}}>
                    <input
                      style={{
                        marginRight: '1rem',
                        padding: '6px 30px 6px 3rem',
                        minHeight: 'unset',
                      }}
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Search by Name or Email'
                      onChange={handleSearchChange}
                    />
                    <img
                      style={{top: '8px'}}
                      className='custom-placeholder'
                      src={toAbsoluteUrl('/media/icons/duotune/art/custom-search.png')}
                    ></img>
                  </div>
                </div>
              </Tippy>
            )}
          </div>
        </div>

        {spinner ? (
          Loader('0px')
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder'>
                    <th></th>
                    <th className='min-w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          name='selectAll'
                          id='selectAll'
                          onChange={handleSelectAll}
                          checked={isCheckAll}
                        />
                      </div>
                    </th>

                    <th className='min-w-125px'>
                      Name&nbsp;
                      <Tippy placement='top' content='Sort by Name'>
                        <span className='cursor-pointer' onClick={() => handleSorting('Name')}>
                          <KTSVG path='/media/icons/duotune/art/sort.svg' className='svg-icon-3' />
                        </span>
                      </Tippy>
                    </th>
                    <th className='min-w-125px'>
                      Manager&nbsp;
                      <Tippy placement='top' content='Sort by Manager'>
                        <span className='cursor-pointer' onClick={() => handleSorting('Manager')}>
                          <KTSVG path='/media/icons/duotune/art/sort.svg' className='svg-icon-3' />
                        </span>
                      </Tippy>
                    </th>
                    <th className='min-w-125px'>
                      Employee Id&nbsp;
                      <Tippy placement='top' content='Sort by Employee Id'>
                        <span className='cursor-pointer' onClick={() => handleSorting('EmpId')}>
                          <KTSVG path='/media/icons/duotune/art/sort.svg' className='svg-icon-3' />
                        </span>
                      </Tippy>
                    </th>
                    <th className='min-w-125px'>
                      Email&nbsp;
                      <Tippy placement='top' content='Sort by Email'>
                        <span className='cursor-pointer' onClick={() => handleSorting('Email')}>
                          <KTSVG path='/media/icons/duotune/art/sort.svg' className='svg-icon-3' />
                        </span>
                      </Tippy>
                    </th>
                    <th className='min-w-125px'>Contact Number</th>
                    <th className='min-w-125px'>Address</th>
                    <th className='min-w-100px text-center'>Actions</th>
                    <th></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {currentData.map((item: any) => (
                    <tr key={item.userId}>
                      <td></td>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-13-check'
                            key={item.userId}
                            type='checkbox'
                            id={item.userId}
                            onChange={handleClick}
                            checked={isCheck.includes(item.userId)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img src={image2} />
                          </div>
                          <div className='d-flex justify-content-start flex-column  fs-6'>
                            {item.fullName}
                            <span className='text-muted fw-bold text-muted d-block fs-7'>
                              {item.roleName}
                            </span>
                            <span className='text-muted fw-bold d-block fs-7'>
                              {item.department}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='text-dark  d-block fs-6 '>{item.managerName}</span>
                      </td>
                      <td>
                        <span className='text-dark  d-block fs-6 '>{item.empId}</span>
                      </td>
                      <td>
                        <span className='text-dark   d-block fs-6'>{item.email}</span>
                      </td>
                      <td>
                        <span className='text-dark  d-block fs-6'>{item.contactNo}</span>
                      </td>
                      <td>
                        <span className='text-dark  d-block fs-6'>
                          {item.address}
                          <span className='text-muted text-muted d-block fs-7'>
                            {item.baseLocation}
                          </span>
                        </span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-evenly flex-shrink-0'>
                          <Tippy placement='top' content='Edit Member'>
                            <Link
                              to='/editEmp'
                              onClick={() => {
                                localStorage.setItem('editUser', item.userId)
                                localStorage.setItem('editIsActive', 'Inactive')
                              }}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </Link>
                          </Tippy>

                          <Tippy placement='top' content='Delete Member'>
                            <a
                              onClick={() =>
                                deleteHandler(
                                  item.userId,
                                  'inactive',
                                  setSpinner,
                                  setItemOffset,
                                  // setForcePage,
                                  '',
                                  listOfInactiveUsers
                                )
                              }
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </Tippy>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>

            {list.length > 10 && (
              <div className='d-flex align-items-center justify-content-center position-relative mb-3'>
                <div style={{left: '2rem', position: 'absolute', top: '0'}}>
                  {RowsPerPage(
                    setForcePage,
                    setRowPerPage,
                    setItemOffset,
                    rowPerPage,
                    list.length,
                    '0px'
                  )}
                </div>
                {filteredMembers.length > rowPerPage &&
                  Pagination(handlePageClick, pageCount, forcePage)}
              </div>
            )}
            <span
              className='d-flex justify-content-center w-100'
              style={{fontWeight: '600'}}
            >
              Showing {currentData.length} members out of {list.length}
            </span>

            {/* end::Table */}

            <style>
              {`

                        .custom-select-class{
                          text-align: center;
                          width: 8rem;
                        }

                        .select-custom-header{
                          margin-right: 1rem;
                          line-height: 3rem;
                        }

                        .pagination-custom-class{

                        }

                        
                    `}
            </style>

            {/* end::Table container */}
          </div>
        )}

        {/* begin::Body */}
        <div className='d-flex justify-content-end py-3 px-9'>
          {spinner ? (
            ''
          ) : (
            <button
              type='submit'
              className='btn btn-primary mb-3'
              disabled={isCheck.length == 0}
              onClick={activateUser}
            >
              {!loading && <span className='indicator-label'>Activate</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export {InactiveMember}
