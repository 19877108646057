
import React from 'react'

import {Link, Outlet, useLocation} from 'react-router-dom'
import '../../../css/ProductivityManager.css'

const ConfigurationWrapper: React.FC = () => {
  const location = useLocation()

  return (
    <>
      <div className='card mb-5'>
        <div className='card-body pt-3' style={{padding: '0px 25px'}}>
          <div className='d-flex overflow-auto'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={`nav-link me-6 ${
                    location.pathname === '/configuration/setting' ||
                    location.pathname === '/configuration'
                      ? 'text-dark active-wrapper'
                      : 'text-active-primary fw-bolder'
                  }`}
                  to='/configuration/setting'
                >
                  General Settings
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`nav-link me-6 ${
                    location.pathname === '/configuration/cloudSetting'
                      ? 'text-dark inactive-wrapper'
                      : 'text-active-primary fw-bolder'
                  } `}
                  to='cloudSetting'
                >
                  Cloud Settings
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`nav-link me-6 ${
                    location.pathname === '/configuration/BulkConfigUpdate'
                      ? 'text-dark inactive-wrapper'
                      : 'text-active-primary fw-bolder'
                  } `}
                  to='BulkConfigUpdate'
                >
                  Bulk Configuration Update
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export {ConfigurationWrapper}
