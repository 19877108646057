import React, {useState, useEffect} from 'react'
import {useNavigate, Link} from 'react-router-dom'
import {callAllManager} from '../../../modules/auth/redux/AuthCRUD'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {AlertModal} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

export function EditProjectDetails() {
  const API_URL = process.env.REACT_APP_API_URL
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [manager, setManager] = useState<any[]>([])
  const [currentManager, setCurrentManager] = useState<any[]>([])

  const initialValues = {
    projectId: '',
    projectName: '',
    projectOwnerId: '',
    organizationId: localStorage.getItem('org_Id'),
    modifiedBy: localStorage.getItem('name'),
  }

  const profileDetailsSchema = Yup.object().shape({
    projectName: Yup.string()
      .min(3, 'Minimum 3 character')
      .max(50, 'Maximum 50 character')
      .required('Name is required'),
    projectOwnerId: Yup.mixed().required('Project Manager is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, { setSubmitting, resetForm}) => {
      setLoading(true)
        axios
          .post(`${API_URL}/Projects/UpdateProject`, values)
          .then((res) => {
            AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok')
            navigate('/project')
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            setSubmitting(false)
          })
    },
  })

  useEffect(() => {
    axios
      .get(`${API_URL}/Projects/GetProjectByProjectId`, {
        params: {
          Project_Id: localStorage.getItem('editProject'),
        },
      })
      .then((res) => {
        formik.setFieldValue('projectName', res.data.projectName)
        //formik.setFieldValue('projectOwnerName', res.data.projectOwnerName)
        formik.setFieldValue('projectOwnerId', res.data.projectOwnerId)
        formik.setFieldValue('projectId', res.data.projectId)
        setCurrentManager([{label: res.data.projectOwnerName, value: res.data.projectOwnerId}])
      })
  }, [])

  useEffect(() => {
    callAllManager().then((res: any) => {
      const newList = res.data.map((item: any) => {
        return {
          label: item.fullName,
          value: item.userId,
        }
      })
      setManager(newList)
    })
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Project Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          className='form'
        >
          <div className='card-body p-9'>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Project Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-border'
                  placeholder='Project Name'
                  {...formik.getFieldProps('projectName')}
                />
                {formik.touched.projectName && formik.errors.projectName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.projectName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Manager</label>
              <div className='col-lg-8 fv-row'>
                <div className='min-w-200px'>
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    options={manager}
                    value={currentManager}
                    placeholder='Select Manager'
                    onChange={(item: any) => {
                      setCurrentManager(item)
                      formik.setFieldValue('projectOwnerId', item.value)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
                {formik.touched.projectOwnerId && formik.errors.projectOwnerId && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.projectOwnerId}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to='/project'>
              <button type='submit' className='btn btn-secondary mx-5'>
                Cancel
              </button>
            </Link>

            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Edit Project'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
