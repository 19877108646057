import React, {useEffect, useState} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {getConfigHistory} from '../../../services/Configuration.services'
import {Pagination, Loader, RowsPerPage} from '../../../CommonFunctions/CommonFunction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const ConfigurationHistory: React.FC = () => {
  const [historyData, setHistoryData] = useState<any>([])
  const [filteredData, setFilteredData] = useState<any>([])
  const [rowPerPage, setRowPerPage] = useState(10)
  const [forcePage, setForcePage] = useState<any>(null)
  const [configDropdown, setConfigDropdown] = useState<any[]>([])
  const [selectedConfig, setSelectedConfig] = useState('')
  const [userDropdown, setUserDropdown] = useState<any[]>([])
  const [selectedUser, setSelectedUser] = useState('')
  const [screenloader, _] = useState<any>(false)

  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const currentItemss = filteredData.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(filteredData.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % filteredData.length
    setItemOffset(newOffset)
  }

  useEffect(() => {
    fetchHistory()
  }, [])

  const fetchHistory = async () => {
    const getHistory = await getConfigHistory()

    const uniqueConfigNames = Array.from(
      new Set(getHistory.data.map((item: any) => item.config_Name))
    )

    const newList = uniqueConfigNames.map((item: any) => {
      return {
        label: item,
        value: item,
      }
    })

    newList.unshift({
      label: 'Select All',
      value: '',
    })

    const uniqueUsers = Array.from(new Set(getHistory.data.map((item: any) => item.modified_By)))

    const newUsersList = uniqueUsers.map((user) => {
      return {
        label: user,

        value: getHistory.data.find((item: any) => item.modified_By === user).modifier_UserId,
      }
    })

    newUsersList.unshift({
      label: 'Select All',
      value: '',
    })
    setUserDropdown(newUsersList)
    setConfigDropdown(newList)
    setHistoryData(getHistory.data)
    setFilteredData(getHistory.data)
  }

  const handleConfigChange = (value: any) => {
    let filteredList = historyData

    if (value !== '') {
      filteredList = filteredList.filter((history: any) => history.config_Name === value)
    }

    if (selectedUser) {
      filteredList = filteredList.filter((history: any) => history.modifier_UserId === selectedUser)
    }

    setFilteredData(filteredList)
  }

  const handleUserChange = (value: any) => {
    let filterList = historyData

    if (value !== '') {
      filterList = filterList.filter((history: any) => history.modifier_UserId === value)
    }

    if (selectedConfig) {
      filterList = filterList.filter((history: any) => history.config_Name === selectedConfig)
    }

    if (value === '') {
      setForcePage(0)
    }

    setFilteredData(filterList)
  }

  return (
    <>
      <div className='card-body'>
        <div className='card-header border-0 pt-5 pb-5'>
          <div className='d-flex justify-content-between align-items-center py-3 px-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Configuration History</span>
            </h3>
            <div className='d-flex'>
              <div className='d-flex' style={{marginRight: '2rem'}}>
                <span className='mt-2 fs-2 '>Config Name :&nbsp;</span>
                <div
                  id='select-member'
                  style={{width: '200px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search members'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    value={configDropdown.filter((option: any) => option.value === selectedConfig)}
                    options={configDropdown}
                    placeholder='Select config'
                    onChange={(item: any) => {
                      setSelectedConfig(item.value)
                      handleConfigChange(item.value)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
              </div>
              <div className='d-flex'>
                <span className='mt-2 fs-2'>Members :&nbsp;</span>
                <div
                  id='select-member'
                  style={{width: '200px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search members'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    value={userDropdown.filter((option: any) => option.value === selectedUser)}
                    options={userDropdown}
                    placeholder='Select config'
                    onChange={(item: any) => {
                      setSelectedUser(item.value)
                      handleUserChange(item.value)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4' style={{border: '1px solid '}}>
                <thead>
                  <tr
                    className='fw-bolder'
                    style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
                  >
                    <th></th>
                    <th className=' min-w-150px'>Modified By</th>
                    <th className=' min-w-125px'> Config Name </th>
                    <th className='min-w-125px '>Config Old Value</th>
                    <th className='min-w-125px '>Config New Value</th>
                    <th className='min-w-125px'>Updated On</th>
                  </tr>
                </thead>

                <tbody>
                  {currentItemss.map((history: any, index: any) => (
                    <tr key={index}>
                      <td></td>

                      <td>
                        <span className='text-dark  d-flex mb-1 fs-6'>{history.modified_By}</span>
                      </td>

                      <td>
                        <span className='text-dark  d-flex mb-1 fs-6'>{history.config_Name}</span>
                      </td>

                      <td>
                        <span className='text-dark  d-flex mb-1 fs-6 '>
                          {history.config_Old_Value}
                        </span>
                      </td>

                      <td>
                        <span className='d-flex mb-1 fs-6 '>{history.config_New_Value}</span>
                      </td>

                      <td>
                        <span className='d-flex mb-1 fs-6 '>{history.updated_On}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {screenloader
                ? Loader('100px')
                : filteredData.length == 0 && <h2 className='noRecordFound'>No Records Found</h2>}
            </div>
            {filteredData.length > 10 && (
              <div className='d-flex align-items-center justify-content-center position-relative mb-5'>
                <div style={{left: '2rem', position: 'absolute', top: '0'}}>
                  {RowsPerPage(
                    setForcePage,
                    setRowPerPage,
                    setItemOffset,
                    rowPerPage,
                    historyData.length,
                    '0px'
                  )}
                </div>
                {historyData.length > rowPerPage &&
                  Pagination(handlePageClick, pageCount, forcePage)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export {ConfigurationHistory}
