/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {inactiveCompanyInfo} from '../../../modules/auth/redux/AuthCRUD'
import {Link} from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'
import dayjs from 'dayjs'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import {
  Loader,
  MemberAndPlanSearchBar,
  Pagination,
  RowsPerPage,
} from '../../../CommonFunctions/CommonFunction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const API_URL = process.env.REACT_APP_API_URL

const InActiveOrg = () => {
  const [loader, _] = useState<boolean>(false)
  const [companies, setCompanies] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [rowPerPage, setRowPerPage] = useState(10)
  const [forcePage, setForcePage] = useState<any>(null)

  const getorg = () => {
    inactiveCompanyInfo()
      .then((res) => {
        setCompanies(res.data)
        setSpinner(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    setSpinner(true)
    getorg()
  }, [])

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value)
    setItemOffset(0)
  }

  const resendMail = (Props: any, email: any) => {
    let body = {
      organizationId: Props,
    }

    swal({
      title: 'Are you sure?',
      text: 'Activation Mail will be resend!',
      icon: 'warning',
      dangerMode: true,
      buttons: ['cancel', 'Resend'],
    }).then((willresend) => {
      if (willresend) {
        axios
          .post(`${API_URL}/Organization/ActivateInactiveOrganization?organizationId=${Props}`)
          .then((res) => {
            if (res.data.success === true) {
              swal(`${res.data.message}`, '', 'success').then(() => {
                getorg()
              })
            } else {
              swal(`${res.data.message}`, '', 'warning')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }
  const deleteHandler = (Props: any) => {
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      dangerMode: true,
      buttons: ['cancel', 'Delete'],
    }).then((willDelete) => {
      if (willDelete) {
        setSpinner(true)
        axios
          .patch(
            `${API_URL}/OrganizationCleanup/InactiveOrganizationDataCleanupAsync?org_Id=${Props}`
          )
          .then((res) => {
            getorg()
            swal(`${res.data.message}`, '', 'success')
            setSpinner(false)
          })
          .catch((err) => {
            console.log(err.data.message)
          })
      }
    })
  }

  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  const filteredMembers = companies.filter((list) =>
    list.organizationName?.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const currentItemss = filteredMembers.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(companies.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % companies.length
    setItemOffset(newOffset)
  }

  const filterByRemainingDays = (value: any) => {
    if (value === null) {
      getorg()
    } else {
      const info = companies.filter((item) => item.expiryDays <= value)
      setCompanies(info)
    }
  }

  return (
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Inactive Organization</span>
        </h3>

        <div className='d-flex'>
          {MemberAndPlanSearchBar('Search Organization', 'Search Organization', handleSearchChange)}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      {spinner ? (
        Loader('0px')
      ) : (
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className=''></th>
                  <th className='min-w-100px'>Name</th>
                  <th className='min-w-100px'>Email</th>
                  <th className='min-w-100px'>Contact NO</th>
                  <th className='min-w-100px'>Partner</th>

                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-100px text-center'>Onboarding Date</th>
                  <th className='min-w-100px text-center'>Expiry Date</th>
                  <th className='text-center min-w-150px'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {companies?.length > 0 &&
                  currentItemss.map((company) => (
                    <tr key={company.org_Id}>
                      <td></td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fs-6'>{company.organizationName}</span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark  fs-6'>{company.org_Email}</span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark  fs-6'>{company.org_ContactNo}</span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark  fs-6'>{company.partnerName}</span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark  fs-6'>{company.status}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center justify-content-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark text-center fs-6'>
                              {company.expiryDate
                                ? dayjs(company.createdDateLocal).format('DD/MM/YYYY')
                                : '-'}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center justify-content-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark text-center fs-6'>
                              {company.expiryDate
                                ? dayjs(company.expiryDate).format('DD/MM/YYYY')
                                : '-'}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex justify-content-evenly flex-shrink-0'>
                          {/* <div
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Click to resend Activation Mail'
                          >
                            <a
                              onClick={() => resendMail(company.org_Id, company.org_Email)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/communication/com011.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </div> */}

                          <Tippy placement='top' content='Delete organization'>
                            <button
                              disabled={!company.isDelete}
                              key={company.userId}
                              onClick={() => deleteHandler(company.org_Id)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </button>
                          </Tippy>
                        </div>
                      </td>

                      <td></td>
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}

          {loader && Loader('100px')}

          {companies.length > 10 && (
            <div className='d-flex align-items-center justify-content-center position-relative mb-3'>
              <div style={{left: '2rem', position: 'absolute', top: '0'}}>
                {RowsPerPage(
                  setForcePage,
                  setRowPerPage,
                  setItemOffset,
                  rowPerPage,
                  companies.length,
                  '0px'
                )}
              </div>

              {filteredMembers.length > rowPerPage &&
                Pagination(handlePageClick, pageCount, forcePage)}
            </div>
          )}

          <span
            className={`d-flex justify-content-center w-100 ${
              companies.length <= 0 ? 'mt-20' : ''
            }`}
            style={{fontWeight: '600'}}
          >
            Showing {currentItemss.length} Records out of {companies.length}
          </span>
        </div>
      )}

      {/* begin::Body */}
    </div>
  )
}

export default InActiveOrg
