import React from 'react'

import {Link, Outlet, useLocation} from 'react-router-dom'
import '../../../css/ProductivityManager.css'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'

const DocumentWrapper: React.FC = () => {
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const location = useLocation()
  return (
    <>
      <div className='card mb-5'>
        <div className='card-body pt-3' style={{padding: '0px 25px'}}>
          <div className='d-flex'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={`nav-link me-6 ${
                    location.pathname === '/documents/partnerDocuments' ||
                    location.pathname === '/documents'
                      ? 'text-dark active-wrapper'
                      : 'text-active-primary fw-bolder'
                  }`}
                  to='/documents/partnerDocuments'
                >
                  {ConfigDetails.role === 'Partner' ? 'My Docs' : 'Partner'}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`nav-link me-6 ${
                    location.pathname === '/documents/organizationDocuments'
                      ? 'text-dark inactive-wrapper'
                      : 'text-active-primary fw-bolder'
                  } `}
                  to='/documents/organizationDocuments'
                >
                  Organization
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export {DocumentWrapper}
