import React, {useCallback, useEffect, useRef, useState} from 'react'
import {utils as XLSXUtils, writeFile} from 'xlsx'
import {AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import {organizationInfo} from '../../modules/auth/redux/AuthCRUD'
import {Pagination, Loader, RowsPerPage, ErrorModal} from '../../CommonFunctions/CommonFunction'
import '../../../css/systemActivity.css'
import {SummaryReport} from '../../services/common.services'
import {number} from 'yup'

const CompanyOverview: React.FC = () => {
  const [organization, setOrganization] = useState<any[]>([])
  const [screenloader, setScreenLoader] = useState<any>(false)
  const gridRef: any = useRef()
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState<any[]>()
  const [summary, setSummary] = useState<any>({})

  useEffect(() => {
    setScreenLoader(true)
    getSummary()
    organizationInfo()
      .then((res) => {
        console.log('The res is ', res.data)
        const orgData = res.data
        const usersTotal = orgData.reduce((acc: any, org: any) => acc + parseInt(org.totalUsers), 0)
        const activeAdmin = orgData.reduce(
          (acc: any, org: any) => acc + parseInt(org.totalActiveAdmin),
          0
        )
        const activeUsers = orgData.reduce(
          (acc: any, org: any) => acc + parseInt(org.totalActiveUsers),
          0
        )
        const workingUsers = orgData.reduce(
          (acc: any, org: any) => acc + parseInt(org.totalWorkingUsers),
          0
        )

        setOrganization(orgData)
        setPinnedBottomRowData([
          {
            organizationName: `No. of Organization : ${orgData.length}`,
            totalUsers: `Total Users : ${usersTotal}`,
            totalActiveAdmin: `Total Dashboard Admin : ${activeAdmin}`,
            totalActiveUsers: `Total Dashboard Users : ${activeUsers}`,
            totalWorkingUsers: `Total Working Users  : ${workingUsers}`,
          },
        ])
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setScreenLoader(false)
      })
  }, [])

  const createExcel = async (e: any) => {
    e.preventDefault()

    const worksheetData = organization.map((data, index) => [
      (index + 1).toString().padStart(2, '0'),
      data.organizationName,
      data.registrationDate,
      data.accountActivationDate,
      data.totalUsers,
      data.employeesActivated,
      data.totalWorkingUsers,
      data.totalActiveAdmin,
      data.totalActiveUsers,
    ])

    const worksheet = XLSXUtils.aoa_to_sheet([
      [
        'S.no.',
        'Organization Name',
        'Registration Date',
        'Account Activation Date',
        'Employees Added',
        'Employees Activated',
        'Employees Monitored',
        'Admin Engagement',
        'Employee Engagement',
      ],
      ...worksheetData,
    ])

    const workbook = XLSXUtils.book_new()
    XLSXUtils.book_append_sheet(workbook, worksheet, 'Sheet 1')

    try {
      await writeFile(workbook, `Organizations.xlsx`)
    } catch (err) {
      console.error('Error creating Excel file:', err)
    }
  }

  const [dis, setDis] = useState<any>([
    {
      field: 'organizationName',
      headerName: 'Organization Name',
      filter: true,
      floatingFilter: true,
      flex: 1,
      sortable: true, // Enable sorting on this column
    },
    {field: 'registrationDate', headerName: 'Registration Date', flex: 1, sortable: false},
    {
      field: 'accountActivationDate',
      headerName: 'Account Activation Date',
      flex: 1,
      sortable: false,
    },
    {field: 'totalUsers', headerName: 'Employees Added', flex: 1, sortable: false},
    {
      field: 'employeesActivated',
      headerName: 'Employees Activated',
      flex: 1,

      sortable: false,
    },
    {
      field: 'totalWorkingUsers',
      headerName: 'Employees Monitored',
      flex: 1,

      sortable: false,
    },
    {
      field: 'totalActiveAdmin',
      headerName: 'Admin Engagement',
      flex: 1,

      sortable: false,
    },
    {field: 'totalActiveUsers', headerName: 'Employee Engagement', flex: 1, sortable: false},
  ])

  const paginationPageSizeSelector = [10, 25, 50, 100]

  const getRowStyle: any = (params: any) => {
    if (params.node.rowPinned) {
      return {fontWeight: 'bold', background: '#009EF7'}
    }
    if (params.node.rowIndex % 2 === 0) {
      return {background: 'lightBlue'}
    }
  }

  const onBtClearAllSorting = useCallback(() => {
    gridRef.current.api.applyColumnState({
      defaultState: {sort: null},
    })
  }, [])

  const getSummary = async () => {
    try {
      const getSummaryData = await SummaryReport()
      console.log('The getOver data is ', getSummaryData.data)
      setSummary(getSummaryData.data)
    } catch (error) {
      ErrorModal()
    }
  }

  return (
    <>
      <div className={`card `}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Companies</span>
          </h3>
          <div className='d-flex align-items-center justify-content-end'>
            <div style={{alignItems: 'center', display: 'flex'}}>
              <button
                className='btn btn-primary'
                style={{height: '3rem'}}
                onClick={(e) => createExcel(e)}
              >
                <i className='fa fa-download'></i>Download
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='card mt-5 w-50'>
        <div className='card-body py-3' style={{padding: '30px'}}>
          <div className='table-responsive'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Summary Report</span>
            </h3>
            <table className='table align-middle gs-0 gy-4 mt-2' style={{border: '1px solid '}}>
              <thead>
                <tr className='fw-bolder' style={{background: '#728FCE', fontSize: '15px'}}>
                  <th className='min-w-100px text-center'>Metric</th>
                  <th className='min-w-150px text-center'>Last Week</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{border: '1px solid black '}}>
                  <td className=' '>
                    <span className='fs-6' style={{paddingLeft: '5px'}}>
                      New Companies Registered
                    </span>
                  </td>
                  <td>
                    <div>
                      <span className='fs-6 text-danger'>
                        {summary?.totalNewOrganizationsRegistered}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr style={{border: '1px solid black '}}>
                  <td className=' '>
                    <span className='fs-6' style={{paddingLeft: '5px'}}>
                      New Companies Onboarded
                    </span>
                  </td>
                  <td>
                    <div>
                      <span className='fs-6 text-danger'>
                        {summary.totalNewOrganizationsOnboarded}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr style={{border: '1px solid black '}}>
                  <td className=' '>
                    <span className='fs-6 py-2' style={{paddingLeft: '5px'}}>
                      Total Companies
                    </span>
                  </td>
                  <td>
                    <div>
                      <span className='fs-6 text-danger'>{summary.totalOrganizations}</span>
                    </div>
                  </td>
                </tr>
                <tr style={{border: '1px solid black '}}>
                  <td className=''>
                    <span className='fs-6 fw-bold' style={{paddingLeft: '5px'}}>
                      Active Companies
                    </span>
                  </td>
                  <td>
                    <div>
                      <span className='fs-6 fw-6 text-danger'>
                        {summary.totalActiveOrganizations}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr style={{border: '1px solid black '}}>
                  <td className='   '>
                    <span className='fs-6 fw-6' style={{paddingLeft: '5px'}}>
                      Active Dashboard Admin
                    </span>
                  </td>
                  <td>
                    <div>
                      <span className='fs-6 fw-6 text-danger'>
                        {summary.totalActiveDashoardAdmins}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr style={{border: '1px solid black '}}>
                  <td className='  '>
                    <span className='fs-6 fw-6' style={{paddingLeft: '5px'}}>
                      Active Dashboard Users
                    </span>
                  </td>
                  <td>
                    <div>
                      <span className='fs-6 fw-6 text-danger'>
                        {summary.totalActiveDashoardUsers}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr style={{border: '1px solid black '}}>
                  <td className='  '>
                    <span className='fw-6 fs-6' style={{paddingLeft: '5px'}}>
                      Monitored Users
                    </span>
                  </td>
                  <td>
                    <div>
                      <span className='fs-6 fw-6 text-danger'>{summary.totalMonitoredUsers}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='card ag-theme-quartz table-responsive h-600px mt-5 w-100'>
        <div className='card-body px-5 py-6'>
          <AgGridReact
            ref={gridRef}
            rowHeight={34.4}
            getRowStyle={getRowStyle}
            rowData={organization}
            columnDefs={dis}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={paginationPageSizeSelector}
            pinnedBottomRowData={pinnedBottomRowData}
            sideBar={false}
          />
        </div>
      </div>
    </>
  )
}

export {CompanyOverview}
