import React, {FC} from 'react'
import DisplayPartner from './DisplayPartner/DisplayPartner'

const DisplayPartnerWrapper: FC = () => {
  return (
    <>
      {/* begin::Row */}
      <DisplayPartner />
    </>
  )
}

export {DisplayPartnerWrapper}
