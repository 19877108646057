import React, {FC} from 'react'
import { ComapanyDetails } from './cards/addCompany'



const ComWrapper: FC = () =>{
  return (
    <>
      
     <ComapanyDetails/>
      
    </>
  )
}
export {ComWrapper}
