import React, {useEffect, useState} from 'react'

import {Link, Outlet, useLocation} from 'react-router-dom'
import '../../../css/ProductivityManager.css'

const WebWrapper: React.FC = () => {
  const location = useLocation()
  const [webPath, setWebPath] = useState('')
  const [appPath, setAppPath] = useState('')
  const [_, setSocialPath] = useState('')
  const [loading, setLoading] = useState(true)
  const role = localStorage.getItem('role')

  useEffect(() => {
    localStorage.removeItem('editUser')
    localStorage.removeItem('startDate')
    localStorage.removeItem('endDate')

    if (role === process.env.REACT_APP_SYSTEM_THIRD_ROLE) {
      setWebPath('/records/website')
      setAppPath('/records/application')
      setSocialPath('/records/social')
    } else if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
      setWebPath('/manager_records/website')
      setAppPath('/manager_records/application')
      setSocialPath('/manager_records/social')
    } else if (
      role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    ) {
      setWebPath('/admin_records/website')
      setAppPath('/admin_records/application')
      setSocialPath('/admin_records/social')
    }
    setLoading(false)
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div className='card mb-5 '>
        <div className='card-body pt-3' style={{padding: '0px 25px'}}>
          <div className='d-flex overflow-auto '>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={`nav-link me-6 ${
                    location.pathname === '/records/website' ||
                    location.pathname === '/admin_records/website' ||
                    location.pathname === '/records' ||
                    location.pathname === '/manager_records' ||
                    location.pathname === '/manager_records/website' ||
                    location.pathname === '/admin_records'
                      ? 'text-dark active-wrapper'
                      : 'text-active-primary fw-bolder'
                  }`}
                  to={webPath}
                >
                  Websites
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`nav-link me-6 ${
                    location.pathname === '/records/application' ||
                    location.pathname === '/manager_records/application' ||
                    location.pathname === '/admin_records/application'
                      ? 'text-dark inactive-wrapper'
                      : 'text-active-primary fw-bolder'
                  } `}
                  to={appPath}
                >
                  Applications
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export {WebWrapper}
