import axios, {CancelTokenSource} from 'axios'
import dayjs from 'dayjs'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import React, {useEffect, useRef, useState} from 'react'
import {
  MultiDateTimePicker,
  Loader,
  RowsPerPage,
  Pagination,
  AlertModal,
  convertSecIntoHoursMin,
} from '../../CommonFunctions/CommonFunction'
import {KTSVG} from '../../../_metronic/helpers'
import Tippy from '@tippyjs/react'
import moment from 'moment'
import TimePicker from 'rc-time-picker'

const UserTimeEntries: React.FC = () => {
  const API = process.env.REACT_APP_API_URL
  let cancelTokenSource: CancelTokenSource | null = null
  const role = localStorage.getItem('role')
  const [posts, setPosts] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [screenloader, setScreenLoader] = useState<any>(false)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [dateError, setDateError] = useState<any>(false)
  const [updateState, setUpdateState] = useState<any>()

  const earlyStageCall = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    setPosts([])
    setScreenLoader(true)
    setItemOffset(0)
    // setForcePage(0)
  }

  const SendDataOfDate = (start: any, end: any) => {
    setDateError(false)
    earlyStageCall()

    if (start == null && end == null) {
      setDateError(true)
      setScreenLoader(false)
      return
    } else if (start && end == null) {
      end = start
    }

    fetchPosts(
      dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
      dayjs(end).format('YYYY-MM-DDT00:00:00[Z]')
    )
  }

  const fetchPosts = (startDate: any, endDate: any) => {
    setScreenLoader(true)
    const body = {
      organizationId: localStorage.getItem('org_Id'),
      userId: localStorage.getItem('userId'),
      fromDate: startDate ? startDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
      toDate: endDate ? endDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
    }
    //checkprofile()
    cancelTokenSource = axios.CancelToken.source()
    axios
      .post(`${API}/ProjectTaskReport/GetTimeReportForUser`, body, {
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setPosts(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setScreenLoader(false))
  }

  useEffect(() => {
    fetchPosts(dayjs().format('YYYY-MM-DDT00:00:00[Z]'), dayjs().format('YYYY-MM-DDT00:00:00[Z]'))
  }, [])

  //Pagination
  let itemsPerPage: number = rowPerPage
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState<any>(null)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(posts.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(posts.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, posts])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % posts.length
    setUpdateState(null)
    setItemOffset(newOffset)
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    //setStartDate(dates)
    setStartDate(start)
    setEndDate(end)
    SendDataOfDate(start, end)
  }

  const convertSecIntoHoursMinSec = (totalSeconds: any) => {
    const duration = moment.duration(totalSeconds, 'seconds')
    const hours = Math.floor(duration.asHours()).toString().padStart(2, '0')
    const formattedTime = moment.utc(totalSeconds * 1000).format(hours + ':mm')

    return formattedTime
  }

  const Edit = (value?: any) => {
    const id = value.value.id
    const time: any = moment.duration(value.value.newLoggedHours, 'seconds')
    const [extendTimeData, setExtendTimeData] = useState<any>(
      value.value.newLoggedHours == 0
        ? moment().startOf('day')
        : moment().startOf('day').add(value.value.newLoggedHours, 'seconds')
    )
    //console.log(value.newLoggedHours)
    const updateExtendedTime = () => {
      const duration = moment(extendTimeData).diff(moment().startOf('day'), 'seconds')
      const body = {
        id: id,
        newLoggedHours: duration,
      }
      axios
        .post(`${API}/ProjectTaskReport/UpdateUserTimeEntry`, body)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() =>
            fetchPosts(
              dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
              dayjs(endDate ? endDate : startDate).format('YYYY-MM-DDT00:00:00[Z]')
            )
          )
        })
        .catch((err) => console.error(err))
    }

    return (
      <div className='d-flex flex-shrink-0  justify-content-evenly'>
        <TimePicker
          className='w-50 d-flex justify-content-center align-items-center'
          value={extendTimeData}
          showSecond={false}
          allowEmpty={false}
          onChange={(e: any) => {
            setExtendTimeData(e)
          }}
          placeholder='hh:mm'
        />
        <Tippy placement='top' content='Update'>
          <button
            onClick={() => {
              setUpdateState(null)
              updateExtendedTime()
            }}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          >
            <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-3' />
          </button>
        </Tippy>

        <Tippy placement='top' content='Cancel'>
          <button
            onClick={() => setUpdateState(null)}
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm mx-3'
          >
            <KTSVG path='/media/icons/duotune/general/x-lg.svg' />
          </button>
        </Tippy>
      </div>
    )
  }

  return (
    <>
      <div className={`card card-xxl-stretch mb-5 mt-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1 justify-content-center'>
              Time Entries
            </span>
          </h3>

          <div className='d-flex align-items-center justify-content-end'>
            {/* <label className='mx-5 fs-5 fw-bold'>
                <span className='fw-bolder'>TimeZone - </span>
                {userTimeZone}
              </label> */}

            <div className='d-flex align-items-center'>
              {/* {role !== process.env.REACT_APP_SYSTEM_THIRD_ROLE && ( */}
              {/* <div>
                <div
                  className='mx-5'
                  style={{width: '200px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Member'
                >
                  {memberOption.length > 1 && (
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      value={optionSelectedMember}
                      options={memberOption}
                      placeholder='select member'
                      onChange={(item: any) => {
                        setOptionSelectedMember(item)
                        SendDataOfUser(item.value)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  )}
                </div>
              </div> */}

              <div className='d-flex flex-column'>
                {MultiDateTimePicker(
                  startDate,
                  endDate,
                  handleDateChange,
                  'form-control custom-Height'
                )}

                {dateError && (
                  <span className='text-danger' style={{marginLeft: '1rem'}}>
                    Please Select Date
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='card-body py-3' style={{padding: '30px'}}>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4' style={{border: '1px solid '}}>
                {/* begin::Table head */}
                <thead>
                  <tr
                    className='fw-bolder'
                    style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
                  >
                    <th></th>
                    <th className='min-w-100px text-center'>Date</th>
                    <th className='min-w-150px '>Project Name</th>
                    <th className='min-w-125px '>Task Name</th>
                    <th className='min-w-100px text-center'>Estimated Hours</th>
                    <th className='min-w-100px text-center'>Logged Hours</th>
                    <th className='min-w-100px text-center'>Start Time</th>
                    <th className='min-w-100px text-center'>End Time</th>
                    <th className='min-w-125px text-center'>User Entered Time</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {/* <Posts posts={currentPosts} loading={loading} /> */}
                  {currentItems.map((item: any, index: any) => (
                    <tr key={index}>
                      <td></td>

                      <td className=' text-center '>
                        <span className='text-dark fs-6 '>
                          {dayjs(item.recordDate).format('DD/MM/YYYY')}
                        </span>
                      </td>

                      <td>
                        <div>
                          <span className='text-dark fs-6'>{item.projectName}</span>
                        </div>
                      </td>

                      <td>
                        <div>
                          <span className='text-dark fs-6'>{item.taskName}</span>
                        </div>
                      </td>

                      <td className=' text-center '>
                        <span className='text-dark  fs-6'>{item.estimatedHours}</span>
                      </td>

                      <td className=' text-center '>
                        <span className='text-dark  fs-6'>
                          {convertSecIntoHoursMinSec(item.loggedHours).slice(0, 5)}
                        </span>
                      </td>

                      <td className=' text-center '>
                        <span className='text-dark  fs-6'>
                          {dayjs(item.startTime).format('HH:mm')}
                        </span>
                      </td>

                      <td className=' text-center '>
                        <span className='text-dark  fs-6'>
                          {dayjs(item?.endTime).format('HH:mm')}
                        </span>
                      </td>

                      <td>
                        {updateState === index ? (
                          <Edit value={item} />
                        ) : (
                          <div className='d-flex flex-shrink-0  justify-content-evenly'>
                            <input
                              disabled
                              type='text'
                              className='w-50 d-flex justify-content-center align-items-center border-gray-100 border rounded h-30px'
                              value={convertSecIntoHoursMinSec(item.newLoggedHours)}
                            />
                            <button
                              onClick={() => {
                                // setExtendTimeData(moment(item.newLoggedHours))
                                setUpdateState(index)
                              }}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>

              {screenloader && Loader('100px')}
              {!screenloader && currentItems.length === 0 && (
                <h2 className='noRecordFound'>No Records Found</h2>
              )}
            </div>
            {posts.length > 10 && (
              <div className='d-flex align-items-center justify-content-center position-relative mb-7'>
                <div style={{left: '0', position: 'absolute', top: '0'}}>
                  {RowsPerPage(
                    setForcePage,
                    setRowPerPage,
                    setItemOffset,
                    rowPerPage,
                    posts.length,
                    '1rem'
                  )}
                </div>

                {posts.length > rowPerPage && Pagination(handlePageClick, pageCount, 0)}
              </div>
            )}

            {/* end::Table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default UserTimeEntries
