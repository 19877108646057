import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import {MultiDateTimePicker} from '../../CommonFunctions/CommonFunction'
import axios, {CancelTokenSource} from 'axios'
import {
  callAllManager,
  departmentInfoByOrg,
  memberInfoByOrg,
} from '../../modules/auth/redux/AuthCRUD'
import dayjs from 'dayjs'
import {ProdAndUnCompo} from './ProdAndUnCompo'
import {GetAssignee} from '../../services/GetAllAssinee.services'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {userInfoActions} from '../../modules/auth/redux/UserInfoRedux'

const API_URL = process.env.REACT_APP_API_URL

let cancelTokenSourceProductive: CancelTokenSource | null = null
let cancelTokenSourceUnprodtive: CancelTokenSource | null = null

const Pro_vs_Unpro: React.FC = () => {
  const role = localStorage.getItem('role')
  const [alignment, setAlignment] = React.useState('Organization')
  const [memberOption, setMemberOption] = useState<any[]>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  //const [disableReactSelect, setDisableReactSelect] = useState<boolean>(false)
  const [currDisplayState, setCurrDisplayState] = useState<any>('Organization')
  const [memberError, setMemberError] = useState<boolean>(false)
  const [dateError, setDateError] = useState<any>(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState()
  const [listOfProdWebAndApp, setListProdOfWebAndApp] = useState<any>([])
  const [listOfUnWebAndApp, setListOfUnWebAndApp] = useState<any>([])
  const [screenloader, setScreenLoader] = useState<boolean>(false)
  const dispatch = useDispatch()
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)

  const handleChange = async (event: any) => {
    await setCurrDisplayState(event.target.value)
    if (cancelTokenSourceProductive) {
      cancelTokenSourceProductive.cancel('New request initiated')
    }
    if (cancelTokenSourceUnprodtive) {
      cancelTokenSourceUnprodtive.cancel('New request initiated')
    }

    //setDisableReactSelect(true)
    if (currDisplayState === event.target.value) {
      //setDisableReactSelect(false)
    } else {
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: '',
          selectedUserId: '',
        })
      )
      setAlignment(event.target.value)
      setListProdOfWebAndApp([])
      setListOfUnWebAndApp([])
      if (event.target.value === 'Individual') {
        setOptionSelectedMember(null)
        if (ConfigDetails.role === 'Admin') {
          callAllMemberAPI()
        } else if (ConfigDetails.role === 'Manager') {
          callMemUnderManager()
        }

        // setListProdOfWebAndApp([])
        // setListOfUnWebAndApp([])
        setMemberError(true)
      } else if (event.target.value === 'Team') {
        setOptionSelectedMember(null)
        callAllManagerAPI()
        // setListProdOfWebAndApp([])
        // setListOfUnWebAndApp([])
        setMemberError(true)
      } else if (event.target.value === 'Department') {
        setOptionSelectedMember(null)
        calAllDepartAPI()
        // setListProdOfWebAndApp([])
        // setListOfUnWebAndApp([])
        setMemberError(true)
      }
      // else if (event.target.value === 'User') {
      //   setAlignment(event.target.value)
      //   setOptionSelectedMember(null)
      //   callMemUnderManager()
      //   setListProdOfWebAndApp([])
      //   setListOfUnWebAndApp([])
      //   setMemberError(true)
      // }
      else if (event.target.value === 'Organization') {
        setMemberError(false)
        setOptionSelectedMember(null)
        SendDataOfUser('All')
        //setDisableReactSelect(true)
        // setListProdOfWebAndApp([])
        // setListOfUnWebAndApp([])
      }
    }
  }

  const calAllDepartAPI = () => {
    departmentInfoByOrg().then((res) => {
      const newList = res.data.map((item: any) => {
        return {
          label: item.department,
          value: item.departmentId,
        }
      })
      setMemberOption(newList)
      if (res.data.length > 0) {
        //setDisableReactSelect(false)
      }
    })
  }

  const mapResponseData = (data: any[]) => {
    return data.map((item: any) => {
      return {
        label: item.fullName,
        value: item.userId,
      }
    })
  }

  const callAllMemberAPI = () => {
    memberInfoByOrg().then((res) => {
      const newList = mapResponseData(res.data)
      setMemberOption(newList)
      // if (res.data.length > 0) {
      //   //setDisableReactSelect(false)
      // }
    })
  }

  const callAllManagerAPI = () => {
    callAllManager().then((res) => {
      const newList = mapResponseData(res.data)
      // if (
      //   localStorage.getItem('role') === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      //   process.env.REACT_APP_SYSTEM_FIFTH_ROLE
      // ) {
      //   newList.unshift({
      //     label: 'All Members',
      //     value: 'All',
      //   })
      // }
      setMemberOption(newList)
      // if (newList.length > 0) {
      //   //setDisableReactSelect(false)
      // }
    })
  }

  const earlyStageCall = () => {
    if (cancelTokenSourceProductive) {
      cancelTokenSourceProductive.cancel('New request initiated')
    }
    if (cancelTokenSourceUnprodtive) {
      cancelTokenSourceUnprodtive.cancel('New request initiated')
    }

    setListProdOfWebAndApp([])
    setListOfUnWebAndApp([])
    setScreenLoader(true)
  }

  const SendDataOfUser = (value: any) => {
    setMemberError(false)
    earlyStageCall()
    if (startDate == null && endDate == null) {
      setDateError(true)
      setScreenLoader(false)
      return
    }

    let lastDate: any = endDate ?? startDate

    if (currDisplayState === 'Department' && value !== 'All') {
      CallOfProductiveWebAndApp(
        '',
        dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs(lastDate).format('YYYY-MM-DDT00:00:00[Z]'),
        'productive',
        value
      )
      CallOfProductiveWebAndApp(
        '',
        dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs(lastDate).format('YYYY-MM-DDT00:00:00[Z]'),
        'unproductive',
        value
      )
    } else {
      CallOfProductiveWebAndApp(
        value,
        dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs(lastDate).format('YYYY-MM-DDT00:00:00[Z]'),
        'productive',
        ''
      )
      CallOfProductiveWebAndApp(
        value,
        dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs(lastDate).format('YYYY-MM-DDT00:00:00[Z]'),
        'unproductive',
        ''
      )
    }
  }

  const SendDataOfDate = (start: any, end: any) => {
    setDateError(false)
    earlyStageCall()

    if (optionSelectedMember == null && alignment !== 'Organization') {
      setMemberError(true)
      setScreenLoader(false)
      if (currDisplayState !== 'Organization') return
    }

    if (start == null && end == null) {
      setDateError(true)
      setScreenLoader(false)
      return
    } else if (start && end == null) {
      end = start
    }

    if (currDisplayState === 'Department') {
      CallOfProductiveWebAndApp(
        '',
        dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs(end).format('YYYY-MM-DDT00:00:00[Z]'),
        'productive',
        optionSelectedMember.value
      )
      CallOfProductiveWebAndApp(
        '',
        dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs(end).format('YYYY-MM-DDT00:00:00[Z]'),
        'unproductive',
        optionSelectedMember.value
      )
    } else {
      if (currDisplayState == 'Organization') {
        CallOfProductiveWebAndApp(
          'All',
          dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
          dayjs(end).format('YYYY-MM-DDT00:00:00[Z]'),
          'productive',
          ''
        )
        CallOfProductiveWebAndApp(
          'All',
          dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
          dayjs(end).format('YYYY-MM-DDT00:00:00[Z]'),
          'unproductive',
          ''
        )
      } else {
        CallOfProductiveWebAndApp(
          optionSelectedMember.value,
          dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
          dayjs(end).format('YYYY-MM-DDT00:00:00[Z]'),
          'productive',
          ''
        )
        CallOfProductiveWebAndApp(
          optionSelectedMember.value,
          dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
          dayjs(end).format('YYYY-MM-DDT00:00:00[Z]'),
          'unproductive',
          ''
        )
      }
    }
  }

  const callMemUnderManager = () => {
    //callMemberUnderManager()
    GetAssignee()
      .then((res: any) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.assigneeName,
            value: item.assigneeId,
          }
        })
        setMemberOption(newList)
        // if (res.data.length > 0) {
        //   //setDisableReactSelect(false)
        // }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    // if (
    //   role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
    //   role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    // ) {
    //   callAllMemberAPI()
    // }
    if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
      callMemUnderManager()
    }

    // const isGroupAdmin = localStorage.getItem('isGroupAdmin')
    // if (!isGroupAdmin) {
    //   setOptionSelectedMember({
    //     label: localStorage.getItem('name'),
    //     value: localStorage.getItem('userId'),
    //   })
    // }
  }, [])

  useEffect(() => {
    setScreenLoader(true)
    if (ConfigDetails.selectedUserName) {
      //console.log('get inside')
      setAlignment('Individual')
      setCurrDisplayState('Individual')
      if (ConfigDetails.role === 'Admin') {
        callAllMemberAPI()
      } else if (ConfigDetails.role === 'Manager') {
        callMemUnderManager()
      }

      setOptionSelectedMember({
        label: ConfigDetails.selectedUserName,
        value: ConfigDetails.selectedUserId,
      })

      CallOfProductiveWebAndApp(
        ConfigDetails.selectedUserId,
        dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        'productive',
        ''
      )
      CallOfProductiveWebAndApp(
        ConfigDetails.selectedUserId,
        dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        'unproductive',
        ''
      )
    } else {
      CallOfProductiveWebAndApp(
        localStorage.getItem('userId'),
        dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        'productive',
        ''
      )
      CallOfProductiveWebAndApp(
        localStorage.getItem('userId'),
        dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        'unproductive',
        ''
      )
    }
  }, [])

  const CallOfProductiveWebAndApp = <T,>(
    userId?: T,
    beginDate?: T,
    lastDate?: T,
    category?: T,
    depart?: T
  ) => {
    if (category === 'productive') cancelTokenSourceProductive = axios.CancelToken.source()

    if (category === 'unproductive') cancelTokenSourceUnprodtive = axios.CancelToken.source()

    let body
    if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
      body = {
        OrganizationId: localStorage.getItem('org_Id'),
        UserId: userId ? userId : localStorage.getItem('userId'),
        DepartmentId: depart,
        FromDate: beginDate,
        ToDate: lastDate,
        CategoryName: category,
      }
    } else {
      body = {
        organizationId: localStorage.getItem('org_Id'),
        userId: userId ? userId : localStorage.getItem('userId'),
        fromDate: beginDate,
        toDate: lastDate,
        departmentId: depart,
        categoryName: category,
      }
    }
    let fetch = ''
    if (userId === 'All') {
      fetch = 'OverviewReportForOrganization'
    } else if (currDisplayState === 'Individual' || ConfigDetails.selectedUserId) {
      fetch = 'OverviewReportForUser'
    } else if (currDisplayState === 'Team') {
      fetch = userId === 'All' ? 'OverviewReportForOrganization' : 'OverviewReportForManager'
    } else if (currDisplayState === 'Organization') {
      fetch = 'OverviewReportForOrganization'
    } else if (currDisplayState === 'Department') {
      fetch =
        role === process.env.REACT_APP_SYSTEM_SECOND_ROLE
          ? 'OverviewReportForReportManagerandDeptId'
          : 'OverviewReportForDepartment'
    } else {
      fetch = 'OverviewReportForOrganization'
    }

    axios
      .get(`${API_URL}/OverviewReport/${fetch}`, {
        params: body,
        cancelToken:
          category === 'productive'
            ? cancelTokenSourceProductive?.token
            : cancelTokenSourceUnprodtive?.token,
      })
      .then((res) => {
        if (!Array.isArray(res.data)) {
          category === 'productive'
            ? setListProdOfWebAndApp([res.data])
            : setListOfUnWebAndApp([res.data])
          setScreenLoader(false)
        } else {
          category === 'productive'
            ? setListProdOfWebAndApp(res.data)
            : setListOfUnWebAndApp(res.data)

          setScreenLoader(false)
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    SendDataOfDate(start, end)
  }

  return (
    <>
      <div className='card mb-xl-7'>
        {/* begin::Header */}
        <div className='card-header border-0 py-5'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Productive VS Unproductive</span>
            </h3>

            <div className='d-flex'>
              <div className=' d-flex' style={{marginRight: '10px', height: '3.2rem'}}>
                {role === process.env.REACT_APP_SYSTEM_SECOND_ROLE ? (
                  <ToggleButtonGroup
                    color='primary'
                    value={alignment}
                    //exclusive
                    onChange={handleChange}
                    aria-label='Platform'
                  >
                    <ToggleButton value='Individual'>User</ToggleButton>
                    <ToggleButton value='Department'>Department</ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  <ToggleButtonGroup
                    color='primary'
                    value={alignment}
                    //exclusive
                    onChange={handleChange}
                    aria-label='Platform'
                  >
                    <ToggleButton value='Organization'>Organization</ToggleButton>
                    <ToggleButton value='Team'>Team</ToggleButton>
                    <ToggleButton value='Individual'>Individual</ToggleButton>
                    <ToggleButton value='Department'>Department</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </div>

              <div
                style={{marginRight: '10px', width: '200px'}}
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title={alignment == 'Team' ? 'Search Manager' : 'Search Member'}
              >
                <Select
                  components={makeAnimated()}
                  value={
                    alignment === 'Organization'
                      ? {label: 'All Members', value: 'All'}
                      : optionSelectedMember
                  }
                  isDisabled={alignment == 'Organization'}
                  //isDisabled={formik.values.frequency == 'Weekly' ? false : true}
                  //value={attendance}
                  //defaultValue={{ label: 'Admin', value: localStorage.getItem('userId') }}
                  options={memberOption}
                  placeholder='Select Member'
                  onChange={(item: any) => {
                    setOptionSelectedMember(item)
                    if (
                      alignment == 'Individual' &&
                      (role == process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                        role == process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
                        role == process.env.REACT_APP_SYSTEM_SECOND_ROLE)
                    ) {
                      dispatch(
                        userInfoActions.updateSelectedUser({
                          selectedUserName: item.label,
                          selectedUserId: item.value,
                        })
                      )
                    }
                    SendDataOfUser(item.value)
                  }}
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnScroll={true}
                />
                {memberError && (
                  <span className='text-danger' style={{marginLeft: '1rem'}}>
                    Please Select Member
                  </span>
                )}
              </div>

              <div className='d-flex flex-column'>
                {MultiDateTimePicker(
                  startDate,
                  endDate,
                  handleDateChange,
                  'form-control custom-Height'
                )}

                {dateError === true && (
                  <span className='text-danger' style={{marginLeft: '1rem'}}>
                    Please Select Date
                  </span>
                )}
              </div>

              {/* <div className='d-flex justify-content-end'>
                                <button
                                    disabled={selectedMember ? false : true}
                                    className='btn btn-primary'
                                    style={{ marginLeft: '1rem', height: '3.2rem' }}
                                    onClick={() => createCsv()}
                                >
                                    <i className='fa fa-download'></i>Download
                                </button>
                            </div> */}
            </div>
          </div>
        </div>
        {/* <FilterHeader
                Title={'Overview'}
                Individual={true}
                Team={true}
                Department={true}
                Dropdown={true}
                DateRange={true}
                /> */}
        {/* end::Header */}
      </div>

      <div className='d-flex flex-wrap justify-content-around my-5'>
        <ProdAndUnCompo
          data={listOfProdWebAndApp}
          title='Productive Web And App'
          loader={screenloader}
        />
        <ProdAndUnCompo
          data={listOfUnWebAndApp}
          title='Unproductive Web And App'
          loader={screenloader}
        />
      </div>
    </>
  )
}

export default Pro_vs_Unpro
