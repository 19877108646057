import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DashboardWrapper1} from '../pages/screenshots/dash'

import {MemberWrapperChnageManager} from '../pages/members/changeManager'
import {ProjectWrapper} from '../pages/projects/projectwrapper'
import {EmpWrapper} from '../pages/addEmp/emp'
import {ProWrapper} from '../pages/addPro/Project'
import {AttendanceSheetWrapper} from '../pages/attendance/attend'
import {OrganizationWrapper} from '../pages/Organisation/OrganizationWrapper'
import {ComWrapper} from '../pages/addCompany/addCompany'
import {EditEmpWrapper} from '../pages/editEmp/editEmp'
import {EditCompanyDetails} from '../pages/addCompany/cards/editCompany'
import {EditProjectDetails} from '../pages/editProject/cards/editProject'
import {Urls} from '../pages/url'
import {Passwords} from '../pages/passwords/cards/passwords'
import {AddSchedule} from '../pages/Schedule/schedule'
import {Shift} from '../../_metronic/partials/widgets/tables/Shift'
import {Task} from '../pages/Task/Task'
import {Main} from '../pages/Task/Main'
import {Allocate} from '../pages/Task/TaskAllocation'
import Configration from '../pages/configuration/ConfigurationPages/ConfigurationSetting'
import {Schedule} from '../pages/Schedule/DisSchedule'
import {WebAppMain} from '../pages/Web&App/Main'
import {Individual} from '../pages/Web&App/individual'
import {Team} from '../pages/Web&App/team'
import {EditSchedule} from '../pages/Schedule/edit_schedule'
import {MemberMain} from '../pages/members/Mainmember'
import {Mem_admin} from '../pages/members/mem/mem_admin'
import {InactiveMember} from '../pages/members/mem/InactiveMember'
import {Holiday} from '../pages/Holiday/Holiday'
import {AddHoliday} from '../pages/Holiday/AddHoliday'
import {EmpWebsite} from '../pages/Websites&Application/emp/EmpWebsite'
import {WebWrapper} from '../pages/Websites&Application/WebWrapper'

import {EmpApplication} from '../pages/Websites&Application/emp/EmpApplication'
import {Timelines} from '../pages/Timeline/Timelines'
import {AdminAttendance} from '../pages/AttendanceReport/AdminAttendance'
import {Category} from '../pages/Category/Category'
import {ProductivitysSheet} from '../pages/Productivitys/Productivitys'
import {DepartmentWrapper} from '../pages/department/departmentWrapper'
import DepartmentDetails from '../pages/addDepartment/cards/DepartmentDetails'
import {EditDeparmentWrapper} from '../pages/editDepartMent/editDepartment'
import {AddProjectTypeWrapper} from '../pages/addProjectTypes/addProjectType'
import {AddTaskTypeWrapper} from '../pages/addTaskTypes/addTaskType'
import {ProjectTaskWrapper} from '../pages/Project&TaskType/Project&TaskWrapper'
import {ProjectType} from '../pages/Project&TaskType/Project&Task/ProjectType'
import {TaskType} from '../pages/Project&TaskType/Project&Task/TaskType'
import {TransactionDetails} from '../pages/TransactionDetails/TransactionDetails'
import {DisplayProjectAndTask} from '../pages/ProjectAndTask/DisplayProjectAndTask'
import TimeClaim from '../pages/TimeClaim/TimeClaim'
import {UpdateTimeClaim} from '../pages/TimeClaim/UpdateTimeClaim'
import {UserTimeClaim} from '../pages/TimeClaim/UserTimeClaim'
import {BuilderPageAdmin} from '../pages/layout-builder/BuilderPageAdmin'
import {ConfigurationHistory} from '../pages/configuration/ConfigurationPages/ConfigurationHistory'
import GiveAccess from '../pages/reportAccess/ReportAccess'
import LiveStreaming from '../pages/LiveStreaming/LiveStreaming'
import {Invoice} from '../pages/Pricing and Billing/Invoice'
import {MyPlan} from '../pages/Pricing and Billing/MyPlan'
import CloudConfiguration from '../pages/configuration/ConfigurationPages/CloudConfiguration'
import {ConfigurationWrapper} from '../pages/configuration/ConfigurationWrapper'
import LiveRecording from '../pages/liveRecording/LiveRecording'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import Prod_vs_Unpro from '../pages/prod_vs_unprod/Prod_vs_Unpro'
import BulkConfigUpdate from '../pages/bulkConfigUpdate/BulkConfigUpdate'
import ActivitySummary from '../pages/activitySummary/ActivitySummary'
import {Plans} from '../pages/Pricing and Billing/Plans'
import ExtendTrial from '../pages/extendTrial/ExtendTrial'
import AddExtendTrial from '../pages/extendTrial/AddExtendTrial'
import EditTrial from '../pages/extendTrial/EditTrial'
import DisplayTask from '../pages/Task/DisplayTask'
import DisplayAllocate from '../pages/Task/DisplayAllocate'
import EditTask from '../pages/Task/EditTask'
import EditAllocatedTask from '../pages/Task/EditAllocatedTask'
import {MarkTaskCompletion} from '../pages/Task/TaskCompletion'
import {OrgSelection} from '../modules/auth/components/OrgSelectionPopUp'
import TimeEntriesReport from '../pages/timeEntries/TimeEntriesReport'
import BulkUploadTask from '../pages/Task/BulkUploadTask'
import UserTimeEntries from '../pages/timeEntries/UserTimeEntries'
import {ProjectAndTaskReportOld} from '../pages/ProjectTaskReport/ProjectTaskReport'
import {UserProjectTaskReport} from '../pages/userProjectTaskReport/userProjectTaskReport'
import WeeklySummary from '../pages/weeklySummary/WeeklySummary'
import {Help} from '../pages/help/Help'
// import {PartnerDocuments} from '../pages/documents/PartnerDocuments'
import {OrganizationDocs} from '../pages/docs/OrganizationDocs'
import SupportForm from '../pages/supportFrom/SupportFrom'
const PrivateRoutes = () => {
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)

  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/builder' />} />
        <Route path='auth/*' element={<Navigate to='/builder' />} />
        {/* Pages */}
        <Route path='orgSelect' element={<OrgSelection />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* <Route path='testpage' element={<DashboardWrapper1 />} /> */}
        <Route path='project' element={<ProjectWrapper />} />
        <Route path='add' element={<EmpWrapper />} />
        <Route path='report' element={<AttendanceSheetWrapper />} />
        <Route path='company' element={<OrganizationWrapper />} />
        <Route path='com' element={<ComWrapper />} />
        <Route path='editEmp' element={<EditEmpWrapper />} />
        <Route path='editCompany' element={<EditCompanyDetails />} />
        <Route path='editProject' element={<EditProjectDetails />} />
        {/* <Route path='member' element={<MemberWrapper />} />
          <Route path='memberemp' element={<MemberWrapperEmp />} /> */}
        <Route path='changeMan' element={<MemberWrapperChnageManager />} />
        {/* <Route path='member' element={<Member />} />
          <Route path='memberemp' element={<MemberWrapperEmp />} /> */}
        <Route path='newproject' element={<ProWrapper />} />
        <Route path='report/urls' element={<Urls />} />
        <Route path='report/systemActivity' element={<ActivitySummary />} />
        <Route path='report/dailyTimeEntry' element={<TimeEntriesReport />} />
        <Route path='report/weeklySummary' element={<WeeklySummary />} />
        {/* <Route path='visitedPage' element={<ActWrapper />} /> */}
        <Route path='schedule' element={<Schedule className='' />} />
        <Route path='addschedule' element={<AddSchedule />} />
        <Route path='editschedule' element={<EditSchedule />} />
        <Route path='changePassword' element={<Passwords />} />
        <Route path='shift' element={<Shift />} />
        <Route path='holiday' element={<Holiday />} />
        <Route path='addholiday' element={<AddHoliday />} />
        <Route path='report/timeline' element={<Timelines />} />
        <Route path='report/attendance' element={<AdminAttendance />} />
        <Route path='WebAppCatalogue' element={<Category />} />
        <Route path='department' element={<DepartmentWrapper />} />
        <Route path='transactionDetails' element={<TransactionDetails />} />
        <Route path='adminDashboard' element={<BuilderPageAdmin />} />
        <Route path='history' element={<ConfigurationHistory />} />
        {/* <Route path='configuration/setting' element={<Configration />} /> */}
        <Route path='addProjectType' element={<AddProjectTypeWrapper />} />
        <Route path='addTaskType' element={<AddTaskTypeWrapper />} />
        <Route path='ReportAccess' element={<GiveAccess />} />
        <Route path='userTaskReport' element={<UserProjectTaskReport />} />
        {/* <Route path='projectTaskReport' element={<ProjectAndTaskReport />} /> */}
        <Route path='projectTaskReport' element={<ProjectAndTaskReportOld />} />
        {/* <Route path='documents' element={<PartnerDocuments />} /> */}
        <Route path='documents' element={<OrganizationDocs />} />
        <Route path='help' element={<Help />} />
        <Route path='support' element={<SupportForm />} />
        <Route path='trial' element={<ExtendTrial />} />
        <Route path='addTrial' element={<AddExtendTrial />} />
        <Route path='editTrial' element={<EditTrial />} />

        {ConfigDetails.isEnableLiveStreaming && (
          <Route path='liveStreaming' element={<LiveStreaming />} />
        )}
        <Route path='liveRecording' element={<LiveRecording />} />
        <Route path='markTaskCompletion' element={<MarkTaskCompletion />} />
        <Route path='plans' element={<Plans />} />
        <Route path='invoice' element={<Invoice />} />
        <Route path='myPlan' element={<MyPlan />} />

        {/* <Route path='project&task' element={<ProjectTaskWrapper />} /> */}

        {/* <Route path='projectType' element={<ProjectType />} />
          <Route path='taskType' element={<TaskType />} /> */}

        <Route path='report/dispPrjDetails' element={<DisplayProjectAndTask />} />

        <Route path='TimeClaim/claim' element={<TimeClaim />} />
        <Route path='TimeClaim/request' element={<UpdateTimeClaim />} />
        <Route path='TimeClaim/status' element={<UserTimeClaim />} />
        <Route path='timeEntry' element={<UserTimeEntries />} />
        <Route path='report/ProdVsUnpro' element={<Prod_vs_Unpro />} />

        <Route path='type' element={<ProjectTaskWrapper />}>
          <Route index element={<ProjectType />} />
          <Route path='typeProject' element={<ProjectType />} />
          <Route path='typeTask' element={<TaskType />} />
        </Route>

        <Route path='configuration' element={<ConfigurationWrapper />}>
          <Route index element={<Configration />} />
          <Route path='setting' element={<Configration />} />
          <Route path='cloudSetting' element={<CloudConfiguration />} />
          <Route path='BulkConfigUpdate' element={<BulkConfigUpdate />} />
        </Route>

        <Route path='member' element={<MemberMain />}>
          <Route index element={<Mem_admin />} />
          <Route path='active' element={<Mem_admin />} />
          <Route path='inactive' element={<InactiveMember />} />
        </Route>

        <Route path='admin_records' element={<WebWrapper />}>
          <Route index element={<EmpWebsite />} />
          <Route path='website' element={<EmpWebsite />} />
          <Route path='application' element={<EmpApplication />} />
        </Route>

        <Route path='task' element={<Main />}>
          <Route index element={<DisplayTask />} />
          <Route path='create' element={<DisplayTask />} />
          <Route path='allocate' element={<DisplayAllocate />} />
          <Route path='creation' element={<Task />} />
          <Route path='allocation' element={<Allocate />} />
          <Route path='editTask' element={<EditTask />} />
          <Route path='editAllocatedTask' element={<EditAllocatedTask />} />
          <Route path='bukUploadTask' element={<BulkUploadTask />} />
        </Route>

        <Route path='web&app' element={<WebAppMain />}>
          <Route index element={<Individual />} />
          <Route path='individual' element={<Individual />} />
          <Route path='team' element={<Team />} />
        </Route>

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='report/productivitys'
          element={
            <SuspensedView>
              <ProductivitysSheet></ProductivitysSheet>
            </SuspensedView>
          }
        />

        <Route
          path='report/timeTracker'
          element={
            <SuspensedView>
              <AttendanceSheetWrapper></AttendanceSheetWrapper>
            </SuspensedView>
          }
        />

        <Route
          path='reports/productivitys'
          element={
            <SuspensedView>
              <ProductivitysSheet></ProductivitysSheet>
            </SuspensedView>
          }
        />

        <Route
          path='screenshots'
          element={
            <SuspensedView>
              <DashboardWrapper1 />
            </SuspensedView>
          }
        />
        <Route
          path='company/newcompany'
          element={
            <SuspensedView>
              <ComWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='member/addmember'
          element={
            <SuspensedView>
              <EmpWrapper></EmpWrapper>
            </SuspensedView>
          }
        />
        <Route
          path='department/addDepartment'
          element={
            <SuspensedView>
              <DepartmentDetails></DepartmentDetails>
            </SuspensedView>
          }
        />

        <Route
          path='department/editDepartment'
          element={
            <SuspensedView>
              <EditDeparmentWrapper></EditDeparmentWrapper>
            </SuspensedView>
          }
        />

        <Route
          path='project/addproject'
          element={
            <SuspensedView>
              <ProWrapper></ProWrapper>
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
