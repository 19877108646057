import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getConfigHistory() {
  const Org_ID = localStorage.getItem('org_Id')

  return axios.get(`${API_URL}/OrganizationAppConfiguration/GetOrganizationAppConfigHistoryById`, {
    params: {
      Org_ID,
    },
  })
}
export function GetCloudConfigurationOfOrg(kind: string) {
  const orgId = localStorage.getItem('org_Id')

  return axios.get(`${API_URL}/CloudStorageConfiguration/GetCloudStorageByOrgIdAndKind`, {
    params: {
      orgId,
      storageKind: kind,
    },
  })
}

export function UpdateCloudConfiguration(value: any) {
  return axios.post(`${API_URL}/CloudStorageConfiguration/UpdateCloudStorage`, value)
}
export function CheckConnection(value: any) {
  return axios.post(`${API_URL}/CloudStorageConfiguration/TestCloudConnection`, value)
}
