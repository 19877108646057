import React, { FC } from 'react'
import ChangeMan from './changeMan/Change_Man'


const MemberWrapperChnageManager: FC = () => {
    return (
        <>
            {/* begin::Row */}




            <ChangeMan className='mb-5 mb-xl-8' />
        </>
    )
}

export { MemberWrapperChnageManager }