/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {memberInfo, companyInfo} from '../../../modules/auth/redux/AuthCRUD'
import {KTSVG} from '../../../../_metronic/helpers'
import swal from 'sweetalert'
import axios from 'axios'

import dayjs from 'dayjs'
import image2 from '../../../../images/60111.jpg'
import {Pagination} from '../../../CommonFunctions/CommonFunction'

const API_URL = process.env.REACT_APP_API_URL

const Member = () => {
  const getmember = () => {
    memberInfo()
      .then((res) => {
        setMembers(res.data)
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const [members, setMembers] = useState<any[]>([])
  const [users, _] = useState<any[]>([])

  const [companies, setCompanies] = useState<any[]>([])
  const [manager, setManager] = useState(true)
  const [selectedcompany, setSelectedCompany] = useState<any>('')
  const [searchQuery, setSearchQuery] = useState('')

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  console.log(`Loading items from ${itemOffset} to ${endOffset}`)
  const filteredMembers = members.filter(
    (member) =>
      member.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      member.email.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const currentItemss = filteredMembers.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(members.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % members.length
    setItemOffset(newOffset)
  }

  useEffect(() => {
    companyInfo()
      .then((res) => {
        console.log(res.data)
        setCompanies(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    getmember()
  }, [])

  const getAllListOfUsers = async (id: string) => {
    if (id == 'all') {
      console.log('all chla')
      getmember()
    } else {
      axios
        .get(`${API_URL}/Registration/GetAllUsersByOrganization`, {
          params: {
            Org_Id: id,
          }
        })
        .then((res) => {
          console.log(res.data)
          setMembers(res.data)
          setManager(false)
        })
    }
    //console.log(selectedcompany)
  }

  const deleteHandler = (Props: any) => {
    let body = {
      userId: Props,
      isActive: false,
      modifiedDate: dayjs().format('DD-MM-YYYY'),
      modifiedBy: localStorage.getItem('name'),
    }

    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      dangerMode: true,
      buttons: ['cancel', 'Inactive'],
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .patch(`${API_URL}/Registration/DeleteUser`, body)
          .then((res) => {
            //getmember()
            console.log(selectedcompany)
            if (selectedcompany == '') {
              getmember()
            } else {
              getAllListOfUsers(selectedcompany)
            }

            swal(`${res.data.message}`, '', 'success')
          })
          .catch((err) => {
            console.log(err.data.message)
          })
      }
    })
  }

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value)
    setItemOffset(0)
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Members Statistics</span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>
        <div style={{display: 'flex'}}>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Search members by name or email'
          >
            <input
              style={{marginRight: '1rem', padding: '6px 30px', minHeight: 'unset'}}
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Search by Name or Email'
              onChange={handleSearchChange}
            />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '10px',
            }}
          >
            <select
              className='form-select form-select-solid '
              style={{color: 'black', padding: '7px 5px 7px 20px'}}
              onChange={({target: {value}}) => {
                getAllListOfUsers(value)
                setSelectedCompany(value)
              }}
            >
              <option value='all'>All Organization</option>
              {companies.map((member) => (
                <option style={{background: 'white', color: 'black'}} value={member.org_Id}>
                  {member.organizationName}
                </option>
              ))}
            </select>
          </div>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <Link to='/member/addmember' className='btn btn-sm btn-primary me-3'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New Member
            </Link>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                </th>
                {/* <th className='w-80px'>Image</th> */}
                <th className='min-w-120px'>Name</th>
                <th className='min-w-100px'>Organization</th>
                {/* <th className='min-w-100px'>Role</th> */}
                {manager && <th className='min-w-120px'>Manager</th>}
                <th className='min-w-120px'>Email</th>
                <th className='min-w-100px'>Contact Number</th>
                <th className='min-w-100px'>Address</th>
                <th className='w-150px text-center'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {currentItemss.map((names) => (
                <tr>
                  <td></td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        {/* <img src={toAbsoluteUrl(`${API_URL}/images/${names.images}`)} alt={names.firstName} /> */}
                        <img src={image2} />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bolder fs-6' style={{width: '13rem'}}>
                          {names.fullName}
                        </span>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {names.roleName}
                        </span>
                      </div>
                    </div>
                  </td>
                  {/* <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {names.fullName}
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {names.domain}
                      </span>
                    </td> */}
                  <td className='org-wrapper'>
                    <span
                      className='text-dark fw-bolder d-block fs-6 org-name'
                      style={{width: '13rem'}}
                    >
                      {names.organizationName}
                    </span>
                  </td>
                  {/* <td>
                      <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {names.roleName}
                      </span>
                    </td> */}
                  {manager && (
                    <td>
                      <span className='text-dark fw-bolder d-block fs-6' style={{width: '13rem'}}>
                        {names.managerName}
                      </span>
                    </td>
                  )}

                  <td>
                    <span className='text-dark fw-bolder d-block fs-6' style={{width: '13rem'}}>
                      {names.email}
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bolder d-block fs-6'>{names.contactNo}</span>
                  </td>
                  <td>
                    <span className='text-dark fw-bolder d-block fs-6' style={{width: '13rem'}}>
                      {names.address}
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {names.baseLocation}
                      </span>
                    </span>
                  </td>
                  <td>
                    <div className='d-flex justify-content-evenly flex-shrink-0'>
                      <Link
                        to='/editEmp'
                        state={users}
                        onClick={() => localStorage.setItem('editUser', names.userId)}
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Edit user'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </Link>

                      <a
                        onClick={() => deleteHandler(names.userId)}
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Delete user'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}

              {/* <tr>
                <td>

                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                        Jessie Clarcson
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        C#, ASP.NET, MS SQL
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                    Agoda
                  </a>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>
                    Houses &amp; Hotels
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-bold'>70%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-danger'
                        role='progressbar'
                        style={{ width: '70%' }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr> */}
            </tbody>
            {/* end::Table body */}
          </table>
          {filteredMembers.length > 2 && Pagination(handlePageClick, pageCount, 0)}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {Member}
