import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'

import {HeaderWrapper} from './components/header/HeaderWrapper'

import {Content} from './components/Content'
import {PageDataProvider} from './core'

import {MenuComponent} from '../assets/ts/components'

const MasterLayout = () => {
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  // useEffect(() => {
  //   setTimeout(() => {
  //     MenuComponent.reinitialization()
  //   }, 500)
  // }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' style={{paddingTop: '50px'}}>
          <HeaderWrapper />

          <div
            id='kt_content'
            className='content h-100  d-flex flex-column flex-column-fluid'
            style={{background: ' #e6e6ff'}}
          >
            {/* <Toolbar /> */}
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/* <RightToolbar /> */}
      {/* <DrawerMessenger />
      <SearchModal/> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <Main />
      <InviteUsers />
      <UpgradePlan /> */}
      {/* end:: Modals */}
      {/* <ScrollTop /> */}
    </PageDataProvider>
  )
}

export {MasterLayout}
