import {useFormik} from 'formik'
import * as Yup from 'yup'

import dayjs, {Dayjs} from 'dayjs'
import React, {useState} from 'react'
import axios from 'axios'
import {AlertModal} from '../../../../app/CommonFunctions/CommonFunction'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

const Shift: React.FC = () => {
  const [loading] = useState<boolean>(false)
  const [starttime, setStartTime] = useState<Dayjs | null>(null)
  const [endtime, setEndTime] = useState<Dayjs | null>(null)
  const API = process.env.REACT_APP_API_URL

  const initialValues = {
    shiftName: '',
    startTime: '',
    endTime: '',
    createdDate: dayjs().format('DD-MM-YYYY'),
    createdBy: localStorage.getItem('name'),
    org_Id: localStorage.getItem('org_Id'),
  }

  const checkShift = Yup.object().shape({
    shiftName: Yup.string().required('Name is required'),
    // starttime: Yup.mixed()
    //     .required("Field is required"),
    // endtime: Yup.mixed()
    //     .required("Field is required")
  })

  const formik = useFormik({
    initialValues,
    validationSchema: checkShift,
    onSubmit: (values, {resetForm}) => {
      console.log(values)
      //values.startTime = dayjs(starttime).format("HH:mm")
      values.endTime = dayjs(endtime).format('HH:mm')
      axios
        .post(`${API}/UserShift/AddUserShift`, values)
        .then((res) => {
          AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok')

          resetForm()
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })

  return (
    <div className='card card-xxl-stretch mb-5 mb-xl-10'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Shift</span>
        </h3>
      </div>
      {/* end::Header */}
      <div className='card-body py-3'>
        <form onSubmit={formik.handleSubmit} className='form' style={{marginBottom: '5px'}}>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Name'
                  {...formik.getFieldProps('shiftName')}
                />
                {formik.touched.shiftName && formik.errors.shiftName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.shiftName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Start Time</label>
              <div className='col-lg-8 fv-row'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={starttime}
                    onChange={(value: any) => {
                      setStartTime(value)
                      formik.values.startTime = dayjs(value).format('HH:mm')
                    }}
                    // renderInput={(params: any) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {formik.touched.startTime && formik.errors.startTime && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.startTime}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>End Time</label>
              <div className='col-lg-8 fv-row'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={endtime}
                    onChange={(value: any) => {
                      setEndTime(value)
                      //formik.values.time = dayjs(value).format("HH:mm:ss")
                    }}
                    // renderInput={(params: any) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {formik.touched.endTime && formik.errors.endTime && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.endTime}</div>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '40px',
              }}
            >
              <span>
                <div>
                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                      {!loading && <span className='indicator-label'>Create</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </span>
            </div>

            <h3 className='card-title align-items-start flex-column'>
              {/* <span className='fw-bolder mb-2 text-dark'>Activities</span> */}
              <span className='fw-bolder mb-2 text-dark'></span>
              <span className='text-muted fw-bold fs-7'></span>
            </h3>
          </div>
        </form>
      </div>
    </div>
  )
}

export {Shift}
