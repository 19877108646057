/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import '../../../css/project&task.css'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../setup'
import {GetUserProjectTaskReportByDate} from '../../services/Project&Task.service'
import {
  ErrorModal,
  Loader,
  MultiDateTimePicker,
  Pagination,
  convertSecIntoHoursMin,
} from '../../CommonFunctions/CommonFunction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import {manipulateResponseData} from '../Common_Function/Function'
import dayjs from 'dayjs'
import {GetAssignee} from '../../services/GetAllAssinee.services'
import {userInfoActions} from '../../modules/auth'

const UserProjectTaskReport = () => {
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const [projectReportData, setProjectReportData] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [memberOption, setMemberOption] = useState<any>([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    if (ConfigDetails.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
      getAllAssinee()
    } else if (
      ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    ) {
      callAllMemberAPI()
    }

    let user = {}
    if (
      (ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
        ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
        ConfigDetails.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) &&
      ConfigDetails.selectedUserId
    ) {
      user = {
        label: ConfigDetails.selectedUserName,
        value: ConfigDetails.selectedUserId,
      }
    } else {
      user = {
        label: ConfigDetails.userName,
        value: ConfigDetails.userId,
      }
    }

    setOptionSelectedMember(user)
  }, [])

  useEffect(() => {
    getProjectTaskReport(optionSelectedMember, startDate, endDate)
  }, [optionSelectedMember, startDate, endDate])

  const callAllMemberAPI = () => {
    memberInfoByOrg().then((res) => {
      const newList = manipulateResponseData(res.data)
      setMemberOption(newList)
    })
  }

  const getAllAssinee = async () => {
    try {
      const assigne = await GetAssignee(ConfigDetails?.userId)
      const newList = assigne.data.map((item: any) => {
        return {
          label: item.assigneeName,
          value: item.assigneeId,
        }
      })
      setMemberOption(newList)
    } catch (error) {
      ErrorModal()
    }
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(dates)
    setStartDate(start)
    setEndDate(end)
  }

  let itemsPerPage: number = 6
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % projectReportData.length
    setItemOffset(newOffset)
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      width: '15rem',
    }),
  }

  const currentItemss = projectReportData?.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(projectReportData.length / itemsPerPage)

  const getProjectTaskReport = async (user: any, from: any, to: any) => {
    try {
      setSpinner(true)
      if (user.value !== '') {
        const body = {
          organizationId: ConfigDetails.orgId,
          userId: user.value,
          fromDate: dayjs(from).format('YYYY-MM-DDT00:00:00[Z]'),
          toDate: to
            ? dayjs(to).format('YYYY-MM-DDT00:00:00[Z]')
            : dayjs(from).format('YYYY-MM-DDT00:00:00[Z]'),
        }

        if (body.userId) {
          const getReportData = await GetUserProjectTaskReportByDate(body)
          setProjectReportData(getReportData.data)
        }

        setSpinner(false)
      }
    } catch (error) {
      ErrorModal()
    }
  }
  const [itemTaskOffset, setItemTaskOffset] = useState(0)

  const taskTable = (taskData: any) => {
    let itemsTaskPerPage: number = 5

    const endTaskOffset = itemTaskOffset + itemsTaskPerPage

    const handleTaskPageClick = (event: any) => {
      const newTaskOffset =
        (event.selected * itemsTaskPerPage) % taskData.userTaskAssignedDetails.length
      setItemTaskOffset(newTaskOffset)
    }

    const taskItems = taskData.userTaskAssignedDetails?.slice(itemTaskOffset, endTaskOffset)
    const taskPageCount = Math.ceil(taskData.userTaskAssignedDetails.length / itemsTaskPerPage)
    return (
      <div className='accordion-body'>
        <div className='table-responsive'>
          <table className='table table-rounded  border gy-7 gs-7'>
            <thead style={{background: 'rgb(248,249,253)'}}>
              <tr
                className='fw-semibold fs-6 text-gray-800 border-bottom border-gray-200'
                style={{fontWeight: '600'}}
              >
                <th className='w-375px'>Task Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th className='text-center'>Estimated Hours</th>
                <th className='text-center'>Logged Hours</th>
                <th className='text-center'>Final Hours</th>
              </tr>
            </thead>
            <tbody>
              {taskItems.map((task: any, index: any) => (
                <tr className='border-bottom border-gray-200' key={index}>
                  <td>{task.taskName}</td>

                  <td>{task.isDefault ? '-' : dayjs(task.startDate).format('DD/MM/YYYY')}</td>
                  <td>{task.isDefault ? '-' : dayjs(task.endDate).format('DD/MM/YYYY')}</td>
                  <td>{task.isDefault ? '-' : task.taskStatus}</td>
                  <td className='text-center'>{task.isDefault ? '-' : task.totalEstimatedHours}</td>
                  <td className='text-center'>{convertSecIntoHoursMin(task.totalLoggedHours)}</td>
                  <td className='text-center'>
                    {convertSecIntoHoursMin(task.totalNewLoggedHours)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='d-flex align-items-center justify-content-center mb-3'>
          {taskPageCount > 1 && Pagination(handleTaskPageClick, taskPageCount, 0)}
        </div>
      </div>
    )
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder mb-1' style={{fontSize: '19px'}}>
            User Task Report
          </span>
        </h3>
        <div className='d-flex' style={{justifyContent: 'center', alignItems: 'center'}}>
          {ConfigDetails.role !== process.env.REACT_APP_SYSTEM_THIRD_ROLE && (
            <div
              style={{marginRight: '10px', width: '200px'}}
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title={'Search Member'}
            >
              <Select
                components={makeAnimated()}
                value={optionSelectedMember}
                options={memberOption}
                placeholder='Select Member'
                onChange={(item: any) => {
                  if (
                    ConfigDetails.selectedUserId !== item.value &&
                    (ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                      ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
                      ConfigDetails.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE)
                  ) {
                    dispatch(
                      userInfoActions.updateSelectedUser({
                        selectedUserName: item.label,
                        selectedUserId: item.value,
                      })
                    )
                  }
                  setOptionSelectedMember(item)
                }}
                isClearable={false}
                isSearchable={true}
                closeMenuOnScroll={true}
                styles={customStyles}
              />
            </div>
          )}

          {MultiDateTimePicker(startDate, endDate, handleDateChange, 'form-control custom-Height')}
        </div>
      </div>

      <div style={{margin: '1rem'}}>
        <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bolder'>
              <th className='d-flex justify-content-between align-items-center'>
                <td className='w-50px'></td>
                <td className='w-250px d-flex justify-content-center fs-3'>Member</td>
                <td className='w-250px d-flex justify-content-center fs-3'>Project</td>
                <td className='w-250px d-flex justify-content-center fs-3'>Logged Hours</td>
                <td className='w-250px d-flex justify-content-center fs-3'>Final Hours</td>
                <td></td>
              </th>
            </tr>
          </thead>
          {spinner && Loader(0)}
          {!spinner && currentItemss.length == 0 && (
            <h2 className='noRecordFound'>No Records Found</h2>
          )}
          {!spinner &&
            currentItemss.length > 0 &&
            currentItemss.map((data, index) => (
              <div className='accordion' id={`kt_accordion_${index}`} key={index}>
                <div className='accordion-item'>
                  <div
                    className='accordion-header report-header'
                    id={`kt_accordion_${index}_header`}
                  >
                    <div
                      className='accordion-button fs-4 fw-semibold collapsed'
                      data-bs-toggle='collapse'
                      data-bs-target={`#kt_accordion_${index}_body`}
                      aria-expanded='false'
                      aria-controls={`kt_accordion_${index}_body`}
                    >
                      <div className='d-flex justify-content-between w-100'>
                        <div></div>
                        <div className='w-200px d-flex justify-content-center'>{data.userName}</div>
                        <div className='w-200px d-flex justify-content-center'>
                          {data.projectName}
                        </div>
                        <div className='w-200px d-flex justify-content-center'>
                          {convertSecIntoHoursMin(data.loggedHours)}
                        </div>
                        <div className='w-200px d-flex justify-content-center'>
                          {convertSecIntoHoursMin(data.newLoggedHours)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id={`kt_accordion_${index}_body`}
                    className='accordion-collapse collapse'
                    aria-labelledby={`kt_accordion_${index}_header`}
                    data-bs-parent='#accordionExample'
                  >
                    {taskTable(data)}
                  </div>
                </div>
              </div>
            ))}
        </table>
      </div>
      <div className='d-flex align-items-center justify-content-center mb-3'>
        {pageCount > 1 && Pagination(handlePageClick, pageCount, 0)}
      </div>

      <style>
        {`
          .accordion-button:not(.collapsed){
            color: unset !important;
            background-color: #d2dbff !important;
          }
          .report-accordion-body{
            padding : unset !important;
        }
        .report-header{
          padding : unset !important
      }
          .table.gy-7 td{
            padding-top:1.5rem !important;
            padding-bottom:1.5rem !important;
        } 
        `}
      </style>
    </div>
  )
}

export {UserProjectTaskReport}
