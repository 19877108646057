import React, {useState, useEffect} from 'react'
import '../Timeline/TimelineCSS.css'

import '../../../css/timeline.css'
import '../../../css/WebAppCatalogue.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {Loader, AlertModal} from '../../CommonFunctions/CommonFunction'
import {memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {List as VirtualizedList} from 'react-virtualized'
import {
  getUnAssignedUser,
  getAssignedUser,
  getUnAssignedDepartment,
  addUsers,
  addDepartment,
  getAssignedDepartment,
  RemoveUsers,
  RemoveDepartment,
} from '../../services/GiveAccess.services'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'

const ReportAccess = () => {
  const [selectedAssignee, setSelectedAssignee] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [unassignedMembers, setUnassignedMembers] = useState<any[]>([])
  const [assingedMembers, setAssingedMembers] = useState<any[]>([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const [isSelectedCheck, setIsSelectedCheck] = useState<any>([])
  const [isSelectedCheckAll, setIsSelectedCheckAll] = useState(false)
  const [filterdata, setFilterData] = useState<any[]>([])
  const [deptFilterdata, setdeptFilterdata] = useState<any[]>([])
  const [selecetedFilterData, setSelecetedFilterData] = useState<any[]>([])
  const [selecetedDeptFilterData, setSelecetedDeptFilterData] = useState<any[]>([])
  const [assignDropdown, setAssignDropdown] = useState<any[]>([])
  const [toggle, setToggle] = useState('user')
  const [unassignedDepartment, setUnassignedDepartment] = useState<any[]>([])
  const [assignedDepartment, setAssignedDepartment] = useState<any[]>([])

  const searchInput = document.getElementById('searchInput')
  const selectedSearchInput = document.getElementById('selectedSearchInput')

  useEffect(() => {
    getmember()
  }, [])

  useEffect(() => {
    if (!selectedAssignee) {
      return
    }

    if (toggle === 'user') {
      getUserData(selectedAssignee)
    } else {
      getDepartmentData(selectedAssignee)
    }

    if (searchInput && selectedSearchInput) {
      ;(searchInput as HTMLInputElement).value = ''
      ;(selectedSearchInput as HTMLInputElement).value = ''
    }
  }, [selectedAssignee, toggle])

  const getmember = () => {
    memberInfoByOrg()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.fullName,
            value: item.userId,
          }
        })

        setSelectedAssignee(newList[0].value)
        setAssignDropdown(newList)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getUserData = async (user: any) => {
    setSpinner(true)
    const getunassigned = await getUnAssignedUser(user)

    setUnassignedMembers(getunassigned.data)
    setFilterData(getunassigned.data)

    const getassigned = await getAssignedUser(user)

    setAssingedMembers(getassigned.data)
    setSelecetedFilterData(getassigned.data)
    setSpinner(false)
  }

  const getDepartmentData = async (user: any) => {
    setSpinner(true)
    const unassignedDepartmentData = await getUnAssignedDepartment(user)
    setUnassignedDepartment(unassignedDepartmentData.data)
    setdeptFilterdata(unassignedDepartmentData.data)
    const assignedDept = await getAssignedDepartment(selectedAssignee)
    setAssignedDepartment(assignedDept.data)
    setSelecetedDeptFilterData(assignedDept.data)
    setSpinner(false)
  }

  const handleToggle = async (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (searchInput && selectedSearchInput) {
      ;(searchInput as HTMLInputElement).value = ''
      ;(selectedSearchInput as HTMLInputElement).value = ''
    }
    setToggle(newAlignment)
    setIsCheck([])
    setIsSelectedCheck([])
    setIsCheckAll(false)
    setIsSelectedCheckAll(false)
  }

  const handleClick = (e: any) => {
    const {id, checked} = e.target

    setIsCheck((prevIsCheck: any) => {
      if (checked) {
        return [...prevIsCheck, id]
      } else {
        return prevIsCheck.filter((item: any) => item !== id)
      }
    })
  }

  const handleSelectedClick = (e: any) => {
    const {id, checked} = e.target

    const arr: any = []

    setIsSelectedCheck([...isSelectedCheck, id])
    if (!checked) {
      setIsSelectedCheck(isSelectedCheck.filter((item: any) => item !== id))
    }
    if (isCheck) {
      setIsSelectedCheckAll(false)
    }
  }

  const handleSelectAll = (e: any) => {
    const isChecked = e.target.checked
    setIsCheckAll(isChecked)
    if (isChecked) {
      if (toggle === 'user') {
        const assigneeIds = unassignedMembers.map((member: any) => member.assigneeId)
        setIsCheck(assigneeIds)
      } else {
        const assigneeIds = unassignedDepartment.map((department: any) => department.departmentId)
        setIsCheck(assigneeIds)
      }
    } else {
      setIsCheck([])
    }
  }

  const handleSelectedAll = (e: any) => {
    const isChecked = e.target.checked
    setIsSelectedCheckAll(!isSelectedCheckAll)
    if (isChecked) {
      if (toggle === 'user') {
        const assigneeIds = assingedMembers.map((member: any) => member.assigneeId)
        setIsSelectedCheck(assigneeIds)
      } else {
        const assigneeIds = assignedDepartment.map((department: any) => department.departmentId)
        setIsSelectedCheck(assigneeIds)
      }
    } else {
      setIsSelectedCheck([])
    }
  }

  const handleSearchChange = (event: any) => {
    if (toggle === 'user') {
      const query = event.target.value.toLowerCase()
      const data = unassignedMembers.filter((list: any) =>
        list.assigneeName.toLowerCase().includes(query)
      )
      setFilterData(data)
    } else {
      const query = event.target.value.toLowerCase()
      const filterQueryData = unassignedDepartment.filter((list: any) =>
        list.departmentName.toLowerCase().includes(query)
      )
      setdeptFilterdata(filterQueryData)
    }
  }

  const handleSelectedSearchChange = (event: any) => {
    if (toggle === 'user') {
      const query = event.target.value.toLowerCase()
      const data = assingedMembers.filter((list: any) =>
        list.assigneeName.toLowerCase().includes(query)
      )
      setSelecetedFilterData(data)
    } else {
      const query = event.target.value.toLowerCase()
      const filterQueryData = assignedDepartment.filter((list: any) =>
        list.departmentName.toLowerCase().includes(query)
      )
      setSelecetedDeptFilterData(filterQueryData)
    }
  }

  const addUsersOrDepartment = async () => {
    if (searchInput && selectedSearchInput) {
      ;(searchInput as HTMLInputElement).value = ''
      ;(selectedSearchInput as HTMLInputElement).value = ''
    }
    if (toggle === 'user') {
      setSpinner(true)
      const addAssignee = await addUsers(isCheck, selectedAssignee)
      if (addAssignee.data.success === true) {
        setIsCheck([])
        setIsCheckAll(false)
        getUserData(selectedAssignee)
        AlertModal(addAssignee.data.message, '', 'success', false, '#7066E0', 'Ok')
      }
    } else {
      setSpinner(true)
      const addDept = await addDepartment(isCheck, selectedAssignee)
      if (addDept.data.success === true) {
        setIsCheck([])
        setIsCheckAll(false)
        getDepartmentData(selectedAssignee)
        AlertModal(addDept.data.message, '', 'success', false, '#7066E0', 'Ok')
      }
    }
  }
  const deleteUsersOrDepartment = async () => {
    if (searchInput && selectedSearchInput) {
      ;(searchInput as HTMLInputElement).value = ''
      ;(selectedSearchInput as HTMLInputElement).value = ''
    }
    if (toggle === 'user') {
      setSpinner(true)
      const removeAssignee = await RemoveUsers(isSelectedCheck, selectedAssignee)
      if (removeAssignee.data.success === true) {
        setIsSelectedCheck([])
        setIsSelectedCheckAll(false)
        getUserData(selectedAssignee)
        AlertModal(removeAssignee.data.message, '', 'success', false, '#7066E0', 'Ok')
      }
    } else {
      setSpinner(true)
      const removeDept = await RemoveDepartment(isSelectedCheck, selectedAssignee)
      if (removeDept.data.success === true) {
        setIsSelectedCheck([])
        setIsSelectedCheckAll(false)
        getDepartmentData(selectedAssignee)
        AlertModal(removeDept.data.message, '', 'success', false, '#7066E0', 'Ok')
      }
    }
  }

  return (
    <>
      <div className='card mt-5'>
        <div className='mx-5 my-5'>
          <div className='d-flex align-items-center'>
            <div>
              <span className='d-block' style={{fontSize: '18px', fontWeight: '600'}}>
                Report Access To
              </span>
            </div>
            <div>
              <div className='px-3' style={{width: '16rem'}}>
                <Select
                  className='selectDropdown'
                  components={makeAnimated()}
                  value={assignDropdown.filter((option: any) => option.value === selectedAssignee)}
                  options={assignDropdown}
                  placeholder='select an assignee'
                  onChange={(e: any) => {
                    setSelectedAssignee(e.value)
                    setIsCheck([])
                  }}
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnScroll={true}
                />
              </div>
            </div>

            <div className='d-flex align-items-center'>
              <span className='d-block' style={{fontSize: '18px', fontWeight: '600'}}>
                For
              </span>
              <div className='row px-3' style={{display: 'flex'}}>
                <div
                  className=' fv-row'
                  style={{width: 'min-content', display: 'flex', alignItems: 'center'}}
                >
                  <ToggleButtonGroup
                    color='primary'
                    value={toggle}
                    exclusive
                    onChange={handleToggle}
                    aria-label='Platform'
                  >
                    <ToggleButton value='user'>User</ToggleButton>
                    <ToggleButton value='department'>Department</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex mt-5 justify-content-between'>
        <div className='card mb-5 mb-xl-8' style={{width: '46%'}}>
          <div className='card-header border-0 pt-5' style={{flexDirection: 'column'}}>
            <div style={{marginTop: '10px', position: 'relative'}}>
              <input
                type='text'
                id='searchInput'
                style={{marginTop: 'unset', paddingLeft: '3rem'}}
                className='form-control hght form-control-solid '
                placeholder='Search by name'
                onChange={(e) => handleSearchChange(e)}
              />
              <img
                className='custom-placeholder'
                src={toAbsoluteUrl('/media/icons/duotune/art/custom-search.png')}
              ></img>
            </div>
          </div>

          <div className='card-body py-3 px-7' style={{height: '30rem'}}>
            <div className='table-responsive'>
              <div className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <div style={{scrollbarGutter: 'stable'}}>
                  {!spinner && (
                    <div className='d-flex fw-bolder fs-6'>
                      <div className='p-0 pt-3'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='selectAll'
                            id='selectAll'
                            onChange={handleSelectAll}
                            checked={isCheckAll}
                          />
                        </div>
                      </div>

                      <div className=' table-header px-6 w-100'>Name</div>
                    </div>
                  )}
                </div>

                <div id='customized'>
                  {spinner ? (
                    Loader('100px')
                  ) : (
                    <VirtualizedList
                      height={320}
                      width={200}
                      rowCount={toggle === 'user' ? filterdata.length : deptFilterdata.length}
                      rowHeight={50}
                      rowRenderer={({index, key, style}) => {
                        const users = toggle === 'user' ? filterdata[index] : deptFilterdata[index]

                        return (
                          <div
                            key={key}
                            style={style}
                            className='d-flex align-items-center border-bottom min-w-500px'
                          >
                            <div style={{paddingRight: '0.75rem'}}>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input widget-13-check'
                                  key={users}
                                  type='checkbox'
                                  id={toggle === 'user' ? users.assigneeId : users.departmentId}
                                  onChange={handleClick}
                                  checked={
                                    toggle === 'user'
                                      ? isCheck.includes(users.assigneeId)
                                      : isCheck.includes(users.departmentId)
                                  }
                                />
                              </div>
                            </div>
                            <div style={{padding: '0.75rem'}}>
                              <div className='d-flex align-items-center'>
                                <div
                                  className='d-flex justify-content-start flex-column text'
                                  style={{fontSize: '1.1rem'}}
                                >
                                  {toggle === 'user' ? users.assigneeName : users.departmentName}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className='control-group'
          style={{
            display: 'flex',
            alignContent: 'center',
            flexFlow: 'column',
            justifyContent: 'center',
          }}
        >
          <button
            disabled={isCheck.length <= 0}
            onClick={addUsersOrDepartment}
            className='btn btn-primary'
            style={isCheck.length <= 0 ? {opacity: '0.4'} : {}}
          >
            <img src={toAbsoluteUrl('/media/icons/duotune/art/lnr-chevron-right.svg')}></img>
          </button>

          <div className='clear'></div>

          <button
            disabled={isSelectedCheck.length <= 0}
            onClick={deleteUsersOrDepartment}
            className='btn btn-primary mt-2'
            style={isSelectedCheck.length <= 0 ? {opacity: '0.4'} : {}}
          >
            <img src={toAbsoluteUrl('/media/icons/duotune/art/lnr-chevron-left.svg')}></img>
          </button>
        </div>

        <div className='card mb-5 mb-xl-8' style={{width: '46%'}}>
          <div className='card-header border-0 pt-5' style={{flexDirection: 'column'}}>
            <div style={{marginTop: '10px', position: 'relative'}}>
              <input
                type='text'
                style={{marginTop: 'unset', paddingLeft: '3rem'}}
                id='selectedSearchInput'
                className='form-control hght form-control-solid'
                placeholder='Search by name'
                onChange={(e) => handleSelectedSearchChange(e)}
              />
              <img
                className='custom-placeholder'
                src={toAbsoluteUrl('/media/icons/duotune/art/custom-search.png')}
              ></img>
            </div>
          </div>
          <div className='card-body py-3 px-7' style={{height: '30rem'}}>
            <div className='table-responsive'>
              <div className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <div style={{scrollbarGutter: 'stable', overflowY: 'scroll'}}>
                  {!spinner && (
                    <div className='d-flex fw-bolder fs-6'>
                      <div className='p-0 pt-3'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='selectAll'
                            id='selectAll'
                            onChange={handleSelectedAll}
                            checked={isSelectedCheckAll}
                          />
                        </div>
                      </div>

                      <div className=' table-header px-6 w-100'>Name</div>
                    </div>
                  )}
                </div>
                <div id='customized'>
                  {spinner ? (
                    Loader('100px')
                  ) : (
                    <VirtualizedList
                      height={320}
                      width={200}
                      rowCount={
                        toggle === 'user'
                          ? selecetedFilterData.length
                          : selecetedDeptFilterData.length
                      }
                      rowHeight={50}
                      rowRenderer={({index, key, style}) => {
                        const users =
                          toggle === 'user'
                            ? selecetedFilterData[index]
                            : selecetedDeptFilterData[index]

                        return (
                          <div
                            key={key}
                            style={style}
                            className='d-flex align-items-center border-bottom min-w-500px '
                          >
                            <div style={{paddingRight: '0.75rem'}}>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input widget-13-check'
                                  key={users}
                                  type='checkbox'
                                  id={toggle === 'user' ? users.assigneeId : users.departmentId}
                                  onChange={handleSelectedClick}
                                  checked={
                                    toggle === 'user'
                                      ? isSelectedCheck.includes(users.assigneeId)
                                      : isSelectedCheck.includes(users.departmentId)
                                  }
                                />
                              </div>
                            </div>
                            <div style={{padding: '0.75rem'}}>
                              <div className='d-flex align-items-center'>
                                <div
                                  className='d-flex justify-content-start flex-column text'
                                  style={{fontSize: '1.1rem'}}
                                >
                                  {toggle === 'user' ? users.assigneeName : users.departmentName}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <style>
          {`
                #customized div{
                  width:unset !important;
                  max-width:100% !important;
                }

                

                #selected-customized div{
                  width:unset !important;
                  max-width:100% !important;
                }

              `}
        </style>
      </div>
    </>
  )
}

export default ReportAccess
