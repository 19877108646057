import React, {useState, useEffect} from 'react'

import * as Yup from 'yup'
import {useFormik} from 'formik'

import Label, {AlertModal} from '../../CommonFunctions/CommonFunction'
import axios from 'axios'

import {useNavigate} from 'react-router-dom'

const API = process.env.REACT_APP_API_URL

const letters = /^[a-zA-Z][a-zA-Z ]*$/

const InformationDetails: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const memberSchema = Yup.object().shape({
    infoConfigName: Yup.string()
      .matches(letters, 'Only Characters are not allowed')
      .min(3, 'Minimum 3 character')
      .required('Configuration Name is required'),

    infoValue: Yup.string().min(3, 'Minimum 10 character').required('Description is required'),
  })

  const initialValues = {
    infoConfigName: '',
    infoValue: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: memberSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      values.infoConfigName = values.infoConfigName.trim()
      axios
        .post(`${API}/AppLabelInformation/AddAppInformation`, values)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            navigate('/allInformation')
            resetForm()
            setLoading(false)
          })
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setSubmitting(false)
        })

      resetForm()
      setLoading(false)
    },
  })

  return (
    <Label
      formik={formik}
      loading={loading}
      navigate={navigate}
      isDisabled={false}
      buttonName='Add Information'
    />
  )
}

export default InformationDetails
