import Tippy from '@tippyjs/react'
import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import {Loader} from '../../CommonFunctions/CommonFunction'
import SuperAdminTrialExtensionPopUp from './SuperAdminTrialExtensionPopUp'
import axios from 'axios'

const TrialExtension: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [spinner, setSpinner] = useState<boolean>(false)
  const [request, setRequest] = useState<any[]>([])
  const [reqDetials, setReqDetials] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    getList()
  }, [])

  //   const getList = async () => {
  //     try {
  //       setSpinner(true)
  //       const getRequest = await GetTrialPendingRequest()
  //       setRequest(getRequest.data)
  //       setSpinner(false)
  //     } catch (error) {
  //       setSpinner(false)
  //       //AlertModal('Something went wrong!', '', 'error', false, '#7066E0', 'Ok')
  //     }
  //   }

  const getList = () => {
    setSpinner(true)
    axios
      .get(`${API_URL}/OrganizationTrialExtenstion/GetOrganizationTrialExtensionListForSuperAdmin`)
      .then((res) => setRequest(res.data))
      .catch((err) => console.error(err))
      .finally(() => setSpinner(false))
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal = (data: any) => {
    setReqDetials(data)
    setIsModalOpen(true)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Trial Extension</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
        </div>

        {/* end::Header */}

        {/* begin::Body */}
        {spinner && Loader('0')}

        {!spinner && (
          <div className={`card-body py-3 mb-17`}>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder'>
                    <th></th>
                    <th className='min-w-150px'>Organization</th>
                    <th className='min-w-150px'>Existing Start Date </th>
                    <th className='min-w-150px'>Existing End Date </th>
                    <th className='min-w-125px text-center'>Action</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {request.length > 0 &&
                    request.map((details) => (
                      <tr key={details.organizationId}>
                        <td></td>

                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {details.organizationName}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {dayjs(details.trialStartDate).format('DD-MM-YYYY')}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {dayjs(details.trialEndDate).format('DD-MM-YYYY')}
                          </span>
                        </td>

                        <td>
                          <div className='d-flex justify-content-evenly flex-shrink-0'>
                            <Tippy placement='top' content='Approve Request'>
                              <button
                                className='btn btn-primary'
                                // onClick={() => updateStatus(details, 'Approved')}
                                onClick={() => handleOpenModal(details)}
                              >
                                Extend
                              </button>
                            </Tippy>

                            {/* <Tippy placement='top' content='Reject request'>
                              <button
                                className='btn btn-danger'
                                // onClick={() => updateStatus(details, 'Rejected')}
                                onClick={() => handleOpenModal(details, 'Rejected')}
                              >
                                Reject
                              </button>
                            </Tippy> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>

              {request.length <= 0 && (
                <span className='position-absolute w-100'>
                  <h2 className='noRecordFound'>No Records Found</h2>
                </span>
              )}
            </div>

            {/* end::Table */}

            {/* end::Table container */}
          </div>
        )}

        <div>
          {isModalOpen && (
            <SuperAdminTrialExtensionPopUp
              getList={getList}
              onClose={handleCloseModal}
              data={reqDetials}
            ></SuperAdminTrialExtensionPopUp>
          )}
        </div>
      </div>
    </>
  )
}

export default TrialExtension
