import axios from 'axios'
import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'

import {Pagination, Loader, RowsPerPage} from '../../CommonFunctions/CommonFunction'
import UpgradePlanReqModal from '../../../_metronic/partials/modals/payment/UpgradePlanReqModal'
import {useNavigate} from 'react-router-dom'
const API_URL = process.env.REACT_APP_API_URL

const ChangePlanReq: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false)
  const [companies, setCompanies] = useState<any[]>([])
  const [rowPerPage, setRowPerPage] = useState(10)
  const [companyDetails, setComapnayDeatils] = useState<any[]>([])

  //Pagination
  let itemsPerPage: number = rowPerPage
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState<any>()
  const navigate = useNavigate()

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(companies.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(companies.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, companies])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % companies.length
    setItemOffset(newOffset)
  }

  const viewHandler = (id: number) => {
    axios
      .get(`${API_URL}/BillingPlan/GetChangePlanRequestDetailById`, {
        params: {
          requestId: id,
        },
      })
      .then((res) => {
        setComapnayDeatils(res.data)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    setLoader(true)
    axios.get(`${API_URL}/BillingPlan/GetAllPendingChangePlanRequest`).then((res) => {
      setCompanies(res.data)
      setLoader(false)
    })
  }, [])

  const approvePlanRequest = async (orgId: string, planId: string, extraUsers: any) => {
    navigate(`/payment/onlinePayment?orgId=${orgId}&planId=${planId}&users=${extraUsers}`)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Change Plan Request</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
        </div>

        <div className='card-body py-3 mb-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder'>
                  <th></th>
                  <th className='min-w-150px'>organization Name</th>
                  <th className='min-w-125px'>Active Plan</th>
                  <th className='min-w-125px'>Change Plan</th>
                  <th className='min-w-100px'>Active Users</th>
                  <th className='min-w-100px'>Extra Users</th>
                  <th className='min-w-125px'>Requested Date</th>
                  <th className='min-w-100px text-center'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}

              {/* begin::Table body */}

              {companies?.length > 0 &&
                !loader &&
                currentItems.map((names: any) => (
                  <tbody>
                    <tr key={names.id}>
                      <td></td>

                      <td>
                        <span className='text-dark  d-block fs-6 '>{names.organizationName}</span>
                      </td>

                      <td>
                        <span className='text-dark  d-block fs-6'>{names.activePlanName}</span>
                      </td>
                      <td>
                        <span className='text-dark d-block fs-6'>{names.newPlanName}</span>
                      </td>
                      <td>
                        <span className='text-dark d-block fs-6'>{names.activeUserCount}</span>
                      </td>
                      <td>
                        <span className='text-dark d-block fs-6'>{names.extraUserCount}</span>
                      </td>
                      <td>
                        <span className='text-dark d-block fs-6'>
                          {dayjs(names.requestedDate).format('DD/MM/YYYY')}
                        </span>
                      </td>

                      <td>
                        <div className='d-flex justify-content-evenly flex-shrink-0'>
                          <button
                            onClick={() => viewHandler(names.id)}
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_CurrentPlan'
                            className='btn btn-sm btn-primary'
                          >
                            View
                          </button>
                          <button
                            onClick={() =>
                              approvePlanRequest(
                                names.organizationId,
                                names.newPlanId,
                                names.extraUserCount
                              )
                            }
                            className='btn btn-sm btn-primary'
                          >
                            Approve
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}

              {companies?.length <= 0 && !loader && (
                <span className='position-absolute w-100'>
                  <h2 className='noRecordFound'>No Records Found</h2>
                </span>
              )}

              {/* end::Table body */}
            </table>
          </div>

          {loader && Loader('100px')}

          {companies.length > 10 && (
            <div className='d-flex align-items-center justify-content-center position-relative mb-3'>
              <div style={{left: '2rem', position: 'absolute', top: '0'}}>
                {RowsPerPage(
                  setForcePage,
                  setRowPerPage,
                  setItemOffset,
                  rowPerPage,
                  companies.length,
                  '0px'
                )}
              </div>

              {companies.length > rowPerPage && Pagination(handlePageClick, pageCount, forcePage)}
            </div>
          )}

          <span
            className={`d-flex justify-content-center w-100 ${
              companies.length <= 0 ? 'mt-20' : ''
            }`}
            style={{fontWeight: '600'}}
          >
            Showing {currentItems.length} Request out of {companies.length}
          </span>
        </div>
      </div>
      <UpgradePlanReqModal data={companyDetails} />
    </>
  )
}

export default ChangePlanReq
