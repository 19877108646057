import React, {useState, useEffect} from 'react'

import axios from 'axios'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import image2 from '../../../../images/60111.jpg'
import '../changeMan/changeMen.css'
import {Loader, AlertModal} from '../../../CommonFunctions/CommonFunction'
import {callAllManager} from '../../../modules/auth/redux/AuthCRUD'
import {GetAllUserListByOrganization} from '../../../services/common.services'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  className: string
}

const Change_Man: React.FC<Props> = ({className}: Props) => {
  const [newManager, setNewManager] = useState<any[]>([])
  const [employeeList, setemployeeList] = useState<[]>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [memberOption, setMemberOption] = useState<any[]>([])

  const [isCheck, setIsCheck] = useState<any>([])
  const [optionNewSelectedMember, setOptionNewSelectedMember] = useState<any>([])
  const [disableReAssignManagerList, SetDisableReAssignManagerList] = useState<boolean>(false)
  const [screenloader, setScreenLoader] = useState<any>(false)
  const [fromError, setFromError] = useState<boolean>(false)
  const [toError, setToError] = useState<boolean>(false)
  const [userError, setUserError] = useState<boolean>(false)

  useEffect(() => {
    getAllManager()
  }, [])

  const getAllManager = () => {
    callAllManager().then((res) => {
      const newList = res.data.map((item: any) => {
        return {
          label: item.fullName,
          value: item.userId,
        }
      })
      setMemberOption(newList)
    })
  }

  const setManagerOtherThanSelected = (id: any) => {
    if (optionNewSelectedMember.value === id) {
      setOptionNewSelectedMember([])
    }
    GetAllUserListByOrganization(localStorage.getItem('org_Id'))
      .then((res) => {
        const newList = res.data.filter((item: any) => {
          return item.userId !== id
        })

        const allMemberWithoutSelectedMemeber: any = newList.map((item: any) => {
          return {
            label: item.fullName,
            value: item.userId,
          }
        })

        setNewManager(allMemberWithoutSelectedMemeber)
      })
      .catch((err) => console.log(err))
  }

  const selectedValue = (id?: any) => {
    setScreenLoader(true)
    setFromError(false)
    setIsCheck([])
    setemployeeList([])

    axios
      .get(`${API_URL}/Registration/GetAllReporteesByManager`, {
        params: {
          Org_Id: localStorage.getItem('org_Id'),
          managerId: id ? id : optionSelectedMember.value,
        },
      })
      .then((res) => {
        setemployeeList(res.data)
        setScreenLoader(false)
        if (res.data.length == 0) {
          SetDisableReAssignManagerList(true)
        } else {
          SetDisableReAssignManagerList(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const ReassignManager = () => {
    if (optionSelectedMember.value == undefined) {
      setFromError(true)
    }
    if (isCheck.length == 0) {
      setUserError(true)
    }
    if (optionNewSelectedMember.value == undefined) {
      setToError(true)
    }

    if (
      isCheck.length > 0 &&
      optionNewSelectedMember.value != undefined &&
      optionNewSelectedMember.value != undefined
    ) {
      const body = {
        organizationId: localStorage.getItem('org_Id'),
        userIds: isCheck,
        managerId: optionNewSelectedMember.value,
      }
      axios.post(`${API_URL}/Registration/ReassignManager`, body).then((res) => {
        AlertModal(
          res.data.message,
          '',
          res.data.success ? 'success' : 'warning',
          false,
          '#7066E0',
          'Ok'
        )

        //set
        setOptionNewSelectedMember([])
        setOptionSelectedMember([])
        setemployeeList([])
        getAllManager()
        //selectedValue()
        setNewManager([])
      })
    }
  }

  const handleClick = (e: any) => {
    setUserError(false)
    const {id, checked} = e.target
    const arr: any = []
    setIsCheck([...isCheck, id])
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id))
    }
  }

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Change Manager</span>

            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
        </div>

        {/* end::Header */}
        {/* begin::Body */}
        <section style={{borderRadius: '5px'}}>
          <div style={{flex: 1, height: '1px', backgroundColor: '#D3D3D3', marginBottom: '10px'}} />

          <div style={{padding: '1.5rem'}}>
            <div className='d-grid mb-5' style={{gridTemplateColumns: '1fr 1fr .3fr'}}>
              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 mx-3'>
                  <div className='mx-5'>
                    <label>From Manager</label>
                  </div>
                </div>

                <div
                  style={{marginRight: '10px', width: '300px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Manager'
                >
                  <Select
                    components={makeAnimated()}
                    value={optionSelectedMember}
                    //isDisabled={disableReactSelect}
                    //isDisabled={formik.values.frequency == 'Weekly' ? false : true}
                    //value={attendance}
                    //defaultValue={{ label: 'Admin', value: localStorage.getItem('userId') }}
                    options={memberOption}
                    placeholder='Select Manager'
                    onChange={(item: any) => {
                      setOptionSelectedMember(item)
                      selectedValue(item.value)
                      setManagerOtherThanSelected(item.value)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                  {fromError && (
                    <span className='text-danger' style={{marginLeft: '1rem'}}>
                      Please Select Manager
                    </span>
                  )}
                </div>
              </div>

              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 mx-3'>
                  <div className='mx-5'>
                    <label>To Manager</label>
                  </div>
                </div>

                <div
                  style={{marginRight: '10px', width: '300px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Manager'
                >
                  <Select
                    components={makeAnimated()}
                    value={optionNewSelectedMember}
                    //isDisabled={disableReAssignManagerList}
                    //isDisabled={formik.values.frequency == 'Weekly' ? false : true}
                    //value={attendance}
                    //defaultValue={{ label: 'Admin', value: localStorage.getItem('userId') }}
                    options={newManager}
                    placeholder='Select Manager'
                    onChange={(item: any) => {
                      setOptionNewSelectedMember(item)
                      setToError(false)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                  {toError && (
                    <span className='text-danger' style={{marginLeft: '1rem'}}>
                      Please Select Manager
                    </span>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <button
                  disabled={disableReAssignManagerList}
                  className='btn btn-primary'
                  style={{width: '100px'}}
                  onClick={ReassignManager}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          {/* <div style={{ flex: 1, height: '1px', backgroundColor: '#D3D3D3', marginBottom: '10px' }} /> */}

          {/* } */}
        </section>
        {/* begin::Body */}
      </div>

      <div className='card px-5 py-5'>
        <div>
          {screenloader === false && employeeList.length > 0 && (
            <>
              <div
                className='pt-2 d-grid'
                style={{gridTemplateColumns: 'repeat(auto-fit,minmax(23rem,1fr))'}}
              >
                {employeeList.map((name: any) => (
                  <div className='memberColumnContainer mb-8 mx-3' key={name.userId}>
                    {/* begin::Bullet */}
                    <div className='symbol symbol-45px me-5'>
                      {/* <img src={toAbsoluteUrl(`${API_URL}/images/${names.images}`)} alt={names.firstName} /> */}
                      <img src={image2} />
                    </div>
                    {/* end::Bullet */}
                    {/* begin::Description */}
                    <div className='flex-grow-1'>
                      <div className='fw-bolder fs-6'>{name.fullName}</div>
                      <span className='fw-bold d-block'>{name.roleName}</span>
                    </div>
                    {/* end::Description */}
                    {/* begin::Checkbox */}
                    <div className='form-check form-check-custom form-check-solid mx-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        key={name.userId}
                        id={name.userId}
                        onChange={handleClick}
                        checked={isCheck.includes(name.userId)}
                      />
                    </div>
                    {/* end::Checkbox */}
                  </div>
                ))}
              </div>

              {userError === true && (
                <span className='text-danger d-flex justify-content-center'>
                  Please Select User
                </span>
              )}
            </>
          )}

          {screenloader === false && employeeList.length < 0 && (
            <h2 className='noRecordFound'>No Records Found</h2>
          )}

          {screenloader && Loader('0px')}
        </div>
      </div>
    </>
  )
}

export default Change_Man
