import React, {useState, useEffect} from 'react'
import axios, {CancelTokenSource} from 'axios'

import dayjs from 'dayjs'
import {ProChart} from '../../Web&App/ProChart'
import '../../../../css/charts.css'
import {memberInfoByOrg} from '../../../modules/auth/redux/AuthCRUD'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  Loader,
  SingleDatePickerComponent,
  MultiDateTimePicker,
} from '../../../CommonFunctions/CommonFunction'
import {utils as XLSXUtils, writeFile} from 'xlsx'
import {isBefore} from 'date-fns'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../setup'
import {userInfoActions} from '../../../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

const ProductivitysAdmin: React.FC = () => {
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [members, setMembers] = useState<any[]>([
    {
      label: 'All Members',
      value: 'All',
    },
  ])
  const [records, setRecords] = useState<any[]>([])
  const [role, setRole] = useState('')
  const [toggle, setToggle] = useState('')
  const [selectedMember, setSelectedMember] = useState('')
  const [teamStartDate, setTeamStartDate] = useState(startDate)
  const [spinner, setSpinner] = useState(false)
  const [memberError, setMemberError] = useState(false)
  const [dateError, setDateError] = useState(false)

  //to get the data of a specified date range
  const dateRange = (id: any) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    setSpinner(true)
    if (id === '') {
      setSpinner(false)
      return
    }

    if (toggle === 'individual') {
      if (!startDate && !endDate) {
        setSpinner(false)
        return
      }
    } else if (toggle === 'team' || toggle === 'organization') {
      if (!teamStartDate) {
        setSpinner(false)
        return
      }
    }

    let newEndDate: any
    if (endDate == null) {
      newEndDate = startDate
    } else {
      newEndDate = endDate
    }

    if (toggle === 'team' || toggle === 'organization') {
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: undefined,
          selectedUserId: undefined,
        })
      )
      cancelTokenSource = axios.CancelToken.source()

      let body = {}

      if (id === 'All') {
        body = {
          organizationId: localStorage.getItem('org_Id'),
          fromDate: dayjs(teamStartDate).format('YYYY-MM-DDT00:00:00[Z]'),
          toDate: dayjs(teamStartDate).format('YYYY-MM-DDT00:00:00[Z]'),
        }
      } else {
        body = {
          organizationId: localStorage.getItem('org_Id'),
          managerId: id,
          fromDate: dayjs(teamStartDate).format('YYYY-MM-DDT00:00:00[Z]'),
          toDate: dayjs(teamStartDate).format('YYYY-MM-DDT00:00:00[Z]'),
        }
      }

      let productiveApi =
        id === 'All' ? 'GetAllUsersTotalProductiveHours' : 'GetTotalProductiveHoursByManager'

      axios
        .post(`${API_URL}/TotalProductiveHours/${productiveApi}`, body, {
          cancelToken: cancelTokenSource?.token,
        })
        .then((res) => {
          setRecords(res.data)
          setSpinner(false)
        })
        .catch((err) => {
          console.log(err)
        })
    } else if (toggle === 'individual') {
      cancelTokenSource = axios.CancelToken.source()

      const body = {
        organizationId: localStorage.getItem('org_Id'),
        userId: id,
        fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        toDate: dayjs(newEndDate).format('YYYY-MM-DDT00:00:00[Z]'),
      }

      axios
        .post(`${API_URL}/TotalProductiveHours/GetTotalProductiveHoursByUser`, body, {
          cancelToken: cancelTokenSource?.token,
        })
        .then((res) => {
          setRecords(res.data)
          setSpinner(false)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  // to check if there is a selected user
  useEffect(() => {
    if (
      (ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
        ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE) &&
      ConfigDetails.selectedUserId
    ) {
      setToggle('individual')
      setRole('user')
      getAllMembers()
      handelNameChange(ConfigDetails.selectedUserId)
    } else {
      setRole('manager')
      setToggle('organization')
      setSelectedMember('All')
    }
  }, [])

  useEffect(() => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    setSpinner(true)

    if (toggle === 'individual') {
      cancelTokenSource = axios.CancelToken.source()
      getAllMembers()
    }
  }, [])

  const getAllMembers = () => {
    memberInfoByOrg()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.fullName,
            value: item.userId,
          }
        })
        setMembers(newList)
        setSpinner(false)
      })
      .catch((err) => {
        console.log(err)
        setSpinner(false)
      })
  }

  //to handle toggle between the individual and Team
  const handleToggle = ({target}: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    if (newAlignment === null) {
      return
    }
    setSelectedMember('')
    setMembers([])
    setToggle(newAlignment)

    if (teamStartDate && (!endDate || isBefore(teamStartDate, endDate))) {
      setStartDate(teamStartDate)
    }

    // teamStartDate?setStartDate(teamStartDate):''
    setTeamStartDate(startDate)
    setRecords([])
    cancelTokenSource = axios.CancelToken.source()
    if (newAlignment === 'organization') {
      setMembers([
        {
          label: 'All Members',
          value: 'All',
        },
      ])

      setSelectedMember('All')

      // handelNameChange('All')
    }

    if (newAlignment != null && newAlignment !== 'organization') {
      const endpoint =
        newAlignment === 'team' ? 'GetAllManagersByOrganization' : 'GetAllUsersByOrganization'

      axios
        .get(`${API_URL}/Registration/${endpoint}`, {
          params: {
            Org_Id: localStorage.getItem('org_Id'),
          },
        })
        .then((res) => {
          const newList = res.data.map((item: any) => ({
            label: item.fullName,
            value: item.userId,
          }))

          setMembers(newList)
        })
        .catch((err) => console.log(err))
    }

    if (newAlignment !== null) {
      switch (newAlignment) {
        case 'team':
          setRole('manager')
          break
        case 'organization':
          setRole('manager')
          break
        case 'individual':
          setRole('user')
          break
        default:
          break
      }
    }
  }

  //to create excel file
  const createExcel = (e: any) => {
    e.preventDefault()
    const worksheetData = records.map((data) => {
      const fromDate = dayjs(data.fromDate).format('DD/MM/YYYY')
      const name = data.userName

      const productiveTime = dayjs()
        .startOf('day')
        .second(data.totalProductiveTime)
        .format('HH:mm:ss')

      const idleTime = dayjs().startOf('day').second(data.totalIdleTime).format('HH:mm:ss')
      const awayTime = dayjs().startOf('day').second(data.awayTime).format('HH:mm:ss')
      const unproductiveTime = dayjs()
        .startOf('day')
        .second(data.unproductiveTime)
        .format('HH:mm:ss')

      return [fromDate, name, productiveTime, idleTime, awayTime, unproductiveTime]
    })

    const worksheet = XLSXUtils.aoa_to_sheet([
      ['Date', 'Name', 'Productive Time', 'Idle Time', 'Away Time', 'Unproductive Time'],
      ...worksheetData,
    ])

    const name =
      toggle === 'individual'
        ? records[0].userName
        : dayjs(records[0].fromDate).format('DD-MM-YYYY')

    const workbook = XLSXUtils.book_new()
    XLSXUtils.book_append_sheet(workbook, worksheet, 'Sheet 1')
    writeFile(workbook, `${name}.xlsx`)
  }

  useEffect(() => {
    if (selectedMember !== '') dateRange(selectedMember)
  }, [startDate, endDate, teamStartDate, toggle])

  useEffect(() => {
    selectedMember ? setMemberError(false) : setMemberError(true)

    if (toggle === 'individual') {
      startDate ? setDateError(false) : setDateError(true)
    } else {
      teamStartDate ? setDateError(false) : setDateError(true)
    }
  }, [selectedMember, toggle, startDate, teamStartDate])

  const handelNameChange = (id: any) => {
    setSelectedMember(id)
    dateRange(id)
  }

  const recallApi = (hasAnyChange: any) => {
    if (hasAnyChange) {
      dateRange(selectedMember)
    }
  }

  const handleDateChange = (dates: any) => {
    if (toggle === 'team' || toggle === 'organization') {
      setTeamStartDate(dates)
    } else {
      const [start, end] = dates
      setStartDate(start)
      setEndDate(end)
    }
  }

  return (
    <>
      <form className='form'>
        <div className='card-body'>
          <div className='tab-content pt-3'>
            <div>
              <div className='card-header border-0 pt-5'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 16px',
                    alignItems: 'center',
                  }}
                >
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Productive vs Idle</span>
                  </h3>
                  <div
                    style={{
                      marginRight: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div
                      className='nav-container'
                      style={{
                        display: 'flex',
                        width: toggle === 'individual' ? '65rem' : '60rem',
                        justifyContent: 'space-between',
                      }}
                    >
                      <ToggleButtonGroup
                        color='primary'
                        value={toggle}
                        exclusive
                        onChange={handleToggle}
                        aria-label='Platform'
                        style={{height: '3rem'}}
                      >
                        <ToggleButton value='organization'>Organization</ToggleButton>
                        <ToggleButton value='team'>Team</ToggleButton>
                        <ToggleButton value='individual'>Individual</ToggleButton>
                      </ToggleButtonGroup>

                      <div
                        id='select-member'
                        style={{width: '200px'}}
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Search members'
                      >
                        <Select
                          className='selectDropdown'
                          components={makeAnimated()}
                          value={members.filter((option) => option.value === selectedMember)}
                          options={members}
                          placeholder='Select Member'
                          onChange={(item: any) => {
                            if (
                              ConfigDetails.selectedUserId !== item.value &&
                              toggle === 'individual'
                            ) {
                              dispatch(
                                userInfoActions.updateSelectedUser({
                                  selectedUserName: item.label,
                                  selectedUserId: item.value,
                                })
                              )
                            }
                            handelNameChange(item.value)
                          }}
                          isClearable={false}
                          isSearchable={true}
                          closeMenuOnScroll={true}
                          isDisabled={members.length === 0 || toggle === 'organization'}
                        />

                        {memberError && (
                          <div className='fv-plugins-message-container'>
                            <div className='text-danger mt-2 mx-3'>Select a member</div>
                          </div>
                        )}
                      </div>

                      {toggle === 'team' || toggle === 'organization' ? (
                        <div className='date-picker-admin-team muted' id='select-date'>
                          {SingleDatePickerComponent(
                            teamStartDate,
                            handleDateChange,
                            'form-control ss_date'
                          )}

                          {dateError && (
                            <div className='fv-plugins-message-container'>
                              <div className='text-danger mt-2 mx-3'>Select a date</div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className='date-picker-admin-individual muted' id='select-date'>
                          {MultiDateTimePicker(
                            startDate,
                            endDate,
                            handleDateChange,
                            'form-control ss_date'
                          )}

                          {dateError && (
                            <div className='fv-plugins-message-container'>
                              <div className='text-danger mt-2 mx-3'>Select a date</div>
                            </div>
                          )}
                        </div>
                      )}

                      <button
                        className={`btn btn-primary ${records.length == 0 && 'disabled'}`}
                        style={{height: '3rem'}}
                        onClick={(e) => createExcel(e)}
                      >
                        <i className='fa fa-download'></i>Download
                      </button>
                    </div>
                  </div>
                </div>
                {spinner ? (
                  Loader('100px')
                ) : (
                  <div className='card-body py-3 mt-1'>
                    <div className='proChart-container'>
                      {records.length === 0 && <h2 className='noRecordFound'>No Records Found</h2>}
                      {records.length > 0 && (
                        <>
                          <ProChart
                            className={''}
                            myObjects={records}
                            role={role}
                            reCall={recallApi}
                          ></ProChart>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <style>
              {`
                

                .react-datepicker__input-container>input {
                padding-left: 1rem;
            
                }

                .proChart-container::-webkit-scrollbar-thumb {
                  background-color: #C1C1C1 !important;
                }
                .proChart-container::-webkit-scrollbar {
                  height:0.5rem
                }


                .react-datepicker__close-icon {
                right: 1.5rem;
            }
          
          .form-select{
              width: 200px;
              border: 1px solid black;
              height: 37px;
              line-height: 1;
              margin-right: 1rem;
          }
              `}
            </style>
          </div>
        </div>
      </form>
    </>
  )
}
export {ProductivitysAdmin}
