import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import axios from 'axios'
import moment from 'moment'
import {memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import {MultiDateTimePicker, AlertModal} from '../../CommonFunctions/CommonFunction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'

const Allocate: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState<any[]>([])
  const [task, setTask] = useState<any[]>([])
  const [member, setMember] = useState<any[]>([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [taskValue, setTaskValue] = useState<any[]>([])
  const [selectedMember, setSelectedMember] = useState<any>({})
  const [selectedProject, setSelectedProject] = useState(null)

  const [searchParams, _] = useSearchParams()
  const API = process.env.REACT_APP_API_URL
  const navigate = useNavigate()

  const callTask = (value: any) => {
    axios
      .get(
        `${API}/UserTask/GetTaskListByUserIdAndProjectId?userId=${selectedMember.value}&projectId=${value}`
      )
      .then((res: any) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.taskName,
            value: item.taskId,
          }
        })
        setTask(newList)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    axios
      .get(`${API}/Projects/GetProjectByOrganisation`, {
        params: {
          id: localStorage.getItem('org_Id'),
        },
      })
      .then((res: any) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.projectName,
            value: item.projectId,
          }
        })
        setProjects(newList)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    memberInfoByOrg().then((res: any) => {
      const newList = res.data.map((item: any) => {
        return {
          label: item.fullName,
          value: item.userId,
        }
      })
      const userId = searchParams.get('user')
      const matchingEntry = newList.find((entry: any) => entry.value === userId)

      if (!matchingEntry) {
        AlertModal('User not found.', '', 'error', false, '#7066E0', 'Ok').then(() => {
          navigate('/task/allocate')
        })
      }

      setSelectedMember(matchingEntry)
      formik.setFieldValue('userId', matchingEntry.value)
      formik.setFieldValue('taskId', '')
      setMember(newList)
    })
  }, [])

  const checkShift = Yup.object().shape({
    userId: Yup.mixed().required('Please Select user'),
    estimatedHours: Yup.number().required('Please Enter Hours'),
    taskId: Yup.mixed().required('Please Select task'),
    projectId: Yup.mixed().required('Please Select project'),
  })

  const initialValues = {
    userId: '',
    projectId: '',
    startDate: '',
    endDate: '',
    taskId: '',
    organizationId: localStorage.getItem('org_Id'),
    //createdBy: localStorage.getItem('name'),
    estimatedHours: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: checkShift,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      axios
        .post(`${API}/AllocateTask/Assign`, values)
        .then((res) => {
          AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok')
          navigate(`/task/allocate`)
        })
        .catch((err) => {
          console.log(err)
        })
      setLoading(false)
    },
    validate: (values) => {
      let errors: any = {}
      if (!values.startDate || values.startDate == null) {
        errors.endDate = 'Please Select Start Date and End Date'
      }
      if (values.endDate == null) {
        errors.endDate = 'Please Select end date'
      }
      if (values.endDate == null && values.startDate === null) {
        errors.endDate = 'Please Select Start Date and End Date'
      }
      return errors
    },
  })

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    formik.setFieldValue('startDate', start)
    formik.setFieldValue('endDate', end)
  }

  const handleMemberChange = (memberData: any) => {
    setSelectedProject(null)
    formik.setFieldValue('projectId', '')
    setSelectedMember(memberData)
    formik.setFieldValue('userId', memberData.value)
    formik.setFieldValue('taskId', '')
  }

  const handleProjectChange = (projectData: any) => {
    setSelectedProject(projectData)
    formik.setFieldValue('projectId', projectData.value)
    formik.setFieldValue('taskId', '')
    setTaskValue([])
    callTask(projectData.value)
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='card card-xxl-stretch mb-5 '>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Allocate Task</span>
            </h3>
          </div>
          {/* end::Header */}
          <div className='card-body py-3'>
            <div className='form'>
              <div className='card-body px-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>User</label>
                  <div className='col-lg-8 fv-row'>
                    <div className='min-w-200px'>
                      <Select
                        className='selectDropdown'
                        components={makeAnimated()}
                        value={selectedMember}
                        options={member}
                        placeholder='Select User'
                        onChange={(item: any) => {
                          handleMemberChange(item)
                        }}
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                      />
                    </div>

                    {formik.touched.userId && formik.errors.userId && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.userId}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Project</label>
                  <div className='col-lg-8 fv-row'>
                    <div className='min-w-200px'>
                      <Select
                        className='selectDropdown'
                        components={makeAnimated()}
                        value={selectedProject}
                        options={projects}
                        placeholder='Select Project'
                        onChange={(item: any) => {
                          handleProjectChange(item)
                        }}
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                      />
                    </div>
                    {formik.touched.projectId && formik.errors.projectId && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.projectId}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Task</label>
                  <div className='col-lg-8 fv-row'>
                    <div className='min-w-200px'>
                      <Select
                        className='selectDropdown'
                        components={makeAnimated()}
                        value={taskValue}
                        options={task}
                        placeholder='Select Task'
                        onChange={(item: any) => {
                          formik.setFieldValue('taskId', item.value)
                          setTaskValue(item)
                        }}
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                      />
                    </div>
                    {formik.touched.taskId && formik.errors.taskId && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.taskId}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Date</label>
                  <div className='col-lg-8 fv-row'>
                    {MultiDateTimePicker(
                      startDate,
                      endDate,
                      handleDateChange,
                      'form-control form-control-border',
                      moment().add(1, 'years')
                    )}

                    {formik.touched.endDate && formik.errors.endDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.endDate}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                    Estimated Hours
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='number'
                      min={1}
                      // max={99}
                      className='form-control form-control-border'
                      placeholder='Estimated Hours'
                      {...formik.getFieldProps('estimatedHours')}
                    />
                    {formik.touched.estimatedHours && formik.errors.estimatedHours && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.estimatedHours}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='d-flex w-100 justify-content-end'>
                  <div className=' d-flex justify-content-end py-6'>
                    <Link to='/task/allocate'>
                      <button type='button' className='btn btn-secondary mx-5'>
                        Cancel
                      </button>
                    </Link>
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                      {!loading && <span className='indicator-label'>Allocate</span>}
                      {loading && (
                        <span className='indicator-progress d-block'>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export {Allocate}
