import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

export function GetOrgNameAndId() {
  return axios.get(`${API_URL}/Organization/GetOrganizationList`)
}
export function CheckExpiry(token: any) {
  const encodedToken = encodeURIComponent(token)
  return axios.get(`${API_URL}/Login/TokenValidity?token=${encodedToken}`)
}

export function GetAllUserListByOrganization(org: any) {
  return axios.get(`${API_URL}/Registration/GetAllUserListByOrganization?organizationId=${org}`)
}
export function GetOrganizationExpiryDetailByOrgId(org: any) {
  return axios.get(`${API_URL}/Organization/GetOrganizationExpiryDetailByOrgId?orgId=${org}`)
}

export function SaveUserLoginDetails(body: any) {
  return axios.post(`${API_URL}/Registration/SaveUserLoginDetails`, body)
}
export function SummaryReport() {
  return axios.get(`${API_URL}/MeraMonitor/SummaryReport`)
}
