import React, {useState, useEffect} from 'react'

import {useNavigate} from 'react-router-dom'

import {useFormik} from 'formik'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {AlertModal, baseSchema} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'

import dayjs from 'dayjs'
import CompanyDetailsForm from './CompanyDetailsForm'

const API_URL = process.env.REACT_APP_API_URL

const initialValues = {
  org_Id: '',
  organizationName: '',
  org_Address: '',
  org_Email: '',
  org_ContactNo: '',
  TaxNumber: '',
  emp_Strength: '',
  ContractDocFile: '',
  ContractDocFileName: '',
  modifiedBy: localStorage.getItem('name'),
  modifiedDate: dayjs().format('DD-MM-YYYY'),
}

export function EditCompanyDetails() {
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState('')
  const [showFile, setShowFile] = useState(false)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: baseSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setTimeout(() => {
        axios
          .post(`${API_URL}/Organization/UpdateOrganization`, values, {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          })
          .then((res) => {
            AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok')

            navigate('/organization')
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)

            setLoading(false)
            setSubmitting(false)
            setStatus('Registration process has broken')
          })
      }, 0)
    },
  })
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  useEffect(() => {
    axios
      .get(`${API_URL}/Organization/GetOrganizationById`, {
        params: {
          id: localStorage.getItem('editOrg'),
        },
      })
      .then((res) => {
        formik.setFieldValue('organizationName', res.data.organizationName)
        formik.setFieldValue('org_Address', res.data.org_Address)
        formik.setFieldValue('org_ContactNo', res.data.org_ContactNo)
        formik.setFieldValue('org_Email', res.data.org_Email)
        formik.setFieldValue('TaxNumber', res.data.taxNumber)
        formik.setFieldValue('org_Id', res.data.org_Id)
        formik.setFieldValue('ContractDocFile', res.data.contractDocFileName)
        formik.setFieldValue('ContractDocFileName', res.data.contractDocFileName)
        formik.setFieldValue('emp_Strength', res.data.emp_Strength)
        setFileName(res.data.contractDocFileName)
        if (res.data.contractDocFileName.length > 0) {
          setShowFile(true)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const cancelFile = () => {
    formik.setFieldValue('ContractDocFile', '')
    formik.setFieldValue('ContractDocFileName', '')

    setShowFile(!showFile)
  }

  return (
    <CompanyDetailsForm
      formType='Edit'
      formik={formik}
      loading={loading}
      cancelFile={cancelFile}
      showFile={showFile}
      fileName={fileName}
    />
  )
}
