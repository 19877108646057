import React, {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import swal from 'sweetalert2'

import axios from 'axios'
import {
  Pagination,
  Loader,
  AlertModal,
  CommonActionButtons,
} from '../../../CommonFunctions/CommonFunction'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const API_URL = process.env.REACT_APP_API_URL

const TaskType = () => {
  const [taskType, setTaskType] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredTaskType, setFilteredTaskType] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [updateState, setUpdateState] = useState('')

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const filteredMembers = taskType.filter((data) =>
    data.taskTypeName !== null
      ? data.taskTypeName.toLowerCase().includes(searchQuery.toLowerCase())
      : ''
  )

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % taskType.length
    setItemOffset(newOffset)
  }

  const currentItemss = filteredMembers.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(taskType.length / itemsPerPage)

  useEffect(() => {
    const role = localStorage.getItem('role')
    if (
      role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    ) {
      setSpinner(true)
      GetTaskType()
    }
  }, [])

  //to get all the existing project types
  const GetTaskType = async () => {
    try {
      const projectTypes = await axios.get(`${API_URL}/TaskType/GetTaskTypesByOrganization`, {
        params: {
          OrgId: localStorage.getItem('org_Id'),
        },
      })
      setTaskType(projectTypes.data)
      setFilteredTaskType(projectTypes.data)
      setSpinner(false)
    } catch (error) {
      console.log(error)
    }
  }

  //to delete a project type
  const deleteHandler = (Props: any) => {
    let body = {
      taskTypeId: Props,
    }

    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Yes, delete it!'
    ).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .patch(`${API_URL}/TaskType/DeleteTaskType`, body)
          .then((res) => {
            GetTaskType()
            AlertModal(
              res.data.message,
              '',
              res.data.success ? 'success' : 'warning',
              false,
              '#7066E0',
              'Ok'
            )
          })
          .catch((err) => {
            console.log(err.message)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Task type not Deleted', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  const handleSearchChange = (name: string) => {
    setSearchQuery(name)
    setFilteredTaskType(filteredTaskType)
    setItemOffset(0)
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder mb-1' style={{fontSize: '19px'}}>
            Task Type
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>

        <div style={{display: 'flex'}}>
          {spinner ? (
            ''
          ) : (
            <Tippy placement='top' content='Search by Task Type'>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <input
                  style={{marginRight: '1rem', padding: '0.7rem 1.5rem', minHeight: 'unset'}}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Search by Task Type'
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </div>
            </Tippy>
          )}

          <Tippy placement='top' content='Click to add a Task Type'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              <Link to='/addTaskType' className='btn btn-sm btn-primary me-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Task Type
              </Link>
            </div>
          </Tippy>
        </div>
      </div>

      {spinner ? (
        Loader('100px')
      ) : (
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                  </th>
                  <th className='min-w-120px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Name
                  </th>
                  <th className='min-w-100px text-center'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItemss.map((names, index) =>
                  updateState === names.taskTypeId ? (
                    <Edit name={names} setUpdateState={setUpdateState} getTaskTypes={GetTaskType} />
                  ) : (
                    <tr key={index}>
                      <td></td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div
                            className='d-flex justify-content-start flex-column '
                            style={{width: '13rem', fontSize: '13px'}}
                          >
                            {names.taskTypeName}

                            {/* </a> */}
                          </div>
                        </div>
                      </td>
                      <td style={{width: '10rem'}}>
                        <div className='d-flex justify-content-evenly flex-shrink-0'>
                          <Tippy placement='top' content='Edit Task Type'>
                            <button
                              onClick={() => {
                                setUpdateState(names.taskTypeId)
                              }}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              style={{}}
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </button>
                          </Tippy>

                          <Tippy placement='top' content='Delete Task Type'>
                            <a
                              onClick={() => deleteHandler(names.taskTypeId)}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </Tippy>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  )
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            <div className='d-flex flex-column'>
              {filteredMembers.length > 10 && (
                <div className='d-flex align-items-center justify-content-center'>
                  {Pagination(handlePageClick, pageCount, 0)}
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
      )}

      {/* begin::Body */}
    </div>
  )
}

interface EditProps {
  name: any
  setUpdateState: any
  getTaskTypes: any
}

const Edit: React.FC<EditProps> = ({name, setUpdateState, getTaskTypes}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [editedName, setEditedName] = useState(name.taskTypeName)

  const handleUpdate = () => {
    const values = {
      taskTypeId: name.taskTypeId,
      taskTypeName: editedName,
      orgId: name.orgId,
      isActive: name.isActive,
      isDefault: name.isDefault,
    }

    axios
      .post(`${API_URL}/TaskType/UpdateTaskType`, values)
      .then((res) => {
        AlertModal(
          res.data.message,
          '',
          res.data.success ? 'success' : 'warning',
          false,
          '#7066E0',
          'Ok'
        )

        getTaskTypes()
      })
      .catch((err) => {
        console.log(err)
      })
    setUpdateState(null)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleCancel = () => {
    setUpdateState(null)
  }

  return (
    <>
      <tr>
        <td></td>
        <td>
          <input
            className='edit-input fs-6'
            type='text'
            name='name'
            ref={inputRef}
            spellCheck={false}
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
        </td>

        <td style={{width: '10rem'}}>
          <CommonActionButtons handleUpdate={handleUpdate} handleCancel={handleCancel} />
        </td>
        <td></td>
      </tr>
    </>
  )
}

export {TaskType}
