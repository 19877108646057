import React, {FC, useEffect, useState} from 'react'
import {ErrorModal, Loader} from '../../CommonFunctions/CommonFunction'
import '../../../css/document.css'
import Lightbox from 'react-image-lightbox'
interface PopupProps {
  fileUrl: any
  partnerId: any
  orgId: any
  modalClose: any
  fileName: any
}

const ViewDocument: FC<PopupProps> = ({fileUrl, partnerId, orgId, modalClose, fileName}) => {
  const [fileType, setFileType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  useEffect(() => {
    getBlobFile()
  }, [])

  const getBlobFile = async () => {
    try {
      setIsLoading(true)
      const mimeType = determineMimeType(fileName)
      setFileType(mimeType)
      setIsLoading(false)
    } catch (error) {
      ErrorModal()
    }
  }

  const determineMimeType = (fileName: any) => {
    const extension = fileName.split('.').pop().toLowerCase() // Get file extension

    // Determine MIME type based on file extension
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg'
      case 'png':
        return 'image/png'
      case 'pdf':
        return 'application/pdf'
      default:
        return 'application/octet-stream' // Fallback MIME type
    }
  }

  const handleIframeLoad = () => {
    setIsLoading(false)
  }

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
  }
  return (
    <div className='partner_main-container  d-flex'>
      <div className='partner_inner-container'>
        <div
          className='card mt-3 partner_modal-container'
          style={{padding: '0rem 2.5rem 2rem 2.5rem'}}
        >
          <div className='card mt-5'>
            <div
              className='card-header card-header-stretch border-bottom border-gray-200'
              style={{padding: 'unset', minHeight: 'unset'}}
            >
              <h1>Display</h1>
              <i
                className='fa fa-times'
                onClick={() => modalClose()}
                aria-hidden='true'
                style={{
                  fontSize: '2rem',
                  marginLeft: '22rem',
                  cursor: 'pointer',
                }}
              ></i>
            </div>
            <div>
              {isLoading && <div className='mt-3'>{Loader(100)}</div>}

              {!isLoading && fileType === 'application/pdf' && (
                <iframe
                  className='image-container'
                  src={`${fileUrl}#toolbar=0&navpanes=0`}
                  // style={{width: '100%', height: '26rem'}}
                  title='PDF Viewer'
                  onLoad={handleIframeLoad}
                ></iframe>
              )}

              {!isLoading && (fileType === 'image/jpeg' || fileType === 'image/png') && (
                <div>
                  <div
                    className='d-flex mt-2 mb-2'
                    style={{
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <img
                      className='image-container'
                      src={fileUrl}
                      alt='Image File'
                      onClick={() => toggleModal()}
                    />
                  </div>
                  {modalIsOpen && (
                    <Lightbox
                      mainSrc={fileUrl}
                      imageTitle=''
                      imageCaption={fileName}
                      onCloseRequest={toggleModal} // Provide a function to close the modal
                      animationDisabled={false}
                      imagePadding={60}
                      wrapperClassName='custom-lightbox-wrapper'
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <style>{`
          .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
            animation: fadeIn 0.5s ease;
            
          }

          #div-scroller::-webkit-scrollbar-thumb {
            background-color: #C1C1C1 !important;
          }
          #div-scroller::-webkit-scrollbar {
            width:0.5rem
          }



          

        .custom-modal-header{
            padding-right: 29rem;
            font-size: 1.8rem;
            font-weight: 700;
        }

        .paginationBttns{
            width:23rem;
            margin: 2rem auto 0 auto;
        }

        .temp-class{
            padding: 0.5rem;
            width: 8rem;
            margin: 0;
            justify-content:center;
            margin:unset
        }


        `}</style>
    </div>
  )
}

export {ViewDocument}
