import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DashboardWrapper1} from '../pages/screenshots/dash'
import {EmpWrapper} from '../pages/addEmp/emp'
import {ProWrapper} from '../pages/addPro/Project'
import {AttendanceSheetWrapper} from '../pages/attendance/attend'
import {MarkTaskCompletion} from '../pages/Task/TaskCompletion'
import {Holiday} from '../pages/Holiday/Holiday'

import {Passwords} from '../pages/passwords/cards/passwords'

import {Urls} from '../pages/url'
import {Individual} from '../pages/Web&App/individual'

import {ProductivitysSheet} from '../pages/Productivitys/Productivitys'
import {Attendance} from '../pages/AttendanceReport/Attendance'
import {Timelines} from '../pages/Timeline/Timelines'
import {WebWrapper} from '../pages/Websites&Application/WebWrapper'

import {EmpWebsite} from '../pages/Websites&Application/emp/EmpWebsite'
import {EmpApplication} from '../pages/Websites&Application/emp/EmpApplication'

import TimeClaim from '../pages/TimeClaim/TimeClaim'
import {UserTimeClaim} from '../pages/TimeClaim/UserTimeClaim'
import ActivitySummary from '../pages/activitySummary/ActivitySummary'
import TimeEntriesReport from '../pages/timeEntries/TimeEntriesReport'
import UserTimeEntries from '../pages/timeEntries/UserTimeEntries'

import {UserProjectTaskReport} from '../pages/userProjectTaskReport/userProjectTaskReport'
import WeeklySummary from '../pages/weeklySummary/WeeklySummary'
import SupportForm from '../pages/supportFrom/SupportFrom'

const PrivateRoutesEmp = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/builder' />} />
        {/* Pages */}

        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='testpage' element={<DashboardWrapper1 />} />
        {/* <Route path='project' element={<ProjectWrapper />} /> */}
        <Route path='add' element={<EmpWrapper />} />
        <Route path='reports' element={<AttendanceSheetWrapper />} />
        <Route path='changePassword' element={<Passwords />} />
        <Route path='report/urls' element={<Urls />} />
        <Route path='report/systemActivity' element={<ActivitySummary />} />
        <Route path='web&app/individual' element={<Individual />} />
        <Route path='holiday' element={<Holiday />} />
        <Route path='productivitys' element={<ProductivitysSheet />} />
        <Route path='report/attendance' element={<Attendance />} />
        <Route path='report/timeline' element={<Timelines />} />
        <Route path='report/dailyTimeEntry' element={<TimeEntriesReport />} />
        <Route path='report/weeklySummary' element={<WeeklySummary />} />
        <Route path='markTaskCompletion' element={<MarkTaskCompletion />} />
        <Route path='userTaskReport' element={<UserProjectTaskReport />} />
        <Route path='support' element={<SupportForm />} />
        <Route path='records' element={<WebWrapper />}>
          <Route index element={<EmpWebsite />} />
          <Route path='website' element={<EmpWebsite />} />
          <Route path='application' element={<EmpApplication />} />
        </Route>

        {/* <Route path='schedule' element={<Schedule className='' />} />
        <Route path='addschedule' element={<AddSchedule />} />
        <Route path='editschedule' element={<EditSchedule />} /> */}
        {/* <Route path="web&app" element={<WebAppMain />}>
          <Route index element={<Individual />} />
          <Route path="individual" element={<Individual />} />
          <Route path="team" element={<Team />} />
        </Route> */}

        {/* <Route path='company' element={<CompanyWrapper />} /> */}
        {/* <Route path='com' element={<ComWrapper />} /> */}
        {/* <Route path='editEmp' element={<EditEmpWrapper />} /> */}
        {/* <Route path='editCompany' element={<EditCompanyDetails />} /> */}
        {/* <Route path='editProject' element={<EditProjectDetails />} /> */}
        {/* <Route path='member' element={<MemberWrapper />} /> */}
        {/* <Route path='newproject' element={<ProWrapper />} /> */}

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='report/timeTracker'
          element={
            <SuspensedView>
              <AttendanceSheetWrapper></AttendanceSheetWrapper>
            </SuspensedView>
          }
        />
        <Route
          path='report/productivity'
          element={
            <SuspensedView>
              <ProductivitysSheet></ProductivitysSheet>
            </SuspensedView>
          }
        />
        {/* <Route
          path='reports/productivitys'
          element={
            <SuspensedView>
              <ProductivitysSheet></ProductivitysSheet>
            </SuspensedView>
          }
        /> */}
        <Route
          path='screenshots'
          element={
            <SuspensedView>
              <DashboardWrapper1 />
            </SuspensedView>
          }
        />
        {/* <Route
          path='company/newcompany'
          element={
            <SuspensedView>
              <ComWrapper />
            </SuspensedView>
          }
        /> */}

        <Route path='TimeClaim/claim' element={<TimeClaim />} />
        <Route path='TimeClaim/status' element={<UserTimeClaim />} />
        <Route path='timeEntry' element={<UserTimeEntries />} />

        <Route
          path='member/addmember'
          element={
            <SuspensedView>
              <EmpWrapper></EmpWrapper>
            </SuspensedView>
          }
        />
        <Route
          path='project/addproject'
          element={
            <SuspensedView>
              <ProWrapper></ProWrapper>
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutesEmp}
