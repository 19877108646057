import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

export function getAllAddedInformation() {
  return axios.get(`${API_URL}/AppLabelInformation/GetAllAppInformation`)
}
export function getAppInformationById(appInfoId: string) {
  return axios.get(`${API_URL}/AppLabelInformation/GetAppInformationById`, {
    params: {
      appInfoId,
    },
  })
}
export function EditInformation(body: any) {
  return axios.post(`${API_URL}/AppLabelInformation/UpdateAppInformation`, body)
}
export function DeleteInformation(appInfoId: string) {
  return axios.patch(`${API_URL}/AppLabelInformation/DeleteAppInformation?appInfoId=${appInfoId}`)
}
