import dayjs from 'dayjs'
import React, {useState, useEffect, FC} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import '../AttendanceReport/custom.css'
import axios, {CancelTokenSource} from 'axios'
import {callAllManager, memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import ToggleButton from '@mui/material/ToggleButton'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  Pagination,
  Loader,
  MultiDateTimePicker,
  convertSecIntoHoursMin,
  downloadAttendanceReportData,
  ErrorModal,
} from '../../CommonFunctions/CommonFunction'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import * as XLSX from 'xlsx'
import {ToggleButtonGroup} from '@mui/material'
import {convertSecIntoHoursMinSec} from '../Common_Function/Function'
import {DownloadAttendanceReport} from '../../services/attendance.service'
import {userInfoActions} from '../../modules/auth/redux/UserInfoRedux'

const API_URL = process.env.REACT_APP_API_URL

let cancelTokenSource: CancelTokenSource | null = null

const AdminAttendance: FC = () => {
  const startOfMonth = dayjs().startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  const endOfMonth = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  const UserData: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const role = localStorage.getItem('role')
  const [startDate, setStartDate] = useState(new Date(startOfMonth))
  const [endDate, setEndDate] = useState(new Date(endOfMonth))
  const [showstartdate, setShowStartDate] = useState(dayjs(startOfMonth).format('DD/MM/YYYY'))
  const [showenddate, setShowEndDate] = useState<string | null>(
    dayjs(endOfMonth).format('DD/MM/YYYY')
  )
  const [attendance, setAttendance] = useState<any[]>([])
  const [calendarData, setCalendarData] = useState<any>()
  const [showName, setShowName] = useState<any>('')
  const [screenloader, setScreenLoader] = useState<any>(false)

  const [alignment, setAlignment] = useState('Organization')
  const [memberOption, setMemberOption] = useState<any[]>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [currDisplayState, setCurrDisplayState] = useState<any>('Organization')
  const [selectedMember, setSelectedMember] = useState<any>('All')
  const [showHoverOnList, setShowHoverOnList] = useState<any>(false)
  const selectedRow = React.useState(-1)
  const [validStartDate, setValidStartDate] = useState<any>(dayjs())
  const [validEndDate, setValidEndDate] = useState<any>(dayjs())
  //const [disableReactSelect, setDisableReactSelect] = useState<boolean>(false)
  const [memberError, setMemberError] = useState<any>(false)
  const [dateError, setDateError] = useState<any>(false)
  const dispatch = useDispatch()

  // useEffect(() => {
  //   setScreenLoader(true)
  //   const startOfMonth = dayjs()
  //     .startOf('month')
  //     .startOf('day')
  //     .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  //   const endOfMonth = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  //   console.log('the start of month is ', startOfMonth, endOfMonth)
  //   handleDateChange([new Date(startOfMonth), new Date(endOfMonth)])
  // }, [])

  const handleChange = (event: any, newAlignment: string) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    //setDisableReactSelect(true)
    if (currDisplayState === event.target.value) {
      //setDisableReactSelect(false)
    } else {
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: '',
          selectedUserId: '',
        })
      )
      setAlignment(event.target.value)
      if (event.target.value == 'Individual') {
        setOptionSelectedMember(null)
        callAllMemberAPI()
        setAttendance([])
        setMemberError(true)
        setSelectedMember('')
        setCalendarData([])
        setShowName('')
        setValidEndDate('')
        setValidStartDate('')
      } else if (event.target.value == 'Team') {
        setOptionSelectedMember(null)
        callAllManagerAPI()
        setAttendance([])
        setMemberError(true)
        setSelectedMember('')
        setCalendarData([])
        setShowName('')
        setValidEndDate('')
        setValidStartDate('')
      } else if (event.target.value == 'Organization') {
        setOptionSelectedMember(null)
        SendDataOfUser({
          label: 'All Members',
          value: 'All',
        })
        setOptionSelectedMember({
          label: 'All Members',
          value: 'All',
        })
        setMemberError(false)
        setSelectedMember('All')
        setCalendarData([])
        setShowName('')
        setValidEndDate('')
        setValidStartDate('')
      }
    }

    setCurrDisplayState(event.target.value)
  }

  useEffect(() => {
    if (UserData.selectedUserName) {
      setAlignment('Individual')
      setCurrDisplayState('Individual')
      callAllMemberAPI()
      SendDataOfUser({
        label: UserData.selectedUserName,
        value: UserData.selectedUserId,
      })
      setOptionSelectedMember({
        label: UserData.selectedUserName,
        value: UserData.selectedUserId,
      })
    } else if (alignment === 'Organization') {
      SendDataOfUser({
        label: 'All Members',
        value: 'All',
      })
    }
  }, [alignment])

  const earlyStageCall = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    setAttendance([])
    setScreenLoader(true)
    setItemOffset(0)
    setForcePage(true)
  }

  const SendDataOfUser = (value: any) => {
    setMemberError(false)
    earlyStageCall()

    if (startDate == null && endDate == null) {
      setDateError(true)
      setShowName('')
      setScreenLoader(false)
      return
    }

    let lastDate: any
    lastDate = endDate === null ? startDate : endDate

    CallOfEmp(
      value.value,
      dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      dayjs(lastDate).format('YYYY-MM-DDT00:00:00[Z]'),
      value.label
    )
  }

  const SendDataOfDate = (start: any, end: any) => {
    setDateError(false)
    earlyStageCall()

    if (optionSelectedMember == null && alignment !== 'Organization') {
      setMemberError(true)

      setScreenLoader(false)
    }

    if (start == null && end == null) {
      setDateError(true)
      setScreenLoader(false)
      setShowStartDate('')
      setShowEndDate('')
      setCalendarData([])
      setShowName('')
      setAttendance([])
      setValidEndDate('')
      setValidStartDate('')
      return
    } else if (start && end == null) {
      setShowStartDate(dayjs(start).format('DD/MM/YYYY'))
      setShowEndDate(null)
      end = start
    } else {
      setShowStartDate(dayjs(start).format('DD/MM/YYYY'))
      setShowEndDate(dayjs(end).format('DD/MM/YYYY'))
    }

    // if (alignment == 'Organization') {
    //   CallOfEmp(
    //     'All',
    //     dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
    //     dayjs(end).format('YYYY-MM-DDT00:00:00[Z]'),
    //     'All Members'
    //   )
    // } else {
    // }

    CallOfEmp(
      optionSelectedMember ? optionSelectedMember.value : 'All',
      dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
      dayjs(end).format('YYYY-MM-DDT00:00:00[Z]'),
      optionSelectedMember ? optionSelectedMember.label : 'All Members'
    )
  }

  const CallOfEmp = (userId?: any, beginDate?: any, lastDate?: any, name?: any) => {
    try {
      if (alignment == 'Team' || alignment == 'Organization') {
        setShowHoverOnList(true)
      } else {
        setShowHoverOnList(false)
      }

      if (!beginDate) beginDate = dayjs().format('YYYY-MM-DDT00:00:00[Z]')

      const body = {
        organizationId: UserData.orgId,
        managerId: userId ? userId : localStorage.getItem('userId'),
        userId: userId ? userId : localStorage.getItem('userId'),
        fromDate: beginDate ? beginDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        toDate: lastDate ? lastDate : beginDate,
      }

      cancelTokenSource = axios.CancelToken.source()

      let fetch = ''
      if (currDisplayState == 'Individual') {
        fetch = 'AttendanceReport/GetAttendaceReportByUser'
      } else if (currDisplayState == 'Organization' && userId === 'All') {
        fetch = 'AttendanceReport/GetAllUsersAttendanceReport'
      } else if (currDisplayState == 'Team') {
        fetch = 'AttendanceReport/GetAttendaceReportByManager'
      }

      axios
        .post(`${API_URL}/${fetch}`, body, {
          cancelToken: cancelTokenSource?.token,
        })
        .then((res) => {
          if (!Array.isArray(res.data)) {
            setAttendance([res.data])
          } else {
            setAttendance(res.data)
          }

          if (userId !== 'All') {
            viewCalendar(
              userId,
              dayjs(beginDate).format('YYYY-MM-DDT00:00:00[Z]'),
              dayjs(lastDate).format('YYYY-MM-DDT00:00:00[Z]')
            )

            setShowName(name)
          } else {
            setCalendarData([])
            setShowName('')
          }

          setScreenLoader(false)
        })
      // .catch((err) => {

      //   console.log(err)
      //   // setScreenLoader(false)
      // })
    } catch (error) {
      ErrorModal()
      setScreenLoader(false)
    }
  }

  const mapListData = (data: any[]) => {
    return data.map((item: any) => {
      return {
        label: item.fullName,
        value: item.userId,
      }
    })
  }

  const callAllManagerAPI = () => {
    callAllManager().then((res) => {
      const newList = mapListData(res.data)

      setMemberOption(newList)
      // if (newList.length > 0) {
      //   setDisableReactSelect(false)
      // }
    })
  }

  const callAllMemberAPI = () => {
    memberInfoByOrg().then((res) => {
      const newList = mapListData(res.data)
      setMemberOption(newList)
      // if (res.data.length > 0) {
      //   setDisableReactSelect(false)
      // }
    })
  }

  const viewCalendar = (id: any, start?: any, end?: any) => {
    setSelectedMember(id ? id : localStorage.getItem('userId'))

    const body = {
      userId: id ? id : localStorage.getItem('userId'),
      fromDate: start
        ? dayjs(start).format('YYYY-MM-DDT00:00:00[Z]')
        : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
      toDate: end
        ? dayjs(end).format('YYYY-MM-DDT00:00:00[Z]')
        : dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
      organizationId: UserData.orgId,
    }
    cancelTokenSource = axios.CancelToken.source()
    axios
      .post(`${API_URL}/AttendanceReport/GetAttendaceByUser`, body, {
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        const result = res.data.map((item: any) => {
          let colorBG: any = ''
          let allDay: any = false
          let Display: any = ''
          let look: any = ''
          if (item.title == 'Absent') {
            colorBG = '#e74f4e'
            allDay = true
            Display = 'background'
          }
          if (item.title == 'Present') {
            colorBG = '#13aa13'
            allDay = true
            Display = 'background'
          }
          if (item.title == 'ThreeQuarterDay') {
            allDay = true
            Display = 'background'
            look = 'threeQuarterDay'
          }
          if (item.title == 'QuarterDay') {
            allDay = true
            Display = 'background'
            look = 'quarterDay'
          }
          if (item.title == 'Holiday') {
            colorBG = '#4646e2'
            allDay = true
            Display = 'background'
          }
          if (item.title == 'HalfDay') {
            allDay = true
            Display = 'background'
            look = 'halfDayDetail'
          }
          if (item.title == 'Present (Holiday)') {
            allDay = true
            Display = 'background'
            look = 'presentHoliday'
          }
          if (item.title == 'HalfDay (Holiday)') {
            allDay = true
            Display = 'background'
            look = 'HalfDayHoliday'
          }
          if (item.title == 'ThreeQuarterDay (Holiday)') {
            allDay = true
            Display = 'background'
            look = 'threeQuarterDayHoliday'
          }
          if (item.title == 'QuarterDay (Holiday)') {
            allDay = true
            Display = 'background'
            look = 'quarterDayHoliday'
          }
          return {
            ...item,
            title:
              item.title === 'Absent' ||
              item.title === 'Present' ||
              item.title === 'ThreeQuarterDay' ||
              item.title === 'QuarterDay' ||
              item.title === 'Holiday' ||
              item.title === 'HalfDay' ||
              item.title === 'Present (Holiday)' ||
              item.title === 'QuarterDay (Holiday)' ||
              item.title === 'HalfDay (Holiday)'
                ? ''
                : item.title,
            allDay: allDay,
            backgroundColor: colorBG,
            display: Display,
            color: 'black',
            classNames: look,
          }
        })
        setCalendarData(result)
        setValidStartDate(start ? start : dayjs().format())
        setValidEndDate(end ? end : dayjs().format())
      })
      .catch((err) => console.log(err))
  }

  //Pagination
  let itemsPerPage: number = 5
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState<any>()

  useEffect(() => {
    setForcePage(0)
  }, [attendance])

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(attendance.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(attendance.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, attendance])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % attendance.length
    setItemOffset(newOffset)
  }

  const createCsv = async () => {
    const body = {
      fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      toDate:
        endDate !== null
          ? dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]')
          : dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      organizationId: UserData.orgId,
      userType:
        alignment === 'Organization'
          ? 'All'
          : optionSelectedMember.value !== 'All'
          ? alignment
          : 'All',
      userId:
        alignment === 'Organization'
          ? ''
          : optionSelectedMember.value !== 'All'
          ? optionSelectedMember.value
          : '',
    }
    await downloadAttendanceReportData(body, alignment, optionSelectedMember, startDate, endDate)
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    SendDataOfDate(start, end)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px 16px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Attendance</span>
            </h3>

            <div className='d-flex'>
              <div className=' d-flex' style={{marginRight: '10px', height: '3.2rem'}}>
                <ToggleButtonGroup
                  color='primary'
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label='Platform'
                >
                  <ToggleButton value='Organization'>Organization</ToggleButton>
                  <ToggleButton value='Team'>Team</ToggleButton>
                  <ToggleButton value='Individual'>Individual</ToggleButton>
                </ToggleButtonGroup>
              </div>

              <div
                style={{marginRight: '10px', width: '200px'}}
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title={alignment == 'Team' ? 'Search Manager' : 'Search Member'}
              >
                <Select
                  components={makeAnimated()}
                  value={
                    alignment === 'Organization'
                      ? {label: 'All Members', value: 'All'}
                      : optionSelectedMember
                  }
                  isDisabled={alignment === 'Organization'}
                  options={memberOption}
                  placeholder='Select Member'
                  onChange={(item: any) => {
                    setOptionSelectedMember(item)
                    setSelectedMember(item.value)
                    if (
                      alignment === 'Individual' &&
                      (role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                        role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE)
                    ) {
                      dispatch(
                        userInfoActions.updateSelectedUser({
                          selectedUserName: item.label,
                          selectedUserId: item.value,
                        })
                      )
                    }
                    SendDataOfUser(item)
                  }}
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnScroll={true}
                />

                {memberError && (
                  <span className='text-danger' style={{marginLeft: '1rem'}}>
                    Please Select Member
                  </span>
                )}
              </div>

              <div className='d-flex flex-column'>
                {MultiDateTimePicker(
                  startDate,
                  endDate,
                  handleDateChange,
                  'form-control custom-Height'
                )}

                {dateError && (
                  <span className='text-danger' style={{marginLeft: '1rem'}}>
                    Please Select Date
                  </span>
                )}
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  disabled={screenloader || startDate === null || !selectedMember ? true : false}
                  className='btn btn-primary'
                  style={{marginLeft: '1rem', height: '3.2rem'}}
                  onClick={() => createCsv()}
                >
                  <i className='fa fa-download'></i>Download
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3' style={{padding: '40px'}}>
          {/* begin::Table container */}

          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4' style={{border: '1px solid'}}>
              {/* begin::Table head */}
              <thead>
                <tr
                  className='fw-bolder fs-5'
                  style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
                >
                  <th className=' w-10px'></th>
                  <th className=' min-w-150px'>Name</th>
                  <th className=' min-w-120px'>Date</th>
                  <th className=' min-w-150px text-center'>Logged Hours</th>
                  <th className=' min-w-140px text-center'>Total Hours Expected</th>
                  <th className=' min-w-120px text-center'>No of Holidays</th>
                  <th className=' min-w-120px text-center'>No of Leaves</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {currentItems.map((item: any) => (
                  <tr
                    key={item.userId}
                    onClick={() => {
                      viewCalendar(item.userId, startDate, endDate)
                      setShowName(item.userName)
                    }}
                    className={`${item.userId == selectedMember ? 'changeColorBackground' : ''} ${
                      showHoverOnList === true
                        ? 'clickable-row '.concat(selectedRow === item.userId ? 'selected' : '')
                        : 'cursor-default'
                    }`}
                  >
                    <td></td>
                    <td>
                      <span className='fw-bold d-block fs-5'>{item.userName}</span>
                    </td>
                    <td>
                      <span className='fw-bold d-block fs-5'>
                        {' '}
                        {showstartdate}
                        {showenddate
                          ? showenddate == 'Invalid Date'
                            ? ''
                            : ' - ' + showenddate
                          : ''}
                      </span>
                    </td>
                    <td>
                      <span className='fw-bold d-block fs-5 text-center'>
                        {convertSecIntoHoursMinSec(item.activeTime)}
                      </span>
                    </td>
                    <td>
                      <span className='fw-bold d-block fs-5 text-center'>{`${item.totalExpectedHours}:00:00`}</span>
                    </td>
                    <td>
                      <span className='fw-bold d-block fs-5 text-center'>{item.holiday}</span>
                    </td>
                    <td>
                      <span className='fw-bold d-block fs-5 text-center'>{item.leaves}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>

            {screenloader ? (
              Loader('0px')
            ) : currentItems.length !== 0 ? (
              ''
            ) : (
              <h2 className='noRecordFound'>No Records Found</h2>
            )}
          </div>
          {/* end::Table */}
          {attendance.length > 4 && (
            <div className='d-flex align-items-center justify-content-center'>
              {Pagination(handlePageClick, pageCount, forcePage)}
            </div>
          )}
        </div>
        {/* end::Body */}
      </div>

      <div style={{background: 'white', padding: '50px', borderRadius: '7px'}}>
        <FullCalendar
          customButtons={{
            ShowName: {
              text: showName,
            },
          }}
          height='80vh'
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next',
            center: `title`,
            right: currDisplayState == 'Team' ? (showName ? 'ShowName' : '') : '',
          }}
          initialView='dayGridMonth'
          validRange={{
            start: dayjs(validStartDate).format('YYYY-MM-DDTHH:mm:ssZ'),
            end: dayjs(validEndDate).add(1, 'day').format('YYYY-MM-DD'),
          }}
          dayCellClassNames='daycell'
          editable={false}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          events={calendarData}
        />
      </div>
    </>
  )
}

export {AdminAttendance}
