/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'

import swal from 'sweetalert2'
import {Loader, AlertModal} from '../../CommonFunctions/CommonFunction'

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import {
  getAllBillingPlans,
  DeleteBillingPlan,
  GetAllCustomBillingPlans,
} from '../../services/Payment.services'

const AllPlans: React.FC = () => {
  const navigate = useNavigate()
  const [allPlans, setAllPlans] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)

  useEffect(() => {
    setSpinner(true)
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      // Make both API calls concurrently
      const [plansResponse, customPlansResponse] = await Promise.all([
        getAllBillingPlans(),
        GetAllCustomBillingPlans(),
      ])

      const plansData = plansResponse.data
      const customPlansData = customPlansResponse.data

      const mergedPlans = [...plansData, ...customPlansData]

      setAllPlans(mergedPlans)

      setSpinner(false)
    } catch (error) {
      console.error('Error fetching plans:', error)
    }
  }

  const deleteHandler = (planId: any) => {
    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Yes, delete it!'
    ).then(async (result: any) => {
      if (result.isConfirmed) {
        let updateStatus = await DeleteBillingPlan(planId)

        AlertModal(
          updateStatus.data.message,
          '',
          updateStatus.data.success ? 'success' : 'warning',
          false,
          '#7066E0',
          'Ok'
        ).then(() => {
          setSpinner(true)
          fetchData()
        })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Entry not Deleted', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder mb-1' style={{fontSize: '19px'}}>
            Plans
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>

        <div style={{display: 'flex'}}>
          <Tippy placement='top' content='Click to add a new plan'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              // title='Click to add a user'
            >
              <Link to='/allPlans/addNewPlan' className='btn btn-sm btn-primary me-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add new Plan
              </Link>
            </div>
          </Tippy>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      {spinner ? (
        Loader('100px')
      ) : (
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                  </th>
                  <th className='min-w-120px'> Name</th>
                  <th className='min-w-120px'> Description</th>
                  <th className='min-w-120px'> Currency</th>
                  <th className='min-w-120px'> Amount</th>
                  <th className='min-w-120px'> Features</th>
                  <th className='min-w-120px'> Custom Plan</th>
                  <th className='min-w-175px text-center'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allPlans.length > 0 &&
                  allPlans?.map((names: any) => (
                    <tr key={names.planId}>
                      <td></td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column '>
                            {names.planName}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column '>
                            {names.planDescription}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column '>
                            {names.currencyCode}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column '>
                            {names.amount}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column '>
                            {names.benefits.join(', ')}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column '>
                            {names.isCustomPlan === false ? 'No' : 'Yes'}
                          </div>
                        </div>
                      </td>

                      <td style={{width: '10rem'}}>
                        <div className='d-flex justify-content-evenly flex-shrink-0'>
                          <Tippy placement='top' content='Edit'>
                            <Link
                              to={`/allPlans/editPlanDetails?planId=${names.planId}`}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </Link>
                          </Tippy>

                          <Tippy placement='top' content='Delete plan'>
                            <a
                              onClick={() => deleteHandler(names.planId)}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                names.isActive === false ? 'disabled' : ''
                              }`}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </Tippy>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default AllPlans
