import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import {
  Pagination,
  MemberAndPlanSearchBar,
  Loader,
  RowsPerPage,
  ErrorModal,
} from '../../CommonFunctions/CommonFunction'
import 'tippy.js/dist/tippy.css'

import axios from 'axios'
import CurrentPlanModal from '../../../_metronic/partials/modals/payment/CurrentPlanModal'
import {Link} from 'react-router-dom'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {GatOrgsPaymentDetailsFromPartner} from '../../services/partner.service'

const API_URL = process.env.REACT_APP_API_URL

const ExistingPlan: React.FC = () => {
  const UserRole: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [loader, setLoader] = useState<boolean>(false)
  const [companies, setCompanies] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [rowPerPage, setRowPerPage] = useState(10)
  const [forcePage, setForcePage] = useState<any>(null)
  const [companyDetails, setComapnayDeatils] = useState<any[]>([])

  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  const filteredMembers = companies.filter((list) =>
    list.organizationName?.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const currentItemss = filteredMembers.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(companies.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % companies.length
    setItemOffset(newOffset)
  }

  const getListSubscribeOrg = async () => {
    try {
      if (UserRole.role === 'Partner') {
        const getPaymentDetails = await GatOrgsPaymentDetailsFromPartner(UserRole.userId)
        setCompanies(getPaymentDetails.data)
        setLoader(false)
      } else {
        axios
          .get(`${API_URL}/Payment/GetAllCurrentPayment`)
          .then((res) => {
            setCompanies(res.data)
            setLoader(false)
          })
          .catch((err) => console.log(err))
      }
    } catch (error) {
      ErrorModal()
    }
  }

  useEffect(() => {
    setLoader(true)

    getListSubscribeOrg()
  }, [])

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value)
    setItemOffset(0)
  }

  const viewHandler = (id: number) => {
    axios
      .get(`${API_URL}/Payment/GetPaymentDetailsByPaymentId`, {
        params: {
          payment_Id: id,
        },
      })
      .then((res) => {
        setComapnayDeatils(res.data)
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              {UserRole.role === 'Partner' ? 'Payment Details' : 'Payment History'}
            </span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>

          {MemberAndPlanSearchBar('Search Organization', 'Search Organization', handleSearchChange)}
        </div>

        <div className='card-body py-3 mb-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder'>
                  <th>
                    {/* <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          </div> */}
                  </th>
                  <th className='min-w-125px'>Organization Name</th>
                  <th className='min-w-125px'>Active Plan</th>
                  <th className='min-w-125px'>Total Users</th>
                  <th className='min-w-125px'>Active Users</th>
                  <th className='min-w-125px'>Mode</th>
                  <th className='min-w-125px'>Effective From</th>
                  <th className='min-w-125px'>Termination Date</th>
                  <th className='min-w-100px text-center'>Actions</th>
                  <th></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {companies?.length > 0 &&
                  !loader &&
                  currentItemss.map((names) => (
                    <tr key={names.paymentId}>
                      <td></td>

                      <td>
                        <span className='text-dark  d-block fs-6 '>{names.organizationName}</span>
                      </td>

                      <td>
                        <span className='text-dark  d-block fs-6'>{names.billingPlanName}</span>
                      </td>
                      <td>
                        <span className='text-dark  d-block fs-6'>{names?.noOfUsers==0?'-':names.noOfUsers}</span>
                      </td>
                      <td>
                        <span className='text-dark  d-block fs-6'>{names?.noOfActiveUsers==0?'-':names.noOfActiveUsers}</span>
                      </td>
                      <td>
                        <span className='text-dark  d-block fs-6'>{names.transactionType}</span>
                      </td>

                      <td>
                        <span className='text-dark d-block fs-6'>
                          {dayjs(names.effectiveFrom).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark d-block fs-6'>
                          {dayjs(names.terminationDate).format('DD/MM/YYYY')}
                        </span>
                      </td>

                      <td>
                        <div className='d-flex justify-content-evenly flex-shrink-0'>
                          <button
                            onClick={() => viewHandler(names.paymentId)}
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_CurrentPlan'
                            className='btn btn-sm btn-primary '
                          >
                            View
                          </button>

                          <Link
                            to='/paymentHistory'
                            // onClick={() => viewHandler(names.paymentId)}
                            // data-bs-toggle='modal'
                            // data-bs-target='#kt_modal_paymentHistory'
                            className='btn btn-sm btn-primary '
                            onClick={() => localStorage.setItem('editOrg', names.organizationId)}
                          >
                            History
                          </Link>
                        </div>
                      </td>

                      <td></td>
                    </tr>
                  ))}
              </tbody>

              {companies?.length <= 0 && !loader && (
                <span className='position-absolute w-100'>
                  <h2 className='noRecordFound'>No Records Found</h2>
                </span>
              )}
              {/* end::Table body */}
            </table>
          </div>

          {loader && Loader('100px')}

          {companies.length > 10 && (
            <div className='d-flex align-items-center justify-content-center position-relative mb-3'>
              <div style={{left: '2rem', position: 'absolute', top: '0'}}>
                {RowsPerPage(
                  setForcePage,
                  setRowPerPage,
                  setItemOffset,
                  rowPerPage,
                  companies.length,
                  '0px'
                )}
              </div>

              {filteredMembers.length > rowPerPage &&
                Pagination(handlePageClick, pageCount, forcePage)}
            </div>
          )}

          <span
            className={`d-flex justify-content-center w-100 ${
              companies.length <= 0 ? 'mt-20' : ''
            }`}
            style={{fontWeight: '600'}}
          >
            Showing {currentItemss.length} Records out of {companies.length}
          </span>
        </div>
      </div>
      <CurrentPlanModal data={companyDetails} />
    </>
  )
}

export default ExistingPlan
