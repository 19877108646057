import axios, {CancelTokenSource} from 'axios'
import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import './tables.css'
import {
  Pagination,
  Loader,
  RowsPerPage,
  SingleDatePickerComponent,
  loadAssigeeAndMember,
  earlyStageCall,
  SendDataOfUser,
} from '../../../../app/CommonFunctions/CommonFunction'
import '../../../../css/systemActivity.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import {convertSecIntoHoursMinSec} from '../../../../app/pages/Common_Function/Function'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import {RootState} from '../../../../setup'
import {userInfoActions} from '../../../../app/modules/auth/redux/UserInfoRedux'

type Props = {
  className: string
}
const API = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

const TablesWidget5: React.FC<Props> = ({className}) => {
  const role = localStorage.getItem('role')
  const [posts, setPosts] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [memberOption, setMemberOption] = useState<any>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [screenloader, setScreenLoader] = useState<any>(false)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [userTimeZone, setUserTimeZone] = useState<string>('')
  const dispatch = useDispatch()
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)

  const SendDataOfDate = (date: any) => {
    earlyStageCall(cancelTokenSource, setPosts, setScreenLoader, setItemOffset)
    fetchPosts(dayjs(date).format('YYYY-MM-DDT00:00:00[Z]'), optionSelectedMember.value)
  }

  const fetchPosts = (date: any, userId?: any) => {
    setScreenLoader(true)
    const body = {
      userId: userId ? userId : localStorage.getItem('userId'),
      date: date,
    }
    cancelTokenSource = axios.CancelToken.source()
    axios
      .post(`${API}/CaptureWindows/GetScreenActivity`, body, {
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setUserTimeZone(res.data.timeZone)
        setPosts(res.data.captureProcessResponse)
        setScreenLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setScreenLoader(false)
      })
  }

  useEffect(() => {
    const isGroupAdmin = localStorage.getItem('isGroupAdmin')
    if (!isGroupAdmin && !ConfigDetails.selectedUserId) {
      setOptionSelectedMember({
        label: localStorage.getItem('name'),
        value: localStorage.getItem('userId'),
      })
    }
    if (!isGroupAdmin && ConfigDetails.selectedUserId) {
      setOptionSelectedMember({
        label: ConfigDetails?.selectedUserName,
        value: ConfigDetails?.selectedUserId,
      })
    }
    loadAssigeeAndMember(role, setMemberOption, setOptionSelectedMember, ConfigDetails)
  }, [])

  useEffect(() => {
    if (!ConfigDetails.selectedUserId) {
      fetchPosts(dayjs().format('YYYY-MM-DDT00:00:00[Z]'))
    } else {
      fetchPosts(dayjs().format('YYYY-MM-DDT00:00:00[Z]'), ConfigDetails.selectedUserId)
    }
  }, [])

  //Pagination
  let itemsPerPage: number = rowPerPage
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState<any>()

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(posts.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(posts.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, posts])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % posts.length
    setItemOffset(newOffset)
  }

  const handleDateChange = (dates: any) => {
    setStartDate(dates)
    SendDataOfDate(dates)
  }

  return (
    <>
      <div className={`card ${className} mt-8`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1 justify-content-center'>
              System Activity
            </span>
          </h3>

          <div className='d-flex align-items-center justify-content-end'>
            <label className='mx-5 fs-5 fw-bold'>
              <span className='fw-bolder'>TimeZone - </span>
              {userTimeZone}
            </label>

            <div className='d-flex align-items-center'>
              <div>
                <div
                  style={{width: '200px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Member'
                >
                  {role === process.env.REACT_APP_SYSTEM_THIRD_ROLE ? (
                    <>
                      {memberOption.length > 1 && (
                        <Select
                          className='selectDropdown'
                          components={makeAnimated()}
                          value={optionSelectedMember}
                          options={memberOption}
                          placeholder='select member'
                          onChange={(item: any) => {
                            setOptionSelectedMember(item)
                            const userParams = {
                              userId: item.value,
                              cancelTokenSource,
                              setPosts,
                              setScreenLoader,
                              setItemOffset,
                              startDate,
                              fetchPosts,
                            }
                            SendDataOfUser(userParams)
                          }}
                          isClearable={false}
                          isSearchable={true}
                          closeMenuOnScroll={true}
                        />
                      )}
                    </>
                  ) : (
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      value={optionSelectedMember}
                      options={memberOption}
                      placeholder='select member'
                      onChange={(item: any) => {
                        setOptionSelectedMember(item)
                        if (
                          role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                          role == process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
                          role == process.env.REACT_APP_SYSTEM_SECOND_ROLE
                        ) {
                          dispatch(
                            userInfoActions.updateSelectedUser({
                              selectedUserName: item.label,
                              selectedUserId: item.value,
                            })
                          )
                        }
                        const userParams = {
                          userId: item.value,
                          cancelTokenSource,
                          setPosts,
                          setScreenLoader,
                          setItemOffset,
                          startDate,
                          fetchPosts,
                        }
                        SendDataOfUser(userParams)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  )}
                </div>
              </div>
              {SingleDatePickerComponent(startDate, handleDateChange, 'form-control ss_date')}
            </div>
          </div>
        </div>
        <div>
          <div className='card-body py-3' style={{padding: '30px'}}>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4' style={{border: '1px solid '}}>
                <thead>
                  <tr
                    className='fw-bolder'
                    style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
                  >
                    <th className=''></th>
                    <th className='min-w-150px text-start'>Apps / URL's</th>
                    <th className='min-w-300px text-start'>Title</th>
                    <th className='min-w-125px text-center'>Start Time</th>
                    <th className='min-w-125px text-center'>Time Spent</th>
                    <th className=''></th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item: any, index: any) => (
                    <tr key={index}>
                      <td></td>
                      <td>
                        <span className='text-dark  fs-6 ' style={{lineBreak: 'anywhere'}}>
                          {item.url === '' ? item.processName : item.url}
                        </span>
                      </td>

                      <td>
                        <div>
                          <span className='text-dark  fs-6' style={{lineBreak: 'anywhere'}}>
                            {item.titleName}
                          </span>
                        </div>
                      </td>
                      <td className=' text-center '>
                        <span className='text-dark  fs-6'>
                          {dayjs(item.startTime).format('HH:mm:ss')}
                        </span>
                      </td>
                      <td className=' text-center '>
                        <span className='text-dark  fs-6'>
                          {convertSecIntoHoursMinSec(item.processTotalTimeSeconds)}
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {screenloader ? (
                Loader('100px')
              ) : currentItems.length != 0 ? (
                ''
              ) : (
                <h2 className='noRecordFound'>No Records Found</h2>
              )}
            </div>
            {posts.length > 10 && (
              <div className='d-flex align-items-center justify-content-center position-relative mb-7'>
                <div style={{left: '0', position: 'absolute', top: '0'}}>
                  {RowsPerPage(
                    setForcePage,
                    setRowPerPage,
                    setItemOffset,
                    rowPerPage,
                    posts.length,
                    '1rem'
                  )}
                </div>

                {posts.length > rowPerPage && Pagination(handlePageClick, pageCount, forcePage)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export {TablesWidget5}
