import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { AlertModal, Loader, Pagination } from '../../CommonFunctions/CommonFunction'
import Tippy from '@tippyjs/react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import axios from 'axios'
import Swal from 'sweetalert2'

const ExtendTrial: React.FC = () => {

    const [extendTrail, setExtendTrail] = useState<[]>([])
    const [screenloader, setScreenLoader] = useState<any>(false)
    const API_URL = process.env.REACT_APP_API_URL


    const getTrailDetails = () => {
        setScreenLoader(true)
        axios.get(`${API_URL}/OrganizationTrialExtenstion/GetTrialHistoryByOrgId`, {
            params: {
                orgId: localStorage.getItem('org_Id'),
            }
        })
            .then(res => {
                setExtendTrail(res.data)
            })
            .catch(err => console.log(err))

        setScreenLoader(false)
    }

    useEffect(() => {
        getTrailDetails()
    }, [])

    const deleteHandler = (id: any) => {
        AlertModal(
            'Are you sure?',
            "You won't be able to revert this!",
            'warning',
            true,
            '#3085d6',
            'Yes, delete it!'
        ).then((result: any) => {
            if (result.isConfirmed) {
                axios.post(`${API_URL}/OrganizationTrialExtenstion/DeleteTrialRequest?trialExtensionRequestId=${id}`)
                    .then((res) => {
                        getTrailDetails()
                        AlertModal(
                            res.data.message,
                            '',
                            res.data.success ? 'success' : 'warning',
                            false,
                            '#7066E0',
                            'Ok'
                        )
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                AlertModal('Trial not Deleted', '', 'error', false, '#7066E0', 'Ok')

            }
        })
    }

    let itemsPerPage: number = 10
    const [currentItems, setCurrentItems] = useState<any>([])
    const [pageCount, setPageCount] = useState(0)
    const [itemOffset, setItemOffset] = useState(0)

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage

        setCurrentItems(extendTrail.slice(itemOffset, endOffset))
        setPageCount(Math.ceil(extendTrail.length / itemsPerPage))
    }, [itemOffset, itemsPerPage, extendTrail])

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % extendTrail.length
        setItemOffset(newOffset)
    }


    return (
        <>
            <div className='card mb-5 mb-xl-8'>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>Trial</span>
                        
                        {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new members</span> */}
                    </h3>
                    <span className='d-flex justify-content-end align-items-center text-danger'>* Approval of trial extension request is at the discretion of MeraMonitor Admin only</span>
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                    >
                        <div>
                            <Tippy placement='top' content='Click to add a Trail'>
                                <Link to='/addTrial' className='btn btn-sm btn-primary me-3'>
                                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                                    Add Trial Extension
                                </Link>
                            </Tippy>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3' style={{ padding: '30px' }}>
                    <div className='table-responsive'>
                        <table
                            className='table align-middle gs-0 gy-4'
                            style={{ borderRadius: '5px', border: '1px solid' }}
                        >
                            <thead>
                                <tr className='fw-bolder' style={{ background: '#728FCE', fontSize: '15px' }}>
                                    <th className='min-w-10px'></th>
                                    <th className='min-w-100px'>Start Date</th>
                                    <th className='min-w-100px'>End Date</th>
                                    <th className='min-w-100px text-center'>Requested Days</th>
                                    <th className='min-w-150px'>Comment</th>
                                    <th className='min-w-100px'>Status</th>
                                    <th className='min-w-100px text-center'>Extension Days</th>
                                    <th className='min-w-150px'>Reason</th>
                                    <th className='min-w-125px text-center'>Action</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {currentItems.map((name: any) => (
                                    <tr key={currentItems.trialRequestId}>
                                        <td></td>
                                        <td>
                                            <div className='d-flex justify-content-start flex-column'>
                                                <span className='text-dark  d-block fs-6 '>
                                                    {dayjs(name.trialStartDate).format('DD/MM/YYYY')}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <span className='text-dark  d-block fs-6 '>
                                                {dayjs(name.trialEndDate).format('DD/MM/YYYY')}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='text-dark text-center d-block fs-6 '>
                                                {name.requestDays}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='text-dark  d-block fs-6 '>
                                                {name.requestReason}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='text-dark  d-block fs-6 '>
                                                {name.status}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='text-dark text-center d-block fs-6 '>
                                                {name.extensionDays}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='text-dark  d-block fs-6 '>
                                                {name.responseReason}
                                            </span>
                                        </td>
                                        <td>
                                            {name.status == 'Pending' &&
                                                (
                                                    <div className='d-flex justify-content-center flex-shrink-0'>
                                                        <Tippy placement='top' content='Edit Trial'>
                                                            <Link
                                                                to='/editTrial'
                                                                onClick={() => {
                                                                    localStorage.setItem('editUser', name.trialRequestId)
                                                                }}
                                                                className={`btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm me-1`}
                                                            >
                                                                <KTSVG
                                                                    path='/media/icons/duotune/art/art005.svg'
                                                                    className='svg-icon-3'
                                                                />
                                                            </Link>
                                                        </Tippy>

                                                        <Tippy placement='top' content='Delete Trial'>
                                                            <a
                                                                onClick={() => deleteHandler(name.trialRequestId)}
                                                                className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm'
                                                            >
                                                                <KTSVG
                                                                    path='/media/icons/duotune/general/gen027.svg'
                                                                    className='svg-icon-3'
                                                                />
                                                            </a>
                                                        </Tippy>
                                                    </div>
                                                )}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}

                        {screenloader && Loader('100px')}

                        {!screenloader && currentItems.length === 0 && (
                            <h2 className='noRecordFound'>No Records Found</h2>
                        )}
                    </div>
                    {/* end::Table container */}
                </div>
                {extendTrail.length > 10 && (
                    <div className='d-flex align-items-center justify-content-center mb-3'>
                        {Pagination(handlePageClick, pageCount, 0)}
                    </div>
                )}
            </div>
        </>
    )
}

export default ExtendTrial