import Tippy from '@tippyjs/react'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios from 'axios'
import * as Yup from 'yup'
import {AlertModal, ErrorModal} from '../../CommonFunctions/CommonFunction'

type Props = {
  value?: any
  display?: string
  deleteHandler?: any
  partnerId?: any
  refreshList?: any
  cancelHandler: any
  updateStatus?: any
}

const PartnerCommision: React.FC<Props> = ({
  value,
  display,
  deleteHandler,
  partnerId,
  refreshList,
  cancelHandler,
  updateStatus,
}) => {
  const API_URL = process.env.REACT_APP_API_URL
  const [data, setData] = useState<any>(value)
  const [rowsData, setRowsData] = useState<any>([])
  const [org, setOrg] = useState<any>()
  const [orgOptions, setOrgOptions] = useState<any[]>([])
  const [newData, setNewData] = useState<any[]>([])
  const [showAddButton, setShowAddButton] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean | null>(false)

  useEffect(() => {
    if (display == 'Add') {
      axios
        .get(`${API_URL}/PartnerOrgAssociation/GetAllOrgWithOutPartnerAssociation`)
        .then((res) => {
          const newList = res.data.map((item: any) => {
            return {
              label: item.organizationName,
              value: item.organizationId,
            }
          })
          setOrgOptions(newList)
        })
        .catch((err) => console.log(err))
    }
    if (data) {
      formik.setFieldValue('id', data?.id)
      formik.setFieldValue('commissionPercentage', data?.commissionPercentage)
      formik.setFieldValue('organizationId', data?.organizationId)
      formik.setFieldValue('partnerId', data?.partnerId)
      formik.setFieldValue('taxNumber', data?.taxNumber)
      setOrg([{label: data?.organizationName, value: data?.organizationId}])
      //console.log(data.documentFileList)
      setRowsData(data.documentFileList)
    } else {
      formik.setFieldValue('partnerId', partnerId)
    }
  }, [])

  const deleteTableRows = (id: any, index: number, location: any) => {
    if (location === 'text') {
      const newList = rowsData.filter((item: any) => item.documentId !== id)
      setRowsData(newList)
      const deleteValue = rowsData.filter((item: any) => item.documentId == id)
      formik.setFieldValue('deletePartnerContractDocFiles', [
        ...formik.values.deletePartnerContractDocFiles,
        deleteValue[0],
      ])

      //setDeletedValue((prev) => [...prev, deleteValue[0]])
    } else {
      setShowAddButton(true)
      setNewData([])
    }

    //setRemoveButton(true)
    // setError(false)
    // if (display == 'Add') {
    //   const rows = [...rowsData]
    //   rows.splice(index, 1)
    //   rows[rows.length - 1] = {...rows[rows.length - 1], isDisable: false}
    //   setRowsData(rows)
    // } else {
    //   if (id) {
    //     const newList = rowsData.filter((item: any) => item.documentId !== id)
    //     setRowsData(newList)
    //   } else {
    //     const rows = [...rowsData]
    //     rows.splice(index,1)
    //     setRowsData(rows)
    //   }
    //   //console.log(newList)
    // }

    // if (index === 1) {
    //   setRemoveButton(true)
    //   return
    // }
    // if (rows.length === 0) {
    //   //setUpdateButtonDisable(true)
    // }
  }

  // const addTableRows = async () => {
  //   if (newData.length == 0) {
  //     const documentId = Math.random().toString(16).slice(2)
  //     setNewData((prev: any) => [...prev, {documentId}])
  //     setShowAddButton(false)
  //   } else {
  //     //setShowAddButton(false)
  //   }
  // }

  const partnerCommissionValidation = Yup.object().shape({
    organizationId: Yup.string()
      .min(3, 'Minimum 3 character')
      .max(50, 'Maximum 50 character')
      .required('Organization is required'),
    commissionPercentage: Yup.number()
      .required('Commission is required')
      .min(1, 'Please Enter Number more than 0')
      .max(99, 'Please Enter Number less than 100'),
  })

  const initialValues = {
    id: '',
    partnerName: '',
    commissionPercentage: '',
    organizationId: '',
    partnerId: '',
    taxNumber: '',
    partnerContractDocFiles: [],
    deletePartnerContractDocFiles: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: partnerCommissionValidation,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      if (display == 'Add') {
        handleDisplayAdd(values)
      }
      if (display == 'Edit') {
        handleDisplayEdit(values)
      } else {
        updateStatus(data?.id)
      }
    },
  })

  const handleDisplayAdd = (values: any) => {
    try {
      const form = new FormData()
      const data: any = values?.partnerContractDocFiles[0]
      if (data) {
        for (const item of data) {
          form.append(`partnerContractDocFiles`, item)
        }
      }
      form.append('organizationId', values.organizationId)
      form.append('partnerId', values.partnerId)
      form.append('taxNumber', values.taxNumber)
      form.append('commissionPercentage', values.commissionPercentage)
      axios
        .post(`${API_URL}/PartnerOrgAssociation/AddPartnerOrgAssociation`, form, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          )

          refreshList(partnerId)
        })
        .catch((err) => console.error(err))
    } catch (error) {
      ErrorModal()
    }
  }

  const handleDisplayEdit = (values: any) => {
    try {
      const form = new FormData()
      const data: any = values.partnerContractDocFiles[0]
      if (data) {
        for (const item of data) {
          form.append(`AddPartnerContractDocFiles`, item)
        }
      }
      form.append('id', values.id)
      form.append('organizationId', values.organizationId)
      form.append('partnerId', values.partnerId)
      form.append('taxNumber', values.taxNumber)
      form.append('commissionPercentage', values.commissionPercentage)
      form.append(
        'SerilizedDocDetailToDeleteFiles',
        JSON.stringify(values.deletePartnerContractDocFiles)
      )
      axios
        .post(`${API_URL}/PartnerOrgAssociation/UpdatePartnerOrgAssociation`, form, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          )
          updateStatus(null)
          refreshList(partnerId)
        })
        .catch((err) => console.error(err))
    } catch (error) {
      ErrorModal()
    }
  }
  return (
    <div className='row g-6 g-xl-9 mb-5 mx-5'>
      <div className='card-body  p-0 bg-white'>
        <div className=' card-rounded w-100' style={{border: '2px solid black'}}>
          <form onSubmit={formik.handleSubmit} className='form d-flex'>
            <div className='card-body mx-15 mt-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'> Organization</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    //style={{ width: '200px' }}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Organization'
                  >
                    <Select
                      isDisabled={display == 'Add' ? false : true}
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={orgOptions}
                      value={org}
                      onBlur={formik.handleBlur}
                      placeholder='Select Organization'
                      onChange={(item: any) => {
                        setOrg(item)
                        // setBillingPlan([])
                        formik.setFieldValue('organizationId', item.value)
                        // completePlansData(item.value)
                        // formik.setFieldValue('OrganizationId', item !== null ? item.value : '')
                        // formik.setFieldValue('ExtraUserCount', '')
                        //SendDataOfUser(item.value)
                      }}
                      isClearable={true}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  {formik.touched.organizationId && formik.errors.organizationId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.organizationId}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Commission</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    disabled={display == 'Default' ? true : false}
                    type='number'
                    className='form-control form-control-border '
                    placeholder='Percentage'
                    {...formik.getFieldProps('commissionPercentage')}
                  />
                  {formik.touched.commissionPercentage && formik.errors.commissionPercentage && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.commissionPercentage}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Tax Number</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    disabled={display == 'Default' ? true : false}
                    className='form-control form-control-border '
                    placeholder='Tax Number'
                    {...formik.getFieldProps('taxNumber')}
                  />
                  {formik.touched.taxNumber && formik.errors.taxNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.taxNumber}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Upload Docs</span>
                </label>

                <div className='col-lg-8 flex-row d-flex px-6'>
                  <div className='d-flex flex-column w-100'>
                    {rowsData?.map((row: any, index: any) => (
                      <div className='row mb-6 d-flex flex-row' key={row.documentId}>
                        <input
                          value={row.fileName}
                          type='text'
                          disabled={true}
                          id='PartnerContractDocFiles'
                          accept='.pdf,.doc,.png,.jpeg,.jpg'
                          className='form-control form-control-border'
                          style={{width: '80%'}}
                          onChange={(event: any) => {
                            formik.setFieldValue('partnerContractDocFiles', [
                              ...formik.values.partnerContractDocFiles,
                              event.currentTarget.files[0],
                            ])
                          }}
                          onBlur={formik.handleBlur}
                        />

                        {formik.touched.partnerContractDocFiles && formik.errors.partnerContractDocFiles && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.partnerContractDocFiles}</div>
                      </div>
                    )}

                        {display != 'Default' && (
                          <button
                            type='button'
                            className='btn btn-primary w-auto mx-3'
                            onClick={() => deleteTableRows(row.documentId, index, 'text')}
                          >
                            x
                          </button>
                        )}
                      </div>
                    ))}

                    {newData?.map((row: any, index: any) => (
                      <div className='row mb-6 d-flex flex-row' key={row.documentId}>
                        <input
                          value={row.fileName}
                          multiple
                          type='file'
                          id='PartnerContractDocFiles'
                          accept='.pdf,.doc,.png,.jpeg,.jpg'
                          className='form-control form-control-border'
                          style={{width: '80%'}}
                          onChange={(event: any) => {
                            formik.setFieldValue('partnerContractDocFiles', [
                              ...formik.values.partnerContractDocFiles,
                              event.currentTarget.files,
                            ])
                          }}
                          onBlur={formik.handleBlur}
                        />

                        {formik.touched.partnerContractDocFiles && formik.errors.partnerContractDocFiles && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.partnerContractDocFiles}</div>
                      </div>
                    )}

                        {display != 'Default' && (
                          <button
                            type='button'
                            className='btn btn-primary w-auto mx-3'
                            //disabled={removeButton}
                            //disabled={ display == 'Default' ? true : false}
                            onClick={() => deleteTableRows(row.documentId, index, 'file')}
                          >
                            x
                          </button>
                        )}
                      </div>
                    ))}
                  </div>

                  {showAddButton && (
                    <div className='w-50 px-6 '>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={addTableRows}
                        style={{
                          display: `${display == 'Default' ? 'none' : ''}`,
                        }}
                      >
                        Add Document +
                      </button>
                    </div>
                  )}
                </div>
              </div> */}
            </div>

            <div
              className='d-flex justify-content-around  flex-column align-items-center flex-wrap px-10 '
              style={{borderLeft: '2px solid black'}}
            >
              <Tippy placement='top' content={display == 'Default' ? 'Edit Record' : 'Save Record'}>
                <button
                  type={'submit'}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  className='btn btn-icon btn-bg-light btn-active-color-primary '
                >
                  {loading && (
                    <span className='indicator-progress d-block'>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                  {display == 'Default' ? (
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  ) : (
                    <KTSVG path='/media/icons/duotune/art/update.svg' className='svg-icon-3' />
                  )}
                </button>
              </Tippy>

              <Tippy placement='top' content={display == 'Default' ? 'Delete Record' : 'Cancel'}>
                <button
                  type='button'
                  onClick={() => (display == 'Default' ? deleteHandler(data?.id) : cancelHandler())}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                  {display == 'Default' ? (
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  ) : (
                    <KTSVG path='/media/icons/duotune/general/x-lg.svg' className='svg-icon-3' />
                  )}
                </button>
              </Tippy>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default PartnerCommision
