import React from 'react'
import {Link, Outlet, useLocation} from 'react-router-dom'

const Main: React.FC = () => {
  const location = useLocation()
  return (
    <>
      <div className='card mb-5'>
        <div className='card-body pt-3' style={{padding: '0px 25px'}}>
          <div className='d-flex overflow-auto'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  //data-bs-toggle="tab"
                  className={`nav-link text-active-primary me-6 ${
                    location.pathname == '/payment/onlinePayment' ||
                    location.pathname === '/payment'
                      ? 'text-dark active-wrapper'
                      : 'text-active-primary'
                  } `}
                  to='/payment/onlinePayment'
                >
                  Payment
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  //data-bs-toggle="tab"
                  className={`nav-link text-active-primary me-6 ${
                    location.pathname == '/payment/adHOCPayment'
                      ? 'text-dark inactive-wrapper'
                      : 'text-active-primary '
                  } `}
                  to='/payment/adHOCPayment'
                >
                  Ad HOC Payment
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export {Main}
