import React, {useState, useEffect, FC} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import swal from 'sweetalert2'
import axios from 'axios'
import dayjs from 'dayjs'
import '../../../../css/configuration.css'
import 'tippy.js/dist/tippy.css'
import {followCursor} from 'tippy.js'
import 'react-rangeslider/lib/index.css'
import RangeSlider from 'react-rangeslider'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import moment from 'moment'
import Swal from 'sweetalert2'
import {AlertModal} from '../../../CommonFunctions/CommonFunction'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import Switch from 'react-switch'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {callConfigInfo, getTimeZone} from '../../../modules/auth/redux/AuthCRUD'
import RowWithSlider from '../../superAdminConfig/RowWithSlider'
import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import MouseCountColor from './MouseCountColor'
import Tippy from '@tippyjs/react'

const API = process.env.REACT_APP_API_URL

const numbers = /^[0-9]+$/

const configschema = Yup.object().shape({
  screenshot_Interval: Yup.number()
    .typeError('Please enter a numeric value only')
    .integer('Please enter a numeric value only')
    .required('Field is required'),

  sessionExpiryDay: Yup.string()
    .matches(numbers, 'Please enter a numeric value only')
    .required('Field is required')
    .max(4, 'Maximum 4 digits'),
  idle_Time_Interval: Yup.number()
    .typeError('Please enter a numeric value only')
    .integer('Please enter a numeric value only')
    .required('Field is required')
    .min(1, 'Minimum 1 minute')
    .max(100, 'Maximum 100 minute'),
  screenCaptureActivityInterval: Yup.number()
    .typeError('Please enter a numeric value only')
    .integer('Please enter a numeric value only')
    .required('Field is required')
    .min(1, 'Minimum 1 minute')
    .max(100, 'Maximum 100 minute'),
  idleTimeAlert: Yup.number()
    .typeError('Please enter a numeric value only')
    .integer('Please enter a numeric value only')
    .required('Field is required')
    .max(100, 'Maximum 100 minute'),
  suspiciousKeyboardAlert: Yup.number()
    .typeError('Please enter a numeric value only')
    .integer('Please enter a numeric value only')
    .required('Field is required')
    .max(100, 'Maximum 100 minute'),
  cutOffTime: Yup.string().required('Field is required'),

  noWorkingHours: Yup.string().required('Field is required'),
})

const Configration: FC = () => {
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState<any>(false)
  const [timeZone, setTimeZone] = useState<any[]>([])
  const [selectedTimeZone, setSelectedTimeZone] = useState<any>([])
  const [mouseCountButton, setMouseCountButton] = useState<any>('Setting')
  const [configInfo, setConfigInfo] = useState<any>([])

  const chechStatus = (value: any) => {
    value ? setStatus(true) : setStatus(false)
  }

  const initialValues = {
    org_Id: localStorage.getItem('org_Id'),
    screenshot_Interval: '',
    idle_Time_Interval: '',
    sessionExpiryDay: '',
    cutOffTime: '',
    screenCaptureActivityInterval: '',
    idleTimeAlert: '',
    suspiciousKeyboardAlert: '',
    created_Date: dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
    modifiedDate: dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
    modifiedBy: localStorage.getItem('name'),
    created_By: localStorage.getItem('name'),
    //projectTaskConfig: false,
    workingDays: [],
    noWorkingHours: '00',
    modifier_UserId: '',
    CaptureKeyboardMouseStroke: '',
    screenshotBlurEffect: '',
    isStartStopMailAlert: false,
    isSuspiciousKeyboardMailAlert: false,
    isIdleMailAlert: false,
    timeZoneId: '',
    recordingCaptureDurationMinutes: 0,
    recordingFramesDurationSeconds: 0,
    activeUserDurationMinutes: 0,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: configschema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      values.cutOffTime = values.cutOffTime.replaceAll(':', '.')

      //values.projectTaskConfig = toggle
      const userId = localStorage.getItem('userId')
      values.modifier_UserId = userId ? userId : ''
      console.log('the values are ', values)

      AlertModal(
        'Are you sure?',
        'It Will Change Configuration',
        'warning',
        true,
        '#3085d6',
        'Update'
      ).then((result: any) => {
        if (result.isConfirmed) {
          if (status) {
            axios
              .post(`${API}/OrganizationAppConfiguration/ConfigureInterval`, values)
              .then((res) => {
                AlertModal(
                  res.data.message,
                  '',
                  res.data.success ? 'success' : 'warning',
                  false,
                  '#7066E0',
                  'Ok'
                )

                callconfigdetail()
                chechStatus(false)
                setLoading(false)
              })
              .catch((err) => {
                console.log(err)
                setLoading(false)
              })
          } else {
            axios
              .post(`${API}/OrganizationAppConfiguration/UpdateIntervalConfiguration`, values)
              .then((res) => {
                AlertModal(
                  res.data.message,
                  '',
                  res.data.success ? 'success' : 'warning',
                  false,
                  '#7066E0',
                  'Ok'
                )

                callconfigdetail()
                setLoading(false)
                localStorage.setItem('orgCutOffTime', values.cutOffTime)
              })
              .catch((err) => {
                console.log(err)
                setLoading(false)
              })
          }
        } else if (result.dismiss === swal.DismissReason.cancel) {
          AlertModal('Nothing Has Been Changed', '', 'error', false, '#7066E0', 'Ok')

          callconfigdetail()
          setLoading(false)
        }
      })
    },
  })

  const callconfigdetail = () => {
    axios
      .get(`${API}/OrganizationAppConfiguration/GetOrganizationAppConfigById`, {
        params: {
          Org_ID: localStorage.getItem('org_Id'),
        },
      })
      .then((res) => {
        //setConfig(res.data)

        if (res.data.org_Id == null) {
          chechStatus(true)
        } else {
          formik.setFieldValue('screenshot_Interval', res.data.screenshot_Interval)
          formik.setFieldValue('idle_Time_Interval', res.data.idle_Time_Interval)
          formik.setFieldValue('sessionExpiryDay', res.data.sessionExpiryDay)
          formik.setFieldValue('cutOffTime', res.data.cutOffTime.replaceAll('.', ':'))
          formik.setFieldValue(
            'screenCaptureActivityInterval',
            res.data.screenCaptureActivityInterval
          )
          formik.setFieldValue('projectTaskConfig', res.data.projectTaskConfig)
          formik.setFieldValue('workingDays', res.data.workingDays)
          formik.setFieldValue('noWorkingHours', res.data.noWorkingHours)
          formik.setFieldValue('idleTimeAlert', res.data.idleTimeAlert)
          formik.setFieldValue('suspiciousKeyboardAlert', res.data.suspiciousKeyboardAlert)
          formik.setFieldValue('CaptureKeyboardMouseStroke', res.data.captureKeyboardMouseStroke)
          formik.setFieldValue('screenshotBlurEffect', res.data.screenshotBlurEffect)
          formik.setFieldValue('isIdleMailAlert', res.data.isIdleMailAlert)
          formik.setFieldValue('activeUserDurationMinutes', res.data.activeUserDurationMinutes)
          formik.setFieldValue(
            'isSuspiciousKeyboardMailAlert',
            res.data.isSuspiciousKeyboardMailAlert
          )
          formik.setFieldValue('isStartStopMailAlert', res.data.isStartStopMailAlert)
          formik.setFieldValue('timeZoneId', res.data.timeZoneId)
          formik.setFieldValue(
            'recordingCaptureDurationMinutes',
            res.data.recordingCaptureDurationMinutes
          )
          formik.setFieldValue(
            'recordingFramesDurationSeconds',
            res.data.recordingFramesDurationSeconds
          )

          setSelectedTimeZone({
            label: res.data.timeZoneName,
            value: res.data.timeZoneId,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    callconfigdetail()

    getTimeZone()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.timeZonesName,
            value: item.id,
          }
        })
        setTimeZone(newList)
      })
      .catch((err) => console.log(err))

    callConfigInfo().then((res) => {
      const obj: any = {}
      for (const item of res.data) {
        obj[item.infoKey] = item.infoValue
      }
      setConfigInfo(obj)
    })
  }, [])

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (Swal.isVisible() && !Swal.getPopup()?.contains(event.target)) {
        Swal.close()
        setLoading(false)
      }
    }

    document?.addEventListener('click', handleOutsideClick)

    return () => {
      document?.removeEventListener('click', handleOutsideClick)
    }
  })

  const changeFieldValueHandle = (name: string, value: any) => {
    formik.setFieldValue(name, String(value))
  }

  const handleCheckboxChange = (day: string) => {
    const updatedWorkingDays: string[] = [...formik.values.workingDays]
    const isChecked =
      formik.values?.workingDays?.length > 0 ? updatedWorkingDays.includes(day) : false
    if (isChecked) {
      const index = updatedWorkingDays.indexOf(day)
      updatedWorkingDays.splice(index, 1)
    } else {
      updatedWorkingDays.push(day)
    }

    formik.setFieldValue('workingDays', updatedWorkingDays)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 align-items-center'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Configuration</h3>
        </div>
        <div
          className=' d-flex justify-content-center align-items-center'
          style={{marginRight: '10px', height: '3.2rem'}}
        >
          <ToggleButtonGroup
            color='primary'
            value={mouseCountButton}
            exclusive
            onChange={(e: any) => {
              setMouseCountButton((prev: any) => (prev === e.target.value ? null : e.target.value))
            }}
            aria-label='Platform'
          >
            <ToggleButton value='Setting'>Setting</ToggleButton>
            <ToggleButton value='ActivityLevel'>Activity Level</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      {mouseCountButton === 'Setting' && (
        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Screenshot Interval (min)
                  {configInfo?.ScreenshotInterval && (
                    <Tippy
                      content={configInfo?.ScreenshotInterval}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>

                <div className='col-lg-8 fv-row'>
                  <RangeSlider
                    value={Number(formik.values.screenshot_Interval)}
                    onChange={(e: any) => formik.setFieldValue('screenshot_Interval', String(e))}
                    min={1}
                    max={100}
                    step={1}
                    labels={{
                      1: '1',
                      [formik.values.screenshot_Interval]: formik.values.screenshot_Interval,
                      100: '100',
                    }}
                  ></RangeSlider>

                  {formik.touched.screenshot_Interval && formik.errors.screenshot_Interval && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.screenshot_Interval}</div>
                    </div>
                  )}
                </div>
              </div>

              {ConfigDetails.isEnableScreenshotBlurEffect && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Screenshot Blur Effect
                    {/* <img className='info-icon' src="/media/icons/duotune/art/information.svg" alt="" /> */}
                    <Tippy
                      content={configInfo?.ScreenshotBlurEffect}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <RangeSlider
                      value={Number(formik.values.screenshotBlurEffect)}
                      onChange={(e: any) => formik.setFieldValue('screenshotBlurEffect', String(e))}
                      min={0}
                      max={3}
                      labels={{
                        0: '0',
                        [formik.values.screenshotBlurEffect]: formik.values.screenshotBlurEffect,
                        3: '3',
                      }}
                    ></RangeSlider>
                    {formik.touched.screenshotBlurEffect && formik.errors.screenshotBlurEffect && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.screenshotBlurEffect}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Capture Keyboard Mouse Stroke (min)
                  {configInfo?.CaptureKeyboardMouseStroke && (
                    <Tippy
                      content={configInfo?.CaptureKeyboardMouseStroke}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>

                <div className='col-lg-8 fv-row'>
                  {/* <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Screen Capture Activity Interval'
                    {...formik.getFieldProps('screenCaptureActivityInterval')}
                  /> */}
                  <RangeSlider
                    value={Number(formik.values.CaptureKeyboardMouseStroke)}
                    onChange={(e: any) =>
                      formik.setFieldValue('CaptureKeyboardMouseStroke', String(e))
                    }
                    min={10}
                    max={60}
                    step={5}
                    labels={{
                      10: '10',
                      [formik.values.CaptureKeyboardMouseStroke]:
                        formik.values.CaptureKeyboardMouseStroke,
                      60: '60',
                    }}
                  ></RangeSlider>
                  {formik.touched.CaptureKeyboardMouseStroke &&
                    formik.errors.CaptureKeyboardMouseStroke && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>
                          {formik.errors.CaptureKeyboardMouseStroke}
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Idle Time Interval (min)
                  {configInfo?.IdleTimeInterval && (
                    <Tippy
                      content={configInfo?.IdleTimeInterval}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>

                <div className='col-lg-8 fv-row'>
                  {/* <input
                    type='string'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Idle Time Interval'
                    {...formik.getFieldProps('idle_Time_Interval')}
                  /> */}
                  <RangeSlider
                    value={Number(formik.values.idle_Time_Interval)}
                    onChange={(e: any) => formik.setFieldValue('idle_Time_Interval', String(e))}
                    min={1}
                    max={100}
                    step={1}
                    labels={{
                      1: '1',
                      [formik.values.idle_Time_Interval]: formik.values.idle_Time_Interval,
                      100: '100',
                    }}
                  ></RangeSlider>
                  {formik.touched.idle_Time_Interval && formik.errors.idle_Time_Interval && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.idle_Time_Interval}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Idle Time Alert (min)
                  {configInfo?.IdleTimeAlert && (
                    <Tippy
                      content={configInfo?.IdleTimeAlert}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>

                <div className='col-lg-8 fv-row'>
                  <RangeSlider
                    value={Number(formik.values.idleTimeAlert)}
                    onChange={(e: any) => formik.setFieldValue('idleTimeAlert', e)}
                    min={0}
                    max={100}
                    labels={{
                      0: '0',
                      [formik.values.idleTimeAlert]: formik.values.idleTimeAlert,
                      100: '100',
                    }}
                  ></RangeSlider>
                  {formik.touched.idleTimeAlert && formik.errors.idleTimeAlert && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.idleTimeAlert}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Suspicious Keyboard Alert (min)
                  {configInfo?.SuspiciousKeyboardAlert && (
                    <Tippy
                      content={configInfo?.SuspiciousKeyboardAlert}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>

                <div className='col-lg-8 fv-row'>
                  <RangeSlider
                    value={Number(formik.values.suspiciousKeyboardAlert)}
                    onChange={(e: any) => formik.setFieldValue('suspiciousKeyboardAlert', e)}
                    min={0}
                    max={100}
                    labels={{
                      0: '0',
                      [formik.values.suspiciousKeyboardAlert]:
                        formik.values.suspiciousKeyboardAlert,
                      100: '100',
                    }}
                  ></RangeSlider>
                  {formik.touched.suspiciousKeyboardAlert && formik.errors.suspiciousKeyboardAlert && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>
                        {formik.errors.suspiciousKeyboardAlert}
                      </div>
                    </div>
                  )}
                </div>
              </div> */}

              <RowWithSlider
                tippy={configInfo?.ActiveUserDuration}
                fieldName='activeUserDurationMinutes'
                title='Active User Duration (min)'
                value={formik.values.activeUserDurationMinutes}
                updateValue={changeFieldValueHandle}
                min={10}
                max={60}
              />

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Session Expiry (Days)
                  {configInfo?.SessionExpiry && (
                    <Tippy
                      content={configInfo?.SessionExpiry}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>

                <div className='col-lg-8 fv-row'>
                  <RangeSlider
                    value={Number(formik.values.sessionExpiryDay)}
                    onChange={(e: any) => formik.setFieldValue('sessionExpiryDay', e)}
                    min={1}
                    max={100}
                    step={1}
                    labels={{
                      1: '1',
                      [formik.values.sessionExpiryDay]: formik.values.sessionExpiryDay,
                      100: '100',
                    }}
                  ></RangeSlider>
                  {formik.touched.sessionExpiryDay && formik.errors.sessionExpiryDay && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.sessionExpiryDay}</div>
                    </div>
                  )}
                </div>
              </div>

              <RowWithSlider
                tippy={configInfo?.RecordingCaptureDuration}
                fieldName='recordingCaptureDurationMinutes'
                title='Recording Capture Duration (min)'
                value={formik.values.recordingCaptureDurationMinutes}
                updateValue={changeFieldValueHandle}
                min={5}
                max={120}
              />

              <RowWithSlider
                tippy={configInfo?.RecordingFramesDuration}
                fieldName='recordingFramesDurationSeconds'
                title='Recording Frames Duration (sec)'
                value={formik.values.recordingFramesDurationSeconds}
                updateValue={changeFieldValueHandle}
                min={1}
                max={5}
              />

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Cut Off Time (hh:mm:ss)
                  {configInfo?.CutOffTime && (
                    <Tippy
                      content={configInfo?.CutOffTime}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>

                <div className='col-lg-8 fv-row'>
                  <TimePicker
                    allowEmpty={false}
                    onChange={(Time: any) =>
                      formik.setFieldValue('cutOffTime', dayjs(Time).format('HH:mm:ss'))
                    }
                    value={
                      formik.values.cutOffTime
                        ? moment(formik.values.cutOffTime, 'HH:mm:ss')
                        : undefined
                    }
                    placeholder='Select a cut off time'
                  />
                  {formik.touched.cutOffTime && formik.errors.cutOffTime && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.cutOffTime}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Time Zone
                  {configInfo?.TimeZone && (
                    <Tippy
                      content={configInfo?.TimeZone}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>

                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Role'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={timeZone}
                      value={selectedTimeZone}
                      onBlur={formik.handleBlur}
                      placeholder='Select Time Zone'
                      onChange={(item: any) => {
                        setSelectedTimeZone(item)
                        formik.setFieldValue('timeZoneId', item.value)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  <div>
                    {formik.touched.timeZoneId && formik.errors.timeZoneId && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.timeZoneId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Attendance Hours Per Day(hh:mm:ss)
                  {configInfo?.AttendanceHoursPerDay && (
                    <Tippy
                      content={configInfo?.AttendanceHoursPerDay}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>

                <div className='col-lg-8 fv-row'>
                  <TimePicker
                    allowEmpty={false}
                    onChange={(Time: any) =>
                      formik.setFieldValue('noWorkingHours', dayjs(Time).format('HH:mm:ss'))
                    }
                    value={
                      formik.values.noWorkingHours
                        ? moment(formik.values.noWorkingHours, 'HH:mm:ss')
                        : undefined
                    }
                    placeholder='Select time'
                  />
                  {formik.touched.noWorkingHours && formik.errors.noWorkingHours && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.noWorkingHours}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Working Days Per Week(Days)
                </label>

                <div className='col-lg-8 fv-row'>
                  <div
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                  >
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Mon'
                        id='flexCheckDefault'
                        checked={formik.values.workingDays.some((day) => day === 'Mon')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Mon</label>
                    </div>

                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Tue'
                        id='flexCheckChecked'
                        checked={formik.values.workingDays.some((day) => day === 'Tue')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Tue</label>
                    </div>

                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Wed'
                        id='kt_check_indeterminate_1'
                        checked={formik.values.workingDays.some((day) => day === 'Wed')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Wed</label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Thu'
                        id='kt_check_indeterminate_1'
                        checked={formik.values.workingDays.some((day) => day === 'Thu')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Thu</label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Fri'
                        id='kt_check_indeterminate_1'
                        checked={formik.values.workingDays.some((day) => day === 'Fri')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Fri</label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Sat'
                        id='kt_check_indeterminate_1'
                        checked={formik.values.workingDays.some((day) => day === 'Sat')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Sat</label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Sun'
                        id='kt_check_indeterminate_1'
                        checked={formik.values.workingDays.some((day) => day === 'Sun')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Sun</label>
                    </div>
                  </div>
                  {formik.touched.workingDays && formik.errors.workingDays && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.workingDays}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Idle Mail Alert
                  {
                    configInfo?.IdleMailAlert && (
                      <Tippy
                        content={configInfo?.IdleMailAlert}
                        followCursor={true}
                        plugins={[followCursor]}>
                        <span>
                          <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                        </span>
                      </Tippy>
                    )
                  }
                </label>
                <div className='col-lg-8 fv-row d-flex align-items-center'>
                  <Switch
                    onChange={(e) => {
                      formik.setFieldValue('isIdleMailAlert', e)
                    }}
                    checked={formik.values.isIdleMailAlert}
                  />
                </div>
              </div> */}

              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Suspicious Keyboard Mail Alert
                  {configInfo?.SuspiciousKeyboardMailAlert && (
                    <Tippy
                      content={configInfo?.SuspiciousKeyboardMailAlert}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>
                <div className='col-lg-8 fv-row d-flex align-items-center'>
                  <Switch
                    onChange={(e) => {
                      formik.setFieldValue('isSuspiciousKeyboardMailAlert', e)
                    }}
                    checked={formik.values.isSuspiciousKeyboardMailAlert}
                  />
                </div>
              </div> */}

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Start Stop Mail Alert
                  {configInfo?.StartStopMailAlert && (
                    <Tippy
                      content={configInfo?.StartStopMailAlert}
                      followCursor={true}
                      plugins={[followCursor]}
                    >
                      <span>
                        <img src='/media/svg/general/circleInfoMark.svg' className='mx-3 mb-3' />
                      </span>
                    </Tippy>
                  )}
                </label>
                <div className='col-lg-8 fv-row d-flex align-items-center'>
                  <Switch
                    onChange={(e) => {
                      formik.setFieldValue('isStartStopMailAlert', e)
                    }}
                    checked={formik.values.isStartStopMailAlert}
                  />
                </div>
              </div>

              <style>
                {`
                          .rc-time-picker-input{
                            height:3rem;
                            font-weight:700;
                            // width:59.3rem;
                          }
  
                          .rc-time-picker-panel-input{
                            font-weight:700
                          }
  
                      `}
              </style>

              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Project Task Config</label>
                <div className='col-lg-8 fv-row' style={{ display: 'flex', alignItems: 'center' }}>
                  <Switch
                    onChange={() => {
                      handleToggle()
                      checked ? setToggle(false) : setToggle(true)
                    }}
                    checked={checked}
                  />
                </div>
              </div> */}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {/* <button className='btn btn-secondary' onClick={() => navigate(status())}>
                              Cancel
                          </button>
  
                          &nbsp; &nbsp; &nbsp; &nbsp; */}
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && (
                  <span className='indicator-label'>{status ? 'Create' : 'Update'}</span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}

      {mouseCountButton === 'ActivityLevel' && (
        <div className='collapse show'>
          <MouseCountColor />
        </div>
      )}
    </div>
  )
}

export default Configration
