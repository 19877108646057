/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'

import axios from 'axios'
import dayjs from 'dayjs'

import {Loader} from '../../../../app/CommonFunctions/CommonFunction'

import '../../../../css/ProVsIdlePopUp.css'
import Lightbox from 'react-image-lightbox'
import {memberInfoByOrg} from '../../../../app/modules/auth/redux/AuthCRUD'

interface PopupProps {
  onClose: () => void
  barDate: string
  selectedUser: string
  series: string
  startDate: string
  endDate: string
}

const API_URL = process.env.REACT_APP_API_URL

const ScreenshotsPopup: FC<PopupProps> = ({
  onClose,
  barDate,
  selectedUser,
  series,
  startDate,
  endDate,
}) => {
  const [updatedImages, setUpdatedImages] = useState<any>([])
  const [imgIndex, setImgIndex] = useState(0)
  const [startIndex, setStartIndex] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [spinner, setSpinner] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  const handleIconClick = () => {
    onClose()
  }

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
  }

  useEffect(() => {
    const fetchScreenShots = async () => {
      setSpinner(true)
      memberInfoByOrg()
        .then((res) => {
          const emailArray = res.data
            .filter((item: any) => item.userId === selectedUser)
            .map((matchingItem: any) => matchingItem.email)

          axios
            .get(`${API_URL}/CloudStorageScreenshots/GetMinutelyScreenShots`, {
              params: {
                OrganizationId: localStorage.getItem('org_Id'),
                Email: emailArray[0],
                ReportFromDate: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss'),

                ReportToDate: dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss'),
              },
            })
            .then((response) => {
              setUpdatedImages(response.data)
              setSpinner(false)
            })
            .catch((err) => {
              console.log(err)
              setSpinner(false)
            })
        })
        .catch((err) => {
          console.log(err)
          setSpinner(false)
        })
    }
    fetchScreenShots()
  }, [selectedUser, startDate, endDate])

  return (
    <div className='modal-container d-flex'>
      <div style={{margin: 'auto', paddingLeft: '200px'}}>
        <div
          className='card mt-3 screenshot-modal-innerContainer'
          style={{padding: '0rem 2.5rem 2rem 2.5rem'}}
        >
          <div className='card mt-5' style={{height: '28rem'}}>
            <div
              className='card-header card-header-stretch border-bottom border-gray-200'
              style={{padding: 'unset'}}
            >
              <span style={{paddingTop: '1.5rem', fontSize: '2rem', fontWeight: '600'}}>
                {dayjs(startDate).format('HH:mm')} - {dayjs(endDate).format('HH:mm')}
              </span>
              <i
                className='fa fa-times'
                onClick={() => handleIconClick()}
                aria-hidden='true'
                style={{
                  fontSize: '2rem',
                  marginLeft: '22rem',
                  paddingTop: '1.5rem',
                  cursor: 'pointer',
                }}
              ></i>
            </div>
            <div className='image-container'>
              {spinner ? (
                Loader('50px')
              ) : (
                <>
                  {updatedImages.length > 0 ? (
                    <div className='d-flex space-between ' id='screenshot-container'>
                      {updatedImages.map((image: any, index: any) => (
                        <div
                          className='d-flex'
                          key={index}
                          style={{margin: '0.5rem', flexFlow: 'column'}}
                        >
                          <img
                            src={image.original}
                            style={{width: '10rem', cursor: 'pointer'}}
                            onClick={() => {
                              toggleModal()
                              setImgIndex(index + startIndex)
                            }}
                            onLoad={() => {
                              setImageLoaded(true)
                            }}
                          />
                          {imageLoaded && (
                            <span style={{textAlign: 'center'}}>
                              {image.description.split('.').join(':')}
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h2
                      className='pt-20 user-select-none'
                      style={{
                        fontSize: '1.6rem',
                        color: '#cccccc',
                        fontWeight: '600',
                        textAlign: 'center',
                        display: 'block',
                      }}
                    >
                      No Screenshots Found
                    </h2>
                  )}
                </>
              )}

              {modalIsOpen && (
                <Lightbox
                  imageTitle={''}
                  imageCaption={updatedImages[imgIndex].description.split('.').join(':')}
                  mainSrc={updatedImages[imgIndex].original}
                  nextSrc={`${updatedImages[(imgIndex + 1) % updatedImages.length]}`}
                  prevSrc={
                    updatedImages[(imgIndex + updatedImages.length - 1) % updatedImages.length]
                  }
                  onCloseRequest={() => toggleModal()}
                  onMovePrevRequest={() =>
                    setImgIndex((imgIndex + updatedImages.length - 1) % updatedImages.length)
                  }
                  onMoveNextRequest={() => setImgIndex((imgIndex + 1) % updatedImages.length)}
                  animationDisabled={false}
                  imagePadding={60}
                  wrapperClassName='custom-lightbox-wrapper'
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <style>{`
          .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
            animation: fadeIn 0.5s ease;
            
          }

          #div-scroller::-webkit-scrollbar-thumb {
            background-color: #C1C1C1 !important;
          }
          #div-scroller::-webkit-scrollbar {
            width:0.5rem
          }



          .screenshot-modal-innerContainer {
            
            margin: 0 auto;
            background-color: #fff;
            border-radius: 6px;
            padding: 20px;
            position: relative;
            width:65rem;
            height:30rem
          }

        .custom-modal-header{
            padding-right: 29rem;
            font-size: 1.8rem;
            font-weight: 700;
        }

        .paginationBttns{
            width:23rem;
            margin: 2rem auto 0 auto;
        }

        .temp-class{
            padding: 0.5rem;
            width: 8rem;
            margin: 0;
            justify-content:center;
            margin:unset
        }

        #screenshot-container{
            flex-wrap: wrap !important;
        }


        `}</style>
    </div>
  )
}

export {ScreenshotsPopup}
