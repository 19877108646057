import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {AlertModal} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function Passwords() {
  const [loading, setLoading] = useState(false)

  const initialValues = {
    email: localStorage.getItem('Email'),
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const memberSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters long.')
      .max(16, 'The password should not exceed 16 characters.')
      .matches(/[0-9]/, 'Password must contain at least one number.')
      .matches(/[a-z]/, 'Password requires at least one lowercase letter.')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
      .matches(/[^\w]/, 'Password must include at least one symbol.')
      .required('Password is required'),
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters long.')
      .max(16, 'The password should not exceed 16 characters.')
      .matches(/[0-9]/, 'Password must contain at least one number.')
      .matches(/[a-z]/, 'Password requires at least one lowercase letter.')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
      .matches(/[^\w]/, 'Password must include at least one symbol.')
      .required('New Password is required'),
      confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Must match "Password" field value')
      .required('Password is required'),
    // oldPassword: Yup.string()
    //   .when([], {
    //     is: (oldPassword: any, password: any) => oldPassword == password,
    //     then: Yup.string().oneOf([Yup.ref('password')], "dont"),
    //     otherwise: Yup.string().required('otherwise chla')
    //   }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: memberSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setTimeout(() => {
        if (values.oldPassword == values.newPassword) {
          AlertModal('Old And New Password Are Same', '', 'warning', false, '#7066E0', 'Ok')

          setLoading(false)
        } else {
          axios
            .post(`${API_URL}/Login/ChangePassword`, values)
            .then((res) => {
              AlertModal(
                res.data.message,
                '',
                res.data.success ? 'success' : 'warning',
                false,
                '#7066E0',
                'Ok'
              )

              resetForm()
              setLoading(false)
            })
            .catch((err) => {
              console.log(err)
              setLoading(false)
              setSubmitting(false)

              setStatus('Registration process has broken')
            })
        }
      }, 700)
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 '
        // role='button'
        // data-bs-toggle='collapse'
        // data-bs-target='#kt_account_profile_details'
        // aria-expanded='false'
        // aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Change Passwords</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          className='form'
          encType='multipart/form-data'
        >
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Old Password</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Old Password'
                  {...formik.getFieldProps('oldPassword')}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.oldPassword}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>New Password</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='New Password'
                  {...formik.getFieldProps('newPassword')}
                />
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.newPassword}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Confirm Password</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Confirm Password'
                  {...formik.getFieldProps('confirmPassword')}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.confirmPassword}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && <span className='indicator-label'>Change Password</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
