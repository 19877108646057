import React from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import * as auth from '../../../app/modules/auth/redux/AuthRedux'

const TrialEnds: React.FC = () => {
  const dispatch = useDispatch()
  const logout = () => {
    localStorage.clear()
    dispatch(auth.actions.logout())
  }
  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`}}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/mera_monitor_logo_bgWhite.svg')}
            className='h-100px mb-5 d-flex mx-auto'
          />
          <h2 className='fs-2x fw-bold mb-3'>Your plan has been suspended !</h2>
          <p className='text-gray-500 fs-4 fw-semibold'>
            Once you choose a plan and make the necessary payment, you will be able to see your
            data.
          </p>
          <div className='pt-lg-10 mb-3'>
            <div className='text-center'>
              <Link to='/myPlan' className='btn btn-lg btn-success fw-bolder'>
                Go to Payment
              </Link>
            </div>
          </div>
          <span className=' fw-bolder fs-5'>OR</span>
          <div className='pt-lg-4 mb-3'>
            <div className='text-center'>
              <div className='btn btn-lg btn-danger fw-bolder' onClick={logout}>
                Logout
              </div>
            </div>
          </div>
          <div
            className=' d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default TrialEnds
