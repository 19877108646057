import React from 'react'
import {Link, Outlet, useLocation} from 'react-router-dom'

const Main: React.FC = () => {
  const location = useLocation()
  return (
    <>
      <div className='card mb-5'>
        <div className='card-body pt-3' style={{padding: '0px 25px'}}>
          <div className='d-flex overflow-auto'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  //data-bs-toggle="tab"
                  className={`nav-link text-active-primary me-6 ${
                    location.pathname == '/task/create' || location.pathname === '/task'
                      ? 'text-dark active-wrapper'
                      : 'text-active-primary'
                  } `}
                  to='/task/create'
                >
                  Creation
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  //data-bs-toggle="tab"
                  className={`nav-link text-active-primary me-6 ${
                    location.pathname == '/task/allocate'
                      ? 'text-dark inactive-wrapper'
                      : 'text-active-primary '
                  } `}
                  to='/task/allocate'
                >
                  Allocation
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  //data-bs-toggle="tab"
                  className={`nav-link text-active-primary me-6 ${
                    location.pathname == '/task/bukUploadTask'
                      ? 'text-dark inactive-wrapper'
                      : 'text-active-primary '
                  } `}
                  to='/task/bukUploadTask'
                >
                  Bulk Upload
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export {Main}
