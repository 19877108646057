import React, {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'

import axios from 'axios'
import dayjs from 'dayjs'
import moment from 'moment'
import {
  Loader,
  Pagination,
  renderTableHeader,
  AlertModal,
} from '../../../../app/CommonFunctions/CommonFunction'
import {KeyBoardMouseStroke} from '../../../../app/pages/Web&App/KeyMouseChart'
import '../../../../css/ProVsIdlePopUp.css'
import {AddProductive, AddUnProductive} from '../../../../app/services/ProVsIdlePopUp.services'

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

interface PopupProps {
  onClose: (hasAnyChange: any) => void
  barDate: string
  selectedUser: string
  series: string
}

const API_URL = process.env.REACT_APP_API_URL

const Popup: FC<PopupProps> = ({onClose, barDate, selectedUser, series}) => {
  const [idle, setIdle] = useState<any[]>([])
  const [productive, setProductive] = useState<any[]>([])
  const [unproductive, setUnproductive] = useState<any[]>([])

  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [hasChange, setHasChange] = useState(false)

  const [activeButton, setActiveButton] = useState(series)

  const role = localStorage.getItem('role')

  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = async () => {
    setLoading(true)
    const body = {
      organizationId: localStorage.getItem('org_Id'),
      userId: selectedUser,
      fromDate: dayjs(barDate).format('YYYY-MM-DDT00:00:00[Z]'),
    }

    try {
      const ProVsIdleRes = await axios.post(`${API_URL}/ActivityTracker/GetUserActivities`, body)

      setIdle(ProVsIdleRes.data.idleAppAndWebResponses)
      setProductive(ProVsIdleRes.data.productiveAppAndWebResponses)
      switch (activeButton) {
        case 'productive':
          setItems(ProVsIdleRes.data.productiveAppAndWebResponses)
          break
        case 'unproductive':
          setItems(ProVsIdleRes.data.unProductiveAppAndWebResponses)
          break
        case 'idle':
          setItems(ProVsIdleRes.data.idleAppAndWebResponses)
          break
        default:
          setItems([])
          break
      }
      // setItems(ProVsIdleRes.data.productiveAppAndWebResponses)
      setUnproductive(ProVsIdleRes.data.unProductiveAppAndWebResponses)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handleIconClick = () => {
    onClose(hasChange)
  }

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState<any>()

  const endOffset = itemOffset + itemsPerPage

  const currentItemss = items.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(items.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length

    setItemOffset(newOffset)
  }

  const convertSecIntoHoursMinSec = (totalSeconds: any) => {
    const duration = moment.duration(totalSeconds, 'seconds')
    const hours = Math.floor(duration.asHours()).toString().padStart(2, '0')
    return moment.utc(totalSeconds * 1000).format(hours + ':mm:ss')
  }

  const handleTabChange = (tab: string) => {
    setActiveButton(tab)
    switch (tab) {
      case 'productive':
        setItems(productive)

        break
      case 'unproductive':
        setItems(unproductive)

        break
      case 'idle':
        setItems(idle)

        break
      default:
        setItems([])
        break
    }
  }

  const makeProductive = async (webapp: any) => {
    if (role === process.env.REACT_APP_SYSTEM_FIRST_ROLE) {
      try {
        setLoading(true)
        const changeCategory = await AddProductive(selectedUser, webapp)

        handleResponse(changeCategory)
      } catch (error) {
        setLoading(false)
      }
    }
  }
  const makeUnproductive = async (webapp: any) => {
    if (role === process.env.REACT_APP_SYSTEM_FIRST_ROLE) {
      try {
        setLoading(true)
        const changeCategory = await AddUnProductive(selectedUser, webapp)

        handleResponse(changeCategory)
      } catch (error) {
        setLoading(false)
      }
    }
  }

  const handleResponse = (changeCategory: any) => {
    setHasChange(true)
    AlertModal(
      changeCategory.data.message,
      '',
      changeCategory.data.success ? 'success' : 'warning',
      false,
      '#7066E0',
      'Ok'
    ).then(() => {
      getUserData()
      setLoading(true)
    })
  }

  return (
    <div className='modal-container d-flex'>
      <div style={{margin: 'auto', paddingLeft: '200px'}}>
        <div
          className='card mt-3 modal-innerContainer'
          style={{padding: '0rem 2.5rem 2rem 2.5rem'}}
        >
          <div className='card mt-5'>
            <div
              className='card-header card-header-stretch border-bottom border-gray-200'
              style={{padding: 'unset'}}
            >
              <div className='card-title'>
                {/* <h3 className="fw-bold m-0">Billing History</h3> */}
                <ul className='nav nav-stretch nav-line-tabs border-transparent' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <a
                      id='kt_billing_6months_tab'
                      className={`nav-link fs-5 fw-semibold me-3 ${
                        series === 'productive' ? 'active' : ''
                      } temp-class`}
                      data-bs-toggle='tab'
                      role='tab'
                      href='#productive'
                      aria-selected={series === 'productive'}
                      onClick={() => {
                        setForcePage(null)
                        handleTabChange('productive')
                        setItemOffset(0)
                        setTimeout(() => {
                          setForcePage(0)
                        }, 50)
                      }}
                      style={
                        activeButton === 'productive'
                          ? {backgroundColor: '#E1F0FF', color: 'black'}
                          : {}
                      }
                    >
                      Productive
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      id='kt_billing_1year_tab'
                      className={`nav-link fs-5 fw-semibold me-3 temp-class ${
                        series === 'unproductive' ? 'active' : ''
                      }`}
                      data-bs-toggle='tab'
                      role='tab'
                      href='#unproductive'
                      aria-selected={series === 'unproductive'}
                      tabIndex={-1}
                      onClick={() => {
                        setForcePage(null)
                        handleTabChange('unproductive')
                        setItemOffset(0)
                        setTimeout(() => {
                          setForcePage(0)
                        }, 50)
                      }}
                      style={
                        activeButton === 'unproductive'
                          ? {backgroundColor: '#E1F0FF', color: 'black'}
                          : {}
                      }
                    >
                      Unproductive
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      id='kt_billing_alltime_tab'
                      className={`nav-link fs-5 fw-semibold temp-class ${
                        series === 'idle' ? 'active' : ''
                      }`}
                      data-bs-toggle='tab'
                      role='tab'
                      href='#idle'
                      aria-selected={series === 'idle'}
                      tabIndex={-1}
                      onClick={() => {
                        setForcePage(null)
                        handleTabChange('idle')
                        setItemOffset(0)
                        setTimeout(() => {
                          setForcePage(0)
                        }, 50)
                      }}
                      style={
                        activeButton === 'idle' ? {backgroundColor: '#E1F0FF', color: 'black'} : {}
                      }
                    >
                      Idle
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      id='kt_billing_alltime_tab'
                      className={`nav-link fs-5 fw-semibold temp-class`}
                      data-bs-toggle='tab'
                      role='tab'
                      href='#keymouse'
                      aria-selected={series === 'keymouse'}
                      tabIndex={-1}
                      onClick={() => {
                        setForcePage(null)
                        handleTabChange('keymouse')
                        setItemOffset(0)
                        setTimeout(() => {
                          setForcePage(0)
                        }, 50)
                      }}
                      style={
                        activeButton === 'keymouse'
                          ? {
                              backgroundColor: '#E1F0FF',
                              color: 'black',
                              width: 'unset',
                              padding: '1rem',
                            }
                          : {width: 'unset'}
                      }
                    >
                      Keyboard & Mouse Stroke
                    </a>
                  </li>
                </ul>
              </div>
              <i
                className='fa fa-times'
                onClick={() => handleIconClick()}
                aria-hidden='true'
                style={{
                  fontSize: '2rem',
                  marginLeft: '22rem',
                  paddingTop: '1.5rem',
                  cursor: 'pointer',
                }}
              ></i>
            </div>

            <div className='tab-content'>
              <div
                id='productive'
                className={`card-body p-0 tab-pane fade ${
                  activeButton === 'productive' ? 'active show' : ''
                }`}
                role='tabpanel'
                aria-labelledby='productive'
              >
                <div
                  style={{
                    background: 'rgb(114, 143, 206)',
                    border: 'solid 1px',
                  }}
                >
                  {renderTableHeader()}
                </div>

                <div
                  id='div-scroller'
                  className='table-responsive'
                  style={{
                    height: '23rem',
                    borderBottom: 'solid 1px',
                    borderRight: 'solid 1px',
                    borderLeft: 'solid 1px',
                  }}
                >
                  {loading ? (
                    Loader('100px')
                  ) : (
                    <table className='table align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <tbody>
                        {currentItemss.map((data: any, index) => (
                          <tr key={index} style={{fontSize: '12px'}}>
                            <td style={{width: '230px'}}></td>
                            <td style={{width: '360px', lineBreak: 'anywhere'}}>
                              <div className='d-flex webapp'>
                                <span className='text-dark  d-flex mb-1 fs-6 webapp-title'>
                                  {data.applicationOrWebsite}{' '}
                                </span>
                                {role === process.env.REACT_APP_SYSTEM_FIRST_ROLE && (
                                  <Tippy placement='top' content='Move to Unproductive'>
                                    <img
                                      onClick={() => makeUnproductive(data.applicationOrWebsite)}
                                      className='webapp-image'
                                      src={toAbsoluteUrl(`/media/icons/duotune/art/transfer.png`)}
                                      style={{
                                        height: '1.3rem',
                                        width: '1.3rem',
                                        marginLeft: '0.5rem',
                                      }}
                                    ></img>
                                  </Tippy>
                                )}
                              </div>
                            </td>
                            <td>
                              <span
                                className='text-dark  d-flex mb-1 fs-6'
                                style={{paddingLeft: '1.8rem'}}
                              >
                                {convertSecIntoHoursMinSec(data.processTotalTimeSeconds)}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  )}
                </div>
                {!loading &&
                  currentItemss.length > 0 &&
                  Pagination(handlePageClick, pageCount, forcePage)}
              </div>

              <div
                id='unproductive'
                className={`card-body p-0 tab-pane fade ${
                  activeButton === 'unproductive' ? 'active show' : ''
                }`}
                role='tabpanel'
                aria-labelledby='unproductive'
              >
                <div
                  style={{
                    background: 'rgb(114, 143, 206)',
                    border: 'solid 1px',
                  }}
                >
                  {renderTableHeader()}
                </div>

                <div
                  id='div-scroller'
                  className='table-responsive'
                  style={{
                    height: '23rem',
                    borderBottom: 'solid 1px',
                    borderRight: 'solid 1px',
                    borderLeft: 'solid 1px',
                  }}
                >
                  {loading ? (
                    Loader('100px')
                  ) : (
                    <table className='table align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <tbody>
                        {currentItemss.map((data: any, index: any) => (
                          <tr key={index} style={{fontSize: '12px'}}>
                            <td style={{width: '230px'}}></td>
                            <td style={{width: '360px', lineBreak: 'anywhere'}}>
                              <div className='d-flex webapp'>
                                <span className='text-dark  d-flex mb-1 fs-6'>
                                  {data.applicationOrWebsite}
                                </span>
                                {role === process.env.REACT_APP_SYSTEM_FIRST_ROLE && (
                                  <Tippy placement='top' content='Move to Productive'>
                                    <img
                                      onClick={() => makeProductive(data.applicationOrWebsite)}
                                      className='webapp-image'
                                      src={toAbsoluteUrl(`/media/icons/duotune/art/transfer.png`)}
                                      style={{
                                        height: '1.3rem',
                                        width: '1.3rem',
                                        marginLeft: '0.5rem',
                                      }}
                                    ></img>
                                  </Tippy>
                                )}
                              </div>
                            </td>
                            <td>
                              <span
                                className='text-dark  d-flex mb-1 fs-6'
                                style={{paddingLeft: '1.8rem'}}
                              >
                                {convertSecIntoHoursMinSec(data.processTotalTimeSeconds)}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  )}
                </div>
                {!loading &&
                  currentItemss.length > 0 &&
                  Pagination(handlePageClick, pageCount, forcePage)}
              </div>

              <div
                id='idle'
                className={`card-body p-0 tab-pane fade ${
                  activeButton === 'idle' ? 'active show' : ''
                }`}
                role='tabpanel'
                aria-labelledby='idle'
              >
                <div
                  style={{
                    background: 'rgb(114, 143, 206)',
                    border: 'solid 1px',
                  }}
                >
                  <table style={{width: '100%', height: '3.8rem'}}>
                    <thead>
                      <tr
                        className='fw-bolder'
                        style={{background: 'rgb(114, 143, 206)', fontSize: '15px'}}
                      >
                        <th
                          className=''
                          style={{width: '30%', textAlign: 'left', paddingLeft: '1.8rem'}}
                        >
                          Apps &amp; websites
                        </th>
                        <th
                          className=''
                          style={{width: '17%', textAlign: 'left', paddingLeft: '2.8rem'}}
                        >
                          Date
                        </th>
                        <th
                          className=''
                          style={{width: '17%', textAlign: 'left', paddingLeft: '1rem'}}
                        >
                          Idle Start
                        </th>
                        <th
                          className=''
                          style={{width: '17%', textAlign: 'left', paddingLeft: '2rem'}}
                        >
                          Idle End
                        </th>
                        <th
                          className=''
                          style={{textAlign: 'left', paddingLeft: '1.8rem', width: '17%'}}
                        >
                          Idle time
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div
                  id='div-scroller'
                  className='table-responsive'
                  style={{
                    height: '23rem',
                    borderBottom: 'solid 1px',
                    borderRight: 'solid 1px',
                    borderLeft: 'solid 1px',
                  }}
                >
                  {loading ? (
                    Loader('100px')
                  ) : (
                    <table className='table align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <tbody>
                        {currentItemss.map((data: any, index) => (
                          <tr key={index} style={{fontSize: '12px'}}>
                            <td
                              style={{width: '30%', paddingLeft: '1.8rem', lineBreak: 'anywhere'}}
                            >
                              <span className='text-dark  d-flex mb-1 fs-6'>
                                {data.applicationOrWebsite}
                              </span>
                            </td>
                            <td style={{width: '17%', paddingLeft: '2.8rem'}}>
                              <span className='text-dark  d-flex mb-1 fs-6'>
                                {dayjs(data.idleStart).format('DD/MM/YYYY')}
                              </span>
                            </td>
                            <td style={{width: '17%', paddingLeft: '1rem'}}>
                              <span className='text-dark  d-flex mb-1 fs-6'>
                                {dayjs(data.idleStart).format('HH:mm:ss')}
                              </span>
                            </td>
                            <td style={{width: '17%', paddingLeft: '2rem'}}>
                              <span className='text-dark  d-flex mb-1 fs-6'>
                                {dayjs(data.idleEnd).format('HH:mm:ss')}
                              </span>
                            </td>
                            <td style={{width: '17%', paddingLeft: '1.8rem'}}>
                              <span className='text-dark  d-flex mb-1 fs-6'>
                                {convertSecIntoHoursMinSec(data.idleTime)}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  )}
                </div>
                {!loading &&
                  currentItemss.length > 0 &&
                  Pagination(handlePageClick, pageCount, forcePage)}
              </div>
              <div
                id='keymouse'
                className={`card-body p-0 tab-pane fade ${
                  activeButton === 'keymouse' ? 'active show' : ''
                }`}
                role='tabpanel'
                aria-labelledby='keymouse'
              >
                <div
                  style={{
                    background: 'rgb(114, 143, 206)',
                    border: 'solid 1px',
                  }}
                >
                  <div className='key-mouse' style={{width: '100%'}}>
                    <KeyBoardMouseStroke
                      className=''
                      startDate={barDate}
                      userId={selectedUser}
                    ></KeyBoardMouseStroke>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
          .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
            animation: fadeIn 0.5s ease;
            
          }

          #div-scroller::-webkit-scrollbar-thumb {
            background-color: #C1C1C1 !important;
          }
          #div-scroller::-webkit-scrollbar {
            width:0.5rem
          }



          .modal-innerContainer {
            
            margin: 0 auto;
            background-color: #fff;
            border-radius: 6px;
            padding: 20px;
            position: relative;
            width:95rem;
          }

        .custom-modal-header{
            padding-right: 29rem;
            font-size: 1.8rem;
            font-weight: 700;
        }

        .paginationBttns{
            width:23rem;
            margin: 2rem auto 0 auto;
        }

        .temp-class{
            padding: 0.5rem;
            width: 8rem;
            margin: 0;
            justify-content:center;
            margin:unset
        }


        `}</style>
    </div>
  )
}

export {Popup}
