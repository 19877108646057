import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DashboardWrapper1} from '../pages/screenshots/dash'

import {EmpWrapper} from '../pages/addEmp/emp'
import {AttendanceSheetWrapper} from '../pages/attendance/attend'

import {ComWrapper} from '../pages/addCompany/addCompany'
import {Urls} from '../pages/url'
import {Passwords} from '../pages/passwords/cards/passwords'
import {AddSchedule} from '../pages/Schedule/schedule'
import {Schedule} from '../pages/Schedule/DisSchedule'
import {EditSchedule} from '../pages/Schedule/edit_schedule'
import {Holiday} from '../pages/Holiday/Holiday'
import {ProductivitysSheet} from '../pages/Productivitys/Productivitys'
import {Attendance} from '../pages/AttendanceReport/Attendance'
import {Timelines} from '../pages/Timeline/Timelines'

import {EmpWebsite} from '../pages/Websites&Application/emp/EmpWebsite'
import {EmpApplication} from '../pages/Websites&Application/emp/EmpApplication'
import {WebWrapper} from '../pages/Websites&Application/WebWrapper'

import {Category} from '../pages/Category/Category'
import TimeClaim from '../pages/TimeClaim/TimeClaim'
import {UpdateTimeClaim} from '../pages/TimeClaim/UpdateTimeClaim'
import {UserTimeClaim} from '../pages/TimeClaim/UserTimeClaim'
import {BuilderPageManager} from '../pages/layout-builder/BuilderPageManager'
import Prod_vs_Unprod from '../pages/prod_vs_unprod/Prod_vs_Unpro'
import LiveStreaming from '../pages/LiveStreaming/LiveStreaming'
import LiveRecording from '../pages/liveRecording/LiveRecording'
import ActivitySummary from '../pages/activitySummary/ActivitySummary'
import {MarkTaskCompletion} from '../pages/Task/TaskCompletion'
import TimeEntriesReport from '../pages/timeEntries/TimeEntriesReport'
import UserTimeEntries from '../pages/timeEntries/UserTimeEntries'
import {UserProjectTaskReport} from '../pages/userProjectTaskReport/userProjectTaskReport'
import WeeklySummary from '../pages/weeklySummary/WeeklySummary'
import SupportForm from '../pages/supportFrom/SupportFrom'

const PrivateRoutesManager = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/builder' />} />
        {/* Pages */}

        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='testpage' element={<DashboardWrapper1 />} />
        {/* <Route path='project' element={<ProjectWrapper />} /> */}
        <Route path='add' element={<EmpWrapper />} />
        <Route path='reports' element={<AttendanceSheetWrapper />} />
        <Route path='com' element={<ComWrapper />} />
        {/* <Route path='member' element={<MemberWrapper />} /> */}
        <Route path='report/urls' element={<Urls />} />
        <Route path='report/systemActivity' element={<ActivitySummary />} />
        <Route path='changePassword' element={<Passwords />} />
        <Route path='schedule' element={<Schedule className='' />} />
        <Route path='addschedule' element={<AddSchedule />} />
        <Route path='editschedule' element={<EditSchedule />} />
        <Route path='holiday' element={<Holiday />} />
        <Route path='report/attendance' element={<Attendance />} />
        <Route path='report/timeline' element={<Timelines />} />
        <Route path='report/dailyTimeEntry' element={<TimeEntriesReport />} />
        <Route path='report/weeklySummary' element={<WeeklySummary />} />
        <Route path='WebAppCatalogue' element={<Category />} />
        <Route path='TimeClaim/claim' element={<TimeClaim />} />
        <Route path='TimeClaim/request' element={<UpdateTimeClaim />} />
        <Route path='TimeClaim/status' element={<UserTimeClaim />} />
        <Route path='timeEntry' element={<UserTimeEntries />} />
        <Route path='overviewDashboard' element={<BuilderPageManager />} />
        <Route path='report/ProdVsUnpro' element={<Prod_vs_Unprod />} />
        <Route path='liveStreaming' element={<LiveStreaming />} />
        <Route path='liveRecording' element={<LiveRecording />} />
        <Route path='markTaskCompletion' element={<MarkTaskCompletion />} />
        <Route path='userTaskReport' element={<UserProjectTaskReport />} />
        <Route path='support' element={<SupportForm />} />
        <Route path='manager_records' element={<WebWrapper />}>
          <Route index element={<EmpWebsite />} />
          <Route path='website' element={<EmpWebsite />} />
          <Route path='application' element={<EmpApplication />} />
        </Route>

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='report/timeTracker'
          element={
            <SuspensedView>
              <AttendanceSheetWrapper></AttendanceSheetWrapper>
            </SuspensedView>
          }
        />
        {/* <Route
          path='reports/productivity'
          element={
            <SuspensedView>
              <ProductivitySheet></ProductivitySheet>
            </SuspensedView>
          }
        /> */}
        <Route
          path='report/productivitys'
          element={
            <SuspensedView>
              <ProductivitysSheet></ProductivitysSheet>
            </SuspensedView>
          }
        />
        <Route
          path='screenshots'
          element={
            <SuspensedView>
              <DashboardWrapper1 />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutesManager}
