import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function GetAllGroupAdmin() {
  return axios.get(`${API_URL}/Registration/GetAllGroupAdmin`)
}
export function GetGroupAdminById(adminId: string, accessToken: string) {
  return axios.get(`${API_URL}/Registration/GetGroupAdminById?groupAdminId=${adminId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
export function GetOrgnizationDetails(orgId: string, accessToken: string) {
  return axios.get(`${API_URL}/Organization/GetOrganizationConfigByOrgId?orgId=${orgId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
