import clsx from 'clsx'
import React, {useState, useEffect} from 'react'
import axios, {CancelTokenSource} from 'axios'

import 'react-datepicker/dist/react-datepicker.css'

import {
  Pagination,
  Loader,
  RowsPerPage,
  AppWebUsageForManager,
} from '../../CommonFunctions/CommonFunction'

import {convertSecIntoHoursMinSec} from '../../utils/ConvertSeconds.utils'

type Props = {
  className: string
  selectedUser: string
  startDate: Date
  endDate: Date
  department: string
}

const API_URL = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

const AdminProdVsUnProd: React.FC<Props> = ({
  className,
  selectedUser,
  startDate,
  endDate,
  department,
}) => {
  const tab = 'Header'

  const [rowPerPage, setRowPerPage] = useState(10)
  const category = 'Productive'
  const [allData, setAllData] = useState<any>([])

  const [spinner, setSpinner] = useState(false)

  const role = localStorage.getItem('role')

  useEffect(() => {
    AppWebUsageForManager(
      role,
      setSpinner,
      department,
      startDate,
      endDate,
      category,
      selectedUser,
      fetchData,
      cancelTokenSource,
      setAllData
    )
  }, [selectedUser, startDate, endDate, department, category])

  const fetchData = async (url: any, body: any) => {
    try {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('New request initiated')
      }
      cancelTokenSource = axios.CancelToken.source()

      const getData = await axios.post(`${API_URL}/AdminDashboard/${url}`, body, {
        cancelToken: cancelTokenSource?.token,
      })

      setAllData(getData.data)
      setSpinner(false)
    } catch (error) {
      console.log(error)
    }
  }

  //Pagination
  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState<any>(0)

  const endOffset = itemOffset + itemsPerPage
  const currentItemss = allData.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(allData.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % allData.length

    setItemOffset(newOffset)
  }

  return (
    <>
      {/* end::Header */}

      {/* begin::Form */}
      {/* begin::Body */}
      <div className='card' style={{width: '49%', overflowY: 'scroll'}}>
        <div className='tab-content pt-3'>
          <div className={clsx('tab-pane', {active: tab === 'Header'})}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5' style={{overflowY: 'scroll'}}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',

                  alignItems: 'center',
                }}
              >
                <h3 className='card-title align-items-start flex-column'>
                  {/* <span className='card-label fw-bolder fs-3 mb-1'>New Arrivals</span> */}
                  <span className='card-label fw-bolder fs-3 mb-1'>Productive Web / Apps</span>

                  {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span> */}
                </h3>
              </div>

              <div className='card-body py-3'>
                <div
                  style={{
                    background: 'rgb(114, 143, 206)',
                    border: 'solid 1px',
                  }}
                >
                  <table style={{width: '100%', height: '3.8rem'}}>
                    <thead>
                      <tr
                        className='fw-bolder'
                        style={{background: 'rgb(114, 143, 206)', fontSize: '15px'}}
                      >
                        <th style={{width: '1rem'}}></th>
                        <th className='' style={{width: '24rem', textAlign: 'left'}}>
                          Site / App
                        </th>
                        <th className=''>Time</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div
                  id='div-scroller'
                  className='table-responsive'
                  style={{
                    height: '23rem',
                    borderBottom: 'solid 1px',
                    borderRight: 'solid 1px',
                    borderLeft: 'solid 1px',
                  }}
                >
                  {spinner && Loader('100px')}
                  <table className='table align-middle gs-0 gy-4'>
                    <tbody>
                      {allData.length > 0 && (
                        <tbody>
                          {currentItemss.length > 0 &&
                            currentItemss.map((url: any) => (
                              <tr style={{fontSize: '12px'}}>
                                <td style={{width: '1rem'}}></td>
                                <td
                                  style={{
                                    lineBreak: 'auto',
                                    width: '24rem',
                                    textAlign: 'left',
                                  }}
                                >
                                  <span className='text-dark  d-flex mb-1 fs-6 custom-span'>
                                    {url.appName ? url.appName : url.url}
                                  </span>
                                </td>

                                <td>
                                  <span className='text-success  d-flex mb-1 fs-6  custom-span'>
                                    {convertSecIntoHoursMinSec(url.totalTime)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      )}
                      {allData.length === 0 && <h2 className='noRecordFound'>No Records Found</h2>}
                    </tbody>
                  </table>
                </div>
              </div>
              <div></div>
              {!spinner && allData.length > 10 && (
                <>
                  {RowsPerPage(
                    setForcePage,
                    setRowPerPage,
                    setItemOffset,
                    rowPerPage,
                    allData.length,
                    '1rem'
                  )}

                  {Pagination(handlePageClick, pageCount, forcePage)}
                </>
              )}
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
          <style>{`
          .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
            animation: fadeIn 0.5s ease;
            
          }

          #div-scroller::-webkit-scrollbar-thumb {
            background-color: #C1C1C1 !important;
          }
          #div-scroller::-webkit-scrollbar {
            width:0.5rem
          }



          .modal-innerContainer {
            max-width: 1000px;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 6px;
            padding: 20px;
            position: relative;
            width:78rem;
          }

        .custom-modal-header{
            padding-right: 29rem;
            font-size: 1.8rem;
            font-weight: 700;
        }

        .paginationBttns{
            width:23rem;
           
        }

        .temp-class{
            padding: 0.5rem;
            width: 8rem;
            margin: 0;
            justify-content:center;
            margin:unset
        }


        `}</style>
        </div>
      </div>

      {/* end::Body */}

      {/* begin::Footer */}

      {/* end::Footer */}
      {/* end::Form */}
    </>
  )
}

export {AdminProdVsUnProd}
