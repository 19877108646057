/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../../css/plans.css'
import {GetAllBillingPlansByOrgId} from '../../services/Payment.services'
import {Loader} from '../../CommonFunctions/CommonFunction'

import {CheckOutPopUp} from './checkoutPopUp'

export const Plans = () => {
  const [allPlans, setAllPlans] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState('')
  const [paymentType, setPaymentType] = useState('Online')
  useEffect(() => {
    setSpinner(true)
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const plans = await GetAllBillingPlansByOrgId()
      setAllPlans(plans.data)
      setSpinner(false)
    } catch (error) {
      console.error('Error fetching plans:', error)
    }
  }

  const handleOpenModal = (planId: any) => {
    setSelectedPlanId(planId)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  }

  return (
    <div className='card'>
      <div className='card-body p-lg-10'>
        <div className='d-flex flex-column'>
          <div className=' text-center'>
            <h1 className='fs-2hx fw-bold mb-5'>
              <strong>Choose Your Plan</strong>
            </h1>
          </div>
          <div
            className='nav-group nav-group-outline mx-auto'
            data-kt-buttons='true'
            data-kt-initialized='1'
          >
            <button
              className={`btn btn-color-gray-400  btn-active-secondary px-6 py-3 me-2 ${
                paymentType === 'Offline' ? 'active' : ''
              }`}
              data-kt-plan='Offline'
              onClick={() => setPaymentType('Offline')}
            >
              Offline
            </button>

            <button
              className={`btn btn-color-gray-400  btn-active-secondary px-6 py-3 ${
                paymentType === 'Online' ? 'active' : ''
              }`}
              data-kt-plan='Online'
              onClick={() => setPaymentType('Online')}
            >
              Online
            </button>
          </div>

          <div className='row g-10 payment-container mt-2'>
            {spinner && Loader('0px')}
            {!spinner && (
              <Slider {...settings}>
                {allPlans.map((item) => (
                  <div className='col-xl-4'>
                    <div className='plan'>
                      <div className='inner'>
                        <span className='pricing'>
                          <span>
                            {item.currencyCode === 'USD' ? `$${item.amount}` : `₹${item.amount}`}
                            <small>/ {item.planTypeName}</small>
                          </span>
                        </span>
                        <p className='title'>{item.planName}</p>
                        <span className='info'>{item.planDescription}</span>

                        <ul className='features'>
                          {item.benefits.map((benefits: any) => (
                            <li>
                              <span className='icon'>
                                <svg
                                  height='24'
                                  width='24'
                                  viewBox='0 0 24 24'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M0 0h24v24H0z' fill='none'></path>
                                  <path
                                    fill='currentColor'
                                    d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z'
                                  ></path>
                                </svg>
                              </span>
                              <span className='benefits'>
                                {/* <strong>20</strong> team members */}
                                {benefits}
                              </span>
                            </li>
                          ))}
                        </ul>

                        <div className='action'>
                          <button className='button' onClick={() => handleOpenModal(item.planId)}>
                            {' '}
                            Choose plan{' '}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
          <div>
            {isModalOpen && (
              <CheckOutPopUp
                paymentType={paymentType}
                onClose={handleCloseModal}
                handleMainIconClick={handleCloseModal}
                planId={selectedPlanId}
              ></CheckOutPopUp>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Plans
