import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import dayjs from 'dayjs'
import {GetKeyboardMouseStrokes} from '../../services/ProVsIdlePopUp.services'
import {ScreenshotsPopup} from '../../../_metronic/partials/modals/ProVsIdle-Popup/ScreenshotsPopUp'

// interface MyObject {
//   fromDate: string
//   awayTime: string
//   unproductiveTime: string
//   organizationId: string
//   toDate: string
//   totalIdleTime: string
//   totalProductiveTime: string
//   userId: string
//   userName: string
// }

type Props = {
  className: string
  startDate: string
  userId: string
}

const KeyBoardMouseStroke: React.FC<Props> = ({className, startDate, userId}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDate, _] = useState('')
  const [series, __] = useState('')
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const role = localStorage.getItem('role')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getStrokesData = await GetKeyboardMouseStrokes(
          userId,
          dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
          dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]')
        )

        if (!chartRef.current) {
          return
        }

        const chart = new ApexCharts(
          chartRef.current,
          getChartOptions(getStrokesData.data, setIsModalOpen, setStart, setEnd)
        )
        if (chart) {
          chart.render()
        }
        return () => {
          if (chart) {
            chart.destroy()
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [isModalOpen])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <style>
          {`
                .apexchartsmychart {
                 overflow-x:scroll;
                 overflow-y:hidden !important;
                }

                body.modal-open{
                    overflow:hidden;
                }
          }
              `}
        </style>
        <div
          ref={chartRef}
          id='kt_charts_widget_1_chart'
          style={{height: '385px', textAlign: 'left'}}
        />
        {isModalOpen && role !== process.env.REACT_APP_SYSTEM_THIRD_ROLE && (
          <ScreenshotsPopup
            onClose={handleCloseModal}
            barDate={selectedDate}
            selectedUser={userId}
            series={series}
            startDate={start}
            endDate={end}
          />
        )}
      </div>
    </div>
  )
}

export {KeyBoardMouseStroke}

function getChartOptions(
  strokes: any,
  setIsModalOpen: (value: boolean) => void,
  setStart: (value: string) => void,
  setEnd: (value: string) => void
): ApexOptions {
  const firstEntry = {
    userName: strokes[0].userName,
    keyboardStrokes: 0,
    mouseStrokes: 0,
    startTime: strokes[0].startTime,
    endTime: strokes[0].startTime,
  }

  strokes.unshift(firstEntry)

  const mouseStrokes = strokes.map((item: any) => item.mouseStrokes)
  const keyboardStrokes = strokes.map((item: any) => item.keyboardStrokes)
  const categories = strokes.map((item: any) => dayjs(item.endTime).format('HH:mm'))

  return {
    series: [
      {
        name: 'Keyboard',
        data: keyboardStrokes,
      },
      {
        name: 'Mouse',
        data: mouseStrokes,
      },
    ],
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      events: {
        click: function (event, chartContext, config) {
          if (config.dataPointIndex !== undefined && config.dataPointIndex !== 0) {
            setStart(strokes[config.dataPointIndex].startTime)
            setEnd(strokes[config.dataPointIndex].endTime)
            setIsModalOpen(true)
          } else {
            return
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: -45,
        rotateAlways: true,
      },
      axisTicks: {
        show: true,
        offsetX: 1000,
      },
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return val.toFixed(0)
        },
      },
    },
  }
}
