import axios, {CancelTokenSource} from 'axios'
import {useEffect, useState} from 'react'
import dayjs from 'dayjs'
import {
  Pagination,
  Loader,
  RowsPerPage,
  SingleDatePickerComponent,
  loadAssigeeAndMember,
  earlyStageCall,
  SendDataOfUser,
} from '../../CommonFunctions/CommonFunction'
import {convertSecIntoAMPM, convertSecIntoHoursMinSec} from '../Common_Function/Function'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {userInfoActions} from '../../modules/auth'

const API = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

const ActivitySummary: React.FC = () => {
  const role = localStorage.getItem('role')
  const [posts, setPosts] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [screenloader, setScreenLoader] = useState<boolean>(false)
  const [userDetail, setUserDetail] = useState<any>({})
  const [memberOption, setMemberOption] = useState<any[]>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [_, setForcePage] = useState<any>(null)
  const dispatch = useDispatch()
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)

  useEffect(() => {
    const isGroupAdmin = localStorage.getItem('isGroupAdmin')
    if (!isGroupAdmin && !ConfigDetails.selectedUserId) {
      setOptionSelectedMember({
        label: localStorage.getItem('name'),
        value: localStorage.getItem('userId'),
      })
    }
    if (!isGroupAdmin && ConfigDetails.selectedUserId) {
      setOptionSelectedMember({
        label: ConfigDetails?.selectedUserName,
        value: ConfigDetails?.selectedUserId,
      })
    }
    loadAssigeeAndMember(role, setMemberOption, setOptionSelectedMember, ConfigDetails)
  }, [])

  const SendDataOfDate = (date: any) => {
    earlyStageCall(cancelTokenSource, setPosts, setScreenLoader, setItemOffset)
    fetchPosts(dayjs(date).format('YYYY-MM-DDT00:00:00[Z]'), optionSelectedMember.value)
  }

  const fetchPosts = (date: any, userId?: any) => {
    setScreenLoader(true)
    const body = {
      OrganizationId: localStorage.getItem('org_Id'),
      UserId: userId,
      ReportDate: date,
    }

    cancelTokenSource = axios.CancelToken.source()
    axios
      .get(`${API}/SystemActivityReport/GetSystemActivityReport`, {
        params: body,
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setUserDetail(res.data)
        setPosts(res.data.details)
        setScreenLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setScreenLoader(false)
      })
  }

  useEffect(() => {
    if (!ConfigDetails.selectedUserId) {
      fetchPosts(dayjs().format('YYYY-MM-DDT00:00:00[Z]'), localStorage.getItem('userId'))
    } else {
      fetchPosts(dayjs().format('YYYY-MM-DDT00:00:00[Z]'), ConfigDetails.selectedUserId)
    }
  }, [])

  //Pagination
  const [rowPerPage, setRowPerPage] = useState(10)
  let itemsPerPage: number = rowPerPage
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(posts.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(posts.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, posts])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % posts.length
    setItemOffset(newOffset)
  }

  const handleDateChange = (dates: any) => {
    setStartDate(dates)
    SendDataOfDate(dates)
  }
  return (
    <>
      <div className={`card card-xxl-stretch mb-5 mt-8`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Activity Summary</span>
          </h3>

          <div className='d-flex align-items-center justify-content-end'>
            <div className='d-flex align-items-center'>
              <div>
                <div
                  style={{width: '200px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Member'
                >
                  {role === process.env.REACT_APP_SYSTEM_THIRD_ROLE ? (
                    <>
                      {memberOption.length > 1 && (
                        <Select
                          className='selectDropdown'
                          components={makeAnimated()}
                          value={optionSelectedMember}
                          options={memberOption}
                          placeholder='select member'
                          onChange={(item: any) => {
                            setOptionSelectedMember(item)
                            const userParams = {
                              userId: item.value,
                              cancelTokenSource,
                              setPosts,
                              setScreenLoader,
                              setItemOffset,
                              startDate,
                              fetchPosts,
                            }
                            SendDataOfUser(userParams)
                          }}
                          isClearable={false}
                          isSearchable={true}
                          closeMenuOnScroll={true}
                        />
                      )}
                    </>
                  ) : (
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      value={optionSelectedMember}
                      options={memberOption}
                      placeholder='select member'
                      onChange={(item: any) => {
                        setOptionSelectedMember(item)
                        if (
                          role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                          role == process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
                          role == process.env.REACT_APP_SYSTEM_SECOND_ROLE
                        ) {
                          dispatch(
                            userInfoActions.updateSelectedUser({
                              selectedUserName: item.label,
                              selectedUserId: item.value,
                            })
                          )
                        }
                        const userParams = {
                          userId: item.value,
                          cancelTokenSource,
                          setPosts,
                          setScreenLoader,
                          setItemOffset,
                          startDate,
                          fetchPosts,
                        }
                        SendDataOfUser(userParams)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  )}
                </div>
              </div>
              {SingleDatePickerComponent(startDate, handleDateChange, 'form-control ss_date')}
            </div>
          </div>
        </div>

        <div className='card-body py-3 d-flex justify-content-around mt-5 px-5'>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: '#a600ff'}}
          >
            <div className='fs-6 fw-bolder'>Date</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {dayjs(startDate).format('DD/MM/YYYY')}
            </div>
          </div>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: 'rgb(64, 132, 255)'}}
          >
            <div className='fs-6 fw-bolder'>First Activity</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {convertSecIntoAMPM(userDetail.firstActivity)}
            </div>
          </div>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: 'rgb(244, 51, 74)'}}
          >
            <div className='fs-6 fw-bolder'>Last Activity</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {convertSecIntoAMPM(userDetail.lastActivity)}
            </div>
          </div>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: 'rgb(98, 55, 221)'}}
          >
            <div className='fs-6 fw-bolder'>Total Time</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {convertSecIntoHoursMinSec(userDetail.totalTime)}
            </div>
          </div>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: 'rgb(52, 168, 83)'}}
          >
            <div className='fs-6 fw-bolder'>Productive Time</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {convertSecIntoHoursMinSec(userDetail.totalProductiveTime)}
            </div>
          </div>
          <div
            className='border rounded min-w-175px py-3 px-4 mb-3 text-white d-flex flex-column align-items-center'
            style={{background: 'rgb(251, 188, 4)'}}
          >
            <div className='fs-6 fw-bolder'>Idle Time</div>
            <div className='fw-semibold text-white' style={{color: 'rgb(17,17,100)'}}>
              {convertSecIntoHoursMinSec(userDetail.totalIdleTime)}
            </div>
          </div>
        </div>
      </div>

      <div className='card py-9 px-10'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4' style={{border: '1px solid '}}>
            <thead>
              <tr
                className='fw-bolder'
                style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
              >
                <th></th>
                <th className='min-w-100px text-center'>Start Time</th>
                <th className='min-w-100px text-center'>End Time</th>
                <th className='min-w-100px text-center'>Spend Time</th>
                <th className='min-w-100px text-center'>Activity Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.length > 0 &&
                currentItems?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td></td>
                    <td className=' text-center '>
                      <span className='text-dark  fs-6'>
                        {dayjs(item.startTime).format('HH:mm:ss')}
                      </span>
                    </td>
                    <td className=' text-center '>
                      <span className='text-dark fs-6'>
                        {dayjs(item.endTime).format('HH:mm:ss')}
                      </span>
                    </td>
                    <td className=' text-center '>
                      <span className='text-dark  fs-6'>
                        {convertSecIntoHoursMinSec(item.spentTime)}
                      </span>
                    </td>
                    <td className=' text-center '>
                      <span className='text-dark  fs-6'>{item.userActivityStatus}</span>
                    </td>
                    <td></td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          {screenloader && Loader('100px')}
          {!screenloader && currentItems.length === 0 && (
            <h2 className='noRecordFound'>No Records Found</h2>
          )}
        </div>

        {posts.length > 10 && (
          <div className='d-flex align-items-center justify-content-center position-relative'>
            <div style={{left: '0', position: 'absolute', top: '0'}}>
              {RowsPerPage(
                setForcePage,
                setRowPerPage,
                setItemOffset,
                rowPerPage,
                posts.length,
                '1rem'
              )}
            </div>

            {posts.length > rowPerPage && Pagination(handlePageClick, pageCount, 0)}
          </div>
        )}
      </div>
    </>
  )
}

export default ActivitySummary
