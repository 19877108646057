/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {

  TablesWidget5,
} from '../../_metronic/partials/widgets'


export function Urls() {
  return (
    <>
      <div className='row gy-10 gx-xl-10'>

        <div className='col-xl-12'>
          <TablesWidget5 className='card-xxl-stretch mb-5' />
        </div>
      </div>
    </>
  )
}
