import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'

import {KTSVG} from '../../../_metronic/helpers'
import {Pagination} from '../../CommonFunctions/CommonFunction'
import {convertSecIntoHoursMinSec} from '../Common_Function/Function'

type Props = {
  data: any
}

const DetailDailySummary: React.FC<Props> = ({data}) => {
  // Pagination
  const [rowPerPage, setRowPerPage] = useState(10)
  let itemsPerPage: number = rowPerPage
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState(0)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(data.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(data.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, data])

  useEffect(() => {
    const calculatedPage = Math.floor(itemOffset / itemsPerPage)
    setForcePage(calculatedPage)
  }, [itemOffset])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % data.length
    setItemOffset(newOffset)
  }

  const handleCloseModal = () => {
    setItemOffset(0)
  }

  return (
    <>
      <div
        className='modal fade'
        id='kt_modal_DetailDailySummary'
        aria-hidden='false'
        onClick={() => console.log('test')}
      >
        <div className='modal-dialog mw-750px d-flex align-items-center h-100 m-auto'>
          <div className='modal-content rounded'>
            <div className='modal-header justify-content-end border-0 p-0 pb-1'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
                onClick={handleCloseModal}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='table-responsive px-10 pb-2'>
              <table className='table align-middle gs-0 gy-4' style={{border: '1px solid '}}>
                <thead>
                  <tr
                    className='fw-bolder'
                    style={{
                      background: '#728FCE',
                      fontSize: '15px',
                      borderBottom: '1px solid',
                    }}
                  >
                    <th></th>
                    <th className='min-w-100px text-center'>Start Time</th>
                    <th className='min-w-100px text-center'>End Time</th>
                    <th className='min-w-100px text-center'>Spend Time</th>
                    <th className='min-w-100px text-center'>Activity Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 &&
                    currentItems?.map((item: any, index: any) => (
                      <tr key={index}>
                        <td></td>
                        <td className=' text-center '>
                          <span className='text-dark  fs-6'>
                            {dayjs(item.startTime).format('HH:mm:ss')}
                          </span>
                        </td>
                        <td className=' text-center '>
                          <span className='text-dark  fs-6'>
                            {dayjs(item.endTime).format('HH:mm:ss')}
                          </span>
                        </td>
                        <td className=' text-center '>
                          <span className='text-dark  fs-6'>
                            {convertSecIntoHoursMinSec(item.spentTime)}
                          </span>
                        </td>
                        <td className=' text-center '>
                          <span className='text-dark  fs-6'>{item.userActivityStatus}</span>
                        </td>
                        <td></td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {data.length > 10 && (
              <div className='d-flex align-items-center justify-content-center position-relative pb-5'>
                {/* <div style={{left: '0', position: 'absolute', top: '0'}}>
                  {RowsPerPage(
                    setForcePage,
                    setRowPerPage,
                    setItemOffset,
                    rowPerPage,
                    data.length,
                    '1rem'
                  )}
                </div> */}

                {data.length > rowPerPage && Pagination(handlePageClick, pageCount, forcePage)}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailDailySummary
