import axios from 'axios'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {useNavigate, Link} from 'react-router-dom'
import * as Yup from 'yup'
import {AlertModal} from '../../CommonFunctions/CommonFunction'

const AddPartnerOrg: React.FC = () => {
  const API = process.env.REACT_APP_API_URL
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const initialValues = {
    partnerId: localStorage.getItem('userId'),
    organizationName: '',
    adminName: '',
    orgAddress: '',
    orgEmail: '',
    orgContactNo: '',
    TaxNumber: '',
    ContractDocFile: '',
  }

  const phone = /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/

  const companySchema = Yup.object().shape({
    organizationName: Yup.string()
      .min(3, 'Minimum 3 character')
      .max(50, 'Maximum 50 character')
      .required('Name is required'),
    orgEmail: Yup.string()
      .email('Wrong email format')
      .min(10, 'Minimum 10 character')
      .max(50, 'Maximum 50 character')
      .required('Email is required'),
    orgAddress: Yup.string()
      .min(3, 'Minimum 3 character')
      .max(250, 'Maximum 250 character')
      .required('Address is required'),
    orgContactNo: Yup.string()
      .matches(phone, 'Contact Number should only contains numerals')
      .min(10, 'Contact Number should contain 10 numbers')
      .max(10, 'Contact Number should contain 10 numbers')
      .required('Contact is required'),
    adminName: Yup.string()
      .min(3, 'Minimum 3 character')
      .max(50, 'Maximum 50 character')
      .required('Admin Name is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: companySchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setLoading(false)
      axios
        .post(`${API}/Organization/AddOrganizationByPartner`, values, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            setLoading(false)
            navigate('/organizations')
          })
        })
        .catch((err) => {
          console.error(err)
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 '
        role='button'
        // data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Organization Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        {/* encType='multipart/form-data' */}
        {/* noValidate   */}
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Name'
                  {...formik.getFieldProps('organizationName')}
                />
                {formik.touched.organizationName && formik.errors.organizationName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.organizationName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address'
                  {...formik.getFieldProps('orgAddress')}
                />
                {formik.touched.orgAddress && formik.errors.orgAddress && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.orgAddress}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Admin Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Admin Name'
                  {...formik.getFieldProps('adminName')}
                />
                {formik.touched.adminName && formik.errors.adminName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.adminName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  {...formik.getFieldProps('orgEmail')}
                />
                {formik.touched.orgEmail && formik.errors.orgEmail && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.orgEmail}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Contact No</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Contact No'
                  {...formik.getFieldProps('orgContactNo')}
                />
                {formik.touched.orgContactNo && formik.errors.orgContactNo && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.orgContactNo}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fw-bold fs-6'>Tax Number</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Tax Number'
                  {...formik.getFieldProps('TaxNumber')}
                />
                {formik.touched.TaxNumber && formik.errors.TaxNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.TaxNumber}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Contract Documents</label>

              <div className='col-lg-8 fv-row'>
                {/* <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Contact No'
                  {...formik.getFieldProps('org_ContactNo')}
                /> */}
                <input
                  type='file'
                  className='form-control form-control-border'
                  placeholder='Payment Receipt'
                  onChange={(e) => {
                    if (e.target.files !== null) {
                      formik.setFieldValue('ContractDocFile', e.target.files[0])
                    }
                  }}
                />
                {formik.touched.ContractDocFile && formik.errors.ContractDocFile && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.ContractDocFile}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to='/organizations'>
              <button type='submit' className='btn btn-secondary mx-5'>
                Cancel
              </button>
            </Link>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && <span className='indicator-label'>Add Organization</span>}

              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddPartnerOrg
