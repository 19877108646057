import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  AlertModal,
  GetList,
  PartnerForm,
  basePartnerSchema,
} from '../../CommonFunctions/CommonFunction'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import AddNewSalesPerson from './AddNewSalesPerson'

const PartnerDetails: React.FC = () => {
  const API = process.env.REACT_APP_API_URL
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [partnerType, setPartnerType] = useState<any>([])
  const [isValid, setIsValid] = useState(true)

  const letters = /^[A-Za-z\s]+$/

  // Schema for member validation
  const partnerSchema = basePartnerSchema.shape({
    fullName: Yup.string()
      .matches(letters, 'numerals and symbols are not allowed')
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Full Name is required'),
  })

  useEffect(() => {
    GetList(setPartnerType)
  }, [])

  const initialValues = {
    email: '',
    fullName: '',
    referralCode: '',
    partnerTypeId: '',
    primaryContactName: '',
    contactNo: '',
    address: '',
    gst: '',
    pan: '',
    commission: '0',
    partnerContractDocFile: '',
    addSalesDetailsRequest: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: partnerSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      const form = new FormData()
      form.append('email', values.email)
      form.append('fullName', values.fullName)
      form.append('referralCode', values.referralCode)
      form.append('partnerTypeId', values.partnerTypeId)
      form.append('primaryContactName', values.primaryContactName)
      form.append('contactNo', values.contactNo)
      form.append('address', values.address)
      form.append('gst', values.gst)
      form.append('pan', values.pan)
      form.append('commission', values.commission)
      form.append('PartnerContractDocFile', values.partnerContractDocFile)
      form.append('addSalesDetailsRequest', JSON.stringify(values.addSalesDetailsRequest))

      axios
        .post(`${API}/Registration/AddPartner`, form)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            navigate('/partner')
            resetForm()
            setLoading(false)
          })
        })
        .catch((err) => {
          console.error(err)
          setLoading(false)
          setSubmitting(false)
        })

      resetForm()
      setLoading(false)
    },
  })

  const handleTypeChange = (e: any) => {
    formik.setFieldValue('partnerTypeId', e.value)
  }

  const saveSalesPersonInfo = (id: any, name: string, value: any) => {
    formik.values.addSalesDetailsRequest.map((item: any) => {
      if (item.id === id) {
        item[name] = value
      }
    })
  }

  const deleteTableRow = (id: any) => {
    const reamingRow = formik.values.addSalesDetailsRequest.filter((item: any) => item.id !== id)
    formik.setFieldValue('addSalesDetailsRequest', reamingRow)
  }

  const params = {
    formik,
    type: 'Add',
    setIsValid,
    partnerType,
    handleTypeChange,
    AddNewSalesPerson,
    saveSalesPersonInfo,
    deleteTableRow,
    loading,
    isValid,
    navigate,
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0' role='banner'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Partner Details</h3>
        </div>
      </div>

      <div className='collapse show'>{PartnerForm(params)}</div>
    </div>
  )
}

export default PartnerDetails
