import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {AlertModal} from '../../../CommonFunctions/CommonFunction'
const API_URL = process.env.REACT_APP_API_URL

const MouseCountColor: React.FC = () => {
  const [rowsData, setRowsData] = useState<any[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [updateButtonDisable, setUpdateButtonDisable] = useState<boolean>(true)
  const [addRowButtonDisable, setAddRowButtonDisable] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    getColorList()
  }, [])

  const getColorList = () => {
    axios
      .get(`${API_URL}/ColorCodeRange/GetColorCodeRangeConfigurationByByOrg`, {
        params: {
          organizationId: localStorage.getItem('org_Id'),
        },
      })
      .then((res: any) => {
        setRowsData(res.data)
      })
      .catch((err) => console.error(err))
  }

  const addTableRows = async () => {
    setUpdateButtonDisable(false)

    if (checkMinMaxValidation()) {
      setError(true)
    } else {
      if (currentIndex >= 0) {
        rowsData.map((row: any) => (row.isDisable = true))
      }
      const rowsInput = {
        organizationId: localStorage.getItem('org_Id'),
        minValue: rowsData.length > 0 ? Number(rowsData?.slice(-1)[0].maxValue) + 1 : 0,
        maxValue: 0,
        colorCode: '',
        isDisable: false,
      }
      setRowsData([...rowsData, rowsInput])
    }
  }
  const deleteTableRows = (index: number) => {
    setUpdateButtonDisable(false)
    setAddRowButtonDisable(false)
    setError(false)
    const rows = [...rowsData]
    rows.splice(index, 1)
    rows[rows.length - 1] = {...rows[rows.length - 1], isDisable: false}

    setRowsData(rows)
    if (rows.length === 0) {
      setUpdateButtonDisable(true)
    }
  }

  const minHandler = async (value: string, index: any) => {
    setUpdateButtonDisable(false)
    setCurrentIndex(index)
    //setMinValue(Number(value))
    const updatesValue = rowsData
    updatesValue[index] = {...updatesValue[index], minValue: Number(value)}
    await setRowsData(updatesValue)
  }

  const maxHandler = (value: any, index: any) => {
    setUpdateButtonDisable(false)
    setError(false)
    setCurrentIndex(index)

    // Create a new array with updated max value
    const updatedRows = rowsData.map((row, i) => {
      if (i === index) {
        return {...row, maxValue: Number(value)}
      }
      return row
    })

    setRowsData(updatedRows)
  }

  const colorHandler = async (value: any, index: any) => {
    setUpdateButtonDisable(false)
    setCurrentIndex(index)
    const updatesValue = rowsData
    updatesValue[index] = {...updatesValue[index], colorCode: value}
    await setRowsData(updatesValue)
    //setInfinity((prev: any) => prev + 1)
  }

  const checkMinMaxValidation = () => {
    const value = rowsData?.slice(-1)
    let min = value[0]?.minValue
    let max = value[0]?.maxValue
    return Number(min) > Number(max)
  }

  const updateHandler = async () => {
    const value = rowsData
    value.slice(-1)[0].maxValue = 1000000

    axios
      .post(`${API_URL}/ColorCodeRange/BulkAddUpadateColorCodeRange`, rowsData)
      .then((res) => {
        getColorList()
        AlertModal(
          res.data.message,
          '',
          res.data.success ? 'success' : 'error',
          false,
          '#7066E0',
          'Ok'
        )
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 align-items-center'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Activity Level</h3>
          </div>
          {!addRowButtonDisable && (
            <button className='btn btn-primary' onClick={addTableRows}>
              Add Color +
            </button>
          )}
        </div>
        <div className='card py-9 px-10'>
          {rowsData?.map((row: any, index: any) => (
            <div className='row mb-6 d-flex flex-row' key={index}>
              <input
                type='number'
                readOnly
                className='form-control form-control-lg form-control-solid w-auto mx-4'
                placeholder='Min Number'
                disabled={row.isDisable}
                min={rowsData[index - 1]?.maxValue ? Number(rowsData[index - 1].maxValue) + 1 : 0}
                defaultValue={row?.minValue}
                onChange={(e) => minHandler(e.target.value, index)}
              ></input>

              <label className='w-auto col-form-label fw-bolder align-items-center d-flex fs-6'>
                {'< ='}{' '}
              </label>

              <input
                type='number'
                disabled={row.isDisable || row.infinity}
                className={`form-control form-control-lg form-control-solid w-auto mx-4`}
                placeholder='Max Number'
                min={rowsData[index - 1]?.maxValue ? Number(rowsData[index - 1].maxValue) + 1 : 0}
                value={row.maxValue}
                onChange={(e) => maxHandler(e.target.value, index)}
              ></input>

              <div className='d-flex w-auto justify-content-center align-items-center'>
                <input
                  disabled={row.isDisable}
                  type='color'
                  defaultValue={row.colorCode ? row.colorCode : '#000000'}
                  onChange={(e) => colorHandler(e.target.value, index)}
                />
              </div>

              {rowsData?.length - 1 == index && (
                <button
                  className='btn btn-primary w-auto mx-5'
                  onClick={() => deleteTableRows(index)}
                >
                  x
                </button>
              )}
            </div>
          ))}
        </div>
        {error && (
          <span
            className='text-danger w-20 position-relative'
            style={{left: '30%', bottom: '3.5rem'}}
          >
            Min Is More Than Max
          </span>
        )}
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button
            type='submit'
            className='btn btn-primary'
            disabled={updateButtonDisable}
            onClick={updateHandler}
          >
            <span className='indicator-label'>Update</span>
            {/* {!loading && (
                        )}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )} */}
          </button>
        </div>
      </div>
    </>
  )
}

export default MouseCountColor
