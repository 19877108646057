import React, {FC} from 'react'
import {EditEmployeeDetails} from './cards/EditEmployeeDetails'

const EditEmpWrapper: FC = () => {
  return (
    <>
      <EditEmployeeDetails />
    </>
  )
}
export {EditEmpWrapper}
