import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {GetAssignee} from '../../services/GetAllAssinee.services'
import {memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import {Loader} from '../../CommonFunctions/CommonFunction'
import {HubConnection, HubConnectionBuilder, HubConnectionState} from '@microsoft/signalr'
import dayjs from 'dayjs'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

const LiveRecording: React.FC = () => {
  const role = localStorage.getItem('role')
  const liveRecodingURL: string = process.env.REACT_APP_LIVE_RECORDING_URL!
  const [memberOption, setMemberOption] = useState<any>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [hubConnection, setHubConnection] = useState<HubConnection>()
  const [members, setMembers] = useState<any[]>([])
  const [userList, setUserList] = useState<any>([])
  const [offlineList, setOfflineList] = useState<any[]>([])
  const [loader, setLoader] = useState<boolean>(false)
  const [recordingButtonDisable, setRecordingButtonDisable] = useState<boolean>(false)

  const initializeConnection = (): HubConnection | null => {
    try {
      const connection = new HubConnectionBuilder().withUrl(liveRecodingURL).build()

      return connection
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    const connection = initializeConnection()
    if (connection) {
      connection
        .start()
        .then(() => {
          setHubConnection(connection)
        })
        .catch((err) => console.error('Error establishing connection:', err))
    }

    return () => {
      if (hubConnection) {
        hubConnection.stop()
      }
      if (connection) {
        connection.stop()
      }
    }
  }, [])

  const tryReconnect = () => {
    const maxReconnectAttempts = 5
    let reconnectAttempts = 0

    if (window.navigator.onLine) {
      for (
        reconnectAttempts;
        reconnectAttempts < maxReconnectAttempts &&
        hubConnection?.state === HubConnectionState.Disconnected;
        reconnectAttempts++
      ) {
        hubConnection.start()
        setHubConnection(hubConnection)
      }
    }
  }

  useEffect(() => {
    if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
      GetAssignee()
        .then((res) => {
          setMembers(res.data)
          const newList = res.data
            .filter((item: any) => item.assigneeId !== localStorage.getItem('userId'))
            .map((item: any) => ({
              label: item.assigneeName,

              value: item.assigneeId,
            }))
          newList.unshift({
            label: 'All Members',
            value: 'All Members',
          })
          setMemberOption(newList)
        })
        .catch((err) => {
          console.log(err)
        })
    } else if (
      role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    ) {
      memberInfoByOrg()
        .then((res) => {
          setMembers(res.data)
          const newList = res.data
            .filter((item: any) => item.userId !== localStorage.getItem('userId'))
            .map((item: any) => {
              return {
                label: item.fullName,
                value: item.userId,
              }
            })
          setMemberOption(newList)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    axios
      .get(`${API_URL}/CloudStorageConfiguration/CheckCloudStorageByOrgIdAndKind`, {
        params: {
          orgId: localStorage.getItem('org_Id'),
          storageKind: 'LiveRecording',
        },
      })
      .then((res) => {
        setRecordingButtonDisable(!res.data)
        // if(res.data)
        // {
        //     AlertModal(
        //         res.data.message,
        //         'Please Configure Live Recording Storage    ',
        //         !res.data.success ? 'success' : 'error',
        //         false,
        //         '#7066E0',
        //         'Ok'
        //       )
        // }
      })
  }, [])

  useEffect(() => {
    if (hubConnection?.state === HubConnectionState.Connected) {
      hubConnection.on('UserOffLineStatus', UserOffLineStatus)
      hubConnection.on('LiveStreamRecordingStatus', userStatus)
    }
  }, [hubConnection])

  useEffect(() => {
    if (localStorage.getItem('role') === 'Manager')
      if (userList.length + offlineList.length === memberOption.length - 1) setLoader(false)
  }, [userList])

  const userStatus = (userId: any, isStartStatus: any, liveRecordingStartTime: any) => {
    if (localStorage.getItem('role') === 'Manager') setLoader(true)
    setUserList((prev: any) => [
      ...prev,
      {
        userId: userId,
        isStartStatus: isStartStatus,
        liveRecordingStartTime: liveRecordingStartTime,
      },
    ])
    //checkToStopLoader()
  }

  const UserOffLineStatus = (userId: string, data: string) => {
    if (data === 'true') {
      setOfflineList((prev: any) => [...prev, {userId: userId, status: 'offline'}])
    }
  }

  const start: any = async (id: string) => {
    setUserList([])
    setOfflineList([])

    if (optionSelectedMember.value === undefined) return

    let listOfUsers = []
    if (optionSelectedMember.value === 'All Members') {
      const test = memberOption
        .map((user: any) => user.value)
        .filter((item: any) => item !== undefined && item !== 'All Members')
      listOfUsers = test
    } else {
      listOfUsers = [optionSelectedMember.value]
    }

    hubConnection?.invoke('StartRecording', listOfUsers).catch((err: any) => console.log(err))
  }

  const getName = (id: any) => {
    // const { assigneeName, fullName } = members.find((item: any) => item.assigneeId == id) || {};
    // if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
    //     return assigneeName;
    // } else if (role === process.env.REACT_APP_SYSTEM_FIRST_ROLE || role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE) {
    //     return fullName;
    // }

    if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
      const newList = members.filter((item: any) => item.assigneeId == id)
      return newList[0].assigneeName
    } else if (
      role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    ) {
      const newList = members.filter((item: any) => item.userId == id)
      return newList[0].fullName
    }
  }

  return (
    <>
      <div className={`card`}>
        <div className='card-body'>
          <div className='tab-content pt-3'>
            {/* begin::Header */}
            <div className='card-title align-items-start flex-column'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Live Recording</span>
                </h3>

                <div className='d-flex align-items-center'>
                  <div
                    style={{width: '200px'}}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Member'
                  >
                    <Select
                      components={makeAnimated()}
                      options={memberOption}
                      placeholder='Select Member'
                      onChange={(item: any) => {
                        const isConnected = hubConnection?.state === HubConnectionState.Connected
                        if (!isConnected) {
                          tryReconnect()
                        }
                        setOptionSelectedMember(item)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>

                  <button
                    disabled={recordingButtonDisable}
                    onClick={start}
                    className='btn btn-primary mx-5'
                  >
                    <span className='indicator-label'>Start Recording</span>
                  </button>
                </div>
              </div>
              <div className='card-body py-3' style={{padding: '30px'}}>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bolder' style={{background: '#728FCE', fontSize: '15px'}}>
                        <th className='min-w-150px  text-center'>Name</th>
                        <th className='min-w-150px text-center'>Recording status</th>
                        <th className='min-w-150px text-center'>Date & Time</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      <tr>
                        <span
                          className='position-relative d-flex justify-content-center w-100'
                          style={{left: '100%'}}
                        >
                          {loader && Loader('0px')}
                        </span>
                      </tr>
                      {userList.length > 0 &&
                        userList.map((name: any) => (
                          <tr key={name.userId}>
                            <td>
                              <div className='d-flex text-center flex-column'>
                                <span className='text-dark  d-block fs-6 '>
                                  {getName(name.userId)}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span
                                className='text-center text-success d-block fs-6 '
                                style={{textAlign: 'left'}}
                              >
                                STARTED
                              </span>
                            </td>
                            <td>
                              <span className='text-dark text-center d-block fs-6 '>
                                {dayjs(name.liveRecordingStartTime, 'DD-MM-YYYY HH:mm:ss').format(
                                  'DD/MM/YYYY HH:mm:ss'
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}

                      {offlineList.length > 0 &&
                        offlineList.map((name: any) => (
                          <tr key={name.userId}>
                            <td>
                              <div className='d-flex text-center flex-column'>
                                <span className='text-dark  d-block fs-6 '>
                                  {getName(name.userId)}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span
                                className=' text-center text-warning d-block fs-6 '
                                style={{textAlign: 'left'}}
                              >
                                OFFLINE
                              </span>
                            </td>
                            <td>
                              <span className='text-dark  d-block fs-6 '></span>
                            </td>
                          </tr>
                        ))}
                    </tbody>

                    {/* end::Table body */}
                  </table>
                  {recordingButtonDisable && (
                    <h2 className='noRecordFound'>Please Configure Live Recording Storage</h2>
                  )}
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{ height: '46rem' }}>
                    {loading === true && Loader('3rem')}

                    {userState === false && loading === false && (
                        <div>
                            <span>
                                <h2 className='noRecordFound mt-15'>Please Select User</h2>
                            </span>
                        </div>
                    )}

                    {image.trim().length > 0 && userState === true && loading === false && (
                <div className='d-flex justify-content-center py-5 mx-5 mb-5'>
                  <img src={`data:image/jpeg;base64,${image}`} width={'80%'} />
                </div>
              )}

                    {userOffline === true &&
                        userState === true &&
                        image.trim().length <= 0 &&
                        loading === false && (
                            <div>
                                <h2 className='noRecordFound mt-15'>User Offline</h2>
                            </div>
                        )}
                </div> */}
      </div>
    </>
  )
}

export default LiveRecording
