import React, {FC} from 'react'
import { ProjectDetail } from './Projects/detail'




const ProWrapper: FC = () =>{
  return (
    <>
      
      <ProjectDetail></ProjectDetail>
      
    </>
  )
}
export {ProWrapper}
