import React, {useState} from 'react'
import axios from 'axios'

import {AlertModal, ErrorModal} from '../../CommonFunctions/CommonFunction'

import '../../../css/task.css'
import sheets from '../../../images/sheets.png'
import {Link, useNavigate} from 'react-router-dom'

const BulkUploadTask: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL

  const [selectedFiles, setSelectedFiles] = useState<any>({})

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const uploadMembers = (e: any) => {
    e.preventDefault()

    const allowedFileTypes = [
      '.csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ]

    const selectedFile = e.target.files[0]

    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      setSelectedFiles(selectedFile)
    }
  }

  const handleDragLeave = (e: any) => {
    e.preventDefault()
  }
  const handleDragOver = (e: any) => {
    e.preventDefault()
  }
  const handleDrop = (e: any) => {
    e.preventDefault()
    const droppedFiles = e.dataTransfer.files
    if (
      droppedFiles[0].type === '.csv' ||
      droppedFiles[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      droppedFiles[0].type === 'application/vnd.ms-excel'
    ) {
      setSelectedFiles(droppedFiles[0])
    }
  }

  const handleClear = (e: any) => {
    e.preventDefault()
    setSelectedFiles({})
  }

  const handleUpload = () => {
    try {
      setLoading(true)
      const orgId: any = localStorage.getItem('org_Id')

      const form = new FormData()

      form.append('UserRequestFile', selectedFiles)

      form.append('OrganizationId', orgId)
      return axios
        .post(`${API_URL}/AllocateTask/ImportBulkTaskAllocation`, form, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-type': 'multipart/form-data',
          },
        })
        .then((res: any) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            navigate('/task/allocate')
          })

          setSelectedFiles({})
          setLoading(false)
        })
        .catch((err: any) => {
          console.log(err)
        })
    } catch (error) {
      ErrorModal()
    }
  }

  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = '/media/sample/SampleTaskAllocation.xlsx'
    link.download = 'SampleTaskAllocation.xlsx'
    link.dispatchEvent(new MouseEvent('click'))
  }

  return (
    <>
      <div className='card card-xxl-stretch mb-5'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Bulk Task Allocation</span>
          </h3>

          <div className='d-flex justify-content-center align-items-center'>
            <Link
              to='#'
              className='me-3'
              onClick={() => handleDownload()}
              style={{display: 'flex', alignItems: 'center'}}
            >
              <span style={{flexGrow: 1, textAlign: 'center'}}>*</span>
              Download Sample file
            </Link>
            &nbsp;&nbsp;&nbsp;
            <button
              type='submit'
              onClick={handleUpload}
              className='btn btn-primary'
              disabled={
                !selectedFiles || !selectedFiles.name || selectedFiles.name === '' || loading
              }
            >
              {!loading && <span className='indicator-label'>Upload</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
        <div
          className='card-body py-3 mx-7 mb-5 mt-10 mb-10'
          style={{display: 'flex', justifyContent: 'center'}}
        >
          <form
            className='task-upload-file-upload-form'
            onDragLeave={(e) => handleDragLeave(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDrop(e)}
          >
            <label className='task-upload-file-upload-label'>
              {selectedFiles && selectedFiles.name && selectedFiles.name !== '' ? (
                <div className='d-flex' style={{justifyContent: 'center'}}>
                  <div className='d-flex' style={{flexDirection: 'column'}}>
                    <img className='task-image' src={sheets} />
                    <span
                      style={{
                        fontSize: 'large',
                        width: '10rem',
                        lineBreak: 'anywhere',
                        marginLeft: '3rem',
                      }}
                    >
                      {selectedFiles.name}
                    </span>
                  </div>
                  <i
                    className='fa fa-times'
                    onClick={(e) => handleClear(e)}
                    aria-hidden='true'
                    style={{
                      fontSize: '2rem',

                      cursor: 'pointer',
                    }}
                  ></i>
                </div>
              ) : (
                <>
                  <div className='task-upload-file-upload-design'>
                    <svg viewBox='0 0 640 512' height='1em'>
                      <path d='M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z'></path>
                    </svg>
                    <p className='task-drag'>Drag and Drop</p>
                    <p className='task-or'>or</p>
                    <span className='task-upload-browse-button'>Browse file</span>
                  </div>
                  <input
                    id='task-upload-file'
                    onChange={(e: any) => uploadMembers(e)}
                    type='file'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  />
                </>
              )}
            </label>
          </form>
        </div>
      </div>
    </>
  )
}

export default BulkUploadTask
