/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {
  GetOrgList,
  Pagination,
  RowsPerPage,
  Loader,
  AlertModal,
} from '../../CommonFunctions/CommonFunction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import dayjs from 'dayjs'
import 'tippy.js/dist/tippy.css'
import {GetTrialExtensionHistoryByOrgId} from '../../services/trialExtension.services'

const TrialExtensionHistory: React.FC = () => {
  const [filteredData, setFilteredData] = useState<any>([])
  const [rowPerPage, setRowPerPage] = useState(10)
  const [forcePage, setForcePage] = useState<any>(null)
  const [selectedOrg, setSelectedOrg] = useState('')

  const [screenloader, setScreenloader] = useState<any>(false)
  const [companiesOptions, setCompaniesOptions] = useState<any[]>([])
  const [history, setHistory] = useState<any[]>([])
  const [selectedStatus, setSelectedStatus] = useState<{label: string; value: string}[]>([])

  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const currentItemss = filteredData.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(filteredData.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % filteredData.length
    setItemOffset(newOffset)
  }

  useEffect(() => {
    fetchOrg()
  }, [])

  const fetchOrg = async () => {
    try {
      const newList = await GetOrgList()
      setCompaniesOptions(newList)
    } catch (error) {
      AlertModal('Something went wrong!', '', 'error', false, '#7066E0', 'Ok')
    }
  }

  const fetchHistory = async (org: any) => {
    try {
      setScreenloader(true)
      const getHistory = await GetTrialExtensionHistoryByOrgId(org)
      setHistory(getHistory.data)
      setFilteredData(getHistory.data)
      setScreenloader(false)
    } catch (error) {
      AlertModal('Something went wrong!', '', 'error', false, '#7066E0', 'Ok')
      setScreenloader(false)
    }
  }

  const handleStatusChange = (statusValue: string) => {
    setScreenloader(true)
    if (statusValue === 'All') {
      setFilteredData(history)
      setScreenloader(false)
    } else {
      let updatedData = history.filter((item) => item.status === statusValue)
      setFilteredData(updatedData)
      setScreenloader(false)
    }
  }

  return (
    <>
      <div className='card-body'>
        <div className='card-header border-0 pt-5 pb-5'>
          <div className='d-flex justify-content-between align-items-center py-3 px-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Trial Extension History</span>
            </h3>
            <div className='d-flex'>
              <div className='d-flex' style={{marginRight: '2rem'}}>
                <span className='mt-2 fs-2 '>Org Name :&nbsp;</span>
                <div
                  id='select-member'
                  style={{width: '200px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search members'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    value={companiesOptions.filter((option: any) => option.value === selectedOrg)}
                    options={companiesOptions}
                    placeholder='Select organization'
                    onChange={(item: any) => {
                      setSelectedStatus([{label: 'All', value: 'All'}])
                      handleStatusChange('All')
                      setSelectedOrg(item.value)
                      fetchHistory(item.value)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
              </div>
              <div className='d-flex'>
                <span className='mt-2 fs-2'>Status :&nbsp;</span>
                <div
                  id='select-member'
                  style={{width: '200px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search members'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    value={selectedStatus}
                    isDisabled={history.length > 0 ? false : true}
                    options={[
                      {
                        label: 'All',
                        value: 'All',
                      },
                      {
                        label: 'Approved',
                        value: 'Approved',
                      },
                      {
                        label: 'Rejected',
                        value: 'Rejected',
                      },
                      {
                        label: 'Pending',
                        value: 'Pending',
                      },
                    ]}
                    placeholder='Select status'
                    onChange={(item: any) => {
                      setSelectedStatus(item)
                      handleStatusChange(item.value)
                      // handleUserChange(item.value)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {screenloader ? (
            Loader('100px')
          ) : (
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4' style={{border: '1px solid '}}>
                  <thead>
                    <tr
                      className='fw-bolder'
                      style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
                    >
                      <th></th>
                      <th className=' min-w-150px'>Start Date</th>
                      <th className=' min-w-125px'> End Date </th>
                      <th className='min-w-125px '>Extension Days</th>
                      <th className='min-w-125px '>Reason</th>
                      <th className='min-w-125px'>Status</th>
                      <th className='min-w-125px'>Status Reason</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentItemss.length > 0 &&
                      currentItemss.map((historyData: any, index: any) => (
                        <tr key={index}>
                          <td></td>

                          <td>
                            <span className='text-dark  d-flex mb-1 fs-6'>
                              {dayjs(historyData.trialStartDate).format('DD-MM-YYYY')}
                            </span>
                          </td>

                          <td>
                            <span className='text-dark  d-flex mb-1 fs-6'>
                              {dayjs(historyData.trialEndDate).format('DD-MM-YYYY')}
                            </span>
                          </td>

                          <td>
                            <span className='text-dark  d-flex mb-1 fs-6 '>
                              {historyData.extensionDays}
                            </span>
                          </td>

                          <td>
                            <span className='d-flex mb-1 fs-6 '>{historyData.requestReason}</span>
                          </td>

                          <td>
                            <span className='d-flex mb-1 fs-6 '>{historyData.status}</span>
                          </td>
                          <td>
                            <span className='d-flex mb-1 fs-6 '>{historyData.responseReason}</span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {screenloader
                  ? Loader('100px')
                  : history.length == 0 && <h2 className='noRecordFound'>No Records Found</h2>}
              </div>
              {filteredData.length > rowPerPage && (
                <div className='d-flex align-items-center justify-content-center position-relative mb-5'>
                  <div style={{left: '2rem', position: 'absolute', top: '0'}}>
                    {RowsPerPage(
                      setForcePage,
                      setRowPerPage,
                      setItemOffset,
                      rowPerPage,
                      history.length,
                      '0px'
                    )}
                  </div>
                  {history.length > rowPerPage && Pagination(handlePageClick, pageCount, forcePage)}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {TrialExtensionHistory}
