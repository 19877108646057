/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../_metronic/helpers'

import {Link} from 'react-router-dom'
import swal from 'sweetalert2'
import {
  Loader,
  createTooltipInput,
  Pagination,
  AlertModal,
} from '../../CommonFunctions/CommonFunction'
import {getAllAddedInformation, DeleteInformation} from '../../services/Information.service'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const DisplayInformation: React.FC = () => {
  const [allInformation, setAllInformation] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const tooltipContent = 'Search by Label Name'
  const inputPlaceholder = 'Search by Label Name'

  useEffect(() => {
    setSpinner(true)
    getAllAddedInformatino()
  }, [])

  const deleteHandler = (infoId: any) => {
    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Yes, delete it!'
    ).then(async (result: any) => {
      if (result.isConfirmed) {
        let updateStatus = await DeleteInformation(infoId)
        if (!updateStatus.data?.success) {
          AlertModal(
            'Something went wrong.',
            'Please try again later.',
            'error',
            false,
            '#7066E0',
            'Ok'
          )
        } else {
          AlertModal(
            updateStatus.data.message,
            '',
            updateStatus.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            getAllAddedInformatino()
          })
        }
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Entry not Deleted', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  const getAllAddedInformatino = async () => {
    try {
      const addInformation = await getAllAddedInformation()

      setAllInformation(addInformation.data.filter((entry: any) => entry.isActive !== false))
      setSpinner(false)
    } catch (error) {
      console.log(error)
    }
  }

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const filteredMembers = allInformation.filter((partnerData) =>
    partnerData.infoConfigName.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % allInformation.length
    setItemOffset(newOffset)
  }

  const currentPagePartner = filteredMembers.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(allInformation.length / itemsPerPage)

  const handleSearchChange = (name: string) => {
    setSearchQuery(name)
    setItemOffset(0)
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder mb-1' style={{fontSize: '19px'}}>
            Label Info
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>

        <div style={{display: 'flex'}}>
          {spinner ? '' : createTooltipInput(tooltipContent, inputPlaceholder, handleSearchChange)}

          <Tippy placement='top' content='Click to add a Information'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              <Link to='/allInformation/addInformation' className='btn btn-sm btn-primary me-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add New Label
              </Link>
            </div>
          </Tippy>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      {spinner ? (
        Loader('100px')
      ) : (
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                  </th>
                  <th className='min-w-120px'> Name</th>
                  <th className='min-w-120px'> Description</th>
                  <th className='min-w-120px'> Key</th>
                  <th className='min-w-175px text-center'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentPagePartner.map((names) => (
                  <tr key={names.partnerId}>
                    <td></td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column '>
                          {names.infoConfigName}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column '>
                          {names.infoValue}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column '>
                          {names.infoKey}
                        </div>
                      </div>
                    </td>

                    <td style={{width: '10rem'}}>
                      <div className='d-flex justify-content-evenly flex-shrink-0'>
                        <Tippy placement='top' content='Edit'>
                          <Link
                            to='/allInformation/editInformation'
                            onClick={() => {
                              localStorage.setItem('editConfig', names.id)
                            }}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </Link>
                        </Tippy>

                        <Tippy placement='top' content='Delete department'>
                          <a
                            onClick={() => deleteHandler(names.id)}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                              names.isActive === false ? 'disabled' : ''
                            }`}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </Tippy>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            <div className='d-flex flex-column'>
              {allInformation.length > 10 && (
                <div className='d-flex align-items-center justify-content-center'>
                  {Pagination(handlePageClick, pageCount, 0)}
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DisplayInformation
