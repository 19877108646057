import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../redux/AuthCRUD'
import {AlertModal, passwordCreateResetForm} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'
import * as auth from '../redux/AuthRedux'
import {userInfoActions} from '../redux/UserInfoRedux'
import {CheckExpiry} from '../../../services/common.services'

const API_URL = process.env.REACT_APP_API_URL

let name: any
export function CreatePassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, _] = useState<boolean | undefined>(undefined)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const email = searchParams.get('email')
    name = searchParams.get('name')
    formik.setFieldValue('email', email)
    formik.setFieldValue('name', name)

    const currentUrl = window.location.href
    const tokenPattern = /token=([^&]*)/
    const match = currentUrl.match(tokenPattern)
    let matchedToken = ''
    if (match) {
      matchedToken = match[1]
    }

    CheckExpiry(matchedToken).then((res: any) => {
      if (res.data === false) {
        AlertModal('Link expired!', '', 'error', false, '#7066E0', 'Ok').then(() => {
          navigate('/auth')
        })
      } else if (email) {
        searchParams.delete('email')
        searchParams.delete('name')
        setSearchParams(searchParams)
      }
    })
  }, [])

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
  }

  const CreatePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long.')
      .max(16, 'The password should not exceed 16 characters.')
      .matches(/[0-9]/, 'Password must contain at least one number.')
      .matches(/[a-z]/, 'Password requires at least one lowercase letter.')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
      .matches(/[^\w]/, 'Password must include at least one symbol.')
      .required('Password Field is required.'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Must match "Password" field value'
    ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: CreatePasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setTimeout(() => {
        axios
          .post(`${API_URL}/Login/CreatePassword`, values)
          .then((res) => {
            AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok').then(() => {
              if (res.data.success) {
                login(values.email, values.password)
                  .then((res) => {
                    let accessToken = res.data.accessToken
                    if (res.data.success === false) {
                      setSubmitting(false)
                      setStatus(res.data.message)
                      setLoading(false)
                    } else {
                      localStorage.setItem('Email', res.data.email)
                      localStorage.setItem('role', res.data.roleName)
                      localStorage.setItem('userId', res.data.userId)
                      localStorage.setItem('org_Id', res.data.organizationId)
                      localStorage.setItem('org_name', res.data.organizationName)
                      localStorage.setItem('name', res.data.fullName)
                      localStorage.setItem('ProjTaskConfig', res.data.projectTaskConfig)
                      localStorage.setItem('cutOffTime', res.data.userCutOffTime)
                      localStorage.setItem('timezone', res.data.orgTimeZoneId)
                      localStorage.setItem('tour', 'true')
                      setStatus('')
                      setSubmitting(false)
                      setLoading(false)
                      dispatch(auth.actions.login(accessToken))
                      dispatch(userInfoActions.updateUserInfo(res.data))
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    setLoading(false)
                    setSubmitting(false)
                    setStatus('The login detail is incorrect')
                  })
              }
            })

            resetForm()
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            setSubmitting(false)

            setStatus('Registration process has broken')
          })
      }, 0)
    },
  })

  return (
    <>
      {passwordCreateResetForm(formik, 'Create Your Password', name, hasErrors, loading, 'Submit')}
    </>
  )
}
