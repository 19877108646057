import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Accordian from '../Component/Accordian'
import axios from 'axios'
import swal from 'sweetalert2'
import {memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import {manipulateResponseData} from '../Common_Function/Function'
import {AlertModal, Loader} from '../../CommonFunctions/CommonFunction'

const DisplayAllocate: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [projectData, setProjectData] = useState<any[]>([])
  const [memberOption, setMemberOption] = useState<any[]>([])
  const [memberSelected, setMemberSelected] = useState<any>({})
  const [screenloader, setScreenLoader] = useState<boolean>(false)

  useEffect(() => {
    getTaskByUserID()
  }, [])

  useEffect(() => {
    memberInfoByOrg().then((res) => {
      const newList = manipulateResponseData(res.data)
      setMemberOption(newList)
    })

    setMemberSelected({
      label: localStorage.getItem('name'),
      value: localStorage.getItem('userId'),
    })
  }, [])

  const getTaskByUserID = (userId?: any) => {
    setScreenLoader(true)
    axios
      .get(`${API_URL}/AllocateTask/GetAllAllocatedTasksByUserId`, {
        params: {
          orgId: localStorage.getItem('org_Id'),
          userId: userId ? userId : localStorage.getItem('userId'),
        },
      })
      .then((res) => {
        setProjectData(res.data)
      })
      .catch((err) => console.error(err))
      .finally(() => setScreenLoader(false))
  }

  const deleteHandler = (taskId: any) => {
    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Delete'
    ).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .patch(`${API_URL}/AllocateTask/DeleteUserAllocatedTasks?allocatedTaskId=${taskId}`)
          .then((res) => {
            if (res.data.success === true) getTaskByUserID(memberSelected.value)
            AlertModal(
              res.data.message,
              '',
              res.data.success === false ? 'error' : 'success',
              false,
              '#7066E0',
              'Ok'
            )
          })
          .catch((err) => {
            console.error(err)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Project not deleted', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  return (
    <>
      <div className='card card-xxl-stretch mb-5'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Allocated Task</span>
          </h3>

          <div className='d-flex justify-content-center align-items-center'>
            <div className='min-w-200px mx-5'>
              <Select
                className='selectDropdown'
                components={makeAnimated()}
                value={memberSelected}
                options={memberOption}
                placeholder='Select Member'
                onChange={(item: any) => {
                  getTaskByUserID(item.value)
                  setMemberSelected(item)
                }}
                isClearable={false}
                isSearchable={true}
                closeMenuOnScroll={true}
              />
            </div>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to Allocate a Task'
            >
              <Link
                to={
                  Object.keys(memberSelected).length > 0
                    ? `/task/allocation?user=${memberSelected.value}`
                    : '/task/allocation'
                }
                className='btn btn-sm btn-primary me-3'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Allocate Task
              </Link>
            </div>
          </div>
        </div>
        <div className='card-body py-3 mx-7 mb-5'>
          {projectData && <Accordian data={projectData} deleteFun={deleteHandler} />}
          {screenloader && Loader('100px')}
          {!screenloader && projectData.length === 0 && (
            <h2 className='noRecordFound'>No Records Found</h2>
          )}
        </div>
      </div>
    </>
  )
}

export default DisplayAllocate
