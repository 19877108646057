import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getMembersByDepartment(department: any) {
  const orgId = localStorage.getItem('org_Id')
  const deptId = department

  return axios.get(`${API_URL}/Registration/GetUsersByDepartment`, {
    params: {
      orgId,
      deptId,
    },
  })
}

export function UnlockUserDevice(userId: any) {
  let organizationId = localStorage.getItem('org_Id')
  return axios.post(`${API_URL}/Registration/UnblockUserMachineLogin`, {
    userId,
    organizationId,
  })
}
