import Tippy from '@tippyjs/react'
import React, {useEffect, useState} from 'react'
import {GetTrialPendingRequest} from '../../services/trialExtension.services'
import {AlertModal, Loader} from '../../CommonFunctions/CommonFunction'
import dayjs from 'dayjs'
import {TrialPopup} from './trialExtensionPopUp'

const TrialExtensionRequest: React.FC = () => {
  const [spinner, setSpinner] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])
  const [request, setRequest] = useState<any[]>([])
  const [reqDetials, setReqDetials] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [status, setStatus] = useState('')
  useEffect(() => {
    getList()
  }, [])

  const getList = async () => {
    try {
      setSpinner(true)
      const getRequest = await GetTrialPendingRequest()
      setRequest(getRequest.data)
      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      AlertModal('Something went wrong!', '', 'error', false, '#7066E0', 'Ok')
    }
  }

  // const updateStatus = async (data: any, status: string) => {
  //   console.log('the data and the status is ', data, status)
  //   // try {
  //   //   setSpinner(true)
  //   //   let body = {
  //   //     trialRequestId: data.trialRequestId,
  //   //     organizationId: data.organizationId,
  //   //     reason: data.reason,
  //   //     status,
  //   //   }

  //   //   const updateStatus = await UpdateTrialRequest(body)
  //   //   AlertModal(
  //   //     updateStatus.data.message,
  //   //     '',
  //   //     updateStatus.data.success ? 'success' : 'error',
  //   //     false,
  //   //     '#7066E0',
  //   //     'Ok'
  //   //   ).then(() => {
  //   //     getList()
  //   //   })
  //   // } catch (error) {
  //   //   setSpinner(false)
  //   //   AlertModal('Something went wrong!', '', 'error', false, '#7066E0', 'Ok')
  //   // }
  // }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  const handleOpenModal = (data: any, status: string) => {
    setReqDetials(data)
    setStatus(status)
    setIsModalOpen(true)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Trial Extension Request</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
        </div>

        {/* end::Header */}

        {/* begin::Body */}
        {spinner && Loader('0')}

        {!spinner && (
          <div className={`card-body py-3 ${list.length > 0 ? 'mb-3' : 'mb-18'}`}>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder'>
                    <th></th>
                    <th className='w-275px'>Organization</th>
                    <th className='w-150px'>Existing Start Date </th>
                    <th className='w-150px'>Existing End Date </th>
                    <th className='w-125px'>Extension Days</th>
                    <th className='w-125px'>Reason</th>
                    <th className='w-150px'>Extension End Date </th>
                    <th className='w-250px text-center'>Action</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {request.length > 0 &&
                    request.map((details) => (
                      <tr key={details.trialRequestId}>
                        <td></td>

                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {details.organizationName}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {dayjs(details.effectiveFrom).format('DD-MM-YYYY')}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {dayjs(details.terminationDate).format('DD-MM-YYYY')}
                          </span>
                        </td>

                        <td>
                          <span className='text-dark  d-block fs-6'>{details.requestDays}</span>
                        </td>

                        <td>
                          <span className='text-dark d-block fs-6'>{details.requestReason}</span>
                        </td>

                        <td>
                          <span className='text-dark d-block fs-6'>
                            {dayjs(details.trialEndDate).format('DD-MM-YYYY')}
                          </span>
                        </td>

                        <td>
                          <div className='d-flex justify-content-evenly flex-shrink-0'>
                            <Tippy placement='top' content='Approve Request'>
                              <button
                                className='btn btn-primary'
                                // onClick={() => updateStatus(details, 'Approved')}
                                onClick={() => handleOpenModal(details, 'Approved')}
                              >
                                Approve
                              </button>
                            </Tippy>

                            <Tippy placement='top' content='Reject request'>
                              <button
                                className='btn btn-danger'
                                // onClick={() => updateStatus(details, 'Rejected')}
                                onClick={() => handleOpenModal(details, 'Rejected')}
                              >
                                Reject
                              </button>
                            </Tippy>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>

              {request.length <= 0 && (
                <span className='position-absolute w-100'>
                  <h2 className='noRecordFound'>No Records Found</h2>
                </span>
              )}
            </div>

            {/* end::Table */}

            {/* end::Table container */}
          </div>
        )}

        <div>
          {isModalOpen && (
            <TrialPopup
              getList={getList}
              onClose={handleCloseModal}
              //updateStatus={updateStatus}
              barDate={new Date().toDateString()}
              selectedUser={'selectedUser'}
              series={'series'}
              data={reqDetials}
              status={status}
            ></TrialPopup>
          )}
        </div>
      </div>
    </>
  )
}

export default TrialExtensionRequest
