import React, {FC, useEffect, useState} from 'react'
import '../../../css/checkoutPopUp.css'
import 'tippy.js/dist/tippy.css'

import {Link, useNavigate} from 'react-router-dom'
import '../../../css/plans.css'
import dayjs from 'dayjs'

interface PopupProps {
  title: string
  members: any
  selectedDate: any
  onClose: () => void // Function to close the modal
}

const UserList: FC<PopupProps> = ({title, members, onClose, selectedDate}) => {
  const [userList, setUserList] = useState<any[]>([])
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    if (title === 'Total Members') {
      setUserList(members?.allMembers || [])
    } else if (title === 'Offline Members') {
      setUserList(members?.offlineMembers || [])
    } else if (title === 'Active Members') {
      setUserList(members?.activeMembers || [])
    }
  }, [title, members])

  const handleIconClick = () => {
    onClose()
  }

  return (
    <div className='modal-container d-flex'>
      <div style={{margin: 'auto', paddingLeft: '200px'}}>
        <div
          className='card mt-3 screenshot-modal-innerContainer'
          style={{padding: '0rem 2.5rem 2rem 2.5rem'}}
        >
          <div className='card mt-5' style={{height: '28rem'}}>
            <div
              className='card-header card-header-stretch border-bottom border-gray-200'
              style={{padding: 'unset'}}
            >
              <span style={{paddingTop: '1.5rem', fontSize: '1.5rem', fontWeight: '600'}}>
                {title}
              </span>
              <i
                className='fa fa-times'
                onClick={() => handleIconClick()}
                aria-hidden='true'
                style={{
                  paddingTop: '1.5rem',
                  fontSize: '2rem',
                  // fontSize: '2rem',
                  // marginLeft: '22rem',
                  // paddingTop: '1.5rem',
                  cursor: 'pointer',
                }}
              ></i>
            </div>
            <div className='user-popup-container overflow-auto'>
              <div className='d-flex space-between ' id='screenshot-container'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <tbody>
                    {members.length > 0 &&
                      members.map((user: any, index: any) => (
                        <tr key={index}>
                          <td></td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column cursor-pointer fs-5'>
                                <Link
                                  to={`/report/timeTracker/?source=teamdashboard&name=${
                                    user.userName
                                  }&user=${user.userId}&date=${dayjs(selectedDate).format(
                                    'DD/MM/YYYY'
                                  )}`}
                                  className='text-dark text-hover-primary fs-7'
                                >
                                  {user.userName}
                                </Link>
                              </div>
                            </div>
                          </td>

                          <td></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
          .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
            animation: fadeIn 0.5s ease;
            
          }

          #div-scroller::-webkit-scrollbar-thumb {
            background-color: #C1C1C1 !important;
          }
          #div-scroller::-webkit-scrollbar {
            width:0.5rem
          }



          .screenshot-modal-innerContainer {
            
            margin: 0 auto;
            background-color: #fff;
            border-radius: 6px;
            padding: 20px;
            position: relative;
            width:23rem;
            height:30rem
          }

        .custom-modal-header{
            padding-right: 29rem;
            font-size: 1.8rem;
            font-weight: 700;
        }

        .paginationBttns{
            width:23rem;
            margin: 2rem auto 0 auto;
        }

        .temp-class{
            padding: 0.5rem;
            width: 8rem;
            margin: 0;
            justify-content:center;
            margin:unset
        }

        #screenshot-container{
            flex-wrap: wrap !important;
        }


        `}</style>
    </div>
  )
}

export {UserList}
