import React, {useState, useEffect} from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import swal from 'sweetalert2'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import {
  Pagination,
  Loader,
  MultiDateTimePicker,
  AlertModal,
} from '../../CommonFunctions/CommonFunction'
import {GetAllRequestByOrgForAdmin} from '../../services/TimeClaim.services'

const API_URL = process.env.REACT_APP_API_URL

const UpdateTimeClaim = () => {
  const [spinner, setSpinner] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [dateError, setDateError] = useState(true)
  const [rawTimeData, setRawTimeData] = useState<any[]>([])
  const [timeData, setTimeData] = useState<any[]>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [memberOption, setMemberOption] = useState<any>([])
  const [forcePage, setForcePage] = useState<any>()
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const [activityStatusData, setActivityStatusData] = useState<any>([])
  const [emptyActivityStatusData, setEmptyActivityStatusData] = useState<any>([])

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  const currentItemss = timeData.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(timeData.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    setIsCheckAll(false)
    setIsCheck([])
    setActivityStatusData([])
    setEmptyActivityStatusData([])
    const newOffset = (event.selected * itemsPerPage) % timeData.length
    setItemOffset(newOffset)
    setCurrentPage(event.selected)
  }

  useEffect(() => {
    setSpinner(true)
    getAllTimeClaims(startDate, endDate)
  }, [])

  const getAllTimeClaims = async (fromDate: any, toDate: any) => {
    if (!fromDate) {
      return
    }

    const role = localStorage.getItem('role')

    setSpinner(true)
    let body = {
      userId: localStorage.getItem('userId'),
      organizationId: localStorage.getItem('org_Id'),
      fromDate: dayjs(fromDate).format('YYYY-MM-DDT00:00:00[Z]'),
      toDate: toDate
        ? dayjs(toDate).format('YYYY-MM-DDT00:00:00[Z]')
        : dayjs(fromDate).format('YYYY-MM-DDT00:00:00[Z]'),
    }

    let allTimeClaims
    if (role === process.env.REACT_APP_SYSTEM_FIRST_ROLE) {
      const getAllRequest = await GetAllRequestByOrgForAdmin(
        dayjs(fromDate).format('YYYY-MM-DDT00:00:00[Z]'),
        toDate
          ? dayjs(toDate).format('YYYY-MM-DDT00:00:00[Z]')
          : dayjs(fromDate).format('YYYY-MM-DDT00:00:00[Z]')
      )

      const uniqueUsers = new Map()

      getAllRequest.data.forEach((item: any) => {
        uniqueUsers.set(item.userId, {
          label: item.userName,
          value: item.userId,
        })
      })

      const newList = Array.from(uniqueUsers.values())
      if (newList.length > 0) {
        newList.unshift({
          label: 'All',
          value: 'All',
        })
        setOptionSelectedMember({
          label: 'All',
          value: 'All',
        })
      }

      setMemberOption(newList)
      setTimeData(getAllRequest.data)
      setRawTimeData(getAllRequest.data)
    } else {
      allTimeClaims = await axios.post(`${API_URL}/TimeClaim/GetClaimTimeForManager`, body)
      const uniqueUsers = new Map()
      allTimeClaims.data.forEach((item: any) => {
        uniqueUsers.set(item.userId, {
          label: item.userName,
          value: item.userId,
        })
      })
      const newList = Array.from(uniqueUsers.values())
      if (newList.length > 0) {
        newList.unshift({
          label: 'All',
          value: 'All',
        })
        setOptionSelectedMember({
          label: 'All',
          value: 'All',
        })
      }

      setMemberOption(newList)
      setTimeData(allTimeClaims.data)
      setRawTimeData(allTimeClaims.data)
    }

    setSpinner(false)
  }

  useEffect(() => {
    startDate ? setDateError(false) : setDateError(true)
  }, [startDate])

  const updateTimeClaimStatus: any = async (status: any) => {
    let url = ''
    let body = {}
    let updateStatus: any

    if (activityStatusData.length > 0) {
      url = 'UpdateStatusOfTimeClaimForStoppedorOfflineList'
      activityStatusData.map((item: any) => (item.claimStatus = status))
      body = activityStatusData
      updateStatus = await axios.post(`${API_URL}/TimeClaim/${url}`, body)
    }
    if (emptyActivityStatusData.length > 0) {
      url = 'UpdateStatusOfTimeClaimList'
      emptyActivityStatusData.map((item: any) => (item.claimStatus = status))
      body = emptyActivityStatusData
      console.log(body)
      updateStatus = await axios.post(`${API_URL}/TimeClaim/${url}`, body)
    }

    setIsCheck([])
    setIsCheckAll(false)
    setActivityStatusData([])
    setEmptyActivityStatusData([])
    getAllTimeClaims(startDate, endDate)
    AlertModal(updateStatus.data.message, '', 'success', false, '#7066E0', 'Ok')
  }

  const handleDateChange = (dates: any) => {
    setOptionSelectedMember([])
    setTimeData([])
    setRawTimeData([])
    setItemOffset(0)
    setCurrentPage(0)
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    setIsCheckAll(false)
    setIsCheck([])
    getAllTimeClaims(start, end)
    setForcePage(null)
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      width: '15rem',
    }),
  }

  const handleSelectedMemberChange = (user: any) => {
    setSpinner(true)
    setTimeout(() => {
      setOptionSelectedMember(user)
      setItemOffset(0)
      setCurrentPage(0)
      setForcePage(null)
      if (user.value === 'All') {
        setTimeData(rawTimeData)
      } else {
        const userData = rawTimeData.filter((item) => {
          return item.userId === user.value
        })

        setTimeData(userData)
      }
      setSpinner(false)
    }, 1000)
  }

  const handleSelectAll = () => {
    setIsCheck([])
    setActivityStatusData([])
    setEmptyActivityStatusData([])
    if (isCheckAll === false) {
      setIsCheck(currentItemss.map((li: any) => li.timeClaimId))
      currentItemss?.filter((item: any) => {
        const body = {
          claimStatus: '',
          organizationId: localStorage.getItem('org_Id'),
          timeClaimId: item.timeClaimId,
          userId: item.userId,
          managerId: localStorage.getItem('userId'),
          fromTime: dayjs(item.fromTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          toTime: dayjs(item.toTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          requestDate: dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        }
        if (item.activityStatus == 'Stopped' || item.activityStatus == 'Offline') {
          setActivityStatusData((prev: any) => [...prev, body])
        } else {
          setEmptyActivityStatusData((prev: any) => [...prev, body])
        }
      })
    }
    setIsCheckAll(!isCheckAll)
  }

  const handleClick = (e: any, data: any) => {
    const {id, checked} = e.target
    const {userId, timeClaimId, fromTime, toTime, activityStatus} = data
    const body = {
      claimStatus: '',
      organizationId: localStorage.getItem('org_Id'),
      timeClaimId: timeClaimId,
      userId,
      managerId: localStorage.getItem('userId'),
      fromTime: dayjs(fromTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      toTime: dayjs(toTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      requestDate: dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
    }
    if (checked) {
      setIsCheck((prev: any) => [...prev, id])
      if (activityStatus === 'Stopped' || activityStatus === 'Offline') {
        setActivityStatusData((prev: any) => [...prev, body])
      } else {
        setEmptyActivityStatusData((prev: any) => [...prev, body])
      }
    }
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id))
      if (activityStatus === 'Stopped' || activityStatus === 'Offline') {
        setActivityStatusData(
          activityStatusData.filter((item: any) => item.timeClaimId !== timeClaimId)
        )
      } else {
        setEmptyActivityStatusData(
          emptyActivityStatusData.filter((item: any) => item.timeClaimId !== timeClaimId)
        )
      }
    }
    if (isCheck) {
      setIsCheckAll(false)
    }
    // setTimeout(() => {
    //   if (isCheck.length == currentItemss.length - 1) {
    //     setIsCheckAll(true)
    //   }
    // }, 0)
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Time Claim Request</span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>

        <div className='d-flex' style={{justifyContent: 'center', alignItems: 'center'}}>
          <div
            style={{marginRight: '10px'}}
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title={'Search Member'}
          >
            <Select
              components={makeAnimated()}
              value={optionSelectedMember}
              options={memberOption}
              placeholder='Select Member'
              onChange={(item: any) => {
                handleSelectedMemberChange(item)
              }}
              isClearable={false}
              isSearchable={true}
              closeMenuOnScroll={true}
              styles={customStyles}
              isDisabled={memberOption.length === 0}
            />
          </div>
          <div className='date-picker-admin-individual muted' id='select-date'>
            {MultiDateTimePicker(startDate, endDate, handleDateChange, 'form-control ss_date')}

            {dateError && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2 mx-8'>Select a date</div>
              </div>
            )}
          </div>{' '}
        </div>
      </div>
      {spinner ? (
        Loader('0')
      ) : (
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder'>
                  <th></th>
                  <th className='min-w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='selectAll'
                        id='selectAll'
                        onChange={handleSelectAll}
                        checked={isCheckAll}
                      />
                    </div>
                  </th>
                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-100px'>Date</th>
                  <th className='min-w-100px'>Time Zone</th>
                  <th className='min-w-100px'>Start Time</th>
                  <th className='min-w-100px'>End Time</th>
                  <th className='min-w-150px'>Reason</th>
                  {/* <th className='min-w-150px text-center'>Actions </th> */}
                </tr>
              </thead>
              <tbody>
                {currentItemss.map((time) => (
                  <tr key={time.timeClaimId}>
                    <td></td>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-13-check'
                          key={time.timeClaimId}
                          type='checkbox'
                          id={time.timeClaimId}
                          onChange={(e) => handleClick(e, time)}
                          checked={isCheck.includes(time.timeClaimId)}
                        />
                      </div>
                    </td>
                    <td>
                      <span className='text-dark  d-block fs-6 '>{time.userName}</span>
                    </td>
                    <td>
                      <span className='text-dark  d-block fs-6 ' style={{width: '6rem'}}>
                        {dayjs(time.recordDate).format('DD/MM/YYYY')}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark d-flex mb-1 fs-6'>{time.timeZone}</span>
                    </td>
                    <td>
                      <span className='text-dark  d-block fs-6'>
                        {dayjs(time.fromTime).format('HH:mm')}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark d-block fs-6'>
                        {dayjs(time.toTime).format('HH:mm')}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark d-block fs-6'>{time.reason}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {!spinner && timeData.length === 0 && (
              <h2 className='noRecordFound user-select-none'>No Request Found</h2>
            )}

            <div className='d-flex justify-content-end py-3 px-9'>
              {!spinner && (
                <div className='d-flex justify-content-evenly flex-shrink-0'>
                  <Tippy placement='top' content='Approve Request'>
                    <button
                      disabled={isCheck.length == 0}
                      className='btn btn-primary align-items-center me-3'
                      onClick={() => updateTimeClaimStatus('Approved')}
                    >
                      Approve
                    </button>
                  </Tippy>

                  <Tippy placement='top' content='Reject request'>
                    <button
                      disabled={isCheck.length == 0}
                      className='btn align-items-center bg-danger text-white'
                      onClick={() => updateTimeClaimStatus('Rejected')}
                    >
                      Reject
                    </button>
                  </Tippy>
                </div>
              )}
            </div>

            <div className='d-flex flex-column'>
              {timeData.length > 10 && (
                <div className='d-flex align-items-center justify-content-center'>
                  {Pagination(handlePageClick, pageCount, currentPage)}
                </div>
              )}
            </div>

            <style>
              {`
                    .ss_date:{
                      width:201px !important;
                    }
                        .paginationBttns{
                            
                            margin-bottom:0;
                        }

                        .custom-select-class{
                          text-align: center;
                          width: 8rem;
                        }

                        .select-custom-header{
                          margin-right: 1rem;
                          line-height: 3rem;
                        }

                        .pagination-custom-class{

                        }
                        
                    `}
            </style>
          </div>
        </div>
      )}
    </div>
  )
}

export {UpdateTimeClaim}
