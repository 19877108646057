import React from 'react'

import {Link, Outlet, useLocation} from 'react-router-dom'
import '../../../css/ProductivityManager.css'

const ProjectTaskWrapper: React.FC = () => {
  const location = useLocation()

  return (
    <>
      <div className='card mb-5'>
        <div className='card-body pt-3' style={{padding: '0px 25px'}}>
          <div className='d-flex overflow-auto'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={`nav-link text-active-primary me-6 ${
                    location.pathname === '/type/typeProject' || location.pathname === '/type'
                      ? 'text-dark active-wrapper'
                      : 'text-active-primary fw-bolder'
                  }`}
                  to='/type/typeProject'
                >
                  Project Type
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={`nav-link text-active-primary me-6 ${
                    location.pathname === '/type/typeTask'
                      ? 'text-dark inactive-wrapper'
                      : 'text-active-primary fw-bolder'
                  } `}
                  to='/type/typeTask'
                >
                  Task Type
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export {ProjectTaskWrapper}
