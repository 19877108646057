import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios from 'axios'
import swal from 'sweetalert2'
import {AlertModal, Loader, Pagination, RowsPerPage} from '../../CommonFunctions/CommonFunction'

const DisplayTask: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [projects, setProjects] = useState<any[]>([])
  const [projectTask, setProjectTask] = useState<any[]>([])
  const [defaultOrg, setDefaultOrg] = useState<any>([])
  const [screenloader, setScreenLoader] = useState<boolean>(false)
  const [rowPerPage, setRowPerPage] = useState(10)

  const deleteHandler = (taskId: any) => {
    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Delete'
    ).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .patch(`${API_URL}/UserTask/DeleteProjectTask?taskId=${taskId}`)
          .then((res) => {
            if (res.data.success === true) getTaskByProjectID(defaultOrg.value)
            AlertModal(
              res.data.message,
              '',
              res.data.success === false ? 'error' : 'success',
              false,
              '#7066E0',
              'Ok'
            )
          })
          .catch((err) => {
            console.error(err)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Project not deleted', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  useEffect(() => {
    axios
      .get(`${API_URL}/Projects/GetProjectByOrganisation`, {
        params: {
          id: localStorage.getItem('org_Id'),
        },
      })
      .then((res: any) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.projectName,
            value: item.projectId,
          }
        })
        setProjects(newList)
        getTaskByProjectID(res.data[0].projectId)
        setDefaultOrg([{label: res.data[0].projectName, value: res.data[0].projectId}])
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [])

  const getTaskByProjectID = (projectId: any) => {
    axios
      .get(`${API_URL}/UserTask/GetTaskListByProjectId`, {
        params: {
          Project_Id: projectId,
        },
      })
      .then((res) => {
        setProjectTask(res.data)
        setScreenLoader(false)
      })
      .catch((err) => console.error(err))
  }

  //Pagination
  let itemsPerPage: number = rowPerPage
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState<any>()

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(projectTask.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(projectTask.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, projectTask])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % projectTask.length
    setItemOffset(newOffset)
  }

  return (
    <>
      <div className='card card-xxl-stretch mb-5'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Task</span>
          </h3>
          <div className='d-flex justify-content-center align-items-center'>
            <div className='min-w-200px mx-5'>
              <Select
                className='selectDropdown'
                components={makeAnimated()}
                value={defaultOrg}
                options={projects}
                placeholder='Select Project'
                onChange={(item: any) => {
                  getTaskByProjectID(item.value)
                  setDefaultOrg(item)
                }}
                isClearable={false}
                isSearchable={true}
                closeMenuOnScroll={true}
              />
            </div>

            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to Create a Task'
            >
              <Link to='/task/creation' className='btn btn-sm btn-primary me-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Create Task
              </Link>
            </div>
          </div>
        </div>
        <div className='card-body py-3 mx-7 mb-5'>
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-dark'>
                  <th> </th>
                  <th className='min-w-125px'>Task Name</th>
                  {/* <th className='min-w-125px'>Start Date</th>
                  <th className='min-w-125px'>End Date</th>
                  <th className='min-w-125px'>Estimate Hours</th> */}
                  <th className='min-w-150px text-center'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {currentItems?.map((item: any) => (
                  <tr key={item.taskId}>
                    <td></td>

                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {item.taskName}
                        </div>
                      </div>
                    </td>
                    {/* <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {dayjs(item.startDate).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {dayjs(item.endDate).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {item.estimatedHours}
                        </div>
                      </div>
                    </td> */}

                    <td>
                      <div className='d-flex flex-shrink-0 justify-content-evenly'>
                        <Link
                          to={`/task/editTask?taskId=${item.taskId}`}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </Link>
                        <a
                          onClick={() => {
                            deleteHandler(item.taskId)
                          }}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {screenloader && Loader('100px')}
            {!screenloader && currentItems.length === 0 && (
              <h2 className='noRecordFound'>No Records Found</h2>
            )}
            {/* end::Table */}
          </div>

          {projectTask.length > 10 && (
            <div className='d-flex align-items-center justify-content-center position-relative mb-7'>
              <div style={{left: '0', position: 'absolute', top: '0'}}>
                {RowsPerPage(
                  setForcePage,
                  setRowPerPage,
                  setItemOffset,
                  rowPerPage,
                  projectTask.length,
                  '1rem'
                )}
              </div>

              {projectTask.length > rowPerPage && Pagination(handlePageClick, pageCount, forcePage)}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DisplayTask
