import React, {useCallback, useEffect, useState} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {GetAssignee} from '../../services/GetAllAssinee.services'
import {memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import {Loader} from '../../CommonFunctions/CommonFunction'
import {HubConnection, HubConnectionBuilder, HubConnectionState} from '@microsoft/signalr'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {userInfoActions} from '../../modules/auth'

const LiveStreaming: React.FC = () => {
  const role = localStorage.getItem('role')
  const dispatch = useDispatch()
  const SelectedUser: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [memberOption, setMemberOption] = useState<any>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [hubConnection, setHubConnection] = useState<HubConnection>()
  const [userState, setUserState] = useState<boolean>(false)
  const [image, setImage] = useState<any>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [userOffline, setUserOffline] = useState<boolean>(false)
  const orgId = localStorage.getItem('org_Id')
  const liveStreamURL: string = process.env.REACT_APP_LIVE_STREAM_URL!

  const initializeConnection = (): HubConnection | null => {
    try {
      const connection = new HubConnectionBuilder().withUrl(liveStreamURL).build()

      return connection
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    const connection = initializeConnection()
    if (connection) {
      connection
        .start()
        .then(() => {
          setHubConnection(connection)
        })
        .catch((err) => console.error('Error establishing connection:', err))
    }

    return () => {
      if (hubConnection) {
        stop(optionSelectedMember.value)
        hubConnection.stop()
      }
      if (connection) {
        connection.stop()
      }
    }
  }, [])

  const tryReconnect = () => {
    const maxReconnectAttempts = 5
    let reconnectAttempts = 0

    if (window.navigator.onLine) {
      for (
        reconnectAttempts;
        reconnectAttempts < maxReconnectAttempts &&
        hubConnection?.state === HubConnectionState.Disconnected;
        reconnectAttempts++
      ) {
        hubConnection.start()
        setHubConnection(hubConnection)
      }
    }
  }

  useEffect(() => {
    if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
      GetAssignee()
        .then((res) => {
          const newList = res.data
            .filter((item: any) => item.assigneeId !== localStorage.getItem('userId'))
            .map((item: any) => ({
              label: item.assigneeName,

              value: item.assigneeId,
            }))

          setMemberOption(newList)
        })
        .catch((err) => {
          console.log(err)
        })
    } else if (
      role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    ) {
      memberInfoByOrg()
        .then((res) => {
          // setMembers(res.data)
          const newList = res.data
            .filter((item: any) => item.userId !== localStorage.getItem('userId'))
            .map((item: any) => {
              return {
                label: item.fullName,
                value: item.userId,
              }
            })

          setMemberOption(newList)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [])

  useEffect(() => {
    if (hubConnection?.state === HubConnectionState.Connected) {
      hubConnection.on('OnStreamCastDataReceived', (stream) => {
        setImage(stream)
        setUserState(true)
      })

      hubConnection.on('UserOffLineStatus', UserOffLineStatus)
    }
  }, [hubConnection])

  const UserOffLineStatus = useCallback((data: string) => {
    setUserOffline(data === 'true' ? true : false)
  }, [])

  const start = async (id: string) => {
    await setUserOffline(false)
    if (id === undefined) {
      await setUserState(false)
      setLoading(false)
      return
    }

    if (hubConnection?.state === HubConnectionState.Connected) {
      hubConnection
        .invoke('AddScreenCastViewer', id)
        .then(() => setUserState(true))
        .catch((err: Error) => console.error(err))

      hubConnection.invoke('JoinScreenCastGroup', id).catch((err: Error) => console.error(err))
    }
    setLoading(false)
  }

  const stop = async (id: string) => {
    await setImage('')

    if (id === undefined) return

    if (hubConnection?.state === HubConnectionState.Connected) {
      hubConnection.invoke('RemoveScreenCastGroup', id).catch((err: Error) => console.error(err))

      hubConnection
        .invoke('CheckScreenCastViewerExists', id, id)
        .catch((err: Error) => console.error(err))
    }
  }

  const handleUserChange = async (user: any) => {
    setLoading(true)
    if (orgId !== '6656c5bd282c1dfc1ab6d44f') {
      await setUserState(true)
      if (hubConnection?.state === HubConnectionState.Connected) {
      } else {
        tryReconnect()
      }

      stop(optionSelectedMember.value)

      setTimeout(() => {
        start(user.value)
      }, 100)
    }
    setOptionSelectedMember(user)
  }

  useEffect(() => {
    if (hubConnection?.state == 'Connected') {
      if (SelectedUser.selectedUserId && SelectedUser.selectedUserId !== SelectedUser.userId) {
        let user = {
          label: SelectedUser.selectedUserName,
          value: SelectedUser.selectedUserId,
        }
        handleUserChange(user)
      } else if (memberOption.length > 0) {
        handleUserChange(memberOption[0])
      }
    }
  }, [hubConnection])

  return (
    <>
      <div className={`card`}>
        <div className='card-body'>
          <div className='tab-content pt-3'>
            {/* begin::Header */}
            <div className='card-title align-items-start flex-column'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Live Stream</span>
                </h3>

                <div className='d-flex align-items-center'>
                  <div
                    style={{width: '200px'}}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Member'
                  >
                    <Select
                      components={makeAnimated()}
                      value={optionSelectedMember}
                      //defaultValue={{label:'Select Member', value:undefined}}
                      options={memberOption}
                      placeholder='Select Member'
                      onChange={async (item: any) => {
                        dispatch(
                          userInfoActions.updateSelectedUser({
                            selectedUserName: item.label,
                            selectedUserId: item.value,
                          })
                        )
                        handleUserChange(item)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {orgId === '6656c5bd282c1dfc1ab6d44f' && (
          <div
            className='video-container'
            style={{
              height: '46rem',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              marginBottom: '2rem',
            }}
          >
            {!optionSelectedMember && (
              <div>
                <span>
                  <h2 className='noRecordFound mt-15'>Please Select User</h2>
                </span>
              </div>
            )}
            {optionSelectedMember && (
              <video style={{width: '800px', height: '450px'}} autoPlay loop muted>
                <source src='/media/video/1003935-hd_1920_1080_25fps.mp4' type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        )}

        {orgId !== '6656c5bd282c1dfc1ab6d44f' && (
          <div style={{height: '46rem'}}>
            {loading === true && Loader('3rem')}

            {userState === false && loading === false && (
              <div>
                <span>
                  <h2 className='noRecordFound mt-15'>Please Select User</h2>
                </span>
              </div>
            )}

            {image.trim().length > 0 && userState === true && loading === false && (
              <div className='d-flex justify-content-center py-5 mx-5 mb-5'>
                <img src={`data:image/jpeg;base64,${image}`} width={'72%'} />
              </div>
            )}

            {userOffline === true &&
              userState === true &&
              image.trim().length <= 0 &&
              loading === false && (
                <div>
                  <h2 className='noRecordFound mt-15'>User Offline</h2>
                </div>
              )}
          </div>
        )}
      </div>
    </>
  )
}

export default LiveStreaming
