import React from 'react'

type Props = {
  id: any
  updateValue?: any
  deleteTableRow: any
  data?: any
}

const AddNewSalesPerson: React.FC<Props> = ({id, updateValue, deleteTableRow, data}) => {
  return (
    <div className='border px-5 mb-3 pt-5 d-flex '>
      <div className='w-100'>
        <div className='row mb-6 d-flex'>
          <label className='col-form-label fw-bold fs-6' style={{width: '14%'}}>
            <span>Name</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              //disabled={data?.salesName ? true : false}
              value={data?.salesName}
              type='text'
              className='form-control form-control-border '
              placeholder='Name'
              onChange={(e) => updateValue(id, 'salesName', e.target.value)}
            />
          </div>
        </div>

        <div className='row mb-6 d-flex'>
          <label className='col-form-label fw-bold fs-6' style={{width: '14%'}}>
            <span>Phone</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              //disabled={data?.salesName ? true : false}
              value={data?.phoneNo}
              type='tel'
              className='form-control form-control-border '
              placeholder='Phone Number'
              onChange={(e) => updateValue(id, 'phoneNo', e.target.value)}
            />
          </div>
        </div>

        <div className='row mb-6 d-flex'>
          <label className='col-form-label fw-bold fs-6' style={{width: '14%'}}>
            <span>Email</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              //disabled={data?.salesName ? true : false}
              value={data?.email}
              type='email'
              className='form-control form-control-border '
              placeholder='Email'
              onChange={(e) => updateValue(id, 'email', e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='d-flex align-content-center flex-wrap'>
        <button className='btn btn-primary w-auto mx-5 d-flex' onClick={() => deleteTableRow(id)}>
          x
        </button>
      </div>
    </div>
  )
}

export default AddNewSalesPerson
