import React from 'react'

import {Accordion} from 'react-bootstrap'

import TaskTableAccordion from './TaskTableAccordion'

type Props = {
  data: any
  deleteFun: any
}

const Accordian: React.FC<Props> = ({data, deleteFun}) => {
  //console.log(data)
  //const [taskList, setTaskList] = useState<any[]>(data.tasks)

  return (
    // <div className='accordion' id={data.projectId}>
    //   <div className='accordion-item ' id={data.projectId}>
    //     <h2 className='accordion-header d-flex'>
    //       {/* <input type='checkbox' className='d-flex mx-3'></input> */}
    //       <button
    //         className='accordion-button collapsed'
    //         type='button'
    //         data-bs-toggle='collapse'
    //         data-bs-target={`#${id2}`}
    //         aria-expanded='false'
    //         aria-controls='panelsStayOpen-collapseOne'
    //       >
    //         {data.projectName}
    //       </button>
    //     </h2>
    //     <div id={id2} className='accordion-collapse collapse'>
    //       <div className='accordion-body px-15'>
    //         <div className='table-responsive'>
    //           {/* begin::Table */}
    //           <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
    //             {/* begin::Table head */}
    //             <thead>
    //               <tr className='fw-bolder text-dark'>
    //                 <th className='w-25px'> </th>
    //                 <th className='min-w-140px'>Task Name</th>
    //                 <th className='min-w-140px'>Start Date</th>
    //                 <th className='min-w-140px'>End Date</th>
    //                 <th className='min-w-140px'>Estimate Hours</th>
    //                 <th className='min-w-50px'>Actions</th>
    //               </tr>
    //             </thead>
    //             {/* end::Table head */}
    //             {/* begin::Table body */}
    //             <tbody>
    //               {taskList?.map((item) => (
    //                 <tr key={item.allocatedTaskId}>
    //                   <td></td>

    //                   <td>
    //                     <div className='d-flex align-items-center'>
    //                       <div className='d-flex justify-content-start flex-column'>
    //                         {item.taskName}
    //                       </div>
    //                     </div>
    //                   </td>
    //                   <td>
    //                     <div className='d-flex align-items-center'>
    //                       <div className='d-flex justify-content-start flex-column'>
    //                         {dayjs(item.startDate).format('DD/MM/YYYY')}
    //                       </div>
    //                     </div>
    //                   </td>
    //                   <td>
    //                     <div className='d-flex align-items-center'>
    //                       <div className='d-flex justify-content-start flex-column'>
    //                         {dayjs(item.endDate).format('DD/MM/YYYY')}
    //                       </div>
    //                     </div>
    //                   </td>
    //                   <td>
    //                     <div className='d-flex align-items-center'>
    //                       <div className='d-flex justify-content-start flex-column'>
    //                         {item.estimatedHours}
    //                       </div>
    //                     </div>
    //                   </td>

    //                   <td>
    //                     <div className='d-flex flex-shrink-0'>
    //                       <a
    //                         onClick={() => {
    //                           //deleteHandler(item.taskId)
    //                         }}
    //                         className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
    //                       >
    //                         <KTSVG
    //                           path='/media/icons/duotune/general/gen027.svg'
    //                           className='svg-icon-3'
    //                         />
    //                       </a>
    //                     </div>
    //                   </td>
    //                 </tr>
    //               ))}
    //             </tbody>
    //             {/* end::Table body */}
    //           </table>
    //           {/* end::Table */}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <Accordion defaultActiveKey='0' className='position-relative' style={{zIndex: 0}}>
        {data &&
          data.map((item: any) => (
            <Accordion.Item eventKey={item.projectId} key={item.projectId}>
              <Accordion.Header>{item.projectName}</Accordion.Header>
              <Accordion.Body>
                <TaskTableAccordion data={item.tasks} deleteFun={deleteFun} />
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
    </>
  )
}

export default Accordian
