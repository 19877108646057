import React from 'react'
import {Link, Outlet} from 'react-router-dom'

const WebAppMain: React.FC = () => {
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-3' style={{padding: '0px 25px'}}>
          <div className='d-flex overflow-auto h-35px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link className={`nav-link text-active-primary me-6 `} to='/web&app/individual'>
                  Individual
                </Link>
              </li>
              <li className='nav-item'>
                <Link className={`nav-link text-active-primary me-6 `} to='/web&app/team'>
                  Team
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export {WebAppMain}
