import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function GetAssignee(id?:any) {
  const orgId = localStorage.getItem('org_Id')
  const userId = id || localStorage.getItem('userId')
  return axios.get(`${API_URL}/ReportManagerAssignment/GetAllMembersByUserId`, {
    params: {
      orgId,
      userId,
    },
  })
}
