import axios, {CancelTokenSource} from 'axios'
import dayjs from 'dayjs'
const API_URL = process.env.REACT_APP_API_URL

let cancelTokenSource: CancelTokenSource | null = null

export function getUnAssignedUser(userId: string) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }

  const orgId = localStorage.getItem('org_Id')
  cancelTokenSource = axios.CancelToken.source()

  return axios.get(`${API_URL}/ReportManagerAssignment/GetUnassignedMembersByUserId`, {
    params: {
      orgId,
      userId,
    },
    cancelToken: cancelTokenSource?.token,
  })
}

export function getAssignedUser(userId: string) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }

  const orgId = localStorage.getItem('org_Id')
  cancelTokenSource = axios.CancelToken.source()
  return axios.get(`${API_URL}/ReportManagerAssignment/GetAssignedMembersByUserId`, {
    params: {
      orgId,
      userId,
    },
    cancelToken: cancelTokenSource?.token,
  })
}

export function getUnAssignedDepartment(userId: string) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }

  const orgId = localStorage.getItem('org_Id')
  cancelTokenSource = axios.CancelToken.source()

  return axios.get(`${API_URL}/ReportManagerAssignment/GetAllUnassignedDepartmentByUserId`, {
    params: {
      orgId,
      userId,
    },
    cancelToken: cancelTokenSource?.token,
  })
}

export function getAssignedDepartment(userId: string) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }

  const orgId = localStorage.getItem('org_Id')
  cancelTokenSource = axios.CancelToken.source()

  return axios.get(`${API_URL}/ReportManagerAssignment/GetAllAssignedDepartmentsByUserId`, {
    params: {
      orgId,
      userId,
    },
    cancelToken: cancelTokenSource?.token,
  })
}

export function addUsers(assigneeId: string, userId: string) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }

  let body = {
    organizationId: localStorage.getItem('org_Id'),
    userId,
    assigneeId,

    createdDate: dayjs().format(),
    createdBy: localStorage.getItem('userId'),
  }

  cancelTokenSource = axios.CancelToken.source()

  return axios.post(`${API_URL}/ReportManagerAssignment/AddAssignees`, body, {
    cancelToken: cancelTokenSource?.token,
  })
}

export function addDepartment(departmentId: string, userId: string) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }

  let body = {
    organizationId: localStorage.getItem('org_Id'),
    userId,
    departmentId,

    createdDate: dayjs().format(),
    createdBy: localStorage.getItem('userId'),
  }

  cancelTokenSource = axios.CancelToken.source()

  return axios.post(`${API_URL}/ReportManagerAssignment/AddDepartments`, body, {
    cancelToken: cancelTokenSource?.token,
  })
}

export function RemoveUsers(assignee: string, userId: string) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }

  let orgId = localStorage.getItem('org_Id')

  cancelTokenSource = axios.CancelToken.source()

  return axios.delete(`${API_URL}/ReportManagerAssignment/DeleteAssignedMembersByUserId`, {
    params: {
      orgId,
      userId,
    },
    data: assignee,
    cancelToken: cancelTokenSource?.token,
  })
}

export function RemoveDepartment(department: string, userId: string) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }

  let orgId = localStorage.getItem('org_Id')
  cancelTokenSource = axios.CancelToken.source()

  return axios.delete(`${API_URL}/ReportManagerAssignment/DeleteAssignedDepartmentsByUserId`, {
    params: {
      orgId,
      userId,
    },
    data: department,
    cancelToken: cancelTokenSource?.token,
  })
}
