import React from 'react'
import PartnerCommision from '../Component/PartnerCommision'

type Props = {
  data: any,
  cancelHandler:any,
  refreshList:any,
  partnerId:any,
  updateStatus:any,
}

const EditPartnerCommission: React.FC<Props> = ({data,cancelHandler,refreshList,updateStatus,partnerId}) => {
  return (
    <>
      <PartnerCommision value={data} cancelHandler={cancelHandler} display={'Edit'} refreshList={refreshList} updateStatus={updateStatus} partnerId={partnerId} />
    </>
  )
}

export default EditPartnerCommission
