/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {RootState} from '../../../setup'
import Select from 'react-select'
import {Loader, AlertModal} from '../../CommonFunctions/CommonFunction'
import 'tippy.js/dist/tippy.css'
import {shallowEqual, useSelector} from 'react-redux'
import makeAnimated from 'react-select/animated'
import {
  GetAllAllocatedTaskByUserId,
  MarkTaskCompleteByAllocatedIds,
} from '../../services/Project&Task.service'
import {GetAssignee} from '../../services/GetAllAssinee.services'
import {memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import dayjs from 'dayjs'
const MarkTaskCompletion = () => {
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const [spinner, setSpinner] = useState(false)
  const [_, setForcePage] = useState<any>(null)

  const [projects, setProjects] = useState<any[]>([])
  const [allData, setAllData] = useState<any[]>([])
  const [selectedProject, setSelectedProject] = useState('')
  const [allTasks, setAllTasks] = useState<any[]>([])
  const [allUsers, setAllUsers] = useState<any[]>([])
  const [selectedUser, setSelectedUser] = useState('')

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(allTasks.map((li: any) => li.allocatedTaskId))
    if (isCheckAll) {
      setIsCheck([])
    }
  }

  const handleClick = (e: any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id))
    }
    if (isCheck) {
      setIsCheckAll(false)
    }
  }

  const fetchDetails = () => {
    const orgId = localStorage.getItem('org_Id')
    const userId = localStorage.getItem('userId')
    const role = ConfigDetails.role

    if (role === 'User') {
      GetAllocatedUserTask(userId || '', orgId || '')
    } else {
      GetEmployees(userId || '', role)
    }
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  const GetAllocatedUserTask = async (userId: string, orgId: string) => {
    try {
      const taskData = await GetAllAllocatedTaskByUserId(orgId, userId)

      const filteredData = taskData.data.filter(
        (item: any) => item.projectName !== null && item.projectId !== null
      )

      const newArray = filteredData.map((item: any) => ({
        label: item.projectName as string,
        value: item.projectId as string,
      }))

      setAllData(taskData.data)
      setProjects(newArray)
      setSpinner(false)
    } catch (error) {
      AlertModal('Something went wrong.', '', 'error', false, '#7066E0', 'Ok')
    }
  }

  const handleAfterUpdate = () => {
    try {
      setSelectedProject('')
      setSelectedUser('')
      setAllTasks([])
      fetchDetails()
    } catch (error) {
      console.log(error)
    }
  }

  const GetEmployees = async (userId: string, role: string) => {
    setSpinner(true)
    setAllData([])
    setAllTasks([])
    setSelectedProject('')
    let getAllEmpUnderManager
    if (role === 'Manager') {
      getAllEmpUnderManager = await GetAssignee(userId)
      const newList = getAllEmpUnderManager?.data.map((item: any) => {
        return {
          label: item.assigneeName,
          value: item.assigneeId,
        }
      })

      setAllUsers(newList)
    } else {
      getAllEmpUnderManager = await memberInfoByOrg()
      const newList = getAllEmpUnderManager?.data.map((item: any) => {
        return {
          label: item.fullName,
          value: item.userId,
        }
      })

      setAllUsers(newList)
    }
    setSpinner(false)
  }

  const handleProjectSelection = async (selectedProject: string) => {
    try {
      setSelectedProject(selectedProject)
      setIsCheck([])
      const tasksForSelectedProject = allData.filter((item) => item.projectId === selectedProject)
      const task = tasksForSelectedProject[0].tasks
      setAllTasks(task)
    } catch (error) {
      AlertModal('Something went wrong.', '', 'error', false, '#7066E0', 'Ok')
    }
  }

  const handleUserChange = async (selectedUser: string) => {
    try {
      setSpinner(true)
      setAllData([])
      setAllTasks([])
      setSelectedProject('')
      setSelectedUser(selectedUser)
      const orgId = localStorage.getItem('org_Id')
      GetAllocatedUserTask(selectedUser, orgId || '')
    } catch (error) {
      AlertModal('Something went wrong.', '', 'error', false, '#7066E0', 'Ok')
    }
  }

  const markTaskAsCompleted = async () => {
    try {
      const userId = localStorage.getItem('userId')
      const body = {
        allocatedTaskIds: isCheck,
        isTaskCompleted: true,
        modifiedBy: userId,
      }
      const markComplete = await MarkTaskCompleteByAllocatedIds(body)
      AlertModal(
        markComplete.data.message,
        '',
        markComplete.data.success ? 'success' : 'warning',
        false,
        '#7066E0',
        'Ok'
      ).then(() => {
        handleAfterUpdate()
      })
    } catch (error) {
      AlertModal('Something went wrong.', '', 'error', false, '#7066E0', 'Ok')
    }
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}
        <div className='card-header border-0 '>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Task Completion</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
          <div className='d-flex'>
            {ConfigDetails.role !== 'User' && (
              <div className='d-flex'>
                <span
                  className='d-flex align-items-center'
                  style={{fontWeight: '500', fontStyle: 'bold', fontSize: '15px'}}
                >
                  User :
                </span>

                <div
                  className='card-toolbar mx-3'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                >
                  <Select
                    components={makeAnimated()}
                    value={allUsers.filter((option) => option.value === selectedUser)}
                    options={allUsers}
                    placeholder='Select User'
                    onChange={(item: any) => {
                      handleUserChange(item.value)
                      setForcePage(null)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
              </div>
            )}

            <div className='d-flex'>
              <span
                className='d-flex align-items-center'
                style={{fontWeight: '500', fontStyle: 'bold', fontSize: '15px'}}
              >
                Projects :
              </span>

              <div
                className='card-toolbar mx-3'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <Select
                  components={makeAnimated()}
                  value={projects.filter((option) => option.value === selectedProject)}
                  options={projects}
                  placeholder='Select Project'
                  onChange={(item: any) => {
                    handleProjectSelection(item.value)
                    setForcePage(null)
                  }}
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnScroll={true}
                />
              </div>
            </div>
          </div>
        </div>

        {spinner ? (
          Loader('0px')
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder'>
                    <th></th>
                    <th className='min-w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          name='selectAll'
                          id='selectAll'
                          onChange={handleSelectAll}
                          checked={isCheckAll}
                        />
                      </div>
                    </th>

                    <th className='min-w-125px'>Name</th>
                    <th className='min-w-125px'>Start Date</th>
                    <th className='min-w-125px'>End Date</th>
                    <th className='min-w-125px'>Estimated Hours</th>

                    <th></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {allTasks.map((item: any) => (
                    <tr key={item.allocatedTaskId}>
                      <td></td>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-13-check'
                            key={item.allocatedTaskId}
                            type='checkbox'
                            id={item.allocatedTaskId}
                            onChange={handleClick}
                            checked={isCheck.includes(item.allocatedTaskId)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column  fs-6'>
                            {item.taskName}
                          </div>
                        </div>
                      </td>

                      <td>
                        <span className='text-dark  d-block fs-6 '>
                          {dayjs(item.startDate).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark  d-block fs-6 '>
                          {dayjs(item.endDate).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark   d-block fs-6'>{item.estimatedHours}</span>
                      </td>

                      <td></td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>

            {/* end::Table */}

            <style>
              {`
                        
                        .custom-select-class{
                          text-align: center;
                          width: 13rem;
                        }

                        .select-custom-header{
                          margin-right: 1rem;
                          line-height: 3rem;
                        }

                        .card-toolbar > .css-b62m3t-container > div {
                          width:13rem !important;
                        }
                        

                        

                        
                    `}
            </style>

            {/* end::Table container */}
          </div>
        )}

        {/* begin::Body */}
        <div className='d-flex justify-content-end py-3 px-9'>
          {spinner ? (
            ''
          ) : (
            <button
              type='submit'
              className='btn btn-primary mb-3'
              disabled={isCheck.length == 0}
              onClick={markTaskAsCompleted}
            >
              {!spinner && <span className='indicator-label'>Mark Complete</span>}
              {spinner && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export {MarkTaskCompletion}
