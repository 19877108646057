import Tippy from '@tippyjs/react'
import axios from 'axios'
import React, {useEffect, useState} from 'react'

import {AlertModal, Loader} from '../../CommonFunctions/CommonFunction'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'

const API_URL = process.env.REACT_APP_API_URL

const OnBoardReq: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])

  useEffect(() => {
    getList()
  }, [])

  const getList = () => {
    axios
      .get(`${API_URL}/OnboardingOrganizationRequest/GetAllOnboardingOrgPendingRequest`)
      .then((res) => {
        setList(res.data)
      })

    setLoader(false)
  }

  const ApproveHandler = (id: string) => {
    // setLoader(true)
    axios
      .post(
        `${API_URL}/OnboardingOrganizationRequest/ApproveOnboardingOrgRequest?requestId=${id}&userId=${localStorage.getItem(
          'userId'
        )}`
      )
      .then((res) => {
        AlertModal(
          res.data.message,
          '',
          res.data.success ? 'success' : 'warning',
          false,
          '#7066E0',
          'Ok'
        )
        getList()
      })
  }

  const RejectHandle = async (id: string) => {
    // const ipAPI = "//api.ipify.org?format=json";
    // const response = await fetch(ipAPI);
    // const data = await response.json();
    // const inputValue = data.ip;
    const {value: reason} = await Swal.fire({
      title: 'Reason for Rejecting Request',
      input: 'textarea',
      inputPlaceholder: 'Type your message here...',
      // inputValue,
      inputAttributes: {
        'aria-label': 'Type your message here',
      },
      showCancelButton: true,
      inputValidator: (value) => (!value ? 'You need to write something!' : ''),
      width: '23rem',
      heightAuto: false,
      confirmButtonColor: '#7066E0',
      cancelButtonColor: '#d33',
      backdrop: `rgba(0,0,0,0.8)`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      customClass: {
        title: 'swal-popup',
      },
    })

    if (reason) {
      //console.log(ipAddress)
      axios
        .post(`${API_URL}/OnboardingOrganizationRequest/RejectOnboardingOrgRequest`, {
          requestId: id,
          userId: localStorage.getItem('userId'),
          reason: reason,
        })
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          )
          res.data.success === true && getList()
        })
    }
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>OnBoard Request</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        {loader && Loader('100px')}

        {!loader && (
          <div className={`card-body py-3 ${list.length > 0 ? 'mb-3' : 'mb-18'}`}>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder'>
                    <th></th>
                    <th className='min-w-125px'>Name</th>
                    <th className='min-w-125px'>Email</th>
                    <th className='min-w-125px'>Contact Number</th>
                    <th className='min-w-125px'>Address</th>
                    <th className='min-w-125px'>Referred By</th>
                    <th className='min-w-125px'>Requested Date</th>
                    <th className='min-w-125px text-center'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {list.length > 0 &&
                    list.map((details) => (
                      <tr key={details.requestId}>
                        <td></td>

                        <td>
                          <span className='text-dark  d-block fs-6 '>
                            {details.organizationName}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark d-block fs-6'>{details.orgEmail}</span>
                        </td>

                        <td>
                          <span className='text-dark d-block fs-6'>{details.orgContactNo}</span>
                        </td>

                        <td>
                          <span className='text-dark d-block fs-6'>{details.orgAddress}</span>
                        </td>

                        <td>
                          <span className='text-dark d-block fs-6'>{details.referedBy}</span>
                        </td>

                        <td>
                          <span className='text-dark d-block fs-6'>
                            {dayjs(details.requestedDate).format('DD/MM/YYYY')}
                          </span>
                        </td>

                        <td>
                          <div className='d-flex justify-content-evenly flex-shrink-0'>
                            <Tippy placement='top' content='Approve Request'>
                              <button
                                className='btn btn-primary'
                                onClick={() => ApproveHandler(details.requestId)}
                              >
                                Approve
                              </button>
                            </Tippy>

                            <Tippy placement='top' content='Reject request'>
                              <button
                                className='btn btn-danger'
                                onClick={() => RejectHandle(details.requestId)}
                              >
                                Reject
                              </button>
                            </Tippy>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>

              {list.length <= 0 && (
                <span className='position-absolute w-100'>
                  <h2 className='noRecordFound'>No Records Found</h2>
                </span>
              )}
            </div>

            {/* end::Table */}

            {/* end::Table container */}
          </div>
        )}
      </div>
    </>
  )
}

export default OnBoardReq
