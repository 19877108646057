import React, { FC } from 'react'
import { AttendanceSheetManager } from './attend/attendanceManager'
import { AttendanceSheetemp } from './attend/attendancemp'

const AttendanceSheetWrapper: FC = () => {

  if (localStorage.getItem('role') === process.env.REACT_APP_SYSTEM_SECOND_ROLE)
    return (
      <>
        {/* begin::Row */}

        <AttendanceSheetManager></AttendanceSheetManager>
      </>
    )
  else
    return (
      <>
        <AttendanceSheetemp></AttendanceSheetemp>
      </>
    )
}

export { AttendanceSheetWrapper }
