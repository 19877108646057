/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {PrivateRoutes} from './AdminRoutes'
import {PrivateRoutesEmp} from './EmpRoutes'
import {PrivateRoutesManager} from './ManagerRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {RootState} from '../../setup'
import {App} from '../App'
import {SuperAdminRoutes} from './SuperAdminRoutes'
import PartnerRoutes from './PartnerRoutes'
import TrialEnds from '../modules/auth/TrialEnds'
import {MyPlan} from '../pages/Pricing and Billing/MyPlan'
import Plans from '../pages/Pricing and Billing/Plans'
import {OrgSelection} from '../modules/auth/components/OrgSelectionPopUp'
import {handleLogout} from '../CommonFunctions/CommonFunction'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const dispatch = useDispatch()
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const role = localStorage.getItem('role')

  useEffect(() => {
    const pathname = window.location.pathname
    const queryString = window.location.search

    const urlParams = new URLSearchParams(queryString)
    const verify = urlParams.get('verify')
    if (verify) {
      handleLogout(dispatch)
    }
    const reportDateParam = urlParams.get('ReportDate')
    const isTeam = urlParams?.get('IsTeam')

    if (pathname === '/report/timeTracker' && reportDateParam !== null && isTeam) {
      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/
      if (dateFormatRegex.test(reportDateParam)) {
        const intendedRoute = pathname + queryString
        localStorage.setItem('finalRoute', intendedRoute)
      }
    }
  }, [])

  if (role === process.env.REACT_APP_SYSTEM_FIRST_ROLE) {
    return (
      <>
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route element={<App />}>
              {ConfigDetails.isSuspended ? (
                <>
                  <Route path='/*' element={<TrialEnds />} />
                  <Route path='/myPlan' element={<MyPlan />} />
                  <Route path='/plans' element={<Plans />} />
                </>
              ) : (
                <>
                  <Route path='error/*' element={<ErrorsPage />} />
                  <Route path='logout' element={<Logout />} />

                  {isAuthorized && !ConfigDetails.isGroupAdmin ? (
                    <>
                      <Route index element={<Navigate to='/builder' />} />

                      <Route path='/*' element={<PrivateRoutes />} />
                      {/* <Route index element={<Navigate to='/dashboard' />} /> */}
                    </>
                  ) : (
                    <>
                      <Route path='orgSelect' element={<OrgSelection />} />
                      <Route path='auth/*' element={<AuthPage />} />
                      <Route path='*' element={<Navigate to='/auth' />} />
                    </>
                  )}
                </>
              )}

              {/* <Route path='error/*' element={<ErrorsPage />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    )
  } else if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
    return (
      <>
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />
              {isAuthorized ? (
                <>
                  <Route path='/*' element={<PrivateRoutesManager />} />
                  {/* <Route index element={<Navigate to='/dashboard' />} /> */}
                  <Route index element={<Navigate to='/builder' />} />
                </>
              ) : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    )
  } else if (role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE) {
    return (
      <>
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />
              {isAuthorized ? (
                <>
                  <Route path='/*' element={<SuperAdminRoutes />} />
                  {/* <Route index element={<Navigate to='/dashboard' />} /> */}
                  <Route index element={<Navigate to='/organization' />} />
                </>
              ) : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    )
  } else if (role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE) {
    return (
      <>
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />
              {isAuthorized ? (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/builder' />} />
                </>
              ) : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    )
  } else if (role === process.env.REACT_APP_SYSTEM_SIXTH_ROLE) {
    return (
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {isAuthorized ? (
              <>
                <Route path='/*' element={<PartnerRoutes />} />
                <Route index element={<Navigate to='/builder' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    )
  } else {
    return (
      <>
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='logout' element={<Logout />} />
              {isAuthorized ? (
                <>
                  <Route path='/*' element={<PrivateRoutesEmp />} />
                  {/* <Route index element={<Navigate to='/dashboard' />} /> */}
                  <Route index element={<Navigate to='/builder' />} />
                </>
              ) : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
              )}
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    )
  }
}

export {AppRoutes}
