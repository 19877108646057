import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import {Loader, Pagination, RowsPerPage} from '../../CommonFunctions/CommonFunction'
import axios from 'axios'
import {handleDownload} from '../Common_Function/Function'
import {useNavigate} from 'react-router-dom'

const PaymentHistory: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  //const [paymentHistory, setPaymentHistory] = useState<any[]>([])
  const navigate = useNavigate()
  const [loader, setLoader] = useState<boolean>(false)
  const [org, setOrg] = useState<any[]>([])
  const [rowPerPage, setRowPerPage] = useState(10)
  const [forcePage, setForcePage] = useState<any>(null)

  useEffect(() => {
    setLoader(true)
    axios
      .get(
        `${API_URL}/Payment/GetAllPaymentHistoryByOrgId?org_Id=${localStorage.getItem('editOrg')}`
      )
      .then((res) => setOrg(res.data))
      .catch((err) => {
        console.error(err)
      })
    setLoader(false)
  }, [])

  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  // const filteredMembers = org.filter((list) =>
  //     list.organizationName?.toLowerCase().includes(searchQuery.toLowerCase())
  // )

  const currentItemss = org.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(org.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % org.length
    setItemOffset(newOffset)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Plan History</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
        </div>

        <div className='card-body py-3 mb-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder'>
                  <th>
                    {/* <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              </div> */}
                  </th>
                  <th className='min-w-100px'>Plan Name</th>
                  <th className='min-w-100px text-center'>Total Users</th>
                  <th className='min-w-100px text-center'>Payment Mode</th>
                  <th className='min-w-100px text-center'>Effective From</th>
                  <th className='min-w-100px text-center'>Termination Date</th>
                  <th className='min-w-100px text-center'>Currency</th>
                  <th className='min-w-100px text-center'>Amount</th>
                  <th className='min-w-125px text-center'>Download</th>
                  <th></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {org?.length > 0 &&
                  !loader &&
                  currentItemss.map((names) => (
                    <tr key={names.billingPlanId}>
                      <td></td>

                      <td>
                        <span className='text-dark  d-block fs-6 '>{names.billingPlanName}</span>
                      </td>

                      <td>
                        <span className='text-dark text-center d-block fs-6'>
                          {names?.noOfUsers==0?'-':names.noOfUsers}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark text-center d-block fs-6'>
                          {names.paymentMode}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark text-center d-block fs-6'>
                          {dayjs(names.effectiveFrom).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark text-center d-block fs-6'>
                          {dayjs(names.terminationDate).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark text-center d-block fs-6'>{names.currency}</span>
                      </td>
                      <td>
                        <span className='text-dark text-center d-block fs-6'>{names.amount}</span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-around'>
                          <button
                            className='btn btn-sm btn-primary '
                            onClick={() => handleDownload(names.receiptUrl)}
                          >
                            Receipt
                          </button>
                          <button
                            className='btn btn-sm btn-primary '
                            onClick={() => handleDownload(names.invoiceUrl)}
                          >
                            Invoice
                          </button>
                        </div>
                      </td>

                      <td></td>
                    </tr>
                  ))}
              </tbody>

              {org?.length <= 0 && !loader && (
                <span className='position-absolute w-100'>
                  <h2 className='noRecordFound'>No Records Found</h2>
                </span>
              )}
              {/* end::Table body */}
            </table>
          </div>

          {loader && Loader('100px')}

          {org.length > 10 && (
            <div className='d-flex align-items-center justify-content-center position-relative mb-3'>
              <div style={{left: '2rem', position: 'absolute', top: '0'}}>
                {RowsPerPage(
                  setForcePage,
                  setRowPerPage,
                  setItemOffset,
                  rowPerPage,
                  org.length,
                  '0px'
                )}
              </div>

              {org.length > rowPerPage && Pagination(handlePageClick, pageCount, forcePage)}
            </div>
          )}

          <span
            className={`d-flex justify-content-center w-100 ${org.length <= 0 ? 'mt-20' : ''}`}
            style={{fontWeight: '600'}}
          >
            Showing {currentItemss.length} Records out of {org.length}
          </span>
        </div>

        <div className='card-footer d-flex justify-content-end py-3 px-9'>
          <button className='btn btn-secondary' onClick={() => navigate('/ExistingPlan')}>
            Back
          </button>
        </div>
      </div>
    </>
  )
}

export default PaymentHistory
