/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'

import '../../../css/project&task.css'

import {
  Pagination,
  Loader,
  MultiDateTimePicker,
  ErrorModal,
  convertSecIntoHoursMin,
} from '../../CommonFunctions/CommonFunction'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import {ProjectAndTaksPopUp} from '../projectAndTaskPopUp'
import {GetProjectTaskDetailReport} from '../../services/Project&Task.service'
import {convertSecIntoHoursMinSec} from '../Common_Function/Function'

const ProjectAndTaskReportOld = () => {
  const [department, _] = useState<any[]>([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [spinner, setSpinner] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [projectData, setProjectData] = useState<any[]>([])
  const [poupData, setPoupData] = useState<any[]>([])

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % department.length
    setItemOffset(newOffset)
  }

  const currentItemss = projectData.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(department.length / itemsPerPage)

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(dates)
    setStartDate(start)
    setEndDate(end)
  }

  useEffect(() => {
    getProjectTaskReport()
  }, [startDate, endDate])

  const getProjectTaskReport = async () => {
    try {
      setSpinner(true)
      let organizationId = localStorage.getItem('org_Id')
      let body = {
        organizationId,
        fromDate: startDate,
        toDate: endDate ? endDate : startDate,
      }
      const reportData = await GetProjectTaskDetailReport(body)
      setProjectData(reportData.data)
      setSpinner(false)
    } catch (error) {
      ErrorModal()
    }
  }

  const [itemTaskOffset, setItemTaskOffset] = useState(0)

  const taskTable = (taskDetails: any) => {
    let itemsTaskPerPage: number = 5

    const endTaskOffset = itemTaskOffset + itemsTaskPerPage

    const handleTaskPageClick = (event: any) => {
      const newTaskOffset = (event.selected * itemsTaskPerPage) % taskDetails.taskDetails.length
      setItemTaskOffset(newTaskOffset)
    }

    const colors = ['#FC716E', '#FFE4D9']

    const taskItems = taskDetails.taskDetails?.slice(itemTaskOffset, endTaskOffset)
    const taskPageCount = Math.ceil(taskDetails.taskDetails.length / itemsTaskPerPage)

    const handlePopUp = (popUpData: any) => {
      try {
        setPoupData(popUpData)
        setIsModalOpen(true)
      } catch (error) {
        ErrorModal()
      }
    }
    return (
      <div className='accordion-body report-accordion-body'>
        <div className='table-responsive'>
          <table className='table table-rounded  border gy-7 gs-7'>
            <thead style={{background: 'rgb(248,249,253)'}}>
              <tr
                className='fw-semibold fs-6 text-gray-800 border-bottom border-gray-200'
                style={{fontWeight: '600'}}
              >
                <th className='min-w-250px'>Task Name</th>
                <th className='text-center'>Estimated Hours</th>
                <th className='text-center'>Logged Hours</th>
                <th className='text-center'>Final Hours</th>
                <th>Members</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {spinner && Loader('50px')}
              {!spinner &&
                taskItems.map((task: any, index: any) => (
                  <tr className='border-bottom border-gray-200' key={index}>
                    <td>{task.taskName}</td>
                    <td className='text-center'>{task.isDefault ? '-' : task.estimatedHours}</td>
                    <td className='text-center'>{convertSecIntoHoursMinSec(task.loggedHours)}</td>
                    <td className='text-center'>{convertSecIntoHoursMin(task.newLoggedHours)}</td>
                    <td>
                      <div className='project-card___wrapper-acounts'>
                        {task.userDetails.map((user: any, userIndex: number) => (
                          <div className='project-card__acounts' key={userIndex}>
                            <div
                              style={{
                                verticalAlign: 'middle',
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                background: colors[userIndex % colors.length],
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Tippy placement='top' content={<span> {user.fullName}</span>}>
                                <span style={{fontSize: 'medium', fontWeight: '500'}}>
                                  {`${user.fullName.split(' ')[0].charAt(0)}${user.fullName
                                    .split(' ')
                                    .slice(-1)[0]
                                    .charAt(0)}`}
                                </span>
                              </Tippy>
                            </div>
                          </div>
                        ))}
                        {task.userDetails.length > 3 && (
                          <div className='project-card__score'>{`+${
                            task.userDetails.length - 3
                          }`}</div>
                        )}
                      </div>
                    </td>
                    <td>
                      <span
                        data-bs-toggle='modal'
                        data-bs-target='#project_task_1'
                        style={{cursor: 'pointer', color: '#009EF7'}}
                        onClick={() => handlePopUp(task.userDetails)}
                      >
                        See more
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='d-flex align-items-center justify-content-center mb-3'>
          {taskPageCount > 1 && Pagination(handleTaskPageClick, taskPageCount, 0)}
        </div>
      </div>
    )
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder mb-1' style={{fontSize: '19px'}}>
            Project Report
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>
        <div className='d-flex' style={{justifyContent: 'center', alignItems: 'center'}}>
          {MultiDateTimePicker(startDate, endDate, handleDateChange, 'form-control custom-Height')}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      {/* Begin Accordion */}

      <div style={{margin: '1rem'}}>
        <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bolder'>
              <th className='d-flex justify-content-around align-items-center'>
                <td className='fs-3'>Project Name</td>
                <td className='fs-3'>Project Owner</td>
                <td className='fs-3'>Estimated Hours</td>
                <td className='fs-3'>Logged Hours</td>
                <td className='fs-3'>Final Hours</td>
              </th>
            </tr>
          </thead>
          {spinner && Loader(0)}
          {!spinner && currentItemss.length == 0 && (
            <h2 className='noRecordFound'>No Records Found</h2>
          )}

          {!spinner &&
            currentItemss.length > 0 &&
            currentItemss.map((data, index) => (
              <div className='accordion' id={`kt_accordion_${index}`} key={index}>
                <div className='accordion-item'>
                  <div
                    className='accordion-header report-header'
                    id={`kt_accordion_${index}_header`}
                  >
                    <div
                      className='accordion-button fs-4 fw-semibold collapsed '
                      data-bs-toggle='collapse'
                      data-bs-target={`#kt_accordion_${index}_body`}
                      aria-expanded='false'
                      aria-controls={`kt_accordion_${index}_body`}
                    >
                      <div className='d-flex justify-content-between w-100 '>
                        <div className='w-200px d-flex justify-content-center '>
                          {data.projectName}
                        </div>
                        <div className='w-200px d-flex justify-content-center '>
                          {data.projectOwner}
                        </div>
                        <div className='w-200px d-flex justify-content-center'>
                          {data.isDefault ? '-' : data.estimatedHours}
                        </div>
                        <div className='w-200px d-flex justify-content-center '>
                          {convertSecIntoHoursMin(data.loggedHours)}
                        </div>
                        <div className='w-200px d-flex justify-content-center '>
                          {convertSecIntoHoursMin(data.newLoggedHours)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id={`kt_accordion_${index}_body`}
                    className='accordion-collapse collapse'
                    aria-labelledby={`kt_accordion_${index}_header`}
                    data-bs-parent='#accordionExample'
                  >
                    {taskTable(data)}
                  </div>
                </div>
              </div>
            ))}
        </table>
      </div>
      <div className='d-flex align-items-center justify-content-center mb-3'>
        {pageCount > 1 && Pagination(handlePageClick, pageCount, 0)}
      </div>
      <style>
        {`
        .accordion-button:not(.collapsed){
          color : unset !important;
          background-color:#d2dbff !important;
        };

        .table  {
            padding: unset !important;
        }

        .report-header{
            padding : unset !important
        }

        .report-accordion-body{
            padding : unset !important;
        }

        .table.gy-7 td{
            padding-top:1.5rem !important;
            padding-bottom:1.5rem !important;
        } 
        
        `}
      </style>
      {isModalOpen && <ProjectAndTaksPopUp onClose={handleCloseModal} userDetails={poupData} />}
    </div>
  )
}

export {ProjectAndTaskReportOld}
