function getObjectPropertyValueByKey(obj: any, key: string): any | undefined {
  const map = new Map(Object.entries(obj))
  if (obj.hasOwnProperty(key) && map) {
    return map.get(key)
  }
}

/**
 * Generates unique ID for give prefix.
 * @param {string} prefix Prefix for generated ID
 * @returns {boolean}
 */
function getUniqueIdWithPrefix(prefix: string | undefined): string {
  const array = new Uint32Array(4)
  crypto.getRandomValues(array)
  const randomString = array.reduce((str, value) => str + value.toString(16).padStart(8, '0'), '')

  const id = prefix ? `${prefix}${randomString}` : randomString

  return id.slice(0, 12)
}

/* eslint-disable no-useless-escape */
function stringSnakeToCamel(str: string): string {
  return str.replace(/(\-\w)/g, function (m) {
    return m[1].toUpperCase()
  })
}

function toJSON(value: string | JSON): JSON | undefined {
  if (typeof value !== 'string') {
    return value
  }

  if (!value) {
    return undefined
  }

  // ("'" => "\"");
  const result = value
    .toString()
    .split('')
    .map((el) => (el !== "'" ? el : '"'))
    .join('')
  var jsonStr = result.replace(/(\w+:)|(\w+ :)/g, function (matched) {
    return '"' + matched.substring(0, matched.length - 1) + '":'
  })
  try {
    return JSON.parse(jsonStr)
  } catch {
    return undefined
  }
}

export {getObjectPropertyValueByKey, getUniqueIdWithPrefix, stringSnakeToCamel, toJSON}
