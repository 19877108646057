import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getAllBillingPlans() {
  return axios.get(`${API_URL}/BillingPlan/GetAllBillingPlans`)
}
export function GetAllBillingPlansByOrgId() {
  const org_Id = localStorage.getItem('org_Id')
  return axios.get(`${API_URL}/BillingPlan/GetAllBillingPlansByOrgId?organiizationId=${org_Id}`)
}
export function GetAllCustomBillingPlans() {
  return axios.get(`${API_URL}/BillingPlan/GetAllCustomBillingPlans`)
}
export function CompletePlansData(orgId: any) {
  return axios.get(
    `${API_URL}/BillingPlan/GetAllBillingPlansByOrganizationId?organizationId=${orgId}`
  )
}

export function getAllPayment() {
  const org_Id = localStorage.getItem('org_Id')

  return axios.get(`${API_URL}/Payment/GetAllPaymentHistoryByOrgId`, {
    params: {
      org_Id,
    },
  })
}

export function getActivePlanByOrg() {
  const orgId = localStorage.getItem('org_Id')

  return axios.get(`${API_URL}/Payment/GetAllPlansByOrgId`, {
    params: {
      orgId,
    },
  })
}

export function upgradePlanRequest(planDetails: any) {
  return axios.post(`${API_URL}/BillingPlan/AddChangePlanRequest`, planDetails)
}
export function DeleteBillingPlan(billingPlanId: any) {
  return axios.post(`${API_URL}/BillingPlan/DeleteBillingPlan?billingPlanId=${billingPlanId}`)
}
export function GetBillingPlanByPlanId(planId: any) {
  return axios.get(`${API_URL}/BillingPlan/GetBillingPlanByPlanId?planId=${planId}`)
}

export function GetPaymentSummary(planId: any, organization?: any, extraUser?: any) {
  let orgId = organization || localStorage.getItem('org_Id')
  return axios.get(
    `${API_URL}/OnlinePayment/PaymentSummary?OrganizationId=${orgId}&BillingPlanId=${planId}&ExtraUserCount=${
      extraUser ? extraUser : 0
    }`
  )
}
export function GetCheckoutUrl(body: any) {
  return axios.post(`${API_URL}/OnlinePayment/Checkout`, body)
}
export function CheckPaymentSuccessOrFailure(sessionId: any) {
  return axios.post(`${API_URL}/Payment/SuccessOrFailureOnlinePayment?sessionId=${sessionId}`)
}
export function GetPendingChangePlanRequestByOrgId() {
  const orgId = localStorage.getItem('org_Id')
  return axios.get(`${API_URL}/BillingPlan/GetPendingChangePlanRequestByOrgId?orgId=${orgId}`)
}
export function DeletePendingUpgradeRequest(requestId: string) {
  return axios.post(`${API_URL}/BillingPlan/DeleteChangePlanRequest?requestId=${requestId}`)
}
