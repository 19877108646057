import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getAllBillingPlans() {
  return axios.get(`${API_URL}/BillingPlan/GetAllBillingPlans`)
}
export function GetAllCustomBillingPlans() {
  return axios.get(`${API_URL}/BillingPlan/GetAllCustomBillingPlans`)
}

export function GetAllAllocatedTaskByUserId(orgId: string, userId: string) {
  return axios.get(
    `${API_URL}/AllocateTask/GetAllAllocatedTasksByUserId?orgId=${orgId}&userId=${userId}`
  )
}

export function MarkTaskCompleteByAllocatedIds(body: any) {
  return axios.post(`${API_URL}/AllocateTask/MarkTaskCompletedByAllocationIds`, body)
}

export function GetUserProjectTaskReportByDate(body: any) {
  return axios.post(`${API_URL}/ProjectTaskReport/GetUserProjectTaskReportByDate`, body)
}

export function GetProjectTaskDetailReport(body: any) {
  return axios.post(`${API_URL}/ProjectTaskReport/GetProjectTaskDetailReport`, body)
}
