import React, {useState, useEffect} from 'react'
import axios, {CancelTokenSource} from 'axios'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {
  Pagination,
  Loader,
  MultiDateTimePicker,
  RowsPerPage,
  TimeTrackerTable,
  ErrorModal,
} from '../../../CommonFunctions/CommonFunction'
import dayjs from 'dayjs'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {utils as XLSXUtils, write} from 'xlsx'
import {parseISO, isBefore, isEqual, addDays} from 'date-fns'
import {convertSecIntoAMPM, convertSecIntoHoursMinSec} from '../../Common_Function/Function'
import {GetAssignee} from '../../../services/GetAllAssinee.services'
import {RootState} from '../../../../setup'
import {userInfoActions} from '../../../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

const AttendanceSheetManager: React.FC = () => {
  const SelectedUser: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const [attendance, setAttendance] = useState<any[]>([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [showstartdate, setShowStartDate] = useState(dayjs().format('DD/MM/YYYY'))
  const [showenddate, setShowEndDate] = useState<any>(null)
  const [screenloader, setScreenLoader] = useState<any>(false)
  const [dateError, setDateError] = useState<any>(false)
  const [memberOption, setMemberOption] = useState<any>([])

  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')

  //Pagination
  const [rowPerPage, setRowPerPage] = useState(10)
  let itemsPerPage: number = rowPerPage
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState<number | null>(null)

  // Effect to handle item offset changes and update current items
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(attendance.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(attendance.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, attendance])

  // Effect to reset itemOffset and forcePage when attendance changes
  useEffect(() => {
    setItemOffset(0)
    setForcePage(0) // Force the first page
  }, [attendance])

  // Function to handle page click
  const handlePageClick = (event: {selected: number}) => {
    const newOffset = (event.selected * itemsPerPage) % attendance.length
    setItemOffset(newOffset)
    setForcePage(event.selected) // Update the forcePage to sync with current page
  }
  useEffect(() => {
    setScreenLoader(true)
    GetAssignee()
      .then((res: any) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.assigneeName,
            value: item.assigneeId,
          }
        })
        newList.unshift({
          label: 'All Members',
          value: 'All',
        })
        setMemberOption(newList)
      })
      .catch((err) => {
        console.log(err)
      })

    const isGroupAdmin = localStorage.getItem('isGroupAdmin')
    if (!isGroupAdmin && !SelectedUser.selectedUserId) {
      setOptionSelectedMember({
        label: localStorage.getItem('name'),
        value: localStorage.getItem('userId'),
      })
    }

    if (!isGroupAdmin && SelectedUser.selectedUserId) {
      setOptionSelectedMember({
        label: SelectedUser.selectedUserName,
        value: SelectedUser.selectedUserId,
      })
    }

    const currentUrl = new URL(window.location.href)
    const searchParams = currentUrl.searchParams
    const reportDate = searchParams.get('ReportDate')

    if (searchParams.get('IsTeam')) {
      const formatted = dayjs(reportDate).format('YYYY-MM-DDT00:00:00[Z]')
      setStartDate(new Date(formatted))
      CallOfEmp('All', formatted, formatted)
      setOptionSelectedMember({
        label: 'All Members',
        value: 'All',
      })
      searchParams.delete('ReportDate')
      searchParams.delete('IsTeam')
      currentUrl.search = searchParams.toString()

      const newUrl = currentUrl.href
      window.history.replaceState({path: newUrl}, '', newUrl)
    } else {
      if (reportDate) {
        searchParams.delete('ReportDate')
        currentUrl.search = searchParams.toString()

        const newUrl = currentUrl.href
        window.history.replaceState({path: newUrl}, '', newUrl)

        const formatted = dayjs(reportDate).format('YYYY-MM-DDT00:00:00[Z]')
        setStartDate(new Date(formatted))
      }
    }
  }, [])

  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const searchParams = currentUrl?.searchParams
    const from = searchParams?.get('source')
    if (from === 'teamdashboard') {
      handleTeamDashboardRedirect()
    }
  }, [])

  const handleTeamDashboardRedirect = () => {
    try {
      const currentUrl = new URL(window.location.href)
      const searchParams = currentUrl?.searchParams
      const userName = searchParams?.get('name')
      const userId = searchParams?.get('user')
      const queryDate = searchParams?.get('date')
      const formattedDate = dayjs(queryDate, 'DD/MM/YYYY').toDate()
      setStartDate(formattedDate)

      setAttendance([])
      setOptionSelectedMember({
        label: userName,
        value: userId,
      })
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: userName,
          selectedUserId: userId,
        })
      )
    } catch (error) {
      ErrorModal()
    }
  }

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(attendance.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(attendance.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, attendance])

  useEffect(() => {
    if (
      optionSelectedMember !== null &&
      optionSelectedMember.value !== undefined &&
      startDate !== null
    )
      CallOfEmp(
        optionSelectedMember.value,
        dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        endDate
          ? dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]')
          : dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]')
      )
  }, [optionSelectedMember, startDate, endDate])

  const earlyStageCall = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    setAttendance([])
    setScreenLoader(true)
    setItemOffset(0)
    //setForcePage(0)
  }

  const SendDataOfDate = (start: any, end: any) => {
    setDateError(false)
    earlyStageCall()

    if (start == null && end == null) {
      setDateError(true)
      setScreenLoader(false)
    } else if (start && end == null) {
      setShowStartDate(dayjs(start).format('DD/MM/YYYY'))
      setShowEndDate(null)
      end = start
    } else {
      setShowStartDate(dayjs(start).format('DD/MM/YYYY'))
      setShowEndDate(dayjs(end).format('DD/MM/YYYY'))
    }
  }

  const CallOfEmp = (userIds?: any, beginDate?: any, lastDate?: any) => {
    setItemOffset(0)
    setCurrentItems([])
    setForcePage(null)
    setScreenLoader(true)
    if (userIds === 'All') {
      let OrganizationId = localStorage.getItem('org_Id')
      let UserId = localStorage.getItem('userId')
      let FromDate = beginDate ? beginDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]')
      let ToDate = lastDate ? lastDate : startDate
      cancelTokenSource = axios.CancelToken.source()
      axios
        .get(`${API_URL}/Calculate/GetUsersBifurcationTotalTimeByReportManager`, {
          params: {
            OrganizationId,
            UserId,
            FromDate,
            ToDate,
          },
          cancelToken: cancelTokenSource?.token,
        })
        .then((res) => {
          if (!Array.isArray(res.data)) {
            setAttendance([res.data])
            setScreenLoader(false)
          } else {
            setAttendance(res.data)
            setScreenLoader(false)
          }
        })
        .catch((err) => {
          console.log(err)
          setScreenLoader(false)
        })
    } else {
      const body = {
        organizationId: localStorage.getItem('org_Id'),
        userId: userIds,
        fromDate: beginDate ? beginDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        toDate: lastDate ? lastDate : startDate,
      }
      cancelTokenSource = axios.CancelToken.source()
      axios
        .post(`${API_URL}/Calculate/GetUsersBifurcationTotalTime`, body, {
          cancelToken: cancelTokenSource?.token,
        })
        .then((res) => {
          if (!Array.isArray(res.data)) {
            setAttendance([res.data])
            setScreenLoader(false)
          } else {
            setAttendance(res.data)
            setScreenLoader(false)
          }
        })

        .catch((err: any) => {
          if (err.code != 'ERR_CANCELED') {
            setScreenLoader(false)
          }
        })
    }
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    SendDataOfDate(start, end)
    setItemOffset(0)
    setForcePage(null)
  }

  const handleUserChange = (user: any) => {
    if (SelectedUser.selectedUserId !== user.value && user.value !== 'All') {
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: user.label,
          selectedUserId: user.value,
        })
      )
    }
    if (user.value === 'All') {
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: undefined,
          selectedUserId: undefined,
        })
      )
    }
    setOptionSelectedMember(user)
    setItemOffset(0)
    setForcePage(null)
  }

  const createExcel = async (e: any) => {
    e.preventDefault()

    const workbook = XLSXUtils.book_new()
    const body = {
      fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      toDate: endDate
        ? dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]')
        : dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      organizationId: localStorage.getItem('org_Id'),
      userType: optionSelectedMember.value === 'All' ? 'Team' : 'Individual',
      userId:
        optionSelectedMember.value === 'All'
          ? localStorage.getItem('userId')
          : optionSelectedMember.value,
    }

    const report = await axios.post(`${API_URL}/Calculate/DownloadTimeTrackerReport`, body)

    const detailedData = report.data.filter((data: any) => data.reportDate)

    const detailedWorksheetData = detailedData.map((data: any) => {
      return [
        data.employeeName,
        data.reportDate,
        data.inTime === '0001-01-01T00:00:00' ? '' : data.inTime,
        data.outTime === '0001-01-01T00:00:00' ? '' : data.outTime,
        data.attendance,
        data.email,
        data.role,
        data.department,
        data.reportHead,
        convertSecIntoHoursMinSec(data.activeTime),
        convertSecIntoHoursMinSec(data.productiveTime),
        convertSecIntoHoursMinSec(data.unproductiveTime),
        convertSecIntoHoursMinSec(data.idleTime),
        convertSecIntoHoursMinSec(data.awayTime),
        convertSecIntoHoursMinSec(data.totalTime),
      ]
    })

    const detailedWorksheet = XLSXUtils.aoa_to_sheet([
      [
        'Name',
        'Date',
        'In Time',
        'Out Time',
        'Attendance',
        'Email',
        'Role',
        'Department',
        'Report Head',
        'Active Time',
        'Productive Time',
        'Unproductive Time',
        'Idle Time',
        'Away Time',
        'Total Time',
      ],
      ...detailedWorksheetData,
    ])

    XLSXUtils.book_append_sheet(workbook, detailedWorksheet, 'Detailed')
    //to create summary shert
    const summaryData = report.data.filter((data: any) => !data.reportDate)

    const summaryWorksheetData = summaryData.map((data: any) => {
      return [
        data.employeeName,
        data.email,
        data.role,
        data.department,
        data.reportHead,
        convertSecIntoHoursMinSec(data.activeTime),
        convertSecIntoHoursMinSec(data.productiveTime),
        convertSecIntoHoursMinSec(data.unproductiveTime),
        convertSecIntoHoursMinSec(data.idleTime),
        convertSecIntoHoursMinSec(data.awayTime),
        convertSecIntoHoursMinSec(data.totalTime),
      ]
    })

    const summaryWorksheet = XLSXUtils.aoa_to_sheet([
      [
        'Name',
        'Email',
        'Role',
        'Department',
        'Report Head',
        'Active Time',
        'Productive Time',
        'Unproductive Time',
        'Idle Time',
        'Away Time',
        'Total Time',
      ],
      ...summaryWorksheetData,
    ])

    XLSXUtils.book_append_sheet(workbook, summaryWorksheet, 'Summary')

    const formattedStart = parseISO(dayjs(startDate).format())
    const formattedEnd = parseISO(dayjs(endDate ? endDate : startDate).format())

    let currentDate = formattedStart
    while (isBefore(currentDate, formattedEnd) || isEqual(currentDate, formattedEnd)) {
      const currentDateString = dayjs(currentDate).format('DD-MM-YYYY')
      const dateData = report.data.filter(
        (data: any) => dayjs(data.reportDate).format('DD-MM-YYYY') === currentDateString
      )
      const dateWorksheetData = dateData.map((data: any) => {
        return [
          data.employeeName,
          data.reportDate,
          data.inTime === '0001-01-01T00:00:00' ? '' : data.inTime,
          data.outTime === '0001-01-01T00:00:00' ? '' : data.outTime,
          data.attendance,
          data.email,
          data.role,
          data.department,
          data.reportHead,
          convertSecIntoHoursMinSec(data.activeTime),
          convertSecIntoHoursMinSec(data.productiveTime),
          convertSecIntoHoursMinSec(data.unproductiveTime),
          convertSecIntoHoursMinSec(data.idleTime),
          convertSecIntoHoursMinSec(data.awayTime),
          convertSecIntoHoursMinSec(data.totalTime),
        ]
      })

      const dateWorksheet = XLSXUtils.aoa_to_sheet([
        [
          'Name',
          'Date',
          'In Time',
          'Out Time',
          'Attendance',
          'Email',
          'Role',
          'Department',
          'Report Head',
          'Active Time',
          'Productive Time',
          'Unproductive Time',
          'Idle Time',
          'Away Time',
          'Total Time',
        ],
        ...dateWorksheetData,
      ])

      XLSXUtils.book_append_sheet(workbook, dateWorksheet, dayjs(currentDate).format('D MMMM YYYY'))
      currentDate = addDays(currentDate, 1)
    }

    const fileBuffer = write(workbook, {type: 'buffer', bookType: 'xlsx'})
    const blob = new Blob([fileBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      `${showstartdate}-${showenddate ? showenddate : showstartdate}.xlsx`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <div className='card-body'>
        <div className='tab-content pt-3'>
          <div>
            <div className='card-header border-0 pt-5'>
              <div
                className='d-flex justify-content-between align-items-center'
                style={{padding: '10px 16px'}}
              >
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Time Tracker</span>
                </h3>
                <div className='d-flex  align-items-center'>
                  <div>
                    <div
                      style={{width: '200px', marginRight: '1rem'}}
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title='Search Member'
                    >
                      <Select
                        className='selectDropdown'
                        components={makeAnimated()}
                        value={optionSelectedMember}
                        options={memberOption}
                        placeholder='select member'
                        onChange={(item: any) => {
                          handleUserChange(item)
                        }}
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-column'>
                    {MultiDateTimePicker(
                      startDate,
                      endDate,
                      handleDateChange,
                      'form-control custom-Height'
                    )}

                    {dateError && (
                      <span className='text-danger' style={{marginLeft: '1rem'}}>
                        Please Select Date
                      </span>
                    )}
                  </div>

                  <div className='d-flex '>
                    <button
                      type='button'
                      onClick={(e) => createExcel(e)}
                      className='btn btn-primary'
                      style={{marginLeft: '1rem', height: '3.2rem'}}
                    >
                      <span className='indicator-label'>
                        <i className='fa fa-download'></i>Download
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className='card-body py-3'>
                <div className='table-responsive'>
                  {TimeTrackerTable(
                    attendance,
                    currentItems,
                    convertSecIntoHoursMinSec,
                    convertSecIntoAMPM
                  )}

                  {screenloader
                    ? Loader('100px')
                    : currentItems.length === 0 && (
                        <h2 className='noRecordFound'>No Records Found</h2>
                      )}
                </div>
              </div>
              {attendance.length > 10 && (
                <div className='d-flex align-items-center justify-content-center position-relative mb-7'>
                  <div style={{left: '1rem', position: 'absolute', top: '0'}}>
                    {RowsPerPage(
                      setForcePage,
                      setRowPerPage,
                      setItemOffset,
                      rowPerPage,
                      attendance.length,
                      '1rem'
                    )}
                  </div>

                  {attendance.length > rowPerPage &&
                    Pagination(handlePageClick, pageCount, forcePage)}
                </div>
              )}
              {/* {attendance.length > 10 && (
                <div className='d-flex align-items-center justify-content-center'>
                  {Pagination(handlePageClick, pageCount, 0)}
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {AttendanceSheetManager}
