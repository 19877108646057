import axios from 'axios'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useNavigate, Link, useSearchParams} from 'react-router-dom'
import {AlertModal} from '../../CommonFunctions/CommonFunction'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Yup from 'yup'

const EditTask: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState<any[]>([])
  const [projectValue, setProjectValue] = useState<any>()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const API = process.env.REACT_APP_API_URL

  useEffect(() => {
    axios
      .get(`${API}/Projects/GetProjectByOrganisation`, {
        params: {
          id: localStorage.getItem('org_Id'),
        },
      })
      .then((res: any) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.projectName,
            value: item.projectId,
          }
        })
        setProjects(newList)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    const taskId = searchParams.get('taskId')
    axios
      .get(`${API}/UserTask/GetTaskDetailById?taskId=${taskId}`)
      .then((res: any) => {
        formik.setFieldValue('taskId', taskId)
        formik.setFieldValue('projectId', res.data.projectId)
        formik.setFieldValue('taskName', res.data.taskName)
        setProjectValue([{label: res.data.projectName, value: res.data.projectId}])
        searchParams.delete('taskId')
        setSearchParams(searchParams)
      })
      .catch((err) => console.error(err))
  }, [])

  const initialValues = {
    taskId: '',
    taskName: '',
    projectId: '',
    organizationId: localStorage.getItem('org_Id'),
  }

  const checkShift = Yup.object().shape({
    taskName: Yup.string().required('Name is required'),
    projectId: Yup.string().required('Project is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: checkShift,
    onSubmit: (values) => {
      setLoading(true)
      axios
        .post(`${API}/UserTask/UpdateTask`, values)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success === false ? 'warning' : 'success',
            false,
            '#7066E0',
            'Ok'
          )
          navigate('/task')
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
    },
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='card card-xxl-stretch mb-5'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Task</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='form'>
            <div className='card-body px-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Project</label>
                <div className='col-lg-8 fv-row'>
                  <div className='min-w-200px'>
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      isDisabled
                      value={projectValue}
                      options={projects}
                      placeholder='Select Project'
                      onChange={(item: any) => {
                        setProjectValue(item)
                        formik.setFieldValue('projectId', item.value)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  {formik.touched.projectId && formik.errors.projectId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.projectId}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Name</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-border'
                    placeholder='Name'
                    {...formik.getFieldProps('taskName')}
                  />
                  {formik.touched.taskName && formik.errors.taskName && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.taskName}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='d-flex w-100 justify-content-end'>
                <div className=' d-flex justify-content-end py-6'>
                  <Link to='/task'>
                    <button type='button' className='btn btn-secondary mx-5'>
                      Cancel
                    </button>
                  </Link>
                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && <span className='indicator-label'>Update</span>}
                    {loading && (
                      <span className='indicator-progress d-block'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditTask
