/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import '../../../../css/dashboard.css'

type Props = {
  className: string
  color: string
  bgcolor: string
  iconColor: string
  descriptions: string
  title: string
  description: string
  img?: string
  percent?: any
  action?: any
  date?: any
  depart?: any
  cursorType?: any
}

const StatisticsWidget5work: React.FC<Props> = ({
  className,
  color,
  bgcolor,
  iconColor,
  descriptions,
  title,
  description,
  img,
  percent,
  action,
  date,
  depart,
  cursorType,
}) => {
  return (
    <a className={`card hoverable ${className} custom-dashboard-cards  ${cursorType}`}>
      {/* begin::Body */}
      <div className='card-body' style={{background: `${bgcolor}`}}>
        <div
          className={`text-inverse-${color} fw-bolder fs-5 mb-2`}
          style={{padding: '15px 18px', background: 'rgba(68, 68, 68, 0.29)'}}
        >
          {descriptions}
        </div>
        <div style={{display: 'flex', padding: '15px 22px', justifyContent: 'space-between'}}>
          {percent ? (
            <div>
              <span className={`text-inverse-${color} fw-bolder`} style={{fontSize: '15px'}}>
                {percent}
              </span>

              <div className={`text-inverse-${color} fw-bolder`} style={{fontSize: '22px'}}>
                {title}
              </div>
            </div>
          ) : (
            <div>
              <div className={`text-inverse-${color} fw-bolder mb-2 mt-5 dashboard-card-title`}>
                {title}
              </div>
            </div>
          )}

          {img && <img src={img} className='dashboard-card-icon' />}
        </div>
        {/* <div className={`fw-bold text-inverse-${color} fs-9 text-algin:right`}>{description}</div> */}
      </div>
      {/* end::Body */}
    </a>
  )
}

export {StatisticsWidget5work}
