import React, {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  AlertModal,
  GetOrgList,
  Loader,
  handleOrgAdd,
  planSchema,
} from '../../CommonFunctions/CommonFunction'
import {GetBillingPlanByPlanId} from '../../services/Payment.services'
import {useNavigate, useSearchParams} from 'react-router-dom'
import moment from 'moment'
import dayjs from 'dayjs'

const API = process.env.REACT_APP_API_URL

interface SelectedValues {
  organization: any // Adjust the type according to your needs
  date: any // Adjust the type according to your needs
}

const EditPlanDetails: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [planCurrency, setPlanCurrency] = useState<any[]>([])
  const [isCustom, setIsCustom] = useState<any[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [benefits, setBenefits] = useState<any[]>([])
  const [selectedBenefits, setSelectedBenefits] = useState<any[]>([])
  const [planDuration, setPlanDuration] = useState<any[]>([])
  const [selectedValues, setSelectedValues] = useState<SelectedValues[]>([])
  const [orgCount, setOrgCount] = useState(1)
  const [orgErrors, setOrgErrors] = useState(Array.from({length: orgCount}, () => false))
  const [dateErrors, setDateErrors] = useState(Array.from({length: orgCount}, () => false))
  const [filteredDropdown, setFilteredDropdown] = useState<any[]>([])
  const [organizationDropdown, setOrganizationDropdown] = useState<any[]>([])

  const initialValues = {
    planId: '',
    planName: '',
    planTypeId: '',
    planDescription: '',
    currencyCodeId: '',
    amount: '',
    benefitIds: [],
    isCustomPlan: false,
    customBillingPlans: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: planSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setLoading(true)

      if (values.isCustomPlan === true && selectedValues.length === 0) {
        // Set setOrgErrors to true at index 0
        const updatedErrors = [...orgErrors]
        updatedErrors[0] = true
        setOrgErrors(updatedErrors)

        setLoading(false)
        return
      } else if (values.isCustomPlan === true) {
        const lastIdx = orgCount - 1
        const lastOrg = selectedValues[lastIdx]?.organization
        const lastDate = selectedValues[lastIdx]?.date

        if (!lastOrg) {
          const updatedErrors = [...orgErrors]
          updatedErrors[lastIdx] = true
          setOrgErrors(updatedErrors)
          setLoading(false)
          return
        } else if (!lastDate) {
          setLoading(false)
          const updatedErrors = [...dateErrors]
          updatedErrors[lastIdx] = true
          setDateErrors(updatedErrors)
          return
        }
      }

      if (values.isCustomPlan === true) {
        let arr: any = []

        for (let i = 0; i < selectedValues.length; i++) {
          arr.push({
            organizationId: selectedValues[i].organization.value,
            effectiveFromDate: dayjs(selectedValues[i].date).format('YYYY-MM-DDThh:MM:ssZ'),
          })
        }

        values.customBillingPlans = arr
      } else {
        values.customBillingPlans = []
      }

      const body = {...values, amount: String(values.amount)}

      axios
        .post(`${API}/BillingPlan/UpdateBillingPlan`, body)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            navigate('/allPlans')
            resetForm()
            setLoading(false)
          })
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setSubmitting(false)
        })

      // resetForm()
      setLoading(false)
    },
  })

  useEffect(() => {
    getPlanDetails()
  }, [])

  const getPlanDetails = async (): Promise<void> => {
    try {
      setLoading(true)
      const planId = searchParams.get('planId')
      formik.setFieldValue('planId', planId)
      const planDetails = await GetBillingPlanByPlanId(planId)
      if (!planDetails.data.planName) {
        AlertModal('No matching plan found', '', 'error', false, '#7066E0', 'Ok').then(() => {
          navigate('/allPlans')
        })
      }

      //get the organization list
      const getOrgList = await OrgList()
      setOrganizationDropdown(getOrgList)
      setFilteredDropdown(getOrgList)

      //get the plan types list
      const activePlansTypeList = await ActiveListType()
      setPlanDuration(activePlansTypeList)

      //get the features list
      const activePlansFeatures = await ActivePlanFeaturesList()
      setBenefits(activePlansFeatures)

      //get the currency codes
      const currencyList = await CurrencyCodeList()
      setPlanCurrency(currencyList)

      //set the default values
      const duration = activePlansTypeList.find(
        (data: any) => data.label === planDetails.data['planTypeName']
      )
      formik.setFieldValue('planTypeId', duration?.value || '')

      const currency = currencyList.find(
        (data: any) => data.label === planDetails.data['currencyCode']
      )
      formik.setFieldValue('currencyCodeId', currency?.value || '')
      const selectedFeatures = activePlansFeatures.filter((feature: any) =>
        planDetails.data.benefits.includes(feature.label)
      )
      const benefitsArr = selectedFeatures.map((feature: any) => feature.value)
      formik.setFieldValue('benefitIds', benefitsArr)
      setSelectedBenefits(selectedFeatures)
      formik.setFieldValue('amount', planDetails.data['amount'])
      formik.setFieldValue('planName', planDetails.data['planName'])
      formik.setFieldValue('planDescription', planDetails.data['planDescription'])
      formik.setFieldValue('isCustomPlan', planDetails.data['isCustomPlan'])

      setIsCustom([
        {
          label: planDetails.data['isCustomPlan'] ? 'Yes' : 'No',
          value: planDetails.data['isCustomPlan'],
        },
      ])

      if (planDetails.data.customPlanData) {
        let arr: any = []
        let orgCount = 0
        for (let i = 0; i < planDetails.data.customPlanData.length; i++) {
          orgCount++
          // if (i !== 1) {
          //   setOrgCount((prevOrgCount) => prevOrgCount + 1)
          // }

          const orgInfo = getOrgList.find(
            (org: any) => org.value === planDetails.data.customPlanData[i].organizationId
          )

          const selectedValue = {
            organization: {
              label: orgInfo ? orgInfo.label : 'Organization',
              value: planDetails.data.customPlanData[i].organizationId,
            },
            date: new Date(planDetails.data.customPlanData[i].effectiveFromDate),
          }

          arr.push(selectedValue)
        }

        setOrgCount(orgCount > 0 ? orgCount : 1)
        setSelectedValues(arr)

        const updatedDropdown = getOrgList.filter(
          (data: any) => !arr.some((value: any) => value.organization.value === data.value)
        )
        setFilteredDropdown(updatedDropdown)
      }
      setLoading(false)
      //set the custom organizations
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoading(false)
      AlertModal('Something went wrong!', '', 'error', false, '#7066E0', 'Ok').then(() => {
        navigate('/allPlans')
      })
      // Handle error as needed
    }
  }

  const OrgList = async () => {
    try {
      const orgList = await GetOrgList()

      return orgList
    } catch (error) {
      console.log(error)
    }
  }

  //to get the list of plan type
  const ActiveListType = async () => {
    try {
      const getListData = await axios.get(`${API}/PlanTypes/GetAllActivePlanTypesList`)
      return getListData.data.map((data: any) => ({
        label: data.planTypeName,
        value: data.planTypeId,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  //to get the list of features
  const ActivePlanFeaturesList = async () => {
    try {
      const getListData = await axios.get(`${API}/PlanFeatures/GetAllActivePlanFeatures`)
      let benefitsArr: any = []
      const list = getListData.data.map((data: any) => {
        benefitsArr.push(data.planFeatureId)

        return {
          label: data.planFeature,
          value: data.planFeatureId,
        }
      })

      return list
    } catch (error) {
      console.log(error)
    }
  }

  //to get the currency codes
  const CurrencyCodeList = async () => {
    try {
      const getListData = await axios.get(`${API}/CurrencyCodes/GetAllActiveCurrencyCodeList`)
      return getListData.data.map((data: any) => ({
        label: data.currencyCode,
        value: data.currencyCodeId,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const handleOrganizationDropChange = (item: any, index: number) => {
    const updatedValues = [...selectedValues]

    updatedValues[index] = {...updatedValues[index], organization: item}

    setSelectedValues(updatedValues)

    // Reset the error for the specific index
    const updatedErrors = [...orgErrors]
    updatedErrors[index] = false
    setOrgErrors(updatedErrors)

    const updatedDropdown = organizationDropdown.filter(
      (data) => !updatedValues.some((value) => value.organization.value === data.value)
    )
    setFilteredDropdown(updatedDropdown)
  }

  // to handle the change in the date
  const handleDateChange = (date: any, index: number) => {
    const updatedValues = [...selectedValues]

    updatedValues[index] = {...updatedValues[index], date: date}
    // Reset the error for the specific index
    const updatedErrors = [...dateErrors]
    updatedErrors[index] = false
    setDateErrors(updatedErrors)

    setSelectedValues(updatedValues)
  }

  const handleOrgRemove = (index: number) => {
    const updatedValues = [...selectedValues]
    updatedValues.splice(index, 1)
    setSelectedValues(updatedValues)
    const updatedDropdown = organizationDropdown.filter(
      (data) => !updatedValues.some((value) => value.organization === data)
    )
    setFilteredDropdown(updatedDropdown)
    if (orgCount > 1) {
      setOrgCount(orgCount - 1)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0' role='banner'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Plan Details</h3>
        </div>
      </div>
      {loading ? (
        Loader('0')
      ) : (
        <div className='collapse show'>
          <form onSubmit={formik.handleSubmit} className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Plan Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-border '
                    placeholder='Plan Name'
                    {...formik.getFieldProps('planName')}
                  />
                  {formik.touched.planName && formik.errors.planName && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.planName}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Description</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-border '
                    placeholder='Description'
                    {...formik.getFieldProps('planDescription')}
                  />
                  {formik.touched.planDescription && formik.errors.planDescription && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.planDescription}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Duration</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Currency'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={planDuration}
                      onBlur={formik.handleBlur}
                      value={planDuration.find(
                        (item) => item.value === formik.getFieldProps('planTypeId').value
                      )}
                      placeholder='Select Duration'
                      onChange={(item: any) => {
                        formik.setFieldValue('planTypeId', item.value)
                      }}
                      isClearable={true}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  <div>
                    {formik.touched.planTypeId && formik.errors.planTypeId && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.planTypeId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Currency</label>
                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Currency'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={planCurrency}
                      value={planCurrency.find(
                        (item) => item.value === formik.getFieldProps('currencyCodeId').value
                      )}
                      onBlur={formik.handleBlur}
                      placeholder='Select Currency'
                      onChange={(item: any) => {
                        formik.setFieldValue('currencyCodeId', item.value)
                      }}
                      isClearable={true}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  <div>
                    {formik.touched.currencyCodeId && formik.errors.currencyCodeId && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.currencyCodeId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Amount</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='number'
                    className='form-control form-control-border '
                    placeholder='Amount'
                    {...formik.getFieldProps('amount')}
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.amount}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 '>Benefits</label>
                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    //style={{ width: '200px' }}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Department'
                  >
                    <Select
                      className='selectDropdown'
                      isMulti
                      components={makeAnimated()}
                      options={benefits}
                      value={selectedBenefits}
                      onBlur={formik.handleBlur}
                      placeholder='Benefits'
                      onChange={(item: any) => {
                        setSelectedBenefits(item)
                        const array = item.map((item: any) => item.value)
                        formik.setFieldValue('benefitIds', array)
                      }}
                      isClearable={true}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                      closeMenuOnSelect={false}
                    />
                  </div>
                  <div>
                    {formik.touched.benefitIds && formik.errors.benefitIds && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.benefitIds}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Custom Plan</label>
                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    //style={{ width: '200px' }}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Department'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={[
                        {label: 'Yes', value: true},
                        {label: 'No', value: false},
                      ]}
                      value={isCustom}
                      onBlur={formik.handleBlur}
                      menuPlacement='auto'
                      menuPosition='fixed'
                      placeholder='Custom Plan'
                      onChange={(item: any) => {
                        // if (item.value === false) {
                        //   setSelectedValues([])
                        // }
                        setSelectedValues([])
                        setIsCustom(item)
                        formik.setFieldValue('isCustomPlan', item !== null ? item.value : '')
                        //SendDataOfUser(item.value)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  <div>
                    {formik.touched.isCustomPlan && formik.errors.isCustomPlan && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.isCustomPlan}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {formik.getFieldProps('isCustomPlan').value === true ? (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                    Organization
                  </label>

                  <div className='col-lg-8 fv-row'>
                    {Array.from({length: orgCount}, (_, index) => (
                      <div className='mt-2'>
                        <div className='d-flex ' style={{justifyContent: 'space-between'}}>
                          <div
                            className='w-350px'
                            //style={{ width: '200px' }}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Search Organization'
                          >
                            <Select
                              className='selectDropdown'
                              components={makeAnimated()}
                              options={filteredDropdown}
                              value={selectedValues[index]?.organization}
                              onBlur={formik.handleBlur}
                              placeholder='Select Organization'
                              menuPlacement='auto'
                              menuPosition='fixed'
                              onChange={(item: any) => {
                                // setSelectedOrg(item)
                                handleOrganizationDropChange(item, index)
                                //SendDataOfUser(item.value)
                              }}
                              isClearable={true}
                              isSearchable={true}
                              closeMenuOnScroll={true}
                            />
                            {orgErrors[index] && (
                              <span className='text-danger' style={{marginLeft: '1rem'}}>
                                Please select an organization
                              </span>
                            )}
                          </div>

                          <div
                            className='w-350px'
                            //style={{ width: '200px' }}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Search Organization'
                          >
                            <DatePicker
                              // className='form-control custom-Height'

                              className='form-control w-100'
                              dateFormat='dd/MM/yyyy'
                              selected={selectedValues[index]?.date}
                              onChange={(date: any) => {
                                handleDateChange(date, index)
                              }}
                              isClearable={false}
                              placeholderText='Select an effective date'
                              maxDate={moment().add(1, 'year').toDate()}
                              minDate={new Date()}
                              onKeyDown={(e) => {
                                e.preventDefault()
                              }}
                            />
                            {dateErrors[index] && (
                              <span className='text-danger' style={{marginLeft: '1rem'}}>
                                Please Select an effective date
                              </span>
                            )}
                          </div>
                          <div style={{paddingTop: '0.5rem'}}>
                            {index !== 0 && (
                              <i
                                className='fa fa-times'
                                onClick={() => handleOrgRemove(index)}
                                aria-hidden='true'
                                style={{
                                  fontSize: '1.5rem',
                                  display: 'flex',
                                  flexDirection: 'column',

                                  cursor: 'pointer',
                                }}
                              ></i>
                            )}
                          </div>
                        </div>
                        {/* <div>
                        {formik.touched.organization && formik.errors.organization && (
                          <div className='fv-plugins-message-container'>
                            <div className='text-danger mt-2'>{formik.errors.organization}</div>
                          </div>
                        )}
                      </div> */}
                      </div>
                    ))}

                    <button
                      className='btn btn-primary mt-2'
                      type='button'
                      onClick={() =>
                        handleOrgAdd(
                          orgCount,
                          selectedValues,
                          setOrgCount,
                          orgErrors,
                          setOrgErrors,
                          dateErrors,
                          setDateErrors
                        )
                      }
                    >
                      Add More Organization
                    </button>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => navigate('/allPlans')}
              >
                Cancel
              </button>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && <span className='indicator-label'>Save Changes</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default EditPlanDetails
