import React, {FC} from 'react'
import { EditDepartment } from './cards/editDepartment'




const EditDeparmentWrapper: FC = () =>{
  return (
    <>
      
      <EditDepartment></EditDepartment>
      
    </>
  )
}
export {EditDeparmentWrapper}
