import React, {useEffect, useState} from 'react'
import {
  callAllManager,
  departmentInfoByOrg,
  memberInfoByOrg,
} from '../../modules/auth/redux/AuthCRUD'
import {manipulateResponseData} from '../Common_Function/Function'
import {GetAssignee} from '../../services/GetAllAssinee.services'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import {AlertModal, Loader} from '../../CommonFunctions/CommonFunction'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {List as VirtualizedList} from 'react-virtualized'
import {getMembersByDepartment} from '../../services/memberInfo.service'
import RowConfigWithToggle from './RowConfigWithToggle'
import axios from 'axios'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
const API = process.env.REACT_APP_API_URL

const BulkConfigUpdate: React.FC = () => {
  const role = localStorage.getItem('role')
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [alignment, setAlignment] = React.useState('Organization')
  const [memberOption, setMemberOption] = useState<any[]>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [disableReactSelect, setDisableReactSelect] = useState<boolean>(true)
  const [currDisplayState, setCurrDisplayState] = useState<any>(
    role === process.env.REACT_APP_SYSTEM_SECOND_ROLE ? 'User' : 'Individual'
  )
  const [memberError, setMemberError] = useState<boolean>(false)
  const [listOfMember, setListOfMembers] = useState<[]>([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [filterdata, setFilterData] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [isCheck, setIsCheck] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [changeData, setChangeData] = useState<any[]>([])
  const [resetValue, setResetValue] = useState<string>('')

  const handleChange = async (event: any, newAlignment: string) => {
    setDisableReactSelect(true)
    if (currDisplayState === event.target.value) {
      setDisableReactSelect(false)
    } else {
      if (event.target.value === 'Organization') {
        setSpinner(true)
        setIsCheck([])
        setIsCheckAll(false)
        setChangeData([])
        setDisableReactSelect(true)
        setAlignment(event.target.value)
        setOptionSelectedMember(null)
        callAllMemberAPI()
        setMemberError(false)
        setResetValue(event.target.value)
      } else if (event.target.value === 'Team') {
        setIsCheck([])
        setIsCheckAll(false)
        setChangeData([])
        setAlignment(event.target.value)
        setOptionSelectedMember(null)
        callAllManagerAPI()
        setMemberError(true)
        setResetValue(event.target.value)
      } else if (event.target.value === 'Department') {
        setIsCheck([])
        setIsCheckAll(false)
        setChangeData([])
        setAlignment(event.target.value)
        setOptionSelectedMember(null)
        calAllDepartAPI()
        setMemberError(true)
        setResetValue(event.target.value)
      }
      setCurrDisplayState(event.target.value)
    }
  }

  const calAllDepartAPI = () => {
    departmentInfoByOrg().then((res) => {
      const newList = res.data.map((item: any) => {
        return {
          label: item.department,
          value: item.departmentId,
        }
      })
      setMemberOption(newList)
      if (res.data.length > 0) {
        setDisableReactSelect(false)
      }
    })
  }

  const callAllMemberAPI = () => {
    setSpinner(true)
    memberInfoByOrg()
      .then((res) => {
        const newList = manipulateResponseData(res.data)
        setListOfMembers(newList)
        setFilterData(newList)
        setSpinner(false)
      })
      .catch((err) => {
        console.log(err)
        setSpinner(false)
      })
  }

  const callAllManagerAPI = () => {
    callAllManager().then((res) => {
      const newList = manipulateResponseData(res.data)
      setMemberOption(newList)
      if (res.data.length > 0) {
        setDisableReactSelect(false)
      }
    })
    setSpinner(false)
  }

  useEffect(() => {
    setSpinner(true)
    callAllMemberAPI()
  }, [])

  const handleSearchChange = (event: any) => {
    const query = event.target.value.toLowerCase()
    const data = listOfMember.filter((list: any) => list.label.toLowerCase().includes(query))
    setFilterData(data)
  }

  const handleSelectAll = (e: any) => {
    const isChecked = e.target.checked
    setIsCheckAll(isChecked)
    if (isChecked) {
      const assigneeIds = filterdata.map((member: any) => member.value)
      setIsCheck(assigneeIds)
    } else {
      setIsCheck([])
    }
  }

  const handleClick = (e: any) => {
    const {id, checked} = e.target
    setIsCheck((prevIsCheck: any) => {
      if (checked) {
        return [...prevIsCheck, id]
      } else {
        return prevIsCheck.filter((item: any) => item !== id)
      }
    })

    isCheck.length === listOfMember.length - 1 ? setIsCheckAll(true) : setIsCheckAll(false)
  }

  const editBody = (fieldName: string, value: boolean | string) => {
    const secondList = changeData.some((item) => item.configName == fieldName)
    if (!secondList) {
      setChangeData((prev) => [...prev, {configName: fieldName, configValue: value}])
    } else {
      changeData.forEach((item) => {
        if (item.configName === fieldName) {
          item.configValue = value
        }
      })
    }
  }

  const userIdWithConfig = () => {
    setLoading(true)
    const newData = changeData.filter((item) => {
      return item.configValue !== ''
    })
    let body: any = {
      userIds: isCheck,
      configNameAndValues: newData,
    }

    if (body.userIds.length == 0 || body.configNameAndValues.length == 0) {
      AlertModal(
        body.userIds.length == 0 ? 'Please Select User' : 'No Configuration has been Selected',
        '',
        'warning',
        false,
        '#7066E0',
        'Ok'
      )
      setLoading(false)
      return
    }

    axios
      .post(`${API}/Registration/BulkUpdateUserConfig`, body)
      .then((res) => {
        AlertModal(
          res.data.message,
          '',
          res.data.success ? 'success' : 'warning',
          false,
          '#7066E0',
          'Ok'
        )

        setLoading(false)
        setResetValue('reset')
        setIsCheck([])
        setChangeData([])
        setIsCheckAll(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const sendListofMembers = (id: any) => {
    setSpinner(true)
    if (currDisplayState == 'Team') {
      GetAssignee(id)
        .then((res: any) => {
          const newList = res.data.map((item: any) => {
            return {
              label: item.assigneeName,
              value: item.assigneeId,
            }
          })
          setFilterData(newList)
          setListOfMembers(newList)
          setMemberError(false)
          setSpinner(false)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      getMembersByDepartment(id).then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.fullName,
            value: item.userId,
          }
        })
        setFilterData(newList)
        setListOfMembers(newList)
        setMemberError(false)
        setSpinner(false)
      })
    }
  }

  return (
    <>
      <div className='card mb-xl-7'>
        {/* begin::Header */}
        <div className='card-header border-0 py-5'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Bulk Configuration Update </span>
            </h3>

            <div className='d-flex'>
              <div className=' d-flex' style={{marginRight: '10px', height: '3.2rem'}}>
                <ToggleButtonGroup
                  color='primary'
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label='Platform'
                >
                  <ToggleButton value='Organization'>Organization</ToggleButton>
                  <ToggleButton value='Team'>Team</ToggleButton>
                  <ToggleButton value='Department'>Department</ToggleButton>
                </ToggleButtonGroup>
              </div>

              <div
                style={{marginRight: '10px', width: '200px'}}
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title={alignment == 'Team' ? 'Search Manager' : 'Search Member'}
              >
                <Select
                  components={makeAnimated()}
                  value={optionSelectedMember}
                  isDisabled={disableReactSelect}
                  //defaultValue={{ label: 'Admin', value: localStorage.getItem('userId') }}
                  options={memberOption}
                  placeholder='Select Member'
                  onChange={(item: any) => {
                    setOptionSelectedMember(item)
                    sendListofMembers(item.value)
                    setResetValue('OrgButton')
                  }}
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnScroll={true}
                />
                {memberError && (
                  <span className='text-danger' style={{marginLeft: '1rem'}}>
                    Please Select Member
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-wrap justify-content-between my-5'>
        <div className='card mb-5 mb-xl-8' style={{width: '49%'}}>
          <div className='card-header border-0 pt-5' style={{flexDirection: 'column'}}>
            <div style={{marginTop: '10px', position: 'relative'}}>
              <input
                type='text'
                id='searchInput'
                style={{marginTop: 'unset', paddingLeft: '3rem'}}
                className='form-control hght form-control-solid '
                placeholder='Search by Name'
                onChange={handleSearchChange}
              />
              <img
                className='custom-placeholder'
                src={toAbsoluteUrl('/media/icons/duotune/art/custom-search.png')}
              ></img>
            </div>
          </div>

          <div className='card-body py-3 px-7' style={{height: '30rem'}}>
            <div className='table-responsive'>
              <div className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <div style={{scrollbarGutter: 'stable', overflowY: 'scroll'}}>
                  {!spinner && (
                    <div className='d-flex fw-bolder fs-6'>
                      <div className='p-0 pt-3'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='selectAll'
                            id='selectAll'
                            onChange={handleSelectAll}
                            checked={isCheckAll}
                          />
                        </div>
                      </div>

                      <div className=' table-header px-6 w-100'>Name</div>
                    </div>
                  )}
                </div>

                <div id='customized'>
                  {spinner && Loader('100px')}

                  {!spinner &&
                    listOfMember.length > 0 &&
                    (!memberError ? (
                      <VirtualizedList
                        height={320}
                        width={550}
                        rowCount={filterdata.length}
                        rowHeight={50}
                        rowRenderer={({index, key, style}) => {
                          const users = filterdata[index]

                          return (
                            <div
                              key={key}
                              style={style}
                              className='d-flex align-items-center border-bottom min-w-500px'
                            >
                              <div style={{paddingRight: '0.75rem'}}>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input widget-13-check'
                                    key={users.value}
                                    type='checkbox'
                                    id={users.value}
                                    onChange={handleClick}
                                    checked={isCheck.includes(users.value)}
                                  />
                                </div>
                              </div>
                              <div style={{padding: '0.75rem'}}>
                                <div className='d-flex align-items-center'>
                                  <div
                                    className='d-flex justify-content-start flex-column text'
                                    style={{fontSize: '1.1rem'}}
                                  >
                                    {users.label}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }}
                      />
                    ) : (
                      <h2 className='noRecordFound'>Please Select User</h2>
                    ))}

                  {!spinner &&
                    listOfMember.length == 0 &&
                    (memberError ? (
                      <h2 className='noRecordFound'>Please Select User</h2>
                    ) : (
                      <h2 className='noRecordFound'>No Record Found</h2>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card mb-5 mb-xl-8' style={{width: '49%'}}>
          <div className='mx-5 my-10 mt-20'>
            {/* <div className='d-flex justify-content-between mb-4 mx-5 px-5 border-bottom'>
                            <label className=' col-form-label fw-bolder fs-6 fs-4 mx-10'>Name</label>
                            <label className=' col-form-label fw-bolder fs-6 fs-4'>Update</label>
                        </div> */}

            <RowConfigWithToggle
              addValueInList={editBody}
              title='Capture Screenshot'
              id='IsScreenShotEnable'
              reset={resetValue}
            />

            {ConfigDetails.isEnableLiveStreaming && (
              <RowConfigWithToggle
                addValueInList={editBody}
                title='Live Stream'
                id='IsLiveStreamEnable'
                reset={resetValue}
              />
            )}

            {ConfigDetails.isEnableLiveRecording && (
              <RowConfigWithToggle
                addValueInList={editBody}
                title='Live Recording'
                id='IsLiveRecordingEnable'
                reset={resetValue}
              />
            )}

            <RowConfigWithToggle
              addValueInList={editBody}
              title='Notification'
              id='IsNotificationEnable'
              reset={resetValue}
            />

            <RowConfigWithToggle
              addValueInList={editBody}
              title='Stop Timer Enable'
              id='IsStopTimerEnable'
              reset={resetValue}
            />

            {ConfigDetails.isEnableManualTracking && (
              <RowConfigWithToggle
                addValueInList={editBody}
                title='Private Mode'
                id='IsManualTrackingEnable'
                reset={resetValue}
              />
            )}
          </div>
          <div className='position-absolute py-4 px-9 bottom-0' style={{right: '0'}}>
            <button className='btn btn-primary' disabled={loading} onClick={userIdWithConfig}>
              {!loading && <span className='indicator-label'>update</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BulkConfigUpdate
