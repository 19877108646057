/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'

import {
  Loader,
  BuilderPageSelect,
  SingleDatePickerComponent,
} from '../../CommonFunctions/CommonFunction'
import {convertSecIntoHoursMinSec} from '../../utils/ConvertSeconds.utils'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import clsx from 'clsx'

import {AdminChart} from '../adminDashboard/AdminChart'
import {AdminWebVsApp} from '../adminDashboard/AdminWeb&App'
import {AdminProdVsUnProd} from '../adminDashboard/AdminProdVsUnProd'
import {AdminStats} from '../adminDashboard/AdminStats'
import dayjs from 'dayjs'
import {AdminUnproductive} from '../adminDashboard/AdminUnproductive'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import axios, {CancelTokenSource} from 'axios'

const API = process.env.REACT_APP_API_URL
const BuilderPageAdmin = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [alignment, _] = React.useState('Individual')
  const [members, setMembers] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [selectedMember, setSelectedMember] = useState('All')
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, __] = useState(null)
  const [department, setDepartment] = useState<any[]>([])
  const [adminStats, setAdminStats] = useState<any>({})
  const [toggleValue, setToggleValue] = useState('Organization')

  const color = 'warning'

  let cancelTokenSource: CancelTokenSource | null = null
  useEffect(() => {
    getAdminStats()
  }, [selectedDepartment, selectedMember, startDate])

  useEffect(() => {
    getAllManager()
    // GetDepartment()
  }, [])

  //get all the managers
  const getAllManager = () => {
    return axios
      .get(`${API_URL}/Registration/GetAllManagersByOrganization`, {
        params: {
          Org_Id: localStorage.getItem('org_Id'),
        },
      })
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.fullName,
            value: item.userId,
          }
        })

        setMembers(newList)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //handle the change in member
  const handleMemberChange = (e: any) => {
    setSelectedMember(e.value)
    setSelectedDepartment('')
  }

  //handle the change in dept
  const handleDeptChange = (dept: any) => {
    setSelectedDepartment(dept.value)
    setSelectedMember('')
  }

  const getAdminStats = async () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    cancelTokenSource = axios.CancelToken.source()

    setSpinner(true)
    let url = ''
    let body = {}

    if (selectedDepartment) {
      url = 'GetDashboardCardsDataByDeptId'
      body = {
        organizationId: localStorage.getItem('org_Id'),
        departmentId: selectedDepartment,
        fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        toDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      }
    }

    if (selectedMember) {
      if (selectedMember === 'All') {
        url = 'GetDashboardCardsDataForOrg'
        body = {
          organizationId: localStorage.getItem('org_Id'),
          fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
          toDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        }
      } else {
        url = 'GetDashboardCardsDataForManager'
        body = {
          organizationId: localStorage.getItem('org_Id'),
          managerId: selectedMember,
          fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
          toDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        }
      }
    }

    try {
      const getData = await axios.post(`${API}/AdminDashboard/${url}`, body, {
        cancelToken: cancelTokenSource?.token,
      })

      setAdminStats(getData.data)
      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      console.log(error)
    }
  }

  //handle date change
  const handleDateChange = (dates: any) => {
    setStartDate(dates)
    // setEndDate(end)
  }

  //to handle the change in the toggle value
  const handleChange = (event: any, newAlignment: string) => {
    setToggleValue(event.target.value)
    if (event.target.value === 'Department') {
      handleDeptChange(department[0])
    }

    if (event.target.value === 'Team') {
      setSelectedMember(members[0].value)
    }

    if (event.target.value === 'Organization') {
      setSelectedMember('All')
    }
  }

  return (
    <>
      <form className='form'>
        <div className='card-body'>
          <div className='tab-content pt-3'>
            <div className='card-header border-0 pt-5 mb-7'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '10px 16px',
                  alignItems: 'center',
                }}
              >
                <div className='d-flex align-items-end'>
                  <div style={{marginRight: '10px'}}>
                    <ToggleButtonGroup
                      color='primary'
                      value={toggleValue}
                      exclusive
                      onChange={handleChange}
                      aria-label='Platform'
                    >
                      <ToggleButton value='Organization'>Organization</ToggleButton>
                      <ToggleButton value='Team'>Team</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  {toggleValue === 'Organization' && (
                    <div
                      style={{marginRight: '10px', width: '200px'}}
                      className='card-toolbar'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title={alignment == 'Team' ? 'Search Manager' : 'Search Member'}
                    >
                      <Select
                        components={makeAnimated()}
                        value={
                          toggleValue === 'Organization'
                            ? {label: 'All Members', value: 'All'}
                            : members.filter((option) => option.value === selectedMember)
                        }
                        options={members}
                        placeholder='Select Member'
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                        onChange={(e) => handleMemberChange(e)}
                        isDisabled
                      />
                    </div>
                  )}
                  {toggleValue === 'Team' && (
                    <div
                      style={{marginRight: '10px', width: '200px'}}
                      className='card-toolbar'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title={alignment == 'Team' ? 'Search Manager' : 'Search Member'}
                    >
                      <Select
                        components={makeAnimated()}
                        value={members.filter((option) => option.value === selectedMember)}
                        options={members}
                        placeholder='Select Member'
                        isClearable={false}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                        onChange={(e) => handleMemberChange(e)}
                      />
                    </div>
                  )}

                  {toggleValue === 'Department' &&
                    BuilderPageSelect(alignment, department, selectedDepartment, handleDeptChange)}

                  {localStorage.getItem('role') != 'User' && (
                    <>
                      <div className='d-flex flex-column'>
                        {SingleDatePickerComponent(
                          startDate,
                          handleDateChange,
                          'form-control custom-Height'
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={clsx('tab-pane', {active: true})}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <AdminStats
                  time={spinner ? '-' : adminStats.totalUsers}
                  title='Total Members'
                  description={dayjs().format('DD MMMM YYYY')}
                  color={color}
                  img='/media/icons/duotune/art/group.svg'
                  bgcolor='#34A853'
                  rawData={adminStats}
                  date={startDate}
                ></AdminStats>

                <AdminStats
                  time={spinner ? '-' : adminStats.totalActiveUsers}
                  title='Active Members'
                  description={dayjs().format('DD MMMM YYYY')}
                  color='warning'
                  img='/media/icons/duotune/art/group.svg'
                  bgcolor='#FBBC04'
                  rawData={adminStats}
                  date={startDate}
                ></AdminStats>

                <AdminStats
                  time={spinner ? '-' : adminStats.totalOfflineUsers}
                  title='Offline Members'
                  description={dayjs().format('DD MMMM YYYY')}
                  color='warning'
                  img='/media/icons/duotune/art/group.svg'
                  bgcolor='#6237DD'
                  rawData={adminStats}
                  date={startDate}
                ></AdminStats>

                <AdminStats
                  time={spinner ? '-' : convertSecIntoHoursMinSec(adminStats.totalHours)}
                  title='Total Hours'
                  description={dayjs().format('DD MMMM YYYY')}
                  color='warning'
                  bgcolor='#4084FF'
                ></AdminStats>
              </div>
              <div
                className='mt-10'
                style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}
              >
                <div className='card-header border-0 pt-5' style={{width: '49%'}}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '10px 16px',
                      alignItems: 'center',
                    }}
                  >
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>Team Productivity</span>
                    </h3>
                  </div>

                  <div className='card-body py-3 mt-1'>
                    <div>
                      {spinner ? (
                        Loader('100px')
                      ) : (
                        <AdminChart
                          className={''}
                          startDate={startDate}
                          endDate={endDate ? endDate : startDate}
                          selectedUser={selectedMember ? selectedMember : ''}
                          department={selectedDepartment ? selectedDepartment : ''}
                          role={alignment}
                        ></AdminChart>
                      )}
                    </div>
                  </div>
                </div>
                <AdminWebVsApp
                  className=''
                  startDate={startDate}
                  endDate={endDate ? endDate : startDate}
                  selectedUser={selectedMember ? selectedMember : ''}
                  department={selectedDepartment ? selectedDepartment : ''}
                ></AdminWebVsApp>
              </div>

              <div
                className='mt-10'
                style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}
              >
                <AdminProdVsUnProd
                  className='card-xl-stretch '
                  startDate={startDate}
                  endDate={endDate ? endDate : startDate}
                  selectedUser={selectedMember ? selectedMember : ''}
                  department={selectedDepartment ? selectedDepartment : ''}
                ></AdminProdVsUnProd>
                <AdminUnproductive
                  className='card-xl-stretch '
                  startDate={startDate}
                  endDate={endDate ? endDate : startDate}
                  selectedUser={selectedMember ? selectedMember : ''}
                  department={selectedDepartment ? selectedDepartment : ''}
                ></AdminUnproductive>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export {BuilderPageAdmin}
