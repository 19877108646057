import React, {FC, useEffect, useState} from 'react'

import '../../css/checkoutPopUp.css'
import 'tippy.js/dist/tippy.css'
import {Loader, convertSecIntoHoursMin} from '../CommonFunctions/CommonFunction'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../css/projectTaskReport.css'
import dayjs from 'dayjs'
import moment from 'moment'

const API = process.env.REACT_APP_API_URL
interface PopupProps {
  onClose: () => void
  userDetails: any
}

const ProjectAndTaksPopUp: FC<PopupProps> = ({onClose, userDetails}) => {
  const [spinner, setSpinner] = useState(false)
  const [userData, setUserData] = useState<any[]>([])

  useEffect(() => {
    setSpinner(true)
    setUserData(userDetails)
    setSpinner(false)
  }, [])

  const handleIconClick = () => {
    onClose()
  }

  const convertSecIntoHoursMinSec = (totalSeconds: any) => {
    const duration = moment.duration(totalSeconds, 'seconds')

    // Format the duration as HH:mm
    return moment.utc(duration.asMilliseconds()).format('HH:mm')
  }

  return (
    <div className='modal-container d-flex'>
      <div style={{margin: 'auto', paddingLeft: '200px'}}>
        <div
          className='card mt-3 project-modal-inner-container'
          style={{padding: '0rem 2.5rem 2rem 2.5rem'}}
        >
          <div className='card mt-5'>
            <div
              className='card-header card-header-stretch border-bottom border-gray-200'
              style={{padding: 'unset'}}
            >
              <span style={{paddingTop: '1.5rem', fontSize: '2rem', fontWeight: '600'}}>
                Members
              </span>
              <i
                className='fa fa-times'
                onClick={() => handleIconClick()}
                aria-hidden='true'
                style={{
                  fontSize: '2rem',
                  marginLeft: '22rem',
                  paddingTop: '1.5rem',
                  cursor: 'pointer',
                }}
              ></i>
            </div>
            <div className='project-task-report-container mt-2'>
              {spinner ? (
                Loader('50px')
              ) : (
                <div>
                  <div className='card-body py-3' style={{padding: '30px'}}>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table
                        className='table align-middle gs-0 gy-4'
                        style={{border: '1px solid '}}
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr
                            className='fw-bolder'
                            style={{background: '#728FCE', fontSize: '15px'}}
                          >
                            <th className='min-w-150px text-center'>User Name</th>
                            <th className='min-w-125px '>Start Date</th>
                            <th className='min-w-125px '>End Date</th>
                            <th className='min-w-100px text-center'>Estimated Hours</th>
                            <th className='min-w-100px text-center'>Logged Hours</th>
                            <th className='min-w-100px text-center'>Final Hours</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {userData.length > 0 &&
                            userData.map((data: any, index: any) => (
                              <tr
                                key={index}
                                className={
                                  index % 2 == 0
                                    ? 'border-bottom bg-light-primary border border-dark'
                                    : 'border border-dark'
                                }
                                style={{borderBottom: '1px solid !important'}}
                              >
                                <td>
                                  <div>
                                    <span className='text-dark fs-6' style={{marginLeft: '10px'}}>
                                      {data.fullName}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <span className='text-dark fs-6'>
                                      {dayjs(data.startDate).format('DD/MM/YYYY')}
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div>
                                    <span className='text-dark fs-6'>
                                      {dayjs(data.endDate).format('DD/MM/YYYY')}
                                    </span>
                                  </div>
                                </td>

                                <td className=' text-center '>
                                  <span className='text-dark  fs-6'>{data.estimatedHours}</span>
                                </td>

                                <td className=' text-center '>
                                  <span className='text-dark  fs-6'>
                                    {convertSecIntoHoursMinSec(data.loggedHours)}
                                  </span>
                                </td>
                                <td className=' text-center '>
                                  <span className='text-dark  fs-6'>
                                    {convertSecIntoHoursMin(data.newLoggedHours)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        {/* end::Table body */}
                      </table>
                    </div>
                    {/* {posts.length > 10 && (
              <div className='d-flex align-items-center justify-content-center position-relative mb-7'>
                <div style={{left: '0', position: 'absolute', top: '0'}}>
                  {RowsPerPage(
                    setForcePage,
                    setRowPerPage,
                    setItemOffset,
                    rowPerPage,
                    posts.length,
                    '1rem'
                  )}
                </div>

                {posts.length > rowPerPage && Pagination(handlePageClick, pageCount, forcePage)}
              </div>
            )} */}

                    {/* end::Table */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <style>{`
          .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
            animation: fadeIn 0.5s ease;
            
          }

          #div-scroller::-webkit-scrollbar-thumb {
            background-color: #C1C1C1 !important;
          }
          #div-scroller::-webkit-scrollbar {
            width:0.5rem
          }



          

        .custom-modal-header{
            padding-right: 29rem;
            font-size: 1.8rem;
            font-weight: 700;
        }

        .paginationBttns{
            width:23rem;
            margin: 2rem auto 0 auto;
        }

        .temp-class{
            padding: 0.5rem;
            width: 8rem;
            margin: 0;
            justify-content:center;
            margin:unset
        }

        #screenshot-container{
            flex-wrap: wrap !important;
        }


        `}</style>
    </div>
  )
}

export {ProjectAndTaksPopUp}
