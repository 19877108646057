/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import axios, {CancelTokenSource} from 'axios'
import dayjs from 'dayjs'
import '../../../css/WebVsIdle.css'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {Loader} from '../../CommonFunctions/CommonFunction'

type Props = {
  className: string
  selectedUser: string
  startDate: Date
  endDate: Date
  department: string
}

const API_URL = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

const AdminWebVsApp: React.FC<Props> = ({
  className,
  selectedUser,
  startDate,
  endDate,
  department,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [websites, setWebsites] = useState<any[]>([])
  const [chart, setChart] = useState<any>(null)
  const [spinner, setSpinner] = useState(false)
  const [alignment, setAlignment] = useState('Web')
  const role = localStorage.getItem('role')
  const colors = ['#7F61EA', '#D5AC82', '#e74f4e', '#00A8FF', '#FFC100']

  useEffect(() => {
    if (role === 'Manager') {
      getManagerData()
    } else {
      setWebsites([])
      setSpinner(true)
      let timesUrl = ''
      let body = {}

      if (alignment === 'Web') {
        if (department) {
          timesUrl = 'AdminDashboard/AllUsersWebUsageByDeptId'

          body = {
            organizationId: localStorage.getItem('org_Id'),
            departmentId: department,
            fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
            toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
          }
        }

        if (selectedUser) {
          if (selectedUser === 'All') {
            timesUrl = 'AdminDashboard/WebUsageForOrganization'

            body = {
              organizationId: localStorage.getItem('org_Id'),
              fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
              toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
            }
          } else {
            timesUrl = 'AdminDashboard/WebUsageForManager'
            body = {
              managerId: selectedUser,
              fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
              toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
              organizationId: localStorage.getItem('org_Id'),
            }
          }
        }
      } else if (alignment === 'App') {
        if (department) {
          timesUrl = 'AdminDashboard/AllUsersAppUsageByDeptId'

          body = {
            organizationId: localStorage.getItem('org_Id'),
            departmentId: department,
            fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
            toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
          }
        }
        if (selectedUser) {
          if (selectedUser === 'All') {
            timesUrl = 'AdminDashboard/AppUsageForOrganization'

            body = {
              organizationId: localStorage.getItem('org_Id'),
              fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
              toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
            }
          } else {
            timesUrl = 'AdminDashboard/AppUsageForManager'

            body = {
              managerId: selectedUser,
              organizationId: localStorage.getItem('org_Id'),
              fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
              toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
            }
          }
        }
      }

      if (chart) {
        chart.destroy()
      }

      fetchData(timesUrl, body)
    }
  }, [chartRef, selectedUser, startDate, endDate, department, alignment])

  const fetchData = async (timesUrl: any, body: any) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    cancelTokenSource = axios.CancelToken.source()

    axios
      .post(`${API_URL}/${timesUrl}`, body, {
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        const slicedWebsite = res.data.slice(0, 5)
        setWebsites(slicedWebsite)
        setSpinner(false)
        if (chartRef.current) {
          const height = parseInt(getCSS(chartRef.current, 'height'))
          const chart = new ApexCharts(chartRef.current, getChartOptions(height, slicedWebsite))
          setChart(chart)
          chart.render()
          return () => chart.destroy()
        }
      })
      .catch((err) => {
        console.log(err)
        setSpinner(false)
      })
  }
  const fetchManagerData = async () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    setSpinner(true)
    let fetchUrl = ''
    let body = {}
    if (!department) {
      cancelTokenSource = axios.CancelToken.source()
      body = {
        OrganizationId: localStorage.getItem('org_Id'),
        ManagerId: selectedUser,

        FromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        ToDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        WebOrAppCategory: alignment === 'Web' ? 'website' : 'application',
      }
      fetchUrl = 'WebAndAppUsageForReportManager'
    } else {
      body = {
        OrganizationId: localStorage.getItem('org_Id'),
        ManagerId: selectedUser,
        DepartmentId: department,
        FromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        ToDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        WebOrAppCategory: alignment === 'Web' ? 'website' : 'application',
      }
      fetchUrl = 'WebAndAppUsageForReportManagerandDeptId'
    }

    cancelTokenSource = axios.CancelToken.source()

    axios
      .get(`${API_URL}/AdminDashboard/${fetchUrl}`, {
        params: body,
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        const slicedWebsite = res.data.webUsage
          ? res.data.webUsage.slice(0, 5)
          : res.data.appUsage.slice(0, 5)
        setWebsites(slicedWebsite)

        setSpinner(false)
        if (chartRef.current) {
          const height = parseInt(getCSS(chartRef.current, 'height'))
          const chart = new ApexCharts(chartRef.current, getChartOptions(height, slicedWebsite))
          setChart(chart)
          chart.render()
          return () => chart.destroy()
        }
      })
      .catch((err) => {
        console.log(err)
        setSpinner(false)
      })
  }

  const handleToggle = (e: any) => {
    setAlignment(e.target.value)
  }

  const getManagerData = async () => {
    setWebsites([])
    fetchManagerData()
  }

  return (
    <div className={`card ${className}`} style={{width: '49%', paddingBottom: '3rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'> Top 5 Web & Apps</span>
        </h3>
        <div className='d-flex' style={{marginRight: '2.5rem'}}>
          <ToggleButtonGroup
            color='primary'
            value={alignment}
            exclusive
            aria-label='Platform'
            style={{height: '3rem'}}
            onChange={(e) => handleToggle(e)}
          >
            <ToggleButton value='Web'>Web</ToggleButton>

            <ToggleButton value='App'>Apps</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      {spinner === false && (
        <>
          {websites.length > 0 ? (
            <div className='webapp-dashboard-conatiner'>
              <div
                ref={chartRef}
                id='kt_charts_widget_7_chart'
                className='card-rounded-bottom web-dash-graph-container'
              ></div>

              <div className='web-data-container'>
                {websites.map((website, index) => (
                  <Tippy
                    key={index}
                    placement='top'
                    content={website.url ? website.url : website.appName}
                  >
                    <div
                      className='firstEntry'
                      style={{borderLeft: `0.6rem solid ${colors[index]}`, cursor: 'pointer'}}
                      title={website.url ? website.url : website.appName}
                    >
                      <span className='web-data-container-time-spent'>
                        {dayjs().startOf('day').second(website.totalTime).format('HH:mm:ss')}
                      </span>
                      <span className='web-data-container-name'>
                        {website.url ? website.url : website.appName}
                      </span>
                    </div>
                  </Tippy>
                ))}
              </div>
            </div>
          ) : (
            <h2 className='noRecordFound'>No Records Found</h2>
          )}
        </>
      )}

      {
        spinner && Loader('100px')
        //  : calendarData ? (
        //   ''
        // ) : (
        //   <h2 className='noRecordFound'>No Records Found</h2>
        // )
      }

      {/* end::Body */}
    </div>
  )
}

export {AdminWebVsApp}

function getChartOptions(height: number, categoryData: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const labels = categoryData.map((data: any) => (data.url ? data.url : data.appName))
  const dataValues = categoryData.map((data: any) => Number(data.totalTime))

  return {
    series: dataValues,
    chart: {
      id: 'mychart',
      fontFamily: 'inherit',
      stacked: false,
      type: 'donut',
      height: '220px',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          size: '40%',
        },
        offsetY: 0,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        title: {
          formatter: function () {
            return 'Time spent -'
          },
        },
        formatter: function (val, {seriesIndex}) {
          return dayjs().startOf('day').second(Number(val)).format('HH:mm:ss')
        },
      },
    },
    legend: {
      show: false,
      position: 'bottom',
      fontFamily: 'inherit',
      fontSize: '14px',
      labels: {
        colors: labelColor,
      },
      itemMargin: {
        horizontal: 15,
        vertical: 10,
      },
      showForSingleSeries: false,
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return dayjs().startOf('day').second(Number(val)).format('HH:mm:ss')
      },
    },
    fill: {
      type: 'gradient',
    },
    stroke: {
      show: false,
      width: 2,
      colors: ['#fff'],
    },
    colors: ['#603BE5', '#CC9966', '#e74f4e', '#0193D7', '#FBCA3A'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
    },
    labels: labels,
  }
}
