import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios from 'axios'
import swal from 'sweetalert2'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {AlertModal, ErrorModal, GetOrgList} from '../../CommonFunctions/CommonFunction'
import dayjs from 'dayjs'

const API_URL = process.env.REACT_APP_API_URL

const DeleteScreenshot: React.FC = () => {
  const [organization, setOrganization] = useState<any[]>([])
  const [selectedOrg, setSelectedOrg] = useState<any>(null)
  const [members, setMembers] = useState<any[]>([])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [loading, setLoading] = useState(false)
  const [selectedMember, setSelectedMember] = useState<any>(null)

  useEffect(() => {
    FillOrgList()
  }, [])

  const FillOrgList = async () => {
    const data = await GetOrgList()
    setOrganization(data)
  }

  useEffect(() => {
    getMembersList()
  }, [selectedOrg])

  const getMembersList = async () => {
    try {
      setSelectedMember(null)
      setMembers([])
      if (selectedOrg !== null) {
        const list = await axios.get(`${API_URL}/Registration/GetAllUsersByOrganization`, {
          params: {
            Org_Id: selectedOrg.value,
          },
        })

        const newList = list.data.map((item: any) => {
          return {
            label: item.fullName,
            value: item.userId,
          }
        })
        setMembers(newList)
      }
    } catch (error) {
      console.error(error)
      ErrorModal()
    }
  }

  const handleDateChange = (dates: any) => {
    setStartDate(dates)
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: 160, // Set your desired width here
    }),
  }

  const handelDelete = async () => {
    setLoading(true)
    try {
      let message = ''
      let endpoint = ''
      if (selectedMember === null && startDate === null && selectedOrg !== null) {
        message = `You are going to delete all the screenshot of ${selectedOrg.label}`
        endpoint = `/GetAzureBlobScreenShots/DeleteBlobScreenShotsByOrgId?orgId=${selectedOrg.value}`
      }

      if (startDate === null && selectedMember !== null && selectedOrg !== null) {
        message = `You are going to delete all the screenshots of ${selectedMember.label}`
        endpoint = `GetAzureBlobScreenShots/DeleteBlobScreenShotsByUserId?orgId=${selectedOrg.value}&userId=${selectedMember.value}`
      }

      if (startDate !== null && selectedMember !== null && selectedOrg !== null) {
        message = `You are going to delete all the screenshots of ${
          selectedMember.label
        } before ${dayjs(startDate).format('DD-MM-YYYY')}`
        endpoint = `GetAzureBlobScreenShots/DeleteBlobScreenShotsByUserIdAndDate?orgId=${
          selectedOrg.value
        }&userId=${selectedMember.value}&deletionDate=${dayjs(startDate).format(
          'YYYY-MM-DDT00:00:00[Z]'
        )}`
      }

      if (selectedOrg !== null && selectedMember === null && startDate !== null) {
        message = `You are going to delete all the screenshot of ${
          selectedOrg.label
        } before ${dayjs(startDate).format('DD-MM-YYYY')}`

        endpoint = `GetAzureBlobScreenShots/DeleteBlobScreenShotsByOrgIdAndDate?orgId=${
          selectedOrg.value
        }&deletionDate=${dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]')}`
      }

      if (selectedMember === null && selectedOrg === null && startDate !== null) {
        message = `You are going to delete screenshots of all organization before ${dayjs(
          startDate
        ).format('DD-MM-YYYY')}`

        endpoint = `GetAzureBlobScreenShots/DeleteBlobScreenShotsByDate?deletionDate=${dayjs(
          startDate
        ).format('YYYY-MM-DDT00:00:00[Z]')}`
      }

      swal
        .fire({
          title: `${message}`,
          text: '',
          icon: 'warning',
          showCancelButton: true,
          width: '26rem',
          heightAuto: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
          backdrop: `rgba(0,0,0,0.8)`,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          customClass: {
            title: 'swal-popup custom-swal-class',
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const deleteUserSS = await axios.get(`${API_URL}/${endpoint}`)
            AlertModal(
              deleteUserSS.data.message,
              '',
              deleteUserSS.data.success ? 'success' : 'warning',
              false,
              '#7066E0',
              'Ok'
            ).then(() => {
              setSelectedOrg(null)
              setSelectedMember(null)
            })
          }
        })
    } catch (error) {
      ErrorModal()
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className={`card card-xxl-stretch mb-xl-7`}>
        {/* begin::Header */}
        <div className='d-flex justify-content-between align-items-center py-3 px-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Delete Screenshot</span>
          </h3>

          <div className='d-flex'>
            <div
              style={{marginRight: '10px'}}
              className='card-toolbar d-flex'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Select organiz...'
            >
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Organization :&nbsp;
              </span>
              <Select
                components={makeAnimated()}
                value={selectedOrg}
                // isDisabled={disableReactSelect}
                options={organization}
                placeholder='Select organiz...'
                onChange={(item: any) => {
                  setSelectedOrg(item)
                }}
                isClearable={true}
                isSearchable={true}
                closeMenuOnScroll={true}
                styles={customStyles}
              />
            </div>

            <div
              style={{marginRight: '10px'}}
              className='card-toolbar d-flex'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Select Member'
            >
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Member :&nbsp;
              </span>
              <Select
                components={makeAnimated()}
                value={selectedMember}
                options={members}
                placeholder='Select Member'
                onChange={(item: any) => {
                  setSelectedMember(item)
                }}
                isClearable={true}
                isSearchable={true}
                closeMenuOnScroll={true}
                styles={customStyles}
              />
            </div>

            <div className='d-flex '>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Date :&nbsp;
              </span>
              <div>
                <DatePicker
                  className='form-control custom-Height'
                  dateFormat='dd/MM/yyyy'
                  selected={startDate}
                  onChange={(date: any) => {
                    handleDateChange(date)
                  }}
                  isClearable={true}
                  placeholderText='Select a Date'
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <button
            type='submit'
            onClick={handelDelete}
            className='btn btn-primary'
            disabled={
              loading || (selectedMember === null && selectedOrg === null && startDate === null)
            }
          >
            {!loading && <span className='indicator-label'>Delete Screenshot</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Deleting...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
      <style>
        {`
          .custom-swal-class{
           font-size:16px !important
          }
        `}
      </style>
    </>
  )
}

export default DeleteScreenshot
