import React, { FC } from 'react'
import { ProductivitysEmp } from './Productivitys/ProductivitysEmp'
import { ProductivitysManager } from './Productivitys/ProductivitysManager'
import { ProductivitysAdmin } from './Productivitys/ProductivitysAdmin'

const ProductivitysSheet: FC = () => {

  const role = localStorage.getItem('role')
  if (role === process.env.REACT_APP_SYSTEM_FIRST_ROLE || role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE)
    return (
        <ProductivitysAdmin></ProductivitysAdmin>
    )
  else if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE)
    return (
        <ProductivitysManager></ProductivitysManager>
    )
  else
    return (
        <ProductivitysEmp></ProductivitysEmp>
    )
}

export { ProductivitysSheet }
