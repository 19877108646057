import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios from 'axios'
import swal from 'sweetalert2'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {AlertModal, ErrorModal, GetOrgList} from '../CommonFunctions/CommonFunction'
import dayjs from 'dayjs'

const API_URL = process.env.REACT_APP_API_URL

const PopulateSalesData: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const populateData = async () => {
    try {
      setLoading(true)
      const populate = await axios.patch(`${API_URL}/OrganizationCleanup/PopulateSalesData`)
      AlertModal(
        populate.data.message,
        '',
        populate.data.success ? 'success' : 'warning',
        false,
        '#7066E0',
        'Ok'
      )
      setLoading(false)
    } catch (error) {
      ErrorModal()
    }
  }

  return (
    <>
      <div className={`card card-xxl-stretch mb-xl-7`}>
        {/* begin::Header */}
        <div className='d-flex justify-content-between align-items-center py-3 px-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Populate Sales Data</span>
          </h3>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <button
            type='submit'
            onClick={populateData}
            className='btn btn-primary'
            disabled={loading}
          >
            {!loading && <span className='indicator-label'>Populate</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Polulating...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
      <style>
        {`
          .custom-swal-class{
           font-size:16px !important
          }
        `}
      </style>
    </>
  )
}

export default PopulateSalesData
