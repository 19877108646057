import dayjs from 'dayjs'
import moment from 'moment'

export const convertSecIntoHoursMinSec = (totalSeconds: any) => {
  const duration = moment.duration(totalSeconds, 'seconds')
  const hours = Math.floor(duration.asHours()).toString().padStart(2, '0')
  const formattedTime = moment.utc(totalSeconds * 1000).format(hours + ':mm:ss')

  return formattedTime === '00:00:00' ? '-' : formattedTime
}

export const convertSecIntoAMPM = (sec: any) => {
  let checkTime = dayjs(sec).format('HH:mm:ss')
  let displayTime = dayjs(sec).format('hh:mm:ss A')
  return checkTime == '00:00:00' || checkTime == 'Invalid Date' ? `-` : displayTime
}

export const manipulateResponseData = (data: any) => {
  return data.map((item: any) => {
    return {
      label: item.fullName,
      value: item.userId,
    }
  })
}

export const getOrganizationNameAndID = (data: any) => {
  return data.map((item: any) => {
    return {
      label: item.organizationName,
      value: item.org_Id,
    }
  })
}

export const getColorStatus = (value: any): string => {
  switch (value) {
    case 'Pending':
      return 'badge fw-bold me-auto px-4 py-3 badge-light-warning'
    case 'Approved':
      return 'badge fw-bold me-auto px-4 py-3 badge-light-success'
    case 'Rejected':
      return 'badge fw-bold me-auto px-4 py-3 badge-light-danger'
    default:
      return ''
  }
}

export const getMaxDate = (setNextYear: any) => {
  const currentYear = new Date().getFullYear()
  const nextYearValue = currentYear + 1
  // console.log('The next year is ', typeof nextYear)
  setNextYear(nextYearValue)

  // Set the maxDate to December 31st of next year
  // return new Date(nextYear, 11, 31)
}

export const handleDownload = (url: string) => {
  const link = document.createElement('a')
  link.href = url
  const fileName = url.substring(url.lastIndexOf('/') + 1)
  link.download = fileName
  link.dispatchEvent(new MouseEvent('click'))
}
