import React, {useState, useEffect} from 'react'
import dayjs, {Dayjs} from 'dayjs'
import '../../../css/website.css'
import '../../../css/partnerCommission.css'
import {
  Loader,
  ErrorModal,
  MemberAndPlanSearchBar,
  Pagination,
  generateDatePresets,
} from '../../CommonFunctions/CommonFunction'
import Tippy from '@tippyjs/react'
import {DatePicker} from 'antd'
import {
  GetAllCommissionDetailByPartnerId,
  GetAllActivePartner,
} from '../../services/partner.service'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
const {RangePicker} = DatePicker

const PartnerCommission = () => {
  const presets = generateDatePresets()
  const role = localStorage.getItem('role')
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [spinner, setSpinner] = useState(false)
  const [amount, setAmount] = useState(0)
  const [paidAmount, setPaidAmount] = useState(0)
  const [commission, setCommission] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [first, setFirst] = useState('')
  const [last, setLast] = useState('')
  const [filteredMembers, setFilteredMembers] = useState<any[]>([])
  const [currentData, setCurrentData] = useState<any[]>([])
  const [pageCount, setPageCount] = useState(0)
  let itemsPerPage: number = 5
  const [itemOffset, setItemOffset] = useState(0)
  const [partnerOptions, setPartnerOptions] = useState<any>([])
  const [partner, setPartner] = useState<any>([])
  const [showNoRecord, setShowNoRecord] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  useEffect(() => {
    setSpinner(true)
    let updatedFilteredMembers = commission.filter((data) =>
      data.organisationName.toLowerCase().includes(searchQuery.toLowerCase())
    )

    const newEndOffset = itemOffset + itemsPerPage
    const item = updatedFilteredMembers.slice(itemOffset, newEndOffset)
    const count = Math.ceil(updatedFilteredMembers.length / itemsPerPage)
    setFilteredMembers(updatedFilteredMembers)
    setCurrentData(item)
    setPageCount(count)
    setSpinner(false)
  }, [commission, searchQuery, itemOffset, itemsPerPage])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % commission.length
    setItemOffset(newOffset)
  }

  const handleDateChange = (dates: any) => {
    if (dates) {
      setStartDate(dates[0] as Dayjs)
      setEndDate(dates[1] as Dayjs)
    }
  }

  useEffect(() => {
    const thisMonth = [dayjs().startOf('month'), dayjs()] as [dayjs.Dayjs, dayjs.Dayjs]
    handleDateChange(thisMonth)

    GetAllActivePartner()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.partnerName,
            value: item.partnerId,
          }
        })
        setPartnerOptions(newList)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    //if (role === process.env.REACT_APP_SYSTEM_SIXTH_ROLE)
    getPartnerCommissionDetails(partner.value ? partner.value : null)
  }, [startDate, endDate])

  const getPartnerCommissionDetails = async (partnerId?: any) => {
    try {
      // Check user's role and show error if necessary
      if (role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE && !partner.value) {
        setShowError(true)
        //return
      }

      // Reset flags
      if (role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE) {
        setShowNoRecord(false)
      }

      setSpinner(true)

      // Early return if either startDate or endDate is not provided
      if (!startDate || !endDate) {
        setSpinner(false)
        return
      }

      // Format dates for the request body
      const formattedStartDate = startDate.format('YYYY-MM-DDTHH:mm:ss[Z]')
      const formattedEndDate = endDate.format('YYYY-MM-DDTHH:mm:ss[Z]')

      const requestBody = {
        partnerId: partnerId || localStorage.getItem('userId'),
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      }

      // Fetch commission data
      const commissionData = await GetAllCommissionDetailByPartnerId(requestBody)

      // Check if commission data is empty and show flag if necessary
      if (role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE && partnerId) {
        if (commissionData.data.length === 0) {
          setShowNoRecord(true)
        }
        setShowError(false)
      }

      // Set partner's name and split it
      const partnerName = localStorage.getItem('name')
      if (partnerName) {
        const [firstName, ...otherNames] = partnerName.split(' ')
        const lastName = otherNames.join(' ')

        setFirst(firstName)
        setLast(lastName)
      }

      // Calculate total commission amount using reduce
      const totalCommissionAmount = commissionData.data.reduce(
        (sum: any, commission: any) => sum + commission.commissionAmount,
        0
      )

      //Calculate the total paid commissoin
      const totalPaidCommissionAmount = commissionData.data
        .filter((commission: any) => commission.commissionStatus === 'Paid')
        .reduce((sum: any, commission: any) => sum + commission.commissionAmount, 0)

      // Update state values
      setPaidAmount(totalPaidCommissionAmount)
      setAmount(totalCommissionAmount)
      setCommission(commissionData.data)

      // Set spinner to false after processing
      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      console.error('Error fetching partner commission details:', error)
      ErrorModal()
    }
  }

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value)
    setItemOffset(0)
  }

  return (
    <div>
      {role !== process.env.REACT_APP_SYSTEM_FOURTH_ROLE && (
        <div className='card  mb-5'>
          <div className='d-flex ' style={{justifyContent: 'space-between'}}>
            <div className='mx-5 my-5 d-flex'>
              <div className='partner-initials'>
                <Tippy placement='top' content='Aman Partner'>
                  <span className='partner-intitals'>
                    {' '}
                    {first[0]}
                    {last[0]}{' '}
                  </span>
                </Tippy>
              </div>
              <div className='d-flex flex-column partner-name-container'>
                <span className='partner-name'>{first}</span>
                <span className='partner-name'>{last}</span>
              </div>
            </div>
            <div className='d-flex'>
              <div className='d-flex flex-column partner-commission-container'>
                <span className='partner-commission-label'>Total Commission</span>
                <span className='partner-commission-amount'>{amount}</span>
              </div>
              <div className='d-flex flex-column partner-commission-container'>
                <span className='partner-commission-label'>Commission Received</span>
                <span className='partner-commission-amount'>{paidAmount}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='card mb-5 mb-xl-10'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='partner-daterange-container'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-1 mb-1'>Commission Details</span>
            </h3>

            <div className='d-flex justify-content-center align-items-center'>
              {MemberAndPlanSearchBar(
                'Search  by organization name',
                'Search by organization name',
                handleSearchChange
              )}

              {role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE && (
                <div
                  className='min-w-200px me-3'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Partner'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    options={partnerOptions}
                    value={partner}
                    // onBlur={formik.handleBlur}
                    placeholder='Select Partner'
                    onChange={(item: any) => {
                      setPartner(item)
                      getPartnerCommissionDetails(item.value)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
              )}

              <div className='d-flex justify-content-center align-items-center'>
                {startDate !== null && (
                  <RangePicker
                    value={[startDate, endDate]}
                    onChange={handleDateChange}
                    style={{height: '2.9rem'}}
                    presets={presets}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3' style={{padding: '40px'}}>
          {/* begin::Table container */}

          <div className='table-responsive'>
            {/* begin::Table */}
            {spinner && Loader('50px')}

            {!spinner && commission.length > 0 && (
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder'>
                    <th></th>
                    <th className='min-w-150px'>Organization</th>
                    <th className='min-w-125px text-center'>Org. Payment Date</th>
                    <th className='min-w-125px text-center'>Org. Payment Amount</th>
                    <th className='min-w-100px'>Commission</th>
                    <th className='min-w-100px'>Currency</th>
                    <th className='min-w-125px text-center'>Commission Amount</th>
                    <th className='min-w-125px text-center'>Txn. ID</th>
                    <th className='min-w-125px text-center'>Commission Status</th>

                    <th></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {!spinner &&
                    currentData.length > 0 &&
                    currentData.map((data: any, index: any) => (
                      <tr key={index}>
                        <td></td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column fs-6'>
                              {data.organisationName}
                            </div>
                          </div>
                        </td>

                        <td>
                          <span className='text-dark  d-block fs-6 text-center'>
                            {dayjs(data.organizationPaymentDate).format('DD/MM/YYYY')}
                          </span>
                        </td>

                        <td>
                          <span className='text-dark text-center d-block fs-6'>
                            {data.organizationPaymentAmount}
                          </span>
                        </td>

                        <td>
                          <span className='text-dark  d-block fs-6 text-center'>
                            {data.commissionPercentage}%
                          </span>
                        </td>

                        <td>
                          <span className='text-dark d-block fs-6 mx-3 '>{data.currency}</span>
                        </td>
                        <td>
                          <span className='text-dark d-block fs-6 mx-3 text-center'>
                            {data.commissionAmount}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark d-block fs-6 mx-3 text-center'>
                            {data.partnerPaymentTransactionId}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark d-block fs-6 mx-3 text-center'>
                            {data.commissionStatus}
                          </span>
                        </td>

                        <td></td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
            )}

            {!spinner &&
              commission.length == 0 &&
              role === process.env.REACT_APP_SYSTEM_SIXTH_ROLE && (
                <h2 className='noRecordFound'>No Records Found</h2>
              )}

            {!spinner &&
              commission.length == 0 &&
              showNoRecord &&
              role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE && (
                <h2 className='noRecordFound'>No Records Found</h2>
              )}

            {showError &&
              !spinner &&
              commission.length == 0 &&
              //   !showNoRecord &&

              role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE && (
                <h2 className='noRecordFound'>Please Select Partner</h2>
              )}
          </div>
          {filteredMembers.length > 5 && (
            <div className='d-flex align-items-center justify-content-center position-relative mb-3'>
              {Pagination(handlePageClick, pageCount, 0)}
            </div>
          )}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export {PartnerCommission}
