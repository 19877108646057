import axios, {CancelTokenSource} from 'axios'
import React, {useEffect, useState} from 'react'
import dayjs from 'dayjs'

import '../../../../css/website.css'
import {
  Loader,
  FillMemberDropDownInWebApp,
  WebAppHeader,
  websiteApplicationTable,
  websiteApplicationFooterTotalTime,
  websiteApplicationExpandedTable,
} from '../../../CommonFunctions/CommonFunction'
import {convertSecIntoHoursMinSec} from '../../Common_Function/Function'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {userInfoActions} from '../../../modules/auth'
import {RootState} from '../../../../setup'

type MyObjectType = {
  title: string
  totalTime: string
}

let cancelTokenSource: CancelTokenSource | null = null

const EmpApplication = () => {
  const SelectedUser: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const API_URL = process.env.REACT_APP_API_URL
  const [spinner, setSpinner] = useState(false)
  const [startDate, setStartDate] = useState<Date | null>(
    localStorage.getItem('startDate') ? new Date(localStorage.getItem('startDate')!) : new Date()
  )
  const [endDate, setEndDate] = useState<Date | null>(
    localStorage.getItem('endDate') ? new Date(localStorage.getItem('endDate')!) : null
  )
  const [applications, setApplications] = useState<any[]>([])
  const [selectedApplication, setSelectedApplication] = useState<MyObjectType[]>([])
  const [title, setTitle] = useState<string>('')
  const [selectedRow, setSelectedRow] = useState(null)
  const [clickedTotal, setClickedTotal] = useState(0)
  const [totalTime, setTotalTime] = useState(0)
  const role = localStorage.getItem('role')
  const [dateError, setDateError] = useState(false)
  const [userId, setUserId] = useState(
    localStorage.getItem('editUser')
      ? localStorage.getItem('editUser')
      : localStorage.getItem('userId')
  )
  const [members, setMembers] = useState<any[]>([])

  useEffect(() => {
    FillMemberDropDownInWebApp(setSpinner, cancelTokenSource, role, setMembers)
  }, [])

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  //to get the details of the application clicked
  const handleApplicationClick = (application: any[], titleName: string) => {
    let totalCalculatedTime = 0
    application.forEach((website: any) => {
      totalCalculatedTime += website.totalTime
    })

    setTitle(titleName)
    setSelectedApplication(application)
    setClickedTotal(totalCalculatedTime)
  }

  //to get the data between the range
  const dateRange = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    setSpinner(true)
    setApplications([])
    setSelectedApplication([])
    setSelectedRow(null)
    if (!startDate && !endDate) {
      setSpinner(false)
    } else {
      let newEndDate: any
      if (endDate == null) {
        newEndDate = startDate
      } else {
        newEndDate = endDate
      }

      localStorage.setItem('editUser', userId ? userId : '')
      localStorage.setItem('startDate', startDate ? startDate.toString() : '')
      localStorage.setItem('endDate', newEndDate ? newEndDate.toString() : '')

      cancelTokenSource = axios.CancelToken.source()

      const body = {
        organizationId: localStorage.getItem('org_Id'),
        userId: userId,
        fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        toDate: dayjs(newEndDate).format('YYYY-MM-DDT00:00:00[Z]'),
      }

      axios
        .post(`${API_URL}/WebAndAppReport/AppUsageForUser`, body, {
          cancelToken: cancelTokenSource?.token,
        })
        .then((res) => {
          if (res.data.length == 0) {
            setApplications([])
            setSelectedApplication([])
            setSelectedRow(null)
            setTotalTime(0)
            setSpinner(false)
          } else {
            if (!Array.isArray(res.data)) {
              setApplications([res.data])
            } else {
              handleRowClick(0)
              handleApplicationClick(res.data[0].titles, res.data[0].appName)
              setApplications(res.data)
            }

            let CalculatedtotalTime = 0
            res.data.forEach((website: any) => {
              CalculatedtotalTime += website.totalTime
            })

            setTotalTime(CalculatedtotalTime)
            setSpinner(false)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  //to shorten the name of the app
  const truncate = (titleString: string) => {
    const maxCount = 40
    if (titleString.length > 40) {
      return titleString.slice(0, maxCount) + '...'
    }
    return titleString
  }

  //to highlight the application clicked
  const handleRowClick = (index: any) => {
    setSelectedRow(index)
  }

  //to handle the change in member
  const handleChange = (id: string) => {
    if (
      SelectedUser.selectedUserId !== id &&
      (SelectedUser.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
        SelectedUser.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
        SelectedUser.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE)
    ) {
      let user = members.find((option: any) => option.value === id)
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: user.label,
          selectedUserId: id,
        })
      )
    }
    setUserId(id)
  }

  useEffect(() => {
    dateRange()
  }, [userId, startDate, endDate])

  useEffect(() => {
    startDate ? setDateError(false) : setDateError(true)
  }, [startDate])

  const params = {
    type: 'Application',
    spinner,
    data: applications,
    handleApplicationClick,
    handleRowClick,
    selectedRow,
    truncate,
    convertSecIntoHoursMinSec,
    totalTime,
  }

  return (
    <div>
      {WebAppHeader(
        role,
        startDate,
        endDate,
        handleDateChange,
        dateError,
        members,
        userId,
        handleChange
      )}
      <div className='d-flex justify-content-around mt-5'>
        <div style={{width: '49%'}}>
          <div className='card sec-wrapper'>
            <div style={{margin: '10px'}} className='web-wrapper'>
              {websiteApplicationTable(params)}
              {spinner
                ? Loader('100px')
                : applications.length === 0 && (
                    <h2 style={{marginTop: '4rem'}} className='noRecordFound'>
                      No Records Found
                    </h2>
                  )}
            </div>
            {!spinner && websiteApplicationFooterTotalTime(totalTime)}
          </div>
        </div>
        <div style={{width: '49%'}}>
          <div className='card sec-wrapper'>
            {title && <div className='mt-4 text-bold fs-4 fw-bolder'>{title}</div>}

            <div className='title-wrapper mx-3 my-5'>
              {websiteApplicationExpandedTable('Title', clickedTotal, spinner, selectedApplication)}
              {spinner
                ? Loader('100px')
                : applications.length === 0 && (
                    <h2 style={{marginTop: '4rem'}} className='noRecordFound'>
                      No Records Found
                    </h2>
                  )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EmpApplication}
