import React, { useEffect, useState } from 'react'
import Switch from 'react-switch'

type Props = {
    title: string,
    addValueInList: any,
    id: string,
    defaultToggle?: boolean,
    updateToggle?: boolean,
    reset?: string
}

const RowConfigWithToggle: React.FC<Props> = ({ title, addValueInList, id, defaultToggle, updateToggle, reset }) => {

    const [diableUpdateButton, setdDisableUpdateButton] = useState<boolean>(true)
    const [updateSwitchState, setUpdateSwitchState] = useState<boolean>(false)
    const [isCheck, setIsCheck] = useState(false)

    useEffect(() => {
        if (reset === 'Individual' || reset === 'Team' || reset === 'Organization' || reset === 'Department' || reset === 'reset') {
            setIsCheck(false)
            setUpdateSwitchState(false)
            setdDisableUpdateButton(true)
        }
    }, [reset])

    return (
        <>
            <div className='d-flex'>
                <div className='p-0 pt-3 mx-3'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            name='selectAll'
                            id='selectAll'
                            onChange={(e) => {
                                //console.log(e.target)
                                if (!e.target.checked) {
                                    addValueInList(id, '')
                                }
                                else {
                                    addValueInList(id, updateSwitchState)
                                }
                                setdDisableUpdateButton(prev => !prev)
                                setIsCheck(prev => !prev)
                            }}
                            checked={isCheck}
                        />
                    </div>
                </div>
                <div className='d-flex mb-4 mx-3 px-5 justify-content-between align-items-center w-100'>

                    <label className=' col-form-label fw-bold fs-6'>{title}</label>
                    {/* <div className='d-flex justify-content-center '>
                <Switch
                    disabled={defaultSwitchDisabled}
                    onChange={(e) => {
                        switchState ? setSwitchState(false) : setSwitchState(true)
                        // !e ? setSwitchDisabled(e) : ()=> {setSwitchDisabled(true) setUpdateSwitchState(false)}
                        // setSwitchDisabled(prev=> !prev ) 
                        if (!e) {
                            setSwitchDisabled(false)
                            addValueInList(id, updateSwitchState)

                        }
                        else {
                            setSwitchDisabled(true)
                            setUpdateSwitchState(false)
                            addValueInList(id, '')
                        }
                    }}
                    checked={switchState}
                />
            </div> */}
                    <div className='d-flex justify-content-end'>
                        <Switch
                            disabled={diableUpdateButton}
                            onChange={(e) => {
                                setUpdateSwitchState(e)
                                if (isCheck) {
                                    addValueInList(id, e)
                                }
                            }}
                            checked={updateSwitchState}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export default RowConfigWithToggle