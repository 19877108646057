/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import swal from 'sweetalert'
import axios from 'axios'
import dayjs from 'dayjs'
import {KTSVG} from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  className: string
}

const Schedule: React.FC<Props> = ({className}: Props) => {
  const [email, _] = useState<any[]>([])
  const [schedulereport, setScheduleReport] = useState<any[]>([])

  const getdatafromarray = (value: any) => {
    let allDays = ''
    for (const next of value) {
      allDays = `${allDays} , ${next.label}`
    }
    return allDays.substring(2, allDays.length)
  }

  const GetAllScheduleReport = () => {
    let body = {
      userId: localStorage.getItem('userId'),
      organizationId: localStorage.getItem('org_Id'),
    }
    axios
      .post(`${API_URL}/ReportScheduler/GetReportByUserAndOrganization`, body)
      .then((res) => {
        console.log(res.data)
        setScheduleReport(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    GetAllScheduleReport()
  }, [])

  const deleteHandler = (Props: any) => {
    let body = {
      reportSchedulerId: Props,
      isActive: false,
      modifiedDate: dayjs().format(),
      modifiedBy: localStorage.getItem('name'),
    }

    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      dangerMode: true,
      buttons: ['cancel', 'Delete'],
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .patch(`${API_URL}/ReportScheduler/DeleteReportScheduler`, body)
          .then((res) => {
            // getAllListOfUsers(selectedcompany)
            GetAllScheduleReport()
            swal(`${res.data.message}`, '', 'success')
          })
          .catch((err) => {
            console.log(err.data.message)
          })
      }
    })
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Schedule Report {email}</span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>
        <div style={{display: 'flex'}}>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <Link to='/addschedule' className='btn btn-sm btn-primary me-3'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Report
            </Link>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                </th>
                <th className='min-w-120px'>Report Name</th>
                <th className='min-w-100px'>Frquency</th>
                <th className='min-w-100px'>Days</th>
                <th className='min-w-100px'>Report Time</th>
                <th className='min-w-120px'>Recipients</th>
                <th className='min-w-100px'>Dates</th>

                <th className='min-w-100px text-center'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {schedulereport.map((reportData: any) => (
                <tr>
                  <td></td>
                  <td>
                    <div className='d-flex align-items-center text-dark fw-bolder d-block fs-6'>
                      {reportData.reportName}
                    </div>
                  </td>
                  <td>
                    <span className='text-dark fw-bolder d-block fs-6'>{reportData.frequency}</span>
                  </td>

                  <td style={{width: '180px'}}>
                    <span className='text-dark fw-bolder d-block fs-6' style={{width: '150px'}}>
                      {getdatafromarray(reportData.days)}
                    </span>
                  </td>

                  <td>
                    <span className='text-dark fw-bolder d-block fs-6'>
                      {dayjs(reportData.executionTime).format('HH:mm')}
                    </span>
                  </td>
                  <td style={{width: '250px'}}>
                    <span className='text-dark fw-bolder d-block fs-6' style={{width: '220px'}}>
                      {getdatafromarray(reportData.distributionList)}
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bolder  d-block fs-6'>
                      {dayjs(reportData.startDate).format('DD-MM-YYYY')} /{' '}
                      {dayjs(reportData.endDate).format('DD-MM-YYYY')}
                    </span>
                  </td>
                  <td style={{width: '160px'}}>
                    <div className='d-flex justify-content-evenly flex-shrink-0'>
                      <Link
                        to='/editschedule'
                        // state={users}
                        onClick={() =>
                          localStorage.setItem('editUser', reportData.reportSchedulerId)
                        }
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </Link>

                      <a
                        onClick={() => deleteHandler(reportData.reportSchedulerId)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {Schedule}
