import axios from 'axios'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertModal, SingleDatePickerComponent} from '../../CommonFunctions/CommonFunction'
import * as Yup from 'yup'
import { getMaxDate } from '../Common_Function/Function'

const AddExtendTrial: React.FC = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [nextYear, setNextYear] = useState(0)
    const API_URL = process.env.REACT_APP_API_URL

    useEffect(() => {
        getMaxDate(setNextYear)

        axios.get(`${API_URL}/OrganizationTrialExtenstion/GetTrialTrackingByOrgId`, {
            params: {
                orgId: localStorage.getItem('org_Id'),
            }
        })
            .then(res => {
                formik.setFieldValue('trialStartDate', res.data.endDate)
            })
    }, [])

    const initialValues = {
        trialStartDate: dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
        requestReason: '',
        requestedDays: 1,
        organizationId: localStorage.getItem('org_Id'),
        // createdBy: localStorage.getItem('name'),
        // createdDate: dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
    }

    const addTrailValidation = Yup.object().shape({
        requestReason: Yup.string().required('Enter Comment'),
        requestedDays: Yup.number().required('Enter No of Days')
    })

    const formik = useFormik({
        initialValues,
        validationSchema: addTrailValidation,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            axios
                .post(`${API_URL}/OrganizationTrialExtenstion/AddTrialRequest`, values)
                .then((res) => {
                    AlertModal(
                        res.data.message,
                        '',
                        res.data.success ? 'success' : 'warning',
                        false,
                        '#7066E0',
                        'Ok'
                    ).then(() => {
                        if (res.data.success) {
                            resetForm()
                            navigate('/trial')
                        }
                        setLoading(false)
                    })
                })
                .catch((err) => {
                    console.log(err)
                    setLoading(false)
                    setSubmitting(false)
                })
        },
        validate: (values) => {
            let errors: any = {}
            if (values.trialStartDate == '') {
                errors.trialStartDate = 'select date'
            }
            return errors
        },
    })

    const handleDateChange = (dates: any) => {
        formik.setFieldValue('trialStartDate', dayjs(dates).format('YYYY-MM-DDT00:00:00[Z]'))
    }



    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Trial Extension Details</h3>
                </div>
            </div>

            <div>
                <form onSubmit={formik.handleSubmit} className='form'>
                    <div className='card-body border-top p-9'>


                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Extension Start Date</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                {SingleDatePickerComponent(
                                    new Date(formik.values.trialStartDate),
                                    handleDateChange,
                                    'form-control single_date',
                                    new Date(nextYear, 11, 31),
                                    new Date(),
                                    true
                                )}

                                {formik.touched.trialStartDate && formik.errors.trialStartDate && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='text-danger mt-2'>{formik.errors.trialStartDate}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span >Extension Days</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input type='number'
                                    className='form-control form-control-lg form-control-solid single_date'
                                    min={1}
                                    placeholder='Extension Days'
                                    {...formik.getFieldProps('requestedDays')}
                                />

                                {formik.touched.requestedDays && formik.errors.requestedDays && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='text-danger mt-2'>{formik.errors.requestedDays}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Comment</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Comment'
                                    {...formik.getFieldProps('requestReason')}
                                />
                                {formik.touched.requestReason && formik.errors.requestReason && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='text-danger mt-2'>{formik.errors.requestReason}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button className='btn btn-secondary' onClick={() => navigate('/trial')}>
                            Cancel
                        </button>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && <span className='indicator-label'>Create</span>}
                            {loading && (
                                <span className='d-block indicator-progress'>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>


                </form>
            </div>
        </div>
    )
}

export default AddExtendTrial