import React, {useState, FC} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ProjectTaskApiCall} from '../../../CommonFunctions/CommonFunction'
import {useNavigate, Link} from 'react-router-dom'

const initialValues = {
  projectTypeName: '',
  projectTypeDescription: '',
}

const ProjectTypeDetails: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const projectTypeSchema = Yup.object().shape({
    projectTypeName: Yup.string().required('Project Type Name is required'),
    projectTypeDescription: Yup.string().typeError('Only string values are allowed'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: projectTypeSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      const body = {
        projectType: values.projectTypeName,
        projectTypeDescription: values.projectTypeDescription,
        org_Id: localStorage.getItem('org_Id'),
      }

      ProjectTaskApiCall(
        'ProjectType/AddProject',
        '/type/typeProject',
        body,
        resetForm,
        setLoading,
        navigate,
        setSubmitting
      )

      setLoading(false)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 cursor-pointer' role='banner'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Project Type Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'> Name</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Name'
                  {...formik.getFieldProps('projectTypeName')}
                />
                {formik.touched.projectTypeName && formik.errors.projectTypeName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.projectTypeName}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span> Description</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  {...formik.getFieldProps('projectTypeDescription')}
                />
                {formik.touched.projectTypeDescription && formik.errors.projectTypeDescription && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.projectTypeDescription}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to={'/type/typeProject'}>
              <button type='submit' className='btn btn-secondary'>
                Cancel
              </button>
            </Link>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && <span className='indicator-label'>Add Project Type</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProjectTypeDetails
