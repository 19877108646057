/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, {useEffect, useState} from 'react'

import {useFormik} from 'formik'

import Select from 'react-select'
import '../../../_metronic/partials/widgets/tables/tables.css'

import dayjs, {Dayjs} from 'dayjs'

import makeAnimated from 'react-select/animated'
import {memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import * as Yup from 'yup'
import {AlertModal, MultiDateTimePicker} from '../../CommonFunctions/CommonFunction'
import {useNavigate} from 'react-router-dom'
import {LocalizationProvider, TimePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

const API = process.env.REACT_APP_API_URL

let index = 0
let currentreport: any
const EditSchedule = () => {
  const navigate = useNavigate()
  const loading = false
  const [members, setMembers] = useState<any[]>([])
  const [timezone, setTimeZone] = useState<any[]>([])

  const [time, setTime] = React.useState<Dayjs | any>(null)
  const [report, setReport] = useState<any>([])
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>(null)
  const [days, setDays] = useState<any>([])
  const [recipient, setRecipient] = useState<any>([])
  const [timezonevalue, setTimeZoneValue] = useState<any>([])

  const options = [
    {value: 'Monday', label: 'Monday'},
    {value: 'Tuesday', label: 'Tuesday'},
    {value: 'Wednesday', label: 'Wednesday'},
    {value: 'Thursday', label: 'Thursday'},
    {value: 'Friday', label: 'Friday'},
    {value: 'Saturday', label: 'Saturday'},
    {value: 'Sunday', label: 'Sunday'},
  ]

  useEffect(() => {
    axios
      .get(`${API}/Report/GetAllReport`)
      .then((res) => {
        setReport(res.data)
      })
  }, [])

  const getDetailsofSchedule = () => {
    axios
      .get(`${API}/ReportScheduler/GetReportByReportSchedulerId`, {
        params: {
          ReportScheduler_Id: localStorage.getItem('editUser'),
        },
      })
      .then((res) => {
       // console.log(res.data)
        formik.setFieldValue('reportName', res.data.reportName)
        formik.setFieldValue('frequency', res.data.frequency)
        formik.setFieldValue('reportId', res.data.reportId)
        formik.setFieldValue('startDate', dayjs(res.data.startDate).format('YYYY-MM-DDT00:00:00[Z]'))
        formik.setFieldValue('endDate', dayjs(res.data.endDate).format('YYYY-MM-DDT00:00:00[Z]'))
        formik.setFieldValue('executionTime', res.data.executionTime)
        formik.setFieldValue('timeZone', res.data.timeZone)
        formik.setFieldValue('reportSchedulerId', res.data.reportSchedulerId)
        formik.setFieldValue('days', res.data.days)
        formik.setFieldValue('distributionList', res.data.distributionList)
        currentreport = res.data.reportName
        setDays(res.data.days)
        setRecipient(res.data.distributionList)
        setStartDate(new Date(res.data.startDate))
        setEndDate(new Date(res.data.endDate))

        setTime(res.data.executionTime)
        let obForTimeZone = [
          {
            label: res.data.timeZone,
            value: res.data.timeZone,
          },
        ]
        setTimeZoneValue(obForTimeZone)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getDetailsofSchedule()
  }, [])

  useEffect(() => {
    axios
      .get(`${API}/TimeZone/GetTimeZone`)
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.timeZonesName,
            value: item.timeZonesName,
          }
        })
        setTimeZone([...newList])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    memberInfoByOrg()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.fullName,
            value: item.email,
          }
        })
        setMembers([...newList])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const initialValues = {
    reportSchedulerId: '',
    userId: localStorage.getItem('userId'),
    organizationId: localStorage.getItem('org_Id'),
    organizationName: localStorage.getItem('org_name'),
    executionTime: '',
    distributionList: '',
    reportId: '',
    reportName: '',
    frequency: '',
    days: '',
    modifiedDate: dayjs().format(),
    startDate: '',
    endDate: '',
    modifiedBy: localStorage.getItem('name'),
    timeZone: '',
    isActive: true,
  }

  const reportSchema = Yup.object().shape({
    reportName: Yup.mixed().required('Report is required'),
    frequency: Yup.string().required('frequency is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: reportSchema,
    onSubmit: (values, {resetForm}) => {
      values.distributionList = recipient

      if (!values.reportId) {
        let _pRId = values.reportName.split('|')
        values.reportId = _pRId[0]
        values.reportName = _pRId[1]
      }
      values.executionTime = dayjs(time).format()

      console.log(values)
      axios
        .post(`${API}/ReportScheduler/UpdateReportScheduler`, values)
        .then((res) => {
          AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    validate: (values) => {
      let errors: any = {}
      if (!values.startDate) {
        errors.endDate = 'Start Date and End Date is required'
      }
      if (values.endDate == 'wrong') {
        errors.endDate = 'end date is required'
      }
      if (!time) {
        errors.executionTime = 'Time is required'
      }
      if (values.days == '' || values.days == null) {
        if (values.frequency == 'Weekly') {
          errors.days = 'Week days is required'
        }
      }
      if (values.timeZone == '' || values.timeZone == null) {
        errors.timeZone = 'Time Zone is required'
      }
      if (values.distributionList == '' || values.distributionList == null) {
        errors.distributionList = 'Recipient is required'
      }
      return errors
    },
  })

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    formik.values.startDate = dayjs(start).format('YYYY-MM-DDT00:00:00[Z]')
    if (end == null) {
      formik.values.endDate = 'wrong'
    } else {
      formik.values.endDate = dayjs(end).format('YYYY-MM-DDT00:00:00[Z]')
    }

    index = index + 1
    if (index == 2) {
      index = 0
    }
  }

  return (
    <div className='card card-xxl-stretch mb-5 mb-xl-10'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Edit Schedule Report</span>
        </h3>
      </div>
      {/* end::Header */}
      <div className='card-body py-3'>
        <form onSubmit={formik.handleSubmit} className='form' style={{marginBottom: '5px'}}>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Report</label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('reportName')}
                  style={{fontSize: '13px'}}
                >
                  <option hidden>{currentreport}</option>
                  {report.map((item: any) => (
                    <option value={item.reportId + '|' + item.reportName}>{item.reportName}</option>
                  ))}
                </select>

                {formik.touched.reportName && formik.errors.reportName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.reportName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Frequency</label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('frequency')}
                  style={{fontSize: '13px'}}
                >
                  <option value={formik.values.frequency} hidden>
                    {formik.values.frequency}
                  </option>
                  <option value='Daily'>Daily</option>
                  <option value='Weekly'>Weekly</option>
                </select>
                {formik.touched.frequency && formik.errors.frequency && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.frequency}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Days</label>
              <div className='col-lg-8 fv-row'>
                <div>
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    isDisabled={formik.values.frequency == 'Weekly' ? false : true}
                    value={days}
                    options={options}
                    isMulti
                    placeholder='select week days'
                    onChange={(item) => {
                      setDays(item)
                      formik.setFieldValue('days', item)
                    }}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnScroll={false}
                  />
                </div>
                {formik.touched.days && formik.errors.days && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.days}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Date</label>
              <div className='col-lg-8 fv-row'>
                {MultiDateTimePicker(startDate, endDate, handleDateChange, 'date_range')}

                {formik.touched.endDate && formik.errors.endDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.endDate}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Time</label>
              <div className='col-lg-8 fv-row'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    className='fw'
                    value={time}
                    orientation='landscape'
                    ampm={false}
                    onChange={(value: any) => {
                      setTime(value)
                    }}
                  />
                </LocalizationProvider>
                {formik.touched.executionTime && formik.errors.executionTime && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.executionTime}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Time Zone</label>
              <div className='col-lg-8 fv-row'>
                <div>
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    placeholder='select time zone'
                    options={timezone}
                    value={timezonevalue}
                    onChange={(item: any) => {
                      console.log(item)
                      setTimeZoneValue(item)
                      if (item == null) {
                        formik.setFieldValue('timeZone', '')
                      } else {
                        formik.setFieldValue('timeZone', item.value)
                      }
                    }}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
                {formik.touched.timeZone && formik.errors.timeZone && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.timeZone}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Add Recipients</label>
              <div className='col-lg-8 fv-row'>
                <div>
                  <Select
                    value={recipient}
                    className='selectDropdown'
                    components={makeAnimated()}
                    placeholder='select Recipients'
                    options={members}
                    isMulti
                    onChange={(item: any) => {
                      setRecipient(item)
                      formik.setFieldValue('distributionList', item)
                    }}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnScroll={false}
                  />
                </div>

                {formik.touched.distributionList && formik.errors.distributionList && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.distributionList}</div>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '40px',
              }}
            >
              <span style={{width: '100%'}}>
                <div className='d-flex justify-content-end py-6 px-9'>
                  <button
                    className='btn btn-secondary'
                    style={{marginRight: '2%'}}
                    onClick={() => navigate('/schedule')}
                  >
                    Cancel
                  </button>

                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && <span className='indicator-label'>Update</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </span>
            </div>

            <h3 className='card-title align-items-start flex-column'>
              <span className='fw-bolder mb-2 text-dark'></span>
              <span className='text-muted fw-bold fs-7'></span>
            </h3>
          </div>
        </form>
      </div>
    </div>
  )
}

export {EditSchedule}
