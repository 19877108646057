/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
    AddReportSchedule
} from '../../../_metronic/partials/widgets'

export function AddSchedule() {
    return (
        <>
            <div className='row gy-10 gx-xl-10'>

                <div className='col-xl-12'>
                    <AddReportSchedule className='card-xxl-stretch mb-5 mb-xl-10' />
                </div>
            </div>
        </>
    )
}