import React from 'react'

import {Outlet} from 'react-router-dom'
import '../../../css/ProductivityManager.css'
import {Department} from './department/department'

const DepartmentWrapper: React.FC = () => {
  return (
    <>
      <Department></Department>
      <Outlet />
    </>
  )
}

export {DepartmentWrapper}
