import dayjs from 'dayjs'
import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import '../AttendanceReport/custom.css'
import axios, {CancelTokenSource} from 'axios'
import {
  Loader,
  Pagination,
  MultiDateTimePicker,
  downloadAttendanceReportData,
} from '../../CommonFunctions/CommonFunction'
import {convertSecIntoHoursMinSec} from '../Common_Function/Function'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {userInfoActions} from '../../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

const Attendance = () => {
  const role = localStorage.getItem('role')
  const SelectedUser: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const UserData: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState()
  const [showstartdate, setShowStartDate] = useState(dayjs().format('DD/MM/YYYY'))
  const [showenddate, setShowEndDate] = useState<any>(null)
  const [attendance, setAttendance] = useState<any[]>([])
  const [calendarData, setCalendarData] = useState<any>()
  const [showName, setShowName] = useState<any>('')
  const [selectedMember, setSelectedMember] = useState<any>('')
  const [validStartDate, setValidStartDate] = useState<any>(dayjs())
  const [validEndDate, setValidEndDate] = useState<any>(dayjs())
  const [screenloader, setScreenLoader] = useState<any>(false)
  const selectedRow = React.useState(-1)
  const [dateError, setDateError] = useState<any>(false)
  const [teamLeader, setTeamLeader] = useState<boolean>(false)

  useEffect(() => {
    setScreenLoader(true)
    const startOfMonth = dayjs()
      .startOf('month')
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    const endOfMonth = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    handleDateChange([new Date(startOfMonth), new Date(endOfMonth)])
  }, [])

  const earlyStageCall = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    setAttendance([])
    setScreenLoader(true)
    setItemOffset(0)
  }

  const SendDataOfDate = (start: any, end: any) => {
    setDateError(false)
    earlyStageCall()

    if (start == null && end == null) {
      setDateError(true)

      setScreenLoader(false)

      setShowStartDate('')
      setShowEndDate('')
      setCalendarData([])
      setShowName('')
      setAttendance([])
      setValidEndDate('')
      setValidStartDate('')
      return
    } else if (start && end == null) {
      setShowStartDate(dayjs(start).format('DD/MM/YYYY'))
      setShowEndDate(null)
      end = start
    } else {
      setShowStartDate(dayjs(start).format('DD/MM/YYYY'))
      setShowEndDate(dayjs(end).format('DD/MM/YYYY'))
    }

    CallOfEmp(
      dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
      dayjs(end).format('YYYY-MM-DDT00:00:00[Z]')
    )
  }

  const CallOfEmp = (beginDate?: any, lastDate?: any, name?: any) => {
    cancelTokenSource = axios.CancelToken.source()

    let fetch = ''

    let OrganizationId = localStorage.getItem('org_Id')
    let ManagerId = localStorage.getItem('userId')
    let FromDate = beginDate ? beginDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]')
    let ToDate = lastDate ? lastDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]')

    fetch = 'AttendanceReport/GetAttendaceReportByReportManager'

    axios
      .get(`${API_URL}/${fetch}`, {
        params: {
          ManagerId,
          OrganizationId,
          FromDate,
          ToDate,
        },
      })
      .then((res) => {
        if (!Array.isArray(res.data)) {
          if (res.data.length > 0) setTeamLeader(true)
          setAttendance([res.data])
        } else {
          if (res.data.length > 0) setTeamLeader(true)
          setAttendance(res.data)
        }
        if (SelectedUser.selectedUserId) {
          let user = {
            label: SelectedUser.selectedUserName,
            value: SelectedUser.selectedUserId,
          }
          handleRowClick(user)
        }

        viewCalendar(
          dayjs(beginDate).format('YYYY-MM-DDT00:00:00[Z]'),
          dayjs(lastDate).format('YYYY-MM-DDT00:00:00[Z]'),
          SelectedUser.selectedUserId ? SelectedUser.selectedUserId : localStorage.getItem('userId')
        )
        setShowName(
          SelectedUser.selectedUserName
            ? SelectedUser.selectedUserName
            : name
            ? name
            : localStorage.getItem('name')
        )

        setScreenLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setScreenLoader(false)
      })
  }

  const viewCalendar = (start?: any, end?: any, id?: any) => {
    setSelectedMember(id || localStorage.getItem('userId'))

    const body = {
      userId: id || localStorage.getItem('userId'),
      fromDate: start || dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
      toDate: end || dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
      organizationId: localStorage.getItem('org_Id'),
    }
    cancelTokenSource = axios.CancelToken.source()

    axios
      .post(`${API_URL}/AttendanceReport/GetAttendaceByUser`, body, {
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        const result = res.data.map((item: any) => {
          let colorBG: any = ''
          let allDay: any = false
          let Display: any = ''
          let look: any = ''
          switch (item.title) {
            case 'Absent':
              colorBG = '#e74f4e'
              allDay = true
              Display = 'background'
              break
            case 'Present':
              colorBG = '#13aa13'
              allDay = true
              Display = 'background'
              break
            case 'ThreeQuarterDay':
              allDay = true
              Display = 'background'
              look = 'threeQuarterDay'
              break
            case 'Holiday':
              colorBG = '#4646e2'
              allDay = true
              Display = 'background'
              break
            case 'HalfDay':
              allDay = true
              Display = 'background'
              look = 'halfDayDetail'
              break
            case 'Present (Holiday)':
              allDay = true
              Display = 'background'
              look = 'presentHoliday'
              break
            case 'HalfDay (Holiday)':
              allDay = true
              Display = 'background'
              look = 'HalfDayHoliday'
              break
            case 'QuarterDay (Holiday)':
              allDay = true
              Display = 'background'
              look = 'quarterDayHoliday'
              break
            case 'QuarterDay':
              allDay = true
              Display = 'background'
              look = 'quarterDay'
              break
          }
          return {
            ...item,
            title:
              item.title === 'Absent' ||
              item.title === 'Present' ||
              item.title === 'ThreeQuarterDay' ||
              item.title === 'QuarterDay' ||
              item.title === 'Holiday' ||
              item.title === 'HalfDay' ||
              item.title === 'Present (Holiday)' ||
              item.title === 'QuarterDay (Holiday)' ||
              item.title === 'HalfDay (Holiday)'
                ? ''
                : item.title,
            allDay: allDay,
            backgroundColor: colorBG,
            display: Display,
            color: 'black',
            classNames: look,
          }
        })
        setCalendarData(result)
        setValidStartDate(start || dayjs().format())
        setValidEndDate(end || dayjs().format())
      })
      .catch((err) => console.log(err))
  }

  //Pagination
  let itemsPerPage: number = 5
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(attendance.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(attendance.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, attendance])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % attendance.length
    setItemOffset(newOffset)
  }

  const createCsv = async () => {
    const body = {
      fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      toDate:
        endDate !== null
          ? dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]')
          : dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      organizationId: UserData.orgId,
      userType: 'Team',
      userId: UserData.userId,
    }
    let optionSelectedMember = {
      label: UserData.userId,
      value: UserData.userName,
    }
    await downloadAttendanceReportData(body, 'Team', optionSelectedMember, startDate, endDate)
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    SendDataOfDate(start, end)
  }

  const handleRowClick = (user: any) => {
    if (SelectedUser.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: user.label,
          selectedUserId: user.value,
        })
      )
    }
    viewCalendar(
      dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
      user.value
    )
    setShowName(user.label)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px 16px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Attendance</span>
            </h3>

            <div className='d-flex'>
              <div className='d-flex flex-column'>
                {MultiDateTimePicker(
                  startDate,
                  endDate,
                  handleDateChange,
                  'form-control custom-Height'
                )}

                {dateError && (
                  <span className='text-danger' style={{marginLeft: '1rem'}}>
                    Please Select Date
                  </span>
                )}
              </div>
              <div className='d-flex'>
                <button
                  className='btn btn-primary'
                  style={{marginLeft: '1rem', height: '3.2rem'}}
                  onClick={() => createCsv()}
                >
                  <i className='fa fa-download'></i>Download
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3' style={{padding: '40px'}}>
          {/* begin::Table container */}

          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4' style={{border: '1px solid'}}>
              {/* begin::Table head */}
              <thead>
                <tr
                  className='fw-bolder fs-5'
                  style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
                >
                  <th className=' w-10px'></th>
                  <th className=' min-w-150px'>Name</th>
                  <th className=' min-w-120px'>Date</th>
                  <th className=' min-w-150px text-center'>Logged Hours</th>
                  <th className=' min-w-140px text-center'>Total Hours Expected</th>
                  <th className=' min-w-120px text-center'>No of Holidays</th>
                  <th className=' min-w-120px text-center'>No of Leaves</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {currentItems.map((item: any) => (
                  <tr
                    key={item.userId}
                    onClick={() => {
                      handleRowClick({label: item.userName, value: item.userId})
                    }}
                    className={`${item.userId == selectedMember ? 'changeColorBackground' : ''} ${
                      role === process.env.REACT_APP_SYSTEM_THIRD_ROLE
                        ? teamLeader
                          ? 'clickable-row '.concat(selectedRow === item.userId ? 'selected' : '')
                          : ''
                        : 'clickable-row '.concat(selectedRow === item.userId ? 'selected' : '')
                    }`}
                  >
                    <td></td>
                    <td>
                      <span className='text-dark d-block fs-6'>{item.userName}</span>
                    </td>
                    <td>
                      <span className='text-dark d-block fs-6'>
                        {' '}
                        {showstartdate}
                        {showenddate ? ' - ' + showenddate : ''}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark d-block fs-6 text-center'>
                        {convertSecIntoHoursMinSec(item.activeTime)}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark d-block fs-6 text-center'>{`${item.totalExpectedHours}:00:00`}</span>
                    </td>
                    <td>
                      <span className='text-dark d-block fs-6 text-center'>{item.holiday}</span>
                    </td>
                    <td>
                      <span className='text-dark d-block fs-6 text-center'>{item.leaves}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>

            {screenloader ? (
              Loader('0px')
            ) : currentItems.length != 0 ? (
              ''
            ) : (
              <h2 className='noRecordFound'>No Records Found</h2>
            )}
          </div>
          {/* end::Table */}

          {attendance.length > 4 && (
            <div className='d-flex align-items-center justify-content-center'>
              {Pagination(handlePageClick, pageCount, 0)}
            </div>
          )}
        </div>
        {/* end::Body */}
      </div>

      <div style={{background: 'white', padding: '50px', borderRadius: '7px'}}>
        <FullCalendar
          customButtons={{
            ShowName: {
              text: showName,
            },
          }}
          height='80vh'
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next',
            center: `title`,
            right:
              localStorage.getItem('role') === process.env.REACT_APP_SYSTEM_SECOND_ROLE
                ? showName
                  ? 'ShowName'
                  : ''
                : '',
          }}
          initialView='dayGridMonth'
          validRange={{
            start: dayjs(validStartDate).format('YYYY-MM-DDT00:00:00[Z]'),
            end: dayjs(validEndDate).add(1, 'day').format('YYYY-MM-DD'),
          }}
          editable={false}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          events={calendarData}
        />
      </div>
    </>
  )
}

export {Attendance}
