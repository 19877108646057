import React, {useState, useEffect} from 'react'
import axios, {CancelTokenSource} from 'axios'
import dayjs from 'dayjs'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import '../../../../css/website.css'

import {
  Loader,
  FillMemberDropDownInWebApp,
  WebAppHeader,
  websiteApplicationTable,
  websiteApplicationFooterTotalTime,
  websiteApplicationExpandedTable,
} from '../../../CommonFunctions/CommonFunction'
import {convertSecIntoHoursMinSec} from '../../Common_Function/Function'
import {RootState} from '../../../../setup'
import {userInfoActions} from '../../../modules/auth'

let cancelTokenSource: CancelTokenSource | null = null

type MyObjectType = {
  title: string
  totalTime: string
}

const EmpWebsite = () => {
  const SelectedUser: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const API_URL = process.env.REACT_APP_API_URL
  const [spinner, setSpinner] = useState(false)
  const [startDate, setStartDate] = useState(() => {
    const storedStartDate = localStorage.getItem('startDate')
    return storedStartDate ? new Date(storedStartDate) : new Date()
  })
  const [endDate, setEndDate] = useState(() => {
    const storedEndDate = localStorage.getItem('endDate')
    return storedEndDate ? new Date(storedEndDate) : null
  })
  const [websites, setWebsites] = useState<any[]>([])
  const [selectedWebsite, setSelectedWebsite] = useState<MyObjectType[]>([])
  const [title, setTitle] = useState<string>('')
  const [selectedRow, setSelectedRow] = useState(null)
  const [totalTime, setTotalTime] = useState(0)
  const [clickedTotal, setClickedTotal] = useState(0)
  const [dateError, setDateError] = useState(false)
  const role = localStorage.getItem('role')
  const [userId, setUserId] = useState('')
  const [members, setMembers] = useState<any[]>([])

  useEffect(() => {
    FillMemberDropDownInWebApp(setSpinner, cancelTokenSource, role, setMembers)
    if (
      (SelectedUser.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
        SelectedUser.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
        SelectedUser.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) &&
      SelectedUser.selectedUserId
    ) {
      // handleChange(SelectedUser.selectedUserId)
      setUserId(SelectedUser.selectedUserId)
    } else {
      setUserId(localStorage.getItem('editUser') || localStorage.getItem('userId') || '')
    }
  }, [])

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleWebsiteClick = (website: any[], titleName: string) => {
    let calculatedtotalTime = 0
    website.forEach((websiteEntry: any) => {
      calculatedtotalTime += websiteEntry.totalTime
    })
    const regex = /^(https?:\/\/)/i
    setTitle(titleName.replace(regex, ''))
    setSelectedWebsite(website)
    setClickedTotal(calculatedtotalTime)
  }

  //called on submit
  const dateRange = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    setSpinner(true)
    setWebsites([])
    setSelectedWebsite([])
    setSelectedRow(null)
    if (startDate) {
      let newEndDate: any
      if (endDate == null) {
        newEndDate = startDate
      } else {
        newEndDate = endDate
      }

      if (
        role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
        role === process.env.REACT_APP_SYSTEM_SECOND_ROLE ||
        role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
      ) {
        localStorage.setItem('startDate', startDate.toString())
        localStorage.setItem('endDate', newEndDate ? newEndDate.toString() : '')
        localStorage.setItem('editUser', userId ? userId : '')
      } else {
        localStorage.setItem('startDate', startDate.toString())
        localStorage.setItem('endDate', newEndDate ? newEndDate.toString() : '')
      }

      const body = {
        organizationId: localStorage.getItem('org_Id'),
        userId: userId,
        fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        toDate: dayjs(newEndDate).format('YYYY-MM-DDT00:00:00[Z]'),
      }

      cancelTokenSource = axios.CancelToken.source()
      if (userId !== '') {
        axios
          .post(`${API_URL}/WebAndAppReport/WebUsageForUser`, body, {
            cancelToken: cancelTokenSource?.token,
          })
          .then((res) => {
            if (res.data.length == 0) {
              setWebsites([])
              setSelectedWebsite([])
              setSelectedRow(null)
              setTotalTime(0)
              setSpinner(false)
            } else {
              if (!Array.isArray(res.data)) {
                setWebsites([res.data])
              } else {
                handleRowClick(0)

                setWebsites(res.data)
                handleWebsiteClick(res.data[0].titles, res.data[0].url)
              }

              let totalCalculatedTime = 0
              res.data.forEach((website: any) => {
                totalCalculatedTime += website.totalTime
              })
              setTotalTime(totalCalculatedTime)
              setSpinner(false)
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.log('Cancelled intentionally')
              setSpinner(false)
            } else {
              setSpinner(false)
              console.log(err)
            }
            setSpinner(false)
            console.log(err)
          })
      }
    }
  }

  const handleRowClick = (index: any) => {
    setSelectedRow(index)
  }

  const handleChange = (id: string) => {
    if (
      SelectedUser.selectedUserId !== id &&
      (SelectedUser.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
        SelectedUser.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
        SelectedUser.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE)
    ) {
      let user = members.find((option: any) => option.value === id)
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: user.label,
          selectedUserId: id,
        })
      )
    }
    setUserId(id)
  }

  useEffect(() => {
    dateRange()
  }, [userId, startDate, endDate])

  useEffect(() => {
    startDate ? setDateError(false) : setDateError(true)
  }, [startDate])

  const params = {
    type: 'Website',
    spinner,
    data: websites,
    handleWebsiteClick,
    handleRowClick,
    selectedRow,
    convertSecIntoHoursMinSec,
    totalTime,
  }

  return (
    <div>
      {WebAppHeader(
        role,
        startDate,
        endDate,
        handleDateChange,
        dateError,
        members,
        userId,
        handleChange
      )}
      <div className='d-flex justify-content-around mt-5'>
        <div style={{width: '49%'}}>
          <div className='card sec-wrapper'>
            <div style={{margin: '10px'}} className='web-wrapper'>
              {websiteApplicationTable(params)}

              {spinner
                ? Loader('100px')
                : websites.length === 0 && <h2 className='noRecordFound'>No Records Found</h2>}
            </div>
            {!spinner && websites.length != 0 && websiteApplicationFooterTotalTime(totalTime)}
          </div>
        </div>
        <div style={{width: '49%'}}>
          <div className='card sec-wrapper'>
            {title && <div className='mt-4 text-bold fs-4 fw-bolder'>{title}</div>}

            <div className='title-wrapper mx-3 my-5'>
              {websiteApplicationExpandedTable('URL', clickedTotal, spinner, selectedWebsite)}
              {spinner
                ? Loader('100px')
                : websites.length === 0 && (
                    <h2 style={{marginTop: '4rem'}} className='noRecordFound'>
                      No Records Found
                    </h2>
                  )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EmpWebsite}
