import React, {useState, useEffect} from 'react'

import axios, {CancelTokenSource} from 'axios'

import dayjs from 'dayjs'
import {
  Pagination,
  Loader,
  RowsPerPage,
  MultiDateTimePicker,
  AlertModal,
  TimeTrackerTable,
  ErrorModal,
} from '../../../CommonFunctions/CommonFunction'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import {utils as XLSXUtils, write} from 'xlsx'
import {parseISO, isBefore, isEqual, addDays} from 'date-fns'
import {
  convertSecIntoAMPM,
  convertSecIntoHoursMinSec,
  manipulateResponseData,
} from '../../Common_Function/Function'
import {callAllManager, memberInfoByOrg} from '../../../modules/auth/redux/AuthCRUD'
import {GetAssignee} from '../../../services/GetAllAssinee.services'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../setup'
import {userInfoActions} from '../../../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

const AttendanceSheetemp: React.FC = () => {
  const role = localStorage.getItem('role')
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const [attendance, setAttendance] = useState<any[]>([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [showstartdate, setShowStartDate] = useState(dayjs().format('DD/MM/YYYY'))
  const [showenddate, setShowEndDate] = useState<any>(null)
  const [screenloader, setScreenLoader] = useState<boolean>(false)

  const [alignment, setAlignment] = React.useState('')
  const [memberOption, setMemberOption] = useState<any>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [currDisplayState, setCurrDisplayState] = useState<any>('')
  const [disableReactSelect, setDisableReactSelect] = useState<boolean>(false)
  const [memberError, setMemberError] = useState<any>(false)
  const [dateError, setDateError] = useState<any>(false)
  const [rowPerPage, setRowPerPage] = useState(10)

  //Pagination
  let itemsPerPage: number = rowPerPage
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [forcePage, setForcePage] = useState<any>()

  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const searchParams = currentUrl?.searchParams
    const from = searchParams?.get('source')
    if (
      !from &&
      role !== process.env.REACT_APP_SYSTEM_THIRD_ROLE &&
      role !== process.env.REACT_APP_SYSTEM_SECOND_ROLE
    ) {
      if (ConfigDetails.selectedUserId) {
        setOptionSelectedMember({
          label: ConfigDetails.selectedUserName,
          value: ConfigDetails.selectedUserId,
        })
        callAllMemberAPI()
        setAlignment('Individual')
        setCurrDisplayState('Individual')
      } else {
        setAlignment('Organization')
        setCurrDisplayState('Organization')
        setOptionSelectedMember({
          label: 'All Members',
          value: 'All',
        })
      }
    } else if (!from && role == 'User') {
      setAlignment('Individual')
      setCurrDisplayState('Individual')
      setOptionSelectedMember({
        label: localStorage.getItem('name'),
        value: localStorage.getItem('userId'),
      })
    }

    // if (
    //   role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
    //   role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    // ) {
    //   callAllMemberAPI()
    // }
    // const isGroupAdmin = localStorage.getItem('isGroupAdmin')
    // if (!isGroupAdmin) {
    //   setOptionSelectedMember({
    //     label: localStorage.getItem('name'),
    //     value: localStorage.getItem('userId'),
    //   })
    // }
  }, [])

  useEffect(() => {
    const currentUrl = new URL(window.location.href)
    const searchParams = currentUrl?.searchParams
    const from = searchParams?.get('source')
    if (from === 'teamdashboard') {
      handleTeamDashboardRedirect()
    } else {
      const reportDate = searchParams?.get('ReportDate')

      if (searchParams.get('IsTeam')) {
        setAlignment('Team')
        setCurrDisplayState('Team')
        callAllManagerAPI()
        setOptionSelectedMember({
          label: 'All Members',
          value: 'All',
        })
        const formatted = dayjs(reportDate).format('YYYY-MM-DDT00:00:00[Z]')
        setStartDate(new Date(formatted))
        searchParams.delete('ReportDate')
        searchParams.delete('IsTeam')
        currentUrl.search = searchParams.toString()

        const newUrl = currentUrl.href
        window.history.replaceState({path: newUrl}, '', newUrl)
      } else {
        if (reportDate) {
          searchParams.delete('ReportDate')
          currentUrl.search = searchParams.toString()

          const newUrl = currentUrl.href
          window.history.replaceState({path: newUrl}, '', newUrl)

          const formatted = dayjs(reportDate).format('YYYY-MM-DDT00:00:00[Z]')
          setStartDate(new Date(formatted))
        }
      }
      setScreenLoader(true)

      if (
        localStorage.getItem('role') !== process.env.REACT_APP_SYSTEM_FIRST_ROLE &&
        localStorage.getItem('role') !== process.env.REACT_APP_SYSTEM_FIFTH_ROLE
      ) {
        getAllAssinee()
      }
    }
  }, [])

  const handleTeamDashboardRedirect = () => {
    try {
      const currentUrl = new URL(window.location.href)
      const searchParams = currentUrl?.searchParams
      const userName = searchParams?.get('name')
      const userId = searchParams?.get('user')
      const queryDate = searchParams?.get('date')
      const formattedDate = dayjs(queryDate, 'DD/MM/YYYY').toDate()
      setStartDate(formattedDate)
      setAlignment('Individual')
      setCurrDisplayState('Individual')
      callAllMemberAPI()
      setAttendance([])
      setOptionSelectedMember({
        label: userName,
        value: userId,
      })
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: userName,
          selectedUserId: userId,
        })
      )
    } catch (error) {
      ErrorModal()
    }
  }

  useEffect(() => {
    if (
      optionSelectedMember !== null &&
      optionSelectedMember.value !== undefined &&
      startDate !== null
    )
      CallOfEmp(
        optionSelectedMember.value,
        dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        endDate
          ? dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]')
          : dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]')
      )
  }, [optionSelectedMember, startDate, endDate])

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage

    setCurrentItems(attendance.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(attendance.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, attendance])

  const earlyStageCall = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    setAttendance([])
    setScreenLoader(true)
    setItemOffset(0)
  }

  const handleChange = (event: any) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    setDisableReactSelect(true)
    if (currDisplayState === event.target.value) {
      setDisableReactSelect(false)
    } else {
      if (event.target.value === 'Individual') {
        setAlignment(event.target.value)
        setOptionSelectedMember(null)
        callAllMemberAPI()
        setAttendance([])
        setMemberError(true)
      } else if (event.target.value === 'Team') {
        setAlignment(event.target.value)
        setOptionSelectedMember(null)
        callAllManagerAPI()
        setAttendance([])
        setMemberError(true)
      } else if (event.target.value === 'Organization') {
        setAlignment(event.target.value)
        setOptionSelectedMember({
          label: 'All Members',
          value: 'All',
        })
        setMemberError(false)
      }
    }

    setCurrDisplayState(event.target.value)
  }

  const callAllManagerAPI = () => {
    callAllManager().then((res) => {
      const newList = manipulateResponseData(res.data)

      // if (
      //   role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      //   role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
      // ) {
      //   newList.unshift({
      //     label: 'All Members',
      //     value: 'All',
      //   })
      // }

      setMemberOption(newList)
      if (newList.length > 0) {
        setDisableReactSelect(false)
      }
    })
  }

  const callAllMemberAPI = () => {
    memberInfoByOrg().then((res) => {
      const newList = manipulateResponseData(res.data)
      setMemberOption(newList)
      if (res.data.length > 0) {
        setDisableReactSelect(false)
      }
    })
  }

  const getAllAssinee = async () => {
    try {
      const assignees = await GetAssignee()

      const newList = assignees?.data.map((item: any) => {
        return {
          label: item.assigneeName,
          value: item.assigneeId,
        }
      })

      setMemberOption(newList)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % attendance.length
    setItemOffset(newOffset)
  }

  const SendDataOfUser = (value: any) => {
    setMemberError(false)
    earlyStageCall()

    if (startDate == null && endDate == null) {
      setDateError(true)
      setScreenLoader(false)
      setScreenLoader(false)
    }
  }

  const SendDataOfDate = (start: any, end: any) => {
    setDateError(false)
    earlyStageCall()

    if (optionSelectedMember == null) {
      setMemberError(true)
      setScreenLoader(false)
    } else if (start == null && end == null) {
      setDateError(true)
      setScreenLoader(false)
    } else if (start && end == null) {
      setShowStartDate(dayjs(start).format('DD/MM/YYYY'))
      setShowEndDate(null)
      end = start
    }
  }

  const CallOfEmp = (userId?: any, beginDate?: any, lastDate?: any) => {
    const body = {
      organizationId: localStorage.getItem('org_Id'),
      userId: userId ? userId : localStorage.getItem('userId'),
      fromDate: beginDate ? beginDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
      toDate: lastDate ? lastDate : beginDate,
    }

    cancelTokenSource = axios.CancelToken.source()

    let fetch = ''
    if (currDisplayState === 'Individual') {
      fetch = 'GetUsersBifurcationTotalTime'
    } else if (currDisplayState == 'Team' || currDisplayState == 'Organization') {
      dispatch(
        userInfoActions.updateSelectedUser({
          selectedUserName: undefined,
          selectedUserId: undefined,
        })
      )
      fetch =
        userId === 'All'
          ? 'GetAllUsersTotalTimeBifurcation'
          : 'GetUsersBifurcationTotalTimeByManager'
    }

    axios
      .post(`${API_URL}/Calculate/${fetch}`, body, {
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        if (!Array.isArray(res.data)) {
          setAttendance([res.data])
          setScreenLoader(false)
        } else {
          setAttendance(res.data)
          setScreenLoader(false)
        }
      })
      .catch((err: any) => {
        if (err.code != 'ERR_CANCELED') {
          setScreenLoader(false)
        }
      })
  }

  const createExcel = async (e: any) => {
    if (optionSelectedMember.value == '') {
      AlertModal('Please Select Member', '', 'warning', false, '#7066E0', 'Ok')

      setScreenLoader(false)
      //setLoading(false)
      return
    }

    e.preventDefault()

    const workbook = XLSXUtils.book_new()
    const body = {
      fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      toDate: endDate
        ? dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]')
        : dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      organizationId: localStorage.getItem('org_Id'),
      userType:
        alignment === 'Individual'
          ? 'Individual'
          : optionSelectedMember.label === 'All Members'
          ? 'All'
          : 'Team',
      userId:
        alignment === 'Individual'
          ? optionSelectedMember.value
          : optionSelectedMember.label === 'All Members'
          ? ''
          : optionSelectedMember.value,
    }

    const report = await axios.post(`${API_URL}/Calculate/DownloadTimeTrackerReport`, body)

    //to create detailed sheet
    const detailedData = report.data.filter((data: any) => data.reportDate)

    const detailedWorksheetData = detailedData.map((data: any) => {
      return [
        data.employeeName,
        data.reportDate,
        data.timeZone,
        data.inTime === '0001-01-01T00:00:00' ? '' : data.inTime,
        data.outTime === '0001-01-01T00:00:00' ? '' : data.outTime,
        data.attendance,
        data.email,
        data.role,
        data.department,
        data.reportHead,
        convertSecIntoHoursMinSec(data.activeTime),
        convertSecIntoHoursMinSec(data.productiveTime),
        convertSecIntoHoursMinSec(data.unproductiveTime),
        convertSecIntoHoursMinSec(data.idleTime),
        convertSecIntoHoursMinSec(data.awayTime),
        convertSecIntoHoursMinSec(data.totalTime),
      ]
    })

    const detailedWorksheet = XLSXUtils.aoa_to_sheet([
      [
        'Name',
        'Date',
        'Time Zone',
        'In Time',
        'Out Time',
        'Attendance',
        'Email',
        'Role',
        'Department',
        'Report Head',
        'Active Time',
        'Productive Time',
        'Unproductive Time',
        'Idle Time',
        'Away Time',
        'Total Time',
      ],
      ...detailedWorksheetData,
    ])

    XLSXUtils.book_append_sheet(workbook, detailedWorksheet, 'Detailed')

    // to create summary shert
    const summaryData = report.data.filter((data: any) => !data.reportDate)

    const summaryWorksheetData = summaryData.map((data: any) => {
      return [
        data.employeeName,
        data.email,
        data.role,
        data.department,
        data.reportHead,
        convertSecIntoHoursMinSec(data.activeTime),
        convertSecIntoHoursMinSec(data.productiveTime),
        convertSecIntoHoursMinSec(data.unproductiveTime),
        convertSecIntoHoursMinSec(data.idleTime),
        convertSecIntoHoursMinSec(data.awayTime),
        convertSecIntoHoursMinSec(data.totalTime),
      ]
    })

    const summaryWorksheet = XLSXUtils.aoa_to_sheet([
      [
        'Name',
        'Email',
        'Role',
        'Department',
        'Report Head',
        'Active Time',
        'Productive Time',
        'Unproductive Time',
        'Idle Time',
        'Away Time',
        'Total Time',
      ],
      ...summaryWorksheetData,
    ])

    XLSXUtils.book_append_sheet(workbook, summaryWorksheet, 'Summary')

    //to create dates sheet
    const formattedStart = parseISO(dayjs(startDate).format())
    const formattedEnd = parseISO(dayjs(endDate ? endDate : startDate).format())

    let currentDate = formattedStart

    while (isBefore(currentDate, formattedEnd) || isEqual(currentDate, formattedEnd)) {
      const currentDateString = dayjs(currentDate).format('DD-MM-YYYY')

      const dateData = report.data.filter(
        (data: any) => dayjs(data.reportDate).format('DD-MM-YYYY') === currentDateString
      )

      const dateWorksheetData = dateData.map((data: any) => {
        return [
          data.employeeName,
          data.reportDate,
          data.inTime === '0001-01-01T00:00:00' ? '' : data.inTime,
          data.outTime === '0001-01-01T00:00:00' ? '' : data.outTime,
          data.attendance,
          data.email,
          data.role,
          data.department,
          data.reportHead,
          convertSecIntoHoursMinSec(data.activeTime),
          convertSecIntoHoursMinSec(data.productiveTime),
          convertSecIntoHoursMinSec(data.unproductiveTime),
          convertSecIntoHoursMinSec(data.idleTime),
          convertSecIntoHoursMinSec(data.awayTime),
          convertSecIntoHoursMinSec(data.totalTime),
        ]
      })

      const dateWorksheet = XLSXUtils.aoa_to_sheet([
        [
          'Name',
          'Date',
          'In Time',
          'Out Time',
          'Attendance',
          'Email',
          'Role',
          'Department',
          'Report Head',
          'Active Time',
          'Productive Time',
          'Unproductive Time',
          'Idle Time',
          'Away Time',
          'Total Time',
        ],
        ...dateWorksheetData,
      ])

      XLSXUtils.book_append_sheet(workbook, dateWorksheet, dayjs(currentDate).format('D MMMM YYYY'))
      currentDate = addDays(currentDate, 1)
    }

    const fileBuffer = write(workbook, {type: 'buffer', bookType: 'xlsx'})
    const blob = new Blob([fileBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const fileName =
      alignment === 'Individual'
        ? attendance[0].userName
        : `${showstartdate}-${showenddate ? showenddate : showstartdate}`
    link.setAttribute('download', `${fileName}.xlsx`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(dates)
    setStartDate(start)
    setEndDate(end)
    SendDataOfDate(start, end)
  }

  return (
    <>
      <div className='card-body'>
        <div className='card-header border-0 pt-5 pb-5'>
          <div className='d-flex justify-content-between align-items-center py-3 px-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Time Tracker</span>
            </h3>

            <div className='d-flex'>
              {role !== process.env.REACT_APP_SYSTEM_THIRD_ROLE && (
                <>
                  <div className=' d-flex' style={{marginRight: '10px', height: '3.2rem'}}>
                    <ToggleButtonGroup
                      color='primary'
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                      aria-label='Platform'
                    >
                      <ToggleButton value='Organization'>Organization</ToggleButton>
                      <ToggleButton value='Team'>Team</ToggleButton>
                      <ToggleButton value='Individual'>Individual</ToggleButton>
                    </ToggleButtonGroup>
                  </div>

                  <div
                    style={{marginRight: '10px', width: '200px'}}
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title={alignment == 'Team' ? 'Search Manager' : 'Search Member'}
                  >
                    <Select
                      components={makeAnimated()}
                      value={optionSelectedMember}
                      isDisabled={disableReactSelect || alignment === 'Organization'}
                      options={memberOption}
                      placeholder='Select Member'
                      onChange={(item: any) => {
                        if (
                          (ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                            ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE) &&
                          ConfigDetails.selectedUserId !== item.value
                        ) {
                          dispatch(
                            userInfoActions.updateSelectedUser({
                              selectedUserName: item.label,
                              selectedUserId: item.value,
                            })
                          )
                        }
                        if (optionSelectedMember !== item) {
                          setOptionSelectedMember(item)
                          SendDataOfUser(item.value)
                        }
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                    {memberError && (
                      <span className='text-danger' style={{marginLeft: '1rem'}}>
                        Please Select Member
                      </span>
                    )}
                  </div>
                </>
              )}

              {localStorage.getItem('role') !== process.env.REACT_APP_SYSTEM_FIRST_ROLE &&
                localStorage.getItem('role') !== process.env.REACT_APP_SYSTEM_FIFTH_ROLE &&
                memberOption.length > 1 && (
                  <div
                    style={{marginRight: '10px', width: '200px'}}
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title={alignment == 'Team' ? 'Search Manager' : 'Search Member'}
                  >
                    <Select
                      components={makeAnimated()}
                      value={optionSelectedMember}
                      isDisabled={disableReactSelect}
                      options={memberOption}
                      placeholder='Select Member'
                      onChange={(item: any) => {
                        if (optionSelectedMember !== item) {
                          setOptionSelectedMember(item)
                          SendDataOfUser(item.value)
                        }
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                    {memberError && (
                      <span className='text-danger' style={{marginLeft: '1rem'}}>
                        Please Select Member
                      </span>
                    )}
                  </div>
                )}

              <div className='d-flex flex-column'>
                {MultiDateTimePicker(
                  startDate,
                  endDate,
                  handleDateChange,
                  'form-control custom-Height'
                )}

                {dateError && (
                  <span className='text-danger' style={{marginLeft: '1rem'}}>
                    Please Select Date
                  </span>
                )}
              </div>

              <div className='d-flex '>
                <button
                  type='button'
                  onClick={(e) => createExcel(e)}
                  className='btn btn-primary'
                  //disabled={screenloader}
                  style={{
                    marginLeft: '1rem',
                    display:
                      role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                      role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
                        ? 'block'
                        : 'none',
                    height: '3.2rem',
                  }}
                >
                  <span className='indicator-label'>
                    <i className='fa fa-download'></i>Download
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              {!screenloader &&
                TimeTrackerTable(
                  attendance,
                  currentItems,
                  convertSecIntoHoursMinSec,
                  convertSecIntoAMPM
                )}

              {screenloader
                ? Loader('100px')
                : currentItems.length === 0 && <h2 className='noRecordFound'>No Records Found</h2>}
            </div>
          </div>
          {attendance.length > 10 && (
            <div className='d-flex align-items-center justify-content-center position-relative mb-7'>
              <div style={{left: '1rem', position: 'absolute', top: '0'}}>
                {RowsPerPage(
                  setForcePage,
                  setRowPerPage,
                  setItemOffset,
                  rowPerPage,
                  attendance.length,
                  '1rem'
                )}
              </div>
              {Pagination(handlePageClick, pageCount, forcePage)}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {AttendanceSheetemp}
