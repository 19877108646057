import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function GetAllActivePartner() {
  return axios.get(`${API_URL}/Registration/GetPartnerList`)
}

export function GetAllPartner() {
  return axios.get(`${API_URL}/Registration/GetAllPartners`)
}

export function GetPartnerById(partnerId: string) {
  return axios.get(`${API_URL}/Registration/GetPartnerById`, {
    params: {
      partnerId,
    },
  })
}

export function ActivatePartner(userId: string) {
  const body = {
    userId: [userId],
  }
  return axios.post(`${API_URL}/Registration/ActivateUser`, body)
}

export function GatOrgsPaymentDetailsFromPartner(partnerId: string) {
  return axios.get(`${API_URL}/Payment/GetOrgsPaymentDetailsForPartner?partnerId=${partnerId}`)
}

export function GetAllCommissionDetailByPartnerId(body: any) {
  return axios.post(`${API_URL}/PartnerCommission/GetAllCommissionDetailByPartnerId`, body)
}

export function GetReferedOrganizationByPartnerId(partnerId: any) {
  return axios.get(`${API_URL}/MeraMonitor/ReferedOrganizationByPartnerId`, {
    params: {
      partnerId,
    },
  })
}

export function GetPendingCommissionDetailByPartnerId(body: any) {
  return axios.post(`${API_URL}/PartnerCommission/GetPendingCommissionDetailByPartnerId`, body)
}

export function GetPartnerTypeList() {
  return axios.get(`${API_URL}/Registration/GetPartnerTypeList`)
}

export function ValidateReferralCode(code: string) {
  return axios.get(`${API_URL}/Registration/ValidateReferralCode?referralCode=${code}`)
}

export function GetPartnerContractDocByPartnerId(partnerId: string) {
  return axios.get(
    `${API_URL}/Registration/GetPartnerContractDocByPartnerId?partnerId=${partnerId}`
  )
}

export function GetOrgContractDocByOrgId(orgId: string) {
  return axios.get(`${API_URL}/Registration/GetOrgContractDocByOrgId?orgId=${orgId}`)
}
