import {Watch} from 'react-loader-spinner'
import {GetAssignee} from '../services/GetAllAssinee.services'
import ReactPaginate from 'react-paginate'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import swal from 'sweetalert2'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import dayjs, {Dayjs} from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import Switch from 'react-switch'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import {
  GetAllUserListByOrganization,
  GetOrgNameAndId,
  GetOrganizationExpiryDetailByOrgId,
} from '../services/common.services'
import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import * as XLSX from 'xlsx'
import {driver} from 'driver.js'
import 'driver.js/dist/driver.css'
import {
  departmentInfoByOrg,
  getRoles,
  getTimeZone,
  memberInfoByOrg,
} from '../modules/auth/redux/AuthCRUD'
import {GetPartnerTypeList, ValidateReferralCode} from '../services/partner.service'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import axios from 'axios'
import AddNewSalesPerson from '../pages/Partner/AddNewSalesPerson'
import {convertSecIntoHoursMinSec} from '../pages/Common_Function/Function'
import moment from 'moment'
import {DownloadAttendanceReport} from '../services/attendance.service'
import {userInfoActions} from '../modules/auth'
import * as auth from '../../app/modules/auth/redux/AuthRedux'

const API_URL = process.env.REACT_APP_API_URL
export function renderTableHeader() {
  return (
    <table style={{width: '100%', height: '3.8rem'}}>
      <thead>
        <tr className='fw-bolder' style={{background: 'rgb(114, 143, 206)', fontSize: '15px'}}>
          <th style={{width: '230px'}}></th>
          <th className='' style={{width: '360px', textAlign: 'left', paddingLeft: '0.8rem'}}>
            Apps &amp; websites
          </th>
          <th className='' style={{textAlign: 'left', paddingLeft: '1.8rem'}}>
            Logged time
          </th>
        </tr>
      </thead>
    </table>
  )
}

export function Loader(topMargin: any) {
  return (
    <div
      className='mb-3'
      style={{
        display: 'flex',
        width: '100%',
        background: 'transparent',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: `${topMargin}`,
        zIndex: '1',
      }}
    >
      <Watch
        height='80'
        width='80'
        radius='48'
        color='#4fa94d'
        ariaLabel='watch-loading'
        wrapperStyle={{}}
      />
    </div>
  )
}

export const GetAllAssinees = (setMembers: (members: any[]) => void) => {
  GetAssignee()
    .then((res) => {
      const newList = res.data.map((item: any) => {
        return {
          label: item.assigneeName,
          value: item.assigneeId,
        }
      })

      const firstItemIndex = newList.findIndex(
        (item: any) => item.value === localStorage.getItem('userId')
      )
      if (firstItemIndex > -1) {
        const [firstItem] = newList.splice(firstItemIndex, 1)
        newList.unshift(firstItem)
      }
      setMembers(newList)
    })
    .catch((err) => {
      console.log(err)
    })
}

export function Pagination(handlePageClick: any, pageCount: any, forcePage: any) {
  return (
    <ReactPaginate
      nextLabel='>>'
      onPageChange={handlePageClick}
      pageRangeDisplayed={1}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      forcePage={forcePage}
      previousLabel='<<'
      containerClassName={'paginationBttns'}
      previousLinkClassName={'previousBttn'}
      nextLinkClassName={'nextBttn'}
      disabledClassName={'paginationDisabled'}
      activeClassName={'paginationActive'}
    />
  )
}

export function RowsPerPage(
  setForcePage: any,
  setRowPerPage: any,
  setItemOffset: any,
  rowPerPage: any,
  length: any,
  rightMargin: any
) {
  return (
    <div
      className='card-toolbar d-flex align-items-center'
      data-bs-toggle='tooltip'
      data-bs-placement='top'
      data-bs-trigger='hover'
    >
      <span className='select-custom-header' style={{marginRight: `${rightMargin}`}}>
        Rows Per Page
      </span>
      <select
        style={{
          padding: '0px',
          minHeight: '2.7rem',
          cursor: 'pointer',
          width: '4rem',
          textAlign: 'center',
        }}
        className='form-control form-control-lg form-control-soli custom-select-class'
        onChange={(e) => {
          setForcePage(null)
          setRowPerPage(Number(e.target.value))
          setItemOffset(0)
          setTimeout(() => {
            setForcePage(0)
          }, 100)
        }}
      >
        <option value='' hidden>
          {rowPerPage}
        </option>
        <option value='10'>10</option>
        <option value='25'>25</option>
        <option value='50'>50</option>
        <option value='75'>75</option>
        <option value='100'>100</option>
        <option value={length}>All</option>
      </select>
    </div>
  )
}

export function BuilderPageSelect(
  alignment: any,
  department: any,
  selectedDepartment: any,
  handleDeptChange: any
) {
  return (
    <div
      style={{marginRight: '10px', width: '200px'}}
      className='card-toolbar'
      data-bs-toggle='tooltip'
      data-bs-placement='top'
      data-bs-trigger='hover'
      title={alignment == 'Team' ? 'Search Manager' : 'Search Member'}
    >
      <Select
        components={makeAnimated()}
        value={department.filter((option: any) => option.value === selectedDepartment)}
        options={department}
        placeholder='Select Department'
        isClearable={false}
        isSearchable={true}
        closeMenuOnScroll={true}
        onChange={(e) => handleDeptChange(e)}
      />
    </div>
  )
}

export function SingleDatePickerComponent(
  startDate: Date,
  handleDateChange: any,
  className: any,
  maxDate?: any,
  minDate?: any,
  disabledDate?: any
) {
  return (
    <DatePicker
      // className='form-control custom-Height'
      disabled={disabledDate}
      className={`${className}`}
      dateFormat='dd/MM/yyyy'
      selected={startDate}
      onChange={(date: any) => {
        handleDateChange(date)
      }}
      isClearable={false}
      placeholderText='Select a Date'
      maxDate={maxDate ? maxDate : new Date()}
      minDate={minDate ? minDate : ''}
      onKeyDown={(e) => {
        e.preventDefault()
      }}
    />
  )
}

export function MultiDateTimePicker(
  startDate: any,
  endDate: any,
  handleDateChange: any,
  className: any,
  max?: any
) {
  return (
    <DatePicker
      className={`${className}`}
      dateFormat='dd/MM/yyyy'
      selected={startDate}
      onChange={(date: any) => {
        handleDateChange(date)
      }}
      startDate={startDate}
      isClearable={true}
      placeholderText='Select Date Range'
      endDate={endDate}
      maxDate={max ? max : new Date()}
      selectsRange
      onKeyDown={(e) => {
        e.preventDefault()
      }}
    />
  )
}

export function AlertModal(
  message: any,
  textMessage: any,
  alertIcon: any,
  showCancelButton: any,
  confirmButtonColor: any,
  confirmButtonText: any
) {
  return swal.fire({
    title: `${message}`,
    text: `${textMessage}`,
    icon: alertIcon,
    showCancelButton: showCancelButton,
    width: '23rem',
    heightAuto: false,
    confirmButtonColor: confirmButtonColor,
    cancelButtonColor: '#d33',
    confirmButtonText: confirmButtonText,
    backdrop: `rgba(0,0,0,0.8)`,
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    customClass: {
      title: 'swal-popup',
    },
  })
}

export function WebAndAppHeader(
  role: any,
  startDate: any,
  endDate: any,
  handleDateChange: any,
  dateError: any,
  members: any,
  userId: any,
  handleChange: any
) {
  return (
    <>
      {(role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
        role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE) && (
        <div className='d-flex align-items-center mb-3'>
          <span className='px-3' style={{fontSize: '18px'}}>
            Date
          </span>

          <div id='select-date'>
            {MultiDateTimePicker(startDate, endDate, handleDateChange, 'form-control')}

            {dateError && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2 mx-3 '>Select a date</div>
              </div>
            )}
          </div>

          <span className='px-3' style={{fontSize: '18px', marginLeft: '20px'}}>
            User{' '}
          </span>

          <div style={{minWidth: '16rem'}}>
            <Select
              className='selectDropdown'
              components={makeAnimated()}
              value={members.filter((option: any) => option.value === userId)}
              options={members}
              placeholder='select member'
              onChange={(e) => handleChange(e.value)}
              isClearable={false}
              isSearchable={true}
              closeMenuOnScroll={true}
            />
          </div>
        </div>
      )}

      {role === process.env.REACT_APP_SYSTEM_THIRD_ROLE && (
        <div className='d-flex align-items-center mb-3'>
          <div>
            <span className='px-3' style={{fontSize: '18px'}}>
              Date
            </span>
          </div>
          <div className='date-wrapper'>
            <div id='select-date'>
              {MultiDateTimePicker(startDate, endDate, handleDateChange, 'form-control')}

              {dateError && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2 mx-3'>Select a date</div>
                </div>
              )}
            </div>
          </div>
          {members.length > 1 && (
            <>
              <div>
                <span className='px-3' style={{fontSize: '18px', marginLeft: '20px'}}>
                  User
                </span>
              </div>
              <div>
                <div style={{minWidth: '16rem'}}>
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    value={members.filter((option: any) => option.value === userId)}
                    options={members}
                    placeholder='select member'
                    onChange={(e) => handleChange(e.value)}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export function MemberAndPlanSearchBar(content: any, placeholder: any, handleSearchChange: any) {
  return (
    <Tippy placement='top' content={content}>
      <div
        className='card-toolbar'
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        data-bs-trigger='hover'
        // title='Search members by name or email'
      >
        <div style={{position: 'relative', marginRight: '1rem'}}>
          <input
            style={{
              padding: '6px 30px 6px 3rem',
              //marginTop: 'unset',
              // paddingLeft: '1rem',
              // color: 'white',
              minHeight: '3rem',
            }}
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder={placeholder}
            onChange={handleSearchChange}
          />
          <img
            style={{top: '8px'}}
            className='custom-placeholder'
            src={toAbsoluteUrl('/media/icons/duotune/art/custom-search.png')}
          ></img>
        </div>
      </div>
    </Tippy>
  )
}

export function CommonActionButtons(handleUpdate: any, handleCancel: any) {
  return (
    <div className='d-flex justify-content-evenly flex-shrink-0'>
      <Tippy placement='top' content='Update'>
        <button
          onClick={handleUpdate}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/update.svg' />
        </button>
      </Tippy>

      <Tippy placement='top' content='Cancel'>
        <button
          onClick={handleCancel}
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm me-1'
          style={{}}
        >
          <KTSVG path='/media/icons/duotune/art/cross.svg' />
        </button>
      </Tippy>
    </div>
  )
}

export function ErrorModal() {
  return AlertModal(
    `Something went wrong!`,
    'Please try again later',
    'warning',
    false,
    '#7066E0',
    'Ok'
  )
}

const predefinedRanges = {
  today: [dayjs(), dayjs()] as [Dayjs, Dayjs],
  yesterday: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] as [Dayjs, Dayjs],
  lastWeek: [dayjs().subtract(7, 'days'), dayjs()] as [Dayjs, Dayjs],
  lastMonth: [
    dayjs().subtract(1, 'month').startOf('month'),
    dayjs().subtract(1, 'month').endOf('month'),
  ] as [Dayjs, Dayjs],
  thisMonth: [dayjs().startOf('month'), dayjs()] as [Dayjs, Dayjs],
  lastYear: [
    dayjs().subtract(1, 'year').startOf('year'),
    dayjs().subtract(1, 'year').endOf('year'),
  ] as [Dayjs, Dayjs],
  nextYear: [dayjs().startOf('year'), dayjs()] as [Dayjs, Dayjs],
}

export const generateDatePresets = () => {
  return [
    {
      label: 'Today',
      value: predefinedRanges.today,
    },
    {
      label: 'Yesterday',
      value: predefinedRanges.yesterday,
    },
    {
      label: 'Last Week',
      value: predefinedRanges.lastWeek,
    },
    {
      label: 'Last Month',
      value: predefinedRanges.lastMonth,
    },
    {
      label: 'This Month',
      value: predefinedRanges.thisMonth,
    },
    {
      label: 'Last Year',
      value: predefinedRanges.lastYear,
    },
    {
      label: 'This Year',
      value: predefinedRanges.nextYear,
    },
  ]
}

export const GetOrgList = async () => {
  try {
    const list = await GetOrgNameAndId()
    return list.data.map((item: any) => {
      return {
        label: item.organizationName,
        value: item.org_Id,
      }
    })
  } catch (error) {
    ErrorModal()
  }
}

export const GetActiveInactiveUser = async (orgId: any) => {
  try {
    const userList = await GetAllUserListByOrganization(orgId)
    return userList.data.map((item: any) => {
      return {
        label: item.fullName,
        value: item.userId,
      }
    })
  } catch (error) {
    ErrorModal()
  }
}

export const FillMemberDropDownInWebApp = (
  setSpinner: any,
  cancelTokenSource: any,
  role: any,
  setMembers: any
) => {
  setSpinner(true)

  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }

  if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
    GetAllAssinees(setMembers)
  }

  if (
    role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
    role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
  ) {
    memberInfoByOrg()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.fullName,
            value: item.userId,
          }
        })
        setMembers(newList)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  if (role === process.env.REACT_APP_SYSTEM_THIRD_ROLE) {
    GetAllAssinees(setMembers)
  }
}

export const WebAppHeader = (
  role: any,
  startDate: any,
  endDate: any,
  handleDateChange: any,
  dateError: any,
  members: any,
  userId: any,
  handleChange: any
) => {
  return (
    <div className='card mt-5'>
      <div className='mx-5 my-5'>
        {role === process.env.REACT_APP_SYSTEM_SECOND_ROLE && (
          <div className='d-flex align-items-center mb-3'>
            <div>
              <span className='px-3' style={{fontSize: '18px'}}>
                Date
              </span>
            </div>
            <div>
              <div id='select-date'>
                {MultiDateTimePicker(startDate, endDate, handleDateChange, 'form-control')}

                {dateError && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2 mx-3 '>Select a date</div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <span className='px-3' style={{fontSize: '18px', marginLeft: '20px'}}>
                User
              </span>
            </div>
            <div>
              <div style={{minWidth: '16rem'}}>
                <Select
                  className='selectDropdown'
                  components={makeAnimated()}
                  value={members.filter((option: any) => option.value === userId)}
                  options={members}
                  placeholder='select member'
                  onChange={(e) => handleChange(e.value)}
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnScroll={true}
                />
              </div>
            </div>
          </div>
        )}

        {WebAndAppHeader(
          role,
          startDate,
          endDate,
          handleDateChange,
          dateError,
          members,
          userId,
          handleChange
        )}
      </div>
    </div>
  )
}

export const addNewSales = (formik: any) => {
  const array = new Uint32Array(4)
  crypto.getRandomValues(array)
  const randomString = array.reduce((str, value) => str + value.toString(16).padStart(8, '0'), '')

  const newSalesObject = {
    id: randomString.slice(0, 12),
    salesName: '',
    phoneNo: '',
    email: '',
  }

  formik.setFieldValue('addSalesDetailsRequest', [
    ...formik.values.addSalesDetailsRequest,
    newSalesObject,
  ])
}

export const handelValidate = async (e: any, formik: any, setIsValid: any) => {
  try {
    e.preventDefault()
    const validity = await ValidateReferralCode(formik.values.referralCode)

    AlertModal(
      validity.data.message,
      '',
      validity.data.success ? 'success' : 'warning',
      false,
      '#7066E0',
      'Ok'
    ).then(() => {
      if (validity.data.success) {
        setIsValid(true)
      } else {
        setIsValid(false)
      }
    })
  } catch (error) {
    ErrorModal()
  }
}

export const handleReferralCodeChange = (event: any, formik: any, setIsValid: any) => {
  const {value} = event.target

  if (value.length === 0) {
    setIsValid(true)
  } else {
    setIsValid(false)
  }

  formik.setFieldValue('referralCode', value)
}

const letters = /^[a-zA-Z][a-zA-Z ]*$/
export const planSchema = Yup.object().shape({
  planName: Yup.string()
    .matches(letters, 'Only Characters are not allowed')
    .min(3, 'Minimum 3 character')
    .required('Plan Name is required. '),

  planTypeId: Yup.string().required('Duration is required.'),
  planDescription: Yup.string(),
  benefitIds: Yup.array().of(Yup.string()),
  currencyCodeId: Yup.string().required('Please select a currency.'),
  amount: Yup.string().required('Amount is required.'),
  isCustomPlan: Yup.boolean().required('Please specify if it is a custom plan or not.'),
})

export const handleOrgAdd = (
  orgCount: any,
  selectedValues: any,
  setOrgCount: any,
  orgErrors: any,
  setOrgErrors: any,
  dateErrors: any,
  setDateErrors: any
) => {
  const lastIdx = orgCount - 1
  const lastOrg = selectedValues[lastIdx]?.organization
  const lastDate = selectedValues[lastIdx]?.date

  // Check if organization and date are selected for the last index
  if (lastOrg && lastDate) {
    setOrgCount(orgCount + 1)
  } else if (!lastOrg) {
    const updatedErrors = [...orgErrors]
    updatedErrors[lastIdx] = true
    setOrgErrors(updatedErrors)
  } else if (!lastDate) {
    const updatedErrors = [...dateErrors]
    updatedErrors[lastIdx] = true
    setDateErrors(updatedErrors)
  }
}

export const createTooltipInput = (content: any, placeholderText: any, handleSearchChange: any) => {
  return (
    <Tippy placement='top' content={content}>
      <div
        className='card-toolbar'
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        data-bs-trigger='hover'
      >
        <div style={{position: 'relative', marginRight: '1rem'}}>
          <input
            style={{padding: '6px 30px 6px 3rem', minHeight: 'unset'}}
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder={placeholderText}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          <img
            style={{top: '8px'}}
            className='custom-placeholder'
            src={toAbsoluteUrl('/media/icons/duotune/art/custom-search.png')}
          />
        </div>
      </div>
    </Tippy>
  )
}

interface LabelProps {
  formik: any
  loading: boolean
  navigate: (path: string) => void
  isDisabled: boolean
  buttonName: string
}
const Label: React.FC<LabelProps> = ({formik, loading, navigate, isDisabled, buttonName}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    formik.handleSubmit()
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0' role='banner'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Information Details</h3>
        </div>
      </div>

      <div className='collapse show'>
        <form onSubmit={handleSubmit} className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Configuration Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  disabled={isDisabled}
                  type='text'
                  className='form-control form-control-border'
                  placeholder='Configuration Name'
                  {...formik.getFieldProps('infoConfigName')}
                />
                {formik.touched.infoConfigName && formik.errors.infoConfigName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.infoConfigName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Description</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-border'
                  placeholder='Description'
                  {...formik.getFieldProps('infoValue')}
                />
                {formik.touched.infoValue && formik.errors.infoValue && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.infoValue}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => navigate('/allInformation')}
            >
              Cancel
            </button>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading ? (
                <span className='indicator-label'>{buttonName}</span>
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default Label

export const AppWebUsageForManager = (
  role: any,
  setSpinner: any,
  department: any,
  startDate: any,
  endDate: any,
  category: any,
  selectedUser: any,
  fetchData: any,
  cancelTokenSource: any,
  setAllData: any
) => {
  if (role === 'Manager') {
    getManagerData(
      cancelTokenSource,
      department,
      setSpinner,
      startDate,
      endDate,
      category,
      selectedUser,
      setAllData
    )
  } else {
    let url = ''
    let body = {}
    setSpinner(true)

    if (department) {
      url = 'ProductiveAndUnProductiveAppWebUsageByDept'
      body = {
        organizationId: localStorage.getItem('org_Id'),
        departmentId: department,
        fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
        categoryName: category,
      }
    }
    if (selectedUser) {
      if (selectedUser === 'All') {
        url = 'ProductiveAndUnProductiveAppWebUsageForOrganization'
        body = {
          organizationId: localStorage.getItem('org_Id'),
          fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
          toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
          categoryName: category,
        }
      } else {
        url = 'ProductiveAndUnProductiveAppWebUsageForManager'
        body = {
          organizationId: localStorage.getItem('org_Id'),
          fromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
          managerId: selectedUser,
          toDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
          categoryName: category,
        }
      }
    }

    fetchData(url, body)
  }
}

export const getManagerData = async (
  cancelTokenSource: any,
  department: any,
  setSpinner: any,
  startDate: any,
  endDate: any,
  category: any,
  selectedUser: any,
  setAllData: any
) => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }
  if (!department) {
    setSpinner(true)
    let body = {
      OrganizationId: localStorage.getItem('org_Id'),
      FromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      ToDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
      CategoryName: category,
      ManagerId: selectedUser,
    }
    cancelTokenSource = axios.CancelToken.source()

    axios
      .get(`${API_URL}/AdminDashboard/ProductiveAndUnProductiveAppWebUsageForReportManager`, {
        params: body,
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setAllData(res.data)
        setSpinner(false)
      })
      .catch((err) => {
        console.log(err)
        setSpinner(false)
      })
  } else {
    setSpinner(true)

    let body = {
      OrganizationId: localStorage.getItem('org_Id'),
      ManagerId: selectedUser,
      DepartmentId: department,
      FromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
      ToDate: dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]'),
      CategoryName: category,
    }

    cancelTokenSource = axios.CancelToken.source()
    axios
      .get(
        `${API_URL}/AdminDashboard/ProductiveAndUnProductiveAppWebUsageForReportManagerandDeptId`,
        {
          params: body,
          cancelToken: cancelTokenSource?.token,
        }
      )
      .then((res) => {
        setAllData(res.data)
        setSpinner(false)
      })
      .catch((err) => {
        console.log(err)
        setSpinner(false)
      })
  }
}

const phone = /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/

export const baseSchema = Yup.object().shape({
  organizationName: Yup.string()
    .min(3, 'Minimum 3 character')
    .max(50, 'Maximum 50 character')
    .required('Name is required'),
  org_Email: Yup.string()
    .email('Wrong email format')
    .min(10, 'Minimum 10 character')
    .max(50, 'Maximum 50 character')
    .required('Email is required'),
  org_Address: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 character')
    .max(250, 'Maximum 250 character'),
  org_ContactNo: Yup.string()
    .nullable()
    .matches(phone, 'Contact Number should only contains numerals')
    .min(10, 'Contact Number should contain 10 numbers')
    .max(10, 'Contact Number should contain 10 numbers'),
})

export const baseMemberSchema = Yup.object().shape({
  fullName: Yup.string()
    .matches(letters, 'numerals and symbols are not allowed')
    .min(3, 'Minimum 3 character')
    .max(50, 'Maximum 50 character')
    .required('Full Name is required'),

  email: Yup.string()
    .email('Wrong email format')
    .min(10, 'Minimum 10 character')
    .max(50, 'Maximum 50 character')
    .required('Email is required'),
  contactNo: Yup.string()
    .nullable()
    .matches(phone, 'Contact Number should contain 10 numbers')
    .min(10, 'Contact Number should contain minimum 10 numbers'),
  baseLocation: Yup.string().max(250, 'Maximum 250 character'),

  departmentId: Yup.string(),
  empId: Yup.string(),
  timeZoneId: Yup.mixed().required('Select Time Zone'),
})

export const StaticPagination = () => {
  return (
    <div className='d-flex flex-stack flex-wrap pt-10'>
      <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 10 of 50 entries</div>

      <ul className='pagination'>
        <li className='page-item previous'>
          <a href='#' className='page-link'>
            <i className='previous'></i>
          </a>
        </li>

        <li className='page-item active'>
          <a href='#' className='page-link'>
            1
          </a>
        </li>

        <li className='page-item'>
          <a href='#' className='page-link'>
            2
          </a>
        </li>

        <li className='page-item'>
          <a href='#' className='page-link'>
            3
          </a>
        </li>

        <li className='page-item'>
          <a href='#' className='page-link'>
            4
          </a>
        </li>

        <li className='page-item'>
          <a href='#' className='page-link'>
            5
          </a>
        </li>

        <li className='page-item'>
          <a href='#' className='page-link'>
            6
          </a>
        </li>

        <li className='page-item next'>
          <a href='#' className='page-link'>
            <i className='next'></i>
          </a>
        </li>
      </ul>
    </div>
  )
}

export const ProjectTaskApiCall = (
  endpoint: any,
  destUrl: any,
  body: any,
  resetForm: any,
  setLoading: any,
  navigate: any,
  setSubmitting: any
) => {
  axios
    .post(`${API_URL}/${endpoint}`, body)
    .then((res) => {
      AlertModal(
        res.data.message,
        '',
        res.data.success ? 'success' : 'warning',
        false,
        '#7066E0',
        'Ok'
      ).then(() => {
        resetForm()
        setLoading(false)
        navigate(res.data.success === false ? '' : destUrl)
      })
    })
    .catch((err) => {
      console.log(err)
      setLoading(false)
      setSubmitting(false)
    })
}

export const deleteHandler = (
  id: any,
  memberType: string,
  setSpinner: any,
  setItemOffset: any,
  // setForcePage: any,
  getmember?: any,
  listOfInactiveUsers?: any
) => {
  try {
    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Yes, delete it!'
    ).then((result: any) => {
      if (result.isConfirmed) {
        setSpinner(true)
        axios
          .patch(`${API_URL}/OrganizationCleanup/UserDataCleanupAsync?userId=${id}`)
          .then((res) => {
            if (memberType === 'active') {
              getmember()
            } else {
              listOfInactiveUsers()
            }

            AlertModal(
              res.data.message,
              '',
              res.data.success ? 'success' : 'warning',
              false,
              '#7066E0',
              'Ok'
            ).then(() => {
              setItemOffset(0)
              setSpinner(false)
            })
          })
          .catch((err) => {
            console.log(err)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Member not deleted', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  } catch (error) {
    ErrorModal()
  }
}

export const passwordCreateResetForm = (
  formik: any,
  heading: any,
  name: any,
  hasErrors: any,
  loading: any,
  buttonName: string
) => {
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
      style={{padding: '40px 50px'}}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{heading}</h1>
        <div className='text-gray-400 fw-bold fs-4' style={{wordWrap: 'break-word'}}>
          Hello {name}, please enter your new password.
        </div>
      </div>
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Password Rested Successfully</div>
        </div>
      )}

      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger mt-2'>
              <span role='text-danger mt-2'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Confirm Password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx('form-control form-control-lg form-control-solid', {
            'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,

            'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
          })}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger mt-2'>
              <span role='text-danger mt-2'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-lg fw-bolder me-4'
          style={{background: '#603BE5', color: '#FFFFFF'}}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{buttonName}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-light-primary fw-bolder'
            style={{background: '#603BE5', color: '#FFFFFF'}}
          >
            Cancel
          </button>
        </Link>
      </div>
    </form>
  )
}

export const handleDateChange = (dates: any, setStartDate: any, setEndDate: any) => {
  const [start, end] = dates
  setStartDate(start)
  setEndDate(end)
}

export const TimeTrackerTable = (
  attendance: any,
  currentItems: any,
  convertSecIntoHoursMinSec: any,
  convertSecIntoAMPM: any
) => {
  return (
    <table className='table align-middle gs-0 gy-4' style={{border: '1px solid '}}>
      <thead>
        <tr
          className='fw-bolder'
          style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
        >
          <th></th>
          <th className=' '>Name</th>
          <th className=' '> Date </th>
          <th className=''>Time Zone</th>
          <th className=' text-center'>Department </th>
          <th className=' text-center'>Active Time</th>
          <th className=' text-center'>Idle Time</th>
          <th className=' text-center'>Away Time</th>
          <th className=' text-center'>Total Time</th>
          <th className=' text-center'>First Activity</th>
          <th className=' text-center'>Last Activity</th>
        </tr>
      </thead>
      <tbody>
        {attendance &&
          typeof attendance !== 'string' &&
          attendance?.length > 0 &&
          currentItems?.map((names: any, index: any) => (
            <tr key={index}>
              <td></td>

              <td>
                <span className='text-dark  d-flex mb-1 fs-6'>{names.userName}</span>
              </td>

              <td>
                <span className='text-dark  d-flex mb-1 fs-6'>
                  {dayjs(names.date).format('DD/MM/YYYY')}
                </span>
              </td>
              <td>
                <span className='text-dark d-flex mb-1 fs-6'>{names.timeZone}</span>
              </td>

              <td>
                <span className='d-flex mb-1 fs-6 justify-content-left'>{names.department}</span>
              </td>

              <td>
                <span className='text-success  d-flex mb-1 fs-6 justify-content-center'>
                  {convertSecIntoHoursMinSec(names.totalActiveTime)}
                </span>
              </td>

              <td>
                <span
                  className='d-flex mb-1 fs-6 justify-content-center'
                  style={{color: 'rgb(251, 188, 4)'}}
                >
                  {convertSecIntoHoursMinSec(names.totalIdleTime)}
                </span>
              </td>

              <td>
                <span
                  className='d-flex mb-1 fs-6 justify-content-center'
                  style={{color: '#9D6B50'}}
                >
                  {convertSecIntoHoursMinSec(names.awayTime)}
                </span>
              </td>

              <td>
                <span
                  className=' d-flex mb-1 fs-6 justify-content-center'
                  style={{color: 'rgb(98, 55, 221'}}
                >
                  {convertSecIntoHoursMinSec(names.totalTime)}
                </span>
              </td>

              <td>
                <span
                  className=' d-flex mb-1 fs-6 justify-content-center'
                  style={{color: 'rgb(64, 132, 255)'}}
                >
                  {convertSecIntoAMPM(names.firstActivity)}
                </span>
              </td>

              <td>
                <span
                  className=' d-flex mb-1 fs-6 justify-content-center'
                  style={{color: 'rgb(244, 51, 74)'}}
                >
                  {convertSecIntoAMPM(names.lastActivity)}
                </span>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

const partnerCodeSchema = Yup.string().matches(
  /^[a-zA-Z0-9]{10}$/,
  'Partner code must be 10 alphanumeric characters long'
)

const GST = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/
const PAN = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/

export const basePartnerSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email('Wrong email format')
    .min(10, 'Minimum 10 character')
    .max(50, 'Maximum 50 character')
    .required('Email is required'),
  partnerTypeId: Yup.string().required('Partner Type is required'),
  referralCode: Yup.string().notRequired().concat(partnerCodeSchema),
  gst: Yup.string().matches(GST, 'GST number format is incorrect').max(15, 'Maximum 15 character'),
  pan: Yup.string().matches(PAN, 'PAN number format is incorrect'),
})

export const PartnerForm = (params: any) => {
  return (
    <form onSubmit={params.formik.handleSubmit} className='form'>
      <div className='card-body border-top p-9'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Full Name</span>
          </label>
          {params.type === 'Add' ? (
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-border '
                placeholder='Name'
                {...params.formik.getFieldProps('fullName')}
              />
              {params.formik.touched.fullName && params.formik.errors.fullName && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{params.formik.errors.fullName}</div>
                </div>
              )}
            </div>
          ) : (
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-border '
                placeholder='Name'
                {...params.formik.getFieldProps('partnerName')}
              />
              {params.formik.touched.partnerName && params.formik.errors.partnerName && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{params.formik.errors.partnerName}</div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Email</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='email'
              className='form-control form-control-border '
              placeholder='Email'
              {...params.formik.getFieldProps('email')}
            />
            {params.formik.touched.email && params.formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Partner Code</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <div className='d-flex'>
              <input
                type='text'
                className='form-control form-control-border '
                placeholder='Partner Code'
                {...params.formik.getFieldProps('referralCode')}
                onChange={(event) =>
                  handleReferralCodeChange(event, params.formik, params.setIsValid)
                }
              />
              <button
                style={{marginLeft: '1rem'}}
                type='submit'
                className='btn btn-primary'
                onClick={(e) => handelValidate(e, params.formik, params.setIsValid)}
                disabled={params.formik.values.referralCode.length !== 10}
              >
                <span className='indicator-label'>validate</span>
              </button>
            </div>
            {params.formik.touched.referralCode && params.formik.errors.referralCode && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.referralCode}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6 '>
          <label className='col-lg-4 col-form-label required fw-bold fs-6 '>Partner Type</label>
          <div className='col-lg-8 fv-row '>
            <div
              className='min-w-200px '
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Search Role'
            >
              {params.type === 'Add' ? (
                <Select
                  className='selectDropdown'
                  components={makeAnimated()}
                  onChange={(e) => params.handleTypeChange(e)}
                  options={params.partnerType}
                  placeholder='Select Role'
                  isClearable={false}
                  isSearchable={false}
                  closeMenuOnScroll={true}
                />
              ) : (
                <Select
                  className='selectDropdown'
                  components={makeAnimated()}
                  value={params.partnerType.find(
                    (option: any) => option.value === params.formik.values.partnerTypeId
                  )}
                  onChange={(e) => params.handleTypeChange(e)}
                  options={params.partnerType}
                  placeholder='Select Partner Type'
                  isClearable={false}
                  isSearchable={false}
                  closeMenuOnScroll={true}
                />
              )}
            </div>
            {params.formik.touched.partnerTypeId && params.formik.errors.partnerTypeId && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.partnerTypeId}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6 '>
          <label className='col-lg-4 col-form-label fw-bold fs-6 '>Role</label>
          <div className='col-lg-8 fv-row '>
            <div
              className='min-w-200px '
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Search Role'
            >
              <Select
                className='selectDropdown '
                components={makeAnimated()}
                defaultValue={{label: 'Partner', value: 'Partner'}}
                placeholder='Select Role'
                isClearable={false}
                isSearchable={false}
                closeMenuOnScroll={true}
                isDisabled={true}
              />
            </div>
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Primary contact name</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-border '
              placeholder='Primary contact name'
              {...params.formik.getFieldProps('primaryContactName')}
            />
            {params.formik.touched.primaryContactName && params.formik.errors.primaryContactName && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.primaryContactName}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Contact No</label>

          <div className='col-lg-8 fv-row'>
            <input
              type='tel'
              className='form-control form-control-border '
              placeholder='Contact No'
              {...params.formik.getFieldProps('contactNo')}
            />
            {params.formik.touched.contactNo && params.formik.errors.contactNo && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.contactNo}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Address</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-border '
              placeholder='Address'
              {...params.formik.getFieldProps('address')}
            />
            {params.formik.touched.address && params.formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.address}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>GST</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-border '
              placeholder='gst'
              {...params.formik.getFieldProps('gst')}
            />
            {params.formik.touched.gst && params.formik.errors.gst && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.gst}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>PAN</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-border '
              placeholder='pan'
              {...params.formik.getFieldProps('pan')}
            />
            {params.formik.touched.pan && params.formik.errors.pan && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.pan}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Commission</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-border '
              placeholder='Commission'
              {...params.formik.getFieldProps('commission')}
            />
            {params.formik.touched.commission && params.formik.errors.commission && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.commission}</div>
              </div>
            )}
          </div>
        </div>

        {params.type === 'Add' ? (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Contract Document</span>
            </label>

            <div className='col-lg-8 flex-row d-flex px-6'>
              <div className='d-flex flex-column w-100'>
                <div className='row mb-6 d-flex flex-row'>
                  <input
                    type='file'
                    id='partnerContractDocFile'
                    accept='.pdf,.doc,.png,.jpeg,.jpg'
                    className='form-control form-control-border'
                    style={{width: '80%'}}
                    onChange={(event: any) => {
                      params.formik.setFieldValue(
                        'partnerContractDocFile',
                        event.currentTarget.files[0]
                      )
                    }}
                    onBlur={params.formik.handleBlur}
                  />

                  {params.formik.touched.partnerContractDocFile &&
                    params.formik.errors.partnerContractDocFile && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>
                          {params.formik.errors.partnerContractDocFile}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Contract Document</span>
            </label>

            <div className='col-lg-8 flex-row d-flex px-6'>
              <div className='d-flex flex-column w-100'>
                <div className='row mb-6 d-flex flex-row flex-nowrap'>
                  {params.showFile && (
                    <div className='d-flex'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Contract Document'
                        value={params.fileName}
                        disabled
                      />
                      <div
                        className='d-flex'
                        style={{
                          flexDirection: 'column',
                          flexWrap: 'nowrap',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '1rem',
                        }}
                      >
                        <i
                          className='fa fa-times'
                          onClick={() => params.cancelFile()}
                          aria-hidden='true'
                          style={{
                            fontSize: '1.5rem',
                            display: 'flex',
                            flexDirection: 'column',

                            cursor: 'pointer',
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                  {!params.showFile && (
                    <div className='d-flex'>
                      <input
                        type='file'
                        className='form-control form-control-border'
                        placeholder='Payment Receipt'
                        onChange={(e) => {
                          if (e.target.files !== null) {
                            params.formik.setFieldValue('partnerContractDocFile', e.target.files[0])
                            params.formik.setFieldValue(
                              'PartnerContractDocName',
                              e.target.files[0].name
                            )
                          }
                        }}
                      />
                    </div>
                  )}

                  {params.formik.touched.partnerContractDocFile &&
                    params.formik.errors.partnerContractDocFile && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>
                          {params.formik.errors.partnerContractDocFile}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}

        {params.type === 'Add' ? (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Sales Contact Details</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <div className='card-toolbar mb-3 d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-sm btn-primary me-3'
                  onClick={() => addNewSales(params.formik)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  Add New Sales
                </button>
              </div>
              {params.formik.values.addSalesDetailsRequest?.map((item: any) => (
                <AddNewSalesPerson
                  id={item.id}
                  updateValue={params.saveSalesPersonInfo}
                  key={item.id}
                  deleteTableRow={params.deleteTableRow}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Sales Contact Details</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <div className='card-toolbar mb-3 d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-sm btn-primary me-3'
                  onClick={() => addNewSales(params.formik)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  Add New Sales
                </button>
              </div>

              {params.formik.values.addSalesDetailsRequest?.map((item: any) => (
                <AddNewSalesPerson
                  id={item.id}
                  data={item}
                  updateValue={params.saveSalesPersonInfo}
                  key={item.id}
                  deleteTableRow={params.deleteTableRow}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button className='btn btn-secondary' onClick={() => params.navigate('/partner')}>
          Cancel
        </button>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <button type='submit' className='btn btn-primary' disabled={!params.isValid}>
          {!params.loading && <span className='indicator-label'>Add Partner</span>}
          {params.loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export const GetList = async (setPartnerType: any) => {
  try {
    const data = await GetPartnerTypeList()
    const newList = data.data.map((item: any) => {
      return {
        label: item.partnerTypeName,
        value: item.partnerTypeId,
      }
    })

    setPartnerType(newList)
  } catch (error) {
    ErrorModal()
  }
}

export const loadAddEditMemberDefaultData = async (param: any) => {
  try {
    await getAllUser(param.setManagerOption)

    const rolesRes = await getRoles()
    const roleList = rolesRes.data.map((item: any) => ({
      label: item.roleName,
      value: item.roleId,
    }))

    param.setRolesOption(roleList)
    if (param.type === 'Add') {
      const defaultUserRole = roleList.find((role: any) => role.label === 'User')
      if (defaultUserRole) {
        param.setRoles(defaultUserRole)
        param.formik.setFieldValue('roleId', defaultUserRole.value)
      }
    }

    const departmentRes = await departmentInfoByOrg()
    const departmentList = departmentRes.data.map((item: any) => ({
      label: item.department,
      value: item.departmentId,
    }))
    param.setDepartmentOption(departmentList)

    const timeZoneRes = await getTimeZone()
    const timeZoneList = timeZoneRes.data.map((item: any) => ({
      label: item.timeZonesName,
      value: item.id,
    }))

    param.setTimeZone(timeZoneList)
    if (param.type === 'Add') {
      const storedTimeZoneId = localStorage.getItem('timezone')
      const selectedTimeZone = timeZoneList.find((tz: any) => tz.value === storedTimeZoneId)
      if (selectedTimeZone) {
        param.setSelectedTimeZone(selectedTimeZone)
      }
    }
  } catch (error) {
    console.error('Error loading default data:', error)
  }
}

const getAllUser = async (setManagerOption: any) => {
  try {
    const org = localStorage.getItem('org_Id')
    const userList = await GetActiveInactiveUser(org)

    setManagerOption(userList)
  } catch (error) {
    ErrorModal()
  }
}

export const addEditMemberFrom = (param: any) => {
  return (
    <form onSubmit={param.formik.handleSubmit} className='form'>
      <div className='card-body border-top p-9'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
            <span>Name</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-border'
              placeholder='Name'
              {...param.formik.getFieldProps('fullName')}
            />
            {param.formik.touched.fullName && param.formik.errors.fullName && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{param.formik.errors.fullName}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Email</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              disabled={param.type === 'Edit' ? true : false}
              type='email'
              className='form-control form-control-border'
              placeholder='Email'
              {...param.formik.getFieldProps('email')}
            />
            {param.formik.touched.email && param.formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{param.formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 '>
            <span>Employee Id</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-border'
              placeholder='Employee Id'
              {...param.formik.getFieldProps('empId')}
            />
            {param.formik.touched.empId && param.formik.errors.empId && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{param.formik.errors.empId}</div>
              </div>
            )}
          </div>
        </div>

        {param.type === 'Add' && param.loadorg && (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Organization</label>
            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg'
                {...param.formik.getFieldProps('organizationId')}
              >
                <option value='' hidden>
                  Select the Organization
                </option>
                {param.companies.map((lead: any) => (
                  <option key={lead.org_Id} value={lead.org_Id}>
                    {lead.organizationName}
                  </option>
                ))}
              </select>
              {param.formik.touched.organizationId && param.formik.errors.organizationId && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{param.formik.errors.organizationId}</div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Department</label>
          <div className='col-lg-8 fv-row'>
            <div
              className='min-w-200px'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Search Department'
            >
              <Select
                className='selectDropdown'
                components={makeAnimated()}
                options={param.departmentOption}
                value={param.department}
                onBlur={param.formik.handleBlur}
                placeholder='Select Department'
                onChange={(item: any) => {
                  param.setDepartment(item)
                  param.formik.setFieldValue('departmentId', item !== null ? item.value : '')
                }}
                isClearable={true}
                isSearchable={true}
                closeMenuOnScroll={true}
              />
            </div>
            <div>
              {param.formik.touched.departmentId && param.formik.errors.departmentId && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{param.formik.errors.departmentId}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        {param.loadmanager && (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Manager</label>
            <div className='col-lg-8 fv-row'>
              <div
                className='min-w-200px'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Search manager'
              >
                <Select
                  className='selectDropdown'
                  components={makeAnimated()}
                  options={param.managerOption}
                  value={param.manager}
                  placeholder='Select manager'
                  onChange={(item: any) => {
                    param.setManager(item)
                    param.formik.setFieldValue('managerId', item !== null ? item.value : '')
                  }}
                  isClearable={true}
                  isSearchable={true}
                  closeMenuOnScroll={true}
                />
              </div>
            </div>
            {param.formik.touched.managerId && param.formik.errors.managerId && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{param.formik.errors.managerId}</div>
              </div>
            )}
          </div>
        )}

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>

          <div className='col-lg-8 fv-row'>
            <div
              className='min-w-200px'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Search Role'
            >
              <Select
                className='selectDropdown'
                components={makeAnimated()}
                options={param.rolesOption}
                value={param.roles}
                onBlur={param.formik.handleBlur}
                placeholder='Select Role'
                onChange={(item: any) => {
                  param.setRoles(item)
                  param.formik.setFieldValue('roleId', item.value)
                }}
                isClearable={false}
                isSearchable={true}
                closeMenuOnScroll={true}
              />
            </div>
            <div>
              {param.formik.touched.roleId && param.formik.errors.roleId && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{param.formik.errors.roleId}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className=''>Base Location City</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-border'
              placeholder='Base Location'
              {...param.formik.getFieldProps('baseLocation')}
            />
            {param.formik.touched.baseLocation && param.formik.errors.baseLocation && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{param.formik.errors.baseLocation}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Time Zone</label>

          <div className='col-lg-8 fv-row'>
            <div
              className='min-w-200px'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Search timezone'
            >
              <Select
                className='selectDropdown'
                components={makeAnimated()}
                options={param.timeZone}
                value={param.selectedTimeZone}
                onBlur={param.formik.handleBlur}
                placeholder='Select Time Zone'
                onChange={(item: any) => {
                  param.formik.setFieldValue('timeZoneId', item.value)
                  param.setSelectedTimeZone(item)
                }}
                isClearable={false}
                isSearchable={true}
                closeMenuOnScroll={true}
              />
            </div>
            <div>
              {param.formik.touched.timeZoneId && param.formik.errors.timeZoneId && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{param.formik.errors.timeZoneId}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Contact No</label>

          <div className='col-lg-8 fv-row'>
            <input
              type='tel'
              className='form-control form-control-border'
              placeholder='Contact No'
              {...param.formik.getFieldProps('contactNo')}
            />
            {param.formik.touched.contactNo && param.formik.errors.contactNo && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{param.formik.errors.contactNo}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Address</label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-border'
              placeholder='Address'
              {...param.formik.getFieldProps('address')}
            />
            {param.formik.touched.address && param.formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{param.formik.errors.address}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Cut Off Time (hh:mm:ss)</label>
          <div className='col-lg-8 fv-row d-flex align-items-center'>
            <div style={{marginRight: '1rem'}}>
              <ToggleButtonGroup
                color='primary'
                value={param.alignment}
                onChange={param.handleToggle}
                aria-label='Platform'
              >
                <ToggleButton value='Default'>Default</ToggleButton>
                <ToggleButton value='Custom'>Custom</ToggleButton>
              </ToggleButtonGroup>
            </div>

            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  disabled={param.disableTimePicker}
                  value={param.timePickerDefaultValue}
                  sx={{
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0)',
                    },
                  }}
                  timeSteps={{hours: 1, minutes: 1, seconds: 1}}
                  format='HH:mm:ss'
                  ampm={false}
                  views={['hours', 'minutes', 'seconds']}
                  onChange={(value: any) => {
                    param.formik.setFieldValue(
                      'userCutOffTime',
                      dayjs(value).format('HH:mm:ss').replaceAll(':', '.')
                    )
                    param.setTimePickerDefaultValue(value)
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        {param.loadtoggle && (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Capture Screenshot</label>
            <div className='col-lg-8 fv-row'>
              <Switch
                onChange={() => {
                  param.checked ? param.setChecked(false) : param.setChecked(true)
                }}
                checked={param.checked}
              />
            </div>
          </div>
        )}

        {param.ConfigDetails.isEnableLiveStreaming && (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Live Stream</label>
            <div className='col-lg-8 fv-row'>
              <Switch
                onChange={() => {
                  param.liveStream ? param.setLiveStream(false) : param.setLiveStream(true)
                }}
                checked={param.liveStream}
              />
            </div>
          </div>
        )}

        {param.ConfigDetails.isEnableLiveRecording && (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Live Recording</label>
            <div className='col-lg-8 fv-row'>
              <Switch
                onChange={() => {
                  param.liveRecording ? param.setLiveRecording(false) : param.setLiveRecording(true)
                }}
                checked={param.liveRecording}
              />
            </div>
          </div>
        )}

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Notification</label>
          <div className='col-lg-8 fv-row'>
            <Switch
              onChange={() => {
                param.notification ? param.setNotification(false) : param.setNotification(true)
              }}
              checked={param.notification}
            />
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Stop Timer Enable</label>
          <div className='col-lg-8 fv-row'>
            <Switch
              onChange={() => {
                param.stopTimer ? param.setStopTimer(false) : param.setStopTimer(true)
              }}
              checked={param.stopTimer}
            />
          </div>
        </div>

        {param.ConfigDetails.isEnableManualTracking && (
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Private Mode</label>
            <div className='col-lg-8 fv-row'>
              <Switch
                onChange={() => {
                  param.manualtracking
                    ? param.setManualTracking(false)
                    : param.setManualTracking(true)
                }}
                checked={param.manualtracking}
              />
            </div>
          </div>
        )}
      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button className='btn btn-secondary' onClick={() => param.navigate(param.status())}>
          Cancel
        </button>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <button type='submit' className='btn btn-primary' disabled={param.loading}>
          {!param.loading && <span className='indicator-label'>{param.buttonLabel}</span>}
          {param.loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export const calculatePercentage = (time: any, totalTime: any) => {
  return ((time / totalTime) * 100).toFixed(2)
}

export const calculateClickedPercentage = (time: any, clickedTotal: any) => {
  return ((time / clickedTotal) * 100).toFixed(2)
}

export const websiteApplicationTable = (params: any) => {
  return (
    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
      <thead>
        <tr className='fw-bolder text-bold web-vistied'>
          <th className='' style={{width: '60%'}}>
            {params.type === 'Website' ? 'Website' : 'Application'}
          </th>
          <th className='min-w-100px'>Duration</th>
          <th className='min-w-100px text-center'>Percentage</th>
        </tr>
      </thead>
      <tbody>
        {!params.spinner &&
          params.data.map((website: any, index: any) => (
            <tr
              key={index}
              onClick={() => {
                if (params.type === 'Website') {
                  params.handleWebsiteClick(website.titles, website.url)
                } else {
                  params.handleApplicationClick(website.titles, website.appName)
                }

                params.handleRowClick(index)
              }}
              style={{
                cursor: 'pointer',
                fontSize: '1.1rem',
                backgroundColor: index === params.selectedRow ? 'rgb(255, 239, 213)' : '',
              }}
            >
              <td className='' style={{wordWrap: 'break-word', lineBreak: 'anywhere'}}>
                {params.type === 'Website' ? website.url : params.truncate(website.appName)}
              </td>
              <td className=''>{params.convertSecIntoHoursMinSec(website.totalTime)}</td>
              <td className=' text-center'>
                {params.type === 'Website'
                  ? calculatePercentage(website.totalTime, params.totalTime)
                  : calculatePercentage(Number(website.totalTime), params.totalTime)}
                %
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export const websiteApplicationFooterTotalTime = (totalTime: any) => {
  return (
    <div className='d-flex px-3'>
      <span className=' fw-bolder text-bold fs-4' style={{width: '60%'}}>
        Total Time Spent
      </span>
      <span className='min-w-100px fw-bolder text-bold fs-4'>
        {convertSecIntoHoursMinSec(totalTime)}
      </span>
    </div>
  )
}

export const websiteApplicationExpandedTable = (
  label: any,
  clickedTotal: any,
  spinner: any,
  data: any
) => {
  return (
    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
      <thead>
        <tr className='fw-bolder text-bold web-vistied '>
          <th className='' style={{width: '60%'}}>
            {label}
          </th>
          <th className='min-w-100px'>Duration</th>
          <th className='min-w-100px text-center'>Percentage</th>
        </tr>
      </thead>
      <tbody>
        {!spinner &&
          data &&
          data.map((website: any, index: any) => (
            <tr key={index}>
              <td className=''>{website.title}</td>
              <td className=''>
                {convertSecIntoHoursMinSec(website.totalTime)}
                {/* {dayjs()
                            .startOf('day')
                            .second(Number(website.totalTime))
                            .format('HH:mm:ss')} */}
              </td>
              <td className=' text-center'>
                {calculateClickedPercentage(website.totalTime, clickedTotal)}%
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export const baseGroupAdminSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(10, 'Minimum 10 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),

  organizationIds: Yup.array().min(1, 'Company cannot be empty'), // Adjust the message as needed
})

export const fillCompanyDropDown = async (setCompaniesOptions: any) => {
  try {
    const data = await GetOrgList()

    setCompaniesOptions(data)
  } catch (error) {
    ErrorModal()
  }
}

export const addEditGroupAdminFrom = (params: any) => {
  return (
    <form onSubmit={params.formik.handleSubmit} className='form'>
      <div className='card-body border-top p-9'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Full Name</span>
          </label>

          {params.type === 'Add' ? (
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-border '
                placeholder='Name'
                {...params.formik.getFieldProps('fullName')}
              />
              {params.formik.touched.fullName && params.formik.errors.fullName && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{params.formik.errors.fullName}</div>
                </div>
              )}
            </div>
          ) : (
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-border '
                placeholder='Name'
                {...params.formik.getFieldProps('groupAdminName')}
              />
              {params.formik.touched.groupAdminName && params.formik.errors.groupAdminName && (
                <div className='fv-plugins-message-container'>
                  <div className='text-danger mt-2'>{params.formik.errors.groupAdminName}</div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Email</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='email'
              className='form-control form-control-border '
              placeholder='Email'
              disabled={params.type === 'Edit' && true}
              {...params.formik.getFieldProps('email')}
            />
            {params.formik.touched.email && params.formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6 '>
          <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Organization</label>
          <div className='col-lg-8 fv-row '>
            <div
              className='min-w-200px '
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Search Role'
            >
              <Select
                className='selectDropdown '
                components={makeAnimated()}
                value={params.selectedOptions}
                options={params.companiesOptions}
                onChange={(selectedItems: any) => {
                  params.setSelectedOptions(selectedItems)
                  const companyValues = selectedItems.map((item: any) => item.value)
                  params.formik.setFieldValue('organizationIds', companyValues)
                }}
                placeholder='Select organization'
                isClearable={false}
                isSearchable={false}
                isMulti
              />
            </div>
            {params.formik.touched.organizationIds && params.formik.errors.organizationIds && (
              <div className='fv-plugins-message-container'>
                <div className='text-danger mt-2'>{params.formik.errors.organizationIds}</div>
              </div>
            )}
          </div>
        </div>
        <div className='row mb-6 '>
          <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Role</label>
          <div className='col-lg-8 fv-row '>
            <div
              className='min-w-200px '
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Search Role'
            >
              <Select
                className='selectDropdown '
                components={makeAnimated()}
                defaultValue={{label: 'Group Admin', value: 'Group Admin'}}
                placeholder='Select Role'
                isClearable={false}
                isSearchable={false}
                closeMenuOnScroll={true}
                isDisabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button className='btn btn-secondary' onClick={() => params.navigate('/allGroupAdmin')}>
          Cancel
        </button>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <button type='submit' className='btn btn-primary' disabled={params.loading}>
          {!params.loading && <span className='indicator-label'>Save changes</span>}
          {params.loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export const submitAddEditGroupAdmin = (
  setLoading: any,
  values: any,
  navigate: any,
  resetForm: any,
  setSubmitting: any,
  url: any
) => {
  setLoading(true)
  axios
    .post(`${API_URL}/${url}`, values)
    .then((res) => {
      AlertModal(
        res.data.message,
        '',
        res.data.success ? 'success' : 'warning',
        false,
        '#7066E0',
        'Ok'
      ).then(() => {
        navigate('/allGroupAdmin')
        resetForm()
        setLoading(false)
      })
    })
    .catch((err) => {
      console.log(err)
      setLoading(false)
      setSubmitting(false)
    })

  resetForm()
  setLoading(false)
}

export const convertSecIntoHoursMin = (totalSeconds: any) => {
  const duration = moment.duration(totalSeconds, 'seconds')
  const hours = Math.floor(duration.asHours()).toString().padStart(2, '0')
  const minutes = duration.minutes().toString().padStart(2, '0')

  const formattedTime = hours + ':' + minutes

  return formattedTime === '00:00' ? '-' : formattedTime
}

export const loadAssigeeAndMember = (
  role: any,
  setMemberOption: any,
  setOptionSelectedMember?: any,
  ConfigDetails?: any
) => {
  if (
    role === process.env.REACT_APP_SYSTEM_SECOND_ROLE ||
    role === process.env.REACT_APP_SYSTEM_THIRD_ROLE
  ) {
    GetAssignee()
      .then((res: any) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.assigneeName,
            value: item.assigneeId,
          }
        })

        setMemberOption(newList)
      })
      .catch((err) => {
        console.log(err)
      })
  } else if (
    role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
    role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
  ) {
    memberInfoByOrg()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.fullName,
            value: item.userId,
          }
        })
        setMemberOption(newList)
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const earlyStageCall = (
  cancelTokenSource: any,
  setPosts: any,
  setScreenLoader: any,
  setItemOffset?: any
) => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('New request initiated')
  }
  setPosts([])
  setScreenLoader(true)
  if (setItemOffset) setItemOffset(0)
}

export const SendDataOfUser = (params: any) => {
  if (params.setItemOffset) {
    earlyStageCall(
      params.cancelTokenSource,
      params.setPosts,
      params.setScreenLoader,
      params.setItemOffset
    )
  } else {
    earlyStageCall(
      params.cancelTokenSource,
      params.setPosts,
      params.setScreenLoader,
      params.setItemOffset
    )
  }

  params.fetchPosts(dayjs(params.startDate).format('YYYY-MM-DDT00:00:00[Z]'), params.userId)
}

export const downloadAttendanceReportData = async (
  body: any,
  alignment?: any,
  optionSelectedMember?: any,
  startDate?: any,
  endDate?: any
) => {
  const reportData = await DownloadAttendanceReport(body)

  const data = reportData.data

  if (data.length === 0) {
    return
  }

  // Get unique dates and convert to 'DD/MM/YYYY' format
  const dates = [
    ...new Set(
      data.flatMap((user: any) =>
        user.activeTimeWithDate.map((record: any) => dayjs(record.date).format('DD/MM/YYYY'))
      )
    ),
  ]

  // Create Excel header
  const headers = ['Name', ...dates]

  // Create rows for each user
  const rows = data.map((user: any) => {
    const row = [user.userName]
    const dateMap = new Map(
      user.activeTimeWithDate.map((record: any) => [
        dayjs(record.date).format('DD/MM/YYYY'),
        record.activeTime,
      ])
    )
    dates.forEach((date) => {
      const activeTime = dateMap.get(date) || 0
      row.push(convertSecIntoHoursMinSec(activeTime))
    })
    return row
  })

  // Combine headers and rows
  const worksheetData = [headers, ...rows]

  // Create a new workbook
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

  // Set column widths
  const colWidths = [{wch: 20}, ...dates.map(() => ({wch: 15}))]
  worksheet['!cols'] = colWidths

  // Apply style to header row
  const headerStyle = {
    font: {bold: true, color: {rgb: 'FFFFFF'}}, // Set font color to white
    fill: {fgColor: {rgb: '0000FF'}}, // Set background color to blue
  }

  headers.forEach((_, colIndex) => {
    const cellAddress = XLSX.utils.encode_cell({r: 0, c: colIndex}) // Header cells are in the first row
    if (!worksheet[cellAddress]) {
      worksheet[cellAddress] = {t: 's', v: headers[colIndex]}
    }
    worksheet[cellAddress].s = headerStyle
  })

  // Append worksheet to workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Report')

  // Generate filename
  let filename
  if (alignment === 'Individual') {
    filename = `${optionSelectedMember.label}.xlsx`
  } else {
    const formattedStartDate = dayjs(startDate).format('DD_MM_YYYY')
    const formattedEndDate = endDate ? dayjs(endDate).format('DD_MM_YYYY') : formattedStartDate
    filename = `${formattedStartDate}-${formattedEndDate}.xlsx`
  }

  // Generate Excel file and trigger download
  const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})
  const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('hidden', '')
  a.setAttribute('href', url)
  a.setAttribute('download', filename)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

const driverObj = driver({
  showProgress: true,
  animate: true,
  allowClose: true,
  disableActiveInteraction: true,
  showButtons: ['next', 'previous', 'close'],
  steps: [
    {
      element: '.memberinfo',
      popover: {
        title: 'Member',
        description: 'Here you can add members.',
        side: 'left',
        align: 'start',
      },
    },
    {
      element: '.tourAddMember',
      popover: {
        title: 'Add Member',
        description: 'Click here to add a new member.',
        side: 'bottom',
        align: 'start',
      },
    },
    {
      element: '.tourDownloadSamle',
      popover: {
        title: 'Download sample file',
        description: 'You can Download the sample file from here to add multiple members at once',
        side: 'bottom',
        align: 'start',
      },
    },
    {
      element: '.tourUpload',
      popover: {
        title: 'Add bulk member',
        description: 'You can upload Excel file here to add multiple members at a time.',
        side: 'bottom',
        align: 'start',
      },
    },
  ],
})

export const guidedTour = () => {
  setTimeout(() => {
    const memberElement = document.querySelector('.member') as HTMLButtonElement | HTMLAnchorElement
    const memberInfoElement = document.querySelector('.memberinfo') as
      | HTMLButtonElement
      | HTMLAnchorElement

    //console.log('the member element is ', memberElement)

    // Check if memberElement has already been clicked
    if (memberElement && !memberElement.classList.contains('clicked')) {
      memberElement.click()
      memberElement.classList.add('clicked') // Add a class to indicate it's been clicked

      const menuTitleElement = memberInfoElement.querySelector('.menu-title') as HTMLElement
      const menuIconElement = memberInfoElement.querySelector('.menu-icon') as HTMLElement

      //console.log('The menuTitleElement is ', menuTitleElement)

      if (menuTitleElement) {
        menuTitleElement.style.setProperty('color', 'white', 'important')
        menuIconElement.style.setProperty('color', 'white', 'important')
      }
    }
    driverObj.drive()
  }, 1000)
}

export const planDetails = async (SelectedUser: any, dispatch: any) => {
  if (SelectedUser.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE) {
    const getPlanDetials = await GetOrganizationExpiryDetailByOrgId(SelectedUser.orgId)
    dispatch(userInfoActions.setPlanDetails(getPlanDetials.data))
  }
}

export const checkPlanStatus = (ConfigDetails: any, dispatch: any, auth: any) => {
  if (ConfigDetails?.role === 'Admin' && ConfigDetails.orgId !== '6656c5bd282c1dfc1ab6d44f') {
    const currentDate = dayjs()
    const planDate = dayjs(ConfigDetails.planExpiry)
    const daysDifference = planDate.diff(currentDate, 'day')

    if (daysDifference == 0) {
      return {
        alert: true,
        message: `Your plan is expiring today!`,
      }
    } else if (daysDifference < 0) {
      dispatch(auth.actions.logout())
      return {
        alert: true,
        message: `Your plan has expired!`,
      }
    } else if (daysDifference <= 7) {
      return {
        alert: true,
        message: `Your plan is going to expire in ${daysDifference} day(s)!`,
      }
    }
  }
}

export const handleLogout = (dispatch: any) => {
  localStorage.clear()
  dispatch(userInfoActions.resetUserInfo())
  dispatch(userInfoActions.setGroupAdminStatus(true))
  dispatch(auth.actions.logout())
}

export const CurrencyList = () => {
  return axios.get(`${API_URL}/CurrencyCodes/GetAllActiveCurrencyCodeList`)
}
