/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {userInfoActions} from '../../modules/auth'
import {Link} from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert2'
import {ActivatePartner} from '../../services/partner.service'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import {
  Pagination,
  AlertModal,
  ErrorModal,
  Loader,
  createTooltipInput,
} from '../../CommonFunctions/CommonFunction'
import {GetAllGroupAdmin} from '../../services/GroupAdmin.services'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'

const API_URL = process.env.REACT_APP_API_URL

const DisplayGroupAdmin: React.FC = () => {
  const [currentPagePartner, setCurrentPagePartner] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const dispatch = useDispatch()
  const GroupAdmin: any = useSelector<RootState>(({data}) => data.groupAdmin, shallowEqual)
  const tooltipContent = 'Search group admin by admin name'
  const inputPlaceholder = 'Search by Name'

  const deleteHandler = (adminId: any) => {
    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Yes, delete it!'
    ).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .post(`${API_URL}/Registration/DeleteGroupAdmin?groupAdminId=${adminId}`)
          .then((res) => {
            getAllGroupAdmin()
            AlertModal(
              res.data.message,
              '',
              res.data.success ? 'success' : 'warning',
              false,
              '#7066E0',
              'Ok'
            )
          })
          .catch((err) => {
            console.log(err.data.message)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Partner not Deleted', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  let filteredMembers: any = []

  useEffect(() => {
    handleDataChange()
  }, [GroupAdmin, itemOffset, searchQuery])

  const handleDataChange = () => {
    setSpinner(true)
    filteredMembers =
      GroupAdmin.length > 0 &&
      GroupAdmin !== undefined &&
      GroupAdmin.filter((adminData: any) =>
        adminData.groupAdminName.toLowerCase().includes(searchQuery.toLowerCase())
      )
    let currentPagePartnerData =
      filteredMembers.length > 0 &&
      filteredMembers !== undefined &&
      filteredMembers.slice(itemOffset, endOffset)

    setCurrentPagePartner(currentPagePartnerData)
    let Count = Math.ceil(GroupAdmin.length / itemsPerPage)
    setPageCount(Count)
    setSpinner(false)
  }

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % GroupAdmin.length
    setItemOffset(newOffset)
  }

  const handleSearchChange = (name: string) => {
    setSearchQuery(name)
    setItemOffset(0)
    handleDataChange()
  }

  useEffect(() => {
    getAllGroupAdmin()
  }, [])

  const getAllGroupAdmin = async () => {
    try {
      const groupAdmin = await GetAllGroupAdmin()
      dispatch(userInfoActions.setGroupAdmin(groupAdmin.data))
    } catch (error) {
      ErrorModal()
    }
  }

  const ResendActivationMail = async (adminId: string) => {
    try {
      const activatePartner = await ActivatePartner(adminId)
      AlertModal(
        activatePartner.data.message,
        '',
        activatePartner.data.success ? 'success' : 'warning',
        false,
        '#7066E0',
        'Ok'
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder mb-1' style={{fontSize: '19px'}}>
            Group Admin
          </span>
        </h3>

        <div style={{display: 'flex'}}>
          {spinner ? '' : createTooltipInput(tooltipContent, inputPlaceholder, handleSearchChange)}

          <Tippy placement='top' content='Click to add a group admin'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              <Link to='/allGroupAdmin/addGroupAdmin' className='btn btn-sm btn-primary me-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Admin
              </Link>
            </div>
          </Tippy>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      {spinner ? (
        Loader('0')
      ) : (
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                  </th>
                  <th className='min-w-125px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Name
                  </th>
                  <th className='min-w-125px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Email
                  </th>
                  <th className='min-w-175px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Organization
                  </th>
                  <th className='min-w-175px text-center'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentPagePartner.length > 0 &&
                  currentPagePartner.map((names: any) => (
                    <tr key={names.groupAdminId}>
                      <td></td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div
                            className='d-flex justify-content-start flex-column '
                            style={{fontSize: '13px'}}
                          >
                            {/* <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'> */}
                            {names.groupAdminName}

                            {/* </a> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div
                            className='d-flex justify-content-start flex-column '
                            style={{fontSize: '13px'}}
                          >
                            {/* <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'> */}
                            {names.groupAdminEmail}

                            {/* </a> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div
                            className='d-flex justify-content-start flex-column'
                            style={{fontSize: '13px'}}
                          >
                            {names.organizations.length > 0 &&
                              names.organizations.map((org: any) => (
                                <div>{org.organizationName}</div>
                              ))}
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex justify-content-evenly flex-shrink-0'>
                          <Tippy placement='top' content='Resend activation mail.'>
                            <a
                              onClick={() => ResendActivationMail(names.groupAdminId)}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                                names.isActive === true ? 'disabled' : ''
                              }`}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/resendemail.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </Tippy>
                          <Tippy placement='top' content='Edit group admin'>
                            <Link
                              to={`/allGroupAdmin/editGroupAdmin/?user=${names.groupAdminId}`}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </Link>
                          </Tippy>

                          <Tippy placement='top' content='Delete group admin'>
                            <a
                              onClick={() => deleteHandler(names.groupAdminId)}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </Tippy>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
            <div className='d-flex flex-column'>
              {GroupAdmin.length > 10 && (
                <div className='d-flex align-items-center justify-content-center'>
                  {Pagination(handlePageClick, pageCount, 0)}
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DisplayGroupAdmin
