import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {useFormik} from 'formik'
import {
  AlertModal,
  GetOrgList,
  Loader,
  SingleDatePickerComponent,
} from '../../CommonFunctions/CommonFunction'
import dayjs from 'dayjs'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import * as Yup from 'yup'

import {useSearchParams} from 'react-router-dom'
import {GetPaymentSummary, CompletePlansData} from '../../services/Payment.services'

const API_URL = process.env.REACT_APP_API_URL

const AddPayment: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<any>(new Date())
  const [companies, setCompanies] = useState<any>([])
  const [timePeriod, setTimePeriod] = useState<any>(0)
  const [companiesOptions, setCompaniesOptions] = useState<any[]>([])
  const [planList, setPlanList] = useState<any[]>([])
  const [billingPlan, setBillingPlan] = useState<any>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [planCurrency, setPlanCurrency] = useState('')
  const [mainData, setMainData] = useState<any>({})
  const [numOfDays, setNumOfDays] = useState<any>('')
  const receiptRef: any = useRef()
  const invoiceRef: any = useRef()

  useEffect(() => {
    const organization = searchParams.get('orgId')
    const users = searchParams.get('users')
    formik.setFieldValue('ExtraUserCount', Number(users))

    formik.setFieldValue('OrganizationId', organization)
    if (organization !== '') {
      completePlansData(organization)
    }
  }, [])

  useEffect(() => {
    FillCompanyDropdown()
  }, [])

  useEffect(() => {
    const planid = searchParams.get('planId')
    if (Object.keys(mainData).length > 0 && planid != 'null') {
      getNoOfDays(planid)
    }
  }, [mainData])

  const FillCompanyDropdown = async () => {
    try {
      const newList = await GetOrgList()
      const orgId = searchParams.get('orgId')
      if (orgId !== null && orgId !== '') {
        const selectedCompany = newList.find((item: any) => item.value === orgId)
        setCompanies(selectedCompany)
        // callOrgPlan()
      }

      setCompaniesOptions(newList)
    } catch (error) {
      console.log('Something went wrong')
    }
  }

  const initialValues = {
    OrganizationId: '',
    BillingPlanId: '',
    EffectiveFrom: dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
    EffectiveTo: dayjs().format('DD/MM/YYYY'),
    Amount: 0,
    ActiveUserCount: 0,
    NetAmount: '',
    NoOfUsers: '',
    ExtraUserCount: 0,
    Discount: 0,
    DiscountAmount: 0,
    TaxAmount: 0,
    Tax: 0,
    ReceiptFile: '',
    InvoiceFile: '',
  }

  const planDetailsSchema = Yup.object().shape({
    OrganizationId: Yup.string().nullable().required('Organization is required'),
    BillingPlanId: Yup.string().required('Plan is required'),
    Amount: Yup.number().required('Amount is required').min(1, 'Please Enter Number more than 0'),
    NoOfUsers: Yup.number()
      .required('No of Users is required')
      .min(1, 'Please Enter Number more than 0'),
    ExtraUserCount: Yup.number()
      .min(0, 'Please Enter Number 0 or greater')
      .integer('Please enter an integer value'),
    ReceiptFile: Yup.mixed().required('Upload Receipt'),
    InvoiceFile: Yup.mixed().required('Upload Invoice'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: planDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoader(true)

      axios
        .post(`${API_URL}/Payment/AddPayment`, values, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            if (res.data.success) {
              setLoader(false)
              resetForm()
              setCompanies([])
              setBillingPlan([])
              receiptRef.current.value = ''
              invoiceRef.current.value = ''
              setStartDate(new Date())
            }
          })
        })
        .catch((err) => {
          console.log(err)
          setLoader(false)
        })
    },
  })

  const handleDateChange = (dates: any, days?: any) => {
    formik.setFieldValue('EffectiveFrom', dayjs(dates).format('YYYY-MM-DDT00:00:00[Z]'))
    setStartDate(dates)
    calculateEffectiveToDate(dates, days ? days : numOfDays)
  }

  const calculateEffectiveToDate = (dates: any, Days: any) => {
    const updatedDte = dayjs(dates).add(Days, 'day').format()
    formik.setFieldValue('EffectiveTo', dayjs(updatedDte).format('DD/MM/YYYY'))
  }

  const completePlansData = async (org: any) => {
    try {
      setLoader(true)

      const PlansDetails = await CompletePlansData(org)
      fillPlansList(PlansDetails.data.billingPlans)
      setMainData(PlansDetails.data)

      if (PlansDetails.data.defaultPlanId !== '') {
        const selectedPlan: any = PlansDetails.data.billingPlans.filter((data: any) => {
          return data.billingPlanId === PlansDetails.data.defaultPlanId
        })

        setBillingPlan([
          {label: selectedPlan[0].billingPlanName, value: selectedPlan[0].billingPlanId},
        ])
        formik.setFieldValue('BillingPlanId', selectedPlan[0].billingPlanId)
        setNumOfDays(selectedPlan[0].noOfDays)

        handleDateChange(new Date(selectedPlan[0].effectiveFromDate), selectedPlan[0].noOfDays)
        const users = searchParams.get('users')

        const paymentSummary = await getPaymentSummary(org, PlansDetails.data.defaultPlanId, users)
      } else {
        setPlanCurrency('')
        formik.setFieldValue('Amount', 0)
        setStartDate(new Date())
        formik.setFieldValue('EffectiveTo', dayjs().add(1, 'day').format('DD/MM/YYYY'))
      }

      formik.setFieldValue('ActiveUserCount', PlansDetails.data.noOfEmployees)
      //setNumOfDays()
      setLoader(false)
    } catch (error) {
      console.log('The error is ', error)
    }
  }

  const fillPlansList = (allPlans: any) => {
    const newList = allPlans.map((item: any) => {
      return {
        label: item.billingPlanName,
        value: item.billingPlanId,
      }
    })

    setPlanList(newList)
  }

  const getPaymentSummary = async (orgId: any, planId: any, extraUsers: any) => {
    try {
      const summary = await GetPaymentSummary(planId, orgId, extraUsers)
      formik.setFieldValue('ActiveUserCount', summary.data.activeUserCount)
      const totalUsers = Number(summary.data.activeUserCount) + Number(extraUsers)
      formik.setFieldValue('NoOfUsers', String(totalUsers))
      formik.setFieldValue('Discount', summary.data.discountPercentage)
      formik.setFieldValue('Tax', summary.data.taxPercentage)
      formik.setFieldValue('Amount', summary.data.totalAmount)
      formik.setFieldValue('NetAmount', summary.data.amount)
      formik.setFieldValue('DiscountAmount', summary.data.discountAmount)
      formik.setFieldValue('TaxAmount', summary.data.taxAmount)
      setPlanCurrency(summary.data.currency || '')
    } catch (error) {
      console.log('the error is ', error)
    }
  }

  const getNoOfDays = (planId?: any) => {
    const details = mainData.billingPlans.filter((data: any) => {
      return data.billingPlanId === planId
    })

    if (details.length > 0) {
      formik.setFieldValue(
        'EffectiveFrom',
        dayjs(details[0].effectiveFromDate).format('YYYY-MM-DDT00:00:00[Z]')
      )
      setStartDate(new Date(details[0].effectiveFromDate))
      calculateEffectiveToDate(details[0].effectiveFromDate, details[0].noOfDays)
      setNumOfDays(details[0].noOfDays)
    }
  }

  const handleExtraUsersUpdateHandler = async (updatedUser: any) => {
    if (Number(updatedUser) >= 0) {
      getPaymentSummary(companies.value, billingPlan[0].value, updatedUser)
    } else if (updatedUser === '') {
      getPaymentSummary(companies.value, billingPlan[0].value, 0)
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Payment Details</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
        </div>
        {loader ? (
          Loader('0')
        ) : (
          <>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Organization Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    //style={{ width: '200px' }}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Organization'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={companiesOptions}
                      value={companies}
                      onBlur={formik.handleBlur}
                      placeholder='Select Organization'
                      onChange={(item: any) => {
                        setCompanies(item)
                        setBillingPlan([])
                        formik.setFieldValue('BillingPlanId', '')
                        completePlansData(item.value)
                        formik.setFieldValue('OrganizationId', item !== null ? item.value : '')
                        formik.setFieldValue('ExtraUserCount', 0)
                        //SendDataOfUser(item.value)
                      }}
                      isClearable={true}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  {formik.touched.OrganizationId && formik.errors.OrganizationId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.OrganizationId}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Plan Name</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Plan'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={planList}
                      value={billingPlan}
                      onBlur={formik.handleBlur}
                      placeholder='Select Plan'
                      onChange={(item: any) => {
                        setBillingPlan([item])
                        formik.setFieldValue('BillingPlanId', item !== null ? item.value : '')
                        getNoOfDays(item.value)
                        getPaymentSummary(
                          formik.values.OrganizationId,
                          item.value,
                          formik.values.ExtraUserCount
                        )
                      }}
                      isClearable={true}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  {formik.touched.BillingPlanId && formik.errors.BillingPlanId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.BillingPlanId}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Employee Count</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='number'
                    min={0}
                    className='form-control form-control-border '
                    placeholder='Employee Count'
                    {...formik.getFieldProps('ActiveUserCount')}
                    disabled
                  />
                  {formik.touched.ActiveUserCount && formik.errors.ActiveUserCount && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.ActiveUserCount}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Extra Users</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='number'
                    value={formik.values.ExtraUserCount}
                    min={0}
                    onChange={(e) => {
                      const integerValue =
                        e.target.value !== '' ? Math.round(parseFloat(e.target.value)) : 0
                      const sanitizedValue =
                        integerValue < 10
                          ? String(integerValue)
                          : String(integerValue).replace(/^0+/, '')
                      handleExtraUsersUpdateHandler(sanitizedValue)
                      formik.setFieldValue('ExtraUserCount', Number(sanitizedValue))
                    }}
                    className='form-control form-control-border'
                    placeholder='Extra Employee Count'
                  />

                  {formik.touched.ExtraUserCount && formik.errors.ExtraUserCount && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.ExtraUserCount}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Currency</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    value={`${planCurrency}`}
                    className='form-control form-control-border'
                    disabled
                    placeholder='Currency'
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Amount</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='number'
                    min={0}
                    className='form-control form-control-border '
                    placeholder='Amount'
                    {...formik.getFieldProps('Amount')}
                    disabled
                  />
                  {formik.touched.Amount && formik.errors.Amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.Amount}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Effective from</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  {SingleDatePickerComponent(
                    startDate,
                    handleDateChange,
                    'form-control',
                    true,
                    new Date()
                  )}

                  {formik.touched.EffectiveFrom && formik.errors.EffectiveFrom && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.EffectiveFrom}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Effective to</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    min={0}
                    disabled
                    className='form-control form-control-border '
                    placeholder='Effective To'
                    {...formik.getFieldProps('EffectiveTo')}
                  />

                  {formik.touched.EffectiveTo && formik.errors.EffectiveTo && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.EffectiveTo}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Upload Receipt</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    id='ReceiptFile'
                    accept='.pdf,.doc,.png,.jpeg,.jpg'
                    className='form-control form-control-border '
                    // {...formik.getFieldProps('ReceiptFile')}
                    onChange={(event: any) => {
                      event?.currentTarget.files.length > 0
                      ? formik.setFieldValue('ReceiptFile', event.currentTarget.files[0])
                      : formik.setFieldValue('ReceiptFile', '')
                    }}
                    onBlur={formik.handleBlur}
                    ref={receiptRef}
                  />
                  {formik.touched.ReceiptFile && formik.errors.ReceiptFile && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.ReceiptFile}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Upload Invoice</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    id='InvoiceFile'
                    className='form-control form-control-border '
                    accept='.pdf,.doc,.png,.jpeg,.jpg'
                    //{...formik.getFieldProps('InvoiceFile')}
                    onChange={(event: any) => {
                      event?.currentTarget.files.length > 0
                      ? formik.setFieldValue('InvoiceFile', event.currentTarget.files[0])
                      : formik.setFieldValue('InvoiceFile', '')
                    }}
                    onBlur={formik.handleBlur}
                    ref={invoiceRef}
                  />
                  {formik.touched.InvoiceFile && formik.errors.InvoiceFile && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.InvoiceFile}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loader}>
                {!loader && <span className='indicator-label'>Add Payment</span>}
                {loader && (
                  <span className='indicator-progress d-block'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  )
}

export default AddPayment
