import React, {FC, useState} from 'react'
import {AlertModal, SingleDatePickerComponent} from '../../CommonFunctions/CommonFunction'
import '../../../css/pendingCommission.css'

import * as Yup from 'yup'
import {useFormik} from 'formik'
import dayjs from 'dayjs'
import axios from 'axios'

interface PopupProps {
  partnerId: string
  modalClose: any
  commissionId: any
  commissionAmount: any
}

const API_URL = process.env.REACT_APP_API_URL
const AddPendingCommissionDetails: FC<PopupProps> = ({
  partnerId,
  modalClose,
  commissionId,
  commissionAmount,
}) => {
  const [loading, _] = useState(false)
  const [startDate, setStartDate] = useState<any>(new Date())

  const paymentSchema = Yup.object().shape({
    PaymentDate: Yup.string().required('Payment Date is required'),
    Amount: Yup.number().required('Amount is required'),
    PartnerId: Yup.string().required('Partner ID is required'),
    TransactionId: Yup.string().required('Transaction ID is required'),
    DocumentFile: Yup.mixed(),
  })

  const formattedDate = dayjs().format('DD/MM/YYYY')
  const initialValues = {
    PartnerId: partnerId,
    PartnerCommissionIds: commissionId,
    PaymentDate: formattedDate,
    Amount: commissionAmount,
    TransactionId: '',
    DocumentFile: '', // or set it to an empty string depending on your implementation
  }

  const formik = useFormik({
    initialValues,
    validationSchema: paymentSchema,
    onSubmit: (values, {resetForm}) => {
      let body = {
        PartnerId: values.PartnerId,
        PaymentDate: dayjs(values.PaymentDate, 'dd-mm-yyyy'),
        Amount: values.Amount,
        TransactionId: values.TransactionId,
        DocumentFile: values.DocumentFile,
        PartnerCommissionIds: values.PartnerCommissionIds,
      }

      axios
        .post(`${API_URL}/PartnerPayment/AddPartnerCommissionPayment`, body, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            if (res.data.success) {
              resetForm()
              modalClose()
              setStartDate(new Date())
            }
          })
        })
        .catch((err: any) => console.log(err))
    },
  })

  const handleDateChange = (dates: any, days?: any) => {
    formik.setFieldValue('PaymentDate', dayjs(dates).format('DD/MM/YYYYT00:00:00[Z]'))
    setStartDate(dates)
  }

  return (
    <div className='pending-commission_main-container  d-flex'>
      <div className='pending-partner_inner-container'>
        <div
          className='card mt-3 pending-commission_modal-container'
          style={{padding: '0rem 2.5rem 2rem 2.5rem'}}
        >
          <div className='card mt-5'>
            <div
              className='card-header card-header-stretch border-bottom border-gray-200'
              style={{padding: 'unset', minHeight: 'unset'}}
            >
              <h1>Payment Details</h1>
              <i
                className='fa fa-times'
                onClick={() => modalClose()}
                aria-hidden='true'
                style={{
                  fontSize: '2rem',
                  marginLeft: '22rem',
                  cursor: 'pointer',
                }}
              ></i>
            </div>
            <form onSubmit={formik.handleSubmit} className='form'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Payment Date</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    {SingleDatePickerComponent(
                      startDate,
                      handleDateChange,
                      'form-control',
                      new Date(new Date().getFullYear() + 1, 11, 31),
                      new Date(new Date().getFullYear() - 1, 11, 31),
                      false
                      // new Date()
                    )}
                    {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.PaymentDate}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Amount</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-border '
                      placeholder='Amount'
                      {...formik.getFieldProps('Amount')}
                      disabled
                    />
                    {formik.touched.Amount && formik.errors.Amount && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.Amount}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Transaction Id</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-border '
                      placeholder='Transaction Id'
                      {...formik.getFieldProps('TransactionId')}
                    />
                    {formik.touched.TransactionId && formik.errors.TransactionId && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.TransactionId}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className=''>Payment Receipt</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='file'
                      className='form-control form-control-border'
                      placeholder='Payment Receipt'
                      onChange={(e) => {
                        if (e.target.files !== null) {
                          formik.setFieldValue('DocumentFile', e.target.files[0])
                        }
                      }}
                    />

                    {formik.touched.DocumentFile && formik.errors.DocumentFile && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.DocumentFile}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && <span className='indicator-label'>Add Payment</span>}
                  {loading && (
                    <span className='indicator-progress d-block'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddPendingCommissionDetails}
