import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {Loader, Pagination, RowsPerPage} from '../../CommonFunctions/CommonFunction'
import axios from 'axios'

const DisplayPartnerOrg: React.FC = () => {
  const API = process.env.REACT_APP_API_URL
  const [loader, __] = useState<boolean>(false)
  const [companies, setCompanies] = useState<any[]>([])
  const [spinner, _] = useState(false)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [forcePage, setForcePage] = useState<any>(null)

  useEffect(() => {
    axios
      .get(`${API}/Organization/GetAllOrganizationByPartnerId`, {
        params: {
          partnerId: localStorage.getItem('userId'),
        },
      })
      .then((res) => setCompanies(res.data))
      .catch((err) => console.error(err))
  }, [])

  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const currentItemss = companies.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(companies.length / itemsPerPage)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % companies.length
    setItemOffset(newOffset)
  }
  return (
    <div className={`card`}>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Organization</span>
        </h3>

        <div className='d-flex'>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a organization'
          >
            <Link to='/Organizations/add' className='btn btn-sm btn-primary me-3'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Add Organization
            </Link>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      {spinner ? (
        Loader('0px')
      ) : (
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className=''></th>
                  <th className='min-w-100px'>Name</th>
                  <th className='min-w-100px'>Email</th>
                  <th className='min-w-100px'>Contact NO</th>
                  {/* <th className='min-w-100px'>Partner</th> */}
                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-100px text-center'>Expiry Date</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {companies?.length > 0 &&
                  currentItemss.map((company) => (
                    <tr key={company.org_Id}>
                      <td></td>
                      <td>
                        <div className='d-flex align-items-center'>
                          {/* <div className='symbol symbol-45px me-5'>
                              <img src={toAbsoluteUrl('/media/avatars/300-30.jpg')} alt='' />
                            </div> */}

                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fs-6'>{company.organizationName}</span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark  fs-6'>{company.org_Email}</span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark  fs-6'>{company.org_ContactNo}</span>
                          </div>
                        </div>
                      </td>

                      {/* <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark  fs-6'>{company.partnerName}</span>
                          </div>
                        </div>
                      </td> */}

                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark  fs-6'>{company.status}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center justify-content-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark text-center fs-6'>
                              {company.expiryDate
                                ? dayjs(company.expiryDate).format('DD/MM/YYYY')
                                : '-'}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td></td>
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}

          {loader && Loader('100px')}

          {companies.length > 10 && (
            <div className='d-flex align-items-center justify-content-center position-relative mb-3'>
              <div style={{left: '2rem', position: 'absolute', top: '0'}}>
                {RowsPerPage(
                  setForcePage,
                  setRowPerPage,
                  setItemOffset,
                  rowPerPage,
                  companies.length,
                  '0px'
                )}
              </div>

              {companies.length > rowPerPage && Pagination(handlePageClick, pageCount, forcePage)}
            </div>
          )}

          <span
            className={`d-flex justify-content-center w-100 ${companies.length <= 0 && 'mt-20'}`}
            style={{fontWeight: '600'}}
          >
            Showing {currentItemss.length} Records out of {companies.length}
          </span>
        </div>
      )}

      {/* begin::Body */}
    </div>
  )
}

export default DisplayPartnerOrg
