/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import axios from 'axios'
import dayjs from 'dayjs'

import {AlertModal, MultiDateTimePicker} from '../../CommonFunctions/CommonFunction'

const API = process.env.REACT_APP_API_URL

const Individual = () => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const loading = false

  const [browsertime, _] = useState<any>([])
  const [apptime, __] = useState<any>([])
  const [date, setDate] = useState<any>([])

  function getChartOptions(height: number): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-danger')
    const borderColor = getCSSVariableValue('--bs-danger')
    const baseColor = getCSSVariableValue('--bs-success')
    const secondaryColor = getCSSVariableValue('--bs-danger')

    return {
      series: [
        {
          name: 'Active time',
          data: browsertime,
        },
        {
          name: 'Idle time',
          data: apptime,
        },
      ],
      chart: {
        id: 'mychart',
        fontFamily: 'inherit',
        type: 'bar',
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '10%',
          borderRadius: 2,
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: date,
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return val + ' hours'
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    }
  }

  const SecIntoHrs$Min = (value: any) => {
    let newarr: any = []
    let next: any
    for (next of value) {
      const totalMinutes = Math.floor(next / 60)

      const hours = Math.floor(totalMinutes / 60)

      newarr.push(hours)
    }

    return newarr
  }

  const dateRange = () => {
    let newEndDate: any
    if (endDate == null) {
      newEndDate = startDate
    } else {
      newEndDate = endDate
    }

    if (startDate == null && endDate == null) {
      AlertModal('Please Select a date', '', 'warning', false, '#7066E0', 'Ok')
    } else {
      const body = {
        OrganizationId: localStorage.getItem('org_Id'),
        UserId: localStorage.getItem('userId'),
        FromDate: dayjs(startDate).format(),
        ToDate: dayjs(newEndDate).format(),
      }
      axios
        .post(`${API}/WebAndAppReport/WebAndAppUsageReportForUser`, body)
        .then((res) => {
          handleResponse(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const handleResponse = (res: any) => {
    if (res.data == '') {
      AlertModal('No data available', '', 'warning', false, '#7066E0', 'Ok')
    } else {
      const browser = res.data.map((item: any) => Number(item.totalBrowserTime))
      const app = res.data.map((item: any) => Number(item.totalAppTime))
      const date = res.data.map((item: any) => item.fromDate)
      let browstime = SecIntoHrs$Min(browser)
      let aptime = SecIntoHrs$Min(app)

      setDate(date)

      ApexCharts.exec(
        'mychart',
        'updateSeries',
        [
          {
            name: 'Web',
            data: browstime,
          },
          {
            name: 'App',
            data: aptime,
          },
        ],
        true
      )

      ApexCharts.exec(
        'mychart',
        'updateOptions',
        {
          xaxis: {
            categories: date,
          },
        },
        true
      )
    }
  }

  useEffect(() => {
    const body = {
      UserId: localStorage.getItem('userId'),
      OrganizationId: localStorage.getItem('org_Id'),
      FromDate: dayjs().format(),
      ToDate: dayjs().format(),
    }
    axios
      .post(`${API}/WebAndAppReport/WebAndAppUsageReportForUser`, body)
      .then((res) => {
        handleResponse(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))

    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  return (
    <div className='card card-xxl-stretch mb-5 mb-xl-10' style={{padding: '10px'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          {/* <span className='card-label fw-bolder fs-3 mb-1'>{record} activity</span> */}

          <span className='fw-bolder fs-3'>Web and App</span>
        </h3>

        {/* start pop up filter */}
        {/* <div className="card-toolbar" data-select2-id="select2-data-125-hcn3">

                    <button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                        <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="5" y="5" width="5" height="5" rx="1" fill="currentColor"></rect>
                                <rect x="14" y="5" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                                <rect x="5" y="14" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                                <rect x="14" y="14" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                            </g>
                        </svg></span>
                    </button>


                    <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_63ff10301761c" data-select2-id="select2-data-kt_menu_63ff10301761c">
                        <div className="px-7 py-5">
                            <div className="fs-5 text-dark fw-bold">Select Date Range</div>
                        </div>
                        <div className="separator border-gray-200"></div>

                        <div className="px-7 py-5" data-select2-id="select2-data-124-stdr">
                            <label className="form-label fw-semibold">Status:</label>

                                <div data-select2-id="select2-data-122-4nus">
                                    <select className="form-select form-select-solid select2-hidden-accessible" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_63ff10301761c" data-allow-clear="true" data-select2-id="select2-data-10-wu5g" aria-hidden="true" data-kt-initialized="1">
                                        <option data-select2-id="select2-data-12-kuwo"></option>
                                        <option value="1" data-select2-id="select2-data-128-z2w6">Approved</option>
                                        <option value="2" data-select2-id="select2-data-129-z6ry">Pending</option>
                                        <option value="2" data-select2-id="select2-data-130-c8ky">In Process</option>
                                        <option value="2" data-select2-id="select2-data-131-41i5">Rejected</option>
                                    </select><span className="select2 select2-container select2-container--bootstrap5 select2-container--below" dir="ltr" data-select2-id="select2-data-11-xg3m" style={{ width: '100%' }}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" aria-disabled="false" aria-labelledby="select2-u4sp-container" aria-controls="select2-u4sp-container"><span className="select2-selection__rendered" id="select2-u4sp-container" role="textbox" aria-readonly="true" title="Select option"><span className="select2-selection__placeholder">Select option</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                                </div>

                            <div className="mb-10">
                                <label className="form-label fw-semibold">Member Type:</label>

                                <div className="d-flex">
                                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                        <input className="form-check-input" type='Checkbox' value="1" />
                                        <span className="form-check-label">
                                            Author
                                        </span>
                                    </label>
                                    <label className="form-check form-check-sm form-check-custom form-check-solid">
                                        <input className="form-check-input" type="checkbox" value="2" />
                                        <span className="form-check-label">
                                            Customer
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="mb-10">
                                <label className="form-label fw-semibold">Notifications:</label>

                                <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                    <input className="form-check-input" type="checkbox" value="" name="notifications" />
                                    <label className="form-check-label">
                                        Enabled
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button type="reset" className="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>

                                <button type="submit" className="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                            </div>
                        </div>
                    </div>



                </div> */}

        {/* end of the pop up filter */}

        <div
          style={{
            marginRight: '30px',
            display: 'flex',
            alignItems: 'center',
            width: '285px',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{display: 'contents'}}>
            {MultiDateTimePicker(startDate, endDate, handleDateChange, '')}

            <button
              type='button'
              className='btn btn-primary'
              disabled={loading}
              onClick={dateRange}
            >
              {!loading && <span className='indicator-label'>Search</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>

        {/* end::Title */}
        {/* <div className='card-header border-0 pt-5'>
          <input type="calendar" />
        </div> */}
        {/* begin::Toolbar */}
        {/* <div className='card-toolbar'>
          <div className='card-toolbar'>
            <ul className='nav'>
              <li className='nav-item'>
                <span
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1'
                  data-bs-toggle='tab'
                  onClick={() => updateChartData("today")}
                >
                  Today's
                </span>
              </li>
              <li className='nav-item'>
                <span
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1'
                  data-bs-toggle='tab'
                  onClick={() => updateChartData("week")}
                >
                  Week
                </span>
              </li>
              <li className='nav-item'>
                <span
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4'
                  data-bs-toggle='tab'
                  onClick={() => updateChartData("month")}
                >
                  Month
                </span>
              </li>
            </ul>
          </div>
        </div> */}
      </div>

      {/* begin::Body */}
      <div className='card-body' style={{padding: '20px'}}>
        {/* begin::Chart */}
        <div
          ref={chartRef}
          id='kt_charts_widget_1_chart'
          style={{height: '500px', textAlign: 'left'}}
        />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Individual}
