import React, {FC} from 'react'
import TaskTypeDetails from './cards/TaskTypeDetails'



const AddTaskTypeWrapper: FC = () =>{
  return (
    <>
      
      <TaskTypeDetails/>
      
    </>
  )
}
export {AddTaskTypeWrapper}
