import React, {lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import PartnerDashboard from '../pages/dashboard/partnerDashboard/PartnerDashboard'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import ExistingPlan from '../pages/payment/ExistingPlan'
import PaymentHistory from '../pages/payment/PaymentHistory'
import {PartnerCommission} from '../pages/partner-commission/partnerCommission'
// import {PartnerDocuments} from '../pages/documents/PartnerDocuments'
import {Passwords} from '../pages/passwords/cards/passwords'
import DisplayPartnerOrg from '../pages/displayPartnerOrg/DisplayPartnerOrg'
import AddPartnerOrg from '../pages/displayPartnerOrg/AddPartnerOrg'
import {DocumentWrapper} from '../pages/docs/DocumentWrapper'
import {PartnerDocs} from '../pages/docs/PartnerDocs'
import {OrganizationDocs} from '../pages/docs/OrganizationDocs'

const PartnerRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/builder' />} />
        <Route path='builder' element={<PartnerDashboard />} />
        <Route path='ExistingPlan' element={<ExistingPlan />} />
        <Route path='paymentHistory' element={<PaymentHistory />} />
        <Route path='commission' element={<PartnerCommission />} />
        {/* <Route path='documents' element={<PartnerDocuments />} /> */}
        <Route path='changePassword' element={<Passwords />} />
        <Route path='organizations' element={<DisplayPartnerOrg />} />
        <Route path='Organizations/add' element={<AddPartnerOrg />} />

        <Route path='documents' element={<DocumentWrapper />}>
          <Route index element={<PartnerDocs />} />
          <Route path='partnerDocuments' element={<PartnerDocs />} />
          <Route path='organizationDocuments' element={<OrganizationDocs />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default PartnerRoutes
