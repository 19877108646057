import React, {useState, useEffect} from 'react'
import {useNavigate, Link} from 'react-router-dom'
import {
  departmentInfoByOrg,
  getRoles,
  getTimeZone,
  memberInfoByOrg,
} from '../../../modules/auth/redux/AuthCRUD'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {
  AlertModal,
  ErrorModal,
  GetActiveInactiveUser,
  baseMemberSchema,
} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'
import Switch from 'react-switch'
import dayjs from 'dayjs'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import 'react-rangeslider/lib/index.css'
import RangeSlider from 'react-rangeslider'

const API_URL = process.env.REACT_APP_API_URL

const phone = /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/
const letters = /^[a-zA-Z0-9 '][a-zA-Z0-9 ']*$/

export function EditEmployeeDetails() {
  const navigate = useNavigate()
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState<any>([])
  const [manager, setManager] = useState<any>([])
  const [checked, setChecked] = React.useState(false)
  const [notification, setNotification] = useState<boolean>(false)
  const [stopTimer, setStopTimer] = useState<boolean>(false)
  const [manualtracking, setManualTracking] = useState<boolean>(false)
  const [liveStream, setLiveStream] = useState<boolean>(false)
  const [liveRecording, setLiveRecording] = useState<boolean>(false)
  const [timeZone, setTimeZone] = useState<any[]>([])
  const [loadtoggle, _] = useState(
    localStorage.getItem('role') === process.env.REACT_APP_SYSTEM_FOURTH_ROLE ? false : true
  )
  const [loadmanager, __] = useState(
    localStorage.getItem('role') === process.env.REACT_APP_SYSTEM_FOURTH_ROLE ? false : true
  )
  const [department, setDepartment] = useState<any>([])
  const [managerOption, setManagerOption] = useState<[]>([])
  const [rolesOption, setRolesOption] = useState<[]>([])
  const [departmentOption, setDepartmentOption] = useState<[]>([])
  const [alignment, setAlignment] = React.useState('Default')
  const [disableTimePicker, setDisableTimePicker] = useState<boolean>(true)
  const [timePickerDefaultValue, setTimePickerDefaultValue] = useState<any>(
    dayjs(localStorage.getItem('orgCutOffTime')?.replaceAll('.', ':'), 'HH:mm:ss')
  )
  const [selectedTimeZone, setSelectedTimeZone] = useState<any>([])

  useEffect(() => {
    getAllUser()
    getRoles()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.roleName,
            value: item.roleId,
          }
        })
        setRolesOption(newList)
      })
      .catch((err) => {
        console.log(err)
      })

    departmentInfoByOrg()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.department,
            value: item.departmentId,
          }
        })
        setDepartmentOption(newList)
      })
      .catch((err) => {
        console.log(err)
      })

    getTimeZone()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.timeZonesName,
            value: item.id,
          }
        })
        setTimeZone(newList)
      })
      .catch((err) => console.log(err))
  }, [])

  const getAllUser = async () => {
    try {
      const org = localStorage.getItem('org_Id')
      let userList = await GetActiveInactiveUser(org)
      userList = userList.filter((entry: any) => entry.value !== localStorage.getItem('editUser'))
      setManagerOption(userList)
    } catch (error) {
      ErrorModal()
    }
  }

  useEffect(() => {
    let data =
      localStorage.getItem('editIsActive') === 'Inactive' ? 'GetInactiveUsersByID' : 'GetUsersByID'

    axios
      .get(`${API_URL}/Registration/${data}`, {
        params: {
          Id: localStorage.getItem('editUser'),
        },
      })
      .then((res) => {
        setChecked(res.data.isScreenShotEnable)
        setNotification(res.data.isNotificationEnable)
        formik.setFieldValue('userId', res.data.userId)
        formik.setFieldValue('address', res.data.address)
        formik.setFieldValue('contactNo', res.data.contactNo)
        formik.setFieldValue('email', res.data.email)
        formik.setFieldValue('fullName', res.data.fullName)
        formik.setFieldValue('managerId', res.data.managerId)
        formik.setFieldValue('roleId', res.data.roleId)
        formik.setFieldValue('startTime', res.data.startTime)
        formik.setFieldValue('endTime', res.data.endTime)
        formik.setFieldValue('baseLocation', res.data.baseLocation)
        formik.setFieldValue('departmentId', res.data.departmentId)
        formik.setFieldValue('empId', res.data.empId)
        formik.setFieldValue('timeZoneId', res.data.timeZoneId)
        formik.setFieldValue('workingDays', res.data.workingDays)
        formik.setFieldValue(
          'userCutOffTime',
          dayjs(res.data.userCutOffTime?.replaceAll(':', '.'), 'HH:mm:ss').format('HH:mm:ss')
        )
        setManualTracking(res.data.isManualTrackingEnable)
        setStopTimer(res.data.isStopTimerEnable)
        setTimePickerDefaultValue(dayjs(res.data.userCutOffTime?.replaceAll('.', ':'), 'HH:mm:ss'))
        setLiveStream(res.data.isLiveStreamEnable)
        setLiveRecording(res.data.isLiveRecordingEnable)

        if (
          localStorage.getItem('orgCutOffTime') === res.data.userCutOffTime?.replaceAll(':', '.')
        ) {
          setAlignment('Default')
          setDisableTimePicker(true)
        } else {
          setAlignment('Custom')
          setDisableTimePicker(false)
        }

        setRoles({
          label: res.data.roleName,
          value: res.data.roleId,
        })
        if (res.data.managerName !== null) {
          setManager({
            label: res.data.managerName,
            value: res.data.managerId,
          })
        }

        if (res.data.department !== null) {
          setDepartment({
            label: res.data.department,
            value: res.data.departmentId,
          })
        }

        setSelectedTimeZone({
          label: res.data.timeZoneName,
          value: res.data.timeZoneId,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const status = (): string => {
    const editIsActive = localStorage.getItem('editIsActive')
    return editIsActive === 'Inactive' ? '/member/inactive' : '/member'
  }

  const initialValues = {
    userId: '',
    managerId: '',
    organizationId: localStorage.getItem('org_Id'),
    email: '',
    roleId: '',
    fullName: '',
    address: '',
    contactNo: '',
    isScreenShotEnable: true,
    modifiedBy: localStorage.getItem('name'),
    modifiedDate: dayjs().format('DD-MM-YYYY'),
    baseLocation: '',
    departmentId: '',
    isNotificationEnable: true,
    IsManualTrackingEnable: false,
    IsStopTimerEnable: false,
    userCutOffTime: localStorage.getItem('orgCutOffTime') || undefined,
    isLiveStreamEnable: false,
    isLiveRecordingEnable: false,
    empId: '',
    timeZoneId: '',
    workingDays: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: baseMemberSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      values.isScreenShotEnable = checked
      values.isNotificationEnable = notification
      values.IsStopTimerEnable = stopTimer
      values.IsManualTrackingEnable = manualtracking
      values.isLiveStreamEnable = liveStream
      values.isLiveRecordingEnable = liveRecording
      values.userCutOffTime = values.userCutOffTime?.replaceAll(':', '.')

      setTimeout(() => {
        axios
          .post(`${API_URL}/Registration/UpdateUsers`, values)
          .then((res) => {
            AlertModal(
              res.data.message,
              '',
              res.data.success ? 'success' : 'warning',
              false,
              '#7066E0',
              'Ok'
            ).then(() => {
              setLoading(false)
              if (localStorage.getItem('editIsActive') == 'Inactive') {
                navigate('/member/inactive')
              } else {
                navigate('/member')
              }
            })
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            setSubmitting(false)
            setStatus('Registration process has broken')
          })
      }, 0)
    },
  })

  const handleToggle = (event: any) => {
    if (event.target.value === 'Default') {
      console.log('deafult')
      setAlignment(event.target.value)
      setDisableTimePicker(true)
      formik.setFieldValue('userCutOffTime', localStorage.getItem('orgCutOffTime'))
      setTimePickerDefaultValue(
        dayjs(localStorage.getItem('orgCutOffTime')?.replaceAll('.', ':'), 'HH:mm:ss')
      )
    } else if (event.target.value === 'Custom') {
      setAlignment(event.target.value)
      setDisableTimePicker(false)
    }
  }

  const handleCheckboxChange = (day: string) => {
    const updatedWorkingDays: string[] = [...formik.values.workingDays]
    const isChecked =
      formik.values?.workingDays?.length > 0 ? updatedWorkingDays.includes(day) : false
    if (isChecked) {
      const index = updatedWorkingDays.indexOf(day)
      updatedWorkingDays.splice(index, 1)
    } else {
      updatedWorkingDays.push(day)
    }

    // Update the formik state with the modified workingDays array
    formik.setFieldValue('workingDays', updatedWorkingDays)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer' role='header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Member Details</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>images</label>
  
                <div className='col-lg-8 fv'>
                  <input
                    type='file'
                   
                    className='form-control form-control-border'
                    placeholder='images'
                    
                    {...formik.getFieldProps('images')}
                  />
                  {formik.touched.images && formik.errors.images && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.images}</div>
                    </div>
                  )}
                </div>
              </div> */}

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                  <span>Name</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-border'
                    placeholder='Name'
                    {...formik.getFieldProps('fullName')}
                  />
                  {formik.touched.fullName && formik.errors.fullName && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.fullName}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Email</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    disabled={true}
                    type='email'
                    className='form-control form-control-border'
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 '>
                  <span>Employee Id</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-border'
                    placeholder='Employee Id'
                    {...formik.getFieldProps('empId')}
                  />
                  {formik.touched.empId && formik.errors.empId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.empId}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Department</label>
                <div className='col-lg-8 fv-row'>
                  {/* <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('departmentId')}
                  >
                    <option value='' hidden>
                      Select the department
                    </option>
                    {department.map((department) => (
                      <option
                        selected={
                          formik.getFieldProps('departmentId') === department.departmentId
                            ? true
                            : false
                        }
                        value={department.departmentId}
                      >
                        {department.department}
                      </option>
                    ))}
                  </select> */}
                  <div
                    className='min-w-200px'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Department'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={departmentOption}
                      value={department}
                      onBlur={formik.handleBlur}
                      placeholder='Select Department'
                      onChange={(item: any) => {
                        setDepartment(item)
                        formik.setFieldValue('departmentId', item !== null ? item.value : '')
                      }}
                      isClearable={true}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  <div>
                    {formik.touched.departmentId && formik.errors.departmentId && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.departmentId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {loadmanager && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Manager</label>
                  <div className='col-lg-8 fv-row'>
                    <div
                      className='min-w-200px'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title='Search manager'
                    >
                      <Select
                        className='selectDropdown'
                        components={makeAnimated()}
                        options={managerOption}
                        value={manager}
                        placeholder='Select Member'
                        onChange={(item: any) => {
                          setManager(item)
                          formik.setFieldValue('managerId', item !== null ? item.value : '')
                        }}
                        isClearable={true}
                        isSearchable={true}
                        closeMenuOnScroll={true}
                      />
                    </div>
                    <div>
                      {formik.touched.managerId && formik.errors.managerId && (
                        <div className='fv-plugins-message-container'>
                          <div className='text-danger mt-2'>{formik.errors.managerId}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Role</label>
                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Role'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={rolesOption}
                      value={roles}
                      onBlur={formik.handleBlur}
                      placeholder='Select Role'
                      onChange={(item: any) => {
                        setRoles(item)
                        formik.setFieldValue('roleId', item.value)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  <div>
                    {formik.touched.roleId && formik.errors.roleId && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.roleId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Base Location City</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-border'
                    placeholder='Base Location'
                    {...formik.getFieldProps('baseLocation')}
                  />
                  {formik.touched.baseLocation && formik.errors.baseLocation && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.baseLocation}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Time Zone</label>

                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search Role'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={timeZone}
                      value={selectedTimeZone}
                      onBlur={formik.handleBlur}
                      placeholder='Select Time Zone'
                      onChange={(item: any) => {
                        formik.setFieldValue('timeZoneId', item.value)
                        setSelectedTimeZone(item)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                  <div>
                    {formik.touched.timeZoneId && formik.errors.timeZoneId && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.timeZoneId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Contact No</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-border'
                    placeholder='Contact No'
                    {...formik.getFieldProps('contactNo')}
                  />
                  {formik.touched.contactNo && formik.errors.contactNo && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.contactNo}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Address</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-border'
                    placeholder='Address'
                    {...formik.getFieldProps('address')}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.address}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Cut Off Time (hh:mm:ss)
                </label>
                <div className='col-lg-8 fv-row d-flex align-items-center'>
                  <div style={{marginRight: '1rem'}}>
                    <ToggleButtonGroup
                      color='primary'
                      value={alignment}
                      onChange={handleToggle}
                      aria-label='Platform'
                    >
                      <ToggleButton value='Default'>Default</ToggleButton>
                      <ToggleButton value='Custom'>Custom</ToggleButton>
                    </ToggleButtonGroup>
                  </div>

                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        disabled={disableTimePicker}
                        value={timePickerDefaultValue}
                        sx={{
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0)',
                          },
                        }}
                        timeSteps={{hours: 1, minutes: 1, seconds: 1}}
                        format='HH:mm:ss'
                        ampm={false}
                        views={['hours', 'minutes', 'seconds']}
                        onChange={(value: any) => {
                          formik.setFieldValue(
                            'userCutOffTime',
                            dayjs(value).format('HH:mm:ss').replaceAll(':', '.')
                          )
                          setTimePickerDefaultValue(value)
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Working Days Per Week(Days)
                </label>

                <div className='col-lg-8 fv-row'>
                  <div
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                  >
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Mon'
                        id='flexCheckDefault'
                        checked={formik.values.workingDays.some((day) => day === 'Mon')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Mon</label>
                    </div>

                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Tue'
                        id='flexCheckChecked'
                        checked={formik.values.workingDays.some((day) => day === 'Tue')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Tue</label>
                    </div>

                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Wed'
                        id='kt_check_indeterminate_1'
                        checked={formik.values.workingDays.some((day) => day === 'Wed')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Wed</label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Thu'
                        id='kt_check_indeterminate_1'
                        checked={formik.values.workingDays.some((day) => day === 'Thu')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Thu</label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Fri'
                        id='kt_check_indeterminate_1'
                        checked={formik.values.workingDays.some((day) => day === 'Fri')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Fri</label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Sat'
                        id='kt_check_indeterminate_1'
                        checked={formik.values.workingDays.some((day) => day === 'Sat')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Sat</label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='Sun'
                        id='kt_check_indeterminate_1'
                        checked={formik.values.workingDays.some((day) => day === 'Sun')}
                        onChange={(e) => handleCheckboxChange(e.target.value)}
                      />
                      <label className='form-check-label'>Sun</label>
                    </div>
                  </div>
                  {formik.touched.workingDays && formik.errors.workingDays && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.workingDays}</div>
                    </div>
                  )}
                </div>
              </div>

              {loadtoggle && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Capture Screenshot</label>
                  <div className='col-lg-8 fv-row'>
                    <Switch
                      onChange={() => {
                        checked ? setChecked(false) : setChecked(true)
                      }}
                      checked={checked}
                    />
                  </div>
                </div>
              )}
              {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Screenshot Blur Effect</label>
              <div className='col-lg-8 fv-row'>
                <RangeSlider
                  value={Number(formik.values.userScreenshotBlurEffect)}
                  onChange={(e: any) => formik.setFieldValue('userScreenshotBlurEffect', String(e))}
                  min={0}
                  max={3}
                  step={1}
                  labels={{
                    0: '0',
                    [formik.values.userScreenshotBlurEffect]:
                      formik.values.userScreenshotBlurEffect,
                    3: '3',
                  }}
                ></RangeSlider>
              </div>
            </div> */}

              {ConfigDetails.isEnableLiveStreaming && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Live Stream</label>
                  <div className='col-lg-8 fv-row'>
                    <Switch
                      onChange={() => {
                        liveStream ? setLiveStream(false) : setLiveStream(true)
                      }}
                      checked={liveStream}
                    />
                  </div>
                </div>
              )}

              {ConfigDetails.isEnableLiveRecording && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Live Recording</label>
                  <div className='col-lg-8 fv-row'>
                    <Switch
                      onChange={() => {
                        liveRecording ? setLiveRecording(false) : setLiveRecording(true)
                      }}
                      checked={liveRecording}
                    />
                  </div>
                </div>
              )}

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Notification</label>
                <div className='col-lg-8 fv-row'>
                  <Switch
                    onChange={() => {
                      notification ? setNotification(false) : setNotification(true)
                    }}
                    checked={notification}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Stop Timer Enable</label>
                <div className='col-lg-8 fv-row'>
                  <Switch
                    onChange={() => {
                      stopTimer ? setStopTimer(false) : setStopTimer(true)
                    }}
                    checked={stopTimer}
                  />
                </div>
              </div>

              {ConfigDetails.isEnableManualTracking && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Private Mode</label>
                  <div className='col-lg-8 fv-row'>
                    <Switch
                      onChange={() => {
                        manualtracking ? setManualTracking(false) : setManualTracking(true)
                      }}
                      checked={manualtracking}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <Link to={status()}>
                <button type='button' className='btn btn-secondary'>
                  Cancel
                </button>
              </Link>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && <span className='indicator-label'>Save Changes</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
