/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import dayjs from 'dayjs'
import {Popup} from '../../../_metronic/partials/modals/ProVsIdle-Popup/ProVsIdlePopup'

type Props = {
  productiveTime: string
  idleTime: string
  unproductive: string
  away: string
  className: string
  fromDate: string
  selectedUser: string
}

const ProductivityChart: React.FC<Props> = ({
  className,
  productiveTime,
  idleTime,
  unproductive,
  away,
  fromDate,
  selectedUser,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [_, setRecord] = useState('Today')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [series, setSeries] = useState('')

  useEffect(() => {
    if (!chartRef.current) {
      console.log('asj')
      return
    } else {
      console.log('asj run')
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        Number(productiveTime),
        Number(idleTime),
        Number(unproductive),
        Number(away),
        setIsModalOpen,
        setSeries
      )
    )
    if (chart) {
      chart.render()
      console.log(updateChartData)
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [productiveTime, idleTime])

  const updateChartData = (recordBy: any) => {
    //Data for Today, week and month
    const todayActiveData = [8, 9, 10, 9.5, 9, 8]
    const todayIdeaData = [1, 0.5, 1, 1.5, 2, 0]

    let activeData
    let idealData

    if (recordBy === 'today') {
      setRecord('Today')
      activeData = todayActiveData
      idealData = todayIdeaData
    } else if (recordBy === 'week') {
      setRecord('Week')
      activeData = [38, 39, 40, 35, 29, 28]
      idealData = [2, 1, 0, 5, 11, 12]
    } else if (recordBy === 'month') {
      setRecord('Monthly')
      console.log('print from record by month')
      activeData = [108, 149, 110, 129.5, 149, 148]
      idealData = [10, 10.5, 11, 21.5, 12, 10]
    }

    ApexCharts.exec(
      'mychart',
      'updateSeries',
      [
        {
          name: 'Active Time',
          data: activeData,
        },
        {
          name: 'Ideal Time ',
          data: idealData,
        },
      ],
      true
    )
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-toolbar' data-select2-id='select2-data-125-hcn3'>
        <div
          className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
          data-kt-menu='true'
          id='kt_menu_63ff10301761c'
          data-select2-id='select2-data-kt_menu_63ff10301761c'
        >
          Name
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bold'>Filter Options</div>
          </div>
          <div className='separator border-gray-200'></div>
          <div className='px-7 py-5' data-select2-id='select2-data-124-stdr'>
            <div className='mb-10' data-select2-id='select2-data-123-xpd8'>
              <label className='form-label fw-semibold'>Status:</label>

              <div data-select2-id='select2-data-122-4nus'>
                <select
                  className='form-select form-select-solid select2-hidden-accessible'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-dropdown-parent='#kt_menu_63ff10301761c'
                  data-allow-clear='true'
                  data-select2-id='select2-data-10-wu5g'
                  aria-hidden='true'
                  data-kt-initialized='1'
                >
                  <option data-select2-id='select2-data-12-kuwo'></option>
                  <option value='1' data-select2-id='select2-data-128-z2w6'>
                    Approved
                  </option>
                  <option value='2' data-select2-id='select2-data-129-z6ry'>
                    Pending
                  </option>
                  <option value='2' data-select2-id='select2-data-130-c8ky'>
                    In Process
                  </option>
                  <option value='2' data-select2-id='select2-data-131-41i5'>
                    Rejected
                  </option>
                </select>
                <span
                  className='select2 select2-container select2-container--bootstrap5 select2-container--below'
                  dir='ltr'
                  data-select2-id='select2-data-11-xg3m'
                  style={{width: '100%'}}
                >
                  <span className='selection'>
                    <span
                      className='select2-selection select2-selection--single form-select form-select-solid'
                      role='combobox'
                      aria-haspopup='true'
                      aria-expanded='false'
                      aria-disabled='false'
                      aria-labelledby='select2-u4sp-container'
                      aria-controls='select2-u4sp-container'
                    >
                      <span
                        className='select2-selection__rendered'
                        id='select2-u4sp-container'
                        role='textbox'
                        aria-readonly='true'
                        title='Select option'
                      >
                        <span className='select2-selection__placeholder'>Select option</span>
                      </span>
                      <span className='select2-selection__arrow' role='presentation'>
                        <b role='presentation'></b>
                      </span>
                    </span>
                  </span>
                  <span className='dropdown-wrapper' aria-hidden='true'></span>
                </span>
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-semibold'>Member Type:</label>

              <div className='d-flex'>
                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                  <input className='form-check-input' type='Checkbox' value='1' />
                  <span className='form-check-label'>Author</span>
                </label>
                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input className='form-check-input' type='checkbox' value='2' />
                  <span className='form-check-label'>Customer</span>
                </label>
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-semibold'>Notifications:</label>

              <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                <input className='form-check-input' type='checkbox' value='' name='notifications' />
                <label className='form-check-label'>Enabled</label>
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              <button
                type='reset'
                className='btn btn-sm btn-light btn-active-light-primary me-2'
                data-kt-menu-dismiss='true'
              >
                Reset
              </button>

              <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <style>
          {`
                .apexchartsmychart {
                 overflow-x:scroll;
                 overflow-y:hidden !important;
                }
          }
              `}
        </style>
        <div
          ref={chartRef}
          id='kt_charts_widget_1_chart'
          style={{height: '200px', textAlign: 'left'}}
        />
        {isModalOpen && (
          <Popup
            onClose={handleCloseModal}
            barDate={fromDate}
            selectedUser={selectedUser ? selectedUser : ''}
            series={series}
          ></Popup>
        )}
      </div>
    </div>
  )
}

export {ProductivityChart}

function getChartOptions(
  height: number,
  producitveTime: number,
  idleTime: number,
  unproductive: number,
  away: number,
  setIsModalOpen: (value: boolean) => void,
  setSeries: (value: string) => void
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-danger')
  const borderColor = getCSSVariableValue('--bs-danger')

  const productiveData = Number(producitveTime) / 3600
  const idleData = Number(idleTime) / 3600
  const unproductiveData = Number(unproductive) / 3600
  const awayData = Number(away) / 3600

  return {
    series: [
      {
        name: 'Productive',
        data: [productiveData],
      },
      {
        name: 'Unproductive',
        data: [unproductiveData],
      },
      {
        name: 'Idle',
        data: [idleData],
      },
      {
        name: 'Away',
        data: [awayData],
      },
    ],
    chart: {
      id: 'mychart',
      fontFamily: 'inherit',
      stacked: false,
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          if (config.w.config.series[config.seriesIndex].name !== 'Away') {
            event.target.style.cursor = 'pointer'
          }
        },
        dataPointSelection(e, chart, options) {
          if (options.seriesIndex === 3) {
            return
          }
          if (e.button === 2) {
            return
          }

          switch (options.seriesIndex) {
            case 0:
              setSeries('productive')
              break
            case 1:
              setSeries('unproductive')
              break
            case 2:
              setSeries('idle')
              break
            case 3:
              setSeries('idle')
              break
            default:
              setSeries('')
              break
          }

          setIsModalOpen(true)
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '100%',
        borderRadius: 5,
        dataLabels: {
          orientation: 'horizontal',
          position: 'center',
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        const changeVal = Math.round(val * 3600)

        return dayjs().startOf('day').second(changeVal).format('HH:mm:ss')
      },
      style: {
        colors: ['#000000'],

        fontSize: '9px',
      },
      offsetX: 10,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Productivity vs Idle'],
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          const hours = Math.floor(Number(val))
          const minutes = Math.floor((Number(val) - hours) * 60)
          const seconds = Math.floor(((Number(val) - hours) * 60 - minutes) * 60)

          return (
            hours.toString().padStart(2, '0') +
            ':' +
            minutes.toString().padStart(2, '0') +
            ':' +
            seconds.toString().padStart(2, '0')
          )
        },
      },
    },
    colors: ['#34A853', '#FF6178', '#FBBC04', '#9D6B50'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  }
}
