import React, {FC} from 'react'
import ProjectTypeDetails from './cards/ProjectTypeDetails'



const AddProjectTypeWrapper: FC = () =>{
  return (
    <>
      <ProjectTypeDetails/>
    </>
  )
}
export {AddProjectTypeWrapper}
