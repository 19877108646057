/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import swal from 'sweetalert2'
import axios from 'axios'
import {
  Pagination,
  Loader,
  CommonActionButtons,
  AlertModal,
} from '../../../CommonFunctions/CommonFunction'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const API_URL = process.env.REACT_APP_API_URL

const ProjectType = () => {
  const [projectType, setProjectType] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredProjectType, setFilteredProjectType] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [updateState, setUpdateState] = useState('')

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const filteredMembers = projectType.filter((projectTypes) =>
    projectTypes.projectType.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % projectType.length
    setItemOffset(newOffset)
  }

  const currentItemss = filteredMembers.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(projectType.length / itemsPerPage)

  useEffect(() => {
    const role = localStorage.getItem('role')
    if (
      role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    ) {
      setSpinner(true)
      GetProjectType()
    }
  }, [])

  //to get all the existing project types
  const GetProjectType = async () => {
    try {
      const organizationId = localStorage.getItem('org_Id')
      const projectTypes = await axios.get(`${API_URL}/ProjectType/GetProjectTypesById`, {
        params: {
          Org_Id: organizationId,
        },
      })
      setProjectType(projectTypes.data)
      setFilteredProjectType(projectTypes.data)
      setSpinner(false)
    } catch (error) {
      console.log(error)
    }
  }

  //to delete a project type
  const deleteHandler = (Props: any) => {
    let body = {
      id: Props,
    }

    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Yes, delete it!'
    ).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .patch(`${API_URL}/ProjectType/DeleteProjectType`, body)
          .then((res) => {
            GetProjectType()
            AlertModal(
              res.data.message,
              '',
              res.data.success ? 'success' : 'warning',
              false,
              '#7066E0',
              'Ok'
            )
          })
          .catch((err) => {
            console.log(err.data.message)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Project type not Deleted', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  const handleSearchChange = (name: string) => {
    setSearchQuery(name)
    setFilteredProjectType(filteredProjectType)
    setItemOffset(0)
  }

  function handleEdit(id: any) {
    console.log('The id is ', id)
    setUpdateState(id)
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder mb-1' style={{fontSize: '19px'}}>
            Project Type
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>

        <div style={{display: 'flex'}}>
          {spinner ? (
            ''
          ) : (
            <Tippy placement='top' content='Search  by Project Type'>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <input
                  style={{marginRight: '1rem', padding: '0.7rem 1.5rem', minHeight: 'unset'}}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Search by Project Type'
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </div>
            </Tippy>
          )}

          <Tippy placement='top' content='Click to add a Project Type'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              <Link to='/addProjectType' className='btn btn-sm btn-primary me-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Project Type
              </Link>
            </div>
          </Tippy>
        </div>
      </div>

      {spinner ? (
        Loader('100px')
      ) : (
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                  </th>
                  <th className='min-w-120px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Name
                  </th>
                  <th className='min-w-120px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Description
                  </th>
                  <th className='min-w-100px text-center'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItemss.map((names, index) =>
                  updateState === names.id ? (
                    <Edit
                      name={names}
                      setUpdateState={setUpdateState}
                      getHolidays={GetProjectType}
                    />
                  ) : (
                    <tr key={index}>
                      <td></td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div
                            className='d-flex justify-content-start flex-column '
                            style={{width: '13rem', fontSize: '13px'}}
                          >
                            {names.projectType}

                            {/* </a> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div
                            className='d-flex justify-content-start flex-column '
                            style={{width: '13rem', fontSize: '13px'}}
                          >
                            {names.projectTypeDescription}

                            {/* </a> */}
                          </div>
                        </div>
                      </td>

                      <td style={{width: '10rem'}}>
                        <div className='d-flex justify-content-evenly flex-shrink-0'>
                          <Tippy placement='top' content='Edit Project Type'>
                            <button
                              onClick={() => {
                                handleEdit(names.id)
                              }}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              style={{}}
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </button>
                          </Tippy>

                          <Tippy placement='top' content='Delete Project Type'>
                            <a
                              onClick={() => deleteHandler(names.id)}
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              data-bs-trigger='hover'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          </Tippy>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  )
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            <div className='d-flex flex-column'>
              {filteredMembers.length > 10 && (
                <div className='d-flex align-items-center justify-content-center'>
                  {Pagination(handlePageClick, pageCount, 0)}
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
      )}

      {/* begin::Body */}
    </div>
  )
}

interface EditProps {
  name: any
  setUpdateState: any
  getHolidays: any
}

const Edit: React.FC<EditProps> = ({name, setUpdateState, getHolidays}) => {
  console.log('This is insid the edit component')
  const inputRef = useRef<HTMLInputElement>(null)

  const [editedName, setEditedName] = useState(name.projectType)
  const [editedDescription, setEditedDescription] = useState(name.projectTypeDescription)

  const handleUpdate = () => {
    const values = {
      id: name.id,
      org_Id: name.org_Id,
      projectType: editedName,
      projectTypeDescription: editedDescription,
      isActive: name.isActive,
      createdDate: name.createdDate,
      createdBy: name.createdBy,
      isDefault: name.isDefault,
    }

    axios
      .post(`${API_URL}/ProjectType/UpdateProjectTypes`, values)
      .then((res) => {
        AlertModal(
          res.data.message,
          '',
          res.data.success ? 'success' : 'warning',
          false,
          '#7066E0',
          'Ok'
        )

        getHolidays()
      })
      .catch((err) => {
        console.log(err)
      })
    setUpdateState(null)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleCancel = () => {
    setUpdateState(null)
  }

  return (
    <>
      <tr>
        <td></td>
        <td>
          <input
            className='edit-input fs-6'
            type='text'
            name='name'
            ref={inputRef}
            spellCheck={false}
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
        </td>
        <td>
          <input
            className='edit-input fs-6'
            type='text'
            name='desctiption'
            spellCheck={false}
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
          />
        </td>

        <td style={{width: '10rem'}}>{CommonActionButtons(handleUpdate, handleCancel)}</td>
        <td></td>
      </tr>
    </>
  )
}

export {ProjectType}
