import React, {FC} from 'react'

import  EmployeeDetails  from './cards/EmployeeDetails'

const EmpWrapper: FC = () =>{
  return (
    <>
      
      <EmployeeDetails/>
      
    </>
  )
}
export {EmpWrapper}
