import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {EmpWrapper} from '../pages/addEmp/emp'
import {ProWrapper} from '../pages/addPro/Project'
import {ComWrapper} from '../pages/addCompany/addCompany'
import {EditEmpWrapper} from '../pages/editEmp/editEmp'
import {EditCompanyDetails} from '../pages/addCompany/cards/editCompany'
import {EditProjectDetails} from '../pages/editProject/cards/editProject'
import {Passwords} from '../pages/passwords/cards/passwords'
import {Member} from '../pages/members/mem/memSuperAdmin'
import {CompanyOverview} from '../pages/companyOverview/CompanyOverview'
import OnBoardReq from '../pages/onBoardReq/OnBoardReq'

import ChangePlanReq from '../pages/payment/ChangePlanReq'
import ExistingPlan from '../pages/payment/ExistingPlan'
import AddPayment from '../pages/payment/AddPayment'
import SuperAdminConfigWrapper from '../pages/superAdminConfig/SuperAdminConfigWrapper'
import SystemConfig from '../pages/superAdminConfig/SystemConfig'
import OrgConfig from '../pages/superAdminConfig/OrgConfig'
import {DisplayPartnerWrapper} from '../pages/Partner/DisplayPartnerWrapper'
import PartnerDetails from '../pages/Partner/PartnerDetails'
import {EditPartnerDetails} from '../pages/Partner/editPartner'
import DisplayInformation from '../pages/labels/displayAllLabel'
import InformationDetails from '../pages/labels/addNewLabel'
import EditInformationDetails from '../pages/labels/editLabelDetails'
import AllPlans from '../pages/plans/displayAllPlans'
import AddNewPlan from '../pages/plans/addNewPlan'
import EditPlanDetails from '../pages/plans/editPlan'
import {TrialExtensionHistory} from '../pages/trial-extension/trialExtensionHistory'
import TrialExtensionRequest from '../pages/trial-extension/trialExtensionRequst'
import PaymentHistory from '../pages/payment/PaymentHistory'
import TaxAndDiscount from '../pages/payment/TaxAndDiscount'
import DisplayGroupAdmin from '../pages/GroupAdmin/DisplayGroupAdmin'
import GroupAdminDetails from '../pages/GroupAdmin/GroupAdminDetails'
import EditGroupAdminDetails from '../pages/GroupAdmin/EditGroupAdminDetails'
// import {PartnerDocuments} from '../pages/documents/PartnerDocuments'
import {PendingCommission} from '../pages/Partner/PartnerPendingCommission'
import {PartnerCommission} from '../pages/partner-commission/partnerCommission'
import PartnerCommissionData from '../pages/partnerCommision/PartnerCommissionData'
import TrialExtension from '../pages/extendTrial/TrialExtension'
import {DocumentWrapper} from '../pages/docs/DocumentWrapper'
import {PartnerDocs} from '../pages/docs/PartnerDocs'
import {OrganizationDocs} from '../pages/docs/OrganizationDocs'
import DeleteScreenshot from '../pages/screenshots/SuperAdminScreenshotDelete'
import PopulateSalesData from '../pages/populateSalesData'
import IntegrationRequest from '../pages/integration/integrationRequest'
import IntegratedPortals from '../pages/integration/integratedPortals'
import InActiveOrg from '../pages/Organisation/com/InActiveOrg'
import ActiveOrg from '../pages/Organisation/com/ActiveOrg'
import {OrganizationWrapper} from '../pages/Organisation/OrganizationWrapper'
import AdHOCPayment from '../pages/adHOCPayment/AdHOCPayment'
import {Main} from '../pages/payment/Main'

const SuperAdminRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/organization' />} />
        {/* Pages */}

        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* <Route path='testpage' element={<DashboardWrapper1 />} />
                <Route path='download' element={<Download className='' />} />
                <Route path='project' element={<ProjectWrapper />} /> */}
        <Route path='add' element={<EmpWrapper />} />
        {/* <Route path='timesheet' element={<TimesheetWrapper />} />
                <Route path='reports' element={<AttendanceSheetWrapper />} />
                <Route path='attendancesheet' element={<Attend />} /> */}

        <Route path='com' element={<ComWrapper />} />
        <Route path='editEmp' element={<EditEmpWrapper />} />
        <Route path='editCompany' element={<EditCompanyDetails />} />
        <Route path='editProject' element={<EditProjectDetails />} />
        <Route path='overview' element={<CompanyOverview />} />
        <Route path='onBoardRequest' element={<OnBoardReq />} />
        {/* <Route path='SendPlanDetails' element={<SendPlanDetails/>}/>
        <Route path='UpgradeRequest' element={<UpgradeRequest/>}/>
        <Route path='CurrPlanOfOrg' element={<CurrPlanOfOrg/>}/> */}
        <Route path='AdHOCPayment' element={<AdHOCPayment />} />
        <Route path='ChangePlanReq' element={<ChangePlanReq />} />
        <Route path='ExistingPlan' element={<ExistingPlan />} />
        <Route path='paymentHistory' element={<PaymentHistory />} />
        <Route path='TaxAndDiscount' element={<TaxAndDiscount />} />
        <Route path='AddPayment' element={<AddPayment />} />
        <Route path='partner' element={<DisplayPartnerWrapper />} />
        <Route path='addPartner' element={<PartnerDetails />} />
        <Route path='partner/editPartner' element={<EditPartnerDetails />} />
        <Route path='allInformation' element={<DisplayInformation />} />
        <Route path='allInformation/addInformation' element={<InformationDetails />} />
        <Route path='allInformation/editInformation' element={<EditInformationDetails />} />
        <Route path='allPlans' element={<AllPlans />} />
        <Route path='allPlans/addNewPlan' element={<AddNewPlan />} />
        <Route path='allPlans/editPlanDetails' element={<EditPlanDetails />} />
        <Route path='trialExtensionHistory' element={<TrialExtensionHistory />} />
        <Route path='trialExtensionRequest' element={<TrialExtensionRequest />} />
        <Route path='allGroupAdmin' element={<DisplayGroupAdmin />} />
        <Route path='allGroupAdmin/addGroupAdmin' element={<GroupAdminDetails />} />
        <Route path='allGroupAdmin/editGroupAdmin' element={<EditGroupAdminDetails />} />
        <Route path='pendingCommission' element={<PendingCommission />} />
        <Route path='associatePartner' element={<PartnerCommissionData />} />
        <Route path='commission' element={<PartnerCommission />} />
        <Route path='trialExtension' element={<TrialExtension />} />
        <Route path='deleteScreenshot' element={<DeleteScreenshot />} />
        <Route path='populateData' element={<PopulateSalesData />} />
        <Route path='integration/request' element={<IntegrationRequest />} />
        <Route path='integration/portals' element={<IntegratedPortals />} />
        <Route path='organization/newcompany' element={<ComWrapper />} />

        <Route path='payment' element={<Main />}>
          <Route index element={<AddPayment />} />
          <Route path='onlinePayment' element={<AddPayment />} />
          <Route path='adHOCPayment' element={<AdHOCPayment />} />
        </Route>

        <Route path='documents' element={<DocumentWrapper />}>
          <Route index element={<PartnerDocs />} />
          <Route path='partnerDocuments' element={<PartnerDocs />} />
          <Route path='organizationDocuments' element={<OrganizationDocs />} />
        </Route>

        <Route path='organization' element={<OrganizationWrapper />}>
          <Route index element={<ActiveOrg />} />
          <Route path='active' element={<ActiveOrg />} />
          <Route path='inactive' element={<InActiveOrg />} />
        </Route>

        {/* <Route path='documents' element={<PartnerDocuments />} /> */}
        {/* <Route path='member' element={<Member />} />
        <Route path='memberemp' element={<MemberWrapperEmp />} /> */}
        <Route path='newproject' element={<ProWrapper />} />
        {/* <Route path='urls' element={<Urls />} />
                <Route path='apps' element={<Apps />} />
                <Route path='schedule' element={<Schedule className='' />} />
                <Route path='addschedule' element={<AddSchedule />} />
                <Route path='editschedule' element={<EditSchedule />} />
                <Route path='timeline' element={<Report />} /> */}
        <Route path='changePassword' element={<Passwords />} />
        {/* <Route path="shift" element={<Shift />} /><Route path="shift" element={<Shift />} />
                <Route path='holiday' element={<Holiday />} />
                <Route path='addholiday' element={<AddHoliday />} />
                <Route path='editholiday' element={<EditHoliday />} /> */}
        <Route path='member' element={<Member />} />

        {/* <Route path="member" element={<MemberMain />}>
                    <Route index element={<Member />} />
                    <Route path="active" element={<Member />} />
                    <Route path="inactive" element={<InactiveMember />} />
                </Route> */}

        {/* < Route path="task" element={<Main />}>
                    <Route index element={<Task />} />
                    <Route path="create" element={<Task />} />
                    <Route path="allocate" element={<Allocate />} />
                </Route> */}

        {/* <Route path="web&app" element={<WebAppMain />}>
                    <Route index element={<Individual />} />
                    <Route path="individual" element={<Individual />} />
                    <Route path="team" element={<Team />} />
                </Route> */}

        {/* <Route path="empAttendance" element={<AttendanceMain />}>
                    <Route index element={<AttendanceIndivitual />} />
                    <Route path="individual" element={<AttendanceIndivitual />} />
                    <Route path="team" element={<TeamAttendance />} />
                </Route> */}

        <Route path='config' element={<SuperAdminConfigWrapper />}>
          <Route index element={<SystemConfig />} />
          <Route path='systemConfig' element={<SystemConfig />} />
          <Route path='orgConfig' element={<OrgConfig />} />
        </Route>

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        {/* <Route
                    path='timesheet/daily'
                    element={
                        <SuspensedView>
                            <TimesheetWrapper></TimesheetWrapper>
                        </SuspensedView>
                    }
                /> */}

        <Route
          path='member/addmember'
          element={
            <SuspensedView>
              <EmpWrapper></EmpWrapper>
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {SuperAdminRoutes}
