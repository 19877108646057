import moment from 'moment'
import React, {useEffect, useState} from 'react'
import Chart from 'react-apexcharts'

type Props = {
  option: any
  series: any
}

const ChartReact: React.FC<Props> = ({option, series}) => {
  const [optionss, setOptionss] = useState<any>({
    xaxis: {
      categories: [],
    },
  })
  const [seriess, setSeriess] = useState<any>([
    {
      data: [],
    },
  ])

  const newOptions = (data: any) => {
    //console.log(data)
    const colors = [
      '#33b2df',
      '#03C04A',
      '#e74f4e',
      '#546E7A',
      '#d4526e',
      '#13d8aa',
      '#A5978B',
      '#2b908f',
      '#f9a3a4',
      '#90ee7e',
      '#f48024',
      '#69d2e7',
    ]
    setOptionss({
      chart: {
        id: 'basic-bar',
        type: 'bar',
        height: 'auto',
        width: '100%',
        toolbar: {
          show: false,
        },
        // events: {
        //     click: function (chart: any, w: any, e: any) {
        //          console.log(chart, w, e)
        //     }
        // }
      },
      colors: colors,
      plotOptions: {
        bar: {
          barHeight: '60%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#000000'],
        },
        formatter: function (val: any, opt: any) {
          //console.log(val)
          return convertSecIntoHoursMinSec(val)
        },
        offsetX: 10,
        dropShadow: {
          enabled: false,
        },
      },
      legend: {
        show: true,
      },
      stroke: {
        width: 2,
        colors: ['#fff'],
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: data,
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: true,
        },
        y: {
          title: {
            formatter: function (seriesName: any) {
              return 'Time : '
            },
          },
          formatter: function (val: any) {
            return convertSecIntoHoursMinSec(val)
          },
        },
      },
    })
  }

  const newSeries = (data: any) =>
    setSeriess([
      {
        data: data,
      },
    ])

  const convertSecIntoHoursMinSec = (totalSeconds: any) => {
    const duration = moment.duration(totalSeconds, 'seconds')
    const hours = Math.floor(duration.asHours()).toString().padStart(2, '0')

    return moment.utc(totalSeconds * 1000).format(hours + ':mm:ss')
  }

  useEffect(() => {
    newOptions(option)
    newSeries(series)
  }, [])

  return (
    <div>
      <Chart options={optionss} series={seriess} type='bar' />
    </div>
  )
}

export {ChartReact}
