import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {Pagination} from '../../CommonFunctions/CommonFunction'

type Props = {
  data: any
  deleteFun: any
}

const TaskTableAccordion: React.FC<Props> = ({data, deleteFun}) => {
  const [rowPerPage, setRowPerPage] = useState(5)
  let itemsPerPage: number = rowPerPage
  const [currentItems, setCurrentItems] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(data?.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(data.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, data])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % data?.length
    setItemOffset(newOffset)
  }
  return (
    <>
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bolder text-dark'>
              <th> </th>
              <th className='min-w-125px'>Task Name</th>
              <th className='min-w-125px'>Start Date</th>
              <th className='min-w-125px'>End Date</th>
              <th className='min-w-125px text-center'>Estimate Hours</th>
              <th className='min-w-150px text-center'>Actions</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {currentItems?.map((item: any) => (
              <tr key={item.allocatedTaskId}>
                <td></td>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>{item.taskName}</div>
                  </div>
                </td>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {dayjs(item.startDate).format('DD/MM/YYYY')}
                    </div>
                  </div>
                </td>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {dayjs(item.endDate).format('DD/MM/YYYY')}
                    </div>
                  </div>
                </td>

                <td>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {item.estimatedHours}
                    </div>
                  </div>
                </td>

                <td>
                  <div className='d-flex flex-shrink-0  justify-content-evenly'>
                    <Link
                      to={`/task/editAllocatedTask?taskId=${item.allocatedTaskId}`}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </Link>
                    <a
                      onClick={() => {
                        deleteFun(item.allocatedTaskId)
                      }}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>

      {data.length > 5 && (
        <div className='d-flex align-items-center justify-content-center position-relative mb-7'>
          {/* <div style={{left: '0', position: 'absolute', top: '0'}}>
          {RowsPerPage(
            setForcePage,
            setRowPerPage,
            setItemOffset,
            rowPerPage,
            item.length,
            '1rem'
          )}
        </div> */}

          {data.length > rowPerPage && Pagination(handlePageClick, pageCount, 0)}
        </div>
      )}
    </>
  )
}

export default TaskTableAccordion
