import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  AlertModal,
  GetList,
  PartnerForm,
  basePartnerSchema,
} from '../../CommonFunctions/CommonFunction'
import axios from 'axios'
import {GetAllPartner, GetPartnerById} from '../../services/partner.service'
import AddNewSalesPerson from './AddNewSalesPerson'

export function EditPartnerDetails() {
  const [loading, setLoading] = useState(false)
  const [_, setPartner] = useState<any[]>([])
  const [partnerType, setPartnerType] = useState<any>([])
  const [disableInput, setDisableInput] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [fileName, setFileName] = useState<string>('')
  const [__, setCount] = useState<any>(0)
  const [showFile, setShowFile] = useState(false)
  const navigate = useNavigate()
  const letters = /^[a-zA-Z][a-zA-Z ]*$/
  const API_URL = process.env.REACT_APP_API_URL

  const initialValues = {
    partnerName: '',
    email: '',
    partnerId: '',
    referralCode: '',
    partnerTypeId: '',
    primaryContactName: '',
    contactNo: '',
    address: '',
    gst: '',
    pan: '',
    commission: '',
    partnerContractDocFile: '',
    PartnerContractDocName: '',
    addSalesDetailsRequest: [],
  }

  const partnerSchema = basePartnerSchema.shape({
    partnerName: Yup.string()
      .matches(letters, 'numerals and symbols are not allowed')
      .min(3, 'Minimum 3 character')
      .max(50, 'Maximum 50 character')
      .required('Full Name is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: partnerSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)

      setTimeout(() => {
        const form = new FormData()
        form.append('partnerId', values.partnerId)
        form.append('email', values.email)
        form.append('partnerName', values.partnerName)
        form.append('referralCode', values.referralCode)
        form.append('partnerTypeId', values.partnerTypeId)
        form.append('primaryContactName', values.primaryContactName)
        form.append('contactNo', values.contactNo)
        form.append('address', values.address)
        form.append('gst', values.gst)
        form.append('pan', values.pan)
        form.append('commission', values.commission)

        form.append('PartnerContractDocFile', values.partnerContractDocFile)
        form.append('PartnerContractDocName', values.PartnerContractDocName)

        form.append('addSalesDetailsRequest', JSON.stringify(values.addSalesDetailsRequest))

        axios
          .post(`${API_URL}/Registration/UpdatePartner`, form)
          .then((res) => {
            AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok').then(() => {
              setLoading(false)
              navigate('/partner')
            })
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            setSubmitting(false)
            setStatus('Registration process has broken')
          })
      }, 0)
    },
  })

  useEffect(() => {
    GetPartners()
    GetList(setPartnerType)
    GetDetails()
  }, [])

  const UserRole = [
    {
      label: 'Partner',
      value: 'Partner',
    },
  ]

  const GetPartners = async () => {
    try {
      const getAllPartner = await GetAllPartner()

      const newList = getAllPartner.data.map((item: any) => {
        return {
          label: item.partnerName,
          value: item.partnerId,
        }
      })
      setPartner(newList)
    } catch (error) {
      console.log(error)
    }
  }

  const GetDetails = async () => {
    const partnerId = localStorage.getItem('editPartner')

    if (partnerId) {
      const getPartnerDetails = await GetPartnerById(partnerId)
      formik.setFieldValue('partnerName', getPartnerDetails.data.partnerName)
      formik.setFieldValue('email', getPartnerDetails.data.partnerEmail)
      formik.setFieldValue('partnerId', getPartnerDetails.data.partnerId)
      formik.setFieldValue('partnerTypeId', getPartnerDetails.data.partnerTypeId)
      formik.setFieldValue('referralCode', getPartnerDetails.data.referralCode)
      formik.setFieldValue('primaryContactName', getPartnerDetails.data.primaryContactName)
      formik.setFieldValue('contactNo', getPartnerDetails.data.contactNo)
      formik.setFieldValue('address', getPartnerDetails.data.address)
      formik.setFieldValue('gst', getPartnerDetails.data.gst)
      formik.setFieldValue('pan', getPartnerDetails.data.pan)
      formik.setFieldValue('commission', getPartnerDetails.data.commission)
      formik.setFieldValue('addSalesDetailsRequest', getPartnerDetails.data.salesDetailsResponse)
      formik.setFieldValue('partnerContractDocFile', getPartnerDetails.data.partnerContractDocName)
      formik.setFieldValue('PartnerContractDocName', getPartnerDetails.data.partnerContractDocName)
      setFileName(getPartnerDetails.data.partnerContractDocName)
      if (
        getPartnerDetails.data.partnerContractDocName !== null &&
        getPartnerDetails.data.partnerContractDocName.length > 0
      ) {
        setShowFile(true)
      }

      if (getPartnerDetails.data.referralCode.length == 10) {
        setIsValid(true)
        setDisableInput(true)
      }
    }
  }

  const handleTypeChange = (e: any) => {
    formik.setFieldValue('partnerTypeId', e.value)
  }

  const saveSalesPersonInfo = (id: any, name: string, value: any) => {
    formik.values.addSalesDetailsRequest.map((item: any) => {
      if (item.id === id) {
        item[name] = value
      }
      setCount((prev: any) => prev + 1)
    })
  }

  const deleteTableRow = (id: any) => {
    const reamingRow = formik.values.addSalesDetailsRequest.filter((item: any) => item.id !== id)
    formik.setFieldValue('addSalesDetailsRequest', reamingRow)
  }

  const cancelFile = () => {
    formik.setFieldValue('partnerContractDocFile', '')
    formik.setFieldValue('PartnerContractDocName', '')

    setShowFile(!showFile)
  }

  const params = {
    formik,
    setIsValid,
    type: 'Edit',
    disableInput,
    partnerType,
    handleTypeChange,
    UserRole,
    showFile,
    fileName,
    cancelFile,
    AddNewSalesPerson,
    saveSalesPersonInfo,
    deleteTableRow,
    loading,
    isValid,
    navigate,
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 '
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Partner Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        {PartnerForm(params)}
      </div>
    </div>
  )
}
