import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

export function GetTrialExtensionHistoryByOrgId(orgId: any) {
  return axios.get(`${API_URL}/OrganizationTrialExtenstion/GetTrialHistoryByOrgId`, {
    params: {
      orgId,
    },
  })
}

export function GetTrialPendingRequest() {
  return axios.get(`${API_URL}/OrganizationTrialExtenstion/GetTrialPendingRequestForSuperAdmin`)
}
export function UpdateTrialRequest(body: any) {
  return axios.post(`${API_URL}/OrganizationTrialExtenstion/UpdateTrialRequestBySuperAdmin`, body)
}
