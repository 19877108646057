/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'

import {
  Loader,
  BuilderPageSelect,
  SingleDatePickerComponent,
} from '../../CommonFunctions/CommonFunction'
import {convertSecIntoHoursMinSec} from '../../utils/ConvertSeconds.utils'

import clsx from 'clsx'

import {AdminChart} from '../adminDashboard/AdminChart'
import {AdminWebVsApp} from '../adminDashboard/AdminWeb&App'
import {AdminProdVsUnProd} from '../adminDashboard/AdminProdVsUnProd'
import {AdminStats} from '../adminDashboard/AdminStats'
import dayjs from 'dayjs'
import {AdminUnproductive} from '../adminDashboard/AdminUnproductive'

import axios, {CancelTokenSource} from 'axios'

const API = process.env.REACT_APP_API_URL
const BuilderPageManager = () => {
  const [alignment, _] = React.useState('Individual')

  const [spinner, setSpinner] = useState(false)
  const [selectedMember, setSelectedMember] = useState('All')
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, __] = useState(null)
  const [department, setDepartment] = useState<any[]>([])
  const [adminStats, setAdminStats] = useState<any>({})
  const [color, ___] = useState('warning')
  const manager = localStorage.getItem('userId')
  const userRole = localStorage.getItem('role')

  let cancelTokenSource: CancelTokenSource | null = null
  useEffect(() => {
    getAdminStats()
  }, [selectedDepartment, selectedMember, startDate])

  useEffect(() => {
    GetDepartment()
  }, [])

  const GetDepartment = async () => {
    const organizationId = localStorage.getItem('org_Id')
    try {
      const departments = await axios.get(`${API}/Department/GetDepartment`, {
        params: {
          organizationId: organizationId,
        },
      })

      const newList = departments.data.map((item: any) => {
        return {
          label: item.department,
          value: item.departmentId,
        }
      })

      newList.unshift({
        label: 'Select Department',
        value: '',
      })

      // return newList

      setDepartment(newList)
    } catch (error) {
      console.log(error)
    }
  }

  //handle the change in dept
  const handleDeptChange = (dept: any) => {
    setSelectedDepartment(dept.value)
    setSelectedMember('')
  }

  const getAdminStats = async () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    cancelTokenSource = axios.CancelToken.source()

    setSpinner(true)

    try {
      if (selectedDepartment) {
        let body = {
          OrganizationId: localStorage.getItem('org_Id'),
          ManagerId: localStorage.getItem('userId'),
          DepartmentId: selectedDepartment,
          FromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
          ToDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        }

        const getData = await axios.get(
          `${API}/AdminDashboard/GetDashboardCardsDataForReportManagerAndDeptId`,
          {
            params: body,
            cancelToken: cancelTokenSource?.token,
          }
        )
        setAdminStats(getData.data)
      } else {
        let body = {
          OrganizationId: localStorage.getItem('org_Id'),
          ManagerId: localStorage.getItem('userId'),
          FromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
          ToDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        }
        const getData = await axios.get(
          `${API}/AdminDashboard/GetDashboardCardsDataForReportManager`,
          {
            params: body,
            cancelToken: cancelTokenSource?.token,
          }
        )
        setAdminStats(getData.data)
      }

      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      console.log(error)
    }
  }

  //handle date change
  const handleDateChange = (dates: any) => {
    setStartDate(dates)
    // setEndDate(end)
  }

  return (
    <>
      <form className='form'>
        <div className='card-body'>
          <div className='tab-content pt-3'>
            <div className='card-header border-0 pt-5 mb-7'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '10px 16px',
                  alignItems: 'center',
                }}
              >
                <div className='d-flex align-items-end'>
                  <span style={{fontSize: '18px', paddingBottom: '0.5rem'}}>
                    Department :&nbsp;{' '}
                  </span>
                  {localStorage.getItem('role') != 'User' && (
                    <>
                      {BuilderPageSelect(
                        alignment,
                        department,
                        selectedDepartment,
                        handleDeptChange
                      )}

                      <div className='d-flex flex-column'>
                        {SingleDatePickerComponent(
                          startDate,
                          handleDateChange,
                          'form-control custom-Height'
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={clsx('tab-pane', {active: true})}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <AdminStats
                  time={spinner ? '-' : adminStats.totalUsers}
                  title='Total Members'
                  description={dayjs().format('DD MMMM YYYY')}
                  color={color}
                  img='/media/icons/duotune/art/group.svg'
                  bgcolor='#34A853'
                  rawData={adminStats}
                  date={startDate}
                ></AdminStats>

                <AdminStats
                  time={spinner ? '-' : adminStats.totalActiveUsers}
                  title='Active Members'
                  description={dayjs().format('DD MMMM YYYY')}
                  color='warning'
                  img='/media/icons/duotune/art/group.svg'
                  bgcolor='#FBBC04'
                  rawData={adminStats}
                  date={startDate}
                ></AdminStats>

                <AdminStats
                  time={spinner ? '-' : adminStats.totalOfflineUsers}
                  title='Offline Members'
                  description={dayjs().format('DD MMMM YYYY')}
                  color='warning'
                  img='/media/icons/duotune/art/group.svg'
                  bgcolor='#6237DD'
                  rawData={adminStats}
                  date={startDate}
                ></AdminStats>

                <AdminStats
                  time={spinner ? '-' : convertSecIntoHoursMinSec(adminStats.totalHours)}
                  title='Total Hours'
                  description={dayjs().format('DD MMMM YYYY')}
                  color='warning'
                  bgcolor='#4084FF'
                ></AdminStats>
              </div>
              <div
                className='mt-10'
                style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}
              >
                <div className='card-header border-0 pt-5' style={{width: '49%'}}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '10px 16px',
                      alignItems: 'center',
                    }}
                  >
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>Team Productivity</span>
                    </h3>
                  </div>

                  <div className='card-body py-3 mt-1'>
                    <div>
                      {spinner ? (
                        Loader('100px')
                      ) : (
                        <AdminChart
                          className={''}
                          startDate={startDate}
                          endDate={endDate ? endDate : startDate}
                          selectedUser={manager ? manager : ''}
                          department={selectedDepartment ? selectedDepartment : ''}
                          role={userRole ? userRole : ''}
                        ></AdminChart>
                      )}
                    </div>
                  </div>
                </div>
                <AdminWebVsApp
                  className=''
                  startDate={startDate}
                  endDate={endDate ? endDate : startDate}
                  selectedUser={manager ? manager : ''}
                  department={selectedDepartment ? selectedDepartment : ''}
                ></AdminWebVsApp>
              </div>

              <div
                className='mt-10'
                style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}
              >
                <AdminProdVsUnProd
                  className='card-xl-stretch '
                  startDate={startDate}
                  endDate={endDate ? endDate : startDate}
                  selectedUser={manager ? manager : ''}
                  department={selectedDepartment ? selectedDepartment : ''}
                ></AdminProdVsUnProd>
                <AdminUnproductive
                  className='card-xl-stretch '
                  startDate={startDate}
                  endDate={endDate ? endDate : startDate}
                  selectedUser={manager ? manager : ''}
                  department={selectedDepartment ? selectedDepartment : ''}
                ></AdminUnproductive>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export {BuilderPageManager}
