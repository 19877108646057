import React, {FC, useEffect, useState} from 'react'

import '../../../css/checkoutPopUp.css'
import {GetAllBillingPlansByOrgId} from '../../services/Payment.services'
import 'tippy.js/dist/tippy.css'
import {Loader} from '../../CommonFunctions/CommonFunction'
import {KTSVG} from '../../../_metronic/helpers'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../../css/plans.css'
import {CheckOutPopUp} from './checkoutPopUp'

const API = process.env.REACT_APP_API_URL
interface PopupProps {
  onClose: () => void
  handleUpdate: () => void
  view: string
  planId: string
}

const PlansPopUp: FC<PopupProps> = ({onClose, view, planId, handleUpdate}) => {
  const [spinner, setSpinner] = useState(false)
  const [allPlans, setAllPlans] = useState<any[]>([])
  const [paymentType, setPaymentType] = useState('Online')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState('')

  useEffect(() => {
    setSpinner(true)
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const plans = await GetAllBillingPlansByOrgId()
      setAllPlans(plans.data)
      setSpinner(false)
    } catch (error) {
      console.error('Error fetching plans:', error)
    }
  }

  const handleIconClick = () => {
    const closeButton = document.getElementById('closeButton')
    closeButton?.click()
  }

  const handleCloseModal = (isUpdated: any) => {
    if (isUpdated) {
      handleUpdate()
    }
    setIsModalOpen(false)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  }

  const handleOpenModal = (planId: any) => {
    setSelectedPlanId(planId)
    setIsModalOpen(true)
  }

  return (
    <div className='modal fade' id='kt_modal_update_plan'>
      <div className='modal-dialog modal-l'>
        <div className='modal-content rounded' style={{width: '160%'}}>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div
              id='closeButton'
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body pt-0  px-5 px-xl-20'>
            <div className=' text-center'>
              <h1 className='mb-2'>Choose Plan</h1>
            </div>

            {allPlans?.length > 0 && (
              <div
                className='nav-group nav-group-outline mx-auto'
                data-kt-buttons='true'
                data-kt-initialized='1'
                style={{width: 'fit-content'}}
              >
                <button
                  className={`btn btn-color-gray-400  btn-active-secondary px-6 py-3 me-2 ${
                    paymentType === 'Offline' ? 'active' : ''
                  }`}
                  data-kt-plan='Offline'
                  onClick={() => setPaymentType('Offline')}
                >
                  Offline
                </button>

                <button
                  className={`btn btn-color-gray-400  btn-active-secondary px-6 py-3 ${
                    paymentType === 'Online' ? 'active' : ''
                  }`}
                  data-kt-plan='Online'
                  onClick={() => setPaymentType('Online')}
                >
                  Online
                </button>
              </div>
            )}

            <div className='row g-10 payment-container mt-2'>
              {spinner && Loader('0px')}
              {!spinner && allPlans?.length > 0 && (
                <Slider {...settings}>
                  {allPlans.map((item) => (
                    <div className='col-xl-4'>
                      <div className='plan'>
                        <div className='inner'>
                          <span className='pricing'>
                            <span>
                              {' '}
                              {item.currencyCode === 'USD'
                                ? `$${item.amount}`
                                : `₹${item.amount}`}{' '}
                              <small>/ {item.planTypeName}</small>{' '}
                            </span>
                          </span>
                          <div>
                            <p className='title'>{item.planName}</p>
                            <span className='info'>{item.planDescription}</span>
                          </div>
                          <div>
                            <ul className='features'>
                              {item.benefits.map((benefits: any) => (
                                <li>
                                  <span className='icon'>
                                    <svg
                                      height='24'
                                      width='24'
                                      viewBox='0 0 24 24'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path d='M0 0h24v24H0z' fill='none'></path>
                                      <path
                                        fill='currentColor'
                                        d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z'
                                      ></path>
                                    </svg>
                                  </span>
                                  <span className='benefits'>
                                    {/* <strong>20</strong> team members */}
                                    {benefits}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='action'>
                            {/* <button className='button' onClick={() => handleOpenModal(item.planId)}> */}
                            <button
                              className='button'
                              onClick={() => handleOpenModal(item.planId)}
                              disabled={
                                view === 'change' && planId !== '' && planId === item.planId
                                  ? true
                                  : false
                              }
                            >
                              {view === 'change' && planId !== '' && planId === item.planId
                                ? 'Currently Active'
                                : 'Choose plan'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* <div className='plan'>
                    <div className='inner'>
                      <span className='pricing'>
                        <span>
                          {' '}
                          $55
                          <small>/ month</small>{' '}
                        </span>
                      </span>
                      <p className='title'>Testing</p>
                      <span className='info'>
                        This is just to testThis is just to testThis is just to test
                      </span>
                      <ul className='features'>
                        <li>
                          <span className='icon'>
                            <svg
                              height='24'
                              width='24'
                              viewBox='0 0 24 24'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path d='M0 0h24v24H0z' fill='none'></path>
                              <path
                                fill='currentColor'
                                d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z'
                              ></path>
                            </svg>
                          </span>
                          <span className='benefits'>
                            
                            LIVE STREAMING
                          </span>
                        </li>
                      </ul>
                      <div className='action'>
                        <button className='button'> Choose plan </button>
                      </div>
                    </div>
                  </div> */}
                </Slider>
              )}
              {!spinner && allPlans.length == 0 && (
                <h2
                  className='noRecordFound'
                  style={{height: '9rem', marginTop: '5rem', userSelect: 'none'}}
                >
                  No Plans Found
                </h2>
              )}
            </div>
          </div>
          {isModalOpen && (
            <CheckOutPopUp
              paymentType={paymentType}
              onClose={handleCloseModal}
              handleMainIconClick={handleIconClick}
              planId={selectedPlanId}
            ></CheckOutPopUp>
          )}
        </div>
      </div>
    </div>
  )
}

export {PlansPopUp}
