import axios from 'axios'
import React, {useEffect, useState} from 'react'

import {Loader} from '../../CommonFunctions/CommonFunction'
import dayjs from 'dayjs'

const API_URL = process.env.REACT_APP_API_URL

const IntegratedPortals: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])

  useEffect(() => {
    getList()
  }, [])

  const getList = () => {
    axios.get(`${API_URL}/PortalIntegration/GetAllPortalIntegration`).then((res) => {
      setList(res.data)
    })

    setLoader(false)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Integrated Portals</span>
            <span className='text-muted mt-1 fw-bold fs-7'></span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        {loader && Loader('100px')}

        {!loader && (
          <div className={`card-body py-3 ${list.length > 0 ? 'mb-3' : 'mb-18'}`}>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder'>
                    <th></th>
                    <th className='min-w-125px'>Portal Name</th>
                    <th className='min-w-125px'>Portal URL</th>
                    <th className='min-w-125px'>Email</th>
                    <th className='min-w-125px'>Contact</th>

                    <th className='min-w-125px'>Portal Api Key</th>
                    <th className='min-w-125px'>Key Expiry</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {list.length > 0 &&
                    list.map((details) => (
                      <tr key={details.id}>
                        <td></td>

                        <td>
                          <span className='text-dark  d-block fs-6 '>{details.portalName}</span>
                        </td>
                        <td>
                          <span className='text-dark d-block fs-6'>{details.portalURL}</span>
                        </td>

                        <td>
                          <span className='text-dark d-block fs-6'>{details.contactEmail}</span>
                        </td>

                        <td>
                          <span className='text-dark d-block fs-6'>{details.contactNumber}</span>
                        </td>
                        <td>
                          <span className='text-dark d-block fs-6' style={{lineBreak: 'anywhere'}}>
                            {details.portalApiKey}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark d-block fs-6'>
                            {dayjs(details.keyExpiryDate).format('DD/MM/YYYY')}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>

              {list.length <= 0 && (
                <span className='position-absolute w-100'>
                  <h2 className='noRecordFound'>No Records Found</h2>
                </span>
              )}
            </div>

            {/* end::Table */}

            {/* end::Table container */}
          </div>
        )}
      </div>
    </>
  )
}

export default IntegratedPortals
