import React, {useState, useEffect} from 'react'

import * as Yup from 'yup'
import {useFormik} from 'formik'

import {
  addEditGroupAdminFrom,
  baseGroupAdminSchema,
  fillCompanyDropDown,
  submitAddEditGroupAdmin,
} from '../../CommonFunctions/CommonFunction'

import {useNavigate} from 'react-router-dom'

const letters = /^[a-zA-Z][a-zA-Z ]*$/

const GroupAdminDetails: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [companiesOptions, setCompaniesOptions] = useState<any[]>([])
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])

  const groupAdminSchema = baseGroupAdminSchema.shape({
    fullName: Yup.string()
      .matches(letters, 'numerals and symbols are not allowed')
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Full Name is required'),
  })

  const initialValues = {
    email: '',
    fullName: '',
    organizationIds: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: groupAdminSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      submitAddEditGroupAdmin(
        setLoading,
        values,
        navigate,
        resetForm,
        setSubmitting,
        'Registration/AddGroupAdmin'
      )
    },
  })

  useEffect(() => {
    fillCompanyDropDown(setCompaniesOptions)
  }, [])

  const params = {
    type: 'Add',
    formik,
    selectedOptions,
    companiesOptions,
    setSelectedOptions,
    loading,
    navigate,
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0' role='banner'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Group Admin Details</h3>
        </div>
      </div>

      <div className='collapse show'>{addEditGroupAdminFrom(params)}</div>
    </div>
  )
}

export default GroupAdminDetails
