import React, {useState, FC} from 'react'

import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ProjectTaskApiCall} from '../../../CommonFunctions/CommonFunction'
import {useNavigate, Link} from 'react-router-dom'

const initialValues = {
  department: '',
  organizationId: localStorage.getItem('org_Id'),
}

const DepartmentDetails: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const departmentSchema = Yup.object().shape({
    department: Yup.string().required('Department Name is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: departmentSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      values.department = values.department.trim()
      values.organizationId = localStorage.getItem('org_Id')
      ProjectTaskApiCall(
        'Department/AddDepartment',
        '/department',
        values,
        resetForm,
        setLoading,
        navigate,
        setSubmitting
      )

      setLoading(false)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 cursor-pointer' role='banner'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Department Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Department Name</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Name'
                  {...formik.getFieldProps('department')}
                />
                {formik.touched.department && formik.errors.department && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.department}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to={'/department'}>
              <button type='button' className='btn btn-secondary'>
                Cancel
              </button>
            </Link>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && <span className='indicator-label'>Add Department</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DepartmentDetails
