import React, {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {AlertModal} from '../../../app/CommonFunctions/CommonFunction'
import '../../../css/ProVsIdlePopUp.css'
import '../../../css/extensionPopUp.css'
import * as Yup from 'yup'
import 'tippy.js/dist/tippy.css'
import {UpdateTrialRequest} from '../../services/trialExtension.services'
const API = process.env.REACT_APP_API_URL
interface PopupProps {
  onClose: (hasAnyChange: any) => void
  updateStatus?: any
  getList: any
  barDate: string
  selectedUser: string
  series: string
  data: any
  status: string
}

const TrialPopup: FC<PopupProps> = ({onClose, getList, data, status}) => {
  const [hasChange, setHasChange] = useState(false)

  useEffect(() => {
    formik.setFieldValue(`noOfDay`, data.requestDays)
  }, [])

  const handleIconClick = () => {
    onClose(hasChange)
  }

  const memberSchema = Yup.object().shape({
    noOfDay: Yup.string()
      .required('No of day is required')
      .test(
        'maxExtensionDays',
        `No of days cannot be greater than ${data.requestDays}`,
        function (value) {
          const extensionDays = data.requestDays as number | undefined
          console.log('The value is ', value, extensionDays)
          if (extensionDays !== undefined && value !== undefined) {
            const numericValue = parseInt(value, 10)
            return numericValue <= extensionDays
          }

          return true // Let other validations handle if extensionDays or value is undefined
        }
      ),
    reason: Yup.string()

      .max(200, 'Maximum 200 character')
      .required('Comment is required'),
  })

  const initialValues = {
    noOfDay: '',
    reason: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: memberSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        let body = {
          trialRequestId: data.trialRequestId,
          organizationId: data.organizationId,
          responseReason: values.reason,
          extensionDays: values.noOfDay,
          status: status,
        }

        const updateStatus = await UpdateTrialRequest(body)
        AlertModal(
          updateStatus.data.message,
          '',
          updateStatus.data.success ? 'success' : 'error',
          false,
          '#7066E0',
          'Ok'
        ).then(() => {
          getList()
          handleIconClick()
        })
      } catch (error) {
        AlertModal('Something went wrong!', '', 'error', false, '#7066E0', 'Ok')
      }
      resetForm()
    },
  })

  return (
    <div className='modal-container d-flex'>
      <div style={{margin: 'auto', paddingLeft: '200px'}}>
        <div
          className='card mt-3 modal-innerContainer'
          style={{padding: '0rem 2.5rem 2rem 2.5rem'}}
        >
          <div className='card mt-5'>
            <form onSubmit={formik.handleSubmit} className='extension-extension-form'>
              <div className='d-flex' style={{justifyContent: 'space-evenly'}}>
                <p className='extension-form-title'>Enter Details</p>
                <i
                  className='fa fa-times'
                  onClick={() => handleIconClick()}
                  aria-hidden='true'
                  style={{
                    fontSize: '1.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '-103px',
                    cursor: 'pointer',
                  }}
                ></i>
              </div>
              {status !== 'Rejected' && (
                <>
                  <span className='extension-span'>No. of Days</span>
                  <div className='extension-input-container'>
                    <input
                      type='text'
                      className='form-control form-control-border'
                      placeholder='No of Days'
                      {...formik.getFieldProps('noOfDay')}
                    />
                    {formik.touched.noOfDay && formik.errors.noOfDay && (
                      <div className='fv-plugins-message-container'>
                        <div className='text-danger mt-2'>{formik.errors.noOfDay}</div>
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className='mt-3'>
                <span className='extension-span'>Comment</span>
                <div className='extension-input-container'>
                  <textarea
                    className='form-control form-control-border'
                    placeholder='Comment'
                    {...formik.getFieldProps('reason')}
                  />
                  {formik.touched.reason && formik.errors.reason && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.reason}</div>
                    </div>
                  )}
                </div>
              </div>

              <button className='extension-submit mt-3' type='submit'>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <style>{`
          .modal-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;
            animation: fadeIn 0.5s ease;
            
          }

          #div-scroller::-webkit-scrollbar-thumb {
            background-color: #C1C1C1 !important;
          }
          #div-scroller::-webkit-scrollbar {
            width:0.5rem
          }



          .modal-innerContainer {
            
            margin: 0 auto;
            background-color: #fff;
            border-radius: 6px;
            padding: 20px;
            position: relative;
            width:100%;
          }

        .custom-modal-header{
            padding-right: 29rem;
            font-size: 1.8rem;
            font-weight: 700;
        }

        .paginationBttns{
            width:23rem;
            margin: 2rem auto 0 auto;
        }

        .temp-class{
            padding: 0.5rem;
            width: 8rem;
            margin: 0;
            justify-content:center;
            margin:unset
        }


        `}</style>
    </div>
  )
}

export {TrialPopup}
