/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

import {Link} from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert2'
import {GetAllPartner, ActivatePartner} from '../../../services/partner.service'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import {
  Loader,
  Pagination,
  AlertModal,
  createTooltipInput,
} from '../../../CommonFunctions/CommonFunction'
const API_URL = process.env.REACT_APP_API_URL

const DisplayPartner: React.FC = () => {
  const [partner, setPartner] = useState<any[]>([])
  const [spinner, setSpinner] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    setSpinner(true)
    getPartners()
  }, [])

  const deleteHandler = (partnerId: any) => {
    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Yes, deactivate!'
    ).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .post(`${API_URL}/Registration/DeletePartner?partnerId=${partnerId}`)
          .then((res) => {
            getPartners()
            AlertModal(
              res.data.message,
              '',
              res.data.success ? 'success' : 'warning',
              false,
              '#7066E0',
              'Ok'
            )
          })
          .catch((err) => {
            console.log(err.data.message)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Partner not deactivated', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  const getPartners = async () => {
    try {
      const getAllPartner = await GetAllPartner()
      setPartner(getAllPartner.data)
      setSpinner(false)
    } catch (error) {
      console.log(error)
    }
  }

  let itemsPerPage: number = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage

  const filteredMembers = partner.filter((partnerData) =>
    partnerData.partnerName.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % partner.length
    setItemOffset(newOffset)
  }

  const currentPagePartner = filteredMembers.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(partner.length / itemsPerPage)

  const handleSearchChange = (name: string) => {
    setSearchQuery(name)
    // setFilteredDepartment(filteredDepartment)
    setItemOffset(0)
  }

  const ResendActivationMail = async (partnerId: string) => {
    try {
      const activatePartner = await ActivatePartner(partnerId)
      AlertModal(
        activatePartner.data.message,
        '',
        activatePartner.data.success ? 'success' : 'warning',
        false,
        '#7066E0',
        'Ok'
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder mb-1' style={{fontSize: '19px'}}>
            Partners
          </span>
        </h3>

        <div style={{display: 'flex'}}>
          {spinner
            ? ''
            : createTooltipInput('Search partner by name', 'Search by Name', handleSearchChange)}

          <Tippy placement='top' content='Click to add a department'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              // title='Click to add a user'
            >
              <Link to='/addPartner' className='btn btn-sm btn-primary me-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Partner
              </Link>
            </div>
          </Tippy>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      {spinner ? (
        Loader('100px')
      ) : (
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                  </th>
                  <th className='min-w-120px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Name
                  </th>
                  <th className='min-w-120px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Email
                  </th>
                  <th className='min-w-120px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Type
                  </th>
                  <th className='min-w-120px' style={{fontSize: '16px', fontWeight: '600'}}>
                    Partner Code
                  </th>
                  <th className='min-w-175px text-center'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentPagePartner.map((names) => (
                  <tr key={names.partnerId}>
                    <td></td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div
                          className='d-flex justify-content-start flex-column '
                          style={{width: '13rem', fontSize: '13px'}}
                        >
                          {/* <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'> */}
                          {names.partnerName}

                          {/* </a> */}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div
                          className='d-flex justify-content-start flex-column '
                          style={{width: '13rem', fontSize: '13px'}}
                        >
                          {/* <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'> */}
                          {names.partnerEmail}

                          {/* </a> */}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div
                          className='d-flex justify-content-start flex-column '
                          style={{width: '13rem', fontSize: '13px'}}
                        >
                          {/* <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'> */}
                          {names.partnerTypeName}

                          {/* </a> */}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div
                          className='d-flex justify-content-start flex-column '
                          style={{width: '13rem', fontSize: '13px'}}
                        >
                          {/* <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'> */}
                          {names.referralCode}

                          {/* </a> */}
                        </div>
                      </div>
                    </td>

                    <td style={{width: '10rem'}}>
                      <div className='d-flex justify-content-evenly flex-shrink-0'>
                        <Tippy placement='top' content='Resend activation mail.'>
                          <a
                            onClick={() => ResendActivationMail(names.partnerId)}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                              names.isActive === true ? 'disabled' : ''
                            }`}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/resendemail.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </Tippy>
                        <Tippy placement='top' content='Edit department'>
                          <Link
                            to='/partner/editPartner'
                            onClick={() => {
                              localStorage.setItem('editPartner', names.partnerId)
                            }}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </Link>
                        </Tippy>

                        <Tippy placement='top' content='Deactivate Partner'>
                          <a
                            onClick={() => deleteHandler(names.partnerId)}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </Tippy>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            <div className='d-flex flex-column'>
              {partner.length > 10 && (
                <div className='d-flex align-items-center justify-content-center'>
                  {Pagination(handlePageClick, pageCount, 0)}
                </div>
              )}
            </div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DisplayPartner
